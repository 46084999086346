import {
  CONNECT_WEATHER_REQUEST,
  CONNECT_WEATHER_SUCCESS,
  CONNECT_WEATHER_FAILURE
} from "./actionTypes";

export const connectWeatherActions = {
  getWeather: () => (dispatch, getState) => {
    dispatch({
      type: CONNECT_WEATHER_REQUEST,
      http: {
        verb: "GET",
        endpoint: `/api/Connect/Weather/v1/Get`,
        successAction: CONNECT_WEATHER_SUCCESS,
        failureAction: CONNECT_WEATHER_FAILURE
      }
    });
  }
};
