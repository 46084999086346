export const TimeFilterEnum = {
  MTD: 'MTD',
  QTD: 'QTD',
  YTD : 'YTD',
  PY: 'PY',
  PM: 'PM',
  PMPY : 'PMPY',
  PYTD : 'PYTD',

  // MTD = 0, //Current Month
  // QTD = 1, //CurrentQuarter
  // YTD = 2,  //CurrentYear
  // PY = 3, //Previous year
  // PM = 4, //Previous month
  // PMPY = 5, //PreviousMonthPreviousYear
  //PYTD = 6 //Previous year to date
};