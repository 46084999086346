import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { posMdioRequestActions } from '../../../actions/posMdioRequestActions';
import qs from 'query-string';
import Pagination from '../../shared/Pagination';
import TableViewControls from '../../shared/tableViewControls';


class PendingMdioList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      page: 1,
      pageSize: 25,
      displayMode: 1,
      data: [],
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.setDisplayMode = this.setDisplayMode.bind(this);

    this.doPaging = this.doPaging.bind(this);
    this.doSearch = this.doSearch.bind(this);

    this.navigateItemDetails = this.navigateItemDetails.bind(this);

    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleStatusChange(e) {
    this.setState({ status: e.target.value });
    this.handleFilterChange({ ...this.state, status: e.target.value });

    e.preventDefault();
  }

  componentDidMount() {
    const { search } = this.props.location;

    let newState = {
      pageSize: 25,
      filter: '',
      page: 1,
      displayMode: 1,
    };

    if (search) {
      const parsed = qs.parse(search.toLowerCase());

      newState = {
        ...newState,
        filter: parsed.filter ? parsed.filter : '',
        page: parsed.page ? parseInt(parsed.page) : 1,
        displayMode: parsed.displaymode ? parseInt(parsed.displaymode) : 1,
      };
    }

    this.setState(newState);

   
    this.props.getItems(
      newState.page, newState.pageSize, newState.filter, 
    );
  }

  componentDidUpdate(prevProps){
    if(this.props.isSaved && !prevProps.isSaved){
      this.props.getItems(
        this.state.page, this.state.pageSize, this.state.filter, 
      );
    }
  }

  handleInput(e, id, fieldName){
    const { data } = this.state;
    this.setState({ data: { ...data,  [id]: { ...data[id], id, [fieldName]: e.target.value } } });
  } 
 
  handleSave(){
    const { data } = this.state;
    const { submitBulkForm } = this.props;

    const formData = [];

    for(const entry of Object.values(data)){
      
      if(entry.sbuxItemNumber && entry.sbuxItemDescription && entry.id){
        formData.push(entry);
      }
    }

    if(formData && formData.length){
      submitBulkForm(formData);
    }
  }

  handleSearch(e) {
    this.setState({ filter : e.target.value, page: 1 });
    e.preventDefault();
  }

  navigateItemDetails(itemId) {
    const { pathname } = this.props.location;
    this.props.push(`${pathname}/${itemId}`);
  }

  doSearch() {
    this.handleFilterChange({ ...this.state });
  }

  doPaging(page) {
    this.setState({ page });

    this.handleFilterChange({ ...this.state, page });
  }
  
  setDisplayMode(mode) {
    this.setState({ displayMode: mode });
  }

  handleFilterChange(currentState) {
    const { pathname } = this.props.location;
    const {
      filter,
      displayMode,
      pageSize,
      page,
    } = currentState;

    this.props.replace(
      `${pathname}?=$filter=${filter}&page=${page}&displayMode=${displayMode}`,
    );

    this.props.getItems(
      page, pageSize, filter,
    );
  }


  render() {
    const { rowCount, mdioList, isSaving } = this.props;
    const {
      page,
      pageSize,
      filter,
      displayMode,
    } = this.state;
    const {
      handleSearch,
      doSearch,
      doPaging,
      navigateItemDetails,
    } = this;

    return (
      <div className="pending-mdio-creation-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-table" />
              <div>
                <h2>
                Pending MDIO creation
                </h2>
                <p>Details of customer items which require a Starbucks Item Number from PIM are detailed here.</p>
              </div>
            </div>
            <div className= "search-wrapper"> 
              <input
                className= "search-item"
                type="text"
                placeholder="Search..."
                value={filter}
                onChange={e => handleSearch(e)}
            
              />
              <button className="action"
                onClick={() => doSearch()}
              >Search</button>
            </div>
          </div>
          <p>Before creating a new item number you should check to see if an existing item exists for this product which can be re-used.</p>
          <p>If an existing item exists then please update this item number and description in the update fields and submit.</p>
          <p>If an item number needs to be created in PIM please use the customer information provided in conjunction with the defaults to create them.</p>
        </div>
        <div className="table-container">
          <div className= "table-header">
            <TableViewControls
              displayMode = {this.state.displayMode}
              setDisplayMode = {this.setDisplayMode}
            />
            <h2>
              POS Item Number requests Update
            </h2>
            <div className="pagination-controls">
              <Pagination
                rowCount={rowCount}
                page={page}
                pageSize={pageSize}
                doPaging={doPaging}
              />
            </div>
          </div>
    
        
          <div className="table-body">
            <table  className={`table-display-mode-${displayMode}`}>
              <thead>
                <tr>
                  <th>Customer Number</th>
                  <th>Market</th>
                  <th>Requested by</th>
                  <th></th>
                  <th>Starbucks Item Number</th>
                  <th>Starbucks Description</th>
                </tr>
              </thead>
              <tbody>
               
                {mdioList.map(item => { 
                  
                  const edit = this.state.data[item.id];
                  
                  return(
                    <tr
                      key={item.id}>
                      <td>{item.customerItemNumber}</td>
                      <td>{item.customerItemDescription}</td>
                      <td>Vew Request Details</td> 
                      <td>
                        <button
                          type="button"
                          className="action"
                          onClick={() => navigateItemDetails(item.id)}
                        >View</button>
                      </td>
                      <td className= "sbux-input">
                        <input type="text"
                          value={edit && edit.sbuxItemNumber}
                          onChange={(e) => this.handleInput(e, item.id, 'sbuxItemNumber')}
                        />
                      </td>
                      <td className= "sbux-input"><input type="text"
                        value={edit && edit.sbuxItemDescription}
                        onChange={(e) => this.handleInput(e, item.id, 'sbuxItemDescription')}
                      /></td>
              
                    </tr>
                  );
              
                })}
              </tbody>
            </table>  
          </div>
          <div className="table-header footer">
            <div></div>
            <div className="pagination-controls">
              <Pagination
                rowCount={rowCount}
                page={page}
                pageSize={pageSize}
                doPaging={doPaging}
              />
            </div>
          </div>

          <div className="downloads">
            <div className= "all-open-requests"> 
              <a
                className="expand-button"
                href={'/api/LsPos/Mdio/v1/Download'}>
                <div className= "all-open-requests-header"><span>  Download all Open Request Details </span>
                  <button
                    type="button"
                    className="download"
                  >
                  </button></div>
                <div><p>  Download all Open Requests for consolidated review and/or upload into PIM for bulk creation</p></div>
              </a>
            </div>
            <div className= "bulk-update">
              <button
                type="button"
                className="bulk-download"
                disabled={isSaving}
                onClick={() => this.handleSave()}
              >
                Submit Bulk Update
              </button>
              <div className= "bulk-update-text">
                <p>  Details can be updated individually from the request details page or in bulk using the above data entry fields.</p>
                <p>  You do not need to make entries in both.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.lsPosMdio.isFetching,
  loaded: state.lsPosMdio.isLoaded,
  isSaving: state.lsPosMdio.isSaving,
  isSaved: state.lsPosMdio.isSaved,
  stores: state.auth.stores,
  mdioList: state.lsPosMdio.mdioList,
  pageNumber: state.lsPosMdio.pageNumber,
  pageCount: Math.ceil(state.lsPosMdio.totalRecords / state.lsPosMdio.pageSize),
  rowCount: state.lsPosMdio.totalRecords,
});

const mapDispatchToProps = dispatch => {
  return {
    getItems: (page, pageSize, filter) =>
      dispatch(
        posMdioRequestActions.requestItems(
          page, pageSize, filter, 
        ),
      ),
    submitBulkForm: (items) => dispatch(
      posMdioRequestActions.submitBulkForm(items),
    ),
   
    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PendingMdioList);
