import {
  APAC_ORDERDOCUMENTS_REQUEST,
  APAC_ORDERDOCUMENTS_SUCCESS,
  APAC_ORDERDOCUMENTS_FAILURE,
  APAC_ORDERDOCUMENTS_CLEARSTATE,
  APAC_ORDERDOCUMENTS_SAVE_REQUEST,
  APAC_ORDERDOCUMENTS_SAVE_SUCCESS,
  APAC_ORDERDOCUMENTS_SAVE_FAILURE,
  APAC_ORDERDOCUMENTS_DELETE_REQUEST,
  APAC_ORDERDOCUMENTS_DELETE_SUCCESS,
  APAC_ORDERDOCUMENTS_DELETE_FAILURE,
} from './actionTypes';

export const apacOrderDocumentActions = {
  clearState: () => {
    return {
      type: APAC_ORDERDOCUMENTS_CLEARSTATE,
    };
  },
  getOrderDocuments: (trackingNumber) => (dispatch, getState) => {
    dispatch({
      type: APAC_ORDERDOCUMENTS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/omorderdocument/v1/getorderdocuments?trackingNumber=${trackingNumber}`,
        successAction: APAC_ORDERDOCUMENTS_SUCCESS,
        failureAction: APAC_ORDERDOCUMENTS_FAILURE,
      },
    });
  },
  uploadOrderDocument: (request) => (dispatch, getState) => {
    dispatch({
      type: APAC_ORDERDOCUMENTS_SAVE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/omorderdocument/v1/uploadorderdocument',
        successAction: APAC_ORDERDOCUMENTS_SAVE_SUCCESS,
        failureAction: APAC_ORDERDOCUMENTS_SAVE_FAILURE,
        body: request,
      },
    });
  },
  deleteDocument: (id) => (dispatch, getState) => {
    dispatch({
      type: APAC_ORDERDOCUMENTS_DELETE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: `/api/omorderdocument/v1/deleteorderdocument?id=${id}`,
        successAction: APAC_ORDERDOCUMENTS_DELETE_SUCCESS,
        failureAction: APAC_ORDERDOCUMENTS_DELETE_FAILURE,
      },
    });
  },
};
