import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { navigationActions } from '../../../actions/navigationActions';

class TwoFactor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tfaCode: '',
      validationMessage: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
  }

  componentDidMount() {
    const { twoFactor, completeMfa } = this.props;

    if (!twoFactor) {
      completeMfa('');
    }
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  handleSubmit() {
    
    const { tfaCode } = this.state;
    const { completeMfa } = this.props;

    if (tfaCode) {
      completeMfa(tfaCode);
    } else {
      this.setState({ validationMessage: 'Please enter an authentication token.' });
    }
  }

  render() {
    const { errorMessage, authingUser, authedUser, twoFactor } = this.props;

    if (twoFactor) {
      return (
        <form onSubmit={this.handleSubmit}
          noValidate>
          <p>You need to complete two factor authentication to continue.</p>
          <p>You should recieve an email with your authentication code.</p>
          <div className='full-code-wrapper'>
            <p>{`${this.props.twoFactorPrefix} - `} </p>
            <input
              placeholder="One Time Token"
              type="text"
              maxLength="6"
              onChange={e => this.handleTextInputUpdate(e, 'tfaCode')}
              required
              value={this.state.tfaCode}
              className="float"
            />
          </div>
          <div className="submission">
            <p>
              {this.props.message}
              {this.state.validationMessage}
            </p>
            <button className="float"
              type='button'
              onClick={ () => this.handleSubmit()}
              disabled={authingUser}>
              {authingUser ? 'Authenticating...' : authedUser ? 'Success' : 'Authenticate'}
            </button>
            <p>{errorMessage}</p>
          </div>
        </form>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  twoFactorPrefix: state.auth.twoFactorPrefix,
  twoFactor: state.auth.twoFactorAuth,
  authingUser: state.auth.authUser,
  authedUser: state.auth.authedUser,
  errorMessage: state.auth.errorMessage,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    completeMfa: tfaCode => dispatch(authActions.completeMfa(tfaCode)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TwoFactor);
