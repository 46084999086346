import React, { useMemo } from 'react';

export const getDefaultTimePeriod = () => {
  const currentDate = new Date();
  return `${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
};

export default ({ value, onChange, past = 1, future = 1, ...additionalProps }) => {

  const availableTimePeriods = useMemo(
    () => {
      // Returns an array of all Month-Year values for the past 2 years
      const months = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
      };
      const currentYear = new Date().getFullYear();
      const endYear = currentYear + future;
      let baseMonth = new Date().getMonth() + 1;
      let startYear = currentYear - past;

      const absoluteMinumumStart = new Date(2020, 4, 1);
      const currentMinimumStart = new Date(startYear, baseMonth - 1, 1);
      if (currentMinimumStart.getTime() < absoluteMinumumStart.getTime()) {
        baseMonth = 4;
        startYear = 2020;
      }

      var timePeriods = [];
      for (let i = startYear; i <= endYear; i++) {
        for (const key in months) {
          if (i === startYear && key <= baseMonth) {
            continue;
          }

          if (i === endYear && baseMonth < key) {
            break;
          }

          timePeriods.push({
            value: `${key}-${i}`,
            text: `${months[key]} ${i}`,
          });
        }
      }

      return timePeriods;
    },
    [past, future]);

  return (
    <label>
      Period
      <select
        value={value}
        onChange={onChange}
        {...additionalProps}
      >
        <option value="">All</option>
        {availableTimePeriods.map(x =>
          <option
            key={x.value}
            value={x.value}
          >
            {x.text}
          </option>,
        )}
      </select>
    </label>
  );
};
