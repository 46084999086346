import React from 'react';

class WeatherTileVertical extends React.Component {

  generateDateLabel(date) {
    const weekdays = [
      'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday',
    ];
    //Use the getDay() method to get the day.
    var day = new Date(date).getDay();
    //Return the element that corresponds to that index.
    return weekdays[day];
  }

  generateTimeStyleColor() {

    return { backgroundColor: '#95d7fb' };
  }

  render() {
    const { data } = this.props;

    if (!data) {
      return null;
    }

    const { dt, sunrise, sunset, weather, temp } = data;

    return (
      <div className="connect-weather-tile-vertical"
        style={this.generateTimeStyleColor(sunrise, sunset)}>
        <div className="date">
          {this.generateDateLabel(dt * 1000)} {weather && weather.length > 0 && weather[0].main}
        </div>
        <div className="weather-row">
          <div className="left-stack">

            <div className="weather">
              <div className="weather-icon">
                {weather && weather.length > 0 &&
                  <img src={`/assets/weather-icons/${weather[0].icon}@2x.png`}
                    alt={weather[0].description} />}
              </div>
            </div>
          </div>

          <div className="temperatures">
            <div className="min-max">
              <div className="max">
                {`${temp.max.toFixed(0)}°C`}
              </div>
              <div className="min">
                {`${temp.min.toFixed(0)}°C`}
              </div>
            </div>
            <div className="thermometer">
              <img src="/assets/weather-icons/thermometer.svg"
                alt="thermometer" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WeatherTileVertical;
