import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../../actions/authActions';
import { navigationActions } from '../../../../actions/navigationActions';

class ConnectHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    
    this.handleContinueToPortal = this.handleContinueToPortal.bind(this);
  }

  getGreeting() {
    const { auth } = this.props;

    var now = new Date();
    var time = now.getTime() % (1000 * 60 * 60 * 24);
    var offset = now.getTimezoneOffset();

    time -= offset * 60 * 1000;

    var greeting = '';

    if (time < 1000 * 60 * 60 * 12) {
      greeting = 'Good Morning, ';
    } else if (time < 1000 * 60 * 60 * 17.5) {
      greeting = 'Good Afternoon, ';
    } else {
      greeting = 'Good Evening, ';
    }

    return `${greeting} ${auth.givenName}`;
  }
  
  handleContinueToPortal(event) {
    this.props.hideConnect();

    this.props.push('/');

    event.preventDefault();
  }

  render() {
    const { isAdmin } = this.props;
    return (
      <div className="connect-header">
        <div className="left-content">
          <div className="logo" />
          <div className="greeting">{this.getGreeting()}</div>
        </div>
        <div className="center-content">
          <input
            type="text"
            title="Search content"
            aria-label="Content Search text box"
            placeholder="Search..."
            onChange={e => this.props.search(e.target.value)}
          />
        </div>
        <div className="right-content">
          <div>
            {isAdmin ? (
              <div>
                <div>Content Admin Mode</div>
              </div>)
              : null}
            <div className="submission">
              <button
                type="button"
                onClick={this.handleContinueToPortal}
                className="float">
              Continue to Portal
              </button>
              <a onClick={this.props.logout}>
               Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    logout: () => dispatch(authActions.requestLogout()),
    hideConnect: () => dispatch(authActions.hideConnect()),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectHeader);
