import React from 'react';

class DocumentTabOds extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { customerRequirementsDetail, handleTextInputUpdate, handleCheckInputUpdate } = this.props;
    return (
      <>
        <label>
        Starbucks Packing List
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.starbucksPackingList}
              onChange={e => handleCheckInputUpdate(e, 'starbucksPackingList')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
        Starbucks Commercial Invoice
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.starbucksCommercialInvoice}
              onChange={e => handleCheckInputUpdate(e, 'starbucksCommercialInvoice')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
        BOL
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.bol}
              onChange={e => handleCheckInputUpdate(e, 'bol')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
        Load Plan
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.loadPlan}
              onChange={e => handleCheckInputUpdate(e, 'loadPlan')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
        Warehouse/Shipper Packing List
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.warehousePackingList}
              onChange={e => handleCheckInputUpdate(e, 'warehousePackingList')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
        Market Specific Requirements
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.marketSpecificRequirementsDocument}
              onChange={e => handleCheckInputUpdate(e, 'marketSpecificRequirementsDocument')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
        Details
          <textarea
            maxLength={500}
            type="text"
            value={customerRequirementsDetail && customerRequirementsDetail.otherRequirementsDocument}
            onChange={e => handleTextInputUpdate(e, 'otherRequirementsDocument')}
          />
        </label>
      </>
    );
  }
}

export default DocumentTabOds;
