import {
  IN_FULL_KPI_CLEARSTATE,
  IN_FULL_KPI_REQUEST,
  IN_FULL_KPI_FAILURE,
  GET_STORE_GROUPS_SUCCESS,
  GET_STORES_SUCCESS,
  GET_LATEST_IMPORT_SUCCESS,
  GET_KPI_CONFIGURATIONS_SUCCESS,
  UPDATE_KPI_CONFIGURATIONS_SUCCESS,
  IN_FULL_KPI_DATA_REQUEST,
  IN_FULL_KPI_DATA_SUCCESS,
  FAILEDITEMS_REQUEST,
  FAILEDITEMS_SUCCESS,
} from './actionTypes';

const kpiDashboardActions = {
  clearState: () => {
    return {
      type: IN_FULL_KPI_CLEARSTATE,
    };
  },
  getInFullDashboard: (params = { customerGroups: '', stores: '', adminView: false, months: '', weeks: '' }) => (dispatch) => {
    dispatch({
      type: IN_FULL_KPI_DATA_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/KPI/v1/Get?filterCustomerGroups=${params.customerGroups}&filterStores=${params.stores}&adminView=${params.adminView}&filterMonths=${params.months}&filterWeeks=${params.weeks}`,
        successAction: IN_FULL_KPI_DATA_SUCCESS,
        failureAction: IN_FULL_KPI_FAILURE,
      },
    });
  },
  getInFullDashboardFailedItems: (params = { customerGroups: '', stores: '', adminView: false, month: '', week: '', group: '', subGroup: '' }) => (dispatch) => {
    dispatch({
      type: FAILEDITEMS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/KPI/v1/GetDetail?filterCustomerGroups=${params.customerGroups
        }&filterStores=${params.stores
        }&adminView=${params.adminView
        }${params.month ? '&filterMonth=' + params.month : ''
        }${params.week ? '&filterWeek=' + params.week : ''
        }${params.group ? '&filterGroup=' + params.group : ''
        }${params.subCustomerGroup ? '&filterSubGroup=' + params.subCustomerGroup : ''}`,
        successAction: FAILEDITEMS_SUCCESS,
        failureAction: IN_FULL_KPI_FAILURE,
      },
    });
  },
  getStoreGroups: () => (dispatch) => {
    dispatch({
      type: '',
      http: {
        verb: 'GET',
        endpoint: '/api/storegroup/v1/GetUserStoreGroups',
        successAction: GET_STORE_GROUPS_SUCCESS,
        failureAction: IN_FULL_KPI_FAILURE,
      },
    });
  },
  getStores: () => (dispatch) => {
    dispatch({
      type: '',
      http: {
        verb: 'GET',
        endpoint: '/api/store/v1/getstores',
        successAction: GET_STORES_SUCCESS,
        failureAction: IN_FULL_KPI_FAILURE,
      },
    });
  },
  getImport: () => (dispatch) => {
    dispatch({
      type: IN_FULL_KPI_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/kpi/v1/getlatestimport',
        successAction: GET_LATEST_IMPORT_SUCCESS,
        failureAction: IN_FULL_KPI_FAILURE,
      },
    });
  },
  getKpiConfigurations: () => (dispatch) => {
    dispatch({
      type: IN_FULL_KPI_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/kpi/v1/GetConfig',
        successAction: GET_KPI_CONFIGURATIONS_SUCCESS,
        failureAction: IN_FULL_KPI_FAILURE,
      },
    });
  },
  updateKpiConfigurations: (params) => (dispatch) => {
    dispatch({
      type: IN_FULL_KPI_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/kpi/v1/UpdateConfig',
        successAction: UPDATE_KPI_CONFIGURATIONS_SUCCESS,
        failureAction: IN_FULL_KPI_FAILURE,
        body: params,
      },
    });
  },
};

export default kpiDashboardActions;
