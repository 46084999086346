import {
  CONNECT_EVENTS_REQUEST,
  CONNECT_EVENTS_SUCCESS,
  CONNECT_EVENTS_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const ConnectEventsReducer = (
  state = initialState.connectEventsState,
  action,
) => {
  switch (action.type) {
  case CONNECT_EVENTS_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      error: undefined,
      events: {},
    };

  case CONNECT_EVENTS_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: undefined,
      events: action.payload,
    };

  case CONNECT_EVENTS_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: action.payload.message,
      events: {},
    };

  default:
    return state;
  }
};
export default ConnectEventsReducer;
