import {
  CONNECT_ASSETCATEGORIES_REQUEST,
  CONNECT_ASSETCATEGORIES_SUCCESS,
  CONNECT_ASSETCATEGORIES_FAILURE,
  CONNECT_ASSETCATEGORYSAVE_SUCCESS,
  CONNECT_ASSETCATEGORYSAVE_FAILURE,
  CONNECT_ASSETCATEGORYSAVE_REQUEST,
  CONNECT_ASSETCATEGORYDELETE_REQUEST,
  CONNECT_ASSETCATEGORYDELETE_SUCCESS,
  CONNECT_ASSETCATEGORYDELETE_FAILURE,
  CONNECT_ASSETS_REQUEST,
  CONNECT_ASSETS_SUCCESS,
  CONNECT_ASSETS_FAILURE,
  CONNECT_ASSETSAVE_REQUEST,
  CONNECT_ASSETSAVE_SUCCESS,
  CONNECT_ASSETSAVE_FAILURE,
  CONNECT_ASSETMOVE_REQUEST,
  CONNECT_ASSETMOVE_SUCCESS,
  CONNECT_ASSETMOVE_FAILURE,
  CONNECT_ASSETDELETE_REQUEST,
  CONNECT_ASSETDELETE_SUCCESS,
  CONNECT_ASSETDELETE_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const connectAssetManagementReducer = (
  state = initialState.connectAssetManagementState,
  action,
) => {
  switch (action.type) {
  case CONNECT_ASSETS_REQUEST: {
    return {
      ...state,
      isLoadingAssets: true,
      isLoadedAssets: false,
      error: undefined,
      assetList: [],
    };
  }
  case CONNECT_ASSETS_SUCCESS: {
    return {
      ...state,
      isLoadingAssets: false,
      isLoadedAssets: true,
      error: undefined,
      assetList: action.payload,
    };
  }
  case CONNECT_ASSETS_FAILURE: {
    return {
      ...state,
      isLoadingAssets: false,
      isLoadedAssets: false,
      error: action.payload.message,
      assetList: [],
    };
  }
  case CONNECT_ASSETCATEGORIES_REQUEST: {
    return {
      ...state,
      isLoadingCategories: true,
      isLoadedCategory: false,
      error: undefined,
      assetCategoryList: [],
    };
  }
  case CONNECT_ASSETCATEGORIES_SUCCESS: {
    return {
      ...state,
      isLoadingCategories: false,
      isLoadedCategory: true,
      error: undefined,
      assetCategoryList: action.payload,
    };
  }
  case CONNECT_ASSETCATEGORIES_FAILURE: {
    return {
      ...state,
      isLoadingCategories: false,
      isLoadedCategory: false,
      error: action.payload.message,
      assetCategoryList: [],
    };
  }
  case CONNECT_ASSETCATEGORYSAVE_REQUEST:
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      error: undefined,
    };
  case CONNECT_ASSETCATEGORYSAVE_SUCCESS:
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      error: undefined,
    };
  case CONNECT_ASSETCATEGORYSAVE_FAILURE:
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      error: action.payload.message,
    };
  case CONNECT_ASSETSAVE_REQUEST:
  case CONNECT_ASSETMOVE_REQUEST: 
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      error: undefined,
    };
  case CONNECT_ASSETSAVE_SUCCESS:
  case CONNECT_ASSETMOVE_SUCCESS: 
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      error: undefined,
    };
  case CONNECT_ASSETSAVE_FAILURE:
  case CONNECT_ASSETMOVE_FAILURE:
  {
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      error: action.payload.message,
    };
  }
  case CONNECT_ASSETCATEGORYDELETE_REQUEST: {
    return {
      ...state,
      isDeleting: true,
      isDeleted: false,
      error: undefined,
    };
  }
  case CONNECT_ASSETCATEGORYDELETE_SUCCESS: {
    return {
      ...state,
      isDeleting: false,
      isDeleted: true,
      error: undefined,
    };
  }
  case CONNECT_ASSETCATEGORYDELETE_FAILURE: {
    return {
      ...state,
      isDeleting: false,
      isDeleted: false,
      error: action.payload.message,
    };
  }
  case CONNECT_ASSETDELETE_REQUEST: {
    return {
      ...state,
      isDeleting: true,
      isDeleted: false,
      error: undefined,
    };
  }
  case CONNECT_ASSETDELETE_SUCCESS: {
    return {
      ...state,
      isDeleting: false,
      isDeleted: true,
      error: undefined,
    };
  }
  case CONNECT_ASSETDELETE_FAILURE: {
    return {
      ...state,
      isDeleting: false,
      isDeleted: false,
      error: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default connectAssetManagementReducer;
