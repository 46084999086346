import {
  EMAILDOCUMENTENTRIES_REQUEST,
  EMAILDOCUMENTENTRIES_SUCCESS,
  EMAILDOCUMENTENTRIES_FAILURE,
  EMAILDOCUMENTENTRY_REQUEST,
  EMAILDOCUMENTENTRY_SUCCESS,
  EMAILDOCUMENTENTRY_FAILURE,
  SAVEEMAILDOCUMENTENTRY_REQUEST,
  SAVEEMAILDOCUMENTENTRY_SUCCESS,
  SAVEEMAILDOCUMENTENTRY_FAILURE,
  DELETEEMAILDOCUMENTENTRY_REQUEST,
  DELETEEMAILDOCUMENTENTRY_SUCCESS,
  DELETEEMAILDOCUMENTENTRY_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const emailDocumentManagementReducer = (
  state = initialState.emailDocumentManagementState,
  action,
) => {
  switch (action.type) {
  case EMAILDOCUMENTENTRIES_REQUEST:
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      errorMessage: '',
      entries: [],
    };
  case EMAILDOCUMENTENTRIES_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      errorMessage: '',
      entries: action.payload,
    };
  case EMAILDOCUMENTENTRIES_FAILURE:
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      errorMessage: action.payload.message,
      entries: [],
    };
  case EMAILDOCUMENTENTRY_REQUEST:
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      errorMessage: '',
      entry: {},
      folders:[],
    };
  case EMAILDOCUMENTENTRY_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      errorMessage: '',
      entry: action.payload.entry,
      folders: action.payload.folders,
    };
  case EMAILDOCUMENTENTRY_FAILURE:
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      errorMessage: action.payload.message,
      entry: {},
      folders:[],
    };
  case SAVEEMAILDOCUMENTENTRY_REQUEST:
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      errorMessage: '',
    };
  case SAVEEMAILDOCUMENTENTRY_SUCCESS:
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      errorMessage: '',
    };
  case SAVEEMAILDOCUMENTENTRY_FAILURE:
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      errorMessage: action.payload.message,
    };
  case DELETEEMAILDOCUMENTENTRY_REQUEST:
    return {
      ...state,
      isDeleting: true,
      isDeleted: false,
      errorMessage: '',
    };
  case DELETEEMAILDOCUMENTENTRY_SUCCESS:
    return {
      ...state,
      isDeleting: false,
      isDeleted: true,
      errorMessage: '',
    };
  case DELETEEMAILDOCUMENTENTRY_FAILURE:
    return {
      ...state,
      isDeleting: false,
      isDeleted: false,
      errorMessage: action.payload.message,
    };

  default:
    return state;
  }
};
export default emailDocumentManagementReducer;
