import {
  LSPOS_ITEMCATALOGUE_REQUEST,
  LSPOS_ITEMCATALOGUE_SUCCESS,
  LSPOS_ITEMCATALOGUE_FAILURE,
  LSPOS_ITEMCATALOGUE_DETAIL_REQUEST,
  LSPOS_ITEMCATALOGUE_DETAIL_SUCCESS,
  LSPOS_ITEMCATALOGUE_DETAIL_FAILURE,
  LSPOS_ITEMCATALOGUE_SAVE_REQUEST,
  LSPOS_ITEMCATALOGUE_SAVE_SUCCESS,
  LSPOS_ITEMCATALOGUE_SAVE_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const PosItemCatalogueReducer = (
  state = initialState.posItemCatalogueState,
  action = {},
) => {
  switch (action.type) {
  case  LSPOS_ITEMCATALOGUE_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
      catalogueItemList: [],
      errorMessage: '',
    };
  }
  case LSPOS_ITEMCATALOGUE_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      totalRecords: action.payload.totalRecords,
      catalogueItemList: action.payload.data,
      errorMessage: '',
    };
  }
  case  LSPOS_ITEMCATALOGUE_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
      catalogueItemList: [],
      errorMessage: action.payload.message,
    };
  }
  case LSPOS_ITEMCATALOGUE_DETAIL_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      catalogueItemDetail: {},
      uoms:[],
      errorMessage: '',
    };
  }
  case LSPOS_ITEMCATALOGUE_DETAIL_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      catalogueItemDetail: action.payload.itemDetail,
      uoms: action.payload.uom,
      errorMessage: '',
    };
  }
  case LSPOS_ITEMCATALOGUE_DETAIL_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      catalogueItemDetail: {},
      uoms:[],
      errorMessage: action.payload.message,
    };
  }
  case LSPOS_ITEMCATALOGUE_SAVE_REQUEST: {
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      errorMessage: '',
    };
  }
  case LSPOS_ITEMCATALOGUE_SAVE_SUCCESS: {
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      errorMessage: '',
    };
  }
  case LSPOS_ITEMCATALOGUE_SAVE_FAILURE: {
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};

export default PosItemCatalogueReducer;
