import React from 'react';
import { navigationActions } from '../../../actions/navigationActions';
import { posMdioRequestActions } from '../../../actions/posMdioRequestActions';
import { connect } from 'react-redux';

class PendingMdioDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posItemId: undefined,
      sbuxItemNumber:  '',
      sbuxItemDescription: '',
      showValidation:false,
    };

    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);  
  } 

  componentDidMount(){
    const {
      getItemDetails,
      match,
    } = this.props;
    const { posItemId } = match.params;
 
    getItemDetails(posItemId);
  }

  componentDidUpdate(prevProps){
    if(this.props.isLoaded && !prevProps.isLoaded){
      this.setState({
        posItemId: this.props.mdioDetail.id,
        sbuxItemNumber:  this.props.mdioDetail.sbuxItemNumber,
        sbuxItemDescription: this.props.mdioDetail.sbuxItemDescription,
      });
    }

    if(this.props.isSaved && !prevProps.isSaved){
      this.props.goBack();
    }
  }


  handleInput(e, fieldName){
    this.setState({ [fieldName]: e.target.value });
  } 

  handleSave(){
    const { posItemId, sbuxItemNumber,sbuxItemDescription } = this.state;
    const { submitForm } = this.props;

    if(!sbuxItemNumber || !sbuxItemDescription){
      this.setState({ showValidation: true });
    }else{
      submitForm({ id: posItemId, sbuxItemNumber,sbuxItemDescription });
    }
  }

  render() {
    const { mdioDetail,  isSaving } = this.props;
    const { sbuxItemNumber, sbuxItemDescription, showValidation } = this.state;
    const { handleSave } = this;

    return (
      <div className="MDIO-item-number page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-form" />
              <h2>Starbucks Item Number MDIO PIM Update</h2>
            </div>
          </div>
        </div>
        <form className="form-container">
          <div className="form-header">
            <p>This item information has been submitted by the customer.</p>
          </div>

          <div className="form-body">

            <label>Customer Item Number
              <input type="text"
                disabled
                value={mdioDetail.customerItemNumber}
                readOnly />
            </label>
            <label>Customer Item Description
              <input type="text"
                disabled
                value={mdioDetail.customerItemDescription}
                readOnly />
            </label>
            <label>GTIN If known
              <input type="text"
                disabled
                value={mdioDetail.gtin}
                readOnly />
            </label>
            <label>Primary UOM
              <input type="text"
                disabled
                value={mdioDetail.primaryUom}
                readOnly />
            </label>
            <label>Product Platform
              <input type="text"
                disabled
                value={mdioDetail.productPlatform}
                readOnly />
            </label>
            <label>Flavour
              <input type="text"
                disabled
                value={mdioDetail.flavour}
                readOnly />
            </label>
            <label>Cocoa
              <input type="text"
                disabled
                value={mdioDetail.cacao}
                readOnly />
            </label>
             
          </div>
            
        </form>
        <form className="form-container">
          <div className="form-header">
            <p>These attributes should be left as defaults when creating the item.</p>
          </div>
          <div className="form-body">
            <label>Lifecycle Type
              <input type="text"
                disabled
                value="Consumer Sellable"
                readOnly />
            </label>
            <label>Default UOM
              <input type="text"
                disabled
                value="EA"
                readOnly />
            </label>
            <label>Pick Pack UOM
              <input type="text"
                disabled
                value="EA"
                readOnly />
            </label>
            <label>Package Size
              <input type="text"
                disabled
                value="One"
                readOnly />
            </label>
            <label>Brand ID
              <input type="text"
                disabled
                value="Starbucks"
                readOnly />
            </label>
            <label>Product Configuration
              <input type="text"
                disabled
                value="Dummy Item"
                readOnly />
            </label>
            <label>Language ID
              <input type="text"
                disabled
                value="English"
                readOnly />
            </label>
            <label>GMO
              <input type="text"
                disabled
                value="No"
                readOnly />
            </label>
            <label>SKU Type
              <input type="text"
                disabled
                value="Standard Item"
                readOnly />
            </label>
            <label>Trademark Flag
              <input type="text"
                disabled
                value="No"
                readOnly />
            </label>
            <label>Shelf Life Code
              <input type="text"
                disabled
                value="No Shelf Life Control"
                readOnly />
            </label>
            <label>Shelf Life Days
              <input type="text"
                disabled
                value="0"
                readOnly />
            </label>
            <label>Shelf Life Days
              <input type="text"
                disabled
                value="0000.NONE.NONE.NONE - NA"
                readOnly />
            </label>
            <label>Inventory SBUX
              <input type="text"
                disabled
                value="PKFO.NONE - PACKAGED - FOODS "
                readOnly />
            </label>
            <label>Purchasing SBUX
              <input type="text"
                disabled
                value="5019.250200 - FOOD CHILLED"
                readOnly />
            </label>
            <label>Product Tax SBUX
              <input type="text"
                disabled
                value="VAT.FOODDRK1 - Food and Drinks"
                readOnly />
            </label>
            <label>Status
              <input type="text"
                disabled
                value="PLANNING"
                readOnly />
            </label>
          </div>
        </form>
        <form className="form-container">
          <div className="form-header">
            <p>Please use the market EXP ORG.</p>
          </div>
          <div className="form-body">
            <label>Customer Market
              <input type="text"
                disabled
                value="UK"
                readOnly />                
            </label>
            <label>ORG
              <input type="text"
                disabled
                value="ZP4"
                readOnly />           
            </label>
            <label>JDH Hierarchy SBUX
              <input type="text"
                disabled
                value="Select in PIM"
                readOnly />           
            </label>
                
          </div>   
        </form>
        <form className="form-container">  
          <div className="form-header">
            <p>Please submit to notify the customer.</p>
          </div>
          <div className="form-body">
            <label>Starbucks Item Number
              <input type="text"
                className={showValidation && !sbuxItemNumber ? 'invalid' : ''}
                value={sbuxItemNumber}
                onChange={(e) => this.handleInput(e, 'sbuxItemNumber')}
              />
            </label>
            <label>Starbucks Item Description
              <input type="text"
                className={showValidation && !sbuxItemDescription ? 'invalid' : ''}
                value={sbuxItemDescription}
                onChange={(e) => this.handleInput(e, 'sbuxItemDescription' )}
              />
            </label>
          </div>
          <div className="form-body">

            <div className="button-container">
              <button 
                className="action"
                type="button"
                disabled={isSaving}
                onClick={() => handleSave() }
              >
                {isSaving
                  ? 'Submitting...'
                  : 'Submit'}
                
              </button>
            </div> 
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.lsPosMdio.isFetching,
  isLoaded: state.lsPosMdio.isLoaded,
  isSaved: state.lsPosMdio.isSaved,
  isSaving: state.lsPosMdio.isSaving,
  stores: state.auth.stores,
  mdioDetail: state.lsPosMdio.mdioDetail,
});

const mapDispatchToProps = dispatch => {
  return {
    getItemDetails: (itemId) =>
      dispatch(
        posMdioRequestActions.requestItemDetail(
          itemId, 
        ),
      ),
    submitForm: form => dispatch(posMdioRequestActions.submitForm(form)),

    
    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PendingMdioDetail);
