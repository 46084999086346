import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { userManagementActions } from '../../../actions/userManagementActions';
import DeleteModal from '../../shared/DeleteModal';

class EditModuleGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupId: null,
      groupName: '',
      moduleList: [],
      userList: [],

      modules: [],
      users: [],

      userFilter: '',

      detailsExpanded: true,
      modulesExpanded: false,
      usersExpanded: false,

      showDeleteModal: false,
    };

    this.handleSaveGroup = this.handleSaveGroup.bind(this);
    this.handleDeleteGroup = this.handleDeleteGroup.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);

    this.handleToggleExpander = this.handleToggleExpander.bind(this);

    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);

    this.handleCheckInputUpdateMultipleUsers = this.handleCheckInputUpdateMultipleUsers.bind(
      this,
    );
  }

  componentDidMount() {
    let groupId = this.props.match.params.groupId;
    if (parseInt(groupId)) {
      this.props.getModuleGroup(groupId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { pathname } = this.props.location;
    if (this.props.groupLoaded && !prevProps.groupLoaded) {
      let { moduleGroup, modules, users } = this.props.groupDetails;
      let topLevel = modules.filter(m => m.parentModuleId === null);

      let moduleList = [
        ...moduleGroup.moduleList,
        ...modules.filter(m => m.mandatory).map(m => m.moduleId),
      ];

      this.setState({
        groupId: moduleGroup.moduleGroupId,
        groupName: moduleGroup.moduleGroupName,
        userList: moduleGroup.userList,
        moduleList,

        moduleStructure: this.generateModuleList(
          modules,
          moduleList,
          topLevel,
          1,
        ),

        modules,
        users,
      });
    } else if (this.props.groupSaved && !prevProps.groupSaved) {
      let group = this.props.groupDetails.moduleGroup;

      if (
        (group.moduleGroupId && !prevProps.groupDetails.moduleGroup) ||
        group.moduleGroupId !== prevProps.groupDetails.moduleGroup.moduleGroupId
      ) {
        this.props.replace(`${pathname}${group.moduleGroupId}`);
        this.props.getModuleGroup(group.moduleGroupId);
      } else {
        this.props.goBack();
      }
    } else if (this.props.groupDeleted && !prevProps.groupDeleted) {
      this.props.goBack();
    }
  }

  handleCheckInputUpdateMultipleUsers(e) {
    let { userList, users, userFilter } = this.state;

    let listValues = users
      .filter(
        s => s.userName.toLowerCase().indexOf(userFilter.toLowerCase()) > -1,
      )
      .map(s => s.userId);

    this.setState({ userList: [...userList, ...listValues] });
  }

  handleSaveGroup(e) {
    let { saveModuleGroup } = this.props;
    let { groupId, groupName, userList, moduleList } = this.state;

    saveModuleGroup({
      moduleGroupId: groupId,
      moduleGroupName: groupName,
      userList,
      moduleList,
    });

    e.preventDefault();
  }

  handleDeleteModalShow() {
    this.setState({ showDeleteModal: true });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false });
  }

  handleToggleExpander(expanderName) {
    let state = this.state;
    this.setState({ [expanderName]: !state[expanderName] });
  }

  handleDeleteGroup() {
    this.props.deleteModuleGroup(this.state.groupId);
    this.handleDeleteModalHide();
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  handleCheckInputUpdate(e, listName, listValueId, mandatory) {
    if (mandatory) return false;

    let listIds = this.state[listName];

    if (e.target.checked) {
      if (listIds.indexOf(listValueId) === -1) {
        listIds.push(listValueId);
      }
    } else {
      let pos = listIds.indexOf(listValueId);
      if (pos !== -1) {
        listIds.splice(pos, 1);
      }
    }

    let newState = {};

    newState[listName] = listIds;

    this.setState(newState);
  }

  handleHierarchyCheckInputUpdate(e, moduleId, path) {
    let { moduleList, moduleStructure } = this.state;
    let { checked } = e.target;
    let newModules = [];
    let oldModules = [];

    //Get currentModule and keep track of parents
    var modulePath = [];
    var currentModule = moduleStructure[path[0]];
    modulePath.push(currentModule);
    for (let i = 1; i < path.length; i++) {
      currentModule = currentModule.children[path[i]];
      modulePath.push(currentModule);
    }

    if (checked) {
      //check parents are selected
      for (let i = 0; i < modulePath.length; i++) {
        modulePath[i].selected = true;
        newModules.push(modulePath[i].moduleId);
      }

      //select children
      let addedModules = this.updateAllChildCheckInput(currentModule, checked);
      newModules = [...newModules, ...addedModules];
    } else {
      //Deselect clicked option
      currentModule.selected = false || currentModule.mandatory;
      oldModules.push(currentModule.moduleId);
      //Deselect children
      let removedModules = this.updateAllChildCheckInput(
        currentModule,
        checked,
      );
      oldModules = [...oldModules, ...removedModules];
    }

    for (let i = 0; i < newModules.length; i++) {
      if (moduleList.indexOf(newModules[i]) === -1) {
        moduleList.push(newModules[i]);
      }
    }
    for (let i = 0; i < oldModules.length; i++) {
      let pos = moduleList.indexOf(oldModules[i]);
      if (pos !== -1) {
        moduleList.splice(pos, 1);
      }
    }

    this.setState({ moduleList, moduleStructure });
  }

  updateAllChildCheckInput(currentModule, checked) {
    let updatedModules = [];
    for (let i = 0; i < currentModule.children.length; i++) {
      let nestedModule = currentModule.children[i];
      nestedModule.selected = checked || nestedModule.mandatory;
      updatedModules.push(nestedModule.moduleId);

      if (nestedModule.children && nestedModule.children.length > 0) {
        var additionalModules = this.updateAllChildCheckInput(
          nestedModule,
          checked,
        );
        updatedModules = [...updatedModules, ...additionalModules];
      }
    }
    return updatedModules;
  }

  getGroupPanel() {
    let { groupName, detailsExpanded } = this.state;

    return (
      <div className="group-details accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('detailsExpanded')}>
          <h3>Group Details</h3>
          <div
            className={`icon ${
              detailsExpanded ? 'icon-expand' : 'icon-collapse'
            }`}></div>
        </div>
        <div className={`accordion-body ${detailsExpanded ? '' : 'collapsed'}`}>
          <label>
            Group Name
            <input
              autoFocus
              placeholder="Group Name"
              onChange={e => this.handleTextInputUpdate(e, 'groupName')}
              value={groupName}
            />
          </label>
        </div>
      </div>
    );
  }

  getUsersPanel() {
    let { users, userList, usersExpanded, userFilter } = this.state;

    let filteredUsers = users.filter(
      s => s.userName.toLowerCase().indexOf(userFilter.toLowerCase()) > -1,
    );

    let selectedUsers = users.filter(g => userList.indexOf(g.userId) >= 0);

    return (
      <div className="group-users accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('usersExpanded')}>
          <h3>Users</h3>
          <div
            className={`icon ${
              usersExpanded ? 'icon-expand' : 'icon-collapse'
            }`}></div>
        </div>
        <div className={`accordion-body ${usersExpanded ? '' : 'collapsed'}`}>
          <div className="horizontal-flex-even">
            <div>
              <h3>
                All Users (Filtering {filteredUsers.length} of {users.length})
              </h3>
              <div className="floating-header">
                <input
                  placeholder="Filter..."
                  value={userFilter}
                  onChange={e => this.handleTextInputUpdate(e, 'userFilter')}
                />
                <button
                  type="button"
                  className="action"
                  onClick={e => this.handleCheckInputUpdateMultipleUsers(e)}>
                  Select All
                </button>
              </div>
              {filteredUsers.length > 50 && <h4>Too many results</h4>}
              {filteredUsers.length <= 50 &&
                filteredUsers.map(g => (
                  <label key={g.storeId}
                    className="wrapping">
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={userList.indexOf(g.userId) >= 0}
                        onChange={e =>
                          this.handleCheckInputUpdate(e, 'userList', g.userId)
                        }
                      />
                      <div className="toggle-icon"></div>
                    </div>
                    <span>{g.userName}</span>
                  </label>
                ))}
            </div>
            <div>
              <h3>Selected Users ({selectedUsers.length})</h3>
              {selectedUsers.map(g => (
                <label key={g.storeId}
                  className="wrapping">
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      checked={true}
                      onChange={e =>
                        this.handleCheckInputUpdate(e, 'userList', g.userId)
                      }
                    />
                    <div className="toggle-icon"></div>
                  </div>
                  <span>{g.userName}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  generateModuleList(modules, moduleList, currentLevelModules, level) {
    return currentLevelModules.map(m => {
      let nextLevel = modules.filter(o => o.parentModuleId === m.moduleId);
      let childItems = this.generateModuleList(
        modules,
        moduleList,
        nextLevel,
        level + 1,
      );

      return {
        moduleId: m.moduleId,
        mandatory: m.mandatory,
        moduleName: m.moduleName,
        selected: moduleList.indexOf(m.moduleId) >= 0 || m.mandatory,
        children: childItems,
      };
    });
  }

  getModuleEntry(currentLevelModules, level, path) {
    if (currentLevelModules && currentLevelModules.length) {
      return (
        <ul className="module-list">
          {currentLevelModules.map((m, idx) => {
            let newPath = [...path, idx];
            let nextListItems = this.getModuleEntry(
              m.children,
              level + 1,
              newPath,
            );

            return (
              <Fragment key={m.moduleId}>
                <li>
                  <label className="wrapping">
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        checked={m.selected}
                        className="toggle"
                        onChange={e =>
                          this.handleHierarchyCheckInputUpdate(
                            e,
                            m.moduleId,
                            newPath,
                          )
                        }
                      />
                      <div className="toggle-icon"></div>
                    </div>

                    {level === 1 && <h4>{m.moduleName} Module</h4>}
                    {level > 1 && <h5>{m.moduleName} Module</h5>}
                  </label>
                </li>
                {nextListItems && <li>{nextListItems}</li>}
              </Fragment>
            );
          })}
        </ul>
      );
    } else {
      return null;
    }
  }

  getModulesPanel() {
    let { moduleStructure, modulesExpanded } = this.state;

    return (
      <div className="group-stores accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('modulesExpanded')}>
          <h3>Module Access</h3>
          <div
            className={`icon ${
              modulesExpanded ? 'icon-expand' : 'icon-collapse'
            }`}></div>
        </div>
        <div className={`accordion-body ${modulesExpanded ? '' : 'collapsed'}`}>
          {this.getModuleEntry(moduleStructure, 1, [])}
        </div>
      </div>
    );
  }

  render() {
    const { showDeleteModal } = this.state;
    return (
      <div className="edit-module-group-page page">
        <div className="floating-panel">
          <form noValidate
            onSubmit={e => this.handleSaveGroup(e)}>
            <div className="floating-header">
              <div className="header-title">
                <div className="icon icon-admin"></div>
                {this.state.groupId ? (
                  <h2>Edit Module Group</h2>
                ) : (
                  <h2>Create Module Group</h2>
                )}
              </div>
            </div>

            {this.getGroupPanel()}

            {this.state.groupId && this.getModulesPanel()}

            {this.state.groupId && this.getUsersPanel()}

            <div className="button-container">
              <button className="action">Save</button>
              {this.state.groupId && (
                <button
                  type="button"
                  className="action negative"
                  onClick={() => this.handleDeleteModalShow()}>
                  Delete
                </button>
              )}
            </div>
          </form>
          {showDeleteModal && (
            <DeleteModal
              cancel={this.handleDeleteModalHide}
              continue={this.handleDeleteGroup}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { currentModuleGroup } = state.userManagement;
  return {
    groupLoading: currentModuleGroup.isFetching,
    groupLoaded: currentModuleGroup.isLoaded,
    groupDetails: currentModuleGroup.groupDetails,
    groupSaving: currentModuleGroup.isSaving,
    groupSaved: currentModuleGroup.isSaved,
    groupDeleting: currentModuleGroup.isDeleted,
    groupDeleted: currentModuleGroup.isDeleted,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getModuleGroup: groupId =>
      dispatch(userManagementActions.requestModuleGroup(groupId)),
    saveModuleGroup: groupData =>
      dispatch(userManagementActions.saveModuleGroup(groupData)),
    deleteModuleGroup: groupId =>
      dispatch(userManagementActions.deleteModuleGroup(groupId)),
    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditModuleGroup);
