import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { notificationActions } from '../../../actions/notificationActions';
import DeleteModal from '../../shared/DeleteModal';

class MessageEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationId: null,
      title: '',
      body: '',
      actionLink: '',
      notificationType: 1,
      sentOn: new Date(),
      sentFrom: 'Admin',
      sentBy: '',
      validFrom: undefined,
      validTo: undefined,
      storeGroupIds: [],
      moduleGroupIds: [],

      moduleGroups: [],
      storeGroups: [],

      detailsExpanded: true,
      modulesExpanded: false,
      customerExpanded: false,

      showDeleteModal: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);

    this.handleToggleExpander = this.handleToggleExpander.bind(this);

    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);
  }

  componentDidMount() {
    let notificationId = this.props.match.params.notificationId;
    if (parseInt(notificationId)) {
      this.props.getAlert(notificationId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { pathname } = this.props.location;
    if (this.props.notificationLoaded && !prevProps.notificationLoaded) {
      let notification = this.props.notificationDetails.notification;
      let notificationDetails = this.props.notificationDetails;

      this.setState({
        notificationId: notification.notificationId,
        title: notification.title,
        body: notification.body,
        actionLink: notification.actionLink,
        notificationType: notification.notificationType,
        sentOn: notification.sentOn,
        sentFrom: notification.sentFrom,
        sentBy: notification.sentBy,
        validFrom: notification.validFrom
          ? notification.validFrom.slice(0,10)
          : undefined,
        validTo: notification.validTo
          ? notification.validTo.slice(0,10)
          : undefined,
        storeGroupIds: notification.notificationStoreGroupIds,
        moduleGroupIds: notification.notificationModuleGroupIds,

        moduleGroups: notificationDetails.moduleGroups,
        storeGroups: notificationDetails.storeGroups,
      });
    } else if (this.props.notificationSaved && !prevProps.notificationSaved) {
      if (
        (this.props.notificationDetails.notification.notificationId &&
          !prevProps.notificationDetails.notification) ||
        this.props.notificationDetails.notification.notificationId !==
          prevProps.notificationDetails.notification.notificationId
      ) {
        this.props.replace(
          `${pathname}${this.props.notificationDetails.notification.notificationId}`,
        );
        this.props.getAlert(
          this.props.notificationDetails.notification.notificationId,
        );
      } else {
        this.props.goBack();
      }
    } else if (
      this.props.notificationDeleted &&
      !prevProps.notificationDeleted
    ) {
      this.props.goBack();
    }
  }

  handleSave(e) {
    let { saveAlert } = this.props;
    let {
      notificationId,
      title,
      body,
      actionLink,
      notificationType,
      sentFrom,
      validFrom,
      validTo,
      storeGroupIds,
      moduleGroupIds,
    } = this.state;

    saveAlert({
      notificationId,
      title,
      body,
      actionLink,
      notificationType,
      sentFrom,
      validFrom,
      validTo,
      storeGroupIds,
      moduleGroupIds,
      storeIds: [],
    });

    e.preventDefault();
  }

  handleDeleteModalShow() {
    this.setState({ showDeleteModal: true });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false });
  }

  handleToggleExpander(expanderName) {
    let state = this.state;
    this.setState({ [expanderName]: !state[expanderName] });
  }

  handleDelete() {
    this.props.deleteAlert(this.state.notificationId);
    this.handleDeleteModalHide();
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  handleCheckInputUpdate(e, name, id) {
    let ids = this.state[name];

    if (e.target.checked) {
      if (ids.indexOf(id) === -1) {
        ids.push(id);
      }
    } else {
      let pos = ids.indexOf(id);
      if (pos !== -1) {
        ids.splice(pos, 1);
      }
    }

    this.setState({
      [name]: ids,
    });
  }

  generateMenuHierarchy(modules) {
    //TODO make tree structure

    return modules.map(m => <div key={m.moduleId}>{m.moduleName}</div>);
  }

  getAlertPanel() {
    let {
      title,
      body,
      actionLink,
      validFrom,
      validTo,
      detailsExpanded,
      sentFrom,
    } = this.state;

    return (
      <div className="accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('detailsExpanded')}>
          <h3>Alert</h3>
          <div
            className={`icon ${
              detailsExpanded ? 'icon-expand' : 'icon-collapse'
            }`}></div>
        </div>
        <div className={`accordion-body ${detailsExpanded ? '' : 'collapsed'}`}>
          <div className="horizontal-layout">
            <div className="vertical-layout">
              <label>
                Title
                <input
                  placeholder="Title"
                  onChange={e => this.handleTextInputUpdate(e, 'title')}
                  value={title}
                  maxLength={256}
                />
              </label>
              <label>
                Module
                <select
                  placeholder="sentFrom"
                  onChange={e => this.handleTextInputUpdate(e, 'sentFrom')}
                  value={sentFrom}>
                  <option value="Admin">Admin</option>
                  <option value="Order Management">Order Management</option>
                  <option value="Customer Service">Customer Service</option>
                  <option value="Item Catalogue">Item Catalogue</option>
                  <option value="Document Library">Document Library</option>
                  <option value="Quality Awareness">Quality Assurance</option>
                  <option value="Pricing Catalogue">Pricing Catalogue</option>
                  <option value="Forecasting">Forecasting</option>
                  <option value="Orderly Inventory">Orderly Inventory</option>
                  <option value="Reports">Reports</option>
                  <option value="Policies">Policies</option>
                </select>
              </label>
              <label>
                Message
                <textarea
                  placeholder="Message"
                  onChange={e => this.handleTextInputUpdate(e, 'body')}
                  value={body}
                  maxLength={2000}
                />
              </label>
            </div>
            <div className="vertical-layout">
              <label>
                Action Link
                <input
                  placeholder="Action Link"
                  onChange={e => this.handleTextInputUpdate(e, 'actionLink')}
                  value={actionLink}
                />
              </label>
              <label>
                Valid From
                <input
                  placeholder="Valid From"
                  type="date"
                  onChange={e => this.handleTextInputUpdate(e, 'validFrom')}
                  value={validFrom}
                />
              </label>
              <label>
                Valid To
                <input
                  placeholder="Valid To"
                  type="date"
                  onChange={e => this.handleTextInputUpdate(e, 'validTo')}
                  value={validTo}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getStoreGroupsPanel() {
    let { storeGroups, storeGroupIds, customerExpanded } = this.state;

    return (
      <div className="group-stores accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('customerExpanded')}>
          <h3>Customer Access Configuration </h3>
          <div
            className={`icon ${
              customerExpanded ? 'icon-expand' : 'icon-collapse'
            }`}></div>
        </div>
        <div
          className={`accordion-body ${customerExpanded ? '' : 'collapsed'}`}>
          {storeGroups.map(g => (
            <label key={g.storeGroupId}>
              <input
                type="checkbox"
                checked={storeGroupIds.indexOf(g.storeGroupId) >= 0}
                onChange={e =>
                  this.handleCheckInputUpdate(
                    e,
                    'storeGroupIds',
                    g.storeGroupId,
                  )
                }
              />
              {g.storeGroupName}
            </label>
          ))}
        </div>
      </div>
    );
  }

  getModuleGroupsPanel() {
    let { moduleGroups, moduleGroupIds, modulesExpanded } = this.state;

    return (
      <div className="group-stores accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('modulesExpanded')}>
          <h3>Instance Module Configuration</h3>
          <div
            className={`icon ${
              modulesExpanded ? 'icon-expand' : 'icon-collapse'
            }`}></div>
        </div>
        <div className={`accordion-body ${modulesExpanded ? '' : 'collapsed'}`}>
          {moduleGroups.map(g => (
            <label key={g.moduleGroupId}>
              <input
                type="checkbox"
                checked={moduleGroupIds.indexOf(g.moduleGroupId) >= 0}
                onChange={e =>
                  this.handleCheckInputUpdate(
                    e,
                    'moduleGroupIds',
                    g.moduleGroupId,
                  )
                }
              />
              {g.moduleGroupName}
            </label>
          ))}
        </div>
      </div>
    );
  }

  render() {
    let { showDeleteModal } = this.state;
    return (
      <div className="edit-message-page page">
        <div className="floating-panel">
          <form noValidate
            onSubmit={e => this.handleSave(e)}>
            <div className="floating-header">
              <div className="header-title">
                <div className="icon icon-alert"></div>
                {this.state.notificationId ? (
                  <h2>Edit Alert</h2>
                ) : (
                  <h2>Create Alert</h2>
                )}
              </div>
            </div>
            {this.getAlertPanel()}

            {this.state.notificationId && this.getModuleGroupsPanel()}
            {this.state.notificationId && this.getStoreGroupsPanel()}

            <div className="button-container">
              <button className="action">Save</button>
              {this.state.notificationId && (
                <button
                  type="button"
                  className="action negative"
                  onClick={() => this.handleDeleteModalShow()}>
                  Delete
                </button>
              )}
            </div>
          </form>
          {showDeleteModal && (
            <DeleteModal
              cancel={this.handleDeleteModalHide}
              continue={this.handleDelete}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    notificationLoading: state.notification.isFetching,
    notificationLoaded: state.notification.isLoaded,
    notificationDetails: state.notification.currentAdminAlert,
    notificationSaving: state.notification.isSaving,
    notificationSaved: state.notification.isSaved,
    notificationDeleting: state.notification.isDeleted,
    notificationDeleted: state.notification.isDeleted,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAlert: notificationId =>
      dispatch(notificationActions.requestAlert(notificationId)),
    saveAlert: notificationData =>
      dispatch(notificationActions.saveAlert(notificationData)),
    deleteAlert: notificationId =>
      dispatch(notificationActions.deleteAlert(notificationId)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessageEdit);
