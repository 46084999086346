import {
  LSPOS_MDIO_REQUEST,
  LSPOS_MDIO_SUCCESS,
  LSPOS_MDIO_FAILURE,
  LSPOS_MDIO_DETAIL_REQUEST,
  LSPOS_MDIO_DETAIL_SUCCESS,
  LSPOS_MDIO_DETAIL_FAILURE,
  LSPOS_MDIO_SAVE_REQUEST,
  LSPOS_MDIO_SAVE_SUCCESS,
  LSPOS_MDIO_SAVE_FAILURE,
  LSPOS_MDIO_BULKSAVE_REQUEST,
  LSPOS_MDIO_BULKSAVE_SUCCESS,
  LSPOS_MDIO_BULKSAVE_FAILURE,
} from './actionTypes';

export const posMdioRequestActions = {
  requestItems: (
    page, pageSize, filter, 
  ) => (dispatch) => {
    dispatch({
      type:  LSPOS_MDIO_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `api/LsPos/Mdio/v1/list?filter=${filter}&page=${page}&pageSize=${pageSize}`,
        successAction: LSPOS_MDIO_SUCCESS,
        failureAction:  LSPOS_MDIO_FAILURE,
      },
    });
  },
  requestItemDetail: (itemId) => (dispatch) => {
    dispatch({
      type: LSPOS_MDIO_DETAIL_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `api/LsPos/Mdio/v1/detail/${itemId}`,
        successAction: LSPOS_MDIO_DETAIL_SUCCESS,
        failureAction: LSPOS_MDIO_DETAIL_FAILURE,
      },
    });
  },
  submitForm: submitForm => (dispatch) => {
    dispatch({
      type: LSPOS_MDIO_SAVE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/lspos/Mdio/v1/Save',
        successAction: LSPOS_MDIO_SAVE_SUCCESS,
        failureAction: LSPOS_MDIO_SAVE_FAILURE,
        body: {
          ...submitForm,
        },
      },
    });
  },  
  submitBulkForm: submitBulkForm => (dispatch) => {
    dispatch({
      type: LSPOS_MDIO_BULKSAVE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/lspos/Mdio/v1/BulkSave',
        successAction: LSPOS_MDIO_BULKSAVE_SUCCESS,
        failureAction: LSPOS_MDIO_BULKSAVE_FAILURE,
        body: submitBulkForm,
      },
    });
  },  
};
