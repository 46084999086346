import {
  RSLPERFORMANCE_REQUEST,
  RSLPERFORMANCE_SUCCESS,
  RSLPERFORMANCE_FAILURE,
} from './actionTypes';

export const RslPerformanceActions = {
  requestRslPerformance: (month, page) => (dispatch, getState) => {
    dispatch({
      type: RSLPERFORMANCE_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/RslPerformance/v1/Get?month=${month}&page=${page}`,
        successAction: RSLPERFORMANCE_SUCCESS,
        failureAction: RSLPERFORMANCE_FAILURE,
      },
    });
  },
};
