import React from 'react';
import { connect } from 'react-redux';
import { connectEventsActions } from '../../../../actions/connectEventsActions';
import { navigationActions } from '../../../../actions/navigationActions';
import { sideBarActions } from '../../../../actions/sideBarActions';
import { authActions } from '../../../../actions/authActions';

class ConnectEvents extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      radius: 3,
    };
    
    this.handleUpdateRadius = this.handleUpdateRadius.bind(this);
  }
  
  componentDidMount() {
    this.props.getEvents();
  }

  handleUpdateRadius(e) {
    this.setState({
      radius: parseInt(e.target.value),
    });
  }

  formatTime(localTime, tbc) {

    let startTime = 'All Day';

    if (!tbc) {
      startTime = localTime.substr(0, 5);
    }

    return startTime;
  }

  renderEvents() {
    const { events } = this.props;
    const { radius } = this.state;

    if (events.page.totalElements === 0) {
      return (<div className="error-stack">
        <div>
          There are currently no events in your area.
        </div>
      </div>);
    }

    let eventList = events._embedded.events;

    let lastDate = undefined;
    let eventArray = [];

    let controls = eventList.filter(e => e.distance <= radius).map(e => {

      let formattedTime = this.formatTime(e.dates.start.localTime, e.dates.start.timeTBA || e.dates.start.noSpecificTime);

      let event = (<div className="event-instance"
        key={e.id}>
        <div className="start-time">{formattedTime}</div>
        <div className="event-details">
          <div title={`${e.classifications[0].segment.name} - ${e.classifications[0].genre.name}`}>{e.classifications[0].segment.name} - {e.classifications[0].genre.name}</div>
          <div title={e.name}>{e.name}</div>
          <div title={e._embedded.venues[0].name}>{e._embedded.venues[0].name}</div>
        </div>
        <div className="event-distance">{e.distance} miles</div>
      </div>);

      if (e.dates.start.localDate !== lastDate && lastDate !== undefined) {
        let dateBlock = (<div key={lastDate}
          className="date-block">
          <div className="date">{new Date(lastDate).toLocaleDateString()}</div>
          {eventArray}
        </div>);

        lastDate = e.dates.start.localDate;
        eventArray = [event];

        return dateBlock;

      } else {
        lastDate = e.dates.start.localDate;

        eventArray.push(event);

        return null;
      }
    });

    if (eventArray.length > 0) {
      controls.push((<div key={lastDate}
        className="date-block">
        <div className="date">{new Date(lastDate).toLocaleDateString()}</div>
        {eventArray}
      </div>));
    }

    return controls;
  }


  render() {
    const { events, error } = this.props;
    const { radius } = this.state;

    return (
      <div className="connect-events">
        <div className="event-stack">
          {!error && events && events.page ?
            <>
              <div className="radiuspanel">
                <label>
                    Search Radius ({radius.toFixed(1)} miles)
                  <input
                    type="range"
                    min="1"
                    max="10"
                    step="1"
                    value={radius }
                    onChange={e => this.handleUpdateRadius(e)}
                  />
                </label>
              </div>
              {this.renderEvents()}
            </>
            :
            <div className="error-stack">
              <div>
                Local events cannot be loaded for your current location. Please update your postcode in your account settings.
              </div>
              <button
                className="float"
                type="button"
                onClick={() => {
                  this.props.push('/myaccount');
                  this.props.hideConnect();
                  this.props.toggleSideBar(false);
                }}
              >
                Account Settings
              </button>
            </div>
          }
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  events: state.connectEvents.events,
  error: state.connectEvents.error,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    getEvents: () => dispatch(connectEventsActions.getEvents()),

    hideConnect: () => dispatch(authActions.hideConnect()),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),

    toggleSideBar: isOpen => dispatch(sideBarActions.toggleSideBar(isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectEvents);
