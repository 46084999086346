import React from 'react';

const CircularProgress = props => {
  return (
    <div className="set-size charts-container">
      <div
        className={`pie-wrapper progress-${Math.floor(props.percentage / 5) *
          5} style-2`}
      >
        <span className="label">
          {parseInt(props.percentage)}
          <span className="smaller">%</span>
        </span>
        <div className="pie">
          <div className="left-side half-circle"></div>
          <div className="right-side half-circle"></div>
        </div>
        <div className="shadow"></div>
      </div>
    </div>
  );
};

export default CircularProgress;
