import {
  DOCUMENTS_REQUEST,
  DOCUMENTS_SUCCESS,
  DOCUMENTS_FAILURE,
  SAVEFOLDER_REQUEST,
  SAVEFOLDER_SUCCESS,
  SAVEFOLDER_FAILURE,
  UPDATEFOLDER_REQUEST,
  UPDATEFOLDER_SUCCESS,
  UPDATEFOLDER_FAILURE,
  DELETEFOLDER_REQUEST,
  DELETEFOLDER_SUCCESS,
  DELETEFOLDER_FAILURE,
  CREATEDOC_REQUEST,
  CREATEDOC_SUCCESS,
  CREATEDOC_FAILURE,
  UPDATEDOC_REQUEST,
  UPDATEDOC_SUCCESS,
  UPDATEDOC_FAILURE,
  MOVEDOC_REQUEST,
  MOVEDOC_SUCCESS,
  MOVEDOC_FAILURE,
  DELETEDOC_REQUEST,
  DELETEDOC_SUCCESS,
  DELETEDOC_FAILURE,
} from './actionTypes';

export const documentActions = {
  requestDocuments: () => (dispatch, getState) => {
    dispatch({
      type: DOCUMENTS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/Documents/v1/GetDocuments',
        successAction: DOCUMENTS_SUCCESS,
        failureAction: DOCUMENTS_FAILURE,
      },
    });
  },
  saveFolder: request => (dispatch, getState) => {
    dispatch({
      type: SAVEFOLDER_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Documents/v1/SaveFolder',
        successAction: SAVEFOLDER_SUCCESS,
        failureAction: SAVEFOLDER_FAILURE,
        body: {
          ...request,
        },
      },
    });
  },
  updateFolder: request => (dispatch, getState) => {
    dispatch({
      type: UPDATEFOLDER_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Documents/v1/UpdateFolder',
        successAction: UPDATEFOLDER_SUCCESS,
        failureAction: UPDATEFOLDER_FAILURE,
        body: {
          ...request,
        },
      },
    });
  },
  deleteFolder: request => (dispatch, getState) => {
    dispatch({
      type: DELETEFOLDER_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: '/api/Documents/v1/DeleteFolder',
        successAction: DELETEFOLDER_SUCCESS,
        failureAction: DELETEFOLDER_FAILURE,
        body: {
          ...request,
        },
      },
    });
  },
  createDocument: request => (dispatch, getState) => {
    dispatch({
      type: CREATEDOC_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Documents/v1/CreateDocument',
        successAction: CREATEDOC_SUCCESS,
        failureAction: CREATEDOC_FAILURE,
        body: {
          ...request,
        },
      },
    });
  },
  updateDocument: request => (dispatch, getState) => {
    dispatch({
      type: UPDATEDOC_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Documents/v1/UpdateDocument',
        successAction: UPDATEDOC_SUCCESS,
        failureAction: UPDATEDOC_FAILURE,
        body: {
          ...request,
        },
      },
    });
  },
  moveDocument: request => (dispatch, getState) => {
    dispatch({
      type: MOVEDOC_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Documents/v1/MoveDocument',
        successAction: MOVEDOC_SUCCESS,
        failureAction: MOVEDOC_FAILURE,
        body: {
          ...request,
        },
      },
    });
  },
  deleteDocument: request => (dispatch, getState) => {
    dispatch({
      type: DELETEDOC_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: '/api/Documents/v1/DeleteDocument',
        successAction: DELETEDOC_SUCCESS,
        failureAction: DELETEDOC_FAILURE,
        body: {
          ...request,
        },
      },
    });
  },
};
