import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { Tooltip } from '@material-ui/core';

class TaskRowAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
    this.handleToggleExpander = this.handleToggleExpander.bind(this);
  }

  handleToggleExpander(expanderName) {
    let state = this.state;
    this.setState({ [expanderName]: !state[expanderName] });
  }

  render() {
    const taskDetail = this.props.data;
    const { isExpanded } = this.state;
    const { onEdit, onDelete, onReport, handleBulkEditChecked, selectedTasks, taskId } = this.props;

    const data = [
      { name: 'Completed', value: taskDetail.percentageCompleted * 10 },
      { name: 'Not Completed', value: 1000 - (taskDetail.percentageCompleted * 10) },
    ];
    var isGreen = taskDetail.percentageCompleted * 10 >= taskDetail.greenLowerLimit * 10;
    var isAmber = taskDetail.percentageCompleted * 10 < taskDetail.greenLowerLimit * 10 && taskDetail.percentageCompleted * 10 >= taskDetail.redUpperLimit * 10;
    var isRed = taskDetail.percentageCompleted * 10 < taskDetail.redUpperLimit * 10;
    const COLORS = [isGreen ? '#02a862' : isAmber ? '#faad00' : isRed ? '#ed4646' : '', '#D3D3D3'];
    return (
      <div className="accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('isExpanded')}>
          <div className='task-first-row-block'>
            <input type='checkbox' 
              checked={selectedTasks.indexOf(taskId) > -1}
              onClick={(e) => {
                e.stopPropagation();
                handleBulkEditChecked(e, taskId);
              }}
            />
            <h3>{taskDetail.title}</h3>
          </div>
          <div className="horizontal-flex task-admin">
            <button className="edit"
              type="button"
              onClick={(e) => {e.stopPropagation(); onEdit();}}>
              Edit
            </button>
            <button
              className="edit negative"
              type="button"
              onClick={(e) => {e.stopPropagation(); onDelete();}}>
              Delete
            </button>
            <button className="edit"
              type="button"
              onClick={(e) => {e.stopPropagation(); onReport();}}>
              Download Report
            </button>
            <Tooltip
              title={`Percentage Complete : ${taskDetail.percentageCompleted?.toString().substring(0,5)}%`}>
              <div>
                <PieChart width={100}
                  height={80}>
                  <Pie
                    data={data}
                    cx="50%"
                    cy="70%"
                    startAngle={180}
                    endAngle={0}
                    innerRadius={20}
                    outerRadius={30}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </div>
            </Tooltip>
            <div className="date-stamp">{`${new Date(
              taskDetail.sentOn + 'Z',
            ).toLocaleDateString()} ${new Date(
              taskDetail.sentOn + 'Z',
            ).toLocaleTimeString()}`}</div>
            <div
              className={`icon ${
                isExpanded ? 'icon-expand' : 'icon-collapse'
              }`}></div>
          </div>
        </div>
        <div className={`accordion-body ${isExpanded ? '' : 'collapsed'}`}>
          {taskDetail.body}
        </div>
      </div>
    );
  }
}

export default TaskRowAdmin;
