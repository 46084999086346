import React from 'react';
import {
  BarChart,
  Bar,
  Tooltip,
  YAxis,
  XAxis,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Cell,
} from 'recharts';

const KpiBarChart = (props) => {
  const {
    title,
    selectedWeek,
    barChartData,
    onBarchartClicked,
    target,
  } = props;

  const getDataNameFromLabel = (label) => {
    return barChartData.find(b => b.label === label).name;
  };

  const data = barChartData && barChartData.map((entity, index) => {
    return {
      colour: entity.percentage.colour,
      value: entity.percentage.value,
      label: entity.label,
      name: entity.name,
    };
  });
  const getBarStroke = (entry) => {
    let result = {
      color: '',
      width: 0,
    };
    if (selectedWeek) {
      if (entry.name === selectedWeek.name) {
        result = {
          color: 'yellow',
          width: 5,
        };
      }
    }
    return result;
  };
  return (
    <div className="percentage-barchart">
      {barChartData && barChartData.length > 0 &&
        <ResponsiveContainer width={'100%'}
          height={400}>
          <BarChart
            width={'100%'}
            height={'100%'}
            data={data}
            onClick={(data) => onBarchartClicked && data ? onBarchartClicked(getDataNameFromLabel(data.activeLabel)) : ''}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" />
            <YAxis label={{ value: '%', angle: -180, position: 'insideLeft' }}
              type="number"
              domain={[0, 100]}/> 
            <Tooltip formatter={(value) => { return [value, 'Percentage']; }} />
            <Bar dataKey="value">
              {
                data.map((entry, index) => (
                  <Cell
                    cursor="pointer"
                    fill={entry.colour}
                    key={`cell-${entry.name}${index}`}
                    stroke={getBarStroke(entry).color}
                    strokeWidth={getBarStroke(entry).width}
                  />
                ))
              }
            </Bar>
            <ReferenceLine 
              y={target} 
              stroke="red" 
              strokeDasharray="3 3" 
              ifOverflow="extendDomain" 
              label={{
                value: 'Starbucks’ Line Fill Target', 
                fill: '#666666',
                position: 'bottom',
                font: 'Roboto',
              }}/>
          </BarChart>
        </ResponsiveContainer>
      }
      {(!barChartData || barChartData.length <= 0) &&
        <p className="text-center">No bar chart data found.</p>
      }
      {barChartData && barChartData.length > 0 &&
        <p className="text-center">{title}</p>
      }
    </div>
  );
};

export default KpiBarChart;
