import { STORES_REQUEST, STORES_SUCCESS, STORES_FAILURE } from './actionTypes';

export const storeActions = {
  getStores: () => (dispatch, getState) => {
    dispatch({
      type: STORES_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/store/v1/GetStores',
        successAction: STORES_SUCCESS,
        failureAction: STORES_FAILURE,
      },
    });
  },
};
