import React from 'react';
import { connect } from 'react-redux';
import ApacContactDetails from './ContactDetails/apacContactDetails';
import EmeaContactDetails from './ContactDetails/emeaContactDetails';
import LacContactDetails from './ContactDetails/lacContactDetails';


class ContactDetails extends React.Component {
  render() {
    const {
      tenantKey,
    } = this.props;

    return (
      <div className="contact-detail-page page">
        <form
          className="form-container"
          noValidate
          onSubmit={e => this.handleSubmit(e)}>
          <div className="form-header">
            <div className="header-title">
              <div className="icon icon-customer-service"></div>
              <h2>Contact Details</h2>
            </div>
          </div>
          {tenantKey === 'EMEA' && <EmeaContactDetails />}
          {tenantKey === 'APAC' && <ApacContactDetails />}
          {tenantKey === 'LAC' && <LacContactDetails />}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tenantKey: state.auth.tenant,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactDetails);
