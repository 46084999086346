import {
  //Active messages
  CONNECTMESSAGING,
  DISCONNECTMESSAGING,
  FETCHCHATROOMS,
  //AGENTJOINCHATROOM,
  //USERJOINCHATROOM,
  //STARTCONVERSATION,
  SENDMESSAGE,
  //TERMINATECONVERSATION,
  //Passive Messages
  RECEIVECHATROOMS,
  HOSTINGCHATROOM,
  USERJOINEDQUEUE,
  QUEUEUPDATED,
  CONVERSATIONSTARTED,
  RECEIVEMESSAGE,
  CONVERSATIONTERMINATED,
} from '../actions/actionTypes';
import initialState from './initialState';

export const messageReducer = (state = initialState.messageState, action) => {
  const { currentChatRoom } = state;

  switch (action.type) {
  case CONNECTMESSAGING:
    return {
      ...state,
      connected: action.payload.connected,
    };

  case DISCONNECTMESSAGING:
    return {
      connected: false,
      chatRooms: [],
      currentChatRoom: {
        conversations: [],
        queue: [],
      },
    };

  case FETCHCHATROOMS:
    return {
      ...state,
      chatRooms: [],
    };

  case RECEIVECHATROOMS:

    const { chatRoomId: currentChatRoomId } = state.currentChatRoom;

    if (currentChatRoomId) {

      let updateCurrentChatRoom = action.payload.filter(
        c => c.chatRoomId === currentChatRoomId,
      )[0];

      if (!updateCurrentChatRoom || !updateCurrentChatRoom.active) {
        return {
          ...state,
          chatRooms: action.payload,
          currentChatRoom: {
            conversations: [],
            queue: [],
          },
        };
      }
    }

    return {
      ...state,
      chatRooms: action.payload,
    };

  case HOSTINGCHATROOM:
  case USERJOINEDQUEUE:
    let chatRoomId = action.payload.chatRoomId;

    let newCurrentChatRoom = state.chatRooms.filter(
      c => c.chatRoomId === chatRoomId,
    )[0];

    return {
      ...state,
      currentChatRoom: {
        ...newCurrentChatRoom,
        conversations: [],
        queue: [],
      },
    };
  case QUEUEUPDATED:
    return {
      ...state,
      currentChatRoom: {
        ...state.currentChatRoom,
        queue: action.payload,
      },
    };
  case CONVERSATIONSTARTED:
    return {
      ...state,
      currentChatRoom: {
        ...state.currentChatRoom,
        conversations: [
          ...state.currentChatRoom.conversations,
          { ...action.payload, messages: [] },
        ],
      },
    };
  case SENDMESSAGE:
    for (let i = 0; i < currentChatRoom.conversations.length; i++) {
      let conversation = currentChatRoom.conversations[i];

      if (conversation.conversationId === action.payload.conversationId) {
        conversation.messages.push({
          text: action.payload.message,
          sent: new Date(),
          sender: true,
        });
      }
    }

    return {
      ...state,
      currentChatRoom: {
        ...state.currentChatRoom,
        conversations: [...state.currentChatRoom.conversations],
      },
    };
  case RECEIVEMESSAGE:
    for (let i = 0; i < currentChatRoom.conversations.length; i++) {
      let conversation = currentChatRoom.conversations[i];

      if (conversation.conversationId === action.payload.conversationId) {
        conversation.messages.push({
          text: action.payload.text,
          sent: action.payload.sent,
          sender: false,
        });
      }
    }

    return {
      ...state,
      currentChatRoom: {
        ...state.currentChatRoom,
        conversations: [...state.currentChatRoom.conversations],
      },
    };
  case CONVERSATIONTERMINATED:
    let conversations = state.currentChatRoom.conversations.filter(
      x => x.conversationId !== action.payload.conversationId,
    );

    return {
      ...state,
      currentChatRoom: {
        ...state.currentChatRoom,
        conversations,
      },
    };
  default:
    return state;
  }
};

export default messageReducer;
