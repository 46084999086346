import {
  CONNECT_GETCONTENTBUILDER_REQUEST,
  CONNECT_GETCONTENTBUILDER_SUCCESS,
  CONNECT_GETCONTENTBUILDER_FAILURE,

  CONNECT_CONTENTBUILDERASSETS_REQUEST,
  CONNECT_CONTENTBUILDERASSETS_SUCCESS,
  CONNECT_CONTENTBUILDERASSETS_FAILURE,
  CONNECT_CONTENTBUILDERBACKGROUNDS_REQUEST,
  CONNECT_CONTENTBUILDERBACKGROUNDS_SUCCESS,
  CONNECT_CONTENTBUILDERBACKGROUNDS_FAILURE,
  CONNECT_CONTENTBUILDERATTACHMENTS_REQUEST,
  CONNECT_CONTENTBUILDERATTACHMENTS_SUCCESS,
  CONNECT_CONTENTBUILDERATTACHMENTS_FAILURE,

  CONNECT_SAVECONTENTBUILDER_REQUEST,
  CONNECT_SAVECONTENTBUILDER_SUCCESS,
  CONNECT_SAVECONTENTBUILDER_FAILURE,
  CONNECT_ARCHIVECONTENTBUILDER_REQUEST,
  CONNECT_ARCHIVECONTENTBUILDER_SUCCESS,
  CONNECT_ARCHIVECONTENTBUILDER_FAILURE,
  CONNECT_DELETECONTENTBUILDER_REQUEST,
  CONNECT_DELETECONTENTBUILDER_SUCCESS,
  CONNECT_DELETECONTENTBUILDER_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const ConnectContentManagementRouter = (
  state = initialState.connectContentManagementState,
  action,
) => {
  switch (action.type) {
  case CONNECT_GETCONTENTBUILDER_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      isSaving: false,
      isSaved: false,
      isArchiving: false,
      isArchived: false,
      isDeleting: false,
      isDeleted: false,
      error: undefined,
      labels: [],
      templates: [],
      audiences: [],
      categories: [],
      content: {},
      canEdit: false,
    };
  case CONNECT_GETCONTENTBUILDER_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      isSaved: false,
      error: undefined,
      labels: action.payload.labels,
      templates: action.payload.templates,
      audiences: action.payload.audience,
      //mbuUsers: action.payload.mbuUsers,
      categories: action.payload.categories,
      content: action.payload.content || {},
      canEdit: action.payload.canEdit,
    };
  case CONNECT_GETCONTENTBUILDER_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: action.payload.message,
      labels: [],
      templates: [],
      audiences: [],
      categories: [],
      content: {},
      canEdit: false,
    };

  case CONNECT_CONTENTBUILDERASSETS_REQUEST:
    return {
      ...state,
      assets: [],
    };
  case CONNECT_CONTENTBUILDERASSETS_SUCCESS:
    return {
      ...state,
      assets: action.payload,
    };
  case CONNECT_CONTENTBUILDERASSETS_FAILURE:
    return {
      ...state,
      assets: [],
    };


  case CONNECT_CONTENTBUILDERBACKGROUNDS_REQUEST:
    return {
      ...state,
      backgrounds: [],
    };
  case CONNECT_CONTENTBUILDERBACKGROUNDS_SUCCESS:
    return {
      ...state,
      backgrounds: action.payload,
    };
  case CONNECT_CONTENTBUILDERBACKGROUNDS_FAILURE:
    return {
      ...state,
      backgrounds: [],
    };

  case CONNECT_CONTENTBUILDERATTACHMENTS_REQUEST:
    return {
      ...state,
      attachments: [],
    };
  case CONNECT_CONTENTBUILDERATTACHMENTS_SUCCESS:
    return {
      ...state,
      attachments: action.payload,
    };
  case CONNECT_CONTENTBUILDERATTACHMENTS_FAILURE:
    return {
      ...state,
      attachments: [],
    };

    //Save Requests
  case CONNECT_SAVECONTENTBUILDER_REQUEST:
    return {
      ...state,
      isSaving: true,
    };
  case CONNECT_ARCHIVECONTENTBUILDER_REQUEST:
    return {
      ...state,
      isArchiving: true,
    };
  case CONNECT_DELETECONTENTBUILDER_REQUEST:
    return {
      ...state,
      isDeleting: true,
    };

    //Save Successes
  case CONNECT_SAVECONTENTBUILDER_SUCCESS:
    return {
      ...state,
      isSaving: false,
      isSaved: true,
    };
  case CONNECT_ARCHIVECONTENTBUILDER_SUCCESS:
    return {
      ...state,
      isArchiving: false,
      isArchived: true,
    };
  case CONNECT_DELETECONTENTBUILDER_SUCCESS:
    return {
      ...state,
      isDeleting: false,
      isDeleted: true,
    };

    //Save Failures
  case CONNECT_SAVECONTENTBUILDER_FAILURE:
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      error: action.payload.message,
    };
  case CONNECT_ARCHIVECONTENTBUILDER_FAILURE:
    return {
      ...state,
      isArchiving: false,
      isArchived: false,
      error: action.payload.message,
    };
  case CONNECT_DELETECONTENTBUILDER_FAILURE:
    return {
      ...state,
      isDeleting: false,
      isDeleted: false,
      error: action.payload.message,
    };

  default:
    return state;
  }
};
export default ConnectContentManagementRouter;
