import {
  DOWNLOAD_REQUEST,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_FAILURE,
} from './actionTypes';

export const specialActions = {
  downloadFile: url => (dispatch, getState) => {
    dispatch({
      type: DOWNLOAD_REQUEST,
      http: {
        verb: 'GET',
        endpoint: url,
        successAction: DOWNLOAD_SUCCESS,
        failureAction: DOWNLOAD_FAILURE,
      },
    });
  },
};
