import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { navigationActions } from '../../../actions/navigationActions';
import NotificationList from '../../notifications/NotificationList/NotificationList';
import { sideBarActions } from '../../../actions/sideBarActions';

class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout(event) {
    this.props.logout();
    this.props.replace('/');
    event.preventDefault();
  }

  render() {
    return (
      //other pages
      <nav className={`menu ${this.props.sideBarOpen ? 'open' : 'closed'}`}>
        <div className="side-menu-holder">
          <div
            className="logo"
            onClick={() => {
              this.props.push('/');
            }}
          />
          <div
            className="close-button"
            onClick={() => this.props.toggleSideBar(false)}>
            X
          </div>
        </div>
        <div className="welcome-all">
          <h2>Notifications</h2>
        </div>
        <NotificationList />
      </nav>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  sideBarOpen: state.sideBar.sideBarOpen,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    logout: () => dispatch(authActions.requestLogout()),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),

    toggleSideBar: isOpen => dispatch(sideBarActions.toggleSideBar(isOpen)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SideMenu);
