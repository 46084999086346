import { MODULE_REQUEST, MODULE_SUCCESS, MODULE_FAILURE } from './actionTypes';

export const moduleActions = {
  requestModule: moduleId => (dispatch, getState) => {
    dispatch({
      type: MODULE_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/module/v1/GetModule${moduleId ? '/' + moduleId : ''}`,
        successAction: MODULE_SUCCESS,
        failureAction: MODULE_FAILURE,
      },
      payload: {
        moduleId: !moduleId ? null : moduleId,
      },
    });
  },
};
