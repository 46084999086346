import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { connectLabelActions } from '../../../actions/connectLabelActions';



class ContentLabels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMode: 2,
    };

    this.setDisplayMode = this.setDisplayMode.bind(this);

    this.navigateItemDetails = this.navigateItemDetails.bind(this);
  }

  componentDidMount() {
    this.props.getLabels();
  }


  navigateItemDetails(labelId) {
    const { pathname } = this.props.location;
    this.props.push(`${pathname}/${labelId}`);
  }

  setDisplayMode(mode) {
    this.setState({ displayMode: mode });
  }

  render() {
    const { labelList } = this.props;
    const { displayMode } = this.state;
    const {
      setDisplayMode,
      navigateItemDetails,
    } = this;
    return (
      <div className="item-catalogue-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div
                className={'icon icon-item-catalogue'}></div>
              <h2>
                Connect Labels
              </h2>
            </div>
            <div>
              <button
                type="button"
                className="action"
                onClick={() =>
                  navigateItemDetails(0)
                }
              >
                Add Label
              </button>
            </div>

          </div>
        </div>

        <div className="item-catalogue-container table-container">
          <div className="table-header">
            <div className="display-options">
              Table Size
              <button
                type="button"
                className="icon-display-mode-1"
                disabled={displayMode === 1}
                onClick={() => setDisplayMode(1)}></button>
              <button
                type="button"
                className="icon-display-mode-2"
                disabled={displayMode === 2}
                onClick={() => setDisplayMode(2)}></button>
              <button
                type="button"
                className="icon-display-mode-3"
                disabled={displayMode === 3}
                onClick={() => setDisplayMode(3)}></button>
            </div>
            <h2>
                Connect Labels
            </h2>
            <div className="pagination-controls">
            </div>
          </div>
          <div className="table-body">
            <table className={`table-display-mode-${displayMode}`}>
              <thead>
                <tr>
                  <th>Label</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {labelList.map(item => (
                  <tr
                    key={item.contentId}>
                    <td>{item.text}</td>
                    <td className="right-cell">
                      <button
                        type="button"
                        className="action"
                        onClick={() =>
                          navigateItemDetails(item.labelId)
                        }
                      >
                        View/Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="table-header"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { connectLabel } = state;
  return {
    isLoading: connectLabel.isLoading,
    isLoaded: connectLabel.isLoaded,
    labelList: connectLabel.labelList,
    errorMessage: connectLabel.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLabels: () => dispatch(connectLabelActions.getLabels()),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentLabels);
