import React from 'react';
import { connect } from 'react-redux';
import { bookingFormActions } from '../../../actions/bookingFormActions';

class OverdueInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      daysBack: 30,
      displayMode: 2,
    };
    this.setDisplayMode = this.setDisplayMode.bind(this);
  }

  componentDidMount() {
    this.props.getBookingForms(this.state.daysBack);
  }

  setDisplayMode(mode) {
    this.setState({ displayMode: mode });
  }

  render() {
    const { bookingForms, loaded } = this.props;
    return (
      <div className="booking-form-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-table"></div>
              <h2>On-Line Booking Form</h2>
            </div>
            <div className="header-buttons"></div>
          </div>
        </div>
        <div className="booking-form-container">
          {bookingForms.length > 0 &&
            bookingForms.map(bookingForm => (
              <div
                className="booking-form floating-panel"
                key={bookingForm.bookingFormId}>
                <div className="booking-form-header">
                  <div className="label-value-pair">
                    <label>Loading Reference:</label>
                    <span>{bookingForm.loadingRef}</span>
                  </div>

                  <div className="label-value-pair">
                    <label>Collection Date:</label>
                    <span>
                      {new Date(
                        bookingForm.dateOfCollection,
                      ).toLocaleDateString()}
                    </span>
                  </div>
                </div>
                <div className="booking-form-body">
                  <div>
                    <div className="label-value-pair">
                      <label>From:</label>
                      <span>{bookingForm.from3Pl}</span>
                    </div>
                    <div className="label-value-pair">
                      <label>Date:</label>
                      <span>
                        {new Date(bookingForm.date).toLocaleDateString()}
                      </span>
                    </div>
                    <div className="label-value-pair">
                      <label>Order Details:</label>
                      {bookingForm.orderDetails.split(',').length > 1 ? (
                        <div>
                          {bookingForm.orderDetails.split(',').map(o => (
                            <div key={o}>{o}</div>
                          ))}
                        </div>
                      ) : (
                        <span>{bookingForm.orderDetails}</span>
                      )}
                    </div>
                    <div className="label-value-pair">
                      <label>Time of Collection:</label>
                      <span>{bookingForm.timeOfCollection}</span>
                    </div>
                  </div>
                  <div>
                    <div className="label-value-pair">
                      <label>Collection Address:</label>
                      <span>{bookingForm.collectionAddress}</span>
                    </div>
                    <div className="label-value-pair">
                      <label>Number of Pallets:</label>
                      <span>{bookingForm.numberOfPallets}</span>
                    </div>
                    <div className="label-value-pair">
                      <label>Gross Weight:</label>
                      <span>{bookingForm.grossWeight}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {bookingForms.length === 0 && loaded && (
            <div className="row floating-panel">
              <h3>
                You've had no booking forms in the last {this.state.daysBack}{' '}
                days.
              </h3>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.bookingForm.isFetching,
  loaded: state.bookingForm.isLoaded,
  bookingForms: state.bookingForm.bookingForms,
});

const mapDispatchToProps = dispatch => {
  return {
    getBookingForms: daysBack =>
      dispatch(bookingFormActions.requestBookingForm(daysBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OverdueInvoices);
