import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { connectLabelActions } from '../../../actions/connectLabelActions';
import DeleteModal from '../../shared/DeleteModal';

class EditLabel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      labelId: null,
      text: '',

      showDeleteModal: false,

      showValidation: false,
    };

    this.handleSaveLabel = this.handleSaveLabel.bind(this);
    this.handleDeleteLabel = this.handleDeleteLabel.bind(this);

    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);

    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);
  }

  componentDidMount() {
    const{ labelId } = this.props.match.params;
    const{ getLabel } = this.props;
    if (parseInt(labelId)) {
      getLabel(labelId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { pathname } = this.props.location;
    const { selectedLabel, 
      isLoaded, 
      isSaved, 
      isDeleted,
      replace, 
      getLabel, 
      goBack, 
    } = this.props;

    if (isLoaded && !prevProps.isLoaded) {
      this.setState({
        labelId: selectedLabel.labelId,
        text: selectedLabel.text,
      });
    } else if (isSaved && !prevProps.isSaved) {
      if (
        (selectedLabel.labelId && !prevProps.selectedLabel.labelId) ||
        selectedLabel.labelId !== prevProps.selectedLabel.labelId
      ) {
        //New label saved for the first time.
        replace(`${pathname}${selectedLabel.labelId}`);
        getLabel(selectedLabel.labelId);

      } else {
        goBack();
      }
    } else if (isDeleted && !prevProps.isDeleted) {
      goBack();
    }
  }

  handleSaveLabel(e) {
    let { saveLabel } = this.props;
    let {
      labelId,
      text,
    } = this.state;

    if (!text) {
      this.setState({ showValidation: true });
    } else {
      saveLabel({ labelId, text });
    }

    e.preventDefault();
  }

  handleDeleteModalShow() {
    this.setState({ showDeleteModal: true });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false });
  }

  handleDeleteLabel() {
    this.props.deleteLabel(this.state.labelId);
    this.handleDeleteModalHide();
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  getLabelPanel() {
    let {
      text,
      showValidation,
    } = this.state;

    return (
      <div className="group-details accordion">
        <div
          className="accordion-header"
        >
          <h3>Label Details</h3>
        </div>
        <div
          className={'accordion-body horizontal-flex user-fields'}
        >
          <div className="user-field-block">
            <div>
              <label>
                Label
                <input
                  maxLength={64}
                  autoFocus
                  placeholder="Label"
                  required
                  className={
                    showValidation && !text
                      ? 'invalid'
                      : ''
                  }
                  onChange={e => this.handleTextInputUpdate(e, 'text')}
                  value={text}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { showDeleteModal, labelId } = this.state;
    const {
      isDeleting,
      isDeleted,
      isSaving,
      isSaved,
      error,
    } = this.props;
    return (
      <div className="edit-user-page page">
        <div className="floating-panel">
          <form noValidate
            onSubmit={e => this.handleSaveLabel(e)}>
            <div className="floating-header">
              <div className="header-title">
                <div className="icon icon-form"></div>
                {labelId ? (
                  <h2>Edit Connect Label</h2>
                ) : (
                  <h2>Create Connect Label</h2>
                )}
              </div>
            </div>

            {this.getLabelPanel()}


            <p className="error">{error}</p>
            <div className="button-container">
              <button className="action"
                disabled={isSaving}>
                {isSaving
                  ? 'Saving...'
                  : isSaved && !labelId
                    ? 'Saved'
                    : 'Save'}
              </button>
              {labelId && (
                <button
                  type="button"
                  className="action negative"
                  onClick={() => this.handleDeleteModalShow()}
                  disabled={isDeleting}
                >
                  {isDeleting
                    ? 'Deleting...'
                    : isDeleted
                      ? 'Deleted'
                      : 'Delete'}
                </button>
              )}
            </div>
          </form>
          {showDeleteModal && (
            <DeleteModal
              cancel={this.handleDeleteModalHide}
              continue={this.handleDeleteLabel}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { connectLabel } = state;
  return {
    isLoading: connectLabel.isLoading,
    isLoaded: connectLabel.isLoaded,
    selectedLabel: connectLabel.selectedLabel,
    isSaving: connectLabel.isSaving,
    isSaved: connectLabel.isSaved,
    isDeleting: connectLabel.isDeleted,
    isDeleted: connectLabel.isDeleted,
    error: connectLabel.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLabel: labelId => dispatch(connectLabelActions.getLabel(labelId)),
    saveLabel: label => dispatch(connectLabelActions.saveLabel(label)),
    deleteLabel: labelId => dispatch(connectLabelActions.deleteLabel(labelId)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLabel);
