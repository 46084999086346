import {
  APAC_ORDERDOCUMENTS_REQUEST,
  APAC_ORDERDOCUMENTS_SUCCESS,
  APAC_ORDERDOCUMENTS_FAILURE,
  APAC_ORDERDOCUMENTS_CLEARSTATE,
  APAC_ORDERDOCUMENTS_SAVE_REQUEST,
  APAC_ORDERDOCUMENTS_SAVE_SUCCESS,
  APAC_ORDERDOCUMENTS_SAVE_FAILURE,
  APAC_ORDERDOCUMENTS_DELETE_REQUEST,
  APAC_ORDERDOCUMENTS_DELETE_SUCCESS,
  APAC_ORDERDOCUMENTS_DELETE_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

const apacOrderDocumentReducer = (state = initialState.apacOrderDocumentState, action) => {
  switch (action.type) {
  case APAC_ORDERDOCUMENTS_CLEARSTATE:
    return {
      ...initialState.apacOrderDocumentState,
    };
  case APAC_ORDERDOCUMENTS_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      error: '',
      documents: [],
    };
  case APAC_ORDERDOCUMENTS_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: '',
      documents: action.payload.documents,
      documentTypes: action.payload.documentTypes,
      isAdmin: action.payload.isAdmin,
      mbu: action.payload.mbu,
      requiredDocumentTypes: action.payload.requiredDocumentTypes,
      thirdPartyCanWrite: action.payload.thirdPartyCanWrite,
      userId: action.payload.userId,
    };
  case APAC_ORDERDOCUMENTS_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: action.payload.error,
    };
  case APAC_ORDERDOCUMENTS_SAVE_REQUEST:
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      error: '',
    };
  case APAC_ORDERDOCUMENTS_SAVE_SUCCESS:
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      error: '',
    };
  case APAC_ORDERDOCUMENTS_SAVE_FAILURE:
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      error: action.payload.error,
    };
  case APAC_ORDERDOCUMENTS_DELETE_REQUEST:
    return {
      ...state,
      isDeleting: true,
      isDeleted: false,
      error: '',
    };
  case APAC_ORDERDOCUMENTS_DELETE_SUCCESS:
    return {
      ...state,
      isDeleting: false,
      isDeleted: true,
      error: '',
    };
  case APAC_ORDERDOCUMENTS_DELETE_FAILURE:
    return {
      ...state,
      isDeleting: false,
      isDeleted: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export default apacOrderDocumentReducer;
