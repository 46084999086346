import React from "react";
import { connect } from "react-redux";
import dtmpFileActions from "../../../actions/siteIDDtmpFileActions";

class SiteIDDtmpFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaName: undefined,
      mediaType: undefined,
      mediaData: undefined,
      appendOnly: false
    };

    this.handleSave = this.handleSave.bind(this);
  }

  handleSave(e) {
    this.props.upload(this.state);

    e.preventDefault();
  }

  handleFileInputUpdate = e => {
    if (!e.target.files || e.target.files.length === 0) return;
    let currentComponent = this;
    let doc = {
      mediaName: e.target.files[0].name,
      mediaType: e.target.files[0].type
    };

    var reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }).then(data => {
      doc.mediaData = data ? data.replace(/^data:(.*,)?/, "") : "";

      currentComponent.setState(doc);
    });
  };

  render() {
    const { errorMessage, isUploading, isLoaded } = this.props;
    const { appendOnly } = this.state;
    return (
      <div className="dtmp-file-upload-page page">
        <form
          className="form-container"
          noValidate
          onSubmit={e => this.handleSave(e)}>
          <div className="form-header">
            <div className="header-title">
              <div className="icon icon-reports"></div>
              <h2>DTMP File Upload</h2>
            </div>
          </div>
          <div className="form-explanation">
            <p>Upload the latest drive-thru market plan to update My Starbucks.</p>
          </div>
          <div className="form-body">
            <label className="file-upload-wrapper">
              <div className="icon icon-image-upload"></div>
              <p>
                {!this.state.mediaName &&
                  "Tap to upload your latest drive-thru market plan file."}
                {this.state.mediaName}
              </p>
              <input
                autoFocus
                name="file"
                id="file"
                className="inputfile"
                type="file"
                onChange={e => this.handleFileInputUpdate(e)}
              />
            </label>
          </div>
          <div className="form-body">
            <label>
              Append Plan (Default: Replace existing plan)
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  onChange={(e) => this.setState({appendOnly: true && e.target.checked })}
                  checked={appendOnly}
                />
                <div className="toggle-icon" />
              </div>
            </label>
          </div>
          <div className="form-buttons">
            {errorMessage && <p>{errorMessage}</p>}
            {isLoaded && <p>File Successfully Uploaded</p>}
            <button type="submit" className="action" disabled={isUploading}>
              Upload
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isUploading: state.siteIDDtmp.isUploading,
    isLoaded: state.siteIDDtmp.isLoaded,
    errorMessage: state.siteIDDtmp.errorMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    upload: file => dispatch(dtmpFileActions.uploadDtmpFile(file))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteIDDtmpFileUpload);
