import {
  OVERDUEINVOICES_REQUEST,
  OVERDUEINVOICES_SUCCESS,
  OVERDUEINVOICES_FAILURE,
} from './actionTypes';

export const OverdueInvoiceActions = {
  requestOverdueInvoices: (page, customerName, invoiceDate, dueDate) => (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: OVERDUEINVOICES_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/OverdueInvoices/v1/Get?page=${page}&customerName=${customerName}&invoiceDate=${
          invoiceDate ? invoiceDate : ''
        }&dueDate=${dueDate ? dueDate : ''}`,
        successAction: OVERDUEINVOICES_SUCCESS,
        failureAction: OVERDUEINVOICES_FAILURE,
      },
    });
  },
};
