/* globals google */
import { Cluster } from "./Cluster.js";

const CALCULATOR = function CALCULATOR(markers, numStyles) {
  let index = 0;

  const title = "";

  const count = markers
    .filter(m => m.get("exclude") === false && m.get("type") === "application")
    .length.toString();

  let dv = count;

  while (dv !== 0) {
    dv = parseInt(dv, 10) / 10;

    index++;
  }

  index = Math.min(index, numStyles);

  return {
    text: count,
    index: index,
    title: title
  };
};

const BATCH_SIZE = 2000;

const BATCH_SIZE_IE = 500;

const IMAGE_PATH = "/assets/map-icons/m";

const IMAGE_EXTENSION = "png";

const IMAGE_SIZES = [
  [24, 35],
  [24, 35],
  [24, 35],
  [24, 35],
  [24, 35],
  [24, 35]
];

const CLUSTERER_CLASS = "cluster";

export class Clusterer {
  markers;
  clusters;
  listenerssEventListener;
  activeMap;
  ready;
  gridSize;
  minClusterSize;
  maxZoom;
  styles;
  title;
  zoomOnClick;
  averageCenter;
  ignoreHidden;
  enableRetinaIcons;
  imagePath;
  imageExtension;
  imageSizes;
  calculator;
  batchSize;
  batchSizeIE;
  clusterClass;
  timerRefStatic;

  constructor(map, optMarkers = [], optOptions = {}) {
    this.extend(Clusterer, google.maps.OverlayView);

    this.markers = [];
    this.clusters = [];
    this.listeners = [];
    this.activeMap = null;
    this.ready = false;
    this.gridSize = optOptions.gridSize || 20;
    this.minClusterSize = optOptions.minimumClusterSize || 3;
    this.maxZoom = optOptions.maxZoom || null;
    this.styles = optOptions.styles || [];

    this.title = optOptions.title || "";

    this.zoomOnClick = true;

    if (optOptions.zoomOnClick !== undefined) {
      this.zoomOnClick = optOptions.zoomOnClick;
    }

    this.averageCenter = false;

    if (optOptions.averageCenter !== undefined) {
      this.averageCenter = optOptions.averageCenter;
    }

    this.ignoreHidden = false;

    if (optOptions.ignoreHidden !== undefined) {
      this.ignoreHidden = optOptions.ignoreHidden;
    }

    this.enableRetinaIcons = false;

    if (optOptions.enableRetinaIcons !== undefined) {
      this.enableRetinaIcons = optOptions.enableRetinaIcons;
    }
    this.imagePath = optOptions.imagePath || IMAGE_PATH;

    this.imageExtension = optOptions.imageExtension || IMAGE_EXTENSION;

    this.imageSizes = optOptions.imageSizes || IMAGE_SIZES;

    this.calculator = optOptions.calculator || CALCULATOR;

    this.batchSize = optOptions.batchSize || BATCH_SIZE;

    this.batchSizeIE = optOptions.batchSizeIE || BATCH_SIZE_IE;

    this.clusterClass = optOptions.clusterClass || CLUSTERER_CLASS;

    if (navigator.userAgent.toLowerCase().indexOf("msie") !== -1) {
      // Try to avoid IE timeout when processing a huge number of markers:
      this.batchSize = this.batchSizeIE;
    }

    this.timerRefStatic = null;

    this.setupStyles();

    this.addMarkers(optMarkers, true);

    this.setMap(map); // Note: this causes onAdd to be called
  }

  onAdd() {

    this.activeMap = this.getMap();

    this.ready = true;

    this.repaint();

    // Add the map event listeners
    this.listeners = [
      google.maps.event.addListener(

        this.getMap(),
        "zoom_changed",

        () => {
          this.resetViewport(false);
          // Workaround for this Google bug: when map is at level 0 and "-" of
          // zoom slider is clicked, a "zoom_changed" event is fired even though
          // the map doesn't zoom out any further. In this situation, no "idle"
          // event is triggered so the cluster markers that have been removed
          // do not get redrawn. Same goes for a zoom in at maxZoom.
          if (

            this.getMap().getZoom() === (this.get("minZoom") || 0) ||

            this.getMap().getZoom() === this.get("maxZoom")
          ) {
            google.maps.event.trigger(this, "idle");
          }
        }
      ),
      google.maps.event.addListener(

        this.getMap(),
        "idle",

        () => {
          this.redraw();
        }
      )
    ];
  }

  onRemove() {
    // Put all the managed markers back on the map:
    for (let i = 0; i < this.markers.length; i++) {
      if (this.markers[i].getMap() !== this.activeMap) {
        this.markers[i].setMap(this.activeMap);
      }
    }

    // Remove all clusters:
    for (let i = 0; i < this.clusters.length; i++) {
      this.clusters[i].remove();
    }

    this.clusters = [];

    // Remove map event listeners:
    for (let i = 0; i < this.listeners.length; i++) {
      google.maps.event.removeListener(this.listeners[i]);
    }

    this.listeners = [];

    this.activeMap = null;

    this.ready = false;
  }

  draw() { }

  setupStyles() {
    if (this.styles.length > 0) {
      return;
    }

    this.styles.push({

    });

    for (let i = 1; i < this.imageSizes.length; i++) {
      this.styles.push({
        url: this.imagePath + i + "." + this.imageExtension,
        height: this.imageSizes[i][1],
        width: this.imageSizes[i][0]
      });
    }
  }

  fitMapToMarkers() {
    const markers = this.getMarkers();

    const bounds = new google.maps.LatLngBounds();

    for (let i = 0; i < markers.length; i++) {
      const position = markers[i].getPosition();
      if (position) {
        bounds.extend(position);
      }
    }

    this.getMap().fitBounds(bounds);
  }

  getGridSize() {
    return this.gridSize;
  }

  setGridSize(gridSize) {
    this.gridSize = gridSize;
  }

  getMinimumClusterSize() {
    return this.minClusterSize;
  }

  setMinimumClusterSize(minimumClusterSize) {
    this.minClusterSize = minimumClusterSize;
  }

  getMaxZoom() {
    return this.maxZoom;
  }

  setMaxZoom(maxZoom) {
    this.maxZoom = maxZoom;
  }

  getStyles() {
    return this.styles;
  }

  setStyles(styles) {
    this.styles = styles;
  }

  getTitle() {
    return this.title;
  }

  setTitle(title) {
    this.title = title;
  }

  getZoomOnClick() {
    return this.zoomOnClick;
  }

  setZoomOnClick(zoomOnClick) {
    this.zoomOnClick = zoomOnClick;
  }

  getAverageCenter() {
    return this.averageCenter;
  }

  setAverageCenter(averageCenter) {
    this.averageCenter = averageCenter;
  }

  getIgnoreHidden() {
    return this.ignoreHidden;
  }

  setIgnoreHidden(ignoreHidden) {
    this.ignoreHidden = ignoreHidden;
  }

  getEnableRetinaIcons() {
    return this.enableRetinaIcons;
  }

  setEnableRetinaIcons(enableRetinaIcons) {
    this.enableRetinaIcons = enableRetinaIcons;
  }

  getImageExtension() {
    return this.imageExtension;
  }

  setImageExtension(imageExtension) {
    this.imageExtension = imageExtension;
  }

  getImagePath() {
    return this.imagePath;
  }

  setImagePath(imagePath) {
    this.imagePath = imagePath;
  }

  getImageSizes() {
    return this.imageSizes;
  }

  setImageSizes(imageSizes) {
    this.imageSizes = imageSizes;
  }

  getCalculator() {
    return this.calculator;
  }

  setCalculator(calculator) {
    this.calculator = calculator;
  }

  getBatchSizeIE() {
    return this.batchSizeIE;
  }

  setBatchSizeIE(batchSizeIE) {
    this.batchSizeIE = batchSizeIE;
  }

  getClusterClass() {
    return this.clusterClass;
  }

  setClusterClass(clusterClass) {
    this.clusterClass = clusterClass;
  }

  getMarkers() {
    return this.markers;
  }

  getTotalMarkers() {
    return this.markers.length;
  }

  getClusters() {
    return this.clusters;
  }

  getTotalClusters() {
    return this.clusters.length;
  }

  addMarker(marker, optNoDraw) {
    this.pushMarkerTo(marker);

    if (!optNoDraw) {
      this.redraw();
    }
  }

  addMarkers(markers, optNoDraw) {
    for (const key in markers) {
      if (markers.hasOwnProperty(key)) {
        this.pushMarkerTo(markers[key]);
      }
    }

    if (!optNoDraw) {
      this.redraw();
    }
  }

  pushMarkerTo(marker) {
    // If the marker is draggable add a listener so we can update the clusters on the dragend:
    if (marker.getDraggable()) {

      google.maps.event.addListener(marker, "dragend", () => {
        if (this.ready) {
          marker.isAdded = false;

          this.repaint();
        }
      });
    }

    marker.isAdded = false;

    this.markers.push(marker);
  }

  removeMarker_(marker) {
    let index = -1;

    if (this.markers.indexOf) {
      index = this.markers.indexOf(marker);
    } else {
      for (let i = 0; i < this.markers.length; i++) {
        if (marker === this.markers[i]) {
          index = i;

          break;
        }
      }
    }

    if (index === -1) {
      // Marker is not in our list of markers, so do nothing:
      return false;
    }

    marker.setMap(null);

    this.markers.splice(index, 1); // Remove the marker from the list of managed markers

    return true;
  }

  removeMarker(marker, optNoDraw) {
    const removed = this.removeMarker_(marker);

    if (!optNoDraw && removed) {
      this.repaint();
    }

    return removed;
  }

  removeMarkers(markers, optNoDraw) {
    let removed = false;

    for (let i = 0; i < markers.length; i++) {
      removed = this.removeMarker_(markers[i]) || removed;
    }

    if (!optNoDraw && removed) {
      this.repaint();
    }

    return removed;
  }

  clearMarkers() {
    this.resetViewport(true);

    this.markers = [];
  }

  repaint() {
    const oldClusters = this.clusters.slice();

    this.clusters = [];

    this.resetViewport(false);

    this.redraw();

    // Remove the old clusters.
    // Do it in a timeout to prevent blinking effect.
    setTimeout(function timeout() {
      for (let i = 0; i < oldClusters.length; i++) {
        oldClusters[i].remove();
      }
    }, 0);
  }

  getExtendedBounds(bounds) {

    const projection = this.getProjection();
    // Convert the points to pixels and the extend out by the grid size.
    const trPix = projection.fromLatLngToDivPixel(
      // Turn the bounds into latlng.
      new google.maps.LatLng(
        bounds.getNorthEast().lat(),
        bounds.getNorthEast().lng()
      )
    );

    trPix.x += this.gridSize;
    trPix.y -= this.gridSize;

    const blPix = projection.fromLatLngToDivPixel(
      // Turn the bounds into latlng.
      new google.maps.LatLng(
        bounds.getSouthWest().lat(),
        bounds.getSouthWest().lng()
      )
    );

    blPix.x -= this.gridSize;
    blPix.y += this.gridSize;

    // Extend the bounds to contain the new bounds.
    bounds.extend(
      // Convert the pixel points back to LatLng nw
      projection.fromDivPixelToLatLng(trPix)
    );

    bounds.extend(
      // Convert the pixel points back to LatLng sw
      projection.fromDivPixelToLatLng(blPix)
    );

    return bounds;
  }

  redraw() {
    // Redraws all the clusters.
    this.createClusters(0);
  }

  resetViewport(optHide) {
    // Remove all the clusters
    for (let i = 0; i < this.clusters.length; i++) {
      this.clusters[i].remove();
    }

    this.clusters = [];

    // Reset the markers to not be added and to be removed from the map.
    for (let i = 0; i < this.markers.length; i++) {
      const marker = this.markers[i];

      marker.isAdded = false;

      if (optHide) {
        marker.setMap(null);
      }
    }
  }

  distanceBetweenPoints(p1, p2) {
    const R = 6371; // Radius of the Earth in km

    const dLat = ((p2.lat() - p1.lat()) * Math.PI) / 180;
    const dLon = ((p2.lng() - p1.lng()) * Math.PI) / 180;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((p1.lat() * Math.PI) / 180) *
      Math.cos((p2.lat() * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

    return R * (2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)));
  }

  isMarkerInBounds(marker, bounds) {
    const position = marker.getPosition();

    if (position) {
      return bounds.contains(position);
    }

    return false;
  }

  addToClosestCluster(marker) {
    let cluster;

    let distance = 40000; // Some large number

    let clusterToAddTo = null;

    for (let i = 0; i < this.clusters.length; i++) {
      cluster = this.clusters[i];

      const center = cluster.getCenter();

      const position = marker.getPosition();

      if (center && position) {
        const d = this.distanceBetweenPoints(center, position);

        if (d < distance) {
          distance = d;

          clusterToAddTo = cluster;
        }
      }
    }

    if (clusterToAddTo && clusterToAddTo.isMarkerInClusterBounds(marker)) {
      clusterToAddTo.addMarker(marker);
    } else {
      cluster = new Cluster(this);

      cluster.addMarker(marker);

      this.clusters.push(cluster);
    }
  }

  createClusters(iFirst) {
    if (!this.ready) {
      return;
    }

    // Cancel previous batch processing if we're working on the first batch:
    if (iFirst === 0) {
      /**
       * This event is fired when the <code>Clusterer</code> begins
       *  clustering markers.
       * @name Clusterer#clusteringbegin
       * @param {Clusterer} mc The Clusterer whose markers are being clustered.
       * @event
       */
      google.maps.event.trigger(this, "clusteringbegin", this);

      if (this.timerRefStatic !== null) {
        window.clearTimeout(this.timerRefStatic);

        delete this.timerRefStatic;
      }
    }

    // Get our current map view bounds.
    // Create a new bounds object so we don't affect the map.
    //
    // See Comments 9 & 11 on Issue 3651 relating to this workaround for a Google Maps bug:
    const mapBounds =

      this.getMap().getZoom() > 3
        ? new google.maps.LatLngBounds(

          this.getMap()
            .getBounds()
            .getSouthWest(),

          this.getMap()
            .getBounds()
            .getNorthEast()
        )
        : new google.maps.LatLngBounds(
          new google.maps.LatLng(85.02070771743472, -178.48388434375),
          new google.maps.LatLng(-85.08136444384544, 178.00048865625)
        );

    const bounds = this.getExtendedBounds(mapBounds);

    const iLast = Math.min(iFirst + this.batchSize, this.markers.length);

    for (let i = iFirst; i < iLast; i++) {
      const marker = this.markers[i];

      if (!marker.isAdded && this.isMarkerInBounds(marker, bounds)) {
        if (!this.ignoreHidden || (this.ignoreHidden && marker.getVisible())) {
          this.addToClosestCluster(marker);
        }
      }
    }

    if (iLast < this.markers.length) {
      this.timerRefStatic = window.setTimeout(

        () => {
          this.createClusters(iLast);
        },
        0
      );
    } else {
      this.timerRefStatic = null;

      /**
       * This event is fired when the <code>Clusterer</code> stops
       *  clustering markers.
       * @name Clusterer#clusteringend
       * @param {Clusterer} mc The Clusterer whose markers are being clustered.
       * @event
       */
      google.maps.event.trigger(this, "clusteringend", this);
    }
  }

  extend(obj1, obj2) {
    return function applyExtend(object) {

      for (const property in object.prototype) {

        this.prototype[property] = object.prototype[property];
      }

      return this;
    }.apply(obj1, [obj2]);
  }
}
