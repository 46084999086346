import React from 'react';

const GroupRecord = props => {
  let { editAction, deleteAction, groupName, groupId, iconName } = props;

  return (
    <div className="group-record"
      data-group-id={groupId}>
      <div className="group-top-details">
        <div className={`icon ${iconName}`}></div>
        {groupName}
      </div>
      <div className="group-top-buttons">
        <button className="edit"
          type="button"
          onClick={() => editAction()}>
          EDIT
        </button>
        <button
          className="edit negative"
          type="button"
          onClick={() => deleteAction()}>
          DELETE
        </button>
      </div>
    </div>
  );
};

export default GroupRecord;
