import {
  CHAT_TOKEN_REQUEST,
  CHAT_TOKEN_SUCCESS,
  CHAT_TOKEN_FAILURE,
} from './actionTypes';

export const chatActions = {
  requestToken: () => (dispatch, getState) => {
    dispatch({
      type: CHAT_TOKEN_REQUEST,
      http: {
        verb: 'POST',
        endpoint: 'api/chatbot/v1/GenerateToken',
        successAction: CHAT_TOKEN_SUCCESS,
        failureAction: CHAT_TOKEN_FAILURE,
      },
    });
  },
};
