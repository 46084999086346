import {
  CONNECT_ASSETCATEGORIES_REQUEST,
  CONNECT_ASSETCATEGORIES_SUCCESS,
  CONNECT_ASSETCATEGORIES_FAILURE,
  CONNECT_ASSETCATEGORYSAVE_SUCCESS,
  CONNECT_ASSETCATEGORYSAVE_FAILURE,
  CONNECT_ASSETCATEGORYSAVE_REQUEST,
  CONNECT_ASSETCATEGORYDELETE_REQUEST,
  CONNECT_ASSETCATEGORYDELETE_SUCCESS,
  CONNECT_ASSETCATEGORYDELETE_FAILURE,
  CONNECT_ASSETS_REQUEST,
  CONNECT_ASSETS_SUCCESS,
  CONNECT_ASSETS_FAILURE,
  CONNECT_ASSETSAVE_REQUEST,
  CONNECT_ASSETSAVE_SUCCESS,
  CONNECT_ASSETSAVE_FAILURE,
  CONNECT_ASSETMOVE_REQUEST,
  CONNECT_ASSETMOVE_SUCCESS,
  CONNECT_ASSETMOVE_FAILURE,
  CONNECT_ASSETDELETE_REQUEST,
  CONNECT_ASSETDELETE_SUCCESS,
  CONNECT_ASSETDELETE_FAILURE,
} from './actionTypes';

export const assetManagementActions = {
  requestAssetCategories: () => (dispatch, getState) => {
    dispatch({
      type: CONNECT_ASSETCATEGORIES_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/Connect/AssetCategoryManagement/v1/Get',
        successAction: CONNECT_ASSETCATEGORIES_SUCCESS,
        failureAction: CONNECT_ASSETCATEGORIES_FAILURE,
      },
    });
  },
  saveAssetCategory: request => (dispatch, getState) => {
    dispatch({
      type: CONNECT_ASSETCATEGORYSAVE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Connect/AssetCategoryManagement/v1/Save',
        successAction: CONNECT_ASSETCATEGORYSAVE_SUCCESS,
        failureAction: CONNECT_ASSETCATEGORYSAVE_FAILURE,
        body: {
          ...request,
        },
      },
    });
  },
  deleteAssetCategory: request => (dispatch, getState) => {
    dispatch({
      type: CONNECT_ASSETCATEGORYDELETE_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: '/api/Connect/AssetCategoryManagement/v1/Delete',
        successAction: CONNECT_ASSETCATEGORYDELETE_SUCCESS,
        failureAction: CONNECT_ASSETCATEGORYDELETE_FAILURE,
        body: {
          ...request,
        },
      },
    });
  },
  requestAssets: (assetCategoryId) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_ASSETS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Connect/AssetManagement/v1/Get?assetCategory=${assetCategoryId}`,
        successAction: CONNECT_ASSETS_SUCCESS,
        failureAction: CONNECT_ASSETS_FAILURE,
      },
    });
  },
  saveAsset: request => (dispatch, getState) => {
    dispatch({
      type: CONNECT_ASSETSAVE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Connect/AssetManagement/v1/Save',
        successAction: CONNECT_ASSETSAVE_SUCCESS,
        failureAction: CONNECT_ASSETSAVE_FAILURE,
        body: {
          ...request,
        },
      },
    });
  },
  moveAsset: request => (dispatch, getState) => {
    dispatch({
      type: CONNECT_ASSETMOVE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Connect/AssetManagement/v1/Move',
        successAction: CONNECT_ASSETMOVE_SUCCESS,
        failureAction: CONNECT_ASSETMOVE_FAILURE,
        body: {
          ...request,
        },
      },
    });
  },
  deleteAsset: request => (dispatch, getState) => {
    dispatch({
      type: CONNECT_ASSETDELETE_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: '/api/Connect/AssetManagement/v1/Delete',
        successAction: CONNECT_ASSETDELETE_SUCCESS,
        failureAction: CONNECT_ASSETDELETE_FAILURE,
        body: {
          ...request,
        },
      },
    });
  },
};
