import React from 'react';

const DropDownSelect = ({ label, options, selectedValue, onChange, hasAllFilter }) => {
  return (
    <div className="c-form-row">
      <label className="c-form-row__label">{label}</label>
      <select className="c-input"
        value={selectedValue}
        onChange={onChange}>
        {hasAllFilter && <option value="-1">All {label}</option>}
        {options && options.map((option) => (
          <option key={option.Id}
            value={option.Id}>{option.Name}</option>
        ))}
      </select>
    </div>
  );
};

export default DropDownSelect;
