import {
  ITEMCATALOGUE_REQUEST,
  ITEMCATALOGUE_SUCCESS,
  ITEMCATALOGUE_FAILURE,
  ITEMDETAIL_REQUEST,
  ITEMDETAIL_SUCCESS,
  ITEMDETAIL_FAILURE,
  ITEMCATALOGUEEXPORT_REQUEST,
  ITEMCATALOGUEEXPORT_SUCCESS,
  ITEMCATALOGUEEXPORT_FAILURE,
} from './actionTypes';

export const itemCatalogueActions = {
  requestItems: (
    filterHazardous,
    storeNumber,
    filter,
    page,
    pageSize,
    status,
  ) => (dispatch, getState) => {
    dispatch({
      type: ITEMCATALOGUE_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/ItemCatalogue/v1/Get?filterHazardous=${
          !filterHazardous ? '' : filterHazardous
        }&storeNumber=${storeNumber}&filter=${filter}&page=${page}&pageSize=${pageSize}&status=${status}`,
        successAction: ITEMCATALOGUE_SUCCESS,
        failureAction: ITEMCATALOGUE_FAILURE,
      },
    });
  },
  requestItemDetail: (storeId, itemId) => (dispatch, getState) => {
    dispatch({
      type: ITEMDETAIL_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/ItemCatalogue/v1/Get/${storeId}/${itemId}`,
        successAction: ITEMDETAIL_SUCCESS,
        failureAction: ITEMDETAIL_FAILURE,
      },
    });
  },
  exportItems: (
    filterHazardous,
    storeNumber,
    filter,
    status,
  ) => (dispatch, getState) => {
    dispatch({
      type: ITEMCATALOGUEEXPORT_REQUEST,
      http: {
        verb: 'GET',
        endpoint:  `/api/ItemCatalogue/v1/Export?filterHazardous=${
          !filterHazardous ? '' : filterHazardous
        }&storeNumber=${storeNumber}&filter=${filter}&status=${status}`,
        successAction: ITEMCATALOGUEEXPORT_SUCCESS,
        failureAction: ITEMCATALOGUEEXPORT_FAILURE,
      },
    });
  },
};
