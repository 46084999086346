import {
  MODULEDATA_REQUEST,
  MODULEDATA_SUCCESS,
  MODULEDATA_FAILURE,
  USERDATA_REQUEST,
  USERDATA_SUCCESS,
  USERDATA_FAILURE,
  ACTIONDATA_REQUEST,
  ACTIONDATA_SUCCESS,
  ACTIONDATA_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const platformUsageReducer = (
  state = initialState.platformUsageState,
  action,
) => {
  switch (action.type) {
  case MODULEDATA_REQUEST: {
    return {
      ...state,
      moduleData: {
        isFetching: true,
        isLoaded: false,
        errorMessage: '',
      },
    };
  }
  case MODULEDATA_SUCCESS: {
    return {
      ...state,
      moduleData: {
        isFetching: false,
        isLoaded: true,
        errorMessage: '',
        data: action.payload,
      },
    };
  }
  case MODULEDATA_FAILURE: {
    return {
      ...state,
      moduleData: {
        isFetching: false,
        isLoaded: false,
        errorMessage: action.payload.message
          ? action.payload.message
          : action.payload.errorMessage
            ? action.payload.errorMessage
            : 'An error has occurred',
      },
    };
  }
  case USERDATA_REQUEST: {
    return {
      ...state,
      userData: {
        isFetching: true,
        isLoaded: false,
        errorMessage: '',
      },
    };
  }
  case USERDATA_SUCCESS: {
    return {
      ...state,
      userData: {
        isFetching: false,
        isLoaded: true,
        errorMessage: '',
        data: action.payload,
      },
    };
  }
  case USERDATA_FAILURE: {
    return {
      ...state,
      userData: {
        isFetching: false,
        isLoaded: false,
        errorMessage: action.payload.message
          ? action.payload.message
          : action.payload.errorMessage
            ? action.payload.errorMessage
            : 'An error has occurred',
      },
    };
  }
  case ACTIONDATA_REQUEST: {
    return {
      ...state,
      actionData: {
        isFetching: true,
        isLoaded: false,
        errorMessage: '',
      },
    };
  }
  case ACTIONDATA_SUCCESS: {
    return {
      ...state,
      actionData: {
        isFetching: false,
        isLoaded: true,
        errorMessage: '',
        data: action.payload,
      },
    };
  }
  case ACTIONDATA_FAILURE: {
    return {
      ...state,
      actionData: {
        isFetching: false,
        isLoaded: false,
        errorMessage: action.payload.message
          ? action.payload.message
          : action.payload.errorMessage
            ? action.payload.errorMessage
            : 'An error has occurred',
      },
    };
  }
  default:
    return state;
  }
};
export default platformUsageReducer;
