import {
  FORECASTCHANGEREQUEST_REQUEST,
  FORECASTCHANGEREQUEST_SUCCESS,
  FORECASTCHANGEREQUEST_FAILURE,
  CHECKITEM_REQUEST,
  CHECKITEM_SUCCESS,
  CHECKITEM_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const forecastChangeRequestReducer = (
  state = initialState.forecastChangeRequestState,
  action,
) => {
  switch (action.type) {
  case FORECASTCHANGEREQUEST_REQUEST: {
    return {
      ...state,
      feedbackSaving: true,
      feedbackSaved: false,
      errorMessage: '',
    };
  }
  case FORECASTCHANGEREQUEST_SUCCESS: {
    return {
      ...state,
      feedbackSaving: false,
      feedbackSaved: true,
      errorMessage: '',
    };
  }
  case FORECASTCHANGEREQUEST_FAILURE: {
    return {
      ...state,
      feedbackSaving: false,
      feedbackSaved: false,
      errorMessage: action.payload.errorMessage,
    };
  }
  case CHECKITEM_REQUEST: {
    return {
      ...state,
      checkingItem: true,
      checkedItem: false,
      requestedItem: action.payload.requestedItem,
      item: {},
      errorMessage: '',
    };
  }
  case CHECKITEM_SUCCESS: {
    return {
      ...state,
      checkingItem: false,
      checkedItem: true,
      item: action.payload,
      errorMessage: '',
    };
  }
  case CHECKITEM_FAILURE: {
    return {
      ...state,
      checkingItem: false,
      checkedItem: true,
      item: {},
      errorMessage: action.payload.errorMessage,
    };
  }
  default:
    return state;
  }
};
export default forecastChangeRequestReducer;
