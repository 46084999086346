import React from "react";

class AssetMoveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      targetCategory: undefined,

      showValidation: false
    };
  }

  handleContinue = e => {
    const { targetCategory } = this.state;

    if (!targetCategory) {
      this.setState({ showValidation: true });
    } else {
      this.props.continue({
        targetAssetCategory: targetCategory
      });
    }
  };

  handleCategoryChange = e => {
    this.setState({ targetCategory: e.target.value });
  };

  render() {
    return (
      <div className="modal-blocker">
        <div className="modal">
          <form>
            <div className="modal-menu">
              <button
                type="button"
                className="action"
                onClick={() => this.props.cancel()}>
                X
              </button>
            </div>
            <div className="modal-title">
              <h2>Category</h2>
            </div>
            <div className="modal-body">
              <label>
                Destination Category
                <select
                  autoFocus
                  value={this.state.targetCategory}
                  className={
                    this.state.showValidation && !this.state.targetCategory
                      ? "invalid"
                      : ""
                  }
                  onChange={e => this.handleCategoryChange(e)}>
                  <option value={0}></option>
                  {this.props.assetcategorys.map(f => (
                    <option value={f.assetCategoryId}>{f.name}</option>
                  ))}{" "}
                </select>
              </label>
            </div>
            <div className="modal-buttons">
              <button
                type="button"
                className="action"
                onClick={() => this.handleContinue()}>
                Move
              </button>
              <button
                type="button"
                className="action negative"
                onClick={() => this.props.cancel()}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default AssetMoveModal;
