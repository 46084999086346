import {
  SITEID_UPLOADEQUITYSTORES_REQUEST,
  SITEID_UPLOADEQUITYSTORES_SUCCESS,
  SITEID_UPLOADEQUITYSTORES_FAILURE,
  SITEID_EQUITY_REQUEST,
  SITEID_EQUITY_SUCCESS,
  SITEID_EQUITY_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const SiteIDEquitytoreReducer = (
  state = initialState.siteIDEquityStoreState,
  action,
) => {
  switch (action.type) {
  case SITEID_EQUITY_REQUEST: {
    return {
      ...state,
      equityStores: [],
      isLoaded: false,
      errorMessage: '',
    };
  }
  case SITEID_EQUITY_SUCCESS: {
    return {
      ...state,
      equityStores: action.payload.equityStores,
      isLoaded: true,
      errorMessage: '',
    };
  }
  case SITEID_EQUITY_FAILURE: {
    return {
      ...state,
      equityStores: [],
      isLoaded: false,
      errorMessage: action.payload.message,
    };
  }
  case SITEID_UPLOADEQUITYSTORES_REQUEST: {
    return {
      ...state,
      isUploading: true,
      isLoaded: false,
      errorMessage: '',
    };
  }
  case SITEID_UPLOADEQUITYSTORES_SUCCESS: {
    return {
      ...state,
      isUploading: false,
      isLoaded: true,
      errorMessage: '',
    };
  }
  case SITEID_UPLOADEQUITYSTORES_FAILURE: {
    return {
      ...state,
      isUploading: false,
      isLoaded: false,
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default SiteIDEquitytoreReducer;
