import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { siteIDDefaultResponseActions } from '../../../actions/siteIDDefaultResponseActions';

class DefaultResponses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMode: 2,
      filterString: '',
      statusId: '',
    };

    this.setDisplayMode = this.setDisplayMode.bind(this);

    this.handleSearch = this.handleSearch.bind(this);

    this.navigateItemDetails = this.navigateItemDetails.bind(this);
  }

  componentDidMount() {
    const { getDefaultResponses } = this.props;

    getDefaultResponses();
  }

  handleSearch(e, field) {
    this.setState({ [field]: e.target.value });
    e.preventDefault();
  }

  setDisplayMode(mode) {
    this.setState({ displayMode: mode });
  }

  navigateItemDetails(id) {
    const { location, push } = this.props;
    const { pathname } = location;

    push(`${pathname}/${id}`);
  }

  render() {
    const {
      defaultResponseList,
      statuses,
    } = this.props;
    const {
      displayMode,
      filterString,
      statusId,
    } = this.state;
    const {
      setDisplayMode,
      navigateItemDetails,
      handleSearch,
    } = this;

    const filteredResponses = defaultResponseList
      .filter((d) => (!statusId
                      || statusId == d.applicableStatusId)
                  && (!filterString
                    || d.title.toLowerCase().indexOf(filterString.toLowerCase()) > -1
                    || d.response.toLowerCase().indexOf(filterString.toLowerCase()) > -1));

    return (
      <div className="page default-responses">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div
                className="icon icon-item-catalogue"
              />
              <h2>
                Default Responses
              </h2>
            </div>
            <div>
              <button
                type="button"
                className="action"
                onClick={() => navigateItemDetails(0)}
              >
                Add Response
              </button>
            </div>

          </div>
        </div>
        <div className="default-responses-list-header page-header">
          <div className="header-buttons">
            <div className="search">
              <input
                type="text"
                placeholder="Search..."
                value={filterString}
                onChange={(e) => handleSearch(e, 'filterString')}
              />
              <select
                type="text"
                placeholder="Status..."
                value={statusId}
                onChange={(e) => handleSearch(e, 'statusId')}
              >
                <option value="">Any Status...</option>
                {statuses && statuses.map((l) => <option key={l.key}
                  value={l.key}>{l.value}</option>)}
              </select>
            </div>
          </div>
        </div>

        <div className="table-container">
          <div className="table-header">
            <div className="display-options">
              Table Size
              <button
                type="button"
                className="icon-display-mode-1"
                aria-label="Table display mode 1"
                disabled={displayMode === 1}
                onClick={() => setDisplayMode(1)}
              />
              <button
                type="button"
                className="icon-display-mode-2"
                aria-label="Table display mode 2"
                disabled={displayMode === 2}
                onClick={() => setDisplayMode(2)}
              />
              <button
                type="button"
                className="icon-display-mode-3"
                aria-label="Table display mode 3"
                disabled={displayMode === 3}
                onClick={() => setDisplayMode(3)}
              />
            </div>
            <h2>
              Default Responses
            </h2>
            <div className="pagination-controls" />
          </div>
          <div className="table-body">
            <table className={`table-display-mode-${displayMode}`}>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Response</th>
                  <th>Applicable Status</th>
                  <th>Route</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {filteredResponses.map((item) => (
                  <tr
                    key={item.id}
                  >
                    <td>{item.title}</td>
                    <td>{item.response}</td>
                    <td>{item.applicableStatusId === null || !statuses || !statuses.find((s) => s.key === item.applicableStatusId) ? 'Any' : statuses.find((s) => s.key === item.applicableStatusId).value}</td>
                    <td>{item.rejection ? 'Rejection' : 'Progression'}</td>
                    <td className="right-cell">
                      <button
                        type="button"
                        className="action"
                        onClick={() => navigateItemDetails(item.id)}
                      >
                        View/Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="table-header" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { siteIDDefaultResponses } = state;
  return {
    isLoading: siteIDDefaultResponses.isLoading,
    isLoaded: siteIDDefaultResponses.isLoaded,
    defaultResponseList: siteIDDefaultResponses.defaultResponseList,
    statuses: siteIDDefaultResponses.statuses,
    errorMessage: siteIDDefaultResponses.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDefaultResponses: () => dispatch(siteIDDefaultResponseActions.getDefaultResponses()),

  push: (path) => dispatch(navigationActions.pushNavigation(path)),
  replace: (path) => dispatch(navigationActions.replaceNavigation(path)),
  reset: () => dispatch(navigationActions.resetNavigation()),
  goBack: () => dispatch(navigationActions.backNavigation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultResponses);
