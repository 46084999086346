import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../../actions/navigationActions';
import { connectTaskActions } from '../../../../actions/connectTaskActions';
import DeleteModal from '../../../shared/DeleteModal';
import { Tooltip } from '@material-ui/core';
import ReminderPanel from './ReminderPanel/ReminderPanel';
import { PieChart, Pie, Cell } from 'recharts';

class TaskEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      //Task details
      taskDetailId: null,
      title: '',
      body: '',
      actionLink: '',
      taskDetailType: 1,

      sentOn: new Date(),
      sentFrom: 'Admin',
      sentBy: '',

      validFrom: undefined,
      validTo: undefined,

      //Audience details
      audienceMode: 'all',
      storeGroupId: undefined,
      storeNumber: undefined,
      targetGroups: [],
      targetStores: [],
      targetMBUs: [],
      //Reminder details
      dueDate: undefined,
      primaryReminder: false,
      secondaryReminder: false,
      overdueReminder: false,
      primaryReminderDaysPrior: null,
      secondaryReminderDaysPrior: null,
      primaryReminderSubject: '',
      secondaryReminderSubject: '',
      overdueReminderSubject: '',
      primaryReminderBody: '',
      secondaryReminderBody: '',
      overdueReminderBody: '',

      primaryScheduledDate: null,
      secondaryScheduledDate: null,
      overdueScheduledDate: null,

      addDocument: false,
      autoCompleteOnDownload: true,
      fileName: '',
      fileData: undefined,
      fileType: null,
      fileExt: '',
      removeDocument: false,

      greenLowerLimit: 0,
      redUpperLimit: 0,
      percentageCompletedTask: 0,
      tempGreenLowerLimit: null,

      storeGroups: [],
      //mbuUsers: [],
      detailsExpanded: true,
      audienceExpanded:false,
      remindersExpanded: false,

      showDeleteModal: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);

    this.handleToggleExpander = this.handleToggleExpander.bind(this);

    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);

    this.handleAudienceAddition = this.handleAudienceAddition.bind(this);
    this.handleAudienceRemoval = this.handleAudienceRemoval.bind(this);

    this.handleRemindersInput = this.handleRemindersInput.bind(this);
    this.handleRemindersCheckedInput = this.handleRemindersCheckedInput.bind(this);
    this.handleFileInputUpdate = this.handleFileInputUpdate.bind(this);

    this.handleGreenLimitBlur = this.handleGreenLimitBlur.bind(this);
    this.handleGreenLimitChange = this.handleGreenLimitChange.bind(this);

    this.handleDownloadDocument = this.handleDownloadDocument.bind(this);
  }

  componentDidMount() {
    let taskId = this.props.match.params.taskId;
    this.props.getTask(taskId || 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLoaded && !prevProps.isLoaded) {

      let notification = this.props.taskDetails.notification;
      let taskDetails = this.props.taskDetails;
      

      let newState = {};

      if (notification) {
        let reminders = notification?.taskDetailReminders;
        let primaryReminder = reminders?.filter(x => x.isPrimary)[0]; 
        console.log(primaryReminder);
        let secondaryReminder = reminders?.filter(x => !x.isPrimary && !x.isOverdueReminder)[0];
        let overdueReminder = reminders?.filter(x => x.isOverdueReminder)[0];

        let audienceMode = 'all';
        let targetGroups = [];
        let targetStores = [];
        //let targetMBUs = [];

        if (notification.allCustomers) {
          audienceMode = 'all';
        } else if (notification.licenseeOnly) {
          audienceMode = 'licensee';
        } else if (notification.retailOnly) {
          audienceMode = 'retail';
        } else if (notification.taskDetailTargetStoreGroupIds && notification.taskDetailTargetStoreGroupIds.length > 0) {
          audienceMode = 'groups';
          targetGroups = notification.taskDetailTargetStoreGroupIds;
        } else if (notification.taskDetailTargetStoreIds && notification.taskDetailTargetStoreIds.length > 0) {
          audienceMode = 'stores';
          targetStores = notification.taskDetailTargetStoreIds;
        /*} else if (notification.taskDetailTargetMBUIds && notification.taskDetailTargetMBUIds.length > 0) {
          audienceMode = 'mbuUsers';
          targetMBUs = notification.taskDetailTargetMBUIds;*/
        } else {
          audienceMode = 'none';
        }

        newState = {
          taskDetailId: notification.taskDetailId,
          title: notification.title,
          body: notification.body,
          actionLink: notification.actionLink,
          notificationType: notification.notificationType,
          sentOn: notification.sentOn,
          sentFrom: notification.sentFrom,
          sentBy: notification.sentBy,
          validFrom: notification.validFrom
            ? new Date(notification.validFrom + 'Z').toISOString().substring(0,10)
            : undefined,
          validTo: notification.validTo
            ? new Date(notification.validTo + 'Z').toISOString().substring(0,10)
            : undefined,
          dueDate: notification.dueDate
            ? new Date(notification.dueDate + 'Z').toISOString().substring(0, 10)
            : undefined,
          taskType: notification.taskType,
          audienceMode: audienceMode,
          targetGroups: targetGroups,
          targetStores: targetStores,
          // targetMBUs: targetMBUs,

          fileName: notification.fileTitle,
          addDocument: notification.fileTitle?.length > 0 ?? false,
          fileExt: notification.fileType?.replace('.', ''),

          percentageCompletedTask: notification.percentageCompleted,
          greenLowerLimit: notification.greenLowerLimit,
          redUpperLimit: notification.redUpperLimit,

          //TODO add the reminders
          primaryReminder: primaryReminder != null,
          secondaryReminder: secondaryReminder != null,
          overdueReminder: overdueReminder != null,
          primaryReminderDaysPrior: primaryReminder?.daysPriorToDueDate ?? null,
          secondaryReminderDaysPrior: secondaryReminder?.daysPriorToDueDate ?? null,
          primaryReminderSubject:  primaryReminder?.reminderTitle ?? '',
          secondaryReminderSubject: secondaryReminder?.reminderTitle ?? '',
          overdueReminderSubject: overdueReminder?.reminderTitle ?? '',
          primaryReminderBody: primaryReminder?.reminderBody ?? '',
          secondaryReminderBody: secondaryReminder?.reminderBody ?? '',
          overdueReminderBody: overdueReminder?.reminderBody?? '',

          primaryScheduledDate: primaryReminder?.scheduledDeliveryDate ? new Date(primaryReminder.scheduledDeliveryDate).toLocaleDateString() : undefined,
          secondaryScheduledDate: secondaryReminder?.scheduledDeliveryDate ? new Date(secondaryReminder.scheduledDeliveryDate).toLocaleDateString() : undefined,
          overdueScheduledDate: notification.dueDate
            ? new Date(notification.dueDate).toLocaleDateString()
            : undefined,
        };
      }

      this.setState({
        ...newState,
        moduleGroups: taskDetails.moduleGroups,
        storeGroups: taskDetails.storeGroups,
        //mbuUsers: taskDetails.mbuUsers,
      });
    } else if (this.props.isSaved && !prevProps.isSaved) {

      this.props.goBack();

    } else if (
      this.props.isdeleted &&
      !prevProps.isdeleted
    ) {
      this.props.goBack();
    }
  }
 
  handleRemindersInput(e, fieldName){
    if (fieldName == 'secondaryReminderDaysPrior'){
      this.setState({ secondaryReminderDaysPrior: Math.max(0, Math.min(e.target.value, this.state.primaryReminderDaysPrior - 1)) });
    }
    else {
      this.setState({ [fieldName]: e.target.value });
    }
  } 

  handleRemindersCheckedInput(e, fieldName){
    this.setState({ [fieldName]: e.target.checked });
  } 

  handleSave(e) {
    let { saveTask } = this.props;
    let {
      taskDetailId,
      title,
      body,
      actionLink,
      taskDetailType,
      sentFrom,
      validFrom,
      validTo,

      dueDate,

      primaryReminder,
      secondaryReminder,
      overdueReminder,
      primaryReminderDaysPrior,
      secondaryReminderDaysPrior,
      primaryReminderSubject,
      secondaryReminderSubject,
      overdueReminderSubject,
      primaryReminderBody,
      secondaryReminderBody,
      overdueReminderBody,

      addDocument,
      fileName,
      fileData,
      fileType,
      autoCompleteOnDownload,
      removeDocument,

      greenLowerLimit,
      redUpperLimit,

      audienceMode,

      targetStores,
      targetGroups,
      //targetMBUs,
    } = this.state;

    saveTask({
      taskDetailId,
      title,
      body,
      actionLink,
      taskDetailType,
      sentFrom,
      validFrom: validFrom ? new Date(validFrom).toISOString() : null,
      validTo: validTo ? new Date(validTo).toISOString() : null,
      dueDate: dueDate? new Date(dueDate).toISOString(): null,

      primaryReminder,
      secondaryReminder,
      overdueReminder,
      
      primaryReminderDaysPrior,
      secondaryReminderDaysPrior,
      primaryReminderSubject,
      secondaryReminderSubject,
      overdueReminderSubject,
      primaryReminderBody,
      secondaryReminderBody,
      overdueReminderBody,

      addDocument,
      fileName,
      fileType,
      fileData,
      autoCompleteOnDownload,
      removeDocument,

      greenLowerLimit,
      redUpperLimit,

      allCustomers: audienceMode === 'all',
      retailOnly: audienceMode === 'retail',
      licenseeOnly: audienceMode === 'licensee',

      storeGroupIds: audienceMode === 'groups' ? targetGroups : [],
      storeNumbers: audienceMode === 'stores' ? targetStores : [],
      //mbuIds: audienceMode === 'mbuUsers' ? targetMBUs : [],
    });

    e.preventDefault();
  }

  handleDeleteModalShow() {
    this.setState({ showDeleteModal: true });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false });
  }

  handleToggleExpander(expanderName) {
    let state = this.state;
    this.setState({ [expanderName]: !state[expanderName] });
  }

  handleDelete() {
    this.props.deleteTask(this.state.taskDetailId);
    this.handleDeleteModalHide();
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  handleCheckInputUpdate(e, name, id) {
    let ids = this.state[name];

    if (e.target.checked) {
      if (ids.indexOf(id) === -1) {
        ids.push(id);
      }
    } else {
      let pos = ids.indexOf(id);
      if (pos !== -1) {
        ids.splice(pos, 1);
      }
    }

    this.setState({
      [name]: ids,
    });
  }

  async handleDownloadDocument() {
    const response = await fetch(`/api/Connect/TaskDetail/v1/DownloadDocument?taskDetailId=${this.state.taskDetailId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });
    if (response.status == 200){
      const data = await response.blob();

      let filename = response.headers.get('content-disposition');
      let nameIndex = filename.indexOf('filename="');

      if (nameIndex > -1) {
        filename = filename.slice(nameIndex + 10);
        nameIndex = filename.indexOf('"');

        if (nameIndex > -1) {
          filename = filename.slice(0, nameIndex);
        }
      } else {
        nameIndex = filename.indexOf('filename=');
        if (nameIndex > -1) {
          filename = filename.slice(nameIndex + 9);

          nameIndex = filename.indexOf(';');

          if (nameIndex > -1) {
            filename = filename.slice(0, nameIndex);
          }
        }
      }

      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(data);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
      
    }
  }

  handleFileInputUpdate(e) {
    if (!e.target.files || e.target.files.length === 0) return;
    let doc = {
      fileName: e.target.files[0].name,
      fileType: e.target.files[0].type,
    };

    var reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }).then(data => {
      doc.fileData = data ? data.replace(/^data:(.*,)?/, '') : '';
      const mime = data.split('/')[1].split(';')[0];
      console.log(mime);

      const extensionMap = {
        'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
        'png': 'png',
        'jpeg': 'jpeg',
        'pdf': 'pdf',
        'plain': 'icon-form',
        'vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      };

      const extension = extensionMap[mime] || '';
      console.log(extension);

      this.setState({ fileData: doc.fileData, fileName: doc.fileName, fileType: doc.fileType, fileExt: extension });
    });
  }

  handleAudienceAddition(rawVal, audienceType) {
    const { [audienceType]: audienceList } = this.state;

    const value = parseInt(rawVal);

    const index = audienceList.indexOf(value);

    if (index === -1 && !isNaN(value)) {
      audienceList.push(value);

      this.setState({ [audienceType]: [...audienceList] });
    }
  }

  handleAudienceRemoval(rawVal, audienceType) {
    const { [audienceType]: audienceList } = this.state;

    const value = parseInt(rawVal);

    const index = audienceList.indexOf(value);

    if (index > -1 && !isNaN(value)) {
      audienceList.splice(index, 1);

      this.setState({ [audienceType]: [...audienceList] });
    }
  }

  handleGreenLimitChange (e) {
    this.setState({ tempGreenLowerLimit: e.target.value });
  }

  handleGreenLimitBlur() {
    const { redUpperLimit } = this.state;
    let tempGreenLimit = parseInt(this.state.tempGreenLowerLimit, 10) || 0;
    tempGreenLimit = Math.max(redUpperLimit + 1, Math.min(100, tempGreenLimit));
    this.setState({ greenLowerLimit: tempGreenLimit, tempGreenLowerLimit: null });
  }

  getTaskPanel() {
    let {
      title,
      body,
      actionLink,
      validFrom,
      validTo,
      detailsExpanded,
      sentFrom,
      dueDate,
      addDocument,
      autoCompleteOnDownload,
      fileName,
      removeDocument,
    } = this.state;

    const {
      taskDetails,
    } = this.props;


    return (
      <div className="accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('detailsExpanded')}>
          <h3>Task</h3>
          <div
            className={`icon ${detailsExpanded ? 'icon-expand' : 'icon-collapse'
            }`}></div>
        </div>
        <div className={`accordion-body ${detailsExpanded ? '' : 'collapsed'}`}>
          <div className="horizontal-layout">
            <div className="vertical-layout">
              <label>
                Title
                <input
                  placeholder="Title"
                  onChange={e => this.handleTextInputUpdate(e, 'title')}
                  value={title}
                  maxLength={256}
                />
              </label>
              <label>
                Area <Tooltip
                  title={'Sets the label and icon for the task.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <select
                  placeholder="sentFrom"
                  onChange={e => this.handleTextInputUpdate(e, 'sentFrom')}
                  value={sentFrom}>
                  <option value="Admin">Admin</option>
                  <option value="Order Management">Order Management</option>
                  <option value="Customer Service">Customer Service</option>
                  <option value="Item Catalogue">Item Catalogue</option>
                  <option value="Document Library">Document Library</option>
                  <option value="Quality Awareness">Quality Assurance</option>
                  <option value="Pricing Catalogue">Pricing Catalogue</option>
                  <option value="Forecasting">Forecasting</option>
                  <option value="Orderly Inventory">Orderly Inventory</option>
                  <option value="Reports">Reports</option>
                  <option value="Policies">Policies</option>
                </select>
              </label>
              <label>
                Message
                <textarea
                  placeholder="Message"
                  onChange={e => this.handleTextInputUpdate(e, 'body')}
                  maxLength={2000}
                  value={body}
                />
              </label>
            </div>
            {/*<div className="vertical-layout">
              
              <label>
                Who should complete?
                <select
                  placeholder="sentFrom"
                  onChange={e => this.handleTextInputUpdate(e, "taskDetailType")}
                  value={taskDetailType}>
                  <option value="1">Individual Users</option>
                  <option value="2">One User Per Store</option>
                </select>
              </label>
            </div>*/}
            <div className="vertical-layout">
              <label>
                Due Date <Tooltip
                  title={'Sets the date the task should be completed by.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <input
                  placeholder="Valid To"
                  type="date"
                  onChange={e => this.handleTextInputUpdate(e, 'dueDate')}
                  value={dueDate}
                />
              </label>
              <label>
                Action Link <Tooltip
                  title={'Either an internal or external link to additional information. \
                        Internal links should begin with a \'/\' external links should begin with \'https://\'.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <input
                  placeholder="Action Link"
                  onChange={e => this.handleTextInputUpdate(e, 'actionLink')}
                  value={actionLink}
                />
              </label>
              <label>
                Valid From <Tooltip
                  title={'The date the task should become visible from.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <input
                  placeholder="Valid From"
                  type="date"
                  onChange={e => this.handleTextInputUpdate(e, 'validFrom')}
                  value={validFrom}
                />
              </label>
              <label>
                Valid To <Tooltip
                  title={'The date the task should stop being visible.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <input
                  placeholder="Valid To"
                  type="date"
                  onChange={e => this.handleTextInputUpdate(e, 'validTo')}
                  value={validTo}
                />
              </label>
            </div>

            <div className="vertical-layout">
              {taskDetails?.notification?.fileTitle == null &&
                            <label>
                              Add Document
                              <div className="toggle-wrapper">
                                <input
                                  type="checkbox"
                                  className="toggle"
                                  onChange={(e) => this.setState({ addDocument: e.target.checked })}
                                  checked={addDocument}
                                />
                                <div className="toggle-icon"></div>
                              </div>
                            </label>
              }
              {addDocument && taskDetails?.notification?.fileTitle == null &&
              <>
                <label className="file-upload-wrapper">
                  <div className="icon icon-image-upload"></div>
                  <p>
                    {!fileName &&
                  'Tap to attach a file to this task.'}
                    {fileName}
                  </p>
                  <input
                    name="file"
                    id="file"
                    className="inputfile"
                    type="file"
                    onChange={e =>
                      this.handleFileInputUpdate(
                        e,
                        'fileData',
                        'fileName',
                      )
                    }
                  />
                </label>
                <label>
                File Name
                  <input
                    placeholder="File name..."
                    type="text"
                    onChange={e => this.handleTextInputUpdate(e, 'fileName')}
                    value={fileName}
                  />
                </label>
                {this.state.fileExt?.length > 0 &&
                <div
                  className={`icon icon-file new ${this.state.fileExt}`                       
                  }
                />
                }
                <label>
                Auto-complete task on download
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      onChange={(e) => this.setState({ autoCompleteOnDownload: e.target.checked })}
                      checked={autoCompleteOnDownload}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
              </>
              }
              {addDocument && taskDetails?.notification?.fileTitle?.length > 0 && 
              <>
                <label>
                Remove this attachment
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      onChange={(e) => this.setState({ removeDocument: e.target.checked })}
                      checked={removeDocument}
                    />
                    <div className="toggle-icon invalid"></div>
                  </div>
                </label>
                <div className='documents-wrapper'>
                  <div className='document-details'>
                    <div className='form-buttons'>
                    </div>
                    <div style={{ fontWeight:'bold', textAlign:'center', marginBottom: '10px' }}>File Name
                    </div>

                    <div className='file-title'>{fileName}</div>
                    <div className="form-buttons">
                      <button type="button"
                        className="action download"
                        onClick={() => this.handleDownloadDocument()}>
                        <div
                          className="icon icon-download-light"
                          alt="Export"
                        />
                      </button>
                    </div>
                    {this.state.fileExt?.length > 0 &&
                <div
                  className={`icon icon-file ${this.state.fileExt}`                       
                  }
                />
                    }
                  </div>
                </div>
                {!removeDocument &&
                <label>
                Auto-complete task on download
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      onChange={(e) => this.setState({ autoCompleteOnDownload: e.target.checked })}
                      checked={autoCompleteOnDownload}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                }
              </>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

  getAudiencePanel() {
    const {
      audienceMode,
      storeGroupId,
      //key,
      storeGroups,
      //mbuUsers,
      targetStores,
      targetGroups,
      //targetMBUs,
      storeNumber,
      greenLowerLimit,
      redUpperLimit,
      percentageCompletedTask,
      audienceExpanded,
    } = this.state;

    const {
      tenantKey,
    } = this.props;

    const isEMEA = tenantKey === 'EMEA';

    const data = [
      { name: 'Completed', value: percentageCompletedTask * 10 },
      { name: 'Not Completed', value: 1000 - (percentageCompletedTask * 10) },
    ];
    var isGreen = percentageCompletedTask * 10 >= greenLowerLimit * 10;
    var isAmber = percentageCompletedTask * 10 < greenLowerLimit * 10 && percentageCompletedTask * 10 >= redUpperLimit * 10;
    var isRed = percentageCompletedTask * 10 < redUpperLimit * 10;
    const COLORS = [isGreen ? '#02a862' : isAmber ? '#faad00' : isRed ? '#ed4646' : '', '#D3D3D3'];

    return (
      <div className="accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('audienceExpanded')}>
          <h3>Audience</h3>
          <div
            className={`icon ${audienceExpanded ? 'icon-expand' : 'icon-collapse'
            }`}></div>
        </div>
        <div className={`accordion-body ${audienceExpanded ? '' : 'collapsed'}`}>
          <div className="horizontal-layout">
            <label>
              Target Audience...
              <label><input type="radio"
                name="audienceMode"
                value="all"
                checked={audienceMode === 'all'}
                onChange={(e) => this.setState({ audienceMode: e.target.value })} /> All Connect Users <Tooltip
                title={'Display this task to all connect users.'}>
                <span className="info">&#x1F6C8;</span></Tooltip></label>
              <label><input type="radio"
                name="audienceMode"
                value="retail"
                checked={audienceMode === 'retail'}
                onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Retail Users <Tooltip
                title={'Display this task to only retail users.'}>
                <span className="info">&#x1F6C8;</span></Tooltip></label>
              <label><input type="radio"
                name="audienceMode"
                value="licensee"
                checked={audienceMode === 'licensee'}
                onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Licensee Users <Tooltip
                title={'Display this task to only licensee users.'}>
                <span className="info">&#x1F6C8;</span></Tooltip></label>
              {isEMEA ? (
                <label><input type="radio"
                  name="audienceMode"
                  value="groups"
                  checked={audienceMode === 'groups'}
                  onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Store Groups <Tooltip
                  title={'Display this task to users in any of the selected store groups.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip></label>
              ): 
                <label><input type="radio"
                  name="audienceMode"
                  value="groups"
                  checked={audienceMode === 'groups'}
                  onChange={(e) => this.setState({ audienceMode: e.target.value })} /> MBU Groups <Tooltip
                  title={'Display this task to users in any of the selected MBU groups.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip></label>
              }
              {isEMEA ? (
                <label><input type="radio"
                  name="audienceMode"
                  value="stores"
                  checked={audienceMode === 'stores'}
                  onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Individual Stores <Tooltip
                  title={'Display this task to users with access to any of the selected stores.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip></label>
              ): 
                <label><input type="radio"
                  name="audienceMode"
                  value="stores"
                  checked={audienceMode === 'stores'}
                  onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Individual Customers <Tooltip
                  title={'Display this task to users with access to any of the selected customers.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip></label>
              }
              {/*{!isEMEA &&
                        <label><input type="radio"
                          name="audienceMode"
                          value="mbuUsers"
                          checked={audienceMode === 'mbuUsers'}
                          onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Individual MBU Users <Tooltip
                          title={'Display this content to users with access to any of the selected MBU Users.'}>
                          <span className="info">&#x1F6C8;</span></Tooltip></label>
              }*/}
            </label>

            {audienceMode === 'groups' && isEMEA ?
              <div>
                <label>
                  Store Groups
                  <div className="floating-header">
                    <select
                      placeholder="..."
                      required
                      value={storeGroupId}
                      onChange={e => this.setState({ storeGroupId: e.target.value })}
                    >
                      <option value="">Select one...</option>
                      {storeGroups && storeGroups.map(l =>
                        <option key={l.storeGroupId}
                          value={l.storeGroupId}>{l.storeGroupName}</option>)
                      }
                    </select>
                    <button
                      type="button"
                      className="action"
                      onClick={() => this.handleAudienceAddition(storeGroupId, 'targetGroups')}
                    >
                      Add
                    </button>
                  </div>
                </label>
                <div>
                  {targetGroups && targetGroups.length > 0 && targetGroups.map(g => {

                    let group = storeGroups.find(a => a.storeGroupId.toString() === g.toString());

                    if (group) {
                      return (<label
                        key={group.storeGroupId}
                      >
                        {group.storeGroupName}
                        <button
                          style={{ marginLeft: '10px' }}
                          type="button"
                          className="action negative"
                          onClick={() => this.handleAudienceRemoval(group.storeGroupId, 'targetGroups')}
                        >Remove</button>
                      </label>
                      );
                    }

                    return null;
                  },
                  )}
                </div>
              </div> : null}
            {audienceMode === 'groups' && !isEMEA ?
              <div>
                <label>
                  MBU Groups
                  <div className="floating-header">
                    <select
                      placeholder="..."
                      required
                      value={storeGroupId}
                      onChange={e => this.setState({ storeGroupId: e.target.value })}
                    >
                      <option value="">Select one...</option>
                      {storeGroups && storeGroups.map(l =>
                        <option key={l.storeGroupId}
                          value={l.storeGroupId}>{l.storeGroupName}</option>)
                      }
                    </select>
                    <button
                      type="button"
                      className="action"
                      onClick={() => this.handleAudienceAddition(storeGroupId, 'targetGroups')}
                    >
                      Add
                    </button>
                  </div>
                </label>
                <div>
                  {targetGroups && targetGroups.length > 0 && targetGroups.map(g => {

                    let group = storeGroups.find(a => a.storeGroupId.toString() === g.toString());

                    if (group) {
                      return (<label
                        key={group.storeGroupId}
                      >
                        {group.storeGroupName}
                        <button
                          type="button"
                          className="action negative"
                          onClick={() => this.handleAudienceRemoval(group.storeGroupId, 'targetGroups')}
                        >Remove</button>
                      </label>
                      );
                    }

                    return null;
                  },
                  )}
                </div>
              </div> : null}
            {audienceMode === 'stores' && isEMEA ? <div>
              <label>
                Stores
                <div className="floating-header">
                  <input
                    placeholder="..."
                    required
                    type="number"
                    onChange={(e) => this.setState({ storeNumber: e.target.value })}
                    value={storeNumber}
                  />
                  <button
                    type="button"
                    className="action"
                    onClick={() => this.handleAudienceAddition(storeNumber, 'targetStores')}
                  >
                    Add
                  </button>
                </div>
              </label>
              <div>
                {targetStores && targetStores.length > 0 && targetStores.map(g => {
                  return (<label
                    key={g}
                  >
                    {g}
                    <button
                      type="button"
                      className="action negative"
                      onClick={() => this.handleAudienceRemoval(g, 'targetStores')}
                    >Remove</button>
                  </label>
                  );
                },
                )}
              </div>
            </div> : null}
            {audienceMode === 'stores' && !isEMEA? <div>
              <label>
                Customers
                <div className="floating-header">
                  <input
                    placeholder="..."
                    required
                    type="number"
                    onChange={(e) => this.setState({ storeNumber: e.target.value })}
                    value={storeNumber}
                  />
                  <button
                    type="button"
                    className="action"
                    onClick={() => this.handleAudienceAddition(storeNumber, 'targetStores')}
                  >
                    Add
                  </button>
                </div>
              </label>
              <div>
                {targetStores && targetStores.length > 0 && targetStores.map(g => {
                  return (<label
                    key={g}
                  >
                    {g}
                    <button
                      type="button"
                      className="action negative"
                      onClick={() => this.handleAudienceRemoval(g, 'targetStores')}
                    >Remove</button>
                  </label>
                  );
                },
                )}
              </div>
            </div> : null}
            {/*{audienceMode === 'mbuUsers' && !isEMEA ?
              <div>

                <label>
                  Individual MBUs
                  <div className="floating-header">
                    <select
                      placeholder="..."
                      required
                      value={key}
                      onChange={e => this.setState({ key: e.target.value })}
                    >
                      <option value="">Select one...</option>
                      {mbuUsers && mbuUsers.map(l =>
                        <option key={l.key}
                          value={l.key}>{l.value}</option>)
                      }
                    </select>
                    <button
                      type="button"
                      className="action"
                      onClick={e => this.handleAudienceAddition(key, 'targetMBUs')}
                    >
                      Add
                    </button>
                  </div>
                </label>
                <div>

                  {targetMBUs && targetMBUs.length > 0 && targetMBUs.map(g => {

                    let group = mbuUsers.find(a => a.key.toString() === g.toString());

                    if (group) {
                      return (<label
                        key={group.key}
                      >
                        {group.value}
                        <button
                          type="button"
                          className="action negative"
                          onClick={e => this.handleAudienceRemoval(group.key, 'targetMBUs')}
                        >Remove</button>
                      </label>
                      );
                    }

                    return null;
                  },
                  )}
                </div>


                </div> : null}*/} 
          </div>
          <div className='horizontal-layout'
            style={{ marginTop: '40px' }}>
            <label>
                Task Completion Targets <Tooltip
                title={'These values help to visualise the completion percentage of each task (shown in a progress bar). These are the default values that will be added to new tasks. They can then be overwritten via the task edit page for each specific task.'}>
                <span className="info"
                  style={{ fontWeight: 200 }}>&#x1F6C8;</span></Tooltip>
              <div className='pie-and-values-wrapper'>
                <div className='user-values'>
                  <div className='user-value-item'>
                    <label>
                        Green (Target Line)
                    </label>
                    <div className='percentage-wrapper'>
                      <span> x &ge; </span>
                      <input type='number'
                        value={this.state.tempGreenLowerLimit !== null ? this.state.tempGreenLowerLimit : this.state.greenLowerLimit}
                        max={100}
                        min={this.state.redUpperLimit + 1}
                        onBlur={this.handleGreenLimitBlur}
                        onChange={this.handleGreenLimitChange}
                      />
                      <div className='percentage'>%</div>
                    </div>
                  </div>
                    
                  <div className='user-value-item'>
                    <label>
                        Amber
                    </label>
                    <div className='percentage-wrapper'>
                      <input type='number'
                        disabled
                        value={this.state.redUpperLimit}
                      />
                      <span> &le; x &lt;</span>
                      <input type='text'
                        disabled
                        value={this.state.greenLowerLimit}
                      />
                      <div className='percentage'>%</div>
                    </div>
                  </div>
                   
                  <div className='user-value-item'>
                    <label>
                        Red
                    </label>
                    <div className='percentage-wrapper'>
                      <span> x &lt;</span>
                      <input type='number' 
                        max={this.state.greenLowerLimit - 1}
                        min={0}
                        value={this.state.redUpperLimit}
                        onChange={(e) => this.setState({ redUpperLimit: Math.max(0, Math.min(e.target.value, this.state.greenLowerLimit - 1)) })}
                      />
                      <div className='percentage'>%</div>
                    </div>
                  </div>
                </div>
              </div>
            </label>
            <label>
                Current Task Progress
              <Tooltip
                style={{ marginLeft: '5px' }}
                title={`Percentage Complete : ${this.state.percentageCompletedTask.toString().substring(0,5)}%`}>
                <span className="info">&#x1F6C8;</span></Tooltip>
              <PieChart width={250}
                height={140}>
                <Pie
                  data={data}
                  cx={100}
                  cy={100}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </label>
          </div>
        </div>
      </div>);
  }

  getReminderPanel() {
    const { remindersExpanded, primaryReminderDaysPrior, secondaryReminderDaysPrior, primaryReminderSubject, secondaryReminderSubject, overdueReminderSubject, primaryReminderBody,
      secondaryReminderBody, overdueReminderBody, primaryReminder, taskDetailId, secondaryReminder, overdueReminder, primaryScheduledDate, secondaryScheduledDate, overdueScheduledDate,
    } = this.state;

    const { handleRemindersInput, handleRemindersCheckedInput, handleToggleExpander } = this;

    return (
      <ReminderPanel 
        taskDetailId={taskDetailId}
        isCreate={this.props.match.params.taskId == 0}
        remindersExpanded={remindersExpanded}
        primaryReminderDaysPrior={primaryReminderDaysPrior}
        secondaryReminderDaysPrior={secondaryReminderDaysPrior}
        primaryReminderSubject={primaryReminderSubject}
        secondaryReminderSubject={secondaryReminderSubject}
        overdueReminderSubject={overdueReminderSubject}
        primaryReminderBody={primaryReminderBody}
        secondaryReminderBody={secondaryReminderBody}
        overdueReminderBody={overdueReminderBody}
        primaryReminder={primaryReminder}
        secondaryReminder={secondaryReminder}
        overdueReminder={overdueReminder}
        primaryScheduledDate={primaryScheduledDate}
        secondaryScheduledDate={secondaryScheduledDate}
        overdueScheduledDate={overdueScheduledDate}
        handleRemindersInput={(e, fieldName) => handleRemindersInput(e, fieldName)}
        handleRemindersCheckedInput={(e, fieldName) => handleRemindersCheckedInput(e, fieldName)}
        handleToggleExpander={(val) => handleToggleExpander(val)}
      />
    );
  }

  render() {
    let { showDeleteModal, primaryReminderDaysPrior, secondaryReminderDaysPrior, primaryReminderSubject, secondaryReminderSubject, overdueReminderSubject, primaryReminderBody,
      secondaryReminderBody, overdueReminderBody, primaryReminder, secondaryReminder, overdueReminder } = this.state;

    const isPrimaryReminderValid =
  primaryReminder && primaryReminderDaysPrior > 0 && primaryReminderSubject.length > 0 && primaryReminderBody.length > 0;

    const isOverdueReminderValid =
  overdueReminder && overdueReminderSubject.length > 0 && overdueReminderBody.length > 0;

    const isSecondaryReminderValid =
  secondaryReminder && secondaryReminderDaysPrior > 0 && secondaryReminderSubject.length > 0 && secondaryReminderBody.length > 0;

    const isValid =
  (primaryReminder ? isPrimaryReminderValid : true) &&
  (secondaryReminder ? isSecondaryReminderValid : true) && 
  (overdueReminder ? isOverdueReminderValid : true);
        
    return (
      <div className="edit-task-page page">
        <div className="floating-panel">
          <form noValidate
            onSubmit={e => this.handleSave(e)}>
            <div className="floating-header">
              <div className="header-title">
                <div className="icon icon-alert"></div>
                {this.state.taskDetailId ? (
                  <h2>Edit Task</h2>
                ) : (
                  <h2>Create Task</h2>
                )}
              </div>
            </div>

            {this.getTaskPanel()}
            {this.getAudiencePanel()}
            {this.getReminderPanel()}

            <div className="button-container"
              style={{ marginTop:'10px' }}
            >
              {this.state.taskDetailId && (
                <button
                  type="button"
                  className="action negative"
                  onClick={() => this.handleDeleteModalShow()}>
                  Delete
                </button>
              )}
              <button className="action"
                disabled={!isValid}
                style={{ marginLeft: '5px' }}>Save</button>
            </div>
          </form>
          {showDeleteModal && (
            <DeleteModal
              cancel={this.handleDeleteModalHide}
              continue={this.handleDelete}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.connectTask.isFetching,
    isLoaded: state.connectTask.isLoaded,
    taskDetails: state.connectTask.currentAdminAlert,
    // mbuUsers: state.connectTask.mbuUsers,
    isSaving: state.connectTask.isSaving,
    isSaved: state.connectTask.isSaved,
    isdeleting: state.connectTask.isDeleted,
    isdeleted: state.connectTask.isDeleted,
    tenantKey: state.auth.tenant,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTask: taskDetailId =>
      dispatch(connectTaskActions.requestEditableTask(taskDetailId)),
    saveTask: notificationData =>
      dispatch(connectTaskActions.saveTask(notificationData)),
    deleteTask: taskDetailId =>
      dispatch(connectTaskActions.deleteTask(taskDetailId)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskEdit);
