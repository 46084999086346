import {
  LSPOS_ITEMNUMBER_REQUEST,
  LSPOS_ITEMNUMBER_SUCCESS,
  LSPOS_ITEMNUMBER_FAILURE,
  LSPOS_ITEMNUMBER_DETAIL_REQUEST,
  LSPOS_ITEMNUMBER_DETAIL_SUCCESS,
  LSPOS_ITEMNUMBER_DETAIL_FAILURE,
  LSPOS_ITEMNUMBER_SAVE_REQUEST,
  LSPOS_ITEMNUMBER_SAVE_SUCCESS,
  LSPOS_ITEMNUMBER_SAVE_FAILURE,
} from './actionTypes';

export const posItemNumberRequestActions = {
  requestItems: (
    page, pageSize, filter, 
  ) => (dispatch) => {
    dispatch({
      type:  LSPOS_ITEMNUMBER_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `api/LsPos/ItemNumber/v1/list?filter=${filter}&page=${page}&pageSize=${pageSize}`,
        successAction: LSPOS_ITEMNUMBER_SUCCESS,
        failureAction: LSPOS_ITEMNUMBER_FAILURE,
      },
    });
  },
  requestItemDetail: (itemId) => (dispatch) => {
    dispatch({
      type: LSPOS_ITEMNUMBER_DETAIL_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/LsPos/ItemNumber/v1/detail/${itemId}`,
        successAction: LSPOS_ITEMNUMBER_DETAIL_SUCCESS,
        failureAction: LSPOS_ITEMNUMBER_DETAIL_FAILURE,
      },
    });
  },
  saveForm: submitForm => (dispatch) => {
    dispatch({
      type: LSPOS_ITEMNUMBER_SAVE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/LsPos/ItemNumber/v1/',
        successAction: LSPOS_ITEMNUMBER_SAVE_SUCCESS,
        failureAction: LSPOS_ITEMNUMBER_SAVE_FAILURE,
        body: {
          ...submitForm,
        },
      },
    });
  } };