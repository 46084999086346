import React from 'react';
import { connect } from 'react-redux';
import { vasActions } from '../../../actions/vasActions';

class VasPriceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayMode: 2,
    };
    this.setDisplayMode = this.setDisplayMode.bind(this);
  }

  componentDidMount() {
    this.props.getVasDetails();
  }

  setDisplayMode(mode) {
    this.setState({ displayMode: mode });
  }

  render() {
    const { vasDetails } = this.props;
    const { displayMode } = this.state;
    const { setDisplayMode } = this;
    return (
      <div className="vas-page page">
        <div className="table-container">
          <div className="table-header">
            <div className="display-options">
              Table Size
              <button
                type="button"
                className="icon-display-mode-1"
                disabled={displayMode === 1}
                onClick={() => setDisplayMode(1)}></button>
              <button
                type="button"
                className="icon-display-mode-2"
                disabled={displayMode === 2}
                onClick={() => setDisplayMode(2)}></button>
              <button
                type="button"
                className="icon-display-mode-3"
                disabled={displayMode === 3}
                onClick={() => setDisplayMode(3)}></button>
            </div>
            <h2>Value Added Services Price List (Kuehne + Nagel)</h2>
            <div className="pagination-controls"></div>
          </div>
          <div className="table-body">
            <table className={`table-display-mode-${displayMode}`}>
              <thead>
                <tr>
                  <th>Service</th>
                  <th>Pricing</th>
                </tr>
              </thead>
              <tbody>
                {vasDetails &&
                  vasDetails.map(vas => (
                    <tr key={vas.vasid}>
                      <td>{vas.fieldName}</td>
                      <td dangerouslySetInnerHTML={{ __html: vas.contents }} />
                    </tr>
                  ))}
              </tbody>
            </table>
            <p>Please note that prices and services are subject to change</p>
            <p>
              Starbucks takes no responsibility for the consequences of error or
              for any loss or damage suffered by users of any of the information
              published in this price list. The price and services listed are
              subject to change.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.vas.isFetching,
  loaded: state.vas.isLoaded,
  vasDetails: state.vas.vasDetails,
});

const mapDispatchToProps = dispatch => {
  return {
    getVasLists: () => dispatch(vasActions.requestVasList()),
    getVasDetails: vasId => dispatch(vasActions.requestVasDetail(vasId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VasPriceList);
