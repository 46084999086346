import {
  CONNECT_CONFIGURATION_REQUEST,
  CONNECT_CONFIGURATION_SUCCESS,
  CONNECT_CONFIGURATION_FAILURE,
  CONNECT_CONFIGURATIONSAVE_REQUEST,
  CONNECT_CONFIGURATIONSAVE_SUCCESS,
  CONNECT_CONFIGURATIONSAVE_FAILURE,
} from './actionTypes';

export const connectConfigurationActions = {
  getConfiguration: () => (dispatch, getState) => {
    dispatch({
      type: CONNECT_CONFIGURATION_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/Connect/configuration/v1/get',
        successAction: CONNECT_CONFIGURATION_SUCCESS,
        failureAction: CONNECT_CONFIGURATION_FAILURE,
      },
    });
  },
  saveConfiguration: configuration => (dispatch, getState) => {
    dispatch({
      type: CONNECT_CONFIGURATIONSAVE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Connect/configuration/v1/save',
        successAction: CONNECT_CONFIGURATIONSAVE_SUCCESS,
        failureAction: CONNECT_CONFIGURATIONSAVE_FAILURE,
        body: {
          ...configuration,
        },
      },
    });
  },
};
