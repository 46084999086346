import React from 'react';
import { connect } from 'react-redux';
import { connectWeatherActions } from '../../../../actions/connectWeatherActions';
import { navigationActions } from '../../../../actions/navigationActions';
import { sideBarActions } from '../../../../actions/sideBarActions';
import { authActions } from '../../../../actions/authActions';
import WeatherTileHorizontal from './weatherTileHorizontal/weatherTileHorizontal';
import WeatherTileVertical from './weatherTileVertical/weatherTileVertical';

class ConnectWeather extends React.Component {
  componentDidMount() {
    this.props.getWeather();
  }

  render() {
    const { weather, error } = this.props;

    let today = undefined, week = [];

    if (weather && weather.daily && weather.daily.length > 0) {
      today = weather.daily[0];
      week = weather.daily.slice(1);
    }

    return (
      <div className="connect-weather">
        <div className="weather-stack">
          {!error ?
            <>
              <div>
                Current
                <WeatherTileHorizontal data={today} />
              </div>
              <div>
                Forecast
                <div className="weather-week">
                  {week && week.map(w => <WeatherTileVertical key={w.dt}
                    data={w} />)}
                </div>
              </div>
            </>
            :
            <div className="error-stack">
              <div>
                The weather cannot be loaded for your current location. Please update your postcode in your account settings.
              </div>
              <button
                className="float"
                type="button"
                onClick={() => {
                  this.props.push('/myaccount');
                  this.props.hideConnect();
                  this.props.toggleSideBar(false);
                }}
              >
                Account Settings
              </button>
            </div>
          }
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  weather: state.connectWeather.weather,
  error: state.connectWeather.error,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    getWeather: () => dispatch(connectWeatherActions.getWeather()),

    hideConnect: () => dispatch(authActions.hideConnect()),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),

    toggleSideBar: isOpen => dispatch(sideBarActions.toggleSideBar(isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectWeather);
