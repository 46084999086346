export const ReasonCodeEnum = {
  AC  : 'AC',
  AN  : 'AN',
  AO  : 'AO',
  BO  : 'BO',
  CO  : 'CO',
  CSL : 'CSL',
  COP : 'COP',
  LB  : 'LB',
  EO  : 'EO',
  FE  : 'FE',
  FL  : 'FL',
  ID  : 'ID',
  MF  : 'MF',
  MTO : 'MTO',
  O   : 'O',
  OE  : 'OE',
  OF  : 'OF',
  PG  : 'PG',
  PO  : 'PO',
  PP  : 'PP',
  PPS : 'PPS',
  RI  : 'RI',
  RS  : 'RS',
  SO  : 'SO',
  ST  : 'ST',
  UF  : 'UF',
  WF  : 'WF',
  WO  : 'WO',
  TD  : 'TD',
};





// AC, //ApprovedChange          
// AN, //ActionNotice            
// AO, //AdvanceOrder            
// BO, //BackOrder               
// CO, //ContainerOptimization   
// CSL,//ChangeSourcingLocation  
// COP,//CutOffPassed            
// LB, //LocalBuy                
// EO, //EmergencyOrder          
// FE, //ForecastError           
// FL, //FullLocking             
// ID, //ItemDiscontinued        
// MF, //MissingForecast         
// MTO,//MakeToOrder             
// O,  //Others                  
// OE, //OrderExpedited          
// OF, //OverForecast            
// PG, //PromoGrid               
// PO, //PostponeOrder           
// PP, //PickPack                
// PPS,//PastPullSupport         
// RI, //RegulatoryIssue         
// RS, //RemainingShelfLife      
// SO, //StockOut                
// ST, //SKUTransition           
// UF, //UnderForecast           
// WF, //WrongForecast           
// WO, //WriteOff                
// TD, //TransferDemand