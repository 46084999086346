import React from 'react';
 
const ApacContactDetails=()=>
{
  return (<>
    <div className="form-body form-body-top">
      <div>
        <h4>APAC Customers</h4>
      </div>
    </div>
    <div className="form-body form-body-top">
      <div>
        <h5>General Queries Customer Service</h5>
        <label>
          <span>Email</span>
          <a href="mailto:apcsr.shipment@starbucks.com">apcsr.shipment@starbucks.com</a>
        </label>
      </div>
    </div>
          
    <div>
      <h4>Shipments</h4>

      <p>
              If you have any shipment queries please email:
      </p>
      <p>
        <a href="mailto:apcsr.shipment@starbucks.com ">
                apcsr.shipment@starbucks.com 
        </a>{' '}
      </p>

      <h4>Claims</h4>
      <p>
              If you have any claim related queries, please contact{' '}
        <a href="mailto:mapcsr.claim@starbucks.com">
                apcsr.claim@starbucks.com
        </a>
      </p>

      <h4>Orders</h4>
      <p>
              For enquiries related to orders, please
              contact{' '}
        <a href="mailto:apcsr.order@starbucks.com">
                 apcsr.order@starbucks.com
        </a>
      </p>
      <p>
              Please ensure that you have the Starbucks order number to hand.
      </p>
    </div>       
  </>);
};
 
export default ApacContactDetails;