import React from 'react';

const Tile = props => {
  const moduleItem = props.data;
  const { onMenuFolderClicked } = props;
  const iconClass =
    moduleItem.iconType === 1 ? moduleItem.iconData.toLowerCase() : '';

  return (
    <div
      className={`tile ${moduleItem.moduleId === -1000 ? 'disabled' : ''}`}
      onClick={e => {
        if (moduleItem.moduleId !== -1000) {
          onMenuFolderClicked(
            moduleItem.type,
            moduleItem.data,
            moduleItem.moduleId,
          );
        }
      }}
    >
      {(moduleItem.moduleId === 550 ||
        moduleItem.moduleId === 549 ||
        moduleItem.moduleId === 906) && <div className="new-tag"></div>}
      {moduleItem.moduleId === -1000 && <div className="coming-soon-tag"></div>}
      <div
        className={`icon ${iconClass} ${
          iconClass === 'icon-file' &&
            moduleItem.description.indexOf(
              '.',
              moduleItem.description.length - 5,
            ) > -1
            ? moduleItem.description.substring(
              moduleItem.description.indexOf(
                '.',
                moduleItem.description.length - 5,
              ) + 1,
            )
            : ''
        }`}
      />
      <div className={`tile-text ${moduleItem.type === 6 ? 'file-text' : ''}`}>
        {moduleItem.type === 3 && (
          <div className={'icon icon-external-link'}></div>
        )}
        {moduleItem.type === 6 && (
          <div className={'icon icon-external-download'}>{moduleItem.size > 0 && (<span className="file-size">{moduleItem.size > 1024 ? `(${(moduleItem.size / 1024).toFixed(1)}Mb)` : `(${moduleItem.size}Kb)`} </span>)}</div>
        )}
        <div className={`tile-name ${moduleItem.type === 6 ? 'file-name' : ''}`}>
          {moduleItem.type === 6 && !moduleItem.title
            ? 'Download'
            : moduleItem.title}
        </div>
        {moduleItem.parentMenuId === null &&
          moduleItem.type !== 6 &&
          moduleItem.description && (
          <div className="tile-description">{moduleItem.description}</div>
        )}
      </div>
    </div>
  );
};

export default Tile;
