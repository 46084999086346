import React from 'react';

const ChatRoomTile = props => {
  const {
    active,
    iconClass,
    title,
    description,
    activeAction,
    inactiveAction,
  } = props;

  return (
    <div
      className={`tile ${active ? '' : 'disabled'}`}
      onClick={e => {
        if (active && activeAction) {
          activeAction();
        } else if (!active && inactiveAction) {
          inactiveAction();
        }
      }}>
      {active ? (
        <div className="active-tag"></div>
      ) : (
        <div className="inactive-tag"></div>
      )}
      <div className={`icon ${iconClass}`} />
      <div className="tile-text">
        <div className="tile-name">{title}</div>
        <div className="tile-description">{description ? description : ''}</div>
      </div>
    </div>
  );
};

export default ChatRoomTile;
