import {
  SITEID_DEFAULTRESPONSE_REQUEST,
  SITEID_DEFAULTRESPONSE_SUCCESS,
  SITEID_DEFAULTRESPONSE_FAILURE,
  SITEID_DEFAULTRESPONSES_REQUEST,
  SITEID_DEFAULTRESPONSES_SUCCESS,
  SITEID_DEFAULTRESPONSES_FAILURE,
  SITEID_SAVERESPONSE_REQUEST,
  SITEID_SAVERESPONSE_SUCCESS,
  SITEID_SAVERESPONSE_FAILURE,
  SITEID_DELETERESPONSE_REQUEST,
  SITEID_DELETERESPONSE_SUCCESS,
  SITEID_DELETERESPONSE_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const SiteIDDefaultResponseRouter = (
  state = initialState.siteIDDefaultResponseState,
  action = {},
) => {
  switch (action.type) {
  case SITEID_DEFAULTRESPONSES_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      isSaving: false,
      isSaved: false,
      isDeleting: false,
      isDeleted: false,
      error: undefined,
      defaultResponseList: [],
      selectedResponse: {},
    };
  case SITEID_DEFAULTRESPONSES_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      isSaving: false,
      isSaved: false,
      isDeleting: false,
      isDeleted: false,
      error: undefined,
      defaultResponseList: action.payload.defaultResponses,
      statuses: action.payload.statuses,
      selectedResponse: {},
    };
  case SITEID_DEFAULTRESPONSES_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      isSaving: false,
      isSaved: false,
      isDeleting: false,
      isDeleted: false,
      error: action.payload.message,
      defaultResponseList: [],
      selectedResponse: {},
    };

  case SITEID_DEFAULTRESPONSE_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      isSaving: false,
      isSaved: false,
      isDeleting: false,
      isDeleted: false,
      error: undefined,
      selectedResponse: {},
    };
  case SITEID_DEFAULTRESPONSE_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      isSaving: false,
      isSaved: false,
      isDeleting: false,
      isDeleted: false,
      error: undefined,
      selectedResponse: action.payload.defaultResponse,
      statuses: action.payload.statuses,
    };
  case SITEID_DEFAULTRESPONSE_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      isSaving: false,
      isSaved: false,
      isDeleting: false,
      isDeleted: false,
      error: action.payload.message,
      selectedResponse: {},
    };

  case SITEID_SAVERESPONSE_REQUEST:
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      error: undefined,
      selectedResponse: {},
    };
  case SITEID_SAVERESPONSE_SUCCESS:
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      error: undefined,
      selectedResponse: action.payload,
    };
  case SITEID_SAVERESPONSE_FAILURE:
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      error: action.payload.message,
      selectedResponse: {},
    };

  case SITEID_DELETERESPONSE_REQUEST:
    return {
      ...state,
      isDeleting: true,
      isDeleted: false,
      error: undefined,
    };
  case SITEID_DELETERESPONSE_SUCCESS:
    return {
      ...state,
      isDeleting: false,
      isDeleted: true,
      error: undefined,
      selectedResponse: {},
    };
  case SITEID_DELETERESPONSE_FAILURE:
    return {
      ...state,
      isDeleting: false,
      isDeleted: false,
      error: action.payload.message,
    };
  default:
    return state;
  }
};

export default SiteIDDefaultResponseRouter;
