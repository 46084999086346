import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../../actions/authActions';
import { navigationActions } from '../../../../actions/navigationActions';
import { connectSideBarActions } from '../../../../actions/connectSideBarActions';
import { connectReportingActions } from '../../../../actions/connectReportingActions';

class ConnectMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  handleToggleSideBar(mode) {
    const { sideBarOpen, sideBarMode, toggleSideBar, logUserAction } = this.props;

    if (mode === sideBarMode && sideBarOpen) {
      toggleSideBar(false, mode);
    } else {
      toggleSideBar(true, mode);
      logUserAction({
        metricTypeId: 5, //Panel Expanded.
        actionName: mode,
      });
    }
  }

  render() {
    const {
      unreadNotifications,
      unreadTasks,
      unreadChat,
      weather,
      auth,
      menuOpen,
      toggleMenu,
    } = this.props;

    return (
      <>
        {menuOpen ? null : <button type="button"
          className="connect-menu-tab"
          onClick={() => toggleMenu(true)}><img src="/assets/icons/right-arrow.svg"
            alt="arrow" /></button>}
        <div className={`connect-menu ${menuOpen ? '' : 'hide'}`}>
          <div className="menu-menu">
            <button type="button"
              className="connect-menu-hide-button"
              onClick={() => toggleMenu(false)}>
              <img src="/assets/icons/right-arrow.svg"
                alt="arrow" />
            </button>

          </div>
          <div className="top-content">
            <button
              type="button"
              className={`notifications  ${unreadNotifications ? 'has-unread' : ''}`}
              onClick={() => this.handleToggleSideBar('notifications')}>
              <div>Unread Notifications</div><div className="number">{unreadNotifications}</div>
            </button>
            <button
              type="button"
              className={`tasks ${unreadTasks ? 'has-unread' : ''}`}
              onClick={() => this.handleToggleSideBar('tasks')}>
              <div>Tasks Requiring Attention</div><div className="number">{unreadTasks}</div>
            </button>
          </div>{auth && (auth.enabledFunctionality.indexOf('connect-1') > -1 || auth.enabledFunctionality.indexOf('connect-2') > -1) ?
            <div className="middle-content">
              <div className="label">More Content</div>
              {auth.enabledFunctionality.indexOf('connect-2') > -1 ? <button
                type="button"
                className={'weather'}
                onClick={() => this.handleToggleSideBar('weather')}>
                <div>Weather</div>
                <div className="weather-detail">
                  <div className="number">{weather && weather.current ? `${weather.current.temp.toFixed(0)}°C` : '-'}</div>
                  {weather && weather.current ? <img src={`/assets/weather-icons/${weather.current.weather[0].icon}@2x.png`}
                    alt={weather.current.weather[0].description} /> : null}
                </div>
              </button> : null}
              {auth.enabledFunctionality.indexOf('connect-1') > -1 ? <button
                type="button"
                className={'events'}
                onClick={() => this.handleToggleSideBar('events')}>
                <div>Events</div><div className="icon icon-qa-light"></div>
              </button> : null}
            </div> : null}
          {auth && (auth.enabledFunctionality.indexOf('connect-3') > -1) ?
            <div className="bottom-content">
              <div className="label">Live Chat</div>
              <button
                type="button"
                className={`live-chat ${unreadChat ? 'has-unread' : ''}`}
                onClick={() => this.handleToggleSideBar('live-chat')}>
                <div>Instant Messaging</div><div className="icon icon-messages-light"></div>
              </button>
            </div> : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  sideBarOpen: state.connectSideBar.open,
  sideBarMode: state.connectSideBar.mode,
  menuOpen: state.connectSideBar.isMenuOpen,
  unreadNotifications: state.notification.allPopups.filter(pop => !pop.acknowledged).length,
  unreadTasks: state.connectTask.allPopups.filter(pop => !pop.completed).length,
  weather: state.connectWeather.weather,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    logout: () => dispatch(authActions.requestLogout()),
    hideConnect: () => dispatch(authActions.hideConnect()),

    logUserAction: (data) => dispatch(connectReportingActions.logUserAction(data)),

    toggleSideBar: (show, mode) => dispatch(connectSideBarActions.toggleSideBar(show, mode)),
    toggleMenu: (show) => dispatch(connectSideBarActions.toggleMenu(show)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectMenu);
