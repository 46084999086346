import {
  CONNECT_CONTENTLABEL_REQUEST,
  CONNECT_CONTENTLABEL_SUCCESS,
  CONNECT_CONTENTLABEL_FAILURE,
  CONNECT_CONTENTLABELS_REQUEST, 
  CONNECT_CONTENTLABELS_SUCCESS, 
  CONNECT_CONTENTLABELS_FAILURE, 
  CONNECT_SAVELABEL_REQUEST,
  CONNECT_SAVELABEL_SUCCESS,
  CONNECT_SAVELABEL_FAILURE,
  CONNECT_DELETELABEL_REQUEST,
  CONNECT_DELETELABEL_SUCCESS,
  CONNECT_DELETELABEL_FAILURE,
} from './actionTypes';
  
export const connectLabelActions = {
  getLabels: () => (dispatch, getState) => {
    dispatch({
      type: CONNECT_CONTENTLABELS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/Connect/contentlabel/v1/list',
        successAction: CONNECT_CONTENTLABELS_SUCCESS,
        failureAction: CONNECT_CONTENTLABELS_FAILURE,
      },
    });
  },
  getLabel: (labelId) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_CONTENTLABEL_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Connect/contentlabel/v1/get?labelId=${labelId ? labelId : ''}`,
        successAction: CONNECT_CONTENTLABEL_SUCCESS,
        failureAction: CONNECT_CONTENTLABEL_FAILURE,
      },
    });
  },
  saveLabel: (label) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_SAVELABEL_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Connect/contentlabel/v1/save',
        successAction: CONNECT_SAVELABEL_SUCCESS,
        failureAction: CONNECT_SAVELABEL_FAILURE,
        body: {
          ...label,
        },
      },
    });
  },
  deleteLabel: (labelId) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_DELETELABEL_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: `/api/Connect/contentlabel/v1/delete?labelId=${labelId ? labelId : ''}`,
        successAction: CONNECT_DELETELABEL_SUCCESS,
        failureAction: CONNECT_DELETELABEL_FAILURE,
      },
    });
  },
};
  