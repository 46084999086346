import {
  CONNECT_TOGGLESIDEBAR_REQUEST,
  CONNECT_TOGGLEMENU_REQUEST,
} from '../actions/actionTypes';

import initialState from './initialState';

export const ConnectSideBarReducer = (state = initialState.connectSideBarState, action) => {
  switch (action.type) {
  case CONNECT_TOGGLESIDEBAR_REQUEST: {
    return {
      open: action.payload.open,
      mode: action.payload.mode,
      isMenuOpen: !action.payload.open,
    };
  }
  case CONNECT_TOGGLEMENU_REQUEST: {
    return {
      ...state,
      isMenuOpen: action.payload.open,
    };
  }
  default:
    return state;
  }
};
export default ConnectSideBarReducer;
