import React from "react";

class AssetSaveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaName: undefined,
      mediaType: undefined,
      mediaData: undefined,
      description: "",
      title: "",
      id:undefined,
    };
  }

  componentDidMount() {
    const { id, title, description, reference } = this.props;

    if (title) {
      this.setState({ id, title, description, reference })
    } else {
      this.setState({ id }) 
    }
  }

  handleTitleChange = e => {
    this.setState({ title: e.target.value });
  };
  
  handleDescriptionChange = e => {
    this.setState({ description: e.target.value });
  };

  handleAssetInputUpdate = e => {
    if (!e.target.files || e.target.files.length === 0){
      this.setState({error:"File does not exist or cannot be accessed."})
      return;
    }
    
    if((e.target.files[0].type.indexOf("image") > -1 && e.target.files[0].size/1024)/1000 >= 20) {
      this.setState({error:"The selected file exceeds the 20MB image limit."})
      return;
    }

    if((e.target.files[0].size/1024)/1000 >= 100) {
      this.setState({error:"The selected file exceeds the 100MB limit."})
      return;
    }

    let currentComponent = this;
    let doc = {
      mediaName: e.target.files[0].name,
      title: e.target.files[0].name,
      mediaType: e.target.files[0].type
    };

    var reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input asset."));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }).then(data => {
      doc.mediaData = data ? data.replace(/^data:(.*,)?/, "") : "";

      currentComponent.setState(doc);
    });
  };
  render() {
    return (
      <div className="modal-blocker">
        <div className="modal">
          <form>
            <div className="modal-menu">
              <button
                type="button"
                className="action"
                onClick={() => this.props.cancel()}
              >
                X
              </button>
            </div>
            <div className="modal-title">
              <h2>Upload</h2>
              <p>We recommend using image files of less than 500 KB for best results, though the limit for an individual file upload is 20 MB.</p>
            </div>
            <div className="modal-body">
              <label className="file-upload-wrapper">
                <div className="icon icon-image-upload"></div>
                <p>
                  {!this.state.mediaName &&
                    "Tap to select an asset to upload to the connect digital asset library."}
                  {this.state.mediaName}
                </p>
                <input
                  autoFocus
                  required
                  name="file"
                  id="file"
                  className="inputfile"
                  type="file"
                  onChange={e => this.handleAssetInputUpdate(e)}
                />
              </label>
              <div className="error">
                {this.state.error}
              </div>
              <label>
                Filename:
                <input
                  value={this.state.title}
                  maxLength={260}
                  onChange={e => this.handleTitleChange(e)}
                />
              </label>
              <label>
                Description:
                <textarea
                  value={this.state.description}
                  maxLength={1000}
                  onChange={e => this.handleDescriptionChange(e)}
                />
              </label>
            </div>
            <div className="modal-buttons">
              <button
                type="button"
                className="action"
                onClick={() => this.props.continue(this.state)}
              >
                Upload
              </button>
              <button
                type="button"
                className="action negative"
                onClick={() => this.props.cancel()}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default AssetSaveModal;
