
import React from 'react';
import './CustomTable.scss';

const Table = ({ headers, data, transposed }) => {

  if (!headers || !data || !Array.isArray(headers) || !Array.isArray(data)) {
    return <div>Error: Invalid table data.</div>;
  }

  if (headers.length === 0 || data.length === 0) {
    return <div>No data to display.</div>;
  }

  // Helper function to format header names
  const formatHeaderName = (name) => name.toLowerCase();

  // Create an array of formatted header names
  const formattedHeaders = headers.map(formatHeaderName);

  // Convert all keys of each row to lowercase
  const lowercaseData = data.map(row => {
    return Object.keys(row).reduce((accumulator, key) => {
      accumulator[key.toLowerCase()] = row[key];
      return accumulator;
    }, {});
  });

  return (
    <table className='table-style'>
      <thead>
        <tr>
          {!transposed && formattedHeaders.map((header, index) => (
            <th className='text-style'
              key={index}>{headers[index]}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {transposed && data && data.map((row, index) => (
          <tr key={index}>
            <th className='th-style' 
              key={index}>{headers[index]}</th>
            {row.map((cell, index) => (
              <td key={index}>{cell}</td>
            ))}
          </tr>
        ))}
        {!transposed && lowercaseData && lowercaseData.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {formattedHeaders.map((header, index) => (
              <td key={index}> {row[header]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
