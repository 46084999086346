import {
  SITEID_APPLICATIONS_REQUEST,
  SITEID_APPLICATIONS_SUCCESS,
  SITEID_APPLICATIONS_FAILURE,
  SITEID_BULKCOMMENT_START,
  SITEID_BULKSAVECOMMENT_REQUEST,
  SITEID_BULKSAVECOMMENT_SUCCESS,
  SITEID_BULKSAVECOMMENT_FAILURE,
  SITEID_BULKSTATUS_START,
  SITEID_BULKPROGRESSSTATUS_REQUEST,
  SITEID_BULKPROGRESSSTATUS_SUCCESS,
  SITEID_BULKPROGRESSSTATUS_FAILURE,
  SITEID_BULKSAVERELATE_REQUEST,
  SITEID_BULKSAVERELATE_SUCCESS,
  SITEID_BULKSAVERELATE_FAILURE,
  SITEID_BULKSAVEUNRELATE_REQUEST,
  SITEID_BULKSAVEUNRELATE_SUCCESS,
  SITEID_BULKSAVEUNRELATE_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const siteIDApplicationsReducer = (
  state = initialState.siteIDApplicationsState,
  action = {},
) => {
  switch (action.type) {
  case SITEID_APPLICATIONS_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      errorMessage: '',
    };
  }
  case SITEID_APPLICATIONS_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      data: action.payload.applications,
      companies: action.payload.companies,
      counties: action.payload.counties,
      priorityAreas: action.payload.priorityAreas,
      equityStores: action.payload.equityStores,
      statuses: action.payload.statuses,
      apiKey: action.payload.apiKey,
      isLicensee: action.payload.isLicensee,
      isReadOnly: action.payload.isReadOnly,
      driveThroughEnabled: action.payload.driveThroughEnabled,
      errorMessage: '',
    };
  }
  case SITEID_APPLICATIONS_FAILURE: {
    return {
      isFetching: false,
      isLoaded: false,
      data: [],
      errorMessage: action.payload.message,
    };
  }
  case SITEID_BULKCOMMENT_START: {
    return {
      ...state,
      isBulkChanging: false,
      isBulkChanged: false,
      bulkFeedback: '',
    };
  }
  case SITEID_BULKSAVECOMMENT_REQUEST: {
    return {
      ...state,
      isBulkChanging: true,
      isBulkChanged: false,
      bulkFeedback: '',
    };
  }
  case SITEID_BULKSAVECOMMENT_SUCCESS: {
    return {
      ...state,
      isBulkChanging: false,
      isBulkChanged: true,
      bulkFeedback: action.payload,
    };
  }
  case SITEID_BULKSAVECOMMENT_FAILURE: {
    return {
      ...state,
      isBulkChanging: false,
      isBulkChanged: false,
      bulkFeedback: action.payload.message,
    };
  }
  case SITEID_BULKSAVERELATE_REQUEST: {
    return {
      ...state,
      isBulkChanging: true,
      isBulkChanged: false,
      bulkFeedback: '',
    };
  }
  case SITEID_BULKSAVERELATE_SUCCESS: {
    return {
      ...state,
      isBulkChanging: false,
      isBulkChanged: true,
      bulkFeedback: action.payload,
    };
  }
  case SITEID_BULKSAVERELATE_FAILURE: {
    return {
      ...state,
      isBulkChanging: false,
      isBulkChanged: false,
      bulkFeedback: action.payload.message,
    };
  }
  case SITEID_BULKSAVEUNRELATE_REQUEST: {
    return {
      ...state,
      isBulkChanging: true,
      isBulkChanged: false,
      bulkFeedback: '',
    };
  }
  case SITEID_BULKSAVEUNRELATE_SUCCESS: {
    return {
      ...state,
      isBulkChanging: false,
      isBulkChanged: true,
      bulkFeedback: action.payload,
    };
  }
  case SITEID_BULKSAVEUNRELATE_FAILURE: {
    return {
      ...state,
      isBulkChanging: false,
      isBulkChanged: false,
      bulkFeedback: action.payload.message,
    };
  }
  case SITEID_BULKSTATUS_START: {
    return {
      ...state,
      isBulkChanging: false,
      isBulkChanged: false,
      bulkFeedback: '',
    };
  }
  case SITEID_BULKPROGRESSSTATUS_REQUEST: {
    return {
      ...state,
      isBulkChanging: true,
      isBulkChanged: false,
      bulkFeedback: '',
    };
  }
  case SITEID_BULKPROGRESSSTATUS_SUCCESS: {
    const newApplicationData = state.data.map((m) => {
      const newApplication = action.payload.changes.find(
        (c) => c && c.applicationId === m.applicationId,
      );

      if (!newApplication) return m;

      return {
        ...m,
        statusId: newApplication.toStatusId,
        status: newApplication.toStatus,
      };
    });

    return {
      ...state,
      data: [...newApplicationData],
      isBulkChanging: false,
      isBulkChanged: true,
      bulkFeedback: action.payload.summary,
    };
  }
  case SITEID_BULKPROGRESSSTATUS_FAILURE: {
    return {
      ...state,
      isBulkChanging: false,
      isBulkChanged: false,
      bulkFeedback: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default siteIDApplicationsReducer;
