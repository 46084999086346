import React from 'react';

const DeleteModal = props => {
  return (
    <div className="modal-blocker">
      <div className="modal">
        <form>
          <div className="modal-menu">
            <button
              type="button"
              className="action"
              onClick={() => props.cancel()}
            >
              X
            </button>
          </div>
          <div className="modal-title">
            <h2>Delete</h2>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to continue?</p>
            <p>All deletions are final.</p>
          </div>
          <div className="modal-buttons">
            <button
              type="button"
              className="action"
              disabled={props.inProgress}
              onClick={() => props.continue(props.data)}
            >
              Yes
            </button>
            <button
              type="button"
              className="action negative"
              disabled={props.inProgress}
              onClick={() => props.cancel()}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeleteModal;
