import {
  COMPLETEMFA_SUCCESS,
  TOKEN_REFRESH_SUCCESS,
  LOGOUT_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
} from '../actions/actionTypes';
import { authActions } from '../actions/authActions';

let refresh = undefined;

const tokenMiddleware = store => next => action => {
  switch (action.type) {
  case COMPLETEMFA_SUCCESS:
  case TOKEN_REFRESH_SUCCESS:
    let tokenLife = action.payload.expiry - new Date().getTime();
    localStorage.setItem('token', action.payload.token);

    if (tokenLife <= 0) {
      //Time has running out.
      return next({
        type: LOGOUT_REQUEST,
        http: {
          verb: 'POST',
          endpoint: '/api/identity/v1/logout',
          successAction: LOGOUT_SUCCESS,
          failureAction: LOGOUT_FAILURE,
        },
      });
    } else {
      //schedule next
      refresh = setTimeout(() => {
        store.dispatch(authActions.requestTokenRefresh());
      }, tokenLife * 0.75);
    }

    return next(action);
  case LOGOUT_REQUEST:
    clearTimeout(refresh);

    return next(action);
  default:
    return next(action);
  }
};

export default tokenMiddleware;
