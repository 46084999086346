import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { feedbackActions } from '../../../actions/feedbackActions';
import MessageModal from '../../shared/MessageModal';

class PortalFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleName: undefined,
      moduleRating: 0,
      mediaName: undefined,
      mediaType: undefined,
      mediaData: undefined,
      feedback: undefined,

      showMessage: false,
      message: '',
      title: '',

      showValidation: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleRadioInputUpdate = this.handleRadioInputUpdate.bind(this);
    this.handleFileInputUpdate = this.handleFileInputUpdate.bind(this);

    this.handleMessageShow = this.handleMessageShow.bind(this);
    this.handleMessageHide = this.handleMessageHide.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.feedbackError && !prevProps.feedbackError) {
      this.handleMessageShow(this.props.feedbackError, 'An error occurred');
    }

    if (this.props.feedbackSaved && !prevProps.feedbackSaved) {
      this.handleMessageShow(
        'Thank you for your submission. While we do not respond to every piece of feedback, all submissions are logged and reviewed by the Starbucks team. ',
        'Feedback Submitted',
      );
    }
  }

  handleSave(e) {
    let { submitFeedback } = this.props;
    let {
      moduleName,
      moduleRating,
      mediaData,
      mediaName,
      mediaType,
      feedback,
    } = this.state;

    if (!moduleName || !feedback) {
      this.setState({ showValidation: true });
    } else {
      submitFeedback({
        moduleName,
        moduleRating,
        mediaData: mediaData ? mediaData.replace(/^data:(.*,)?/, '') : '',
        mediaName,
        mediaType,
        feedback,
      });
    }

    e.preventDefault();
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    if (fieldName === 'moduleRating') {
      update[fieldName] = parseInt(e.target.value);
    } else {
      update[fieldName] = e.target.value;
    }

    this.setState(update);
  }

  handleFileInputUpdate(e, dataField, fileNameField, fileExtField) {
    if (!e.target.files || e.target.files.length === 0) return;
    let currentComponent = this;
    let update = {};

    update[fileNameField] = e.target.files[0].name;

    update[fileExtField] = e.target.files[0].type;

    var reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }).then(data => {
      update[dataField] = data;
      currentComponent.setState(update);
    });
  }

  handleRadioInputUpdate(e, name) {
    this.setState({
      [name]: e.target.value,
    });
  }

  handleMessageShow(message, title) {
    this.setState({ showMessage: true, message, title });
  }

  handleMessageHide() {
    this.setState({ showMessage: false, message: '', title: '' });
  }

  render() {
    let { moduleName, feedback, moduleRating, showValidation } = this.state;
    const { feedbackSaving, feedbackSaved } = this.props;
    return (
      <div className="edit-feedback-page page">
        <form
          className="form-container floating-panel"
          noValidate
          onSubmit={e => this.handleSave(e)}>
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-form"></div>
              <div>
                <h2>MyStarbucks Feedback</h2>
                <p>Give us feedback about the MyStarbucks Portal!</p>
              </div>
            </div>
          </div>
          <div className="form-body">
            <label>
              What do you think about the portal?
              <div className="star-rating">
                <label
                  className={`icon ${
                    moduleRating >= 1 ? 'icon-star1' : 'icon-star-empty'
                  }`}>
                  1
                  <input
                    type="radio"
                    name="rating"
                    value="1"
                    onClick={e => this.handleTextInputUpdate(e, 'moduleRating')}
                  />
                </label>
                <label
                  className={`icon ${
                    moduleRating >= 2 ? 'icon-star2' : 'icon-star-empty'
                  }`}>
                  2
                  <input
                    type="radio"
                    name="rating"
                    value="2"
                    onChange={e =>
                      this.handleTextInputUpdate(e, 'moduleRating')
                    }
                  />
                </label>
                <label
                  className={`icon ${
                    moduleRating >= 3 ? 'icon-star3' : 'icon-star-empty'
                  }`}>
                  3
                  <input
                    type="radio"
                    name="rating"
                    value="3"
                    selected={true}
                    onChange={e =>
                      this.handleTextInputUpdate(e, 'moduleRating')
                    }
                  />
                </label>
                <label
                  className={`icon ${
                    moduleRating >= 4 ? 'icon-star4' : 'icon-star-empty'
                  }`}>
                  4
                  <input
                    type="radio"
                    name="rating"
                    value="4"
                    onChange={e =>
                      this.handleTextInputUpdate(e, 'moduleRating')
                    }
                  />
                </label>
                <label
                  className={`icon ${
                    moduleRating >= 5 ? 'icon-star5' : 'icon-star-empty'
                  }`}>
                  5
                  <input
                    type="radio"
                    name="rating"
                    value="5"
                    onChange={e =>
                      this.handleTextInputUpdate(e, 'moduleRating')
                    }
                  />
                </label>
              </div>
            </label>
          </div>
          <div className="form-explanation">
            <p>Is there a module you would like to give feedback on?</p>
          </div>
          <div className="form-body">
            <label>
              <input
                autoFocus
                type="text"
                required
                className={showValidation && !moduleName ? 'invalid' : ''}
                value={moduleName}
                onChange={e => this.handleTextInputUpdate(e, 'moduleName')}
              />
            </label>
          </div>
          <div className="form-body">
            <label>
              Let us know what we could improve.
              <textarea
                required
                value={feedback}
                className={showValidation && !feedback ? 'invalid' : ''}
                onChange={e => this.handleTextInputUpdate(e, 'feedback')}
              />
            </label>
            <label className="file-upload-wrapper">
              <div className="icon icon-image-upload"></div>
              <p>
                {!this.state.mediaName &&
                  'Tap to upload a photo or video with your feedback.'}
                {this.state.mediaName}
              </p>
              <input
                name="file"
                id="file"
                className="inputfile"
                type="file"
                onChange={e =>
                  this.handleFileInputUpdate(
                    e,
                    'mediaData',
                    'mediaName',
                    'mediaType',
                  )
                }
              />
            </label>
          </div>
          <div className="form-buttons">
            <button className="action"
              disabled={feedbackSaving}>
              {feedbackSaving
                ? 'Submitting...'
                : feedbackSaved
                  ? 'Submitted'
                  : 'Submit'}
            </button>
          </div>
        </form>
        {this.state.showMessage && (
          <MessageModal
            cancel={this.handleMessageHide}
            title={this.state.title}
            message={this.state.message}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    feedbackSaving: state.feedback.feedbackSaving,
    feedbackSaved: state.feedback.feedbackSaved,
    feedbackError: state.feedback.errorMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submitFeedback: feedbackData =>
      dispatch(feedbackActions.submitPortalFeedback(feedbackData)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PortalFeedback);
