import {
  PUSHNAVIGATION_REQUEST,
  REPLACENAVIGATION_REQUEST,
  BACKNAVIGATION_REQUEST,
  RESETNAVIGATION_REQUEST,
} from '../actions/actionTypes';
import { push, replace } from 'connected-react-router';

const navigationMiddleware = store => next => action => {
  switch (action.type) {
  case PUSHNAVIGATION_REQUEST:
    store.dispatch(push(action.payload.url));

    let content = document.getElementById('appRight');
    if (content) {
      content.scrollTo(0, 0);
    }

    return next(action);
  case REPLACENAVIGATION_REQUEST:
    store.dispatch(replace(action.payload.url));

    return next(action);
  case BACKNAVIGATION_REQUEST:
    var navigation = store.getState().navigation;

    if (navigation.history.length > 1) {
      store.dispatch(push(navigation.history[navigation.history.length - 2]));
    } else {
      store.dispatch(push('/'));
    }

    return next(action);
  case RESETNAVIGATION_REQUEST:
    store.dispatch(push('/'));
    return next(action);
  default:
    return next(action);
  }
};

export default navigationMiddleware;
