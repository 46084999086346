import {
  CONNECT_TOGGLESIDEBAR_REQUEST,
  CONNECT_TOGGLEMENU_REQUEST,
} from './actionTypes';

export const connectSideBarActions = {
  toggleSideBar: (open, mode) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_TOGGLESIDEBAR_REQUEST,
      payload: {
        open,
        mode,
      },
    });
  },
  toggleMenu: open => (dispatch, getState) => {
    dispatch({
      type: CONNECT_TOGGLEMENU_REQUEST,
      payload: {
        open,
      },
    });
  },
};
