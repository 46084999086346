import {
  GETCUSTOMERREQUIREMENTS_REQUEST,
  GETCUSTOMERREQUIREMENTS_SUCCESS,
  GETCUSTOMERREQUIREMENTS_FAILURE,
  GETCUSTOMERREQUIREMENTSODS_REQUEST,
  GETCUSTOMERREQUIREMENTSODS_SUCCESS,
  GETCUSTOMERREQUIREMENTSODS_FAILURE,
  SAVECUSTOMERREQUIREMENTSODS_REQUEST,
  SAVECUSTOMERREQUIREMENTSODS_SUCCESS,
  SAVECUSTOMERREQUIREMENTSODS_FAILURE,
  UPDATECUSTOMERREQUIREMENTS_REQUEST,
  UPDATECUSTOMERREQUIREMENTS_SUCCESS,
  UPDATECUSTOMERREQUIREMENTS_FAILURE,
  DOWNLOADDOCUMENTODS_REQUEST,
  DOWNLOADDOCUMENTODS_SUCCESS,
  DOWNLOADDOCUMENTODS_FAILURE,
} from './actionTypes';

export const customerRequirementsActions = {
  requestCustomerRequirements: customerNo => (dispatch, getState) => {
    dispatch({
      type: GETCUSTOMERREQUIREMENTS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/CustomerRequirements/v1/Get/${customerNo}`,
        successAction: GETCUSTOMERREQUIREMENTS_SUCCESS,
        failureAction: GETCUSTOMERREQUIREMENTS_FAILURE,
      },
    });
  },
  requestCustomerRequirementsOds: customerNo => (dispatch, getState) => {
    dispatch({
      type: GETCUSTOMERREQUIREMENTSODS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/CustomerRequirements/v1/GetCustomerRequirementsOds/${customerNo}`,
        successAction: GETCUSTOMERREQUIREMENTSODS_SUCCESS,
        failureAction: GETCUSTOMERREQUIREMENTSODS_FAILURE,
      },
    });
  },
  saveCustomerRequirementsOdsForm: data => (dispatch, getState) => {
    dispatch({
      type: SAVECUSTOMERREQUIREMENTSODS_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/CustomerRequirements/v1/SaveCustomerRequirementsOdsForm',
        successAction: SAVECUSTOMERREQUIREMENTSODS_SUCCESS,
        failureAction: SAVECUSTOMERREQUIREMENTSODS_FAILURE,
        body: data,
      },
    });
  },
  downloadCustomerRequirementsOdsDocument: (storeId) => (dispatch, getState) => {
    dispatch({
      type: DOWNLOADDOCUMENTODS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/CustomerRequirements/v1/DownloadOdsDocument?storeId=' + storeId ,
        successAction: DOWNLOADDOCUMENTODS_SUCCESS,
        failureAction: DOWNLOADDOCUMENTODS_FAILURE,
      },
    });
  },
  amendCustomerRequirements: customerRequirements => (dispatch, getState) => {
    dispatch({
      type: UPDATECUSTOMERREQUIREMENTS_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/CustomerRequirements/v1/Amend',
        successAction: UPDATECUSTOMERREQUIREMENTS_SUCCESS,
        failureAction: UPDATECUSTOMERREQUIREMENTS_FAILURE,
        body: {
          ...customerRequirements,
        },
      },
    });
  },
};
