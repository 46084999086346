import React from 'react';
import { Tooltip } from '@material-ui/core';
const ReactMarkdown = require('react-markdown');

class Template11 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };

    this.toggleContent = this.toggleContent.bind(this);
  }

  toggleContent() {
    const { expanded } = this.state;
    const { onRead, id } = this.props;

    this.setState({ expanded: !expanded });

    if (!expanded) { //Expanding
      onRead();
    } else {
      const content = document.getElementById('content' + id);

      content.scrollTop = 0;
    }
  }

  render() {
    const { 
      subHeading, 
      preview,
      content, 
      assetData, 
      background, 
      label, 
      onWatch, 
      id, 
      targetType, 
      targetTypeIds ,
      targetTypeData, 
      attachment, 
      fontColor, 
    } = this.props;

    const { expanded } = this.state;

    let style = {};

    if (background && background.assetCategoryId && background.data) {
      style.backgroundImage = `url('/api/Connect/Asset/v1/Get?assetCategoryId=${background.assetCategoryId}&assetReference=${background.data}')`;
    }

    return (
      <div className="template template-11">


        <div className="template-row"
          style={style}>
          <div className="template-stack">
            <div className="heading-row">
              <h3 className="sub-heading"
                style={{ color: fontColor }}>{subHeading}</h3>
              
              {label && <div className="content-label">{label}</div>}
            </div>
            <div className="image col-1">
              {assetData && assetData.assetCategoryId && assetData.data && assetData.type &&
                <>
                  {assetData.type.toLowerCase().indexOf('image') > -1 && <img
                    src={`/api/Connect/Asset/v1/Get?assetCategoryId=${assetData.assetCategoryId}&assetReference=${assetData.data}`}
                    alt={assetData.description} />}

                  {assetData.type.toLowerCase().indexOf('video') > -1 &&
                    <video controls
                      onPlay={() => onWatch()}>
                      <source src={`/api/Connect/Asset/v1/Stream?assetCategoryId=${assetData.assetCategoryId}&assetReference=${assetData.data}`}
                        type={assetData.type} />
                  Your browser does not support the video tag.
                    </video>}


                  {assetData.type.toLowerCase().indexOf('audio') > -1 &&
                    <audio controls>
                      <source
                        src={`/api/Connect/Asset/v1/Stream?assetCategoryId=${assetData.assetCategoryId}&assetReference=${assetData.data}`}
                        type={assetData.type} />
                  Your browser does not support the audio element.
                    </audio>}
                </>
              }
            </div>
            <div className="content-container">
              <div id={`content${id}`}
                className={`content ${expanded ? 'content-expanded' : ''}`}
                style={{ color: fontColor }}>
                <ReactMarkdown source={preview}
                  skipHtml={true}
                  className="content-preview"
                  linkTarget={'_blank'} />
                <ReactMarkdown source={content}
                  skipHtml={true}
                  className="content-content"
                  linkTarget={'_blank'} />
              </div>
              <div className="content-expander">
                <button className="expand-button"
                  type="button"
                  onClick={() => this.toggleContent()}>{expanded ? 'Read less' : 'Read more'}</button>
                {attachment && attachment.assetCategoryId && attachment.data && attachment.type ?
                  <Tooltip title={(<div>
                    <div>{attachment.title}</div>
                    <div>{attachment.description}</div>
                    <div>{(attachment.size / 1000).toFixed(2) + 'MB'}</div>
                  </div>)}>
                    <a
                      className="expand-button"
                      href={`/api/Connect/Asset/v1/Get?assetCategoryId=${attachment.assetCategoryId}&assetReference=${attachment.data}`}>
                      <span className="icon download-icon"></span>Download</a>
                  </Tooltip> : null}
                {targetType ? 
                  <Tooltip title={targetTypeIds && targetTypeIds.length && targetTypeIds.length > 0 && targetTypeData ? (<ul>
                    {targetTypeIds.map(t => {
                      return (<li key={t}>{targetTypeData.find(x => t === x.key).value}</li>);
                    })}
                  </ul>) : targetType}>
                    <button className="expand-button audience"
                      type="button"
                      onClick={() => false}><div className="icon target-icon"></div>{targetType}</button>
                  </Tooltip> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Template11;
