import React from 'react';

const Folder = props => {
  const file = props.data;
  const { onDeleteFolder, onEditFolder, onView } = props;
  const iconClass = file.iconType === 1 ? file.iconData.toLowerCase() : '';

  return (
    <div className="file-container">
      <div className="file-content">
        <div className="file-icon-and-buttons">
          <div className="document-icon-title">
            <div className={`icon ${iconClass ? iconClass : 'icon-folder'}`} />
            <div className="file-name">{file.title}</div>
          </div>
          <div className="button-container">
            <button
              type="button"
              className="edit"
              onClick={e => {
                onView();
                e.preventDefault();
                e.stopPropagation();
              }}>
              OPEN
            </button>
            <button
              type="button"
              className="edit"
              onClick={e => {
                onEditFolder();
                e.preventDefault();
                e.stopPropagation();
              }}>
              EDIT
            </button>
            <button
              type="button"
              className="edit negative"
              onClick={e => {
                onDeleteFolder();
                e.preventDefault();
                e.stopPropagation();
              }}>
              DELETE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Folder;
