import React from 'react';
import { Tooltip } from '@material-ui/core';

const TaskView = props => {
  const taskDetail = props.data;
  return (
    <div
      className={`task-card ${taskDetail.acknowledged ? '' : 'not-acknowledged'
      }`}>
      <div
        className={`icon-holder  ${taskDetail.sentFrom
          .toLowerCase()
          .replace(' ', '-')}`}>
        <div
          className={`icon ${
            taskDetail.fileTitle?.length > 0
              ? `icon-file ${taskDetail.fileType.replace('.', '')}`
              : taskDetail.sentFrom === 'Order Management'
                ? 'icon-notification-box'
                : taskDetail.sentFrom === 'Admin'
                  ? 'icon-notification-alert'
                  : taskDetail.sentFrom === 'Customer Service'
                    ? 'icon-notification-messages'
                    : taskDetail.sentFrom === 'Item Catalogue'
                      ? 'icon-notification-item'
                      : taskDetail.sentFrom === 'Document Library'
                        ? 'icon-notification-document'
                        : taskDetail.sentFrom === 'Quality Awareness'
                          ? 'icon-notification-qa'
                          : taskDetail.sentFrom === 'Pricing Catalogue'
                            ? 'icon-notification-pricing'
                            : taskDetail.sentFrom === 'Forecasting'
                              ? 'icon-notification-forecasting'
                              : taskDetail.sentFrom === 'Orderly Inventory'
                                ? 'icon-notification-orderly'
                                : taskDetail.sentFrom === 'Reports'
                                  ? 'icon-notification-report' 
                                  : taskDetail.sentFrom === 'Policies'
                                    ? 'icon-notification-policy'
                                    : 'icon-notification-messages'
          }`}
        />
      </div>
      <div className="task">
        {taskDetail.autoCompleteTaskOnDownload &&
        <h5 className="title">{taskDetail.title}<Tooltip
          title={'This task is set to autocomplete when the file is downloaded.'}>
          <span className="info"
            style={{ fontWeight: 200, marginLeft:'2px' }}>&#x1F6C8;</span></Tooltip></h5>
        }
        {!taskDetail.autoCompleteTaskOnDownload &&
        <h5 className="title">{taskDetail.title}</h5>
        }
        <div className="message">
          <span style={{ fontWeight: '500' }}>{taskDetail.fileTitle?.length > 0 ? taskDetail.fileTitle + ' - ' : ''}</span>
          {taskDetail.body}</div>
        <div className="date">{props.vagueDate}</div>
        {!taskDetail.completed && taskDetail.dueDate ? 
          <div className="due">Due: {new Date(taskDetail.dueDate).toLocaleDateString()}</div> : null}

        <div className="button-holder">
          {taskDetail.actionLink && (
            <button
              className="edit action"
              type="button"
              onClick={() => props.navigate()}>
              View Content
            </button>
          )}
          {!taskDetail.completed &&
            <button
              className="edit"
              type="button"
              disabled={taskDetail.fileTitle?.length > 0 && !taskDetail.userHasDownloaded}
              onClick={() => props.acknowledge()}>
              Complete Task
            </button>
          }
          {taskDetail.fileTitle?.length > 0 &&
               <button
                 className="edit positive"
                 type="button"
                 onClick={() => props.handleDownloadDocument()}>
              Download File
               </button>
          }
          {taskDetail.completed &&
           <div className="completed">
             <div>Completed:</div>
             <div>{new Date(taskDetail.completedOn).toLocaleDateString()}</div>
           </div>
          }
        </div>
      </div>
    </div>
  );
};

export default TaskView;
