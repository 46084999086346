import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  UPDATEMYUSER_REQUEST,
  UPDATEMYUSER_SUCCESS,
  UPDATEMYUSER_FAILURE,
  COMPLETEMFA_REQUEST,
  COMPLETEMFA_SUCCESS,
  COMPLETEMFA_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  ACCEPT_TERMS_REQUEST,
  ACCEPT_TERMS_SUCCESS,
  ACCEPT_TERMS_FAILURE,
  TERMS_ACCEPTED,
  CONNECT_DISPLAY,
  CONNECT_HIDE,
  TOKEN_REFRESH_FAILURE,
  TOKEN_REFRESH_REQUEST,
  TOKEN_REFRESH_SUCCESS,
  STORES_REQUEST,
  STORES_SUCCESS,
  STORES_FAILURE,
  REQUESTPASSWORDRESET_REQUEST,
  REQUESTPASSWORDRESET_SUCCESS,
  REQUESTPASSWORDRESET_FAILURE,
  PASSWORDRESET_REQUEST,
  PASSWORDRESET_SUCCESS,
  PASSWORDRESET_FAILURE,
  ACTIVATEACCOUNT_REQUEST,
  ACTIVATEACCOUNT_SUCCESS,
  ACTIVATEACCOUNT_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const authReducer = (state = initialState.authState, action) => {
  switch (action.type) {
  case LOGIN_REQUEST:
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('expiry');
    
    return {
      isFetching: true,
      isAuthenticated: false,
    };
  case TOKEN_REFRESH_REQUEST:
  case COMPLETEMFA_REQUEST:
    return {
      ...state,
      isFetching: true,
    };
  case LOGIN_SUCCESS:
  case COMPLETEMFA_SUCCESS:

    var userLoginData = {
      ...action.payload,
      isFetching: false,
      isAuthenticated: true,
      displayConnect: action.payload.hasConnectAccess,
      tenant: action.payload.tenant,
      hasAcceptedClientTerms: false,
    };
    localStorage.setItem('user', JSON.stringify(userLoginData));
    localStorage.setItem('token', userLoginData.token);
    localStorage.setItem('expiry', userLoginData.expiry);

    return userLoginData;
  case TOKEN_REFRESH_SUCCESS:

    var userLoginData = {
      ...state,
      ...action.payload,
      isFetching: false,
      isAuthenticated: true,
      displayConnect: action.payload.hasConnectAccess,
      tenant: action.payload.tenant,
    };
    localStorage.setItem('user', JSON.stringify(userLoginData));
    localStorage.setItem('token', userLoginData.token);
    localStorage.setItem('expiry', userLoginData.expiry);

    return userLoginData;
  case UPDATEMYUSER_REQUEST:
    return {
      ...state,
      updateUser: true,
      updatedUser: false,
      errorMessage: '',
    };
  case UPDATEMYUSER_SUCCESS:
    var userData = {
      ...state,
      ...action.payload,
      updateUser: false,
      updatedUser: true,
      errorMessage: '',
      resetPassword: false,
    };

    localStorage.setItem('user', JSON.stringify(userData));

    return userData;
  case UPDATEMYUSER_FAILURE:
    return {
      ...state,
      updateUser: false,
      updatedUser: false,
      errorMessage: action.payload.message,
    };
  case COMPLETEMFA_FAILURE:
    return {
      ...state,
      authenticatedUser: false,
      errorMessage: action.payload.message,
    };

  case LOGIN_FAILURE:
  case TOKEN_REFRESH_FAILURE:
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('expiry');
    return {
      ...initialState.authState,
      errorMessage: action.payload.message,
    };

  case LOGOUT_SUCCESS:
  case LOGOUT_FAILURE:
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('expiry');
    window.location.pathname = '/';
    return {
      ...initialState.authState,
      errorMessage: action.payload.message,
    };
  case TERMS_ACCEPTED:
    var data = {
      ...state,
      ...action.payload,
      hasAcceptedClientTerms: true,
    };

    localStorage.setItem('user', JSON.stringify(data));
    return data;
  case ACCEPT_TERMS_REQUEST:
    return {
      ...state,
      isAcceptingClientTerms: true,
      hasAcceptedClientTerms: false,
      hasAcceptedTerms: false,
      errorMessage: '',
    };
  case ACCEPT_TERMS_SUCCESS:
    var termsData = {
      ...state,
      ...action.payload,
      isAcceptingClientTerms: false,
      hasAcceptedClientTerms: true,
      hasAcceptedTerms: true,
      errorMessage: action.payload.message,
    };
    localStorage.setItem('user', JSON.stringify(termsData));
    return termsData;
  case ACCEPT_TERMS_FAILURE:
    return {
      ...state,
      isAcceptingClientTerms: false,
      hasAcceptedClientTerms: false,
      hasAcceptedTerms: false,
      errorMessage: action.payload.message,
    };
  case CONNECT_DISPLAY:
    var connectDisplayData = {
      ...state,
      displayConnect: true,
    };

    localStorage.setItem('user', JSON.stringify(connectDisplayData));

    return connectDisplayData;
  case CONNECT_HIDE:
    var connectHideData = {
      ...state,
      displayConnect: false,
    };

    localStorage.setItem('user', JSON.stringify(connectHideData));

    return connectHideData;

  case STORES_REQUEST: {
    return {
      ...state,
      isFetching_Stores: true,
      isLoaded_Stores: false,
      errorMessage: '',
    };
  }
  case STORES_SUCCESS: {
    return {
      ...state,
      isFetching_Stores: true,
      isLoaded_Stores: false,
      errorMessage: '',
      stores: action.payload,
    };
  }
  case STORES_FAILURE: {
    return {
      ...state,
      isFetching_Stores: false,
      isLoaded_Stores: false,
      errorMessage: action.payload.message,
      stores: [],
    };
  }
  case REQUESTPASSWORDRESET_REQUEST:
    return {
      ...state,
      isFetching_RequestPasswordReset: true,
      isLoaded_RequestPasswordReset: false,
      errorMessage: '',
    };
  case REQUESTPASSWORDRESET_SUCCESS:
    return {
      ...state,
      isFetching_RequestPasswordReset: false,
      isLoaded_RequestPasswordReset: true,
      errorMessage: action.payload.message,
    };
  case REQUESTPASSWORDRESET_FAILURE:
    return {
      ...state,
      isFetching_RequestPasswordReset: false,
      isLoaded_RequestPasswordReset: false,
      errorMessage: action.payload.message,
    };
  case PASSWORDRESET_REQUEST:
  case ACTIVATEACCOUNT_REQUEST:
    return {
      ...state,
      isFetching_ActivateAccount: true,
      isLoaded_ActivateAccount: false,
      errorMessage: '',
    };
  case PASSWORDRESET_SUCCESS:
  case ACTIVATEACCOUNT_SUCCESS:
    return {
      ...state,
      isFetching_ActivateAccount: false,
      isLoaded_ActivateAccount: true,
      errorMessage: action.payload.message,
    };
  case PASSWORDRESET_FAILURE:
  case ACTIVATEACCOUNT_FAILURE:
    return {
      ...state,
      isFetching_ActivateAccount: false,
      isLoaded_ActivateAccount: false,
      errorMessage: action.payload.message,
    };
  default:
    return state;
  }
};
export default authReducer;
