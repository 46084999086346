import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import GroupRecord from '../shared/groupRecord';
import { userManagementActions } from '../../../actions/userManagementActions';
import DeleteModal from '../../shared/DeleteModal';

class ModuleGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showDeleteModal: false };

    this.handleCreateGroup = this.handleCreateGroup.bind(this);
    this.handleEditGroup = this.handleEditGroup.bind(this);
    this.handleDeleteGroup = this.handleDeleteGroup.bind(this);

    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);
  }

  componentDidMount() {
    this.props.getGroups();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.groupDeleted && !prevProps.groupDeleted) {
      this.props.getGroups();
    }
  }

  handleCreateGroup() {
    const { pathname } = this.props.location;
    this.props.push(`${pathname}/0`);
  }

  handleEditGroup(groupId) {
    const { pathname } = this.props.location;
    this.props.push(`${pathname}/${groupId}`);
  }

  handleDeleteModalShow(groupId) {
    this.setState({ showDeleteModal: true, groupId });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false, groupId: undefined });
  }

  handleDeleteGroup() {
    this.props.deleteGroup(this.state.groupId);
    this.handleDeleteModalHide();
  }

  render() {
    const { groups } = this.props;
    const { showDeleteModal } = this.state;
    const {
      handleEditGroup,
      handleDeleteGroup,
      handleDeleteModalShow,
      handleDeleteModalHide,
    } = this;
    return (
      <div className="groups-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-user-management"></div>
              <div>
                <h2>Instance Module Configuration</h2>
                <p>
                  Manage the modules customers can access based on the Orderly
                  instance to which they are assigned.
                </p>
              </div>
            </div>
            <div className="header-buttons">
              <div>
                <button
                  className="action"
                  type="button"
                  onClick={() => this.handleCreateGroup()}>
                  + Create New
                </button>
              </div>
            </div>
          </div>
          <div className="group-container">
            {groups.map(group => (
              <GroupRecord
                key={group.moduleGroupId}
                groupName={group.moduleGroupName}
                iconName="icon-admin"
                groupId={group.moduleGroupId}
                editAction={() => handleEditGroup(group.moduleGroupId)}
                deleteAction={() => handleDeleteModalShow(group.moduleGroupId)}
              />
            ))}
          </div>
        </div>
        {showDeleteModal && (
          <DeleteModal
            cancel={handleDeleteModalHide}
            continue={handleDeleteGroup}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.userManagement.moduleGroups.isFetching,
  groups: state.userManagement.moduleGroups.groupList,
  groupDeleted: state.userManagement.moduleGroups.isDeleted,
});

const mapDispatchToProps = dispatch => {
  return {
    getGroups: () => dispatch(userManagementActions.requestModuleGroups()),
    deleteGroup: userId =>
      dispatch(userManagementActions.deleteModuleGroup(userId)),
    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModuleGroups);
