import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { userManagementActions } from '../../../actions/userManagementActions';
import DeleteModal from '../../shared/DeleteModal';

class EditCustomerGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupId: null,
      groupName: '',
      parentGroupId: null,
      storeList: [],
      userList: [],
      isDistributionCentre: null,
      distributionCentreName: null,
      isShipSupplier: null,
      shipSupplierName: null,

      stores: [],
      users: [],

      storeFilter: '',
      userFilter: '',

      detailsExpanded: true,
      customersExpanded: false,
      usersExpanded: false,

      showDeleteModal: false,
    };

    this.handleSaveGroup = this.handleSaveGroup.bind(this);
    this.handleDeleteGroup = this.handleDeleteGroup.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);
    this.handleCheckInputUpdateMultipleStores = this.handleCheckInputUpdateMultipleStores.bind(
      this,
    );
    this.handleCheckInputUpdateMultipleUsers = this.handleCheckInputUpdateMultipleUsers.bind(
      this,
    );

    this.handleToggleExpander = this.handleToggleExpander.bind(this);

    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);
  }

  componentDidMount() {
    let groupId = this.props.match.params.groupId;
    if (parseInt(groupId)) {
      this.props.getStoreGroup(groupId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { pathname } = this.props.location;
    if (this.props.groupLoaded && !prevProps.groupLoaded) {
      let { storeGroup, stores, users } = this.props.groupDetails;

      this.setState({
        groupId: storeGroup.storeGroupId,
        groupName: storeGroup.storeGroupName,
        userList: storeGroup.userList,
        storeList: storeGroup.storeList,
        isDistributionCentre: storeGroup.isDistributionCentre,
        distributionCentreName: storeGroup.distributionCentreName,
        isShipSupplier: storeGroup.isShipSupplier,
        shipSupplierName: storeGroup.shipSupplierName,

        isAPAC: this.props.tenant == 'APAC', 

        stores,
        users,
      });
    } else if (this.props.groupSaved && !prevProps.groupSaved) {
      let group = this.props.groupDetails.storeGroup;

      if (
        (group.storeGroupId && !prevProps.groupDetails.storeGroup) ||
        group.storeGroupId !== prevProps.groupDetails.storeGroup.storeGroupId
      ) {
        this.props.replace(`${pathname}${group.storeGroupId}`);
        this.props.getStoreGroup(group.storeGroupId);
      } else {
        this.props.goBack();
      }
    } else if (this.props.groupDeleted && !prevProps.groupDeleted) {
      this.props.goBack();
    }
  }

  handleSaveGroup(e) {
    let { saveStoreGroup } = this.props;
    let { groupId, groupName, parentGroupId, userList, storeList, isDistributionCentre, distributionCentreName, isShipSupplier, shipSupplierName } = this.state;

    saveStoreGroup({
      storeGroupId: groupId,
      storeGroupName: groupName,
      parentGroupId,
      isDistributionCentre,
      distributionCentreName,
      isShipSupplier,
      shipSupplierName,
      userList,
      storeList,
    });

    e.preventDefault();
  }

  handleDeleteModalShow() {
    this.setState({ showDeleteModal: true });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false });
  }

  handleToggleExpander(expanderName) {
    let state = this.state;
    this.setState({ [expanderName]: !state[expanderName] });
  }

  handleDeleteGroup() {
    this.props.deleteStoreGroup(this.state.groupId);
    this.handleDeleteModalHide();
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  handleCheckInputUpdateMultipleStores(e) {
    let { storeList, stores, storeFilter } = this.state;

    let listValues = stores
      .filter(
        s =>
          s.storeName.toLowerCase().indexOf(storeFilter.toLowerCase()) > -1 ||
          s.storeNumber.toLowerCase().indexOf(storeFilter.toLowerCase()) > -1,
      )
      .map(s => s.storeId);

    this.setState({ storeList: [...storeList, ...listValues] });
  }

  handleCheckInputUpdateMultipleUsers(e) {
    let { userList, users, userFilter } = this.state;

    let listValues = users
      .filter(
        s => s.userName.toLowerCase().indexOf(userFilter.toLowerCase()) > -1,
      )
      .map(s => s.userId);

    this.setState({ userList: [...userList, ...listValues] });
  }
  
  handleBasicCheckUpdate(e, stateName){
    this.setState({ [stateName]: e.target.checked });
  }

  handleCheckInputUpdate(e, listName, listValueId) {
    let listIds = this.state[listName];

    if (e.target.checked) {
      if (listIds.indexOf(listValueId) === -1) {
        listIds.push(listValueId);
      }
    } else {
      let pos = listIds.indexOf(listValueId);
      if (pos !== -1) {
        listIds.splice(pos, 1);
      }
    }

    let newState = {};

    newState[listName] = listIds;

    this.setState(newState);
  }

  getGroupPanel() {
    let { groupName, detailsExpanded, isDistributionCentre, isShipSupplier, isAPAC, distributionCentreName, shipSupplierName } = this.state;

    return (
      <div className="group-details accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('detailsExpanded')}>
          <h3>Group Details</h3>
          <div
            className={`icon ${
              detailsExpanded ? 'icon-expand' : 'icon-collapse'
            }`}></div>
        </div>
        <div className={`accordion-body ${detailsExpanded ? '' : 'collapsed'}`}>
          <label>
            Group Name
            <input
              autoFocus
              placeholder="Group Name"
              onChange={e => this.handleTextInputUpdate(e, 'groupName')}
              value={groupName}
            />
          </label>
          {isAPAC && 
          <>
            <label className="wrapping">
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  checked={isDistributionCentre}
                  disabled={isShipSupplier}
                  onChange={e =>
                    this.handleBasicCheckUpdate(e, 'isDistributionCentre')
                  }
                />
                <div className="toggle-icon"></div>
              </div>
              <span>Is Distribution Centre</span>
            </label>
            {isDistributionCentre && <label >
              Distribution Centre Name
              <input
                type="text"
                value={distributionCentreName}
                onChange={e =>
                  this.handleTextInputUpdate(e, 'distributionCentreName')
                }
              />
            </label>}
            <label className="wrapping">
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  checked={isShipSupplier}
                  disabled={isDistributionCentre}
                  onChange={e =>
                    this.handleBasicCheckUpdate(e, 'isShipSupplier')
                  }
                />
                <div className="toggle-icon"></div>
              </div>
              <span>Is Direct Ship Supplier</span>
            </label>
            {isShipSupplier && <label >
              Direct Ship Supplier Name
              <input
                type="text"
                value={shipSupplierName}
                onChange={e =>
                  this.handleTextInputUpdate(e, 'shipSupplierName')
                }
              />
            </label>}
          </>}
        </div>
      </div>
    );
  }

  getUsersPanel() {
    let { users, userList, usersExpanded, userFilter } = this.state;

    let filteredUsers = users.filter(
      s => s.userName.toLowerCase().indexOf(userFilter.toLowerCase()) > -1,
    );

    let selectedUsers = users.filter(g => userList.indexOf(g.userId) >= 0);

    return (
      <div className="group-users accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('usersExpanded')}>
          <h3>Users</h3>
          <div
            className={`icon ${
              usersExpanded ? 'icon-expand' : 'icon-collapse'
            }`}></div>
        </div>
        <div className={`accordion-body ${usersExpanded ? '' : 'collapsed'}`}>
          <div className="horizontal-flex-even">
            <div>
              <h3>
                All Users (Filtering {filteredUsers.length} of {users.length})
              </h3>
              <div className="floating-header">
                <input
                  placeholder="Filter..."
                  value={userFilter}
                  onChange={e => this.handleTextInputUpdate(e, 'userFilter')}
                />
                <button
                  type="button"
                  className="action"
                  onClick={e => this.handleCheckInputUpdateMultipleUsers(e)}>
                  Select All
                </button>
              </div>
              {filteredUsers.length > 50 && <h4>Too many results</h4>}
              {filteredUsers.length <= 50 &&
                filteredUsers.map(g => (
                  <label key={g.storeId}
                    className="wrapping">
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={userList.indexOf(g.userId) >= 0}
                        onChange={e =>
                          this.handleCheckInputUpdate(e, 'userList', g.userId)
                        }
                      />
                      <div className="toggle-icon"></div>
                    </div>
                    <span>{g.userName}</span>
                  </label>
                ))}
            </div>
            <div>
              <h3>Selected Users ({selectedUsers.length})</h3>
              {selectedUsers.map(g => (
                <label key={g.storeId}
                  className="wrapping">
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      checked={true}
                      onChange={e =>
                        this.handleCheckInputUpdate(e, 'userList', g.userId)
                      }
                    />
                    <div className="toggle-icon"></div>
                  </div>
                  <span>{g.userName}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  getStoresPanel() {
    let { stores, storeList, customersExpanded, storeFilter } = this.state;

    let filteredStores = stores.filter(
      s =>
        s.storeName.toLowerCase().indexOf(storeFilter.toLowerCase()) > -1 ||
        s.storeNumber.toLowerCase().indexOf(storeFilter.toLowerCase()) > -1,
    );

    let selectedStores = stores.filter(g => storeList.indexOf(g.storeId) >= 0);

    return (
      <div className="group-stores accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('customersExpanded')}>
          <h3>Customer Access</h3>
          <div
            className={`icon ${
              customersExpanded ? 'icon-expand' : 'icon-collapse'
            }`}></div>
        </div>
        <div
          className={`accordion-body ${customersExpanded ? '' : 'collapsed'}`}>
          <div className="horizontal-flex-even">
            <div>
              <h3>
                All Customers (Filtering {filteredStores.length} of{' '}
                {stores.length})
              </h3>
              <div className="floating-header">
                <input
                  placeholder="Filter..."
                  value={storeFilter}
                  onChange={e => this.handleTextInputUpdate(e, 'storeFilter')}
                />
                <button
                  type="button"
                  className="action"
                  onClick={e => this.handleCheckInputUpdateMultipleStores(e)}>
                  Select All
                </button>
              </div>
              {filteredStores.length > 250 && <h4>Too many results</h4>}
              {filteredStores.length <= 250 &&
                filteredStores.map(g => (
                  <label key={g.storeId}
                    className="wrapping">
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={storeList.indexOf(g.storeId) >= 0}
                        onChange={e =>
                          this.handleCheckInputUpdate(e, 'storeList', g.storeId)
                        }
                      />
                      <div className="toggle-icon"></div>
                    </div>
                    <span>{g.storeName}</span>
                  </label>
                ))}
            </div>
            <div>
              <h3>Selected Customers ({selectedStores.length})</h3>
              {selectedStores.map(g => (
                <label key={g.storeId}
                  className="wrapping">
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      checked={true}
                      onChange={e =>
                        this.handleCheckInputUpdate(e, 'storeList', g.storeId)
                      }
                    />
                    <div className="toggle-icon"></div>
                  </div>
                  <span>{g.storeName}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { showDeleteModal } = this.state;
    return (
      <div className="edit-customer-group-page page">
        <div className="floating-panel">
          <form noValidate
            onSubmit={e => this.handleSaveGroup(e)}>
            <div className="floating-header">
              <div className="header-title">
                <div className="icon icon-store"></div>
                {this.state.groupId ? (
                  <h2>Edit Customer Group</h2>
                ) : (
                  <h2>Create Customer Group</h2>
                )}
              </div>
            </div>

            {this.getGroupPanel()}

            {this.state.groupId && this.getStoresPanel()}

            {this.state.groupId && this.getUsersPanel()}

            <div className="button-container">
              <button className="action">Save</button>
              {this.state.groupId && (
                <button
                  type="button"
                  className="action negative"
                  onClick={() => this.handleDeleteModalShow()}>
                  Delete
                </button>
              )}
            </div>
          </form>
        </div>

        {showDeleteModal && (
          <DeleteModal
            cancel={this.handleDeleteModalHide}
            continue={this.handleDeleteGroup}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { currentStoreGroup } = state.userManagement;
  return {
    groupLoading: currentStoreGroup.isFetching,
    groupLoaded: currentStoreGroup.isLoaded,
    groupDetails: currentStoreGroup.groupDetails,
    groupSaving: currentStoreGroup.isSaving,
    groupSaved: currentStoreGroup.isSaved,
    groupDeleting: currentStoreGroup.isDeleted,
    groupDeleted: currentStoreGroup.isDeleted,
    tenant: state.auth.tenant,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getStoreGroup: groupId =>
      dispatch(userManagementActions.requestStoreGroup(groupId)),
    saveStoreGroup: groupData =>
      dispatch(userManagementActions.saveStoreGroup(groupData)),
    deleteStoreGroup: groupId =>
      dispatch(userManagementActions.deleteStoreGroup(groupId)),
    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditCustomerGroup);
