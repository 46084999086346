/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { ReasonCodeEnum } from '../Common/Enums/ReasonCodeEnum';
import { TimeFilterEnum } from '../Common/Enums/TimeFilterEnum';
import MarketMultiSelectFilter from './MultiSelect/MarketMultiSelectFilter';
import PeriodMultiSelectFilter from './MultiSelect/PeriodMultiSelectFilter';
import MultiSelect from '../../siteID/siteIDApplications/list/MultiSelect';

function KpiFilter({ mbuList, suppliers, orgs, 
  marketFilter, 
  periodFilter,
  supplierFilter,
  orgFilter,
  reasonCodeFilter,
  selectedMonths,
  itemName,
  onClick }) {

  const [newSupplierFilter, setNewSupplierFilter] = useState(supplierFilter);
  const [newOrgFilter, setNewOrgFilter] = useState(orgFilter);
  const [newReasonCodeFilter, setNewReasonCodeFilter] = useState(reasonCodeFilter);
  const [newItemValue, setNewItemValue] = useState(itemName);
  const [newMarketFilter, setNewMarketFilter] = useState(marketFilter);
  const [newPeriodFilter, setNewPeriodFilter] = useState(periodFilter);
  const [newSelectedMonths, setNewSelectedMonths] = useState(selectedMonths);
  const [isOrgEditable, setIsOrgEditable] = useState(true);

  const handleItemChange = (e) => {
    setNewItemValue(e.target.value);
  }; 

  function handleSupplierChange(selectedSuppliers) {
    if(selectedSuppliers == []){
      setNewOrgFilter([]);
      setIsOrgEditable(true);
      setNewSupplierFilter([]);
    } else {
      setNewOrgFilter(['ZD5']);
      setIsOrgEditable(false);
      setNewSupplierFilter(selectedSuppliers);
    }
  }
   
  const reasonCodes = [
    {
      key: ReasonCodeEnum.FL,
      value:'Full Locking',
    },
    {
      key: ReasonCodeEnum.OF,
      value:'Over Forecast',
    },
    {
      key: ReasonCodeEnum.RS,
      value:'Remaining Shelf Life',
    },
    {
      key: ReasonCodeEnum.SO,
      value:'Stock Out',
    },
    {
      key: ReasonCodeEnum.UF,
      value:'Under Forecast',
    },
    {
      key: ReasonCodeEnum.BO,
      value:'Back Order',
    },
  ];

  const handleSearchButtonClick = () => {
    onClick(newPeriodFilter, newSelectedMonths, newMarketFilter, newSupplierFilter, newOrgFilter, newReasonCodeFilter, newItemValue);
  };

  const handleClearFilterClick = () => {
    setNewSupplierFilter([]);
    setNewSelectedMonths([]);
    setNewOrgFilter([]);
    setNewReasonCodeFilter([]);
    setNewItemValue('');
    setNewMarketFilter([]);
    setNewPeriodFilter(TimeFilterEnum.YTD);

    onClick(TimeFilterEnum.YTD, [], [], [], [], [], null);
  };
    
  const handleMarketFilterChange = (selectedMarkets) => {
    setNewMarketFilter(selectedMarkets);
  };

  const handlePeriodFilterChange = (newFilter) => {
    setNewPeriodFilter(newFilter);
  };

  const handleMonthsChange = (selectedMonths) => {
    setNewSelectedMonths(selectedMonths);
  };

  const handleOrgChange = (selectedOrgs) => {
    setNewOrgFilter(selectedOrgs);
  };

  const handleReasonCodeChange = (selectedReasons) => {
    setNewReasonCodeFilter(selectedReasons);
  };

  return (
    <>
      <div className='kpi-filter-container'>
        <MarketMultiSelectFilter 
          mbuList={mbuList}
          selectedMarkets={newMarketFilter}
          onMarketFilterChange={handleMarketFilterChange}/>
        <PeriodMultiSelectFilter        
          periodFilter={newPeriodFilter}
          selectedMonths={newSelectedMonths}
          onPeriodFilterChange={handlePeriodFilterChange}
          onSelectedMonths={handleMonthsChange}
          orderMetrics={false} />

        <div className="market-filter-container"
          style={{ marginRight: '50px' }}>
          <MultiSelect
            title="Suppliers"
            selectedValues={newSupplierFilter}
            valueChanged={handleSupplierChange}
            values={suppliers ?? []}
            valueName="newSupplierFilter"
          />
        </div>

        <div className="market-filter-container"
          style={{ marginRight: '50px' }}>
          <MultiSelect
            title="ORGs"
            selectedValues={newOrgFilter}
            valueChanged={handleOrgChange}
            values={orgs ?? []}
            valueName="newOrgFilter"
            disabled = {!isOrgEditable}
          />
        </div>

        <div className="market-filter-container"
          style={{ marginRight: '50px' }}>
          <MultiSelect
            title="Reason Codes"
            selectedValues={newReasonCodeFilter}
            valueChanged={handleReasonCodeChange}
            values={reasonCodes}
            valueName="newReasonCodeFilter"
          />
        </div>
      
        <div className="c-form-row">
          <label className="c-form-row__label">
          Item
            <input type="text"
              placeholder='Search Item By Name'
              name="name"
              value={newItemValue}
              onChange={handleItemChange} />
          </label>
        </div>   
      </div>
        
      <div className="kpi-filter-action">
        <button
          type="button"
          className="action"
          onClick={() => handleSearchButtonClick()}
        >
          Search
        </button>
        {<button
          type="clear"
          className="action default"
          onClick={() => handleClearFilterClick()}
        >
          Clear Filter
        </button>}
      </div>
    </>
  );
}

export default KpiFilter;
