import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../actions/navigationActions';

class WhatIsSiteID extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
      <div className="whatissiteid-page page">
        <div
          className="close-button icon icon-image-remove-dark"
          onClick={() => this.props.push('/')}
        />
        <div className="mid-content">
          <div className="top-content">
            <div className="introduction">
              <img
                className="starbucks-logo"
                src="/assets/clear-logo.png"
                alt="starbucks logo"
              />
              <h3>Starbucks S.I.T.T. Module</h3>
              <p>
                The Site Identification &amp; Tracking Tool is available within the secure MyStarbucks
                portal and enables you to quickly and easily review your current
                estate and submit new site applications.
              </p>
              <button
                type="button"
                onClick={() => {
                  const redirectWindow = window.open(
                    'https://orderly.wistia.com/medias/5xqrqunkbi',
                    '_blank',
                  );
                  redirectWindow.location = 'https://orderly.wistia.com/medias/5xqrqunkbi';
                }}
                className="float"
              >
                Watch intro
              </button>
            </div>
            <div className="image-holder">
              <img
                className="laptop-image"
                src="/assets/whatissiteid/Play.png"
                alt="A laptop showing the My Starbucks portal"
              />
              <iframe
                allowtransparency="true"
                title="Wistia video player"
                allowFullScreen
                frameBorder="0"
                scrolling="no"
                className="wistia_embed"
                name="wistia_embed"
                src="https://fast.wistia.com/embed/medias/5xqrqunkbi"
                width="400"
                height="225"
              />
            </div>
          </div>
          <div className="key-features">
            <div className="key-features-content">
              <h3> Key Features</h3>
              <div className="icons-and-descriptions">
                <div className="feature">
                  <div
                    className="icon icon-siteid-features-white"
                    alt="box with multiple arrows"
                  />
                  <div className="feature__text">
                    <h4> Key Features </h4>
                    <p>
                      Quick and easy access to view all of your site
                      applications consolidated in a single location.
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <div className="icon icon-siteid-pin-white"
                    alt="A map pin" />
                  <div className="feature__text">
                    <h4> Maps Integration</h4>
                    <p>
                      Drop a pin on your map to identify a new site location.
                      View all of your sites on the map.
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <div className="icon icon-announcements"
                    alt="a megaphone" />
                  <div className="feature__text">
                    <h4> Notifications and Alerts</h4>
                    <p>
                      Stay up to date and receive alerts when the status of your
                      site application changes.
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <div
                    className="icon icon-siteid-reports-white"
                    alt="documents"
                  />
                  <div className="feature__text">
                    <h4>Reports and Documents</h4>
                    <p>
                      Easily export your data in a single click. Upload key
                      information during the site application process.
                    </p>
                  </div>
                </div>

                <div className="feature">
                  <div
                    className="icon icon-siteid-access-white"
                    alt="a pad lock"
                  />
                  <div className="feature__text">
                    <h4>Access Controls</h4>
                    <p>
                      Control the levels of access you want to provide your
                      organisation - including users and groups.
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <div className="icon icon-mobile"
                    alt="a mobile phone" />
                  <div className="feature__text">
                    <h4>Access on the go</h4>
                    <p>
                      Fully secure and mobile friendly, so you can keep up to
                      date with your applications wherever you are.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="access-on-the-go">
            <img
              className="phone-image"
              src="/assets/whatissiteid/Application.png"
              alt="A smartphone showing the My Starbucks portal"
            />
            <div className="access-text">
              <h3>Supercharge Your Store Openings</h3>
              <p>
                The new web based module replaces the dated, manual spreadsheet
                driven process, saving you time, giving you better visibility
                and control of applications and their statuses.
              </p>
              <p>
                The application feeds all users tasks and clearly highlights
                next steps needed to bring a site from idea to sign-off. No more
                manual processes are rekeying data, much more speed and
                simplicity.
              </p>
            </div>
          </div>
          <div className="simple-to-use">
            <h3>Simple to use and accessible to all</h3>
            <div className="simple-content">
              <div className="simple-green-box">
                <div
                  className="icon icon-customer-service-light"
                  alt="A starbucks cup"
                />
                <p>
                  You can access the application wherever you are - from your
                  desktop PC in your home or office, or on a mobile device
                  straight after surveying your new site!
                </p>
              </div>
              <img
                className="simple-image"
                src="/assets/whatissiteid/Use Outdoor.png"
                alt="A man with a laptop"
              />
            </div>
          </div>
          <div className="store-showcase">
            <div className="store-green-box">
              <div className="icon icon-qa-light"
                alt="A star" />
              <p>
                A task based interface ensure each user knows the next step in
                the process and can see the current status of the application.
              </p>
              <p>
                Auto-emails from the system encourage timely updates from all
                stakeholders to ensure a speedy process.
              </p>
            </div>
          </div>

          <div className="access-today">
            <div className="access-content">
              <div className="access-text">
                <h3>Access Today</h3>
                <p>
                  Eligible users can access the S.I.T.T. module by logging into
                  MyStarbucks, selecting modules and choosing “S.I.T.T.”.
                </p>
                <button
                  type="button"
                  onClick={() => {
                    this.props.push('/');
                  }}
                  className="float"
                >
                  Sign in now
                </button>
              </div>
              <img
                className="i-pad-image"
                src="/assets/whatissiteid/Log In Now.png"
                alt="An ipad showing the My Starbucks portal"
              />
            </div>
          </div>
          <footer
            className="orderly-footer"
            onClick={() => window.location = ('https://orderly.io')}
          >
            ©
            {this.getYear()}
            {' '}
            Powered by
            <img
              className="orderly-logo"
              src="/assets/logo-orderly.png"
              alt="orderly logo"
            />
          </footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(navigationActions.pushNavigation(path)),
  replace: (path) => dispatch(navigationActions.replaceNavigation(path)),
  reset: () => dispatch(navigationActions.resetNavigation()),
  goBack: () => dispatch(navigationActions.backNavigation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WhatIsSiteID);
