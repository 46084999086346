import React from 'react';

class WeatherTileHorizontal extends React.Component {

  generateDateLabel(date) {

    return 'Today';
  }

  generateTimeStyleColor(sunrise, sunset) {

    return { backgroundColor: '#95d7fb' };
  }

  getDirection(degrees) {

    if (degrees > 337.5 || degrees <= 22.5) {
      return 'N';
    }
    else if (degrees > 292.5) {
      return 'NW';
    }
    else if (degrees > 247.5) {
      return 'W';
    }
    else if (degrees > 202.5) {
      return 'SW';
    }
    else if (degrees > 157.5) {
      return 'S';
    }
    else if (degrees > 112.5) {
      return 'SE';
    }
    else if (degrees > 67.5) {
      return 'E';
    }
    else if (degrees > 22.5) {
      return 'NE';
    }

    return '';
  }

  render() {
    const { data } = this.props;

    if (!data) {
      return null;
    }

    const { dt, sunrise, sunset, weather, temp, wind_deg, wind_speed } = data;

    return (
      <div className="connect-weather-tile-horizontal"
        style={this.generateTimeStyleColor(sunrise, sunset)}>
        <div className="date">
          {this.generateDateLabel(dt)} - {weather && weather.length > 0 && weather[0].main}
        </div>
        <div>
          <div className="weather-row">
            <div className="left-stack">

              <div className="weather">
                <div className="weather-icon">
                  {weather && weather.length > 0 &&
                    <img src={`/assets/weather-icons/${weather[0].icon}@2x.png`}
                      alt={weather[0].description} />}
                </div>
              </div>
            </div>


            <div className="wind">

              <img src="/assets/weather-icons/wind-arrow.svg"
                alt="icon pointing in wind compass direction"
                style={{ transform: `rotate(${wind_deg + 90}deg)` }} />
              <div>
                <div>{this.getDirection(wind_deg)}</div>
                <div>{(wind_speed * 3.6).toFixed(1)}km/h</div>
              </div>

            </div>

            <div className="temperatures">
              <div className="min-max">
                <div className="max">
                  {`${temp.max.toFixed(0)}°C`}
                </div>
                <div className="min">
                  {`${temp.min.toFixed(0)}°C`}
                </div>
              </div>
              <div className="thermometer">
                <img src="/assets/weather-icons/thermometer.svg"
                  alt="thermometer" />
              </div>
            </div>

          </div>{weather && weather.length > 0 &&
            <div className="description">{weather[0].description}</div>
          }
        </div>
      </div>
    );
  }
}

export default WeatherTileHorizontal;
