import {
  CONNECT_EVENTS_REQUEST,
  CONNECT_EVENTS_SUCCESS,
  CONNECT_EVENTS_FAILURE,
} from './actionTypes';

export const connectEventsActions = {
  getEvents: () => (dispatch, getState) => {
    dispatch({
      type: CONNECT_EVENTS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/Connect/Events/v1/Get',
        successAction: CONNECT_EVENTS_SUCCESS,
        failureAction: CONNECT_EVENTS_FAILURE,
      },
    });
  },
};
