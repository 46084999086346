import React from 'react';

class BookingTabOds extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { customerRequirementsDetail, handleTextInputUpdate } = this.props;
    return (
      <>
        <label>
        Booking Requirements
          <textarea
            maxLength={500}
            type="text"
            value={customerRequirementsDetail && customerRequirementsDetail.bookingRequirements}
            onChange={e => handleTextInputUpdate(e, 'bookingRequirements')}
          />
        </label>
      </>
    );
  }
}

export default BookingTabOds;
