import {
  SITEID_UPLOADDTMPFILE_REQUEST,
  SITEID_UPLOADDTMPFILE_SUCCESS,
  SITEID_UPLOADDTMPFILE_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const siteIDDtmpFileReducer = (
  state = initialState.siteIDDtmpFileState,
  action,
) => {
  switch (action.type) {
  case SITEID_UPLOADDTMPFILE_REQUEST: {
    return {
      ...state,
      isUploading: true,
      isLoaded: false,
      errorMessage: '',
    };
  }
  case SITEID_UPLOADDTMPFILE_SUCCESS: {
    return {
      ...state,
      isUploading: false,
      isLoaded: true,
      errorMessage: '',
    };
  }
  case SITEID_UPLOADDTMPFILE_FAILURE: {
    return {
      ...state,
      isUploading: false,
      isLoaded: false,
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default siteIDDtmpFileReducer;
