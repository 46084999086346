import {
  CONNECT_CONTENTLABEL_REQUEST,
  CONNECT_CONTENTLABEL_SUCCESS,
  CONNECT_CONTENTLABEL_FAILURE,
  CONNECT_CONTENTLABELS_REQUEST,
  CONNECT_CONTENTLABELS_SUCCESS,
  CONNECT_CONTENTLABELS_FAILURE,
  CONNECT_SAVELABEL_REQUEST,
  CONNECT_SAVELABEL_SUCCESS,
  CONNECT_SAVELABEL_FAILURE,
  CONNECT_DELETELABEL_REQUEST,
  CONNECT_DELETELABEL_SUCCESS,
  CONNECT_DELETELABEL_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const ConnectLabelRouter = (
  state = initialState.connectLabelState,
  action,
) => {
  switch (action.type) {
  case CONNECT_CONTENTLABELS_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      isSaving: false,
      isSaved: false,
      isDeleting: false,
      isDeleted: false,
      error: undefined,
      labelList: [],
      selectedLabel: {},
    };
  case CONNECT_CONTENTLABELS_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      isSaving: false,
      isSaved: false,
      isDeleting: false,
      isDeleted: false,
      error: undefined,
      labelList: action.payload,
      selectedLabel: {},
    };
  case CONNECT_CONTENTLABELS_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      isSaving: false,
      isSaved: false,
      isDeleting: false,
      isDeleted: false,
      error: action.payload.message,
      labelList: [],
      selectedLabel: {},
    };

  case CONNECT_CONTENTLABEL_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      isSaving: false,
      isSaved: false,
      isDeleting: false,
      isDeleted: false,
      error: undefined,
      selectedLabel: {},
    };
  case CONNECT_CONTENTLABEL_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      isSaving: false,
      isSaved: false,
      isDeleting: false,
      isDeleted: false,
      error: undefined,
      selectedLabel: action.payload,
    };
  case CONNECT_CONTENTLABEL_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      isSaving: false,
      isSaved: false,
      isDeleting: false,
      isDeleted: false,
      error: action.payload.message,
      selectedLabel: {},
    };

  case CONNECT_SAVELABEL_REQUEST:
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      error: undefined,
      selectedLabel: {},
    };
  case CONNECT_SAVELABEL_SUCCESS:
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      error: undefined,
      selectedLabel: action.payload,
    };
  case CONNECT_SAVELABEL_FAILURE:
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      error: action.payload.message,
      selectedLabel: {},
    };

  case CONNECT_DELETELABEL_REQUEST:
    return {
      ...state,
      isDeleting: true,
      isDeleted: false,
      error: undefined,
    };
  case CONNECT_DELETELABEL_SUCCESS:
    return {
      ...state,
      isDeleting: false,
      isDeleted: true,
      error: undefined,
      selectedLabel: {},
    };
  case CONNECT_DELETELABEL_FAILURE:
    return {
      ...state,
      isDeleting: false,
      isDeleted: false,
      error: action.payload.message,
    };
  default:
    return state;
  }
};
export default ConnectLabelRouter;
