/* eslint-disable react/jsx-key */
import React from 'react';

const DropdownSelectCategory = ({  productCategories, selectedValue, onChange }) => {
  return (
    <div className='period-filter-container'>
      <div className='multi-select-siteid dropdown-style'>
        <div className="c-form-row">
          <label className="c-form-row__label">
            <select
              className="c-input"
              value={selectedValue}
              onChange={onChange}
            >
              <option value="-1">All Categories</option>
              {productCategories && productCategories.map((category) => (
                <option value={category.omCategoryId}>{category.name}</option>
              ))}
            </select>
          </label>
        </div>
      </div>
    </div>
  );
};

export default DropdownSelectCategory;
