import React from 'react';
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
} from 'recharts';

const KpiPieChart = (props) => {
  const {
    pieChartData,
    onClickPieChart,
  } = props;
  return (
    <div className="percentage-piechart">
      <div className="percentage-piechart-inner" >
        {pieChartData
          && pieChartData.map((pieChart, index) => {
            const data = [
              {
                colour: '#cfcfcf',
                value: Math.round((100 - pieChart.percentage.value) * 100) / 100,
                name: ' ',
              },
              {
                colour: pieChart.percentage.colour,
                value: Math.round((pieChart.percentage.value) * 100) / 100,
                name: 'In Full %',
              },

            ];
            return (
              <div
                key={index}
                className={`chart ${pieChart.selected ? 'selected' : ''}`}
                onClick={() => onClickPieChart(pieChart)}
              >
                <PieChart width={240}
                  height={240}>
                  <Pie
                    data={data}
                    labelLine={false}
                    fill="#8884d8"
                    dataKey="value"
                  // label={renderCustomizedLabel}
                  >
                    {
                      data.map((entry, index) => (
                        <Cell
                          key={`cell-pie-${entry.name}${index}`}
                          fill={entry.colour}
                          stroke="white"
                        />
                      ))
                    }
                  </Pie>
                  <Tooltip />
                </PieChart>
                <span className="pie-percentage">{pieChart.percentage.value}</span>
                {pieChart.label}
              </div>
            );
          },
          )}
      </div>
    </div>
  );
};

export default KpiPieChart;
