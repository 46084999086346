import React, { useState, useEffect } from 'react';

const SentrkApplicationPage = ({ sentrkUrl, logoutUrl, isMixedUser }) => {
  const [currentUrl, setCurrentUrl] = useState(sentrkUrl);
  const [iframeClass, setIframeClass] = useState('iframeNotFullscreen');
  const [iframeBtnClass, setIframeBtnClass] = useState('iframeNotFullscreenButton');
  const [iframeBtnText, setIframeBtnText] = useState('Make Fullscreen');

  useEffect(() => {
    if (isMixedUser){
      if (sentrkUrl) {
        setCurrentUrl(logoutUrl);

        const timer = setTimeout(() => {
          setTimeout(() => {
            setCurrentUrl(sentrkUrl);
          }, 1000); 
        }, 3000); 
     
        return () => clearTimeout(timer);
      }
    }
  }, [sentrkUrl, logoutUrl]);

  const handleFullscreenToggle = () => {
    setIframeClass(prev => prev === 'iframeNotFullscreen' ? 'iframeFullscreen' : 'iframeNotFullscreen');
    setIframeBtnClass(prev => prev === 'iframeNotFullscreenButton' ? 'iframeFullscreenButton' : 'iframeNotFullscreenButton');
    setIframeBtnText(prev => prev === 'Make Fullscreen' ? 'Exit Fullscreen' : 'Make Fullscreen');
  };

  const iframeVisible = currentUrl !== logoutUrl;

  return (
    <div>
      <h3>Sentrk</h3>
      <div>
        <button
          className={`${iframeBtnClass} button square`}
          type="button"
          title={iframeBtnText}
          onClick={handleFullscreenToggle}
        >
          <div className="icon icon-full-screen"></div>
        </button>
        {iframeVisible && (
          <iframe
            className={iframeClass}
            src={isMixedUser ? currentUrl : sentrkUrl}
            name="sentrk_application"
            id="sentrkIframe"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default SentrkApplicationPage;
