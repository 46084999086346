import React from "react";

class HeatMapPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapLoaded: false,

      heatPoints: [],
      heatMapLayer: undefined
    };
    this.googleMapRef = React.createRef();
  }

  componentDidMount() {
    let googleMapScript = document.getElementById("googleMapScript");

    if (!googleMapScript) {
      googleMapScript = document.createElement("script");
      googleMapScript.id = "googleMapScript";
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${this.props.apiKey}&libraries=visualization`;
      window.document.body.appendChild(googleMapScript);

      googleMapScript.addEventListener("load", () => {
        this.initialiseMap(54.472611, -3.480127, 5);
      });
    } else {
      this.initialiseMap(54.472611, -3.480127, 5);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { mapLoaded, heatMapLayer } = this.state;

    const { isLoading, data } = this.props;

    if (!mapLoaded) return false;

    let newState = {};
    let isChanged = false;

    console.log("State : Start " + this.props.mode);

    //Mode swap - clear down
    if (isLoading && !prevProps.isLoading && heatMapLayer) {
      if (heatMapLayer) {
        heatMapLayer.setMap(null);

        newState = {
          ...newState,
          heatMapLayer: undefined,
          heatPoints: []
        };
        isChanged = true;
      }
    }

    if (!isLoading && !heatMapLayer && data && data.length > 0) {
      const heatMapData = data.map(d => {
        return {
          location: new window.google.maps.LatLng(d.latitude, d.longitude),
          weight: d.weight
        };
      });

      var heatmap = new window.google.maps.visualization.HeatmapLayer({
        data: heatMapData
      });

      heatmap.setMap(this.googleMap);
      newState = {
        ...newState,
        heatMapLayer: heatmap,
        heatPoints: heatMapData
      };
      isChanged = true;
    }

    if (isChanged) {
      this.setState(newState);
      console.log("State : Updated");
    }
  }

  initialiseMap(latitude, longitude, zoom) {
    this.googleMap = this.createGoogleMap(latitude, longitude, zoom);

    this.setState({ mapLoaded: true });
  }

  createGoogleMap = (latitude, longitude, zoom) =>
    new window.google.maps.Map(this.googleMapRef.current, {
      zoom: zoom,
      center: {
        lat: latitude,
        lng: longitude
      },
      gestureHandling: "cooperative",
      disableDefaultUI: true,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR
      }
    });

  render() {
    const { mode } = this.props;
    return (
      <div className={`floating-panel google-panel ${mode}`}>
        <div
          id="google-map"
          ref={this.googleMapRef}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  }
}

export default HeatMapPanel;
