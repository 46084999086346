import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../actions/navigationActions';
import ContactUsModal from './contactUsModal';

class WhatIsOrderly extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.handleContactModalShow = this.handleContactModalShow.bind(this);
    this.handleContactModalHide = this.handleContactModalHide.bind(this);
  }

  handleContactModalShow() {
    this.setState({ showModal: true });
  }

  handleContactModalHide() {
    this.setState({ showModal: false });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getYear() {
    return new Date().getFullYear();
  }

  render() {
    const { showModal } = this.state;
    if (!showModal) {
      return (
        <div className="whatisorderly-page page">
          <div className="orderly-menu">
            <img
              className="orderly-logo"
              src="/assets/orderly/logo-white.png"
              srcSet="/assets/orderly/logo-white.png 1x, /assets/orderly/logo-white@2x.png 2x"
              alt="orderly logo"
            />
            <div className="button-bar">
              <a href="/whatisorderly#key-features">Key Features</a>
              <a href="/whatisorderly#proven">Proven</a>
              <a href="/whatisorderly#award-winning">Award Winning</a>
              <a href="/whatisorderly#highly-rated">Highly Rated</a>
              {/* <a href="/whatisorderly#faq">FAQ</a> */}
              <a href="/whatisorderly#contact-us">Contact Us</a>
            </div>
            <div
              className="close-button icon icon-image-remove"
              onClick={() => this.props.push('/')}
            />
          </div>
          <div className="top-content">
            <div className="introduction">
              <h3>
                Award Winning Inventory Management for your Starbucks Estate
              </h3>
              <p>
                <b>Increase control and efficiency.</b>
              </p>
              <p>
                Developed in partnership with Starbucks - Orderly’s Inventory
                Management system is proven to increase eﬃciency and data
                visibility for operators of Starbucks stores.
              </p>
              <button
                type="button"
                onClick={() => this.handleContactModalShow()}
                className="float float--blue"
              >
                Learn More
              </button>
            </div>
            <img
              className="laptop-image"
              src="/assets/orderly/Laptop@2x.png"
              srcSet="/assets/orderly/Laptop@2x.png 2x"
              alt="A laptop showing orderly"
            />
          </div>
          <div className="bar bar--blue bar--decorations">
            <div id="key-features"
              className="key-features">
              <h3> Key Features</h3>
              <div className="feature-list">
                <div className="feature">
                  <div className="icon icon-recipe"
                    alt="chefs hat" />
                  <div className="feature__text">
                    <h4>Accurate Recipe Data</h4>
                    <p>
                      Centrally managed recipe data with ability to overwrite
                      and suggest ﬁxes
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <div
                    className="icon icon-rmas"
                    alt="Arrow coming out of a tray"
                  />
                  <div className="feature__text">
                    <h4>Automated RMAs</h4>
                    <p>
                      The system recommends items to be sent for credit request
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <div className="icon icon-po"
                    alt="Arrow going into a tray" />
                  <div className="feature__text">
                    <h4>Automated PO Receipting</h4>
                    <p>Orders items shown with fast track entry, saving time</p>
                  </div>
                </div>
                <div className="feature">
                  <div className="icon icon-smarter"
                    alt="light bulb" />
                  <div className="feature__text">
                    <h4>Smarter Stock Counts</h4>
                    <p>
                      Fast, easy stock counts with optional blind-counting
                      interface
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <div className="icon icon-dashboard"
                    alt="speedometer" />
                  <div className="feature__text">
                    <h4>Data Dashboards</h4>
                    <p>Get key store information at your ﬁngertips</p>
                  </div>
                </div>
                <div className="feature">
                  <div
                    className="icon icon-gears"
                    alt="interconnected gears with checkmarks"
                  />
                  <div className="feature__text">
                    <h4>Supplier Integration</h4>
                    <p>
                      Integrates directly with external suppliers including your
                      milk supplier
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <div
                    className="icon icon-transfers"
                    alt="warehouse with boxes and truck moving boxes"
                  />
                  <div className="feature__text">
                    <h4>Stock Transfers</h4>
                    <p>Easily transfer stock between your stores</p>
                  </div>
                </div>
                <div className="feature">
                  <div
                    className="icon icon-reporting"
                    alt="Charts and graphs"
                  />
                  <div className="feature__text">
                    <h4>Full Reporting Suite</h4>
                    <p>
                      Full reporting suite with a wide range of detailed reports
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <div className="icon icon-mobile"
                    alt="a mobile phone" />
                  <div className="feature__text">
                    <h4>Mobile Friendly</h4>
                    <p>
                      No software to install and available on the go from your
                      mobile device
                    </p>
                  </div>
                </div>
              </div>
              <p className="right-align">
                New features are regularly added to continuously improve your
                data visibility and eﬃciency.
              </p>
            </div>
          </div>
          <div id="proven">
            <img
              className="phone-image"
              src="/assets/orderly/iPhoneSplash.png"
              srcSet="/assets/orderly/iPhoneSplash.png 1x, /assets/orderly/iPhoneSplash@2x.png 2x"
              alt="A smartphone showing the My Starbucks portal"
            />
            <div className="access-text">
              <h3>Live in Hundreds of Starbucks Stores</h3>
              <p>
                Since 2017 we’ve worked closely with partners from the Starbucks
                Licensed store estate to enhance our Inventory solution and
                product roadmaps to align and continuously add value to their
                individual businesses.
              </p>
              <p>
                We’re proven to save time in store, drive sales by decreasing
                missed opportunities and reduce waste.
              </p>
            </div>
          </div>
          <div id="award-winning">
            <h3>Winner - Best Restaurant Management Tool</h3>
            <div className="simple-content">
              <div className="blue-box">
                <div className="icon icon-trophy"
                  alt="A trophy" />
                <p>
                  Orderly Inventory is proud to have been named the Best
                  Restaurant Management Tool 2018 at the Restaurant &amp; Bar
                  Technology Innovation Awards 2018. We were praised for our
                  drive and ability to reduce waste in our client’s stores.
                </p>
              </div>
              <img
                className="simple-image"
                src="/assets/orderly/award.png"
                srcSet="/assets/orderly/award.png 1x, /assets/orderly/award@2x.png 2x"
                alt="A man with a laptop"
              />
            </div>
          </div>
          <div id="highly-rated"
            className="bar bar--blue">
            <div className="store">
              <div className="blue-box">
                <img
                  className="amish-image"
                  src="/assets/orderly/amish.png"
                  srcSet="/assets/orderly/amish.png 1x, /assets/orderly/amish@2x.png 2x"
                  alt="A man with a laptop"
                />
                <p>
                  The Orderly Team have made a great product that gives me
                  better visibility of my estate’s Inventory.
                </p>
                <p>Amish Patel - Amsric Foods Limited</p>
              </div>
            </div>
          </div>
          {/* <div id="faq" className="includes">
          <h3>Frequently Asked Questions</h3>
          <div className="questions"></div>
    </div> */}
          <div id="contact-us"
            className="access-today">
            <div className="access-text">
              <h3>Ready to learn more?</h3>
              <p>
                We’d love to demonstrate how we can increase the eﬃciency and
                accuracy of your stock management process.
              </p>
              <p>
                Simply add some contact details on the form below, and we’ll get
                back to you at a time that suits you. Alternatively you .
              </p>
              <button
                type="button"
                onClick={() => this.handleContactModalShow()}
                className="float"
              >
                Book a Consultation
              </button>
              <p>
                Alternatively you can contact us on
                {' '}
                <a href="tel:+44 330 016 5170">+44(0)330 016 5170</a>
                {' '}
                or email
                us at
                {' '}
                <a href="mailto:sales@orderlysuite.com">
                  sales@orderlysuite.com
                </a>
              </p>
            </div>
            <img
              className="i-pad-image"
              src="/assets/orderly/iPad.png"
              srcSet="/assets/orderly/iPad.png 1x, /assets/orderly/iPad@2x.png 2x"
              alt="An ipad showing Orderly"
            />
          </div>
          <footer
            className="orderly-footer"
            onClick={() => window.location = ('https://orderly.io')}
          >
            ©
            {this.getYear()}
            {' '}
            Powered by
            <img
              className="orderly-logo"
              src="/assets/logo-orderly.png"
              alt="orderly logo"
            />
          </footer>
        </div>
      );
    }
    return (
      <div className="whatisorderly-page page">
        <ContactUsModal close={() => this.handleContactModalHide()} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(navigationActions.pushNavigation(path)),
  replace: (path) => dispatch(navigationActions.replaceNavigation(path)),
  reset: () => dispatch(navigationActions.resetNavigation()),
  goBack: () => dispatch(navigationActions.backNavigation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WhatIsOrderly);
