import React from 'react';
import './StatusDropdown.scss';

export default ({ value, extraInfo, className = '', includedStatuses = undefined, ...props }) => {

  const isIncluded = (statusId) => includedStatuses === undefined || includedStatuses.includes(statusId);

  return (
    <label className="ot-status-dropdown">
      {extraInfo} Status
      <select
        className={`coloured-dropdown background-color-${value} ${className}`}
        value={value}
        {...props}
      >
        <option value="">Select a status</option>
        {isIncluded(1) && <option value="1">Cancelled</option>}
        {isIncluded(2) && <option value="2">Request to Cancel</option>}
        {isIncluded(3) && <option value="3">Behind Schedule</option>}
        {isIncluded(4) && <option value="4">On Track</option>}
        {isIncluded(5) && <option value="5">Shipped</option>}
      </select>
    </label>
  );
}; 