import {
  INSTANTMESSAGEARCHIVE_REQUEST,
  INSTANTMESSAGEARCHIVE_SUCCESS,
  INSTANTMESSAGEARCHIVE_FAILURE,
  INSTANTMESSAGEARCHIVE_DETAIL_REQUEST,
  INSTANTMESSAGEARCHIVE_DETAIL_SUCCESS,
  INSTANTMESSAGEARCHIVE_DETAIL_FAILURE,
} from './actionTypes';

export const instantMessagingArchiveActions = {
  requestMessageArchive: (filter, page, pageSize) => (dispatch, getState) => {
    dispatch({
      type: INSTANTMESSAGEARCHIVE_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/instantmessaging/v1/get?filter=${filter}&page=${page}&pageSize=${pageSize}`,
        successAction: INSTANTMESSAGEARCHIVE_SUCCESS,
        failureAction: INSTANTMESSAGEARCHIVE_FAILURE,
      },
    });
  },
  requestMessageArchiveDetail: conversationId => (dispatch, getState) => {
    dispatch({
      type: INSTANTMESSAGEARCHIVE_DETAIL_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/instantmessaging/v1/Get/${conversationId}`,
        successAction: INSTANTMESSAGEARCHIVE_DETAIL_SUCCESS,
        failureAction: INSTANTMESSAGEARCHIVE_DETAIL_FAILURE,
      },
    });
  },
};
