import {
  UPLOADMASTERFILE_REQUEST,
  UPLOADMASTERFILE_SUCCESS,
  UPLOADMASTERFILE_FAILURE,
} from './actionTypes';

export const masterFileActions = {
  uploadMasterFile: masterFile => (dispatch, getState) => {
    dispatch({
      type: UPLOADMASTERFILE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/MasterFile/v1/UploadMasterFile',
        successAction: UPLOADMASTERFILE_SUCCESS,
        failureAction: UPLOADMASTERFILE_FAILURE,
        body: {
          ...masterFile,
        },
      },
    });
  },
};
