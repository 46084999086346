import {
  CONNECT_CONTENTLIST_REQUEST, 
  CONNECT_CONTENTLIST_SUCCESS, 
  CONNECT_CONTENTLIST_FAILURE, 
  CONNECT_CONTENTARCHIVE_REQUEST,
  CONNECT_CONTENTARCHIVE_SUCCESS,
  CONNECT_CONTENTARCHIVE_FAILURE,
  CONNECT_CONTENTDISPLAY_REQUEST,
  CONNECT_CONTENTDISPLAY_SUCCESS,
  CONNECT_CONTENTDISPLAY_FAILURE,
} from './actionTypes';
  
export const connectContentActions = {
  getContentDisplay: () => (dispatch, getState) => {
    dispatch({
      type: CONNECT_CONTENTDISPLAY_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/Connect/content/v1/display',
        successAction: CONNECT_CONTENTDISPLAY_SUCCESS,
        failureAction: CONNECT_CONTENTDISPLAY_FAILURE,
      },
    });
  },
  getContentList: (filter, labelId, publishedDate, page, pageSize) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_CONTENTLIST_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Connect/content/v1/list\
?filter=${filter ? filter : ''}\
&page=${page? page: ''}\
&pageSize=${pageSize? pageSize: ''}\
${labelId ? `&labelId=${labelId}`: ''}
${publishedDate ? `&publishedDate=${publishedDate}`: ''}`,
        successAction: CONNECT_CONTENTLIST_SUCCESS,
        failureAction: CONNECT_CONTENTLIST_FAILURE,
      },
    });
  },
  getContentArchive: (filter, labelId, publishedDate, page, pageSize) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_CONTENTARCHIVE_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Connect/content/v1/archive\
?filter=${filter ? filter : ''}\
&page=${page? page: ''}\
&pageSize=${pageSize? pageSize: ''}\
${labelId ? `&labelId=${labelId}`: ''}
${publishedDate ? `&publishedDate=${publishedDate}`: ''}`,
        successAction: CONNECT_CONTENTARCHIVE_SUCCESS,
        failureAction: CONNECT_CONTENTARCHIVE_FAILURE,
      },
    });
  },
};
  