import React, { useEffect, useState } from 'react';
import { Tooltip } from '@material-ui/core';

function AssignedMatrix(props){
  const [displayMode, setDisplayMode] = useState(1);
  const [customerName, setCustomerName] = useState('');
  const [status, setStatus] = useState('');
  const [records, setRecords] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  useEffect(() => {
    searchRecords(true);
  }, [props.taskDetailId]);

  const searchRecords = async(initialLoad = false) => {   
    const response = await fetch(`/api/connect/taskdetail/v1/GetAssignedMatrixUserRecords?customerName=${customerName}&status=${status}&taskDetailId=${props.taskDetailId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    if (response.status == 200){
      const data = await response.json();

      if (!initialLoad){       
        setTableData(data);
        setHasSearched(true);
      }
      setRecords(data);
    }
    if (response.status == 204){
      setHasSearched(true);
      setRecords([]);
      setTableData([]);
    }
  };

  const convertUTCDateToLocalDate = (date) => {
    var newDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
    return newDate;   
  };

  const sendReminder = async(userId) => {   
    const response = await fetch(`/api/connect/taskdetail/v1/SendReminder?userId=${userId ?? 0}&taskDetailId=${props.taskDetailId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      method: 'POST',
    });

    if (response.status == 200){
      const data = await response.json();
      console.log(data);
      setHasSearched(false);
      setResponseMessage(data);
    }
  };

  const clearRecords = async() => {   
    setTableData([]);
    setCustomerName('');
    setStatus('');
    setHasSearched(false);
    setResponseMessage('');
  };

  const unremindedUsers = records.some(
    x => x.allowEmail && x.completedOn == null && (new Date().getTime() - (30 * 60 * 1000)) > convertUTCDateToLocalDate(new Date(x.lastReminded))?.getTime(),
  );

  return (
    <div className="assigned-matrix-page">
      <div className="assigned-matrix-container table-container">
        <div>
          <div className="table-header">
            <div className='bulk-edit-pagination-container'>
              <div className="display-options">
              Table Size
                <button
                  type="button"
                  className="icon-display-mode-1"
                  disabled={displayMode === 1}
                  onClick={() => setDisplayMode(1)}></button>
                <button
                  type="button"
                  className="icon-display-mode-2"
                  disabled={displayMode === 2}
                  onClick={() => setDisplayMode(2)}></button>
                <button
                  type="button"
                  className="icon-display-mode-3"
                  disabled={displayMode === 3}
                  onClick={() => setDisplayMode(3)}></button>
              </div>
            </div>
            <h2>
          Assigned Task Recipient Matrix
            </h2>
            <div className="pagination-controls">
            </div>
          </div>
          <div>
            <div className="table-header wrap">
              <div className='flex-wrap'>
                <label className='margin-right'>
                  Customer Full Name
                  <input
                    type="text"
                    placeholder="Customer Full Name"
                    onChange={((e) => setCustomerName(e.target.value))}
                    value={customerName}
                  />
                </label>
                <label className='margin-right'>
                  Status
                  <select
                    onChange={((e) => setStatus(e.target.value))}
                    value={status}
                  >
                    <option value=''>All</option>
                    <option value='completed'>Completed</option>
                    <option value='not completed'>Not Completed</option>
                  </select>
                </label>
                <button className='action search'
                  type='button'
                  onClick={() => searchRecords()}
                >Search</button>
                <button className='action negative clear'
                  type='button'
                  onClick={() => clearRecords()}
                >Clear</button>
              </div>
              {!unremindedUsers ? (
                <Tooltip title={'All recipients have received a reminder less than thirty minutes ago. Try again soon.'}>
                  <button
                    type='button'
                    disabled
                    className='action send-reminder-all'
                  >
                    {'Send Reminder For All Recipients'}
                  </button>
                </Tooltip>
              ) : (
                <button
                  type='button'
                  onClick={() => sendReminder()}
                  className='action send-reminder-all'
                >
                  {'Send Reminder For All Recipients'}
                </button>
              )}
            </div>
            <h5 style={{ paddingLeft:'40px', paddingBottom: '10px' }}> 
              {!hasSearched && responseMessage.length > 0 && responseMessage}</h5>
          </div>
        </div>
        {hasSearched &&
        <div className="table-body">
          <table className={`table-display-mode-${displayMode}`}>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email Address</th>        
                <th>Status</th> 
                <th>Completed Time Stamp</th> 
                <th>Downloaded</th>  
                <th>Allow Emails</th>  
                <th></th>  
              </tr>
            </thead>
            <tbody>
              {tableData.length == 0 &&
              <tr>
                <td
                  colSpan={12}
                  className="no-records"
                >
                  {'No records found.'}
                </td>
              </tr>
              }
              {tableData && tableData.length > 0 && tableData.map(r => 
                <tr key={r.taskDetailRecipientId}>
                  <td>{r.firstName}</td>
                  <td>{r.lastName}</td>
                  <td>{r.emailAddress}</td>
                  <td>{r.completedOn != null ? 'Completed' : 'Not Completed'}</td>
                  <td>{r.completedOn ? new Date(r.completedOn)?.toLocaleString(): 'N/A'}</td>
                  <td>{r.downloadedOn ? new Date(r.downloadedOn)?.toLocaleString() : 'N/A'}</td>
                  <td>{r.allowEmail ? 'Yes' : 'No'}</td>
                  <td className="right-cell">
                    {r.allowEmail && r.completedOn == null && (new Date().getTime() - (30 * 60 * 1000)) < convertUTCDateToLocalDate(new Date(r.lastReminded))?.getTime() &&
                    <Tooltip
                      title={'A reminder for this task has been sent to this user less than thirty minutes ago. Try again soon.'}>
                      <button 
                        type='button'
                        disabled
                        className='action margin-right'
                      >Send Reminder</button>
                    </Tooltip>
                    }
                    {r.allowEmail && r.completedOn == null && (new Date().getTime() - (30 * 60 * 1000)) > convertUTCDateToLocalDate(new Date(r.lastReminded))?.getTime() &&
                    <button 
                      type='button'
                      onClick={() => sendReminder(r.userId)}
                      className='action margin-right'
                    >Send Reminder</button>
                    }
                  </td>
                </tr>,
              )}
            </tbody>
          </table>
        </div>
        }
      </div>
    </div>
  );
}

export default AssignedMatrix;
