export default {
  draft: 1,
  toBeIdentified: 2,
  identified: 3,
  toBeRegistered: 4,
  registered: 5,
  approval: 6,
  approved: 7,
  open: 8,
  rejected: 9,
  expired: 10,
  aborted: 11,
  closed: 12,
};