import {
  LSPOS_EMAILCONFIG_REQUEST,
  LSPOS_EMAILCONFIG_SUCCESS,
  LSPOS_EMAILCONFIG_FAILURE,
  LSPOS_EMAILCONFIG_SAVE_REQUEST,
  LSPOS_EMAILCONFIG_SAVE_SUCCESS,
  LSPOS_EMAILCONFIG_SAVE_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const PosItemEmailConfigReducer = (
  state = initialState.posItemEmailConfigState,
  action = {},
) => {
  switch (action.type) {
  case  LSPOS_EMAILCONFIG_REQUEST: {
    return {
      isFetching: true,
      isLoaded: false,
      isSaving: false,
      isSaved: false,
      distributionLists: [],
      itemTypes: [],
      errorMessage: '',
    };
  }
  case LSPOS_EMAILCONFIG_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      distributionLists: action.payload.distributionLists,
      itemTypes: action.payload.itemTypes,
    };
  }
  case  LSPOS_EMAILCONFIG_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      distributionLists: [],
      itemTypes: [],
      errorMessage: action.payload.message,
    };
  }
  case LSPOS_EMAILCONFIG_SAVE_REQUEST: {
    return {
      isSaving: true,
      isSaved: false,
      errorMessage: '',
    };
  }
  case LSPOS_EMAILCONFIG_SAVE_SUCCESS: {
    return {
      isSaving: false,
      isSaved: true,
      errorMessage: '',
    };
  }
  case LSPOS_EMAILCONFIG_SAVE_FAILURE: {
    return {
      isSaving: false,
      isSaved: false,
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};

export default PosItemEmailConfigReducer;
