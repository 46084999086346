import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../actions/navigationActions';
import { marketingActions } from '../../actions/marketingActions';

class ContactUsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      jobTitle: '',
      storeGroup: '',

      dataPreLoaded: false,
      showValidation: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
  }

  handleSubmit(e) {
    const {
      firstName,
      lastName,
      email,
      mobileNumber,
      jobTitle,
      storeGroup,
    } = this.state;

    if (
      !firstName ||
      !lastName ||
      !email ||
      !mobileNumber ||
      !jobTitle ||
      !storeGroup
    ) {
      this.setState({ showValidation: true });
    } else {
      this.props.submitOrderlyRequest({
        firstName,
        lastName,
        email,
        mobileNumber,
        jobTitle,
        storeGroup,
      });
    }
    e.preventDefault();
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const { username, givenName, familyName } = this.props;

    if (username || givenName || familyName) {
      this.setState({
        dataPreLoaded: true,
        email: username,
        firstName: givenName,
        lastName: familyName,
      });
    }
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  render() {
    const {
      dataPreLoaded,
      firstName,
      lastName,
      email,
      mobileNumber,
      jobTitle,
      storeGroup,
      showValidation,
    } = this.state;
    const { isSaved, isSaving } = this.props;
    return (
      <div className="contact-us-page page">
        <div className="orderly-menu">
          <img
            className="orderly-logo"
            src="/assets/orderly/logo-white.png"
            srcSet="/assets/orderly/logo-white.png 1x, /assets/orderly/logo-white@2x.png 2x"
            alt="orderly logo"
          />
          <div className="button-bar">
            <a href="/whatisorderly#root"
              onClick={() => this.props.close()}>
              Back
            </a>
          </div>
        </div>
        {!isSaved ? (
          <div className="floating-modal">
            <h3>Book a Consultation</h3>
            {dataPreLoaded && (
              <p>
                To save your time we've pre-filled some of the form using the
                details from Orderly's MyStarbucks portal.
              </p>
            )}
            <form noValidate
              onSubmit={e => this.handleSubmit(e)}>
              <div className="form-body">
                <label>
                  First Name
                  <input
                    required
                    className={showValidation && !firstName ? 'invalid' : ''}
                    type="text"
                    onChange={e => this.handleTextInputUpdate(e, 'firstName')}
                    value={firstName}
                  />
                </label>
                <label>
                  Last Name
                  <input
                    required
                    className={showValidation && !lastName ? 'invalid' : ''}
                    type="text"
                    value={lastName}
                    onChange={e => this.handleTextInputUpdate(e, 'lastName')}
                  />
                </label>
                <label>
                  Email
                  <input
                    required
                    className={showValidation && !email ? 'invalid' : ''}
                    type="email"
                    value={email}
                    onChange={e => this.handleTextInputUpdate(e, 'email')}
                  />
                </label>
                <label>
                  Mobile Number
                  <input
                    required
                    className={showValidation && !mobileNumber ? 'invalid' : ''}
                    type="telephone"
                    value={mobileNumber}
                    onChange={e =>
                      this.handleTextInputUpdate(e, 'mobileNumber')
                    }
                  />
                </label>
                <label>
                  Job Title
                  <input
                    required
                    className={showValidation && !jobTitle ? 'invalid' : ''}
                    type="text"
                    value={jobTitle}
                    onChange={e => this.handleTextInputUpdate(e, 'jobTitle')}
                  />
                </label>
                <label>
                  Store Group
                  <input
                    required
                    className={showValidation && !storeGroup ? 'invalid' : ''}
                    type="text"
                    value={storeGroup}
                    onChange={e => this.handleTextInputUpdate(e, 'storeGroup')}
                  />
                </label>
              </div>
              <div className="form-buttons">
                <button className="float"
                  disabled={isSaving}>
                  {isSaving
                    ? 'Submitting...'
                    : isSaved
                      ? 'Submitted'
                      : 'Submit'}
                </button>
                <button
                  type="button"
                  className="float float--light"
                  onClick={() => this.props.close()}>
                  Back
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="floating-modal">
            <h3>Thanks</h3>
            <p>
              A member of the Orderly Team will be in touch within the next
              working day.
            </p>

            <div className="icon icon-success"></div>
            <div className="form-buttons-2">
              <button className="float"
                onClick={() => this.props.push('/')}>
                Return to Home
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  username: state.auth.username,
  givenName: state.auth.givenName,
  familyName: state.auth.familyName,
  isSaving: state.marketing.isSaving,
  isSaved: state.marketing.isSaved,
  errorMessage: state.marketing.errorMessage,
});

const mapDispatchToProps = dispatch => {
  return {
    submitOrderlyRequest: details =>
      dispatch(marketingActions.submitOrderlyRequest(details)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactUsModal);
