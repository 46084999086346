import {
  TOGGLESIDEBAR_REQUEST,
} from './actionTypes';

export const sideBarActions = {
  toggleSideBar: open => (dispatch, getState) => {
    dispatch({
      type: TOGGLESIDEBAR_REQUEST,
      payload: { open },
    });
  },
};
