import {
  LSPOS_ITEMNUMBERADMIN_REQUEST,
  LSPOS_ITEMNUMBERADMIN_SUCCESS,
  LSPOS_ITEMNUMBERADMIN_FAILURE,
  LSPOS_ITEMNUMBERADMIN_DETAIL_REQUEST,
  LSPOS_ITEMNUMBERADMIN_DETAIL_SUCCESS,
  LSPOS_ITEMNUMBERADMIN_DETAIL_FAILURE,
  LSPOS_ITEMNUMBERADMIN_SAVE_REQUEST,
  LSPOS_ITEMNUMBERADMIN_SAVE_SUCCESS,
  LSPOS_ITEMNUMBERADMIN_SAVE_FAILURE,
} from './actionTypes';

export const posItemNumberRequestActionsAdmin = {
  requestItems: (
    page, pageSize, filter, 
  ) => (dispatch) => {
    dispatch({
      type:  LSPOS_ITEMNUMBERADMIN_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `api/LsPos/ItemNumber/v1/adminlist?filter=${filter}&page=${page}&pageSize=${pageSize}`,
        successAction: LSPOS_ITEMNUMBERADMIN_SUCCESS,
        failureAction:  LSPOS_ITEMNUMBERADMIN_FAILURE,
      },
    });
  },
  requestItemDetail: (itemId) => (dispatch) => {
    dispatch({
      type: LSPOS_ITEMNUMBERADMIN_DETAIL_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/lspos/v1/itemnumber/detail/${itemId}`,
        successAction: LSPOS_ITEMNUMBERADMIN_DETAIL_SUCCESS,
        failureAction: LSPOS_ITEMNUMBERADMIN_DETAIL_FAILURE,
      },
    });
  },
  submitForm: submitForm => (dispatch) => {
    dispatch({
      type: LSPOS_ITEMNUMBERADMIN_SAVE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/lspos/v1/itemnumber',
        successAction: LSPOS_ITEMNUMBERADMIN_SAVE_SUCCESS,
        failureAction: LSPOS_ITEMNUMBERADMIN_SAVE_FAILURE,
        body: {
          ...submitForm,
        },
      },
    });
  }  };
