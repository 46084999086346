import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import AssetCategory from './shared/AssetCategory';
import Asset from './shared/Asset';
import AssetCategorySaveModal from './shared/AssetCategorySaveModal';
import AssetSaveModal from './shared/AssetSaveModal';
import AssetMoveModal from './shared/AssetMoveModal';
import { assetActions } from '../../../actions/connectAssetActions';
import { assetManagementActions } from '../../../actions/connectAssetManagementActions';
import ConfirmationModal from '../../shared/ConfirmationModal';

class DigitalAssetManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetCategoryId: undefined,
      assetCategoryName: undefined,

      selectedAsset: undefined,

      showAssetCategorySaveModal: false,
      showAssetCategoryUpdateModal: false,
      showAssetCategoryDeleteModal: false,
      showAssetSaveModal: false,
      showAssetUpdateModal: false,
      showAssetDeleteModal: false,
      showAssetMoveModal: false,
    };

    this.handleModalToggle = this.handleModalToggle.bind(this);
    this.handleModalAction = this.handleModalAction.bind(this);
    this.handleAssetCategoryDrillDown = this.handleAssetCategoryDrillDown.bind(this);
    
    this.saveAssetCategory = this.saveAssetCategory.bind(this);
    this.deleteAssetCategory = this.deleteAssetCategory.bind(this);
    
    this.saveAsset = this.saveAsset.bind(this);
    this.moveAsset = this.moveAsset.bind(this);
    this.deleteAsset = this.deleteAsset.bind(this);

    this.handleAssetDrillDown = this.handleAssetDrillDown.bind(this);
  }

  componentDidMount() {
    this.props.requestAssetCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isLoadedAssets,
      isLoadedCategory,
      isSaved, 
      isDeleted, 
      
      requestAssetCategories,
      requestAssets,

      assetCategoryList,
      assetList,
    } = this.props;

    const {
      assetCategoryId,
      selectedAsset,
    } = this.state;

    if (
      (isSaved && !prevProps.isSaved) ||
      (isDeleted && !prevProps.isDeleted)
    ) {
      requestAssetCategories();

      if(assetCategoryId){
        requestAssets(assetCategoryId);
      }
    }

    const newState = {};
    let update = false;

    if(isLoadedCategory && !prevProps.isLoadedCategory){

      //Update category Title
      if(assetCategoryId && assetCategoryList){
        let currentCategory = assetCategoryList.find(a => a.assetCategoryId.toString() === assetCategoryId.toString());

        if(currentCategory){
          newState.assetCategoryName = currentCategory.name;
          update = true;
        }
      }
    }
    
    if(isLoadedAssets && !prevProps.isLoadedAssets){

      //Update asset details
      if(selectedAsset && assetList){
        let currentAsset = assetList.find(a => a.assetId.toString() === selectedAsset.assetId.toString());

        if(currentAsset){
          newState.selectedAsset = currentAsset;
          update = true;
        }
      }
    }

    if(update){
      this.setState({ ...this.state, ...newState });
    }
  }

  handleAssetCategoryDrillDown(assetCategoryId, assetCategoryName) {
    this.setState({
      assetCategoryId,
      assetCategoryName,
      selectedAsset:undefined,
    });

    this.props.requestAssets(assetCategoryId);
  }

  handleAssetDrillDown(selectedAsset) {
    this.setState({
      selectedAsset,
    });
  }

  handleModalToggle(modalFlag, show) {
    this.setState({ [modalFlag]: show });
  }

  handleModalAction(modalFlag, entity) {
    this.setState({ [modalFlag]: true, selectedEntity: entity });
  }

  deleteAsset() {
    const { assetCategoryId, selectedAsset } = this.state;
    this.props.deleteAsset({
      assetCategoryId,
      AssetReference: selectedAsset.data,
    });

    this.setState({
      showAssetDeleteModal: false,
      selectedAsset: undefined,
    });
  }

  deleteAssetCategory() {
    const { assetCategoryId } = this.state;
    this.props.deleteAssetCategory({
      assetCategoryId: assetCategoryId,
    });

    this.setState({
      showAssetCategoryDeleteModal: false,
      assetCategoryId: undefined,
      selectedAsset: undefined,
    });
  }

  saveAssetCategory(doc) {
    this.props.saveAssetCategory({
      assetCategoryId: doc.id,
      name: doc.name,
    });

    this.setState({
      showAssetCategorySaveModal: false,
      showAssetCategoryUpdateModal: false,
    });
  }

  moveAsset(doc) {
    const { assetCategoryId, selectedEntity } = this.state;
    this.props.moveAsset({
      assetCategoryId: doc.targetAssetCategory,
      previousAssetCategoryId: assetCategoryId,
      assetReference: selectedEntity.data,
    });

    this.setState({
      showAssetMoveModal: false,
    });
  }

  saveAsset(doc) {
    this.props.saveAsset({
      assetCategoryId: doc.id,
      assetReference: doc.reference,

      mediaData: doc.mediaData,
      mediaName: doc.mediaName,
      mediaType: doc.mediaType,

      title: doc.title,
      description: doc.description,
    });

    this.setState({
      showAssetSaveModal: false,
      showAssetUpdateModal: false,
    });
  }

  render() {
    const { assetList, assetCategoryList } = this.props;
    const {
      assetCategoryId,
      assetCategoryName,

      selectedAsset,

      showAssetCategorySaveModal,
      showAssetCategoryUpdateModal,
      showAssetCategoryDeleteModal,

      showAssetSaveModal,
      showAssetUpdateModal,
      showAssetMoveModal,
      showAssetDeleteModal,
    } = this.state;
    const {
      handleModalToggle,
      handleModalAction,

      saveAsset,
      moveAsset,
      deleteAsset,
      saveAssetCategory,
      deleteAssetCategory,

      handleAssetCategoryDrillDown,
      handleAssetDrillDown,
    } = this;
    return (
      <div className="digital-asset-management-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="icon-title">
              <div className="icon icon-document-libary"></div>
              <h2>Connect Digital Asset Library Management</h2>
            </div>
            <div className="header-actions">
            </div>
          </div>
          <div className="documents-management-body">
            <div className="documents-management-breadcrumbs">
              <div className="documents-management-breadcrumb">
                <h3>Categories</h3>
                <div className="button-container">
                  <button
                    type="button"
                    className="edit add-assetcategory"
                    onClick={() => handleModalToggle('showAssetCategorySaveModal', true)}>
                    Add New Asset Category
                  </button>
                </div>
              </div>
            </div>
            <div className="documents-management-holder">
              {assetCategoryList
                .map((assetcategory, index) => {
                  return (
                    <AssetCategory
                      key={assetcategory.assetCategoryId}
                      data={assetcategory}
                      selected={assetcategory.assetCategoryId === assetCategoryId}
                      onView={() =>
                        handleAssetCategoryDrillDown(
                          assetcategory.assetCategoryId,
                          assetcategory.name,
                        )
                      }
                    />
                  );
                })}
            </div>
          </div>
          {assetCategoryId &&
          <div className="documents-management-body">
            <div className="documents-management-breadcrumbs">
              <div className="documents-management-breadcrumb">
                <h3>{assetCategoryName}</h3>
                <div className="button-container">
                  <button
                    type="button"
                    className="edit upload"
                    onClick={() => handleModalToggle('showAssetSaveModal', true)}>
                    Add Asset
                  </button>
                  <button
                    type="button"
                    className="edit category"
                    onClick={() => {
                      handleModalAction('showAssetCategoryUpdateModal', { assetCategoryId,
                        name: assetCategoryName });
                    }}>
                    Edit Category
                  </button>
                  <button
                    type="button"
                    className="edit negative"
                    onClick={() =>
                      handleModalAction('showAssetCategoryDeleteModal', { assetCategoryId,
                        name: assetCategoryName })
                    }>
                    Delete Category
                  </button>
                </div>
              </div>
            </div>
            <div className="documents-management-holder">
              {assetList
                .map((document, index) => {
                  return (
                    <Asset
                      key={document.assetId}
                      selected={selectedAsset && document.assetId === selectedAsset.assetId}
                      data={document}
                      onView={() => handleAssetDrillDown(document)}
                    />
                  );
                },
                )}
            </div>
          </div>
          }
          {selectedAsset &&
          <div className="documents-management-body">
            <div className="documents-management-breadcrumbs">
              <div className="documents-management-breadcrumb">
                <h3>{selectedAsset.title}</h3>
                <div className="button-container">
                  <a
                    type="button"
                    className="edit upload"
                    href={`/api/Connect/Asset/v1/Get?assetCategoryId=${assetCategoryId}&assetReference=${selectedAsset.data}`}>
                    Download Asset
                  </a>
                  <button
                    type="button"
                    className="edit asset"
                    onClick={() => {
                      handleModalAction('showAssetUpdateModal', selectedAsset);
                    }}>
                    Edit Asset
                  </button>
                  <button
                    type="button"
                    className="edit asset"
                    onClick={() => {
                      handleModalAction('showAssetMoveModal', selectedAsset);
                    }}>
                    Move Asset
                  </button>
                  <button
                    type="button"
                    className="edit negative"
                    onClick={() =>
                      handleModalAction('showAssetDeleteModal', selectedAsset)
                    }>
                    Delete Asset
                  </button>
                </div>
              </div>
            </div>
            <div className="documents-management-holder">
              <div className="preview">
                {selectedAsset.type.toLowerCase().indexOf('image') > -1 && 
                  <img className="preview"
                    src={`/api/Connect/Asset/v1/Get?assetCategoryId=${assetCategoryId}&assetReference=${selectedAsset.data}`}
                    alt={selectedAsset.description}/>}

                {selectedAsset.type.toLowerCase().indexOf('video') > -1 &&
                <video  className="preview"
                  controls>
                  <source src={`/api/Connect/Asset/v1/Stream?assetCategoryId=${selectedAsset.assetCategoryId}&assetReference=${selectedAsset.data}`} 
                    type={selectedAsset.type} />
                  Your browser does not support the video tag.
                </video>}
                
               
                {selectedAsset.type.toLowerCase().indexOf('audio') > -1 &&
                <audio  className="preview"
                  controls>
                  <source 
                    src={`/api/Connect/Asset/v1/Stream?assetCategoryId=${selectedAsset.assetCategoryId}&assetReference=${selectedAsset.data}`} 
                    type={selectedAsset.type}/>
                  Your browser does not support the audio element.
                </audio>}
              </div>
              <div className="file-details">
                <label>Filename: <span>{selectedAsset.title}</span></label>
                <label>Type: <span>{selectedAsset.type}</span></label>
                <label>Description: <span>{selectedAsset.description}</span></label>
                <label>Size: <span>{selectedAsset.size/1000}MB</span></label>
              </div>
            </div>
          </div>
          }
        </div>
        {showAssetSaveModal && (
          <AssetSaveModal
            id={assetCategoryId}
            cancel={() => handleModalToggle('showAssetSaveModal', false)}
            continue={saveAsset} />
        )}
        {showAssetUpdateModal && (
          <AssetSaveModal
            id={assetCategoryId}
            reference={selectedAsset && selectedAsset.data}
            title={selectedAsset && selectedAsset.title}
            description={selectedAsset && selectedAsset.description}
            cancel={() => handleModalToggle('showAssetUpdateModal', false)}
            continue={saveAsset} />
        )}
        {showAssetMoveModal && (
          <AssetMoveModal
            cancel={() => handleModalToggle('showAssetMoveModal', false)}
            continue={moveAsset}
            assetcategorys={assetCategoryList.filter(d => d.assetCategoryId !== assetCategoryId)}
          />
        )}
        {showAssetDeleteModal && (
          <ConfirmationModal
            cancel={() => handleModalToggle('showAssetDeleteModal', false)}
            continue={deleteAsset}
            title={'Delete'}
            message={'Deleted assets will be removed from any current or archived content. Live content will not be displayed until the asset is replaced. All deletions are final. Are you sure you want to continue?'}
          />
        )}
        {showAssetCategorySaveModal && (
          <AssetCategorySaveModal
            cancel={() => handleModalToggle('showAssetCategorySaveModal', false)}
            continue={saveAssetCategory}
          />
        )}
        {showAssetCategoryUpdateModal && (
          <AssetCategorySaveModal
            id={assetCategoryId}
            name={assetCategoryName}
            cancel={() => handleModalToggle('showAssetCategoryUpdateModal', false)}
            continue={saveAssetCategory}
          />
        )}
        {showAssetCategoryDeleteModal && (
          <ConfirmationModal
            cancel={() => handleModalToggle('showAssetCategoryDeleteModal', false)}
            continue={deleteAssetCategory}
            title={'Delete'}
            message={'Deleted assets will be removed from any current or archived content. Live content will not be displayed until the asset is replaced. All deletions are final. Are you sure you want to continue?'}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isLoadedAssets: state.connectAssetManagement.isLoadedAssets,
  isLoadedCategory: state.connectAssetManagement.isLoadedCategory,
  isSaved: state.connectAssetManagement.isSaved,
  isDeleted: state.connectAssetManagement.isDeleted,
  assetList: state.connectAssetManagement.assetList,
  assetCategoryList: state.connectAssetManagement.assetCategoryList,
});

const mapDispatchToProps = dispatch => {
  return {
    downloadAsset: (assetCategoryId, assetReference) => dispatch(assetActions.requestAsset(assetCategoryId, assetReference)),

    requestAssetCategories: () => dispatch(assetManagementActions.requestAssetCategories()),
    saveAssetCategory: assetcategory => dispatch(assetManagementActions.saveAssetCategory(assetcategory)),
    deleteAssetCategory: assetcategory => dispatch(assetManagementActions.deleteAssetCategory(assetcategory)),

    requestAssets: assetCategoryId => dispatch(assetManagementActions.requestAssets(assetCategoryId)),
    saveAsset: doc => dispatch(assetManagementActions.saveAsset(doc)),
    moveAsset: doc => dispatch(assetManagementActions.moveAsset(doc)),
    deleteAsset: doc => dispatch(assetManagementActions.deleteAsset(doc)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DigitalAssetManagement);
