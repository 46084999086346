import React, { useState } from 'react';

const KpiFailureTable = (props) => {
  const [displayMode, setDisplayMode] = useState(1);
  const {
    failureTableData,
    loading,
    noFilters,
  } = props;

  return (
    <div className="failed-items-table">
      <div className="item-catalogue-container table-container">
        <div className="table-header">
          <div className="display-options">
                        Table Size
            <button
              type="button"
              className="icon-display-mode-1"
              disabled={displayMode === 1}
              onClick={() => setDisplayMode(1)}></button>
            <button
              type="button"
              className="icon-display-mode-2"
              disabled={displayMode === 2}
              onClick={() => setDisplayMode(2)}></button>
            <button
              type="button"
              className="icon-display-mode-3"
              disabled={displayMode === 3}
              onClick={() => setDisplayMode(3)}></button>
          </div>
        </div>
        <div className="table-body">
          <table className={`table-display-mode-${displayMode}`}>
            <thead>
              <tr>
                <th>Item Number</th>
                <th>Description</th>
                <th className="text-center">Failed Lines</th>
                <th>Failure Reason</th>
              </tr>
            </thead>
            <tbody>
              {failureTableData
                                && failureTableData.map((failedItem, index) =>
                                  <tr key={index}>
                                    <td>{failedItem.itemNumber}</td>
                                    <td>{failedItem.description}</td>
                                    <td className="text-center">{failedItem.failedLines}</td>
                                    <td>{failedItem.failureReason}</td>
                                  </tr>,
                                )}
              {(!failureTableData
                                || failureTableData.length <= 0) && !loading && !noFilters &&
                                <tr>
                                  <td colSpan={4}
                                    className="text-center">No failures found.</td>
                                </tr>
              }
              {noFilters &&
                                <tr>
                                  <td colSpan={4}
                                    className="text-center">Please use the provided filters and charts to drill down into the data and view the related failed items.</td>
                                </tr>
              }

              {loading &&
                                <tr>
                                  <td colSpan={4}
                                    className="text-center">
                                    <span className="spinner"
                                      style={{ display:'inline-block' }} />
                                  </td>
                                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default KpiFailureTable;
