import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as V from 'victory';
import './Dashboard.scss';
import orderTrackerActions from '../../../actions/orderTrackerActions';
import PeriodFilter from '../components/PeriodFilter/PeriodFilter';
import StatusDropdown from '../components/StatusDropdown/StatusDropdown';
import { sanitiseNumberInput } from '../utils';

const Dashboard = ({ getDashboards, isLoading, isLoaded, error, data, getCustomers, customers, archive }) => {
  const [timeFilter, setTimeFilter] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [orderProgress, setOrderProgress] = useState('');
  const [customer, setCustomer] = useState('');
  const [daysDelayed, setDaysDelayed] = useState('');

  useEffect(getCustomers, [getCustomers]);

  useEffect(
    () => {
      const params = {};

      if (timeFilter) {
        params.month = timeFilter.split('-')[0];
        params.year = timeFilter.split('-')[1];
      }

      if (orderStatus) {
        params.status = orderStatus;
      }

      if (orderProgress) {
        params.progress = orderProgress;
      }

      params.completed = archive;

      if (customer) {
        params.customerNumber = customer;
      }

      if (daysDelayed) {
        params.delay = daysDelayed;
      }

      getDashboards(params);
    },
    [getDashboards, timeFilter, orderStatus, orderProgress, archive, customer, daysDelayed],
  );

  return (
    <div className="ordertracker-dashboard-page page dashboard">
      <div className="floating-panel">
        <div className="floating-header">
          <div className="header-title">
            <div className={'icon icon-speedo'} />
            <div>
              <h2>Dashboard</h2>
              <p>
                Review and analyse data about orders,
                how they are being processed and what status they are in.
              </p>
            </div>
          </div>
          <div className="group-details accordion">
            <div className="accordion-body vertical-controls">
              <div>
                <PeriodFilter
                  value={timeFilter}
                  onChange={e => setTimeFilter(e.target.value)}
                  disabled={isLoading}
                />
              </div>
              <div>
                <StatusDropdown
                  value={orderStatus}
                  onChange={(e) => setOrderStatus(e.target.value)}
                  disabled={isLoading}
                />
              </div>
              <div>
                <label>
                  Progress
                  <select
                    value={orderProgress}
                    onChange={(e) => setOrderProgress(e.target.value)}
                    disabled={isLoading}
                  >
                    <option value="">All</option>
                    <option value="0">In Progress</option>
                    <option value="1">CI Sent</option>
                    <option value="2">HC Requested (4SA)</option>
                    <option value="3">Legalization</option>
                    <option value="4">Pickup Request Sent (6FF)</option>
                    <option value="5">Pickup Planned</option>
                    <option value="6">Dispatched</option>
                  </select>
                </label>
              </div>
              <div>
                <label>
                  Customer
                  <select
                    value={customer}
                    onChange={(e) => setCustomer(e.target.value)}
                    disabled={isLoading}
                  >
                    <option value="">All</option>
                    {customers && customers.map(x =>
                      <option key={x.number}
                        value={x.number}>{x.displayName}</option>,
                    )}
                  </select>
                </label>
              </div>
              <div>
                <label>
                  Days Delayed
                  <input
                    type="text"
                    pattern="^\d*$"
                    placeholder="Days delayed"
                    className="input-delayed"
                    onChange={e => setDaysDelayed(e.target.value)}
                    onKeyPress={sanitiseNumberInput}
                    value={daysDelayed}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="stat-container">
        {data && data.ordersByStatus &&
          <div className="stat-group">
            <div className="stat-header">
              <h3>Total Orders by Status</h3>
            </div>
            <div className="stat-body">
              <svg width={500}
                height={500}>
                <svg viewBox="-40 -50 400 400"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="xMaxYMin meet"> 
                  <V.VictoryPie
                    standalone={false}
                    labels={() => null}
                    width={450}
                    theme={V.VictoryTheme.material}
                    data={data.ordersByStatus
                      .map(d => {
                        return { x: d.label, y: d.count };
                      })}
                  />
                </svg>
                <V.VictoryLegend
                  x={35}
                  standalone={false}
                  theme={V.VictoryTheme.material}
                  data={data.ordersByStatus.map(d => ({ name: `${d.label}: ${d.count}` }))}
                />
              </svg>
            </div>
          </div>
        }
        {data && data.ordersByProgress &&
          <div className="stat-group">
            <div className="stat-header">
              <h3>Total Orders by Progress</h3>
            </div>
            <div className="stat-body">
              <svg width={500}
                height={500}>
                <svg viewBox="-40 -50 400 400"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="xMaxYMin meet"> 
                  <V.VictoryPie
                    standalone={false}
                    labels={() => null}
                    width={450}
                    theme={V.VictoryTheme.material}
                    data={data.ordersByProgress
                      .map(d => {
                        return { x: d.label, y: d.count };
                      })}
                  />
                </svg>
                <V.VictoryLegend
                  x={35}
                  standalone={false}
                  theme={V.VictoryTheme.material}
                  data={data.ordersByProgress.map(d => ({ name: `${d.label}: ${d.count}` }))}
                />
              </svg>
            </div>
          </div>
        }
        {data && data.ordersBySource &&
          <div className="stat-group">
            <div className="stat-header">
              <h3>Total Orders by Source</h3>
            </div>
            <div className="stat-body">
              <svg width={500}
                height={500}>
                <svg viewBox="-40 -50 400 400"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="xMaxYMin meet"> 
                  <V.VictoryPie
                    standalone={false}
                    labels={() => null}
                    width={450}
                    theme={V.VictoryTheme.material}
                    data={data.ordersBySource
                      .map(d => {
                        return { x: d.label, y: d.count };
                      })}
                  />
                </svg>
                <V.VictoryLegend
                  x={35}
                  standalone={false}
                  theme={V.VictoryTheme.material}
                  data={data.ordersBySource.map(d => ({ name: `${d.label}: ${d.count}` }))}
                />
              </svg>
            </div>
          </div>
        }
        {data && data.orderCountByMonth && data.orderCountByMonth.length > 1 &&
          <div className="stat-group">
            <div className="stat-header">
              <h3>Total Orders by Month</h3>
            </div>
            <div className="stat-body">
              <V.VictoryChart
                theme={V.VictoryTheme.material}
                scale={{ x: 'time', y: 'linear' }}
              >
                <V.VictoryLine
                  data={data.orderCountByMonth
                    .map(d => {
                      return { x: new Date(d.year, d.month, 1), y: d.count };
                    })}
                  sortKey={['year', 'month']}
                />
                <V.VictoryAxis dependentAxis />
                <V.VictoryAxis fixLabelOverlap />
              </V.VictoryChart>
            </div>
          </div>
        }
        {data && data.cancelledOrderCountByMonth && data.cancelledOrderCountByMonth.length > 1 &&
          <div className="stat-group">
            <div className="stat-header">
              <h3>Total Cancelled Orders by Month</h3>
            </div>
            <div className="stat-body">
              <V.VictoryChart
                theme={V.VictoryTheme.material}
                scale={{ x: 'time', y: 'linear' }}
              >
                <V.VictoryLine
                  data={data.cancelledOrderCountByMonth
                    .map(d => {
                      return { x: new Date(d.year, d.month, 1), y: d.count };
                    })}
                  sortKey={['year', 'month']}
                />
                <V.VictoryAxis dependentAxis />
                <V.VictoryAxis fixLabelOverlap />
              </V.VictoryChart>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.orderTracker.isLoading,
    isLoaded: state.orderTracker.isLoaded,
    error: state.orderTracker.error,
    data: state.orderTracker.dashboard,
    customers: state.orderTracker.dashboardCustomers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboards: (payload) => dispatch(orderTrackerActions.getDashboard(payload)),
    getCustomers: () => dispatch(orderTrackerActions.getDashboardCustomers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
