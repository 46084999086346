import React from 'react';
import { connect } from 'react-redux';
import { RslPerformanceActions } from '../../../actions/rslPerformanceActions';

class RslPerformance extends React.Component {
  constructor(props) {
    super(props);
    var date = new Date();

    this.state = {
      currentMonth: new Date(date.getFullYear(), date.getMonth(), 1),
      page: 1,
    };

    this.doPaging = this.doPaging.bind(this);
  }

  componentDidMount() {
    const { page } = this.state;
    this.props.getRslPerformance(this.state.currentMonth.toISOString(), page);
  }

  handleMonthChange(e) {
    const { page } = this.state;
    this.setState({ currentMonth: new Date(e.target.value) });
    this.props.getRslPerformance(new Date(e.target.value).toISOString(), page);
  }

  handleGetMonths() {
    const dates = [];
    const date = new Date();
    let year = date.getFullYear();
    let currentMonth = date.getMonth();
    let counter = 12;

    while (counter > 0) {
      dates.push(new Date(year, currentMonth, 1));

      if (currentMonth === 1) {
        currentMonth = 12;
        year = year - 1;
      } else {
        currentMonth = currentMonth - 1;
      }

      if (currentMonth < 8 && year === 2019) {
        counter = -1;
      } else {
        counter--;
      }
    }

    return dates.map(date => (
      <option key={date}
        value={date}>
        {date.toLocaleString('default', { month: 'short' }) +
          ' ' +
          date.getFullYear()}
      </option>
    ));
  }

  doPaging(page) {
    const { currentMonth } = this.state;
    this.setState({ page });

    this.props.getRslPerformance(new Date(currentMonth).toISOString(), page);
  }

  render() {
    const { rslPerformanceList, page, pageSize, rowCount } = this.props;
    const { currentMonth } = this.state;
    const { doPaging } = this;
    return (
      <div className="rslperformance-page page">
        <div className="floating-panel">
          <div className="rslperformance-header">
            <div className="icon-stack">
              <div className="icon icon-table"></div>
              <h2>RSL Performance</h2>
            </div>
            <div className="header-buttons">
              <select
                autoFocus
                value={currentMonth}
                onChange={e => this.handleMonthChange(e)}>
                {this.handleGetMonths()}
              </select>
              <div className="pagination-controls">
                {rowCount === 0 && '0-0 of 0'}
                {rowCount > 0 &&
                  `${page * pageSize - pageSize + 1}-${
                    page * pageSize < rowCount ? page * pageSize : rowCount
                  } of ${rowCount}`}
                <button
                  type="button"
                  disabled={page === 1}
                  onClick={() => doPaging(page - 1)}>
                  {'<'}
                </button>
                <button
                  type="button"
                  disabled={page >= rowCount / pageSize}
                  onClick={() => doPaging(page + 1)}>
                  {'>'}
                </button>
              </div>
            </div>
          </div>
          <p>
            Starbucks takes no responsibility for the consequences of error or
            for any loss or damage suffered by users of any of the information
            published in this module. The information provided in this module is
            for information purposes only. The information is not to be
            interpreted or construed to create any warranties of any kind,
            either express or implied, regarding the information contained
            herein. The information does not represent the liabilities of
            Starbucks or Kuehne+Nagel regarding minimum shelf-life for inventory
            shipped.
          </p>
        </div>
        <div className="rslperformance-container">
          {rslPerformanceList &&
            rslPerformanceList.map(rslPerformance => (
              <div className="row floating-panel">
                <div className="rsl-header">
                  <div>
                    <div className="label-value-pair">
                      <label>Customer Number:</label>
                      <span>{rslPerformance.customerNumber}</span>
                    </div>
                    <div className="label-value-pair">
                      <label>Delivery ID:</label>
                      <span>{rslPerformance.deliveryId}</span>
                    </div>
                    <div className="label-value-pair">
                      <label>Order Number:</label>
                      <span>{rslPerformance.orderNumber}</span>
                    </div>
                  </div>
                  <div>
                    <div className="label-value-pair">
                      <label>Ship to Company:</label>
                      <span>{rslPerformance.shipToCompanyName}</span>
                    </div>
                    <div className="label-value-pair">
                      <label>Org Code:</label>
                      <span>{rslPerformance.orgCode}</span>
                    </div>
                  </div>
                </div>
                <div className="rsl-table-container">
                  <table className="table-display-mode-2">
                    <thead>
                      <tr>
                        <th>Item Number</th>
                        <th>Lot Number</th>
                        <th>Item Description</th>
                        <th>Pick/Ship Date</th>
                        <th>Expiry Date</th>
                        <th>Agreed RSL Days</th>
                        <th>Actual RSL Days</th>
                        <th>Performance Days</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rslPerformance.items.map(item => (
                        <tr>
                          <td>{item.itemNumber}</td>
                          <td>{item.lotNumber}</td>
                          <td>{item.itemDescription}</td>
                          <td>
                            {new Date(item.pickShipDate).toLocaleDateString()}
                          </td>
                          <td>
                            {new Date(item.expiryDate).toLocaleDateString()}
                          </td>
                          <td>{item.agreedRsldays}</td>
                          <td>{item.actualRsldays}</td>
                          <td>
                            <span
                              className={` ${
                                item.performanceDays < 0 ? 'bad' : 'good'
                              }`}>
                              {item.performanceDays}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.rslPerformance.isFetching,
  loaded: state.rslPerformance.isLoaded,
  rslPerformanceList: state.rslPerformance.rslPerformanceList,
  page: state.rslPerformance.pageNumber,
  pageSize: state.rslPerformance.pageSize,
  rowCount: state.rslPerformance.totalRecords,
});

const mapDispatchToProps = dispatch => {
  return {
    getRslPerformance: (month, page) =>
      dispatch(RslPerformanceActions.requestRslPerformance(month, page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RslPerformance);
