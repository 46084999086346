import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Pagination from '../shared/Pagination';
import { navigationActions } from '../../actions/navigationActions';
import BulkStatusChangeModal from './BulkStatusChangeModal';

function FcrManagement(props){
  const [displayMode, setDisplayMode] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [rowCount, setRowCount] = useState(0);
  const [fcrData, setFcrData] = useState({});
  const [filters, setFilters] = useState({});
  const [iccGroups, setIccGroups] = useState(undefined);
  const [statuses, setStatuses] = useState(undefined);
  const [demandClasses, setDemandClasses] = useState(undefined);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showBulkEditModal, setShowBulkEditModal] = useState(false);
  const [defaultUserIccGroup, setDefaultUserIccGroup] = useState(null);

  useEffect(() => {
    async function changePage() {
      await handleFilterWithParameters({ ...filters, page, pageSize });
    }
    changePage();
  }, [page]);

  
  const fetchData = async(params) => {   
    const convertToQuerystring = (obj) => {
      const qs = Object.keys(obj)
        .filter(key => obj[key] !== undefined)
        .map(key => 
          `${key}=${obj[key]}`)
        .join('&');
    
      return qs.length === 0
        ? qs
        : `?${qs}`;
    }; 
    const response = await fetch(`/api/fcr/v1/FetchManagementData${convertToQuerystring(params, page, pageSize)}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    if (response.status == 200){
      const data = await response.json();

      setFcrData(data.fcrData.data);
      setIccGroups(data.iccGroups);
      setStatuses(data.statuses);
      setDemandClasses(data.demandClasses);
      setPageSize(data.fcrData.pageSize);
      setPage(data.fcrData.pageNumber);
      setRowCount(data.fcrData.totalRecords);
      setDefaultUserIccGroup(data.defaultUserIccGroup);
      if (data.defaultUserIccGroup != null && filters.iccGroup == null){
        setFilters({ ...filters, iccGroup : data.defaultUserIccGroup });
      }
    }
  };

  const handleFilterWithParameters = async(params) => {
    const pathname = window.location.pathname;
    
    const filterString = `iccGroup=${filters.iccGroup}&customerNo=${filters.customerNo}&demandClass=${filters.demandClass}`
      + `&status=${filters.status}&itemNo=${filters.itemNo}&itemName=${filters.itemName}`;
      
    props.replaceNavigation(`${pathname}?page=${params.page}&${filterString}`);
    
    await fetchData(params);
  };
 
  const handleFilter = async() => {
    const page = 1;
    setPage(page);

    await handleFilterWithParameters({ ...filters, page, pageSize });
  };

  const handleFilterClear = async() => {
    const newFilters = {
      iccGroup: '',
      customerNo: '',
      demandClass: '',
      status: '',
      itemNo: '',
      itemName: '',
    };
    const page = 1;
    setPage(1);
    setFilters(newFilters);

    await handleFilterWithParameters({ newFilters, page, pageSize });
  };

  const doPaging = async(newPage) => {
    setPage(newPage);
  };

  const handleFilterInputUpdate = (e, fieldName) => {
    setFilters({ ...filters, [fieldName] : e.target.value });
  };

  const handleBulkEditChecked = (e, id) => {
    if (e.target.checked) {
      // Add the value to the list
      setSelectedIds([...selectedIds, id]);
    } else {
      // Remove the value from the list
      setSelectedIds(selectedIds.filter(val => val !== id));
    }
  };

  const completeRequest = () => {
    setShowBulkEditModal(false);
    handleFilter();
  };

  const setDefaultIccGroup = async() => {   
    const response = await fetch(`/api/fcr/v1/SetDefaultUserIccGroup?iccGroupId=${filters.iccGroup}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    if (response.status == 200){
      completeRequest();
    }
  };
  
  return (
    <div className="fcr-management-page page">
      <div className="floating-panel">
        <div className="floating-header"
          style={{ marginBottom: '0' }}>
          <div className="header-title">
            <div className="icon icon-table"></div>
            <h2>
            FCR Management
            </h2>
          </div>
        </div>
      </div>
      <div className='floating-panel form-container'>
        <div> 
          <div className='form-body'>
            <label>
                   ICC Group
              <select
                value={filters.iccGroup}
                onChange={e => handleFilterInputUpdate(e, 'iccGroup')}
              >
                <option value='0'>Select an ICC Group</option>
                {iccGroups && iccGroups.length && iccGroups.map(o => 
                  <option key={o.key}
                    value={o.key}>{o.value}</option>,
                )
                }
              </select>
            </label>
            <label>
                   Status
              <select
                value={filters.status}
                onChange={e => handleFilterInputUpdate(e, 'status')}
              >
                <option value=''>Select a Status</option>
                {statuses && statuses.length && statuses.map(o => 
                  <option key={o.key}
                    value={o.key}>{o.value}</option>,
                )
                }
              </select>
            </label>
            <label>
                   Item Name
              <input
                type="text"
                placeholder="Item Name"
                onChange={e => handleFilterInputUpdate(e, 'itemName')}
                value={filters.itemName}
              />
            </label>
            <label>
                  SKU
              <input
                type="text"
                placeholder="SKU"
                onChange={e => handleFilterInputUpdate(e, 'itemNo')}
                value={filters.itemNo}
              />
            </label>
            <label>
                  Demand Class
              <select
                value={filters.demandClass}
                onChange={e => handleFilterInputUpdate(e, 'demandClass')}
              >
                <option value=''>Select a Demand Class</option>
                {demandClasses && demandClasses.length && demandClasses.map(o => 
                  <option key={o.key}
                    value={o.key}>{o.value}</option>,
                )
                }
              </select>
            </label>
            <label>
                  Customer Number
              <input
                type="text"
                placeholder="Customer Number"
                onChange={e => handleFilterInputUpdate(e, 'customerNo')}
                value={filters.customerNo}
              />
            </label>
            <div style={{ paddingBottom:'18px', display:'flex', justifyContent:'flex-end' }}
            >
              <button 
                type='button'
                onClick={() => setDefaultIccGroup()}
                disabled={filters.iccGroup == null || filters.iccGroup == 0 || defaultUserIccGroup?.toString() == filters.iccGroup?.toString()}
                className='action'>
                    Default ICC Group
              </button>
            </div>
            <div
              style={{ paddingBottom:'18px', display:'flex', justifyContent:'flex-end' }}
            >
              <button onClick={() => handleFilter()}
                type='button'
                style={{ marginRight:'18px' }}
                className='action'>
                    Filter
              </button>
              <button onClick={() => handleFilterClear()}
                type='button'
                className='action negative'>
                    Clear Filter
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="fcr-management-container table-container">
        <div className="table-header">
          <div className='bulk-edit-pagination-container'>
            <div className="display-options">
              Table Size
              <button
                type="button"
                className="icon-display-mode-1"
                disabled={displayMode === 1}
                onClick={() => setDisplayMode(1)}></button>
              <button
                type="button"
                className="icon-display-mode-2"
                disabled={displayMode === 2}
                onClick={() => setDisplayMode(2)}></button>
              <button
                type="button"
                className="icon-display-mode-3"
                disabled={displayMode === 3}
                onClick={() => setDisplayMode(3)}></button>
            </div>
            {selectedIds && selectedIds.length > 0 && <button
              type='button'
              style={{ marginRight:'18px' }}
              onClick={() => setShowBulkEditModal(true)}
              className='action'>
                    Bulk Edit
            </button>
            }
          </div>
          <h2>
           FCR Management
          </h2>
          <div className="pagination-controls">
            <div className='bulk-edit-pagination-container'>
              <Pagination
                rowCount={rowCount}
                page={page}
                pageSize={pageSize}
                doPaging={doPaging}
              />
            </div>
          </div>
        </div>
        <div className="table-body">
          <table className={`table-display-mode-${displayMode}`}>
            <thead>
              <tr>
                <th>Bulk Edit</th>
                <th>ICC Group</th>
                <th>Customer No</th>
                <th>SKU</th>
                <th>Item Name</th>
                <th>Date (w/c)</th>
                <th>Original Quantity</th>
                <th>Proposed Quantity</th>
                <th>Reason</th>
                <th>Customer Comments</th>
                <th>Status</th>            
                <th />
              </tr>
            </thead>
            <tbody>
              {fcrData && fcrData.length === 0 &&
                <tr>
                  <td
                    colSpan={12}
                    className="no-records"
                    style={{ textAlign: 'center' } }
                  >
                    {'No orders found.'}
                  </td>
                </tr>
              }
              {fcrData && fcrData.length > 0 && fcrData.map(f => (
                <tr
                  key={f.id}>
                  <td>{f.statusName.toLowerCase() == 'pending' ? <input className='checkbox'
                    type='checkbox' 
                    checked={selectedIds.indexOf(f.id) > -1}
                    onClick={(e) => handleBulkEditChecked(e, f.id)}/> : 'N/A'}</td>
                  <td>{f.iccGroupName}</td>
                  <td>{f.customerNo}</td>
                  <td>{f.sku}</td>
                  <td>{f.itemName}</td>
                  <td>{new Date(f.date).toLocaleDateString()}</td>
                  <td>{f.originalQuantity}</td>
                  <td>{f.proposedQuantity}</td>
                  <td>{f.reason}</td>
                  <td>{f.customerComments}</td>
                  <td><span className={`${f.statusName.toLowerCase() == 'pending' ? 'status-pending' : f.statusName.toLowerCase() == 'rejected' ? 'status-rejected' : 'status-approved'}`}>{f.statusName}</span></td>
                  <td className="right-cell">
                    <button
                      type="button"
                      className="action"
                      onClick={() => props.pushNavigation(`/module/238/Fcr-Management/${f.id}`)}
                  
                    >
                        View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="table-header">
          <div></div>
          <div className="pagination-controls">
            <Pagination
              rowCount={rowCount}
              page={page}
              pageSize={pageSize}
              doPaging={doPaging}
            />

          </div>
        </div>
      </div>
      {showBulkEditModal && 
        <BulkStatusChangeModal
          selectedIds={selectedIds}
          complete={() => completeRequest()}
          cancel={() => setShowBulkEditModal(false)}
        />
      }
    </div>
  );
}

const mapStateToProps = () => {
  return {  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushNavigation: (url) => dispatch(navigationActions.pushNavigation(url)),
    replaceNavigation: (url) => dispatch(navigationActions.replaceNavigation(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FcrManagement);
