import {
  OVERDUEINVOICES_REQUEST,
  OVERDUEINVOICES_SUCCESS,
  OVERDUEINVOICES_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const overdueInvoiceReducer = (
  state = initialState.overdueInvoiceState,
  action,
) => {
  switch (action.type) {
  case OVERDUEINVOICES_REQUEST: {
    return {
      isFetching: true,
      isLoaded: false,
      overdueInvoiceList: [],
      errorMessage: '',
    };
  }
  case OVERDUEINVOICES_SUCCESS: {
    return {
      isFetching: false,
      isLoaded: true,
      overdueInvoiceList: action.payload.data,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      totalRecords: action.payload.totalRecords,
      errorMessage: '',
    };
  }
  case OVERDUEINVOICES_FAILURE: {
    return {
      ...state,
      isFetching: false,
      overdueInvoiceList: [],
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default overdueInvoiceReducer;
