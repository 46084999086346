import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { navigationActions } from '../../../actions/navigationActions';
import zxcvbn from 'zxcvbn';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordInvalid: false,
      passwordConfirmation: '',
      passwordConfirmationInvalid: false,
      forgottenPasswordToken: '',
      validationMessage: '',
    };

    this.passwordOnChange = this.passwordOnChange.bind(this);
    this.passwordConfirmationOnChange = this.passwordConfirmationOnChange.bind(
      this,
    );

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { token } = this.props.match.params;
    if (token) {
      this.setState({ forgottenPasswordToken: token });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.loaded && !prevProps.loaded) {
      this.setState({ validationMessage: 'User Updated Successfully.' });
    }
  }

  passwordOnChange(event) {
    let { value } = event.target;
    let valid = value !== undefined && value.length >= 8 && value.length < 50;

    this.setState({ password: value, passwordInvalid: !valid });
  }

  passwordConfirmationOnChange(event) {
    let { value } = event.target;
    let valid = value !== undefined && value.length >= 8 && value.length < 50;

    this.setState({
      passwordConfirmation: value,
      passwordConfirmationInvalid: !valid,
    });
  }

  submit(event) {
    let {
      passwordConfirmation,
      password,
      passwordConfirmationInvalid,
      passwordInvalid,
      forgottenPasswordToken,
    } = this.state;
    let { resetPassword } = this.props;
    const passwordEvaluation = zxcvbn(password);

    if (password === '' || passwordInvalid) {
      if (password.length < 8) {
        this.setState({ validationMessage: 'Password must be greater than 8 characters.' });
      }
      this.setState({ passwordInvalid: true });
      event.preventDefault();
      return;
    }
    if (passwordEvaluation.score < 2){
      this.setState({ validationMessage: 'Increase the strength of your password by adding upper case and special characters (e.g. ABC@!)', 
        passwordInvalid: true });
      event.preventDefault();
      return;
    }

    if (
      passwordConfirmation === '' ||
      passwordConfirmationInvalid ||
      password !== passwordConfirmation
    ) {
      this.setState({ passwordConfirmationInvalid: true, 
        validationMessage: 'Passwords must match.' });
      event.preventDefault();
      return;
    }

    resetPassword({
      password,
      forgottenPasswordToken,
    });

    event.preventDefault();
  }
  render() {
    const { validationMessage } = this.state;
    return (
      <form onSubmit={this.submit}
        noValidate>
        <p>Please enter a new password.</p>
        <input
          placeholder="Password"
          type="password"
          minLength="8"
          onChange={e => this.passwordOnChange(e)}
          required
          value={this.state.password}
          className={`float ${this.state.passwordInvalid ? 'invalid' : ''}`}
          autoComplete="new-password"
        />
        <input
          placeholder="Password Confirmation"
          type="password"
          minLength="8"
          onChange={e => this.passwordConfirmationOnChange(e)}
          required
          value={this.state.passwordConfirmation}
          className={`float ${
            this.state.passwordConfirmationInvalid ? 'invalid' : ''
          }`}
          autoComplete="new-password"
        />
        <div className="submission">
          <p>
            {this.props.message}
            {this.props.loaded ? 'Your password has been reset.' : ''}
          </p>
          <button
            type="submit"
            className="float"
            disabled={this.props.isFetching}>
            {this.props.fetching ? 'Working...' : 'Reset'}
          </button>
          <div className='password-validation-message'>
            <p>{validationMessage}</p>
          </div>
          <button
            type="button"
            className="float negative"
            disabled={this.props.isFetching}
            onClick={() => this.props.push('/')}>
            Home
          </button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  fetching: state.auth.isFetching_ActivateAccount,
  loaded: state.auth.isLoaded_ActivateAccount,
  message: state.auth.errorMessage,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    resetPassword: creds => dispatch(authActions.resetPassword(creds)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPassword);
