import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../../actions/navigationActions';
import Select from 'react-select';

class EmailConfigurationGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group: undefined,
      emails: [],
      isDirty: false,
      inputtedEmail: '',
      error: '',
      emailValid: false,
      selectedMarketOptions: [],
    };

    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleCheckGroupInputUpdate = this.handleCheckGroupInputUpdate.bind(this);
    this.handleEmailInputUpdate = this.handleEmailInputUpdate.bind(this);
    this.addEmailRow = this.addEmailRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
  }

  componentDidMount(){
    this.setState({ group: this.props.group, emails: this.props.group.emailDistributionLists });
  }

  componentDidUpdate(prevProps, prevState){
    if (!prevState.isDirty && this.state.isDirty){
      this.setState({ isDirty: false });

      const update = { ...this.state.group, emailDistributionLists: [ ...this.state.emails ] };
      this.props.handleGroupUpdate(update);
    }
  }

  addEmailRow(){
    const { inputtedEmail, emails, selectedMarketOptions } = this.state;
    let newRow = {
      distributionEmailId: 0,
      emailAddress: inputtedEmail.trim(),
      emailDistributionGroupId: 1,
      freightForwarderId: null,
      tempDisableNotifs: false, 
      storeGroups: selectedMarketOptions.map((market) => ({
        storeGroupId: market.value,
        storeGroupName: market.label,
      })), 
    };

    let canAdd = true;

    for(var item of emails)
    {
      if (item.emailAddress.includes(newRow.emailAddress))
      {
        this.setState({ error: 'Email already exists' });
        canAdd = false;
      }    
    }

    if(canAdd)
    {
      this.setState({ emails: this.state.emails.concat(newRow), inputtedEmail: '', emailValid: false ,error: '', isDirty: true });
    }
  }

  removeRow(email){
    const { emails } = this.state;

    const filteredList = emails.filter(a => a.emailAddress !== email);

    this.setState({ emails: [...filteredList] , isDirty: true });  
  }

  handleCheckInputUpdate(e, fieldName, emailAddress) {

    const { emails } = this.state;

    if (emailAddress != null){

      const matchingItem = emails.find(a => emailAddress === a.emailAddress);
      //search for the email
      const filteredList = emails.filter(a => emailAddress !== a.emailAddress);

      this.setState({ emails: [...filteredList, { ...matchingItem, [fieldName]: true && e.target.checked }], isDirty: true });
    }
  }

  handleTextInputUpdate(e, fieldName, emailAddress) {

    const { emails } = this.state;

    if (emailAddress != null){

      const matchingItem = emails.find(a => emailAddress === a.emailAddress);
      //search for the email
      const filteredList = emails.filter(a => emailAddress !== a.emailAddress);

      this.setState({ emails: [...filteredList, { ...matchingItem, [fieldName]: e.target.value }], isDirty: true });
    }
  }

  handleCheckGroupInputUpdate(e, fieldName) {
    const { group } = this.state;
    this.setState({ group: { ...group, [fieldName]: e.target.checked }, isDirty: true });
  }

  handleEmailInputUpdate(e) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let canAdd = false;
    if(re.test(String(e.target.value).toLowerCase())){
      canAdd = true;
    }
    this.setState({ inputtedEmail: e.target.value, emailValid: canAdd });
  }

  handleMultiSelectChange (selectedOptions) {
    this.setState({ selectedMarketOptions: selectedOptions });
  }

  render() {
    const { freights, markets } = this.props;
    const { emails, group, inputtedEmail, error, emailValid } = this.state;
    const { addEmailRow, handleEmailInputUpdate, handleCheckGroupInputUpdate, removeRow } = this;

    const isMarketSpecific = group?.groupName == 'Force Closing a Shipment';

    if (!group)
    {
      return null;
    }

    const marketOptions = markets.map((market) => ({
      value: market.key,
      label: market.value,
    }));

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        border: '2px solid white',
        width: '400px',
        maxWidth: '600px',
        borderRadius: '15px',
        color: '#6f6f83',
        boxShadow: 'none',
        '&:hover': {
          border: 'none',
        },
        fontSize: '15px',
        fontWeight: 'bold',
        fontFamily: '"Roboto", sans-serif',
      }),
      option: (provided, state) => ({
        ...provided,
        fontWeight: 'normal',
        fontFamily: '"Roboto", sans-serif',
        backgroundColor: state.isSelected ? '#007BFF' : 'white',
        color: state.isSelected ? 'white' : 'black',
        '&:hover': {
          backgroundColor: '#007BFF',
          color: 'white',
        },
      }),
    };

    return (    
      <div className="floating-panel">
        <div className="header-title apac">
          <h4>{group && group.groupName}</h4>
        </div>
        <label className="wrapper apac">
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={group.generatePortalNotifs}
              onChange={(e) => handleCheckGroupInputUpdate(e, 'generatePortalNotifs')}
            />
            <div className="toggle-icon" />
          </div>
          <div className="label apac">
                    Generate Portal Notification
          </div>
        </label>
        <div className="distribution-info error">{error}</div>
        <div className='distribution-info-header-wrapper'>
          <div className="new-email">
            <input placeholder="Enter Email Here"
              value={inputtedEmail}
              onChange={(e) => handleEmailInputUpdate(e)}
            ></input>
            <button className="action"
              type="button"
              disabled={!emailValid}
              onClick={() => addEmailRow()}
            >+</button>
          </div>
          {isMarketSpecific &&
          <Select
            isMulti
            placeholder="All Markets"
            styles={customStyles}
            options={marketOptions}
            value={this.state.selectedMarketOptions}
            onChange={this.handleMultiSelectChange}
          />
          }
        </div>
        {group && group.emailDistributionGroupId == 2 &&
                       <div className="distribution-info"> * This email can be issued to a Freight Forwarder. To ensure that they
                 only recieve emails for shipments that they are managing, please specify their 
                 Freight Forwarding name.
                       </div>
        }
        <table className="table-apac">
          <thead>
            <tr>
              <th>
                  Distribution List
              </th>
              {group && group.emailDistributionGroupId == 2 &&
              <th>
                Freight Forwarder *
              </th>
              }
              <th>
                  Temporarily Disable Notification
              </th>
              {group && isMarketSpecific &&
              <th>
               Market
              </th>
              }
              <th>
                  Remove
              </th>
            </tr>
          </thead>
          <tbody>
            {emails && emails.map( r =>
              <tr key={r.emailAddress}>
                <td>{r.emailAddress}</td>
                {group.emailDistributionGroupId == 2 &&
              <td>
                <select className="apac"
                  placeholder="..."
                  value={r.freightForwarderId}
                  onChange={(e) => this.handleTextInputUpdate(e, 'freightForwarderId', r.emailAddress)}
                >
                  <option value="">Select a freight...</option>
                  {freights && freights.map(l =>
                    <option key={l.key}
                      value={l.key}>{l.value}</option>)
                  }
                </select>
              </td>
                }
                <td>
                  <label className="wrapper apac">
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={r.tempDisableNotifs}
                        onChange={(e) => this.handleCheckInputUpdate(e, 'tempDisableNotifs', r.emailAddress)}
                      />
                      <div className="toggle-icon" />
                    </div>
                  </label>
                </td>
                {group && isMarketSpecific &&
                <td>{r.storeGroups.length > 0 ? r.storeGroups.map(x => x.storeGroupName).join(',') : 'All Markets' }</td>
                }
                <td>
                  <button className="action negative"
                    style={{ width: '35px' }}
                    onClick={() => removeRow(r.emailAddress)}
                  >-</button>
                </td>
              </tr>,
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(mapDispatchToProps)(EmailConfigurationGroup);
