import React, { useState } from 'react';
import DeleteUserModal from './subComponents/deleteUserModal';

const CustomerUserConifgUpload = () => {
  const [mediaData, setMediaData] = useState('');
  const [mediaName, setMediaName] = useState('');
  const [mediaType, setMediaType] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [validated, setValidated] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [usersToBeDeleted, setUsersToBeDeleted] = useState([]);

  const handleValidate = (e) => {
    //verification
    async function verify(){
      setIsUploading(true);
      const response = await fetch('/api/user/v1/ValidateCustomerUserImport', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        method: 'POST',
        body:  JSON.stringify({ 
          mediaData,
          mediaName,
          mediaType,
        }),
      });

      if (response.status == 200 || response.status == 204){
        const data = await response.json();
        if (data.data?.length == 0){
          handleSave();
        }
        else if (data.data?.length > 0){
          setShowDeleteModal(true);
          setIsUploading(false);
          setUsersToBeDeleted(data.data);
        }
      }
      else {
        setIsUploading(false);
        setIsLoaded(true);
        setErrorMessage('Something went wrong.');
      }
    }
    verify();
    e.preventDefault();
  };

  const handleSave = () => {
    //verification
    async function save(){
      setIsUploading(true);
      setValidated(true);
      setShowDeleteModal(false);
      const response = await fetch('/api/user/v1/UploadCustomerUserImport', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        method: 'POST',
        body:  JSON.stringify({ 
          mediaData,
          mediaName,
          mediaType,
        }),
      });
      if (response.status == 200 || response.status == 204){
        setIsUploading(false);
        setIsLoaded(true);
      }
      else{
        setErrorMessage('An error occured');
        setIsUploading(false);
        setIsLoaded(true);
      }
    }
    save();
  };



  const handleFileInputUpdate = (e) => {
    if (!e.target.files || e.target.files.length === 0) return;
    let doc = {
      mediaName: e.target.files[0].name,
      mediaType: e.target.files[0].type,
    };

    var reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }).then(data => {
      doc.mediaData = data ? data.replace(/^data:(.*,)?/, '') : '';

      setMediaData(doc.mediaData);
      setMediaName(doc.mediaName);
      setMediaType(doc.mediaType);
    });
  };

  return (
    <div className="master-file-upload-page page">
      <form className="form-container">
        <div className="form-header">
          <div className="header-title">
            <div className="icon icon-reports"></div>
            <h2>Customer User Configuration Upload</h2>
          </div>
        </div>
        <div className="form-explanation">
          <p>Upload a customer user configuration document to update user information.</p>
        </div>
        <div className="form-body">
          <label className="file-upload-wrapper">
            <div className="icon icon-image-upload"></div>
            <p>
              {!mediaName &&
                  'Tap to upload your Customer Config'}
              {mediaName}
            </p>
            <input
              autoFocus
              name="file"
              id="file"
              className="inputfile"
              type="file"
              onChange={e => handleFileInputUpdate(e)}
            />
          </label>
        </div>
        <div className="form-buttons">
          {errorMessage && <p>{errorMessage}</p>}
          {isLoaded && validated && <p>File Successfully Uploaded</p>}
          <button type="button"
            className="action"
            disabled={isUploading}
            onClick={e => handleValidate(e)}>
              Upload
          </button>
        </div>
      </form>
      {showDeleteModal &&
      <DeleteUserModal
        usersToBeDeleted={usersToBeDeleted}
        continue={() => handleSave()}
        cancel={() => setShowDeleteModal(false)}
      />
      }
    </div>
  );
};

export default CustomerUserConifgUpload;
