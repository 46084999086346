import React from 'react';
 
const LacTerms=()=>
{
  return (<>
    <div className="logo" />
    <h4>MyStarbucks Portal Terms of Use (LAC)</h4>
    <sub>LAST UPDATED 02 AUG 2019</sub>
    <div className="terms-text">
      <h5>These terms and conditions are applicable to the use and access of the Mylacportal.starbucks.com
                Portal (“portal”) in relation to Starbucks business in the Latin America region, which is operated by Starbucks Corporation (“we”).</h5>
                
      <h5>By using our portal you accept these terms of use</h5>
      <p>
                By using our portal, you confirm that you accept these terms of
                use and that you agree to comply with them.
      </p>
      <p>
                If you do not agree to these terms of use, you must not use our portal.
      </p>
      <h5>We may suspend or withdraw our portal</h5>
      <p>
                We do not guarantee that our portal, or any content on it, will
                always be available or be uninterrupted. We may suspend or
                withdraw or restrict the availability of all or any part of our
                portal for business and operational reasons. We will try to give
                you reasonable notice of any suspension or withdrawal.
      </p>
      <p>
                You are also responsible for ensuring that all persons who access
                our portal through your internet connection are aware of these
                terms of use and other applicable terms and conditions, and that
                they comply with them.
      </p>
      <h5>You must keep your account details safe</h5>
      <p>
                If you choose, or you are provided with, a user identification
                code, password or any other piece of information as part of our
                security procedures, you must treat such information as
                confidential. You must not disclose it to any third party.
      </p>
      <p>
                We have the right to disable any user identification code or
                password, whether chosen by you or allocated by us, at any time,
                if in our reasonable opinion you have failed to comply with any of
                the provisions of these terms of use.
      </p>
      {false && <p>
                If you know or suspect that anyone other than you knows your user
                identification code or password, you must promptly notify us at{' '}
        <i>
          <u>apac-tech-support@starbucks.com.</u>
        </i>
      </p>}
      <h5>How you may use material on our portal</h5>
      <p>
                We are the owner or the licensee of all intellectual property
                rights in our portal, and in the material published on it. Those
                works are protected by copyright laws and treaties around the
                world. All such rights are reserved.
      </p>
      <h5>Information on this portal</h5>
      <p>
                Although we make efforts to provide the most up to date the
                information on our portal, we make no representations, warranties
                or guarantees, whether express or implied, that the content on our
                portal is accurate, complete or up to date.
      </p>
      <h5>Our responsibility for loss or damage suffered by you</h5>
      <ul>
        <li>
                    We do not exclude or limit in any way our liability to you where
                    it would be unlawful to do so. This includes liability for death
                    or personal injury caused by our negligence or the negligence of
                    our employees, agents or subcontractors and for fraud or
                    fraudulent misrepresentation.
        </li>
        <li>
                    We exclude all implied conditions, warranties, representations
                    or other terms that may apply to our portal or any content on
                    it.
        </li>
        <li>
                    We will not be liable to you for any loss or damage, whether in
                    contract, tort (including negligence), breach of statutory duty,
                    or otherwise, even if foreseeable, arising under or in
                    connection with:
          <ul>
            <li>use of, or inability to use, our portal; or</li>
            <li>
                        use of or reliance on any content displayed on our portal.
            </li>
          </ul>
        </li>
        <li>
                    In particular, we will not be liable for:
          <ul>
            <li>loss of profits, sales, business, or revenue;</li>
            <li>business interruption;</li>
            <li>loss of anticipated savings;</li>
            <li>
                        loss of business opportunity, goodwill or reputation; or
            </li>
            <li>any indirect or consequential loss or damage.</li>
          </ul>
        </li>
      </ul>
      <h5>Uploading content to our portal</h5>
      <p>
                Whenever you make use of a feature that allows you to upload
                content to our portal, or to make contact with other users of our
                portal, you must comply with the content standards set out in our
                Acceptable Use Policy.
      </p>
      <p>
                You warrant that any such contribution does comply with those
                standards, and you will be liable to us and indemnify us for any
                breach of that warranty. This means you will be responsible for
                any loss or damage we suffer as a result of your breach of
                warranty.
      </p>
      <p>
                Any content you upload to our portal will be considered
                non-confidential and non-proprietary. You retain all of your
                ownership rights in your content, but you are required to grant us
                a limited licence to use, store and copy that content and to
                distribute and make it available to third parties.
      </p>
      <p>
                You are solely responsible for securing and backing up your
                content.
      </p>
      <p>We do not store terrorist content.</p>
      <h5>
                We are not responsible for viruses and you must not introduce them
      </h5>
      <p>
                We do not guarantee that our portal will be secure or free from
                bugs or viruses.
      </p>
      <p>
                You are responsible for configuring your information technology,
                computer programmes and platform to access our portal. You should
                use your own virus protection software.
      </p>
      <p>
                You must not misuse our portal by knowingly introducing viruses,
                trojans, worms, logic bombs or other material that is malicious or
                technologically harmful. You must not attempt to gain unauthorised
                access to our portal, the server on which our portal is stored or
                any server, computer or database connected to our portal. You must
                not attack our portal via a denial-of-service attack or a
                distributed denial-of service attack. We will report any such
                breach to the relevant law enforcement authorities and we will
                co-operate with those authorities by disclosing your identity to
                them. In the event of such a breach, your right to use our portal
                will cease immediately.
      </p>
      <h5>Applicable laws </h5>
      <p>
                These terms of use, their subject matter and their formation
                (and any non-contractual disputes or claims) are governed by the laws of the state of Washington,
                without reference to any conflict of laws principles. We both agree to the exclusive jurisdiction of the
                federal and state courts in King County, Washington, for purposes of any legal actions arising out of or related to these terms of use.
      </p>
    </div>
  </>);
};
 
export default LacTerms;