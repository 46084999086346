import React from 'react';

class ShelfTabOds extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { customerRequirementsDetail, handleTextInputUpdate, handleCheckInputUpdate } = this.props;
    return (
      <>
        <label>
        No Shelf Extension Allowed
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.noShelfExtensionAllowed}
              onChange={e => handleCheckInputUpdate(e, 'noShelfExtensionAllowed')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
        Strict Delivery - No Older Shelf Life Can Be Delivered
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.strictDelivery}
              onChange={e => handleCheckInputUpdate(e, 'strictDelivery')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label> 
        <label>
        Market Specific Requirements
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.marketSpecificRequirementsShelf}
              onChange={e => handleCheckInputUpdate(e, 'marketSpecificRequirementsShelf')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
       Details
          <textarea
            maxLength={500}
            type="text"
            value={customerRequirementsDetail && customerRequirementsDetail.otherRequirementsShelf}
            onChange={e => handleTextInputUpdate(e, 'otherRequirementsShelf')}
          />
        </label>
      </>
    );
  }
}

export default ShelfTabOds;
