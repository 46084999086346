import React from 'react';
import MultiSelect from 'react-multi-select-component';

const KpiFilter = (props) => {
  const {
    months,
    weeks,
    storeGroups,
    stores,
    filter,
    onStoreGroupChange,
    onStoreChange,
    onMonthChange,
    onWeekChange,
  } = props;

  return (
    <div className="kpi-filter">
      {/* <label className="placeholder-label" /> */}
      {storeGroups && storeGroups.length > 0 &&
                <label>
                    Customer Groups
                  <MultiSelect
                    options={storeGroups}
                    value={filter.customerGroup}
                    onChange={onStoreGroupChange}
                    labelledBy={'Select customer groups'}
                  />
                </label>
      }
      {stores && stores.length > 0 &&
                <label>
                    Stores
                  <MultiSelect
                    options={stores}
                    value={filter.store}
                    onChange={onStoreChange}
                    labelledBy={'Select stores'}
                  />
                </label>
      }
      {/* {
              showExportData ? <button type="button" onClick={onExportData}>Export Data</button> : <label className="placeholder-label" />
            } */}
      {months && months.length > 0 &&
                <label>
                    Months
                  <MultiSelect
                    options={months}
                    value={filter.months}
                    onChange={onMonthChange}
                    labelledBy={'Select Month'}
                  />
                </label>
      }
      {weeks && weeks.length > 0 &&
                <label>
                    Weeks
                  <select
                    placeholder={'Select...'}
                    value={filter.weeks && filter.weeks.length && filter.weeks.length === 1 && filter.weeks[0].value}
                    onChange={(e) => onWeekChange([{ value: e.target.value, label: e.target.value }])}
                  >
                    <option value="">Select...</option>
                    {weeks.map(op =>
                      <option key={op.value}
                        value={op.value}>{op.label}</option>)}
                  </select>
                </label>
      }
    </div>
  );
};

export default KpiFilter;
