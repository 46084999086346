import React, { useState, useEffect } from 'react';
import OrderMetrics from './OrderMetrics/OrderMetrics';
import DemandAndSupply from './DemandAndSupply/demandAndSupply';
import ShipmentPerformance from './ShipmentPerformance/shipmentPerformance';
import KpiMetrics from './KpiMetrics/kpiMetrics';
import EmergencyOrders from './EmergencyOrders/emergencyOrders';
import ForecastChangeRequest from './ForecastChangeRequest/forecastChangeRequest';
import ForecastVsOrder from './ForecastVsOrder/forecastVsOrder';
import forecastPerformanceDashboardActions from '../../actions/forecastPerformanceDashboardActions';
import { connect } from 'react-redux';



const ForecastDashboard = (props) => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    props.getMbus();
    props.getProductCategories();
    props.getKpiReferenceData();
    setLoading(false);
  }, [props.getMbus, props.getProductCategories]);

  let isValid = props.mbuData.length > 0 && props.productCategoriesData.length > 0;

  if (loading && !isValid) {
    return <div className="app-spinner">
      <span className="spinner" />
    </div>;
  }
 
  return  (!loading && isValid && <div className="forecast-page page">
    <div className={'floating-panel loaded'}>
      <div className="floating-header">
        <div className="header-title">
          <div
            className={'icon icon-table'}></div>
          <div>
            <h2>Forecast Performance Dashboard</h2>
            <p>View forecast performance metrics for MBUs</p>
          </div>
        </div>
      </div>
      <div className="menu-container">
        <div className="dashboard-container">
          <h3 className='dashboard-title'>Order Metrics</h3>
          <OrderMetrics mbuData={props.mbuData}/> 
        </div>
    
        <div className="dashboard-container"> 
          <h3 className='dashboard-title'>Demand and Supply: Forecast Variance </h3><br/>
          <DemandAndSupply mbuData={props.mbuData}
            productCategoriesData={props.productCategoriesData}/>
        </div>


        <div className="dashboard-container"> 
          <h3 className='dashboard-title'> Shipment Performance</h3><br/>
          <ShipmentPerformance mbuData={props.mbuData} />
        </div>

        <div className="dashboard-container">
          <h3 className='dashboard-title'> Unconstrained Unfulfillment %</h3>
          <KpiMetrics mbuData={props.mbuData}
            kpiReferenceData={props.kpiReferenceData}/> 
        </div>

        <div className="dashboard-container">
          <h3 className='dashboard-title'>Emergency Orders</h3>
          <EmergencyOrders mbuData={props.mbuData}/> 
        </div>

    
        <div className="dashboard-container">
          <h3 className='dashboard-title'>Forecast Change Requests</h3>
          <ForecastChangeRequest mbuData={props.mbuData}/> 
        </div>

        <div className="dashboard-container">
          <h3 className='dashboard-title'>Forecast vs Order ($)</h3>
          <ForecastVsOrder mbuData={props.mbuData} /> 
        </div>
      </div>
 
    </div>
 
  </div>);  
};

const mapStateToProps = (state) => {
  return {
    mbuData: state.forecastPerformanceDashboard.mbuData,
    productCategoriesData: state.forecastPerformanceDashboard.productCategoriesData,
    kpiReferenceData: state.forecastPerformanceDashboard.kpiReferenceData,
  };
};
 

const mapDispatchToProps = (dispatch) => {
  return {
    getMbus: () => dispatch(forecastPerformanceDashboardActions.getMbus()),
    getProductCategories: () => dispatch(forecastPerformanceDashboardActions.getProductCategories()),
    getKpiReferenceData: () => dispatch(forecastPerformanceDashboardActions.getKpiReferenceData()),
  };
};
 

export default connect(mapStateToProps, mapDispatchToProps)(ForecastDashboard);
