/* globals google */

export class ClusterIcon {
  cluster;
  className;
  styles;
  center;
  div;
  sums;
  visible;
  url;
  height;
  width;
  anchorText;
  anchorIcon;
  textColor;
  textSize;
  textDecoration;
  fontWeight;
  fontStyle;
  fontFamily;
  backgroundPosition;

  boundsChangedListener;

  constructor(cluster, styles) {
    cluster.getClusterer().extend(ClusterIcon, google.maps.OverlayView);
    this.cluster = cluster;
    this.className = this.cluster.getClusterer().getClusterClass();
    this.styles = styles;
    this.center = undefined;
    this.div = null;
    this.sums = null;
    this.visible = false;
    this.boundsChangedListener = null;
    this.url = "";
    this.height = 0;
    this.width = 0;
    this.anchorText = [0, 0];
    this.anchorIcon = [0, 0];
    this.textColor = "white";
    this.textSize = 11;
    this.textDecoration = "none";
    this.fontWeight = "bold";
    this.fontStyle = "normal";
    this.fontFamily = "Arial,sans-serif";
    this.backgroundPosition = "0 0";

    this.setMap(cluster.getMap()); // Note: this causes onAdd to be called
  }

  onAdd() {
    let cMouseDownInCluster;
    let cDraggingMapByCluster;

    this.div = document.createElement("div");
    this.div.className = this.className;
    if (this.visible) {
      this.show();
    }

    this.getPanes().overlayMouseTarget.appendChild(this.div);

    // Fix for Issue 157
    this.boundsChangedListener = google.maps.event.addListener(
      this.getMap(),
      "boundschanged",
      function boundsChabged() {
        cDraggingMapByCluster = cMouseDownInCluster;
      }
    );

    this.div.addEventListener(
      "mousedown",
      function onMouseDown() {
        cMouseDownInCluster = true;
        cDraggingMapByCluster = false;
      }
    );

    this.div.addEventListener(
      "click",
      event => {
        cMouseDownInCluster = false;

        if (!cDraggingMapByCluster) {
          const markerClusterer = this.cluster.getClusterer();

          /**
           * This event is fired when a cluster marker is clicked.
           * @name MarkerClusterer#click
           * @param {Cluster} c The cluster that was clicked.
           * @event
           */
          google.maps.event.trigger(markerClusterer, "click", this.cluster);
          google.maps.event.trigger(
            markerClusterer,
            "clusterclick",
            this.cluster
          ); // deprecated name

          // The default click handler follows. Disable it by setting
          // the zoomOnClick property to false.
          if (markerClusterer.getZoomOnClick()) {
            // Zoom into the cluster.
            const maxZoom = markerClusterer.getMaxZoom();

            const bounds = this.cluster.getBounds();

            markerClusterer.getMap().fitBounds(bounds);

            // There is a fix for Issue 170 here:
            setTimeout(function timeout() {

              markerClusterer.getMap().fitBounds(bounds);

              // Don't zoom beyond the max zoom level
              if (
                maxZoom !== null &&
                markerClusterer.getMap().getZoom() > maxZoom
              ) {
                markerClusterer.getMap().setZoom(maxZoom + 1);
              }
            }, 100);
          }

          // Prevent event propagation to the map:
          event.cancelBubble = true;

          if (event.stopPropagation) {
            event.stopPropagation();
          }
        }
      }
    );

    this.div.addEventListener(
      "mouseover",
      () => {
        /**
         * This event is fired when the mouse moves over a cluster marker.
         * @name MarkerClusterer#mouseover
         * @param {Cluster} c The cluster that the mouse moved over.
         * @event
         */
        google.maps.event.trigger(
          this.cluster.getClusterer(),
          "mouseover",
          this.cluster
        );
      }
    );

    this.div.addEventListener(
      "mouseout",
      () => {
        /**
         * This event is fired when the mouse moves out of a cluster marker.
         * @name MarkerClusterer#mouseout
         * @param {Cluster} c The cluster that the mouse moved out of.
         * @event
         */
        google.maps.event.trigger(
          this.cluster.getClusterer(),
          "mouseout",
          this.cluster
        );
      }
    );
  }

  onRemove() {
    if (this.div && this.div.parentNode) {
      this.hide();

      if (this.boundsChangedListener !== null) {
        google.maps.event.removeListener(this.boundsChangedListener);
      }

      google.maps.event.clearInstanceListeners(this.div);

      this.div.parentNode.removeChild(this.div);

      this.div = null;
    }
  }

  draw() {
    if (this.visible && this.div !== null && this.center) {
      const { x, y } = this.getPosFromLatLng(this.center);

      this.div.style.top = y + "px";
      this.div.style.left = x + "px";
    }
  }

  hide() {
    if (this.div) {
      this.div.style.display = "none";
    }

    this.visible = false;
  }

  show() {
    if (this.div && this.center) {
      let img = "",
        divTitle = "";

      // NOTE: values must be specified in px units
      const bp = this.backgroundPosition.split(" ");

      const spriteH = parseInt(bp[0].replace(/^\s+|\s+$/g, ""), 10);

      const spriteV = parseInt(bp[1].replace(/^\s+|\s+$/g, ""), 10);

      const pos = this.getPosFromLatLng(this.center);

      if (
        this.sums === null ||
        typeof this.sums.title === "undefined" ||
        this.sums.title === ""
      ) {
        divTitle = this.cluster.getClusterer().getTitle();
      } else {
        divTitle = this.sums.title;
      }

      this.div.style.cssText = this.createCss(pos);

      img =
        "<img alt='" +
        divTitle +
        "' src='" +
        this.url +
        "' style='position: absolute; top: " +
        spriteV +
        "px; left: " +
        spriteH +
        "px; ";

      if (!this.cluster.getClusterer().enableRetinaIcons) {
        img +=
          "clip: rect(" +
          -1 * spriteV +
          "px, " +
          (-1 * spriteH + this.width) +
          "px, " +
          (-1 * spriteV + this.height) +
          "px, " +
          -1 * spriteH +
          "px);";
      }

      img += "'>";

      this.div.innerHTML =
        img +
        "<div style='" +
        "position: absolute;" +
        "top: " +
        this.anchorText[0] +
        "px;" +
        "left: " +
        this.anchorText[1] +
        "px;" +
        "color: " +
        this.textColor +
        ";" +
        "font-size: " +
        this.textSize +
        "px;" +
        "font-family: " +
        this.fontFamily +
        ";" +
        "font-weight: " +
        this.fontWeight +
        ";" +
        "text-align:center;" +
        "font-style: " +
        this.fontStyle +
        ";" +
        "text-decoration: " +
        this.textDecoration +
        ";" +
        "text-align: center;" +
        "width: " +
        this.width +
        "px;" +
        "line-height:" +
        this.height +
        "px;" +
        "'>" +
        this.sums.text +
        "</div>";

      this.div.title = divTitle;

      this.div.style.display = "";
    }

    this.visible = true;
  }

  useStyle(sums) {
    this.sums = sums;

    const style = this.styles[
      Math.min(this.styles.length - 1, Math.max(0, sums.index - 1))
    ];

    this.url = style.url;
    this.height = style.height;
    this.width = style.width;
    this.anchorText = style.anchorText || [0, 0];
    this.anchorIcon = style.anchorIcon || [this.height / 2, this.width / 2];

    this.textColor = style.textColor || "white";

    this.textSize = style.textSize || 10;

    this.textDecoration = style.textDecoration || "none";

    this.fontWeight = style.fontWeight || "normal";

    this.fontStyle = style.fontStyle || "normal";

    this.fontFamily = style.fontFamily || "Arial,sans-serif";

    this.backgroundPosition = style.backgroundPosition || "0 0";
  }

  setCenter(center) {
    this.center = center;
  }

  createCss(pos) {
    const style = [];

    style.push("cursor: pointer;");

    style.push(
      "position: absolute; top: " + pos.y + "px; left: " + pos.x + "px;"
    );

    style.push("width: " + this.width + "px; height: " + this.height + "px;");

    return style.join("");
  }

  getPosFromLatLng(latlng) {
    const pos = this.getProjection().fromLatLngToDivPixel(latlng);

    pos.x -= this.anchorIcon[1];

    pos.y -= this.anchorIcon[0];

    return pos;
  }
}
