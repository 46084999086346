import React from "react";
import { connect } from "react-redux";
import { navigationActions } from "../../actions/navigationActions";
import { instantMessagingActions } from "../../actions/messageActions";
import ChatRoomTile from "./shared/chatRoomTile";

class InstantMessagingAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      conversationId: 0,
      message: "",
      maxActiveConversation: 3
    };

    this.handleJoinChat = this.handleJoinChat.bind(this);
    this.handleStartConversation = this.handleStartConversation.bind(this);
    this.viewConversation = this.viewConversation.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleSendMessage = this.handleSendMessage.bind(this);
  }

  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({ block: "end" });
      this.chatEnd.scrollIntoView({ block: "end" });
    }
  };

  componentDidMount() {
    this.props.connect();
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps, prevState) {
    this.scrollToBottom();
    if (prevProps.connected !== this.props.connected) {
      if (this.props.connected) {
        this.props.fetchChatRooms();
      } else if (this.props.connected === false) {
        this.props.connect()
      }
    }

    if (
      prevProps.currentChatRoom &&
      prevProps.currentChatRoom.conversations &&
      this.props.currentChatRoom &&
      this.props.currentChatRoom.conversations
    ) {
      if (
        !prevProps.currentChatRoom.conversations.length &&
        this.props.currentChatRoom.conversations.length
      )
        this.setState({
          conversationId: this.props.currentChatRoom.conversations[0]
            .conversationId
        });

      if (
        prevProps.currentChatRoom.conversations.length >
        this.props.currentChatRoom.conversations.length
      ) {
        if (
          this.props.currentChatRoom.conversations.filter(
            x => x.conversationId === this.state.conversationId
          ).length === 0
        ) {
          this.setState({ conversationId: 0 });
        }
      }
    }
  }

  /*
  window.onbeforeunload = function() {
        return true;
      };

      //TODO add preventitive measures to people bailing on chat.
  
  */
  componentWillUnmount() {
    this.props.disconnect();
  }

  handleJoinChat(chatRoomId) {
    this.props.hostChatRoom(chatRoomId);
  }

  handleStartConversation(chatRoomId, userQueueId) {
    if (
      this.props.currentChatRoom.conversations.length <
      this.state.maxActiveConversation
    ) {
      this.props.startConversation(chatRoomId, userQueueId);
    }
  }

  handleSendMessage(conversationId, message) {
    if (message) {
      this.props.sendMessage(conversationId, message);
      this.setState({ message: "" });
    }
  }

  viewConversation(conversationId) {
    this.setState({ conversationId, message: "" });
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  renderQueue(currentChatRoom) {
    var queue = currentChatRoom.queue
      .map((q, i) => (
        <div
          key={q.userQueueId}
          className="queued-user"
          onClick={() =>
            this.handleStartConversation(
              currentChatRoom.chatRoomId,
              q.userQueueId
            )
          }>
          <h2>{i + 1}</h2>
          <div>
            <div>{q.userName}</div>
            <div>{new Date(q.queueStartTime).toLocaleTimeString()}</div>
          </div>
        </div>
      ))
      .reverse();

    while (queue.length < 5) {
      queue.unshift(
        <div key={queue.length} className="queued-user empty">
          <h2>{queue.length + 1}</h2>
          <div>
            <div>Empty Queue Slot</div>
          </div>
        </div>
      );
    }

    return queue;
  }

  renderActiveConversations(currentChatRoom) {
    var conversations = currentChatRoom.conversations
      .map(q => (
        <div
          key={q.conversationId}
          className={`conversation-user ${q.conversationId === this.state.conversationId ? "selected" : ""
            }`}
          onClick={() => this.viewConversation(q.conversationId)}>
          <div
            className={`icon icon-star${q.conversationId === this.state.conversationId ? "5" : "3"
              }`}></div>

          <div>
            <div> {q.userName}</div>
          </div>
        </div>
      ))
      .reverse();

    while (conversations.length < 3) {
      conversations.unshift(
        <div key={conversations.length} className="conversation-user empty">
          <div className="icon icon-star-empty"></div>
          <div>
            <div>Empty Chat Slot</div>
          </div>
        </div>
      );
    }
    return conversations;
  }

  render() {
    const { chatRooms, currentChatRoom } = this.props;
    const { conversationId, message } = this.state;
    const { handleJoinChat, handleTextInputUpdate, handleSendMessage } = this;

    let conversation = currentChatRoom.conversations.filter(
      c => c.conversationId === conversationId
    )[0];

    return (
      <div className="instant-messaging-admin-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-orderly"></div>
              <div>
                <h2>Instant Messaging Admin</h2>
                <p>
                  IM is available from 08:00 – 15:00 CET Mon – Fri{" "}
                  <a target="blank" href="https://www.gov.uk/bank-holidays">
                    (excluding UK public holidays)
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
        {!currentChatRoom.active ? (
          <div className="chat-room-container">
            {chatRooms.map(c => (
              <ChatRoomTile
                key={c.chatRoomId}
                title={c.name}
                iconClass="icon-messages"
                description={c.email}
                active={c.active}
                activeAction={() => handleJoinChat(c.chatRoomId)}
                inactiveAction={() => handleJoinChat(c.chatRoomId)}
              />
            ))}
          </div>
        ) : (
            <div className="floating-panel">
              <div className="floating-header">
                <h2>{currentChatRoom.name}</h2>
                <button
                  type="button"
                  className="float bad"
                  onClick={() => this.props.disconnect()}>
                  Leave
              </button>
              </div>
              <div className="floating-body">
                <div className="conversation-list ">
                  {this.renderQueue(currentChatRoom)}

                  {this.renderActiveConversations(currentChatRoom)}
                </div>
                {conversationId ? (
                  <div className="conversation">
                    <div className="conversation-text">
                      {conversation &&
                        conversation.messages.map((c, i) => (
                          <div
                            key={i}
                            className={`message ${c.sender ? "sender" : "receiver"
                              }`}>
                            <div className="avatar ">
                              <div
                                className={`icon ${!c.sender ? "icon-admin" : "icon-messages"
                                  }`}></div>
                            </div>
                            <div className="message-text-container">
                              <div className="message-text">{c.text}</div>
                              <div className="message-timestamp">
                                {new Date(c.sent).toLocaleTimeString()}
                              </div>
                            </div>
                          </div>
                        ))}
                      <div
                        ref={el => {
                          this.messagesEnd = el;
                        }}></div>
                    </div>
                    <div className="conversation-controls">
                      <input
                        value={message}
                        onChange={e => handleTextInputUpdate(e, "message")}
                        onKeyDown={e =>
                          e.key === "Enter"
                            ? handleSendMessage(
                              conversation.conversationId,
                              message
                            )
                            : null
                        }
                      />
                      <button
                        type="button"
                        className="float"
                        onClick={() =>
                          handleSendMessage(conversationId, message)
                        }>
                        Send
                    </button>
                    </div>
                    <div
                      ref={el => {
                        this.chatEnd = el;
                      }}></div>
                  </div>
                ) : (
                    <div className="conversation">
                      <div className="status">
                        No active conversation, please select a queued user to
                        begin.
                  </div>
                    </div>
                  )}
              </div>
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    connected: state.message.connected,
    chatRooms: state.message.chatRooms,
    currentChatRoom: state.message.currentChatRoom
  };
};

const mapDispatchToProps = dispatch => ({
  connect: () => dispatch(instantMessagingActions.connect()),
  disconnect: () => dispatch(instantMessagingActions.disconnect()),

  fetchChatRooms: () => dispatch(instantMessagingActions.fetchChatRooms()),
  hostChatRoom: chatRoomId =>
    dispatch(instantMessagingActions.hostChatRoom(chatRoomId)),
  startConversation: (chatRoomId, userQueueId) =>
    dispatch(
      instantMessagingActions.startConversation(chatRoomId, userQueueId)
    ),
  sendMessage: (conversationId, message) =>
    dispatch(instantMessagingActions.sendMessage(conversationId, message)),
  //fetchAvailableChatRooms: () =>
  //  dispatch(instantMessagingActions.fetchAvailableChatRooms()),

  push: path => dispatch(navigationActions.pushNavigation(path)),
  replace: path => dispatch(navigationActions.replaceNavigation(path)),
  reset: () => dispatch(navigationActions.resetNavigation()),
  goBack: () => dispatch(navigationActions.backNavigation())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstantMessagingAdmin);
