import {
  CONNECT_GETCONTENTBUILDER_REQUEST,
  CONNECT_GETCONTENTBUILDER_SUCCESS,
  CONNECT_GETCONTENTBUILDER_FAILURE,
  CONNECT_CONTENTBUILDERASSETS_REQUEST,
  CONNECT_CONTENTBUILDERASSETS_SUCCESS,
  CONNECT_CONTENTBUILDERASSETS_FAILURE,
  CONNECT_CONTENTBUILDERBACKGROUNDS_REQUEST,
  CONNECT_CONTENTBUILDERBACKGROUNDS_SUCCESS,
  CONNECT_CONTENTBUILDERBACKGROUNDS_FAILURE,
  CONNECT_CONTENTBUILDERATTACHMENTS_REQUEST,
  CONNECT_CONTENTBUILDERATTACHMENTS_SUCCESS,
  CONNECT_CONTENTBUILDERATTACHMENTS_FAILURE,
  CONNECT_SAVECONTENTBUILDER_REQUEST,
  CONNECT_SAVECONTENTBUILDER_SUCCESS,
  CONNECT_SAVECONTENTBUILDER_FAILURE, 
  CONNECT_ARCHIVECONTENTBUILDER_REQUEST,
  CONNECT_ARCHIVECONTENTBUILDER_SUCCESS,
  CONNECT_ARCHIVECONTENTBUILDER_FAILURE,
  CONNECT_DELETECONTENTBUILDER_REQUEST,
  CONNECT_DELETECONTENTBUILDER_SUCCESS, 
  CONNECT_DELETECONTENTBUILDER_FAILURE,
} from './actionTypes';
  
export const connectContentManagementActions = {
  getContentBuilder: (contentId) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_GETCONTENTBUILDER_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Connect/contentmanagement/v1/get${contentId ? '?contentId=' + contentId : ''}`,
        successAction: CONNECT_GETCONTENTBUILDER_SUCCESS,
        failureAction: CONNECT_GETCONTENTBUILDER_FAILURE,
      },
    });
  },
  requestAssets: (assetCategoryId) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_CONTENTBUILDERASSETS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Connect/ContentManagement/v1/assets?assetCategory=${assetCategoryId}`,
        successAction: CONNECT_CONTENTBUILDERASSETS_SUCCESS,
        failureAction: CONNECT_CONTENTBUILDERASSETS_FAILURE,
      },
    });
  },
  requestBackgrounds: (assetCategoryId) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_CONTENTBUILDERBACKGROUNDS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Connect/ContentManagement/v1/assets?assetCategory=${assetCategoryId}`,
        successAction: CONNECT_CONTENTBUILDERBACKGROUNDS_SUCCESS,
        failureAction: CONNECT_CONTENTBUILDERBACKGROUNDS_FAILURE,
      },
    });
  },
  requestAttachments: (assetCategoryId) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_CONTENTBUILDERATTACHMENTS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Connect/ContentManagement/v1/assets?assetCategory=${assetCategoryId}`,
        successAction: CONNECT_CONTENTBUILDERATTACHMENTS_SUCCESS,
        failureAction: CONNECT_CONTENTBUILDERATTACHMENTS_FAILURE,
      },
    });
  },
  saveContent: (saveData) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_SAVECONTENTBUILDER_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Connect/ContentManagement/v1/Save',
        successAction: CONNECT_SAVECONTENTBUILDER_SUCCESS,
        failureAction: CONNECT_SAVECONTENTBUILDER_FAILURE,
        body:{
          ...saveData,
        },
      },
    });
  },
  archiveContent: (contentId) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_ARCHIVECONTENTBUILDER_REQUEST,
      http: {
        verb: 'POST',
        endpoint: `/api/Connect/ContentManagement/v1/archive/${contentId}`,
        successAction: CONNECT_ARCHIVECONTENTBUILDER_SUCCESS,
        failureAction: CONNECT_ARCHIVECONTENTBUILDER_FAILURE,
      },
    });
  },
  deleteContent: (contentId) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_DELETECONTENTBUILDER_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: `/api/Connect/ContentManagement/v1/delete/${contentId}`,
        successAction: CONNECT_DELETECONTENTBUILDER_SUCCESS,
        failureAction: CONNECT_DELETECONTENTBUILDER_FAILURE,
      },
    });
  },
};
  