import React from "react";

class SaveBulkStatusModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromStatusId: -1,
      nextStatus: undefined,
      notes: "",
      expirationOrLongStop: undefined,
      rejectedForApplication: undefined,

      showValidation: false
    };
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
  }

  componentDidMount() {
    const {
      nextStatus,
      isLicensee,
      nearApplications,
      selectedRecords,
      records
    } = this.props;
    let displayExpiration = false;
    let blameApplication = false;

    let recordStatuses = records
      .filter(r => selectedRecords.indexOf(r.applicationId) > -1)
      .map(r => r.statusId);

    let distinctStatuses = [...new Set(recordStatuses)];

    if (
      !isLicensee &&
      (distinctStatuses.indexOf(2) > -1 ||
        distinctStatuses.indexOf(4) > -1 ||
        distinctStatuses.indexOf(6) > -1)
    ) {
      displayExpiration = true;
    }

    if (nextStatus === 9 && nearApplications && nearApplications.length > 0) {
      blameApplication = true;
    }

    let altStatus = nextStatus;

    if (distinctStatuses.length === 1) {
      if (distinctStatuses[0] < 8) {
        altStatus = distinctStatuses[0] + 1;
      }
    }
    this.setState({
      nextStatus: nextStatus || altStatus,
      displayExpiration,
      blameApplication
    });
  }

  handleTextInputUpdate(e, fieldName) {
    this.setState({ [fieldName]: e.target.value });
  }

  handleContinue = e => {
    const {
      fromStatusId,
      nextStatus,
      notes,
      expirationOrLongStop,
      displayExpiration,
      rejectedForApplication
    } = this.state;

    const { selectedRecords, records } = this.props;

    if (!notes || (displayExpiration && !expirationOrLongStop)) {
      this.setState({ showValidation: true });
    } else {
      this.props.continue(
        {
          fromStatusId,
          nextStatus,
          notes,
          expirationOrLongStop,
          rejectedForApplication
        },
        selectedRecords,
        records,
        nextStatus
      );
    }
  };

  render() {
    const {
      notes,
      expirationOrLongStop,
      nextStatus,
      showValidation,
      displayExpiration,
      blameApplication,
      rejectedForApplication
    } = this.state;
    const {
      cancel,
      inProgress,
      complete,
      summary,
      records,
      title,
      nearApplications,
      isLicensee
    } = this.props;
    const { handleTextInputUpdate, handleContinue } = this;
    return (
      <div className="modal-blocker">
        <div className="modal">
          <form>
            <div className="modal-menu">
              <button type="button" className="action" onClick={() => cancel()}>
                X
              </button>
            </div>
            {!complete ? (
              <React.Fragment>
                <div className="modal-title">
                  <h2>{title}</h2>
                </div>
                <div className="modal-body">
                  <label>
                    Notes {!isLicensee ? "(Licensee Visible)" : ""}
                    <textarea
                      autoFocus
                      required
                      className={showValidation && !notes ? "invalid" : ""}
                      value={notes}
                      onChange={e => handleTextInputUpdate(e, "notes")}
                    />
                  </label>
                  {displayExpiration && (
                    <label>
                      {nextStatus === 7
                        ? "Application Long Stop"
                        : "Status Expiration"}
                      <input
                        autoFocus
                        type="date"
                        required
                        className={
                          showValidation && !expirationOrLongStop
                            ? "invalid"
                            : ""
                        }
                        value={expirationOrLongStop}
                        onChange={e =>
                          handleTextInputUpdate(e, "expirationOrLongStop")
                        }
                      />
                    </label>
                  )}
                  {blameApplication && (
                    <label>
                      Rejected for
                      <select
                        autoFocus
                        value={rejectedForApplication}
                        onChange={e =>
                          handleTextInputUpdate(e, "rejectedForApplication")
                        }
                      >
                        <option value="">Please Select</option>
                        {nearApplications.map(n => (
                          <option key={n.applicationId} value={n.applicationId}>
                            {n.siteName}
                          </option>
                        ))}
                      </select>
                    </label>
                  )}
                </div>
                <div className="modal-buttons">
                  <button
                    type="button"
                    className="action"
                    disabled={inProgress}
                    onClick={() => handleContinue()}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="action negative"
                    disabled={inProgress}
                    onClick={() => cancel()}
                  >
                    Cancel
                  </button>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="modal-title">
                  <h2>{title} - Summary</h2>
                </div>
                <div className="modal-body">
                  <ul>
                    {Object.entries(summary).map(([key, value]) => {
                      var application = records.find(
                        r => r.applicationId.toString() === key
                      );

                      if (application) {
                        return (
                          <li key={key}>
                            {application.siteName} - {value ? value : "Updated"}
                          </li>
                        );
                      }

                      return (
                        <li key={key}>Record - {value ? value : "Updated"}</li>
                      );
                    })}
                  </ul>
                </div>
                <div className="modal-buttons">
                  <button
                    type="button"
                    className="action"
                    disabled={inProgress}
                    onClick={() => cancel()}
                  >
                    Close
                  </button>
                </div>
              </React.Fragment>
            )}
          </form>
        </div>
      </div>
    );
  }
}

export default SaveBulkStatusModal;
