import React from 'react';
import { connect } from 'react-redux';
import { userManagementActions } from '../../../actions/userManagementActions';
import { navigationActions } from '../../../actions/navigationActions';
import zxcvbn from 'zxcvbn';

class FirstResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      givenName: '',
      familyName: '',
      password: '',
      passwordConfirmation: '',
      validationMessage: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
  }

  componentDidMount() {
    const { username, givenName, familyName } = this.props;

    this.setState({
      username,
      givenName,
      familyName,
      password: '',
      passwordConfirmation: '',
      validationMessage: '',
      resetPassword: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.groupDeleted && !prevProps.groupDeleted) {
      this.props.getGroups();
    }
    if (this.props.updatedUser && !prevProps.updatedUser) {
      this.setState({ validationMessage: 'User Updated Successfully.' });
    }
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  handleSubmit(e) {
    const { password, passwordConfirmation } = this.state;
    let error = '';
    const passwordEvaluation = zxcvbn(password);

    if (password.length < 8) {
      error = 'Password must be greater than 8 characters.';
    }   
    else if (passwordEvaluation.score < 2){
      error = 'Increase the strength of your password by adding upper case and special characters (e.g. ABC@!)';
    }
    else if (password !== passwordConfirmation) {
      error = 'Passwords do not match.';
    }

    if (!error) {
      this.props.updateUser(this.state);

      e.preventDefault();
    } else {
      this.setState({ validationMessage: error });
      e.preventDefault();
    }
  }

  render() {
    const { errorMessage, updatingUser, updatedUser } = this.props;
    const { validationMessage } = this.state;
    return (
      <form onSubmit={this.handleSubmit}
        noValidate>
        <p>You need to create a new password to continue.</p>
        <p>Please enter a new password.</p>
        <input
          placeholder="Password"
          type="password"
          minLength="8"
          onChange={e => this.handleTextInputUpdate(e, 'password')}
          required
          value={this.state.password}
          className={`float ${this.state.passwordInvalid ? 'invalid' : ''}`}
          autoComplete="new-password"
        />
        <input
          placeholder="Password Confirmation"
          type="password"
          minLength="8"
          onChange={e => this.handleTextInputUpdate(e, 'passwordConfirmation')}
          required
          value={this.state.passwordConfirmation}
          className={`float ${
            this.state.passwordConfirmationInvalid ? 'invalid' : ''
          }`}
          autoComplete="new-password"
        />
        <div className="submission">
          <p>
            {this.props.message}
          </p>
          <button className="float"
            disabled={updatingUser}>
            {updatingUser ? 'Saving...' : updatedUser ? 'Saved' : 'Update'}
          </button>
          <p>{errorMessage}</p>
          <div className='password-validation-message'>
            <p>{validationMessage}</p>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  username: state.auth.username,
  givenName: state.auth.givenName,
  familyName: state.auth.familyName,
  updatingUser: state.auth.updateUser,
  updatedUser: state.auth.updatedUser,
  errorMessage: state.auth.errorMessage,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    updateUser: user => dispatch(userManagementActions.updateMyUser(user)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FirstResetPassword);
