import {
  GETCUSTOMERREQUIREMENTS_REQUEST,
  GETCUSTOMERREQUIREMENTS_SUCCESS,
  GETCUSTOMERREQUIREMENTS_FAILURE,
  UPDATECUSTOMERREQUIREMENTS_REQUEST,
  UPDATECUSTOMERREQUIREMENTS_SUCCESS,
  UPDATECUSTOMERREQUIREMENTS_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const customerRequirementsReducer = (
  state = initialState.customerRequirementsState,
  action,
) => {
  switch (action.type) {
  case GETCUSTOMERREQUIREMENTS_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      customerRequirements: {},
      errorMessage: '',
    };
  }
  case GETCUSTOMERREQUIREMENTS_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      customerRequirements: action.payload,
      errorMessage: '',
    };
  }
  case GETCUSTOMERREQUIREMENTS_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      customerRequirements: {},
      errorMessage: action.payload.message,
    };
  }
  case UPDATECUSTOMERREQUIREMENTS_REQUEST: {
    return {
      ...state,
      isSubmitting: true,
      isSubmitted: false,
      errorMessage: '',
    };
  }
  case UPDATECUSTOMERREQUIREMENTS_SUCCESS: {
    return {
      ...state,
      isSubmitting: false,
      isSubmitted: true,
      errorMessage: '',
    };
  }
  case UPDATECUSTOMERREQUIREMENTS_FAILURE: {
    return {
      ...state,
      isSubmitting: false,
      isSubmitted: false,
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default customerRequirementsReducer;