import {
  LSPOS_MDIO_REQUEST, 
  LSPOS_MDIO_SUCCESS, 
  LSPOS_MDIO_FAILURE, 
  LSPOS_MDIO_DETAIL_REQUEST,
  LSPOS_MDIO_DETAIL_SUCCESS,
  LSPOS_MDIO_DETAIL_FAILURE,
  LSPOS_MDIO_SAVE_REQUEST, 
  LSPOS_MDIO_SAVE_SUCCESS,
  LSPOS_MDIO_SAVE_FAILURE, 
  LSPOS_MDIO_BULKSAVE_REQUEST,
  LSPOS_MDIO_BULKSAVE_SUCCESS,
  LSPOS_MDIO_BULKSAVE_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const PosMdioReducer = (
  state = initialState.posMdioState,
  action = {},
) => {
  switch (action.type) {
  case  LSPOS_MDIO_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      isSaved: false,
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
      mdioList: [],
      errorMessage: '',
    };
  }
  case LSPOS_MDIO_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      totalRecords: action.payload.totalRecords,
      mdioList: action.payload.data,
      errorMessage: '',
    };
  }
  case  LSPOS_MDIO_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
      mdioList: [],
      errorMessage: action.payload.message,
    };
  }
  case LSPOS_MDIO_DETAIL_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      isSaved: false,
      mdioDetail: {},
      errorMessage: '',
    };
  }
  case LSPOS_MDIO_DETAIL_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      mdioDetail: action.payload,
      errorMessage: '',
    };
  }
  case LSPOS_MDIO_DETAIL_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      mdioDetail: {},
      errorMessage: action.payload.message,
    };
  }
  case LSPOS_MDIO_SAVE_REQUEST:
  case LSPOS_MDIO_BULKSAVE_REQUEST: {
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      errorMessage: '',
    };
  }
  case LSPOS_MDIO_SAVE_SUCCESS:
  case LSPOS_MDIO_BULKSAVE_SUCCESS: {
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      errorMessage: '',
    };
  }
  case LSPOS_MDIO_SAVE_FAILURE:
  case LSPOS_MDIO_BULKSAVE_FAILURE: {
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};

export default PosMdioReducer;
