import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { navigationActions } from '../../../actions/navigationActions';
import { sideBarActions } from '../../../actions/sideBarActions';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayLoading: false,
      mode: 'home',
      path: '',
      menuIsOpen: false,
    };

    this.handleBackNavigation = this.handleBackNavigation.bind(this);
    this.handleHomeNavigation = this.handleHomeNavigation.bind(this);
    this.handleConnectNavigation = this.handleConnectNavigation.bind(this);
    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
  }

  componentDidMount() {
    if (window.location.pathname.length < 3) {
      this.setState({ mode: 'home', path: window.location.pathname });
    } else {
      this.setState({ mode: 'other', path: window.location.pathname });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.path !== window.location.pathname) {
      if (window.location.pathname.length < 3) {
        this.setState({ mode: 'home', path: window.location.pathname });
      } else {
        this.setState({ mode: 'other', path: window.location.pathname });
      }
    }
  }

  handleHomeNavigation() {
    this.props.reset();
  }

  handleConnectNavigation() {
    this.props.push('/connect');
  }

  handleBackNavigation() {
    /*const { path } = this.state;
    const { currentModule, modulePath } = this.props;

    const locations = path.split("?")[0].split("/");

    const prevLocation =
      modulePath && modulePath.length && modulePath.length > 1
        ? modulePath[modulePath.length - 2]
        : null;

    let newPath = "/";

    if (locations.length === 3) {
      if (currentModule && currentModule.parentMenuId) {
        if (
          !modulePath ||
          (prevLocation &&
            prevLocation === currentModule.parentMenuId.toString())
        ) {
          newPath = "/module/" + currentModule.parentMenuId;
        }
      }
    } else if (locations.length > 3) {
      newPath = "";
      for (let i = 1; i < locations.length - 1; i++) {
        newPath += "/" + locations[i];
      }
      console.log(newPath);
      this.props.goBack();
      return;
    }*/

    this.props.goBack();
  }

  handleMenuOpen() {
    this.setState({ menuIsOpen: true });
  }

  handleMenuClose() {
    this.setState({ menuIsOpen: false });
  }

  getGreeting() {
    const { auth } = this.props;

    var now = new Date();
    var time = now.getTime() % (1000 * 60 * 60 * 24);
    var offset = now.getTimezoneOffset();

    time -= offset * 60 * 1000;

    var greeting = '';

    if (time < 1000 * 60 * 60 * 12) {
      greeting = 'Good Morning, ';
    } else if (time < 1000 * 60 * 60 * 17.5) {
      greeting = 'Good Afternoon, ';
    } else {
      greeting = 'Good Evening, ';
    }

    return `${greeting} ${auth.givenName}`;
  }

  render() {
    let {
      auth,
      navigation,
      sideBarOpen,
      toggleSideBar,
      numAlerts,
    } = this.props;
    let { mode, displayLoading, menuIsOpen } = this.state;
    return (
      <div
        className={`header ${mode === 'home' ? 'long' : ''} ${
          displayLoading ? 'loading' : ''
        }`}>
        <div
          className={`logo  ${this.props.sideBarOpen ? 'open' : 'closed'}`}
          onClick={() => {
            this.props.push('/');
          }}
        />
        <div className="menu-holder">
          <div className="menu-bar">
            <div className="left-buttons">
              <button
                type="button"
                className="home-button"
                onClick={() => this.handleHomeNavigation()}>
                Home
              </button>
              {this.props.auth.hasConnectAccess ? 
                <button
                  type="button"
                  className="home-button"
                  onClick={() => this.handleConnectNavigation()}>
                Connect
                </button> : null}
              <button
                type="button"
                className="alert-button"
                onClick={() => toggleSideBar(!sideBarOpen)}>
                <div className="icon icon-announcements"></div>
                {numAlerts}
              </button>
            </div>
            <div className="right-options">
              <button
                type="button"
                className="account-button"
                onClick={() => this.handleMenuOpen()}>
                {auth.givenName} {auth.familyName}
              </button>
              {menuIsOpen && (
                <div className="drop-down">
                  <button
                    type="button"
                    className="account-button"
                    onClick={() => this.handleMenuClose()}>
                    {auth.givenName} {auth.familyName}
                  </button>
                  <button type="button"
                    onClick={this.props.logout}>
                    Logout
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      this.props.push('/myaccount');
                      this.setState({ menuIsOpen: false });
                    }}>
                    Account
                  </button>
                </div>
              )}
              {menuIsOpen && <div className="background"
                onClick={() => this.handleMenuClose()}></div>}
            </div>
          </div>
          <div className="header-row-2">
            <div className="greeting">{this.getGreeting()}</div>
            {mode === 'other' && (
              <button type="button"
                onClick={() => this.handleBackNavigation()}>
                {navigation.history.length > 0 ? 'Back' : 'Home'}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  currentModule: state.module.currentModule,
  navigation: state.navigation,
  router: state.router,
  sideBarOpen: state.sideBar.sideBarOpen,
  numAlerts: state.notification.allPopups.filter(pop => !pop.acknowledged)
    .length,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    logout: () => dispatch(authActions.requestLogout()),
    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),

    toggleSideBar: isOpen => dispatch(sideBarActions.toggleSideBar(isOpen)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
