import React from "react";
import applicationStatusEnum from "../helpers/applicationStatusEnum";

class SaveExtensionRequestModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusChangeId: -1,
      statusId: -1,
      request: "",
      showValidation: false,
      currentStatus: "",
    };

    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
  }

  componentDidMount() {
    const { statusChange, currentStatus } = this.props;

    if (statusChange) {
      this.setState({
        statusChangeId: statusChange.statusChangeId,
        statusId: statusChange.toStatusId,
        request: "",
        currentStatus: currentStatus 
      });
    }
  }

  handleFileInputUpdate = e => {
    if (!e.target.files || e.target.files.length === 0) return;
    let currentComponent = this;
    let doc = {
      fileName: e.target.files[0].name,
      fileType: e.target.files[0].type
    };

    var reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }).then(data => {
      doc.fileData = data ? data.replace(/^data:(.*,)?/, "") : "";

      currentComponent.setState(doc);
    });
  };

  handleTextInputUpdate(e, fieldName) {
    this.setState({ [fieldName]: e.target.value });
  }

  handleContinue = e => {
    const { statusChangeId, statusId, request, fileName, fileType, fileData } = this.state;

    if (!request) {
      this.setState({ showValidation: true });
    } else {
      this.props.continue({
        statusChangeId,
        statusId,
        request,
        fileName,
        fileType,
        fileData,
      });
    }
  };

  render() {
    const { request, showValidation } = this.state;
    const { cancel, inProgress, currentStatus, errorMessage } = this.props;
    const { handleFileInputUpdate, handleTextInputUpdate, handleContinue } = this;
    return (
      <div className="modal-blocker">
        <div className="modal">
          <form>
            <div className="modal-menu">
              <button type="button" className="action" onClick={() => cancel()}>
                X
              </button>
            </div>
            <div className="modal-title">
              <h2>Request Extension</h2>
            </div>
            <div className="modal-body">
              <label>
                Justification
                <textarea
                  autoFocus
                  type="text"
                  required
                  className={showValidation && !request ? "invalid" : ""}
                  value={request}
                  onChange={e => handleTextInputUpdate(e, "request")}
                />
              </label>
              {(currentStatus == applicationStatusEnum.registered || currentStatus == applicationStatusEnum.approved) &&
              <label>
                Please upload proof of Progress
                <label className="file-upload-wrapper">
                  <div className="icon icon-image-upload"></div>
                  <p>
                    {!this.state.fileName
                      ? "Tap to select a file to upload to this application."
                      : this.state.fileName}
                  </p>
                  <input
                    autoFocus
                    required
                    name="file"
                    id="file"
                    className="inputfile"
                    type="file"
                    onChange={e => handleFileInputUpdate(e)}
                  />
                </label>
              </label>
              }
            </div>
            <div className="modal-buttons">
              <button
                type="button"
                className="action"
                disabled={inProgress}
                onClick={() => handleContinue()}
              >
                Save
              </button>
              <button
                type="button"
                className="action negative"
                disabled={inProgress}
                onClick={() => cancel()}
              >
                Cancel
              </button>
            </div>
            <div className="modal-error-message">
              {errorMessage}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SaveExtensionRequestModal;
