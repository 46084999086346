import {
  CONNECT_GETTASKS_REQUEST,
  CONNECT_GETTASKS_SUCCESS,
  CONNECT_GETTASKS_FAILURE,
  CONNECT_COMPLETETASK_REQUEST,
  CONNECT_COMPLETETASK_SUCCESS,
  CONNECT_COMPLETETASK_FAILURE,
  CONNECT_GETTASKLIST_REQUEST,
  CONNECT_GETTASKLIST_SUCCESS, 
  CONNECT_GETTASKLIST_FAILURE,
  CONNECT_GETEDITTASK_REQUEST, 
  CONNECT_GETEDITTASK_SUCCESS, 
  CONNECT_GETEDITTASK_FAILURE, 
  CONNECT_SAVETASK_REQUEST,
  CONNECT_SAVETASK_SUCCESS,
  CONNECT_SAVETASK_FAILURE,
  CONNECT_DELETETASK_REQUEST,
  CONNECT_DELETETASK_SUCCESS,
  CONNECT_DELETETASK_FAILURE,
} from './actionTypes';

export const connectTaskActions = {
  requestTasks: (maxRecords, since) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_GETTASKS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Connect/TaskDetail/v1/GetUserTasks?maxRecords=${
          maxRecords ? maxRecords : ''
        }&since=${since ? since.toISOString() : ''}`,
        successAction: CONNECT_GETTASKS_SUCCESS,
        failureAction: CONNECT_GETTASKS_FAILURE,
      },
      payload: {
        baseline: !since,
      },
    });
  },
  completeTask: (platformNotificationType, type, popupId) => (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: CONNECT_COMPLETETASK_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Connect/TaskDetail/v1/CompleteTask',
        successAction: CONNECT_COMPLETETASK_SUCCESS,
        failureAction: CONNECT_COMPLETETASK_FAILURE,
        body: {
          platformNotificationType,
          popupId,
          type,
        },
      },
    });
  },
  requestTaskList: (maxRecords) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_GETTASKLIST_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Connect/TaskDetail/v1/GetAllTasks?&maxRecords=${maxRecords}`,
        successAction: CONNECT_GETTASKLIST_SUCCESS,
        failureAction: CONNECT_GETTASKLIST_FAILURE,
      },
    });
  },
  requestEditableTask: taskDetailId => (dispatch, getState) => {
    dispatch({
      type: CONNECT_GETEDITTASK_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Connect/TaskDetail/v1/GetTask/${taskDetailId}`,
        successAction: CONNECT_GETEDITTASK_SUCCESS,
        failureAction: CONNECT_GETEDITTASK_FAILURE,
      },
    });
  },
  saveTask: taskDetail => (dispatch, getState) => {
    dispatch({
      type: CONNECT_SAVETASK_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Connect/TaskDetail/v1/save',
        successAction: CONNECT_SAVETASK_SUCCESS,
        failureAction: CONNECT_SAVETASK_FAILURE,
        body: {
          ...taskDetail,
        },
      },
    });
  },
  deleteTask: taskDetailId => (dispatch, getState) => {
    dispatch({
      type: CONNECT_DELETETASK_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: `/api/Connect/TaskDetail/v1/delete/${taskDetailId}`,
        successAction: CONNECT_DELETETASK_SUCCESS,
        failureAction: CONNECT_DELETETASK_FAILURE,
      },
    });
  },
};
