import {
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_FAILURE,
  SITEID_NOTIFICATIONS_SUCCESS,
  ACKNOTIFICATION_REQUEST,
  ACKNOTIFICATION_SUCCESS,
  ACKNOTIFICATION_FAILURE,
  ALLACKNOTIFICATION_REQUEST,
  ALLACKNOTIFICATION_SUCCESS,
  ALLACKNOTIFICATION_FAILURE,
  ALLACKSITEIDNOTIFICATION_REQUEST,
  ALLACKSITEIDNOTIFICATION_SUCCESS,
  ALLACKSITEIDNOTIFICATION_FAILURE,
  ALERTS_REQUEST,
  ALERTS_SUCCESS,
  ALERTS_FAILURE,
  ALERT_REQUEST,
  ALERT_SUCCESS,
  ALERT_FAILURE,
  SAVEALERT_REQUEST,
  SAVEALERT_SUCCESS,
  SAVEALERT_FAILURE,
  DELETEALERT_REQUEST,
  DELETEALERT_SUCCESS,
  DELETEALERT_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const notificationReducer = (
  state = initialState.notificationState,
  action,
) => {
  switch (action.type) {
  case NOTIFICATIONS_REQUEST: {
    if (action.payload && action.payload.baseline) {
      return {
        ...state,
        isFetching: true,
        allPopups: [],
        alertPopups: [],
      };
    }

    return {
      ...state,
      isFetching: true,
    };
  }
  case NOTIFICATIONS_SUCCESS: {
    const alerts = action.payload.notifications.filter(
      alert => !alert.acknowledged && alert.notificationType === 1,
    );

    if (action.payload && action.payload.baseline) {
      return {
        ...state,
        isFetching: false,
        allPopups: [...action.payload.notifications],
        alertPopups: [...alerts],
      };
    } else {
      return {
        ...state,
        isFetching: false,
        allPopups: [...action.payload.notifications, ...state.allPopups],
        alertPopups: [...alerts, ...state.alertPopups],
      };
    }
  }
  case NOTIFICATIONS_FAILURE: {
    return {
      ...state,
      isFetching: false,
      errorMessage: action.payload.message,
    };
  }

  case SITEID_NOTIFICATIONS_SUCCESS: {
    
    if (action.payload && action.payload.baseline) {
      return {
        ...state,
        isFetching: false,
        siteIdAllPopups: action.payload.notifications,
        currentPage: action.payload.currentPage,
        totalRows: action.payload.totalRows,
        pageSize: action.payload.pageSize,
        companies: action.payload.companies,
        isLicensee: action.payload.isLicensee,
      };
    } else {
      return {
        ...state,
        isFetching: false,
        siteIdAllPopups: action.payload.notifications, ...state.siteIdAllPopups,
        currentPage: action.payload.currentPage,
        totalRows: action.payload.totalRows,
        pageSize: action.payload.pageSize,
        companies: action.payload.companies,
        isLicensee: action.payload.isLicensee,
      };
    }
  }
  case ACKNOTIFICATION_REQUEST: 
  case ALLACKNOTIFICATION_REQUEST:
  case ALLACKSITEIDNOTIFICATION_REQUEST:  {
    return {
      ...state,
      isFetching: true,
    };
  }
  
  case ACKNOTIFICATION_SUCCESS: {
    const allPopups = [...state.allPopups].map(p => {
      if (
        p.platformNotificationType ===
            action.payload.platformNotificationType &&
          p.recipientId === action.payload.popupId &&
          p.notificationType === action.payload.type
      ) {
        p.acknowledged = true;
      }
      return p;
    });
      
    const siteIdAllPopups = [...state.siteIdAllPopups].map(p => {
      if (
        p.platformNotificationType ===
            action.payload.platformNotificationType &&
          p.recipientId === action.payload.popupId &&
          p.notificationType === action.payload.type
      ) {
        p.acknowledged = true;
      }
      return p;
    });
    
    const alertPopups = [...state.alertPopups]
      .map(p => {
        if (
          p.platformNotificationType ===
              action.payload.platformNotificationType &&
            p.recipientId === action.payload.popupId &&
            p.notificationType === action.payload.type
        ) {
          return null;
        }
        return p;
      })
      .filter(p => p !== null);

    return {
      ...state,
      isFetching: false,
      allPopups,
      alertPopups,
      siteIdAllPopups,
    };
  }

  case ALLACKNOTIFICATION_SUCCESS: {
    const allPopups = [...state.allPopups].map(p => {
   
      p.acknowledged = true;
     
      return p;
    });
    
    const alertPopups = [];

    return {
      ...state,
      isFetching: false,
      allPopups,
      alertPopups,
    };
  }
      
  case ALLACKSITEIDNOTIFICATION_SUCCESS: {
    const siteIdAllPopups = [...state.siteIdAllPopups].map(p => {
   
      p.acknowledged = true;
     
      return p;
    });
    
    const alertPopups = [];

    return {
      ...state,
      isFetching: false,
      siteIdAllPopups,
      alertPopups,
    };
  }

  case ACKNOTIFICATION_FAILURE: 
  case ALLACKNOTIFICATION_FAILURE:
  case ALLACKSITEIDNOTIFICATION_FAILURE:  {
    return {
      ...state,
      isFetching: false,
      errorMessage: action.payload.message,
    };
  }

  case ALERTS_REQUEST:
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      errorMessage: '',
      adminAlerts: [],
      currentAdminAlert: {},
    };
  case ALERTS_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      errorMessage: '',
      adminAlerts: action.payload,
    };
  case ALERTS_FAILURE:
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      errorMessage: action.payload.message,
      adminAlerts: [],
      currentAdminAlert: {},
    };
  case ALERT_REQUEST:
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      errorMessage: '',
      currentAdminAlert: {},
    };
  case ALERT_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      errorMessage: '',
      currentAdminAlert: action.payload,
    };
  case ALERT_FAILURE:
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      errorMessage: action.payload.message,
      currentAdminAlert: {},
    };
  case SAVEALERT_REQUEST:
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      errorMessage: '',
    };
  case SAVEALERT_SUCCESS:
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      currentAdminAlert: {
        ...state.currentAdminAlert,
        notification: {
          ...state.currentAdminAlert.notification,
          notificationId: action.payload,
        },
      },
      errorMessage: '',
    };
  case SAVEALERT_FAILURE:
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      errorMessage: action.payload.message,
    };
  case DELETEALERT_REQUEST:
    return {
      ...state,
      isDeleting: true,
      isDeleted: false,
      errorMessage: '',
    };
  case DELETEALERT_SUCCESS:
    return {
      ...state,
      isDeleting: false,
      isDeleted: true,
      errorMessage: '',
    };
  case DELETEALERT_FAILURE:
    return {
      ...state,
      isDeleting: false,
      isDeleted: false,
      errorMessage: action.payload.message,
    };

  default:
    return state;
  }
};
export default notificationReducer;
