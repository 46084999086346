import React from 'react';

const AssetCategory = props => {
  const asset = props.data;
  const { onView } = props;
  const iconClass = asset.iconType === 1 ? asset.iconData.toLowerCase() : '';

  return (
    <div className={`asset-container ${props.selected ? 'selected' : ''}`}>
      <button 
        type="button" 
        onClick={e => {
          onView();
          e.preventDefault();
          e.stopPropagation();
        }} 
        className="asset-content">
        <span className={`icon ${iconClass ? iconClass : 'icon-folder'}`} />
        <span className="asset-name">{asset.name}</span>
      </button>
    </div>
  );
};

export default AssetCategory;
