import {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAILURE,
  USERID_REQUEST,
  USERID_SUCCESS,
  USERID_FAILURE,
  SAVEUSER_REQUEST,
  SAVEUSER_SUCCESS,
  SAVEUSER_FAILURE,
  DELETEUSER_REQUEST,
  DELETEUSER_SUCCESS,
  DELETEUSER_FAILURE,
  MODULEGROUPS_REQUEST,
  MODULEGROUPS_SUCCESS,
  MODULEGROUPS_FAILURE,
  MODULEGROUPID_REQUEST,
  MODULEGROUPID_SUCCESS,
  MODULEGROUPID_FAILURE,
  SAVEMODULEGROUP_REQUEST,
  SAVEMODULEGROUP_SUCCESS,
  SAVEMODULEGROUP_FAILURE,
  DELETEMODULEGROUP_REQUEST,
  DELETEMODULEGROUP_SUCCESS,
  DELETEMODULEGROUP_FAILURE,
  STOREGROUPS_REQUEST,
  STOREGROUPS_SUCCESS,
  STOREGROUPS_FAILURE,
  STOREGROUPID_REQUEST,
  STOREGROUPID_SUCCESS,
  STOREGROUPID_FAILURE,
  SAVESTOREGROUP_REQUEST,
  SAVESTOREGROUP_SUCCESS,
  SAVESTOREGROUP_FAILURE,
  DELETESTOREGROUP_REQUEST,
  DELETESTOREGROUP_SUCCESS,
  DELETESTOREGROUP_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const userManagementReducer = (
  state = initialState.userManagementState,
  action,
) => {
  switch (action.type) {
  case USERS_REQUEST:
    return {
      ...state,
      users: {
        isFetching: true,
        isLoaded: false,
        isDeleting: false,
        isDeleted: false,
        userList: [],
        errorMessage: '',
      },
    };
  case USERS_SUCCESS:
    return {
      ...state,
      users: {
        isFetching: false,
        isLoaded: true,
        userList: action.payload.data,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalRecords: action.payload.totalRecords,
        errorMessage: '',
      },
    };
  case USERS_FAILURE:
    return {
      ...state,
      users: {
        isFetching: false,
        isLoaded: false,
        userList: [],
        errorMessage: action.payload.message,
      },
    };
  case USERID_REQUEST:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        isFetching: true,
        isLoaded: false,
        isSaving: false,
        isSaved: false,
        isDeleting: false,
        isDeleted: false,
        userDetails: {},
        errorMessage: '',
      },
    };
  case USERID_SUCCESS:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        isFetching: false,
        isLoaded: true,
        isSaving: false,
        isSaved: false,
        userDetails: action.payload,
        errorMessage: '',
      },
    };
  case USERID_FAILURE:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        isFetching: false,
        isLoaded: false,
        isSaving: false,
        isSaved: false,
        userDetails: {},
        errorMessage: action.payload.message,
      },
    };
  case SAVEUSER_REQUEST:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        isSaving: true,
        isSaved: false,
        errorMessage: '',
      },
    };
  case SAVEUSER_SUCCESS:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        isSaving: false,
        isSaved: true,
        userDetails: {
          ...state.currentUser.userDetails,
          user: {
            ...state.currentUser.userDetails.user,
            userId: action.payload,
          },
        },
        errorMessage: '',
      },
    };
  case SAVEUSER_FAILURE:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        isSaving: false,
        isSaved: false,
        errorMessage: action.payload.message,
      },
    };
  case DELETEUSER_REQUEST:
    return {
      ...state,
      users: {
        ...state.users,
        isDeleting: true,
        isDeleted: false,
        errorMessage: '',
      },
      currentUser: {
        ...state.currentUser,
        isDeleting: true,
        isDeleted: false,
        userDetails: {},
        errorMessage: '',
      },
    };
  case DELETEUSER_SUCCESS:
    return {
      ...state,
      users: {
        ...state.users,
        isDeleting: false,
        isDeleted: true,
        errorMessage: '',
      },
      currentUser: {
        ...state.currentUser,
        isDeleting: false,
        isDeleted: true,
        userDetails: {},
        errorMessage: '',
      },
    };
  case DELETEUSER_FAILURE:
    return {
      ...state,
      users: {
        ...state.users,
        isDeleting: false,
        isDeleted: false,
        errorMessage: action.payload.message,
      },
      currentUser: {
        ...state.currentUser,
        isDeleting: false,
        isDeleted: false,
        userDetails: {},
        errorMessage: action.payload.message,
      },
    };
  case MODULEGROUPS_REQUEST:
    return {
      ...state,
      moduleGroups: {
        isFetching: true,
        isLoaded: false,
        groupList: [],
        errorMessage: '',
      },
    };
  case MODULEGROUPS_SUCCESS:
    return {
      ...state,
      moduleGroups: {
        isFetching: false,
        isLoaded: true,
        groupList: action.payload,
        errorMessage: '',
      },
    };
  case MODULEGROUPS_FAILURE:
    return {
      ...state,
      moduleGroups: {
        isFetching: false,
        isLoaded: false,
        groupList: [],
        errorMessage: action.payload.message,
      },
    };
  case MODULEGROUPID_REQUEST:
    return {
      ...state,
      currentModuleGroup: {
        ...state.currentModuleGroup,
        isFetching: true,
        isLoaded: false,
        isSaving: false,
        isSaved: false,
        groupDetails: {},
        errorMessage: '',
      },
    };
  case MODULEGROUPID_SUCCESS:
    return {
      ...state,
      currentModuleGroup: {
        ...state.currentModuleGroup,
        isFetching: false,
        isLoaded: true,
        isSaving: false,
        isSaved: false,
        groupDetails: action.payload,
        errorMessage: '',
      },
    };
  case MODULEGROUPID_FAILURE:
    return {
      ...state,
      currentModuleGroup: {
        ...state.currentModuleGroup,
        isFetching: false,
        isLoaded: false,
        isSaving: false,
        isSaved: false,
        groupDetails: {},
        errorMessage: action.payload.message,
      },
    };
  case SAVEMODULEGROUP_REQUEST:
    return {
      ...state,
      currentModuleGroup: {
        ...state.currentModuleGroup,
        isSaving: true,
        isSaved: false,
        errorMessage: '',
      },
    };
  case SAVEMODULEGROUP_SUCCESS:
    return {
      ...state,
      currentModuleGroup: {
        ...state.currentModuleGroup,
        isSaving: false,
        isSaved: true,
        groupDetails: {
          ...state.currentModuleGroup.groupDetails,
          moduleGroup: {
            ...state.currentModuleGroup.groupDetails.moduleGroup,
            moduleGroupId: action.payload,
          },
        },
        errorMessage: '',
      },
    };
  case SAVEMODULEGROUP_FAILURE:
    return {
      ...state,
      currentModuleGroup: {
        ...state.currentModuleGroup,
        isSaving: false,
        isSaved: false,
        errorMessage: action.payload.message,
      },
    };
  case DELETEMODULEGROUP_REQUEST:
    return {
      ...state,
      moduleGroups: {
        ...state.moduleGroups,
        isDeleting: true,
        isDeleted: false,
        errorMessage: '',
      },
      currentModuleGroup: {
        ...state.currentModuleGroup,
        isDeleting: true,
        isDeleted: false,
        userDetails: {},
        errorMessage: '',
      },
    };
  case DELETEMODULEGROUP_SUCCESS:
    return {
      ...state,
      moduleGroups: {
        ...state.moduleGroups,
        isDeleting: false,
        isDeleted: true,
        errorMessage: '',
      },
      currentModuleGroup: {
        ...state.currentModuleGroup,
        isDeleting: false,
        isDeleted: true,
        userDetails: {},
        errorMessage: '',
      },
    };
  case DELETEMODULEGROUP_FAILURE:
    return {
      ...state,
      moduleGroups: {
        ...state.moduleGroups,
        isDeleting: false,
        isDeleted: false,
        errorMessage: action.payload.message,
      },
      currentModuleGroup: {
        ...state.currentModuleGroup,
        isDeleting: false,
        isDeleted: false,
        userDetails: {},
        errorMessage: action.payload.message,
      },
    };
  case STOREGROUPS_REQUEST:
    return {
      ...state,
      storeGroups: {
        isFetching: true,
        isLoaded: false,
        groupList: [],
        errorMessage: '',
      },
    };
  case STOREGROUPS_SUCCESS:
    return {
      ...state,
      storeGroups: {
        isFetching: false,
        isLoaded: true,
        groupList: action.payload,
        errorMessage: '',
      },
    };
  case STOREGROUPS_FAILURE:
    return {
      ...state,
      storeGroups: {
        isFetching: false,
        isLoaded: false,
        groupList: [],
        errorMessage: action.payload.message,
      },
    };
  case STOREGROUPID_REQUEST:
    return {
      ...state,
      currentStoreGroup: {
        ...state.currentStoreGroup,
        isFetching: true,
        isLoaded: false,
        isSaving: false,
        isSaved: false,
        groupDetails: {},
        errorMessage: '',
      },
    };
  case STOREGROUPID_SUCCESS:
    return {
      ...state,
      currentStoreGroup: {
        ...state.currentStoreGroup,
        isFetching: false,
        isLoaded: true,
        isSaving: false,
        isSaved: false,
        groupDetails: action.payload,
        errorMessage: '',
      },
    };
  case STOREGROUPID_FAILURE:
    return {
      ...state,
      currentStoreGroup: {
        ...state.currentStoreGroup,
        isFetching: false,
        isLoaded: false,
        isSaving: false,
        isSaved: false,
        groupDetails: {},
        errorMessage: action.payload.message,
      },
    };
  case SAVESTOREGROUP_REQUEST:
    return {
      ...state,
      currentStoreGroup: {
        ...state.currentStoreGroup,
        isSaving: true,
        isSaved: false,
        errorMessage: '',
      },
    };
  case SAVESTOREGROUP_SUCCESS:
    return {
      ...state,
      currentStoreGroup: {
        ...state.currentStoreGroup,
        isSaving: false,
        isSaved: true,
        groupDetails: {
          ...state.currentStoreGroup.groupDetails,
          storeGroup: {
            ...state.currentStoreGroup.groupDetails.storeGroup,
            storeGroupId: action.payload,
          },
        },
        errorMessage: '',
      },
    };
  case SAVESTOREGROUP_FAILURE:
    return {
      ...state,
      currentStoreGroup: {
        ...state.currentStoreGroup,
        isSaving: false,
        isSaved: false,
        errorMessage: action.payload.message,
      },
    };
  case DELETESTOREGROUP_REQUEST:
    return {
      ...state,
      storeGroups: {
        ...state.storeGroups,
        isDeleting: true,
        isDeleted: false,
        errorMessage: '',
      },
      currentStoreGroup: {
        ...state.currentStoreGroup,
        isDeleting: true,
        isDeleted: false,
        userDetails: {},
        errorMessage: '',
      },
    };
  case DELETESTOREGROUP_SUCCESS:
    return {
      ...state,
      storeGroups: {
        ...state.storeGroups,
        isDeleting: false,
        isDeleted: true,
        errorMessage: '',
      },
      currentStoreGroup: {
        ...state.currentStoreGroup,
        isDeleting: false,
        isDeleted: true,
        userDetails: {},
        errorMessage: '',
      },
    };
  case DELETESTOREGROUP_FAILURE:
    return {
      ...state,
      storeGroups: {
        ...state.storeGroups,
        isDeleting: false,
        isDeleted: false,
        errorMessage: action.payload.message,
      },
      currentStoreGroup: {
        ...state.currentStoreGroup,
        isDeleting: false,
        isDeleted: false,
        userDetails: {},
        errorMessage: action.payload.message,
      },
    };
  default:
    return state;
  }
};
export default userManagementReducer;
