import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import './InFullDashboard.scss';
import kpiDashboardActions from '../../../actions/kpiDashboardActions';
import KpiFailureTable from '../KpiFailureTable';
import KpiFilter from '../KpiFilter';
import KpiPieChart from '../KpiPieChart';
import KpiBarChart from '../KpiBarChart';


const InFullDashboard = (props) => {
  const [filter, setFilter] = useState({
    months: [],
    weeks: [],
    customerGroup: [],
    store: [],
  });
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);

  const {
    kpiDashboard,
    failedItems,
    getInFullDashboard,
    getInFullDashboardFailedItems,
    getStoreGroups,
    getStores,
  } = props;

  //Fiscal year starts in October
  const calendarMonths = ['January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const calendarSortMonths = ['October', 'November', 'December', 'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August', 'September'];

  useEffect(() => {

    if (selectedMonth) {
      getInFullDashboardFailedItems({
        customerGroups: filter.customerGroup.map(item => item.value).join(',') || '',
        stores: filter.store.map(item => item.value).join(',') || '',
        month: (selectedMonth && selectedMonth.name) || '',
        week: (selectedWeek && selectedWeek.name) || '',
        group: '',
        subGroup: '',
        adminView: false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth, selectedWeek]);

  useEffect(() => {

    const lastSixMonths = getLastSixMonthsNames();

    if (lastSixMonths.length > 0) {
      setFilter({
        weeks: [],
        customerGroup: [],
        store: [],
        months: lastSixMonths.map(item => {
          return {
            label: item,
            value: item,
          };
        }),
      });
    }

    getInFullDashboard({
      customerGroups: '',
      stores: '',
      adminView: false,
      months: lastSixMonths.join(','),
      weeks: '',
    });
    getStoreGroups();
    getStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInFullDashboard, getStoreGroups, getStores]);

  useEffect(() => {
    if (kpiDashboard
      && kpiDashboard.inFullDashboard
      && kpiDashboard.inFullDashboard.breakdowns
      && kpiDashboard.inFullDashboard.breakdowns.length > 0) {

      const breakdowns = kpiDashboard.inFullDashboard.breakdowns;
      const today = new Date();
      const currentMonth = today.getMonth();

      if (filter.weeks && filter.weeks.length & filter.weeks.length === 1) {
        var selectedWeek = filter.weeks[0].value;

        for (let month of breakdowns) {
          for (let week of month.breakdowns) {
            if (week.name === selectedWeek) {


              setSelectedWeek({ ...week, label: `w/c: ${new Date(week.startDate).toLocaleDateString()} / W${week.name.substring(week.name.length - 2)}` });
              setSelectedMonth({ ...month, label: month.name.substring(0, month.name.length - 4) + 'FY ' + month.name.substring(month.name.length - 4) });

              break;
            }
          }
        }

      } else {
        // Set current month to last active month by default.
        let currentMonthFound = null;
        for (let i = (currentMonth); i > (currentMonth - 6); i--) {
          let currentMonthName = i > -1 ? calendarMonths[i] : calendarMonths[(12 - Math.abs(i))];

          if (currentMonthFound !== null) {
            break;
          }

          for (let month of breakdowns) {
            if (month.name.indexOf(currentMonthName) > -1) {
              currentMonthFound = month;
              break;
            }
          }
        }
        if(currentMonthFound){
          setSelectedMonth({ ...currentMonthFound, label: currentMonthFound.name.substring(0, currentMonthFound.name.length - 4) + 'FY ' + currentMonthFound.name.substring(currentMonthFound.name.length - 4) });
        }else{
          setSelectedMonth(null);
        }
        setSelectedWeek(null);
      }
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpiDashboard.inFullDashboard]);

  const getLastSixMonthsNames = () => {
    let result = [];
    const today = new Date();
    let currentYear = today.getFullYear();
    const currentMonth = today.getMonth();

    //Year has to start in October based on fiscal.
    if (currentMonth >= 9) {
      currentYear = currentYear + 1;
    }

    for (let i = (currentMonth - 5); i <= currentMonth; i++) {
      let calendarMonth = '';
      let calendarYear = currentYear;

      if (i > -1) {
        calendarMonth = calendarMonths[i];
      } else {
        calendarMonth = calendarMonths[(12 - Math.abs(i))];
      }

      if (currentMonth >= 9) {
        if (i < 9) {
          calendarYear = currentYear - 1;
        }
      } else if (i < 0) {
        if ((12 - Math.abs(i)) < 9) {
          calendarYear = currentYear - 1;
        }
      }

      result.push(`${calendarMonth} - ${calendarYear}`);
    }
    return result;
  };

  //create method to craete colour based on % value
  const getChartColour = (percentage, lowerThreshold, target) => {
    let chartColour = '';

    if (percentage < lowerThreshold) {
      chartColour = '#ed4646';
    }
    if (percentage >= lowerThreshold) {
      chartColour = '#faad00';
    }
    if (percentage >= target) {
      chartColour = '#0ed988';
    }
    return chartColour;
  };

  const getPieChartData = () => {
    let result = _.cloneDeep(props.kpiDashboard.inFullDashboard);
    if (result && result.breakdowns && result.breakdowns.length > 0) {
      result.breakdowns = result.breakdowns.map(item => {
        item.selected = false;
        if (selectedMonth && item.name.indexOf(selectedMonth.name) > -1) {
          item.selected = true;
        }

        item.label = item.name.substring(0, item.name.length - 4) + 'FY ' + item.name.substring(item.name.length - 4);
        item.percentage = { ...item.percentage, colour: getChartColour(item.percentage.value, getLowerThreshold(), getTarget()) };
        item.sortValue = item.name.substring(item.name.length - 4) +
          calendarSortMonths.indexOf(item.name.substring(0, item.name.length - 7))
            .toString()
            .padStart(2, '0');

        return item;
      });
    }

    return sortBreakdowns(result.breakdowns);
  };

  const sortBreakdowns = (breakdowns) => {
    if (breakdowns &&
      breakdowns.length &&
      breakdowns.length >= 1 &&
      breakdowns[0] &&
      breakdowns[0].sortValue) {

      return breakdowns.sort((a, b) => b.sortValue - a.sortValue);

    }

    return breakdowns;
  };

  const onPieChartClicked = (pieChart) => {
    setSelectedMonth({ ...pieChart, label: pieChart.name.substring(0, pieChart.name.length - 4) + 'FY ' + pieChart.name.substring(pieChart.name.length - 4) });
    setSelectedWeek(null);
  };


  const getTarget = () => {
    if (props.kpiDashboard &&
      props.kpiDashboard.inFullDashboard &&
      props.kpiDashboard.inFullDashboard.target) {
      return props.kpiDashboard.inFullDashboard.target;
    }

    return 100;
  };

  const getLowerThreshold = () => {
    if (props.kpiDashboard &&
      props.kpiDashboard.inFullDashboard &&
      props.kpiDashboard.inFullDashboard.lowerThreshold) {
      return props.kpiDashboard.inFullDashboard.lowerThreshold;
    }

    return 0;
  };

  const getBarChartData = () => {
    let result = {
      breakdowns: [],
    };
    if (selectedMonth) {
      result = selectedMonth;
    } else if (kpiDashboard
      && kpiDashboard.inFullDashboard
      && kpiDashboard.inFullDashboard.breakdowns
      && kpiDashboard.inFullDashboard.breakdowns.length > 0) {
      for (let month of kpiDashboard.inFullDashboard.breakdowns) {
        result.breakdowns.push(month);
      }
    }

    let breakdowns = result.breakdowns.map((data) => {
      return {
        name: data.name,
        label: `w/c: ${new Date(data.startDate).toLocaleDateString()} / W${data.name.substring(data.name.length - 2)}`,
        sortValue: new Date(data.startDate),
        percentage: {
          ...data.percentage,
          colour: getChartColour(data.percentage.value, getLowerThreshold(), getTarget()),
        },
        max: 100,
      };
    });
    return sortBreakdowns(breakdowns);
  };

  const getBarChartDescription = () => {
    if (selectedMonth) {
      return `Line Fill % in ${selectedMonth.label}`;
    }
    return 'Chart showing bars for previous 6 months';
  };

  const onBarchartClicked = (label) => {
    let result = props.kpiDashboard.inFullDashboard;
    let filteredData = getActiveDashboardData(_.cloneDeep(result), label);
    if (filteredData) {
      if (filteredData.name.indexOf('Week') > -1) {
        setSelectedWeek({ ...filteredData, label: `w/c: ${new Date(filteredData.startDate).toLocaleDateString()} / W${filteredData.name.substring(filteredData.name.length - 2)}` });
      } else {
        setSelectedMonth({ ...filteredData, label: filteredData.name.substring(0, filteredData.name.length - 4) + 'FY ' + filteredData.name.substring(filteredData.name.length - 4) });
      }
    }
  };

  const getFailureTableData = () => {
    let result = {
      title: '',
      data: [],
    };

    if (selectedWeek) {
      result = {
        title: selectedWeek.label,
        data: failedItems,
      };
    } else if (selectedMonth) {
      result = {
        title: selectedMonth.label,
        data: failedItems,
      };
    }

    return result;
  };

  const getFilterMonths = () => {
    if (!props.kpiDashboard || !props.kpiDashboard.inFullDashboard || !props.kpiDashboard.inFullDashboard.filters) {
      return null;
    }

    const filters = props.kpiDashboard.inFullDashboard.filters;

    const a = filters.flatMap((year, index) => {
      return year.subFilters.map((months) => {
        return {
          label:  months.filter.substring(0, months.filter.length - 4) + 'FY ' + months.filter.substring(months.filter.length - 4),
          value: `${months.filter}`,
          disabled: false,
        };
      });
    });
    return a;
  };

  const getFilterWeeks = () => {
    if (!props.kpiDashboard || !props.kpiDashboard.inFullDashboard || !props.kpiDashboard.inFullDashboard.filters) {
      return null;
    }

    const filters = props.kpiDashboard.inFullDashboard.filters;

    let filtered = filters.flatMap((year, index) => {
      return year.subFilters.flatMap((month) => {
        return month.subFilters.map((week) => {
          return {
            label: `${month.filter} - ${week.filter}`,
            value: `${week.filter}`,
            disabled: false,
          };
        });
      });
    });
    filtered = filtered.filter(item => {
      if (filter.months.length <= 0) {
        return true;
      }
      for (let currentMonth of filter.months) {
        if (item.label.indexOf(currentMonth.label) > -1) {
          return true;
        }
        continue;
      }
      return false;
    });
    return filtered;
  };

  const filterByCustomerGroups = (selected) => {
    if (props.kpiDashboard.storeGroups && props.kpiDashboard.storeGroups.length > 0) {
      props.kpiDashboard.storeGroups = props.kpiDashboard.storeGroups.map((item) => {
        if (selected.length < 6) {
          item.disabled = false;
        } else {
          const selectedItem = selected.find(group => group.label === item.label) || null;
          if (!selectedItem) {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
        }
        return item;
      });
    }
    setFilter({
      ...filter,
      customerGroup: selected,
    });

    getFilteredStores();
  };

  const filterByStores = (selected) => {
    setFilter({
      ...filter,
      store: selected,
    });
  };

  const filterByMonths = (selected) => {
    setFilter({
      ...filter,
      months: selected,
      weeks: [],
    });
  };

  const filterByWeeks = (selected) => {
    setFilter({
      ...filter,
      weeks: selected,
    });
  };

  const getFilteredStores = () => {
    let stores = _.cloneDeep(props.kpiDashboard.stores) || [];

    if (filter.customerGroup && filter.customerGroup.length > 0) {
      stores = stores.filter((store) => {
        for (let group of filter.customerGroup) {
          let groupStore = group.data.storeList.find((item) => item === store.value) || null;
          return groupStore !== null;
        }
        return false;
      });
    }
    return stores.filter((store, index) => index < 100);
  };

  const onGoBack = () => {
    if (selectedWeek) {
      setSelectedWeek(null);
    }
  };

  const getActiveDashboardData = (parentData, filterBy) => {
    if (!parentData || !parentData.breakdowns) {
      return null;
    }
    let result = null;
    for (let item of parentData.breakdowns) {
      if (item.name === filterBy) {
        result = item;
        break;
      } else if (!result) {
        result = getActiveDashboardData(item, filterBy);
      }
    }
    return result;
  };

  const doFilter = () => {
    if (filter.customerGroup.length <= 0
      && filter.store.length <= 0
      && filter.months.length <= 0
      && filter.weeks.length <= 0) {

      setSelectedMonth(null);
      setSelectedWeek(null);
      return;
    }
    props.getInFullDashboard({
      customerGroups: filter.customerGroup.map(item => item.value).join(','),
      stores: filter.store.map(item => item.value).join(','),
      months: filter.months.map(item => item.value).join(','),
      weeks: filter.weeks.map(item => item.value).join(','),
      adminView: false,
    });

    setSelectedMonth(null);
    setSelectedWeek(null);
  };

  const doClearFilter = () => {
    const lastSixMonths = getLastSixMonthsNames();

    if (lastSixMonths.length > 0) {
      setFilter({
        weeks: [],
        customerGroup: [],
        store: [],
        months: lastSixMonths.map(item => {
          return {
            label: item,
            value: item,
          };
        }),
      });
    }

    setSelectedMonth(null);
    setSelectedWeek(null);
    props.getInFullDashboard({
      customerGroups: '',
      stores: '',
      months: lastSixMonths.join(','),
      weeks: '',
      adminView: false,
    });
  };

  const doDataExport = () => {
    let wrapper = document.getElementById('inFullKPIDashboard');
    if (wrapper) {
      wrapper.className = 'in-full-dashboard export-mode';
    }
    html2canvas(wrapper).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
      pdf.save(`In Full Export_${new Date().toISOString()}.pdf`);
      if (wrapper) {
        wrapper.className = 'in-full-dashboard';
      }
    });
  };

  if (props.kpiDashboard.error) {
    return (
      <div className="in-full-dashboard">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <span className="icon icon-table" />
              <h2>In Full Dashboard</h2>
            </div>
          </div>
        </div>
      </div>
    );
  }

  var last6Months = getLastSixMonthsNames();

  return (
    <div id="inFullKPIDashboard"
      className="in-full-dashboard">
      {props.kpiDashboard.loading &&
        <div className="app-spinner">
          <span className="spinner" />
        </div>
      }
      <div className="floating-panel">
        <div className="floating-header">
          <div className="title-container">
            <div className="header-title">
              <span className="icon icon-table" />
              <h2>In Full Dashboard</h2>
              <div className="description">
                <p>In Full dashboard only includes item shipped from K&amp;N (Ambient Warehouse) within Starbucks Fiscal period.</p>

                <p>Line Fill % = Total Lines Complete / Total Lines Ordered.</p>

                <p>The dashboard will be updated once a week.</p>
              </div>
            </div>
            <div>
              {props.kpiDashboard.loading ?
                <div> loading... </div>
                :
                <div className="key" >
                  <div>Key:</div>
                  <div className="key-item"><div className="green"></div><span>{`${getTarget()}% and above`}</span></div>
                  <div className="key-item"><div className="amber"></div><span>{`${getTarget()}%-${getLowerThreshold()}%`}</span></div>
                  <div className="key-item"><div className="red"></div><span>{`${getLowerThreshold()}% and below`}</span></div>
                </div> }
            </div>
          </div>


          <div className="filter-wrapper">
            <KpiFilter
              months={getFilterMonths()}
              weeks={getFilterWeeks()}
              storeGroups={props.kpiDashboard.storeGroups}
              stores={getFilteredStores()}
              filter={filter}
              allowMultiSelect={false}
              onStoreGroupChange={filterByCustomerGroups}
              onStoreChange={filterByStores}
              onMonthChange={filterByMonths}
              onWeekChange={filterByWeeks}
            />
          </div>
          <div className="filter-action">
            <button
              type="button"
              className="action export"
              onClick={() => doDataExport()}>Export Data</button>
            <button
              type="button"
              className="action"
              onClick={() => doFilter()}
              disabled={(filter.customerGroup.length === 0 &&
                filter.store.length === 0 &&
                filter.months.length === 0 &&
                filter.weeks.length === 0)}
            >
              Search
            </button>
            {(filter.customerGroup.length > 0
              || filter.store.length > 0
              || filter.weeks.length > 0
              || filter.months.length !== last6Months.length
              || last6Months.some(c => filter.months.map((m => m.value)).indexOf(c) === -1)) &&
              <button
                type="button"
                className="action default"
                onClick={() => doClearFilter()}
              >
                Clear Filter
              </button>
            }
          </div>
        </div>
      </div>
      <div id="viewContent"
        className="view-content">
        {(filter.customerGroup.length > 0 || filter.store.length > 0 || filter.months.length > 0 || filter.weeks.length > 0) &&
          <div id="selectedFilter"
            className="selected-filter">
            {filter.customerGroup.length > 0 &&
              <div className="item">
                <label>Customer Groups:</label>
                <span>{filter.customerGroup.map(item => item.value).join(', ')}</span>
              </div>
            }
            {filter.store.length > 0 &&
              <div className="item">
                <label>Stores:</label>
                <span>{filter.store.map(item => item.value).join(', ')}</span>
              </div>
            }
            {filter.months.length > 0 &&
              <div className="item">
                <label>Months:</label>
                <span>{filter.months.map(item => item.value).join(', ')}</span>
              </div>
            }
            {filter.weeks.length > 0 &&
              <div className="item">
                <label>Weeks:</label>
                <span>{filter.weeks.map(item => item.value).join(', ')}</span>
              </div>
            }
          </div>
        }{(selectedWeek) &&
          <div id="backButton"
            className="breakdown-title">
            <button type="button"
              onClick={() => onGoBack()}>Return</button>
          </div>
        }
        <KpiPieChart
          pieChartData={getPieChartData()}
          onClickPieChart={(pieChart) => onPieChartClicked(pieChart)}
        />
        {getBarChartData() &&
          <KpiBarChart
            title={getBarChartDescription()}
            selectedWeek={selectedWeek}
            barChartData={getBarChartData()}
            onBarchartClicked={(label) => onBarchartClicked(label)}
            target={getTarget()}
          />
        }
        {getFailureTableData().title &&
          <div className="breakdown-title">
            <h3>{getFailureTableData().title} Failures</h3>
          </div>
        }
        <KpiFailureTable
          failureTableData={getFailureTableData().data}
          loading={props.kpiDashboard.loadingFailedItems}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    kpiDashboard: state.kpiDashboard,
    failedItems: state.kpiDashboard.failedItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearState: () => dispatch(kpiDashboardActions.clearState()),
    getInFullDashboard: (params) => dispatch(kpiDashboardActions.getInFullDashboard(params)),
    getInFullDashboardFailedItems: (params) => dispatch(kpiDashboardActions.getInFullDashboardFailedItems(params)),
    getStoreGroups: () => dispatch(kpiDashboardActions.getStoreGroups()),
    getStores: () => dispatch(kpiDashboardActions.getStores()),
    getImport: () => dispatch(kpiDashboardActions.getImport()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InFullDashboard);
