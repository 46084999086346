import React from 'react';

const File = props => {
  const file = props.data;
  const { onDeleteFile, OnEditFile, onView, OnMoveFile } = props;
  const iconClass = file.iconType === 1 ? file.iconData.toLowerCase() : '';

  return (
    <div className="file-container">
      <div className="file-content">
        <div className="file-icon-and-buttons">
          <div className="file-detail">
            <div
              className={`icon icon-file ${
                iconClass === 'icon-file' &&
                  file.description.indexOf('.', file.description.length - 5) > -1
                  ? file.description.substring(
                    file.description.indexOf(
                      '.',
                      file.description.length - 5,
                    ) + 1,
                  )
                  : ''
              }`}
            ></div>
            <div className="file-name">{file.title}</div>
          </div>
          <div className="button-container">
            {onView && (
              <button
                type="button"
                className="edit"
                onClick={e => {
                  onView();
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                DOWNLOAD
              </button>
            )}
            {OnEditFile && (
              <button
                type="button"
                className="edit"
                onClick={e => {
                  OnEditFile();
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                EDIT
              </button>
            )}
            {OnMoveFile && (
              <button
                type="button"
                className="edit"
                onClick={e => {
                  OnMoveFile();
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                MOVE
              </button>
            )}
            {onDeleteFile && (
              <button
                type="button"
                className="edit negative"
                onClick={e => {
                  onDeleteFile();
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                DELETE
              </button>
            )}

          </div>
        </div>
        <div className="file-footer">
          <span className="file-description">{file.description}</span>
          {file.size > 0 && (<span className="file-size">{file.size > 1024 ? `(${(file.size / 1024).toFixed(1)}Mb)` : `(${file.size}Kb)`} </span>)}
        </div>
      </div>
    </div>
  );
};

export default File;
