import { NOTIFICATIONS_REQUEST, LOGOUT_REQUEST } from '../actions/actionTypes';
import { notificationActions } from '../actions/notificationActions';

let refresh = undefined;
let lastChecked = undefined;

const notificationMiddleware = store => next => action => {
  switch (action.type) {
  case NOTIFICATIONS_REQUEST:
    lastChecked = new Date();


    if((action.payload.baseline && !refresh) ||
         !action.payload.baseline){
      //schedule next
      refresh = setTimeout(() => {
        store.dispatch(
          notificationActions.requestNotifications(null, lastChecked),
        );
      }, 300000);
    }

    return next(action);
  case LOGOUT_REQUEST:
    clearTimeout(refresh);
    return next(action);
  default:
    return next(action);
  }
};

export default notificationMiddleware;
