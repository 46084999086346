/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import KpiFilter from '../CustomFilters/KpiFilter';
import CustomBarChart from '../CustomCharts/CustomBarChart';
import CustomLineChart from '../CustomCharts/CustomLineChart';
import forecastPerformanceDashboardActions from '../../../actions/forecastPerformanceDashboardActions';
import './kpiMetrics.scss';
import { connect } from 'react-redux';
import { TimeFilterEnum } from '../Common/Enums/TimeFilterEnum';
import { doDataExport } from '../DemandAndSupply/CarouselCharts/CommonCarouselFunctions';
import Table from '../CustomTables/CustomTable';

const KpiMetrics = (props) => {
  const [marketFilter, setMarketFilter] = useState([]);
  const [supplierFilter, setSupplierFilter] = useState([]);
  const [orgFilter, setOrgFilter] = useState([]);
  const [reasonCodeFilter, setReasonCodeFilter] = useState([]);
  const [itemName, setItemName] = useState(null);
  const [periodFilter, setPeriodFilter] = useState(TimeFilterEnum.YTD);
  const [selectedMonths, setSelectedMonths] = useState([]);

  const tableHeaders = ['Market', 'Unfulfilled (%)'];
  const monthTableHeaders = ['Month', 'Current Year (%)', 'Previous Year (%)'];

  const bars = [
    { dataKey: 'Unfulfilled', strokeColor: '#8884d8' },
  ];

  const months = [
    { key: 10, value: 'October' },
    { key: 11, value: 'November' },
    { key: 12, value: 'December' },
    { key: 1, value: 'January' },
    { key: 2, value: 'February' },
    { key: 3, value: 'March' },
    { key: 4, value: 'April' },
    { key: 5, value: 'May' },
    { key: 6, value: 'June' },
    { key: 7, value: 'July' },
    { key: 8, value: 'August' },
    { key: 9, value: 'September' },
  ];

  const monthsArr = ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept'];

  const filters = [
    { key: 'Period', value: periodFilter },
    { key: 'Selected Months', value: selectedMonths.map(id => months.find(month => month.key === id)?.value).join(', ') },
    { key: 'Selected Markets', value: marketFilter.map(id => props.mbuData?.find(m => m.marketId === id)?.name).join(', ') },
    { key: 'ORGs', value: orgFilter.join(', ') },
    { key: 'Reason Codes', value: reasonCodeFilter.join(', ') },
    { key: 'Item Name', value: itemName },
    { key: 'Suppliers', value: supplierFilter.join(', ') },
  ];
  
  const monthlyBars = [
    { dataKey: 'CurrentYear', strokeColor: '#8884d8' },
    { dataKey: 'PreviousYear', strokeColor: '#82ca9d' },
  ];
  
  useEffect(() => {
    const timer = setTimeout(() => {
      props.getKpiChartData(periodFilter, selectedMonths, marketFilter, supplierFilter, orgFilter, reasonCodeFilter, itemName);
    }, 2000); 
  
    return () => clearTimeout(timer); 
  }, []);
  

  const onClick = (periodFilter, selectedMonths, marketFilter, supplierFilter, orgFilter, reasonCodeFilter, itemName) => {
    setPeriodFilter(periodFilter);
    setSelectedMonths(selectedMonths);
    setMarketFilter(marketFilter);
    setSupplierFilter(supplierFilter);
    setOrgFilter(orgFilter);
    setReasonCodeFilter(reasonCodeFilter);
    setItemName(itemName);
    props.getKpiChartData(periodFilter, selectedMonths, marketFilter, supplierFilter, orgFilter, reasonCodeFilter, itemName); 
  };

  let mbuKpiList = Array.isArray(props.mbuData) && props.mbuData.map(mbu => {
    let data = Array.isArray(props.kpiData.kpiMarkets) &&  props.kpiData.kpiMarkets.find(sd => sd.marketId === mbu.marketId);
    return {
      mbu: mbu.name.replace('Starbucks', ''),
      Unfulfilled: data ? data.unfulfilled : 0,
    };
  });

  let monthlyChartData = Array.isArray(monthsArr) && monthsArr.map(month => {
    let data = Array.isArray(props.kpiData.kpiMonths) && 
               props.kpiData.kpiMonths.find(sd => sd.month === month);
    return {
      month: month,
      CurrentYear: data ? data.currentYear : 0,
      PreviousYear: data ? data.previousYear : 0,
    };
  });

  function transposeData(data) {
    if (!Array.isArray(data)) {
      throw new TypeError('Data must be an array');
    }
    if (data.length === 0) {
      return [];
    }
    const keys = Object.keys(data[0]);
    if (!keys.every(key => data.every(item => key in item))) {
      throw new TypeError('All objects in data must have the same keys');
    }
    return keys.map(key => data.map(item => item[key]));
  }

  const handleExportSummary = () => {
    props.exportKpiMetrics(periodFilter, selectedMonths,marketFilter,supplierFilter,orgFilter,reasonCodeFilter,itemName);
  };


  return <>
    <div className="group-details accordion download-container summary forecast-export">
      <div className="accordion-header">
        <h3>Download Summary</h3>
      </div>
      <div className="accordion-body horizontal-flex order-fields">
        <div>
          <button
            className="action export"
            type="button"
            title="Export"
            disabled={false}
            onClick={() => handleExportSummary()}
          >
            <div
              className="icon icon-download-light"
              alt="Export"
            />
          </button>
        </div>
      </div>
    </div>
    <KpiFilter mbuList= {props.mbuData}
      orgs = {props.kpiReferenceData.orgs}
      suppliers = {props.kpiReferenceData.supplierNames}
      marketFilter={marketFilter}
      periodFilter={periodFilter}
      selectedMonths={selectedMonths}
      supplierFilter={supplierFilter}
      orgFilter={orgFilter}
      reasonCodeFilter={reasonCodeFilter}
      itemName={itemName}
      onClick={onClick}
    />

    <div id='kpi'
      className='kpi'>    
      <div className='kpi-chart-container'>
        <CustomBarChart data={mbuKpiList} 
          bars={bars} 
          chartTitle={'Number of orders based on filters'}
          dataKey={'mbu'}
          width={'100%'} 
          showsPercentage={true}/>
      </div>
      <div className='kpi-table-container'>
        <Table headers={tableHeaders} 
          transposed={true}
          data={transposeData(mbuKpiList)} />
      </div>

      <div className='kpi-chart-container'>
        <CustomLineChart data={monthlyChartData} 
          chartTitle={'Number of monthly orders based on filters'}
          lines={monthlyBars} 
          dataKey={'month'}
          xAxisFontSize={16}
          width={'100%'}
          showsPercentage={true} />
      </div>

      <div className='export-button-style'>
        <button type="button"
          className="action export"
          onClick={() => doDataExport('KPI', 'kpi', 'kpi', filters)}>Export Chart</button>
      </div>
    
      <div className='kpi-table-container'>
        <Table headers={monthTableHeaders} 
          transposed={true}
          data={transposeData(monthlyChartData)} />
      </div>
    </div>
  
    
  </>;
};


const mapStateToProps = (state) => {
  return {
    kpiData: state.forecastPerformanceDashboard.kpiData,
  };
};
 

const mapDispatchToProps = (dispatch) => {
  return {
    getKpiChartData: (timefilter, selectedMonths, mbuIds, suppliers, orgs, reasonCodes, itemName)  => 
      dispatch(forecastPerformanceDashboardActions.getKpiChartData(timefilter, selectedMonths,  mbuIds, suppliers, orgs, reasonCodes, itemName)),
    exportKpiMetrics : (timefilter, selectedMonths, mbuIds, suppliers, orgs, reasonCodes, itemName)  => 
      dispatch(forecastPerformanceDashboardActions.exportKpiMetrics(timefilter, selectedMonths, mbuIds, suppliers, orgs, reasonCodes, itemName)),
  };
};
 

export default connect(mapStateToProps, mapDispatchToProps)(KpiMetrics);
