import React from 'react';

class SaveExtensionResponseModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extensionId: -1,
      outcomeId: -1,
      reason: '',
      extendUntil: undefined,
      addedExtension: undefined,
      showValidation: false,
    };

    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleExtensionDateUpdate = this.handleExtensionDateUpdate.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentDidMount() {
    const { extension, extensionCount } = this.props;

    if (extension) {
      let extendUntil = new Date();

      if(extensionCount === 1){
        extendUntil.setDate(extendUntil.getDate() + (7 * 12));
      }else if (extensionCount === 2){
        extendUntil.setDate(extendUntil.getDate() + (7 * 8));
      }else{
        extendUntil.setDate(extendUntil.getDate() + (7 * 8));
      }

      this.setState({
        extensionId: extension.extensionId,
        extendUntil,
      });
    }
  }

  handleTextInputUpdate(e, fieldName) {
    this.setState({ [fieldName]: e.target.value });
  }

  handleExtensionDateUpdate(e) {
    const { longstop } = this.props;


    let newDate = new Date();

    let updateMode = e.target.value;

    if (updateMode === '1') {
      this.setState({ extendUntil: longstop });
      return;
    } else if (updateMode === '3') {
      newDate.setDate(newDate.getDate() + (12 * 7));
    } else if (updateMode === '5') {
      newDate.setDate(newDate.getDate() + (21 * 7));
    } else if (updateMode === '6') {
      newDate.setDate(newDate.getDate() + (26 * 7));
    } else if (updateMode === '7') {
      newDate.setDate(newDate.getDate() + (31 * 7));
    } else {
      return;
    }

    this.setState({ extendUntil: (newDate).toISOString().substr(0,10) });
  }

  handleContinue() {
    const { extensionId, outcomeId, reason, extendUntil } = this.state;

    if (!outcomeId || !reason || (outcomeId === '1' && !extendUntil)) {
      this.setState({ showValidation: true });
    } else {
      this.props.continue({
        extensionId,
        outcomeId,
        reason,
        extendUntil,
      });
    }
  }

  render() {
    const { outcomeId, reason, extendUntil, addedExtension, showValidation } = this.state;
    const { cancel, inProgress, extensionOutcomes, longstop } = this.props;
    const { handleTextInputUpdate, handleContinue, handleExtensionDateUpdate } = this;
    return (
      <div className="modal-blocker">
        <div className="modal">
          <form>
            <div className="modal-menu">
              <button type="button"
                className="action"
                onClick={() => cancel()}>
                X
              </button>
            </div>
            <div className="modal-title">
              <h2>Extension Review</h2>
            </div>
            <div className="modal-body">
              <label>
                Extension Outcome
                <select
                  autoFocus
                  required
                  className={showValidation && !outcomeId ? 'invalid' : ''}
                  value={outcomeId}
                  onChange={e => handleTextInputUpdate(e, 'outcomeId')}
                >
                  <option value="">Please Select</option>
                  {extensionOutcomes.map(e => (
                    <option key={e.key}
                      value={e.key}>
                      {e.value}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Justification
                <textarea
                  autoFocus
                  type="text"
                  required
                  className={showValidation && !reason ? 'invalid' : ''}
                  value={reason}
                  onChange={e => handleTextInputUpdate(e, 'reason')}
                />
              </label>
              {outcomeId === '1' && (<>
                <label>
                  Extension Options
                  <select    
                    value={addedExtension}
                    onChange={e => handleExtensionDateUpdate(e) }>
                    <option value="">Please Select</option>
                    <option value={3}>3 Months</option>
                    <option value={5}>5 Months</option>
                    <option value={6}>6 Months</option>
                    <option value={7}>7 Months</option>
                    {longstop ? <option value={1}>EMEA Long Stop</option> : null}
                  </select>
                </label>
                <label>
                  Extend Until
                  <input
                    autoFocus
                    type="date"
                    required
                    className={
                      showValidation && outcomeId === '1' && !extendUntil
                        ? 'invalid'
                        : ''
                    }
                    value={
                      extendUntil &&
                      new Date(extendUntil).toISOString().substr(0, 10)
                    }
                    onChange={e => handleTextInputUpdate(e, 'extendUntil')}
                  />
                </label></>
              )}
            </div>
            <div className="modal-buttons">
              <button
                type="button"
                className="action"
                disabled={inProgress}
                onClick={() => handleContinue()}
              >
                Save
              </button>
              <button
                type="button"
                className="action negative"
                disabled={inProgress}
                onClick={() => cancel()}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SaveExtensionResponseModal;
