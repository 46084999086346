import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { itemCatalogueActions } from '../../../actions/itemCatalogueActions';
import qs from 'query-string';
import StoreSelector from '../../shared/storeSelector';
import Pagination from '../../shared/Pagination';

class ItemCatalogue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'itemcatalogue',
      filterHazardous: null,
      storeNumber: '',
      filter: '',
      page: 1,
      pageSize: 25,
      displayMode: 1,
      status: '',
    };

    this.handleStoreChange = this.handleStoreChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.setDisplayMode = this.setDisplayMode.bind(this);

    this.doPaging = this.doPaging.bind(this);
    this.doSearch = this.doSearch.bind(this);

    this.navigateItemDetails = this.navigateItemDetails.bind(this);
  }

  handleStoreChange(storeNumber) {
    this.setState({ storeNumber });

    this.handleFilterChange({ ...this.state, storeNumber });
  }

  handleStatusChange(e) {
    this.setState({ status: e.target.value });
    this.handleFilterChange({ ...this.state, status: e.target.value });

    e.preventDefault();
  }

  componentDidMount() {
    const { stores } = this.props;
    const { moduleId } = this.props.match.params;
    const { pathname, search } = this.props.location;

    const hazardScreen = pathname.indexOf('hazard') > -1;

    let newState = {
      mode: hazardScreen ? 'hazard' : 'itemcatalogue',
      filterHazardous: hazardScreen ? 'true' : null,
      pageSize: 25,
      storeNumber: '',
      filter: '',
      page: 1,
      displayMode: 1,
      status: '',
    };

    let storeNumber = '';

    if (search) {
      const parsed = qs.parse(search.toLowerCase());

      storeNumber = parsed.storenumber ? parsed.storenumber : '';

      newState = {
        ...newState,
        storeNumber: storeNumber,
        filter: parsed.filter ? parsed.filter : '',
        page: parsed.page ? parseInt(parsed.page) : 1,
        displayMode: parsed.displaymode ? parseInt(parsed.displaymode) : 1,
        status: parsed.status ? parsed.status.toUpperCase() : '',
      };
    }

    if (!storeNumber && moduleId !== '303') {
      if (stores && stores.length > 0) {
        storeNumber = stores[0].storeNumber;
      }
    }

    newState.storeNumber = storeNumber;

    this.setState(newState);

    if (storeNumber || moduleId === '303') {
      this.props.getItems(
        newState.filterHazardous,
        newState.storeNumber,
        newState.filter,
        newState.page,
        newState.pageSize,
        newState.status,
      );
    }
  }

  handleSearch(e) {
    this.setState({ filter: e.target.value, page: 1 });
    e.preventDefault();
  }

  navigateItemDetails(storeNumber, itemId) {
    const { pathname } = this.props.location;
    this.props.push(`${pathname}/${storeNumber}?itemid=${itemId}`);
  }

  doSearch() {
    this.handleFilterChange({ ...this.state });
  }

  doPaging(page) {
    this.setState({ page });

    this.handleFilterChange({ ...this.state, page });
  }

  setDisplayMode(mode) {
    this.setState({ displayMode: mode });
  }

  handleFilterChange(currentState) {
    const { pathname } = this.props.location;
    const {
      filterHazardous,
      filter,
      storeNumber,
      displayMode,
      pageSize,
      status,
      page,
    } = currentState;

    this.props.replace(
      `${pathname}?storeNumber=${storeNumber}&filter=${filter}&page=${page}&displayMode=${displayMode}&status=${status}`,
    );

    this.props.getItems(
      filterHazardous,
      storeNumber,
      filter,
      page,
      pageSize,
      status,
    );
  }

  handleExport() {
    const {
      filterHazardous,
      filter,
      storeNumber,
      status,
    } = this.state;

    this.props.exportItems(
      filterHazardous,
      storeNumber,
      filter,
      status,
    );
  }

  render() {
    const { stores, rowCount, itemList } = this.props;
    const { moduleId } = this.props.match.params;
    const {
      storeNumber,
      mode,
      page,
      pageSize,
      filter,
      displayMode,
    } = this.state;
    const {
      handleStoreChange,
      setDisplayMode,
      handleSearch,
      doSearch,
      doPaging,
      navigateItemDetails,
    } = this;

    let storeId = storeNumber;
    
    if(moduleId === '303')
    {
      storeId = '-1';
    }
    
    return (
      <div className="item-catalogue-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div
                className={`icon ${
                  mode === 'itemcatalogue'
                    ? 'icon-item-catalogue'
                    : 'icon-hazardous'
                }`}></div>
              <h2>
                {mode === 'itemcatalogue'
                  ? 'Item Catalogue'
                  : 'Hazardous Materials'}
              </h2>
            </div>
            {moduleId !== '303'?
              <StoreSelector
                onStoreSelected={handleStoreChange}
                stores={stores || [] }
                storeNumber={storeNumber ? storeNumber.toString() : ''}
              />:null}
          </div>
        </div>
        <div className="item-catalogue-header page-header">
          <div></div>
          <div className="header-buttons">
            <div className="export">
             
              {moduleId !== '303'?
                <button
                  className="action export"
                  onClick={() => this.handleExport()}
                  type="button"
                  title="Export"
                >
                  <div
                    className="icon icon-download-light"
                    alt="Export"
                  />
                </button> :null}
            </div>
            <div className="search">
              <input
                type="text"
                placeholder="..."
                value={filter}
                onChange={e => handleSearch(e)}
              />
              <button type="button"
                className="action"
                onClick={() => doSearch()}>
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="item-catalogue-container table-container">
          <div className="table-header">
            <div className="display-options">
              Table Size
              <button
                type="button"
                className="icon-display-mode-1"
                disabled={displayMode === 1}
                onClick={() => setDisplayMode(1)}></button>
              <button
                type="button"
                className="icon-display-mode-2"
                disabled={displayMode === 2}
                onClick={() => setDisplayMode(2)}></button>
              <button
                type="button"
                className="icon-display-mode-3"
                disabled={displayMode === 3}
                onClick={() => setDisplayMode(3)}></button>
            </div>
            <h2>
              {mode === 'itemcatalogue'
                ? 'Item Information'
                : 'Hazardous Materials'}
            </h2>
            <div className="pagination-controls">
              <Pagination
                rowCount={rowCount}
                page={page}
                pageSize={pageSize}
                doPaging={doPaging}
              />
            </div>
          </div>
          <div className="table-body">
            <table className={`table-display-mode-${displayMode}`}>
              <thead>
                <tr>
                  <th>Item Number</th>
                  <th>Item Description</th>
                  {/*<th>Lifecycle Status</th>*/}
                  <th>Primary UOM</th>
                  {/*<th>UOM Conversions</th>
                  <th>Weights</th>
                  <th>Volume</th>
                  <th>Dimension</th>
                  <th>Hazard Class</th>
                  <th>Hazardous Material</th>
                  <th>Shelf Life</th>
                  <th>GTIN</th>
                  <th>Current Price</th>
                  <th>EUR Harmonized Tariff Code</th>
                  <th>Sourcing Classification</th>*/}
                  <th />
                </tr>
              </thead>
              <tbody>
                {itemList.map(item => (
                  <tr
                    key={item.itemId}>
                    <td>{item.itemNumber}</td>
                    <td>{item.itemDescription}</td>
                    <td>{item.primaryUom}</td>
                    <td className="right-cell">
                      <button
                        type="button"
                        className="action"
                        onClick={() =>
                          navigateItemDetails(storeId, item.itemId)
                        }
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="table-header">
            <div></div>
            <div className="pagination-controls">
              <Pagination
                rowCount={rowCount}
                page={page}
                pageSize={pageSize}
                doPaging={doPaging}
              />

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.itemCatalogue.isFetching,
  loaded: state.itemCatalogue.isLoaded,
  stores: state.auth.stores,
  itemList: state.itemCatalogue.itemList,
  pageCount: state.itemCatalogue.maxPages,
  rowCount: state.itemCatalogue.rowCount,
});

const mapDispatchToProps = dispatch => {
  return {
    getItems: (filterHazardous, storeNumber, filter, page, pageSize, status) =>
      dispatch(
        itemCatalogueActions.requestItems(
          filterHazardous,
          storeNumber,
          filter,
          page,
          pageSize,
          status,
        ),
      ),
    exportItems: (filterHazardous, storeNumber, filter, status) => 
      dispatch(
        itemCatalogueActions.exportItems(
          filterHazardous,
          storeNumber,
          filter,
          status,
        ),
      ),
    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemCatalogue);
