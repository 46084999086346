import React from 'react';
import { connect } from 'react-redux';

class StarRating extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { rating } = this.props;

    return (
      <div className="star-rating">
        <div className="template">
          <label className={'icon icon-star-empty'}></label>
          <label className={'icon icon-star-empty'}></label>
          <label className={'icon icon-star-empty'}></label>
          <label className={'icon icon-star-empty'}></label>
          <label className={'icon icon-star-empty'}></label>
        </div>
        <div className="score"
          style={{ width: (rating / 5) * 100 + '%' }}>
          <label className={'icon icon-star1'}></label>
          <label className={'icon icon-star2'}></label>
          <label className={'icon icon-star3'}></label>
          <label className={'icon icon-star4'}></label>
          <label className={'icon icon-star5'}></label>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StarRating);
