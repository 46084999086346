import React from 'react';

const Asset = props => {
  const asset = props.data;
  const { onView } = props;

  const getExtension = (filename) => {
    let extension = '';
    let dotPosition = filename.indexOf('.', filename.length - 5);

    if (dotPosition > -1) {

      extension = filename.substring(dotPosition + 1).toLowerCase();
    }

    return extension;
  };


  return (
    <div className={`asset-container ${props.selected ? 'selected' : ''}`}>
      <button 
        type="button"
        className="asset-content"
        onClick={() => onView()} >
        <span 
          className={`icon icon-file ${getExtension(asset.title)}`}
        />
        <span className="asset-name">{asset.title}</span>
      </button>
    </div>
  );
};

export default Asset;
