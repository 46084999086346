import React from 'react';
import { connect } from 'react-redux';
import File from '../../documents/shared/File';
import DeleteModal from '../../shared/DeleteModal';
import FileSaveModal from '../../documents/shared/FileSaveModal';
import { myDocumentActions } from '../../../actions/myDocumentActions';
import { specialActions } from '../../../actions/specialActions';

class DocumentArchive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storeNumber: 0,
      showUploadModal: false,
      showDeleteModal: false,
      selectedFileName: '',
    };

    this.handleDocumentDownload = this.handleDocumentDownload.bind(this);
    this.handleStoreChange = this.handleStoreChange.bind(this);

    this.handleUploadModalShow = this.handleUploadModalShow.bind(this);
    this.handleUploadModalHide = this.handleUploadModalHide.bind(this);
    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);

    this.deleteMyDocument = this.deleteMyDocument.bind(this);
    this.saveMyDocument = this.saveMyDocument.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.isSaved && !prevProps.isSaved) ||
      (this.props.isDeleted && !prevProps.isDeleted)
    ) {
      //this.props.getMyDocuments(this.state.storeNumber);
    }
  }

  handleStoreChange(storeNumber) {
    this.setState({ storeNumber });
    //this.props.getMyDocuments(storeNumber);
  }

  handleDocumentDownload(link) {
    this.props.downloadDocument(link);
  }

  handleUploadModalShow() {
    this.setState({ showUploadModal: true });
  }

  handleUploadModalHide() {
    this.setState({ showUploadModal: false });
  }

  handleDeleteModalShow() {
    this.setState({ showDeleteModal: true });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false });
  }

  handleDeleteMyDocument(doc) {
    this.setState({ showDeleteModal: true, selectedFileName: doc });
  }

  deleteMyDocument(doc) {
    const { storeNumber, selectedFileName } = this.state;
    this.props.deleteDocument({
      storeNumber,
      fileReference: selectedFileName,
    });

    this.handleDeleteModalHide();
  }

  saveMyDocument(doc) {
    const { storeNumber } = this.state;
    this.props.saveDocument({
      storeNumber,
      mediaData: doc.mediaData,
      mediaName: doc.mediaName,
      mediaType: doc.mediaType,
      description: doc.description,
    });

    this.handleUploadModalHide();
  }

  render() {
    const { myDocumentList, stores } = this.props;
    const { storeNumber, showUploadModal, showDeleteModal } = this.state;
    const {
      handleStoreChange,
      handleUploadModalShow,
      handleUploadModalHide,
      saveMyDocument,
      deleteMyDocument,
      handleDeleteModalHide,
    } = this;
    return (
      <div className="my-documents-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-document-libary"></div>
              <h2>Order Tracker Document Archive</h2>
            </div>
            {/* <button
              type="button"
              className="action"
              disabled={!storeNumber || storeNumber === -1}
              onClick={() => handleUploadModalShow()}>
              Upload Document
            </button>*/}
          </div>

          {/*<div className="header-actions">
            <StoreSelector
              onStoreSelected={handleStoreChange}
              stores={stores}
              storeNumber={storeNumber ? storeNumber.toString() : ''}
            />
          </div>*/}
          <p>
            Starbucks takes no responsibility for any consequences of error or
            for any loss or damage suffered by users of any of the information
            published in or use of this library. Starbucks may audit and/or
            delete documents in this library at any time which Starbucks
            determines should not be hosted on the portal.
          </p>
          <div className="my-documents-body">
            <div className="my-documents-holder">
              {myDocumentList && myDocumentList.length ? myDocumentList.map((document) => (
                <File
                  key={document.data}
                  data={document}
                  onView={() =>
                    this.handleDocumentDownload(
                      `/api/MyDocuments/v1/GetMyDocument?storeNumber=${storeNumber}&documentName=${document.data}`,
                    )
                  }
                  onDeleteFile={() =>
                    this.handleDeleteMyDocument(document.data)
                  }
                />
              )) : <div style={{ textAlign: 'center', width: '100%' }} >You do not currently have access to any orders.</div>}
            </div>
          </div>
          {showUploadModal && (
            <FileSaveModal
              cancel={handleUploadModalHide}
              continue={saveMyDocument}
            />
          )}
          {showDeleteModal && (
            <DeleteModal
              cancel={handleDeleteModalHide}
              continue={deleteMyDocument}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.myDocuments.isFetching,
  isLoaded: state.myDocuments.isLoaded,
  isSaved: state.myDocuments.isSaved,
  isDeleted: state.myDocuments.isDeleted,
  stores: state.auth.stores,
  myDocumentList: state.myDocuments.myDocumentList,
});

const mapDispatchToProps = dispatch => {
  return {
    getMyDocuments: storeNumber =>
      dispatch(myDocumentActions.requestMyDocuments(storeNumber)),
    downloadDocument: url => dispatch(specialActions.downloadFile(url)),
    saveDocument: doc => dispatch(myDocumentActions.saveMyDocument(doc)),
    deleteDocument: doc => dispatch(myDocumentActions.deleteMyDocument(doc)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentArchive);
