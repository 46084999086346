import {
  LSPOS_EMAILCONFIG_REQUEST,
  LSPOS_EMAILCONFIG_SUCCESS,
  LSPOS_EMAILCONFIG_FAILURE,
  LSPOS_EMAILCONFIG_SAVE_REQUEST,
  LSPOS_EMAILCONFIG_SAVE_SUCCESS,
  LSPOS_EMAILCONFIG_SAVE_FAILURE,
} from './actionTypes';

export const posItemEmailConfigActions = {
  getConfiguration: () => (dispatch) => {
    dispatch({
      type: LSPOS_EMAILCONFIG_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/lspos/emailConfiguration/v1/',
        successAction: LSPOS_EMAILCONFIG_SUCCESS,
        failureAction: LSPOS_EMAILCONFIG_FAILURE,
      },
    });
  },
  saveConfiguration: submitForm => (dispatch) => {
    dispatch({
      type: LSPOS_EMAILCONFIG_SAVE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/lspos/emailConfiguration/v1/',
        successAction: LSPOS_EMAILCONFIG_SAVE_SUCCESS,
        failureAction: LSPOS_EMAILCONFIG_SAVE_FAILURE,
        body: {
          ...submitForm,
        },
      },
    });
  }, 
};