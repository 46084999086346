import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { siteIDDefaultResponseActions } from '../../../actions/siteIDDefaultResponseActions';
import DeleteModal from '../../shared/DeleteModal';

class EditDefaultResponse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      title: '',
      response: '',
      applicableStatusId: null,
      rejection: false,

      showDeleteModal: false,

      showValidation: false,
    };

    this.handlesaveDefaultResponse = this.handlesaveDefaultResponse.bind(this);
    this.handledeleteDefaultResponse = this.handledeleteDefaultResponse.bind(this);

    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);

    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
  }

  componentDidMount() {
    const { getDefaultResponses, match } = this.props;
    const { responseId } = match.params;

    getDefaultResponses(responseId);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedResponse,
      isLoaded,
      isSaved,
      isDeleted,
      goBack,
    } = this.props;

    if (isLoaded && !prevProps.isLoaded && selectedResponse) {
      this.setState({
        id: selectedResponse.id,
        title: selectedResponse.title,
        response: selectedResponse.response,
        applicableStatusId: selectedResponse.applicableStatusId,
        rejection: selectedResponse.rejection,
      });
    } else if (isSaved && !prevProps.isSaved) {
      goBack();
    } else if (isDeleted && !prevProps.isDeleted) {
      goBack();
    }
  }

  handlesaveDefaultResponse(e) {
    const { saveDefaultResponse } = this.props;
    const {
      id,
      title,
      response,
      applicableStatusId,
      rejection,
    } = this.state;

    if (!title || !response) {
      this.setState({ showValidation: true });
    } else {
      saveDefaultResponse({
        id,
        title,
        response,
        applicableStatusId,
        rejection,
      });
    }

    e.preventDefault();
  }

  handleDeleteModalShow() {
    this.setState({ showDeleteModal: true });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false });
  }

  handledeleteDefaultResponse() {
    const { deleteDefaultResponse } = this.props;
    const { id } = this.state;

    deleteDefaultResponse(id);
    this.handleDeleteModalHide();
  }

  handleTextInputUpdate(e, fieldName) {
    const update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  handleCheckInputUpdate(e, fieldName) {
    const update = {};

    update[fieldName] = e.target.checked;

    this.setState(update);
  }

  handleDuplicate() {
    const { location, replace } = this.props;
    const { pathname } = location;
    this.setState({ id: null });

    let newPath = pathname.toLowerCase();

    const index = newPath.indexOf('siteiddefaultreponses');

    newPath = newPath.substring(0, index);

    replace(`${newPath}siteiddefaultreponses/0`);
  }

  getDefaultResponsesPanel() {
    const {
      title,
      response,
      applicableStatusId,
      rejection,
      showValidation,
    } = this.state;

    const {
      statuses,
    } = this.props;

    return (
      <div className="group-details accordion">
        <div
          className="accordion-header"
        >
          <h3>Response Details</h3>
        </div>
        <div
          className="accordion-body horizontal-flex user-fields"
        >
          <div className="user-field-block">
            <div>
              <label>
                Title
                <input
                  maxLength={256}
                  placeholder="Title"
                  required
                  className={
                    showValidation && !title
                      ? 'invalid'
                      : ''
                  }
                  onChange={(e) => this.handleTextInputUpdate(e, 'title')}
                  value={title}
                />
              </label>
              <label>
                Response
                <textarea
                  maxLength={2000}
                  placeholder="Response"
                  required
                  className={
                    showValidation && !response
                      ? 'invalid'
                      : ''
                  }
                  onChange={(e) => this.handleTextInputUpdate(e, 'response')}
                  value={response}
                />
              </label>
              <label>
                Applicable Status
                <select
                  placeholder="Applicable Status"
                  onChange={(e) => this.handleTextInputUpdate(e, 'applicableStatusId')}
                  value={applicableStatusId}
                >
                  <option value={null}>Any</option>
                  {statuses.map((s) => <option key={s.key}
                    value={s.key}>{s.value}</option>)}
                </select>
              </label>
              <label>
                Rejection Response
                <div className="toggle-wrapper">
                  <input
                    type="checkbox"
                    className="toggle"
                    onChange={(e) => this.handleCheckInputUpdate(e, 'rejection')}
                    checked={rejection}
                  />
                  <div className="toggle-icon" />
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { showDeleteModal, id } = this.state;
    const {
      isDeleting,
      isDeleted,
      isSaving,
      isSaved,
      error,
    } = this.props;
    return (
      <div className="edit-user-page page">
        <div className="floating-panel">
          <form noValidate
            onSubmit={(e) => this.handlesaveDefaultResponse(e)}>
            <div className="floating-header">
              <div className="header-title">
                <div className="icon icon-form" />
                {id ? (
                  <h2>Edit Response</h2>
                ) : (
                  <h2>Create Response</h2>
                )}
              </div>
              { id ? (
                <button
                  type="button"
                  className="action"
                  onClick={() => this.handleDuplicate()}
                >
                  Duplicate
                </button>
              ) : null}
            </div>

            {this.getDefaultResponsesPanel()}

            <p className="error">{error}</p>
            <div className="button-container">
              <button
                className="action"
                type="submit"
                disabled={isSaving}
              >
                {isSaving
                  ? 'Saving...'
                  : isSaved && !id
                    ? 'Saved'
                    : 'Save'}
              </button>
              {id && (
                <button
                  type="button"
                  className="action negative"
                  onClick={() => this.handleDeleteModalShow()}
                  disabled={isDeleting}
                >
                  {isDeleting
                    ? 'Deleting...'
                    : isDeleted
                      ? 'Deleted'
                      : 'Delete'}
                </button>
              )}
            </div>
          </form>
          {showDeleteModal && (
            <DeleteModal
              cancel={this.handleDeleteModalHide}
              continue={this.handledeleteDefaultResponse}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { siteIDDefaultResponses } = state;
  return {
    isLoading: siteIDDefaultResponses.isLoading,
    isLoaded: siteIDDefaultResponses.isLoaded,
    selectedResponse: siteIDDefaultResponses.selectedResponse,
    statuses: siteIDDefaultResponses.statuses,
    isSaving: siteIDDefaultResponses.isSaving,
    isSaved: siteIDDefaultResponses.isSaved,
    isDeleting: siteIDDefaultResponses.isDeleted,
    isDeleted: siteIDDefaultResponses.isDeleted,
    error: siteIDDefaultResponses.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDefaultResponses: (id) => dispatch(siteIDDefaultResponseActions.getDefaultResponse(id)),
  saveDefaultResponse: (response) => dispatch(
    siteIDDefaultResponseActions.saveDefaultResponse(response),
  ),
  deleteDefaultResponse: (id) => dispatch(siteIDDefaultResponseActions.deleteDefaultResponse(id)),

  push: (path) => dispatch(navigationActions.pushNavigation(path)),
  replace: (path) => dispatch(navigationActions.replaceNavigation(path)),
  reset: () => dispatch(navigationActions.resetNavigation()),
  goBack: () => dispatch(navigationActions.backNavigation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDefaultResponse);
