import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import Folder from '../shared/Folder';
import File from '../shared/File';
import DeleteModal from '../../shared/DeleteModal';
import FolderSaveModal from '../shared/FolderSaveModal';
import FileSaveModal from '../shared/FileSaveModal';
import FileMoveModal from '../shared/FileMoveModal';
import { documentActions } from '../../../actions/documentManagementActions';
import { specialActions } from '../../../actions/specialActions';

class DocumentManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rootModuleId: 350,
      moduleId: 350,
      breadcrumbs: [],
      showFolderCreateModal: false,
      showFolderUpdateModal: false,
      showFolderDeleteModal: false,
      showFileCreateModal: false,
      showFileUpdateModal: false,
      showFileDeleteModal: false,
      showFileMoveModal: false,
      selectedEntity: undefined,
    };

    this.handleDocumentDownload = this.handleDocumentDownload.bind(this);

    this.handleModalToggle = this.handleModalToggle.bind(this);
    this.handleModalAction = this.handleModalAction.bind(this);

    this.moveDocument = this.moveDocument.bind(this);

    this.saveFolder = this.saveFolder.bind(this);
    this.updateFolder = this.updateFolder.bind(this);
    this.deleteFolder = this.deleteFolder.bind(this);

    this.handleFolderDrillDown = this.handleFolderDrillDown.bind(this);
    this.handleFolderDrillUp = this.handleFolderDrillUp.bind(this);

    this.deleteDocument = this.deleteDocument.bind(this);
    this.updateDocument = this.updateDocument.bind(this);
    this.saveDocument = this.saveDocument.bind(this);
  }

  componentDidMount() {
    this.props.requestDocuments();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.isSaved && !prevProps.isSaved) ||
      (this.props.isDeleted && !prevProps.isDeleted)
    ) {
      this.props.requestDocuments();
    }
  }

  handleFolderDrillDown(moduleId, title) {
    var breadcrumbs = this.state.breadcrumbs;
    breadcrumbs.push({ moduleId, title });
    this.setState({
      moduleId,
      breadcrumbs,
    });
  }

  handleFolderDrillUp(moduleId) {
    if (moduleId === this.state.rootModuleId) {
      this.setState({
        moduleId: this.state.rootModuleId,
        breadcrumbs: [],
      });
    } else {
      var breadcrumbs = this.state.breadcrumbs;
      while (breadcrumbs[breadcrumbs.length - 1].moduleId !== moduleId) {
        breadcrumbs.pop();
      }
      this.setState({
        moduleId,
        breadcrumbs,
      });
    }
  }

  handleDocumentDownload(link) {
    this.props.downloadDocument(link);
  }

  handleModalToggle(modalFlag, show) {
    this.setState({ [modalFlag]: show });
  }

  handleModalAction(modalFlag, entity) {
    this.setState({ [modalFlag]: true, selectedEntity: entity });
  }

  deleteDocument() {
    const { moduleId, selectedEntity } = this.state;
    this.props.deleteDocument({
      moduleId,
      FileReference: selectedEntity.data,
    });

    this.setState({
      showFileDeleteModal: false,
    });
  }

  deleteFolder() {
    const { selectedEntity } = this.state;
    this.props.deleteFolder({
      moduleId: selectedEntity.moduleId,
    });

    this.setState({
      showFolderDeleteModal: false,
    });
  }

  saveFolder(doc) {
    const { moduleId } = this.state;
    this.props.saveFolder({
      parentModuleId: moduleId,
      folderName: doc.folderName,
      folderDescription: '',
    });

    this.setState({
      showFolderCreateModal: false,
      showFolderUpdateModal: false,
    });
  }

  updateFolder(doc) {
    const { moduleId, selectedEntity } = this.state;
    this.props.updateFolder({
      parentModuleId: moduleId,
      folderName: doc.folderName,
      folderDescription: '',
      moduleId: selectedEntity.moduleId,
    });

    this.setState({
      showFolderUpdateModal: false,
    });
  }

  moveDocument(doc) {
    const { moduleId, selectedEntity } = this.state;
    this.props.moveDocument({
      moduleId: doc.targetFolder,
      previousModuleId: moduleId,
      FileReference: selectedEntity.data,
    });

    this.setState({
      showFileMoveModal: false,
    });
  }

  updateDocument(doc) {
    const { moduleId, selectedEntity } = this.state;

    this.props.updateDocument({
      moduleId,
      FileReference: selectedEntity.data,

      mediaData: doc.mediaData,
      mediaName: doc.mediaName,
      mediaType: doc.mediaType,

      description: doc.description,
    });

    this.setState({
      showFileUpdateModal: false,
    });
  }

  saveDocument(doc) {
    const { moduleId } = this.state;

    this.props.saveDocument({
      moduleId,

      mediaData: doc.mediaData,
      mediaName: doc.mediaName,
      mediaType: doc.mediaType,

      description: doc.description,
    });

    this.setState({
      showFileCreateModal: false,
    });
  }

  render() {
    const { documentList, push } = this.props;
    const {
      moduleId,
      showFolderCreateModal,
      showFolderUpdateModal,
      showFolderDeleteModal,

      showFileCreateModal,
      showFileUpdateModal,
      showFileMoveModal,
      showFileDeleteModal,

      breadcrumbs,
      rootModuleId,

      selectedEntity,
    } = this.state;
    const {
      handleModalToggle,
      handleModalAction,

      handleDocumentDownload,
      saveDocument,
      updateDocument,
      moveDocument,
      deleteDocument,
      saveFolder,
      updateFolder,
      deleteFolder,

      handleFolderDrillUp,
      handleFolderDrillDown,
    } = this;
    return (
      <div className="documents-management-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="icon-title">
              <div className="icon icon-document-libary"></div>
              <h2>Document Management</h2>
            </div>
            <div className="header-actions">
              <button
                type="button"
                className="action"
                onClick={() => push('/module/651/editmodulegroups')}>
                Permissions
              </button>
            </div>
          </div>
          <div className="documents-management-body">
            <div className="documents-management-breadcrumbs">
              <div className="documents-management-breadcrumb">
                <h3>Document Library</h3>
                <div className="button-container">
                  <button
                    type="button"
                    className="edit view"
                    onClick={() => handleFolderDrillUp(rootModuleId)}>
                    Back
                  </button>
                  <button
                    type="button"
                    className="edit add-folder"
                    onClick={() => handleModalToggle('showFolderCreateModal', true)}>
                    Add Folder
                  </button>
                </div>
              </div>
              {breadcrumbs &&
                breadcrumbs.length > 0 &&
                breadcrumbs.map(b => (
                  <div
                    className="documents-management-breadcrumb"
                    key={b.moduleId}>
                    <h3>{b.title}</h3>
                    <div className="button-container">
                      <button
                        type="button"
                        className="edit view"
                        onClick={() => handleFolderDrillUp(b.moduleId)}>
                        Back
                      </button>
                      <button
                        type="button"
                        className="edit add-folder"
                        onClick={() => handleModalToggle('showFolderCreateModal', true)}>
                        Add Folder
                      </button>
                      <button
                        type="button"
                        className="edit upload"
                        onClick={() => handleModalToggle('showFileCreateModal', true)}>
                        Upload Document
                      </button>
                    </div>
                  </div>
                ))}
            </div>
            <div className="documents-management-holder">
              {documentList
                .filter(d => d.parentMenuId === this.state.moduleId)
                .map((document, index) => {
                  if (document.type === 5) {
                    return (
                      <Folder
                        key={document.moduleId}
                        data={document}
                        onView={() =>
                          handleFolderDrillDown(
                            document.moduleId,
                            document.title,
                          )
                        }
                        onEditFolder={() => {
                          handleModalAction('showFolderUpdateModal', document);
                        }}
                        onDeleteFolder={() =>
                          handleModalAction('showFolderDeleteModal', document)
                        }
                      />
                    );
                  } else {
                    return (
                      <File
                        key={document.data}
                        data={document}
                        onView={() =>
                          handleDocumentDownload(
                            `/api/Documents/v1/GetDocument?moduleId=${moduleId}&documentName=${document.data}`,
                          )
                        }
                        OnEditFile={() => {
                          handleModalAction('showFileUpdateModal', document);
                        }}
                        OnMoveFile={() => {
                          handleModalAction('showFileMoveModal', document);
                        }}
                        onDeleteFile={() =>
                          handleModalAction('showFileDeleteModal', document)
                        }
                      />
                    );
                  }
                })}
            </div>
          </div>
        </div>
        {showFileCreateModal && (
          <FileSaveModal
            cancel={() => handleModalToggle('showFileCreateModal', false)}
            continue={saveDocument} />
        )}
        {showFileUpdateModal && (
          <FileSaveModal
            description={selectedEntity.title}
            cancel={() => handleModalToggle('showFileUpdateModal', false)}
            continue={updateDocument} />
        )}
        {showFileMoveModal && (
          <FileMoveModal
            cancel={() => handleModalToggle('showFileMoveModal', false)}
            continue={moveDocument}
            folders={documentList.filter(d => d.type === 5 && d.moduleId !== moduleId)}
          />
        )}
        {showFileDeleteModal && (
          <DeleteModal
            cancel={() => handleModalToggle('showFileDeleteModal', false)}
            continue={deleteDocument}
          />
        )}
        {showFolderCreateModal && (
          <FolderSaveModal
            cancel={() => handleModalToggle('showFolderCreateModal', false)}
            continue={saveFolder}
          />
        )}
        {showFolderUpdateModal && (
          <FolderSaveModal
            folderName={selectedEntity.title}
            cancel={() => handleModalToggle('showFolderUpdateModal', false)}
            continue={updateFolder}
          />
        )}
        {showFolderDeleteModal && (
          <DeleteModal
            cancel={() => handleModalToggle('showFolderDeleteModal', false)}
            continue={deleteFolder}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.documentManagement.isFetching,
  isLoaded: state.documentManagement.isLoaded,
  isSaved: state.documentManagement.isSaved,
  isDeleted: state.documentManagement.isDeleted,
  documentList: state.documentManagement.documentList,
});

const mapDispatchToProps = dispatch => {
  return {
    requestDocuments: () => dispatch(documentActions.requestDocuments()),
    downloadDocument: url => dispatch(specialActions.downloadFile(url)),

    saveFolder: folder => dispatch(documentActions.saveFolder(folder)),
    updateFolder: folder => dispatch(documentActions.updateFolder(folder)),
    deleteFolder: folder => dispatch(documentActions.deleteFolder(folder)),

    saveDocument: doc => dispatch(documentActions.createDocument(doc)),
    updateDocument: doc => dispatch(documentActions.updateDocument(doc)),
    moveDocument: doc => dispatch(documentActions.moveDocument(doc)),
    deleteDocument: doc => dispatch(documentActions.deleteDocument(doc)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentManagement);
