import React from 'react';
import { connect } from 'react-redux';
import ApacTerms from '../../shared/termsAndConditions/apacTerms';
import EmeaTerms from '../../shared/termsAndConditions/emeaTerms';
import LacTerms from '../../shared/termsAndConditions/lacTerms';

class TermsAndConditionsPolicy extends React.Component {
  render() {
    const {
      tenant,
    } = this.props;

    return (
      <div className="terms-page page">
        <div className={'terms-content loaded'}>
          {tenant === 'EMEA' && <EmeaTerms />}
          {tenant === 'APAC' && <ApacTerms />}
          {tenant === 'LAC' && <LacTerms />}
        </div>
      </div>);
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tenant: state.auth.tenant,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TermsAndConditionsPolicy);
