import React, { useState, useEffect, useRef } from 'react';
import CsrFilters from './csrFilters/csrFilters';
import CsrResultsTable from './csrResultsTable/csrResultsTable';
import CsrSummaryMetricsPanel from './csrSummaryMetricsPanel/csrSummaryMetricsPanel';

function CsrDashboard(){
  const googleMapRef = useRef(null);
  const [googleMap, setGoogleMap] = useState(null);
  const [currentPort, setCurrentPort] = useState(null);
  const [filters, setFilters] = useState({
    orderDate : '',
    mbu: '',
    region: '',
    portOfDischarge: '',
    portOfLoading: '',
    rts: '',
    promiseDate: '',
    formRecieveDate: '',
    placeOfDelivery: '',
    etd: '',
    cob: '',
    eta: '',
    shipMode: '',
    promotion: 0,
    supplier: '',
    category: 0,
    eventCode: 0,
    freeText: '',
  });
  const [orders, setOrders] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [displayMode, setDisplay] = useState(1);
  const [filterData, setFilterData] = useState(null);
  const [locations, setLocations] = useState(null);
  const [completeData, setCompleteData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [locationType, setLocationType] = useState('mbu');
  
  useEffect(() => {
    getDetail();
  }, []);
  
  useEffect(() => {
    let googleMapScript = document.getElementById('googleMapScript');

    if (!googleMapScript) {
      googleMapScript = document.createElement('script');
      googleMapScript.id = 'googleMapScript';
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${'AIzaSyABhW4kbWko-Gdz5FijPKR158kBAVGJ3uQ'}&libraries=visualization`;
      window.document.body.appendChild(googleMapScript);
    
      googleMapScript.addEventListener('load', () => {
        createGoogleMap(5.428857, -238.607194, 3);
      });
    } else {
      createGoogleMap(5.428857, -238.607194, 3);
    }
  },[googleMapRef, locationType]);
  

  useEffect(() => {
    setInitialPort();
  }, [completeData]);

  useEffect(() => {
    if (locations && googleMap && filteredData){
      setMarkers(googleMap);
    }
  }, [googleMap, locations]);

  
  useEffect(() => {
    const newPort = {
      name: '', 
      shipmentCount: 0, 
      shipmentsDelayedCount: 0,
      shipmentsOnTimeCount: 0,
    };

    switch (locationType){
    case 'place of delivery':
      newPort.name =  filters.placeOfDelivery || 'Multiple';

      if(newPort.name != 'Multiple'){
        newPort.shipmentCount = filteredData.filter(x => x.placeOfDelivery?.toLowerCase() == newPort.name.toLowerCase()).length;
        newPort.shipmentsDelayedCount = filteredData.filter(x => x.placeOfDelivery?.toLowerCase() == newPort.name.toLowerCase() && x.daysDelayed > 0).length;
        newPort.shipmentsOnTimeCount = filteredData.filter(x => x.placeOfDelivery?.toLowerCase() == newPort.name.toLowerCase() && x.daysDelayed <= 0).length;
      }else{
        newPort.shipmentCount = filteredData.length;
        newPort.shipmentsDelayedCount = filteredData.filter(x => x.daysDelayed > 0).length;
        newPort.shipmentsOnTimeCount = filteredData.filter(x => x.daysDelayed <= 0).length;
      }

      break;
    case 'port of loading':
      newPort.name =  filters.portOfLoading || 'Multiple';

      if(newPort.name != 'Multiple'){
        newPort.shipmentCount = filteredData.filter(x => x.portOfLoading?.toLowerCase()  == newPort.name.toLowerCase()).length;
        newPort.shipmentsDelayedCount = filteredData.filter(x => x.portOfLoading?.toLowerCase()   == newPort.name.toLowerCase() && x.daysDelayed > 0).length;
        newPort.shipmentsOnTimeCount = filteredData.filter(x => x.portOfLoading?.toLowerCase()  == newPort.name.toLowerCase() && x.daysDelayed <= 0).length;
      }else{
        newPort.shipmentCount = filteredData.length;
        newPort.shipmentsDelayedCount = filteredData.filter(x => x.daysDelayed > 0).length;
        newPort.shipmentsOnTimeCount = filteredData.filter(x => x.daysDelayed <= 0).length;
      }
      break;
    case 'mbu':
      newPort.name =  filters.mbu || 'Multiple';

      if(newPort.name != 'Multiple'){
        newPort.shipmentCount = filteredData.filter(x => x.mbu?.toLowerCase()  == newPort.name.toLowerCase()).length;
        newPort.shipmentsDelayedCount = filteredData.filter(x => x.mbu?.toLowerCase()  == newPort.name.toLowerCase() && x.daysDelayed > 0).length;
        newPort.shipmentsOnTimeCount = filteredData.filter(x => x.mbu?.toLowerCase()  == newPort.name.toLowerCase() && x.daysDelayed <= 0).length;
      }else{
        newPort.shipmentCount = filteredData.length;
        newPort.shipmentsDelayedCount = filteredData.filter(x => x.daysDelayed > 0).length;
        newPort.shipmentsOnTimeCount = filteredData.filter(x => x.daysDelayed <= 0).length;
      }
      break;
    case 'port of discharge':
      newPort.name =  filters.portOfDischarge || 'Multiple';

      if(newPort.name != 'Multiple'){
        newPort.shipmentCount = filteredData.filter(x => x.portOfDischarge?.toLowerCase()  == newPort.name.toLowerCase()).length;
        newPort.shipmentsDelayedCount = filteredData.filter(x => x.portOfDischarge?.toLowerCase()  == newPort.name.toLowerCase() && x.daysDelayed > 0).length;
        newPort.shipmentsOnTimeCount = filteredData.filter(x => x.portOfDischarge?.toLowerCase()  == newPort.name.toLowerCase() && x.daysDelayed <= 0).length;
      }else{
        newPort.shipmentCount = filteredData.length;
        newPort.shipmentsDelayedCount = filteredData.filter(x => x.daysDelayed > 0).length;
        newPort.shipmentsOnTimeCount = filteredData.filter(x => x.daysDelayed <= 0).length;
      }
      break;
    default:

    }

    setCurrentPort(newPort);
  }, [locationType, filteredData]);

  useEffect(() => {
    setOrders(filteredData);    
  }, [filteredData]);

  useEffect(() => {
    handleFilter();
  }, [filters]);

  const getDetail = async() => {
    const response = await fetch('/api/csrshipment/v1/GetCsrShipmentDetail', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    let data = await response.json();

    if (data && data.filters){
      setFilterData(data.filters);
    }
    if (data && data.data){
      setCompleteData(data.data);
      setFilteredData(data.data);
      setOrders(data.data);
    }
    if (data && data.mapPins){
      setLocations(data.mapPins);
    }

    return data;
  };

  const setInitialPort= () => {
    const newPort = {
      name: 'Multiple', 
      shipmentCount: completeData.length, 
      shipmentsDelayedCount: completeData.filter(x => x.daysDelayed > 0).length,
      shipmentsOnTimeCount: completeData.filter(x => x.daysDelayed <= 0).length,
    };
    setCurrentPort(newPort);
  };

  const createGoogleMap = async(latitude, longitude, zoom) => {
    var map = new window.google.maps.Map(googleMapRef.current, {
      zoom,
      center: {
        lat: latitude,
        lng: longitude,
      },
      gestureHandling: 'cooperative',
      disableDefaultUI: true,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
      },
    });
    setGoogleMap(map);
  };

  const setMarkers = (map) => {
    const pins = locations.filter(x => x.type == locationType);

    const heatMapData = pins.map(d => {
      return {
        location: new window.google.maps.LatLng(d.latitude, d.longitude),
        weight: d.orders?.totalRecords,
      };
    });

    var heatmap = new window.google.maps.visualization.HeatmapLayer({
      data: heatMapData,
      radius: 50,
    });

    heatmap.setMap(map);

    pins.forEach(a => {
      var location = { lat: a.latitude, lng: a.longitude };
      var marker = new window.google.maps.Marker({
        position: location,
        icon: '/assets/map-icons/marker5.png',
        map : map,
      });

      marker.addListener('click', () => {
        map.setZoom(8);
        map.setCenter(marker.getPosition());

        switch (locationType){
        case 'place of delivery':
          setFilters({ ...filters, placeOfDelivery: a.name });
          break;
        case 'port of loading':
          setFilters({ ...filters, portOfLoading: a.name });
          break;
        case 'mbu':
          setFilters({ ...filters, mbu: a.name });
          break;
        case 'port of discharge':
          setFilters({ ...filters, portOfDischarge: a.name });
          break;
        }

      });

      marker.setMap(map);
    });
  };

  const setDisplayMode = async(mode) =>{
    setDisplay(mode);
  };

  const handleFilterInputUpdate = async(e, fieldName) => {
    setFilters({  ...filters, [fieldName]: e.target.value });
  };


  const handleFilterClear = async() => {
    const clearedState = {
      orderDate : '',
      mbu: '',
      region: '',
      portOfDischarge: '',
      portOfLoading: '',
      placeOfDelivery: '',
      rts: '',
      promiseDate: '',
      formRecieveDate: '',
      etd: '',
      cob: '',
      eta: '',
      shipMode: '',
      promotion: 0,
      supplier: '',
      category: 0,
      eventCode: 0,
      freeText: '',
    };

    setFilters(clearedState);
    setInitialPort();
    setFilteredData(completeData);
    setOrders(completeData);
    setPage(1);

    createGoogleMap(5.428857, -238.607194, 3);
  };  

  const handleFilter = () => {
    let filtered = completeData;

    if (filters.orderDate != ''){
      const dateStr = filters.orderDate;
      const [month, year] = dateStr.split('-');
      const startDate = `${year}-${month.padStart(2, '0')}-01`;
      console.log(startDate);
      const endDate = `${year}-${month.padStart(2, '0')}-31`;
      
      filtered = filtered.filter(x => x.orderDate >= startDate && x.orderDate < endDate);
    }
    if (filters.mbu != ''){
      filtered = filtered.filter(x => x.mbu?.toLowerCase() == filters.mbu.toLowerCase());
    }
    if (filters.region != ''){
      filtered = filtered.filter(x => x.region?.toLowerCase() == filters.region.toLowerCase());
    }
    if (filters.portOfDischarge != ''){
      filtered = filtered.filter(x => x.portOfDischarge?.toLowerCase() == filters.portOfDischarge.toLowerCase());
    }
    if (filters.portOfLoading != ''){
      filtered = filtered.filter(x => x.portOfLoading?.toLowerCase() == filters.portOfLoading.toLowerCase());
    }
    if (filters.placeOfDelivery != ''){
      filtered = filtered.filter(x => x.placeOfDelivery?.toLowerCase() == filters.placeOfDelivery.toLowerCase());
    }
    if (filters.rts != ''){
      filtered = filtered.filter(x => new Date(x.rts).toISOString().substring(0,10) == filters.rts);
    }
    if (filters.promiseDate != ''){
      filtered = filtered.filter(x => new Date(x.promiseDate).toISOString().substring(0,10)  == filters.promiseDate);
    }
    if (filters.formRecieveDate != ''){
      filtered = filtered.filter(x => new Date(x.formRecieveDate).toISOString().substring(0,10)  == filters.formRecieveDate);
    }
    if (filters.etd != ''){
      filtered = filtered.filter(x => new Date(x.etd).toISOString().substring(0,10)  == filters.etd);
    }
    if (filters.cob != ''){
      filtered = filtered.filter(x => new Date(x.cob).toISOString().substring(0,10) == filters.cob);
    }
    if (filters.eta != ''){
      filtered = filtered.filter(x => new Date(x.eta).toISOString().substring(0,10)  == filters.eta);
    }
    if (filters.freeText != ''){
      filtered = filtered.filter(x => x.soNo?.toLowerCase().includes(filters.freeText?.toLowerCase()) || x.poNo?.toLowerCase().includes(filters.freeText?.toLowerCase())
      || x.org?.toLowerCase().includes(filters.freeText?.toLowerCase()) || x.shipmentNo?.toLowerCase().includes(filters.freeText?.toLowerCase()));
    }
    if (filters.supplier != ''){
      filtered = filtered.filter(x => x.orderLines && x.orderLines?.some(x => x.supplierName?.toLowerCase() == filters.supplier?.toLowerCase()));
    }
    if (filters.shipMode != ''){
      filtered = filtered.filter(x => x.shipMode?.toLowerCase() == filters.shipMode?.toLowerCase());
    }
    if (filters.promotion > 0){
      if(filters.promotion == 1){
        filtered = filtered.filter(x => x.promotion == true);
      }
      else{
        filtered = filtered.filter(x => x.promotion == false);
      }
    }
    setFilteredData(filtered);
  };

  const doPaging = async(page) => {
    setPage(page);
  };

  return <div className="csr-dashboard-page page">
    <div className="floating-panel">
      <div className="floating-header">
        <div className="header-title">
          <div className="icon icon-globe-pin"></div>
          <div>
            <h2>CSR Shipment Dashboard</h2>
          </div>
        </div>
      </div>
    </div>
    <div className='filter-map-row'>
      <CsrSummaryMetricsPanel
        currentPort={currentPort} 
        locationType={locationType}
      />
      <div className='map-wrapper'>
        <div className='location-button-group'>
          <button
            type="button"
            className={locationType == 'mbu' ? 'active' : ''}
            onClick={() => setLocationType('mbu')}
          >
            Market
          </button>
          <button
            type="button"
            className={locationType == 'port of loading' ? 'active' : ''}
            onClick={() => setLocationType('port of loading')}
          >
           Dispatch
          </button>
          <button
            type="button"
            className={locationType == 'port of discharge' ? 'active' : ''}
            onClick={() => setLocationType('port of discharge')}
          >
           Discharge
          </button>
          <button
            type="button"
            className={locationType == 'place of delivery' ? 'active' : ''}
            onClick={() => setLocationType('place of delivery')}
          >
            Destination
          </button>
        </div>
        <div
          id="google-map-csr"
          ref={googleMapRef} /> 
      </div>
    </div>
    <CsrFilters
      filteredData={filteredData}
      filters={filterData}
      handleClear={handleFilterClear}
      handleFilterInputUpdate={handleFilterInputUpdate}
      filterOrders={filters} />
    <CsrResultsTable
      doPaging={doPaging}
      setDisplayMode={setDisplayMode}
      displayMode={displayMode}
      orders={orders}
      page={page}
      pageSize={pageSize} />
  </div>;
}

export default CsrDashboard;
