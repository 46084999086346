import {
  BOOKINGFORMS_REQUEST,
  BOOKINGFORMS_SUCCESS,
  BOOKINGFORMS_FAILURE,
} from './actionTypes';

export const bookingFormActions = {
  requestBookingForm: daysBack => (dispatch, getState) => {
    dispatch({
      type: BOOKINGFORMS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/BookingForm/v1/Get?daysBack=${daysBack}`,
        successAction: BOOKINGFORMS_SUCCESS,
        failureAction: BOOKINGFORMS_FAILURE,
      },
    });
  },
};
