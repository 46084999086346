/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CustomBarChart from '../../CustomCharts/CustomBarChart';
import Table from '../../CustomTables/CustomTable';
import '../demandAndSupply.scss';
import { TimeFilterEnum } from '../../Common/Enums/TimeFilterEnum';
import { transposeData } from './CommonCarouselFunctions';
import { doDataExport } from './CommonCarouselFunctions';
import MarketMultiSelectFilter from '../../CustomFilters/MultiSelect/MarketMultiSelectFilter';
import PeriodMultiSelectFilter from '../../CustomFilters/MultiSelect/PeriodMultiSelectFilter';
import DropdownSelectCategory from '../../Common/DropdownSelectCategory';
import { ExportChart } from '../../Common/Enums/ExportChart';


const MapeByCategoryCarouselChart = (props) => {
  
  const bars = [
    { dataKey: 'ForecastVariance', strokeColor: '#8884d8' },
  ];
  const tableHeaders = ['Month', 'Forecast Variance (%)'];

  const [categoryFilter, setCategoryFilter] = useState(null);
  const marketIds = props.mbus.map(market => market.marketId);
  const [selectedMarkets, setSelectedMarkets] = useState(marketIds);
  const [periodFilter, setPeriodFilter] = useState(TimeFilterEnum.YTD);
  const [selectedMonths, setSelectedMonths] = useState([]);

  const handleMarketFilterChange = (selectedMarkets) => {
    setSelectedMarkets(selectedMarkets);
  };

  const handlePeriodFilterChange = (newFilter) => {
    setPeriodFilter(newFilter);
  };

  const handleMonthsChange = (selectedMonths) => {
    setSelectedMonths(selectedMonths);
  };

  const months = [
    { key: 10, value: 'October' },
    { key: 11, value: 'November' },
    { key: 12, value: 'December' },
    { key: 1, value: 'January' },
    { key: 2, value: 'February' },
    { key: 3, value: 'March' },
    { key: 4, value: 'April' },
    { key: 5, value: 'May' },
    { key: 6, value: 'June' },
    { key: 7, value: 'July' },
    { key: 8, value: 'August' },
    { key: 9, value: 'September' },
  ];  

  const filters = [
    { key: 'Period', value: periodFilter },
    { key: 'Category', value: categoryFilter },
    { key: 'Selected Months', value: selectedMonths.map(id => months.find(month => month.key === id)?.value).join(', ') },
    { key: 'Selected Markets', value: selectedMarkets.map(id => props.mbus?.find(m => m.marketId === id)?.name).join(', ') },
  ];

  const handleCategoryFilterChange = (e) => {
    const newValue = e.target.value;
    var categoryId = newValue === '-1' ? null : newValue;
    console.log(categoryId);
    setCategoryFilter(categoryId);
  };
   

  let monthlyCategoryChartData = Array.isArray(props.months) && props.months.map(month => {
    let data = Array.isArray(props.chartData) && props.chartData.find(sd => sd.month === month);
    return {
      month: month,
      ForecastVariance: data ? data.mape : 0,
    };
  });

  const handleSearchButtonClick = () => {
    props.getMapeByCategoryData(periodFilter,selectedMonths,selectedMarkets,categoryFilter);
  };

  const handleClearFilterClick = () => {
    var newPeriod = TimeFilterEnum.YTD;
    var newMonths = [];
    setSelectedMarkets(marketIds);
    setPeriodFilter(newPeriod);
    setSelectedMonths(newMonths);
    setCategoryFilter(-1);
    props.getMapeByCategoryData(newPeriod,newMonths,marketIds,null);
  };


  const handleExportSummary = () => {
    props.handleExportSummary(ExportChart.ForecastVarianceByCategory,periodFilter,selectedMonths,selectedMarkets, categoryFilter);
  };

  return (
    <div>
      <div className="group-details accordion download-container summary forecast-export">
        <div className="accordion-header">
          <h3>Download Summary</h3>
        </div>
        <div className="accordion-body horizontal-flex order-fields">
          <div>
            <button
              className="action export"
              type="button"
              title="Export"
              disabled={false}
              onClick={() => handleExportSummary()}
            >
              <div
                className="icon icon-download-light"
                alt="Export"
              />
            </button>
          </div>
        </div>
      </div>
      <div className='category-filter-container'>
        <MarketMultiSelectFilter 
          mbuList={props.mbus}
          selectedMarkets={selectedMarkets}
          onMarketFilterChange={handleMarketFilterChange}/>
        <PeriodMultiSelectFilter        
          periodFilter={periodFilter}
          selectedMonths={selectedMonths}
          onPeriodFilterChange={handlePeriodFilterChange}
          onSelectedMonths={handleMonthsChange}
          orderMetrics={false} />
        {<DropdownSelectCategory 
          productCategories = {props.productCategories}
          selectedValue = {categoryFilter}
          onChange = {handleCategoryFilterChange} />}
      </div>

      <div className="kpi-filter-action">
        <button
          type="button"
          className="action"
          onClick={() => handleSearchButtonClick()}
        >
          Search
        </button>
        {<button
          type="clear"
          className="action default"
          onClick={() => handleClearFilterClick()}
        >
          Clear Filter
        </button>}
      </div>
      
      <div id='category-carousel-chart-container'
        className='carousel-chart-container'>
        <CustomBarChart data={monthlyCategoryChartData}
          chartTitle={'Forecast Variance by Category'}
          bars={bars}
          xAxisFontSize={16}
          dataKey={'month'}
          width={'100%'}
          showsPercentage={true} />
      </div>
      
      <div className='export-button-style'
        style={{ textAlign: 'left' }}>
        <button type="button"
          className="action export"
          onClick={() => doDataExport('Forecast Variance by Category', 'category-carousel-chart-container', 'carousel-chart-container', filters)}>Export Chart</button>
      </div>
      
      <div className='mape-table-container'>
        <Table headers={tableHeaders}
          transposed={true}
          data={transposeData(monthlyCategoryChartData)} />
      </div>
    </div>
  );
};
 
export default MapeByCategoryCarouselChart;