import React from 'react';
import { connect } from 'react-redux';
import apacOrderTrackerActions from '../../../actions/apacOrderTrackerActions';

class ShipmentLogUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaName: undefined,
      mediaType: undefined,
      mediaData: undefined,
    };

    this.handleSave = this.handleSave.bind(this);
  }

  handleSave(e) {
    this.props.upload(this.state);

    e.preventDefault();
  }

  handleFileInputUpdate(e) {
    if (!e.target.files || e.target.files.length === 0) return;
    let currentComponent = this;
    let doc = {
      mediaName: e.target.files[0].name,
      mediaType: e.target.files[0].type,
    };

    var reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }).then(data => {
      doc.mediaData = data ? data.replace(/^data:(.*,)?/, '') : '';

      currentComponent.setState(doc);
    });
  }

  render() {
    const { errorMessage, isUploading, isLoaded } = this.props;
    return (
      <div className="master-file-upload-page page">
        <form className="form-container">
          <div className="form-header">
            <div className="header-title">
              <div className="icon icon-reports"></div>
              <h2>Shipment Log Upload</h2>
            </div>
          </div>
          <div className="form-explanation">
            <p>Upload a shipment log to update the shipment information for orders.</p>
          </div>
          <div className="form-body">
            <label className="file-upload-wrapper">
              <div className="icon icon-image-upload"></div>
              <p>
                {!this.state.mediaName &&
                  'Tap to upload your Shipment Log.'}
                {this.state.mediaName}
              </p>
              <input
                autoFocus
                name="file"
                id="file"
                className="inputfile"
                type="file"
                onChange={e => this.handleFileInputUpdate(e)}
              />
            </label>
          </div>
          <div className="form-buttons">
            {errorMessage && <p>{errorMessage}</p>}
            {isLoaded && <p>File Successfully Uploaded</p>}
            <button type="button"
              className="action"
              disabled={isUploading}
              onClick={e => this.handleSave(e)}>
              Upload
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isUploading: state.apacOrderTracker.isUploading,
    isLoaded: state.apacOrderTracker.isLoaded,
    errorMessage: state.apacOrderTracker.errorMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    upload: file => dispatch(apacOrderTrackerActions.shipmentLogUpload(file)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShipmentLogUpload);
