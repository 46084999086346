import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import zxcvbn from 'zxcvbn';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      usernameInvalid: false,
      password: '',
      passwordInvalid: false,
      rememberMe: false,
    };

    this.usernameOnChange = this.usernameOnChange.bind(this);
    this.passwordOnChange = this.passwordOnChange.bind(this);
    this.rememberMeChange = this.rememberMeChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const username = localStorage.getItem('rememberMe');
    if (username) {
      this.setState({ username, rememberMe: true });
    }
  }

  usernameOnChange(event) {
    let { value } = event.target;
    let valid = value !== undefined && value.length > 0 && value.length < 50;

    this.setState({ username: value, usernameInvalid: !valid });
  }

  passwordOnChange(event) {
    let { value } = event.target;

    let valid = value !== undefined && value.length >= 8 && value.length < 50;
    
    this.setState({ password: value, passwordInvalid: !valid, passwordFeedbackMessage: '' });
  }

  rememberMeChange(event) {
    let { checked } = event.target;
    this.setState({ rememberMe: checked });

    if (!checked) {
      localStorage.removeItem('rememberMe');
    }
  }

  submit(event) {
    let { username, password, usernameInvalid, passwordInvalid } = this.state;
    let { login } = this.props;

    if (username === '' || usernameInvalid) {
      this.setState({ usernameInvalid: true });
      event.preventDefault();
      return;
    }

    if (password === '' || passwordInvalid) {
      this.setState({ passwordInvalid: true });
      event.preventDefault();
      return;
    }

    if (this.state.rememberMe) {
      localStorage.setItem('rememberMe', username);
    }

    login({
      username,
      password,
    });

    event.preventDefault();
  }
  render() {
    return (
      <form onSubmit={this.submit}
        noValidate>
        <input
          placeholder="Username"
          type="text"
          onChange={e => this.usernameOnChange(e)}
          required
          autoFocus
          value={this.state.username}
          className={`float ${this.state.usernameInvalid ? 'invalid' : ''}`}
        />
        <input
          placeholder="Password"
          type="password"
          onChange={e => this.passwordOnChange(e)}
          required
          value={this.state.password}
          className={`float ${this.state.passwordInvalid ? 'invalid' : ''}`}
        />
        <div className="submission">
          <label className="wrapping">
            <div className="toggle-wrapper">
              <input
                type="checkbox"
                name="rememberUser"
                className="toggle"
                onChange={e => this.rememberMeChange(e)}
                checked={this.state.rememberMe}
              />
              <div className="toggle-icon"></div>
            </div>
            <span>Remember me?</span>
          </label>
          <p>{this.props.auth.errorMessage}</p>
          <button
            type="submit"
            className="float"
            disabled={this.props.auth.isFetching}>
            Sign in
          </button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    login: creds => dispatch(authActions.requestLogin(creds)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
