/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Table from '../CustomTables/CustomTable';
import CustomBarChart from '../CustomCharts/CustomBarChart';
import forecastPerformanceDashboardActions from '../../../actions/forecastPerformanceDashboardActions';
import { connect } from 'react-redux';
import { TimeFilterEnum } from '../Common/Enums/TimeFilterEnum';
import './forecastChangeRequest.scss';
import { doDataExport } from '../DemandAndSupply/CarouselCharts/CommonCarouselFunctions';
import MarketMultiSelectFilter from '../CustomFilters/MultiSelect/MarketMultiSelectFilter';
import PeriodMultiSelectFilter from '../CustomFilters/MultiSelect/PeriodMultiSelectFilter';
import { ExportChart } from '../Common/Enums/ExportChart';

const ForecastChangeRequest = (props) =>{

  const tableHeaders = ['Market', 'Item Count'];

  const bars = [
    { dataKey: 'ItemCount', strokeColor: '#8884d8' },
  ];

  const marketIds = props.mbuData.map(market => market.marketId);

  const [loading, setLoading] = useState(true);
  const [selectedMarkets, setSelectedMarkets] = useState(marketIds);
  const [periodFilter, setPeriodFilter] = useState(TimeFilterEnum.YTD);
  const [selectedMonths, setSelectedMonths] = useState([]);

  const months = [
    { key: 10, value: 'October' },
    { key: 11, value: 'November' },
    { key: 12, value: 'December' },
    { key: 1, value: 'January' },
    { key: 2, value: 'February' },
    { key: 3, value: 'March' },
    { key: 4, value: 'April' },
    { key: 5, value: 'May' },
    { key: 6, value: 'June' },
    { key: 7, value: 'July' },
    { key: 8, value: 'August' },
    { key: 9, value: 'September' },
  ];  

  const filters = [
    { key: 'Period', value: periodFilter },
    { key: 'Selected Months', value: selectedMonths.map(id => months.find(month => month.key === id)?.value).join(', ') },
    { key: 'Selected Markets', value: selectedMarkets.map(id => props.mbuData?.find(m => m.marketId === id)?.name).join(', ') },
  ];

  useEffect(() => {
    props.getForecastChangeRequestChartData(periodFilter, selectedMonths, selectedMarkets); 
  }, []);

  let mbuFcrList = Array.isArray(props.mbuData) && props.mbuData.map(mbu => {
    let data = Array.isArray(props.forecastChangeRequestsData.mbuForecastChangeRequests) && 
               props.forecastChangeRequestsData.mbuForecastChangeRequests.find(sd => sd.marketId === mbu.marketId);
    return {
      market: mbu.name.trim().replace('Starbucks', ''),
      ItemCount: data ? data.count : 0,
    };
  });

  const monthsArr = ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept'];

  let monthlyFcrList = Array.isArray(monthsArr) && monthsArr.map(month => {
    let data = Array.isArray(props.forecastChangeRequestsData.monthlyForecastChangeRequests) && 
               props.forecastChangeRequestsData.monthlyForecastChangeRequests.find(sd => sd.month === month);
    return {
      month: month,
      ItemCount: data ? data.count : 0,
    };
  });

  const handlePeriodFilterChange = (newFilter) => {
    setPeriodFilter(newFilter);
  };

  const handleMonthsChange = (selectedMonths) => {
    setSelectedMonths(selectedMonths);
  };

  const handleMarketFilterChange = (selectedMarkets) => {
    setSelectedMarkets(selectedMarkets);
  };

  function transposeData(data) {
    if (!Array.isArray(data)) {
      throw new TypeError('Data must be an array');
    }
    if (data.length === 0) {
      return [];
    }
    const keys = Object.keys(data[0]);
    if (!keys.every(key => data.every(item => key in item))) {
      throw new TypeError('All objects in data must have the same keys');
    }
    return keys.map(key => data.map(item => item[key]));
  }

  const handleSearchButtonClick = () => {
    props.getForecastChangeRequestChartData(periodFilter, selectedMonths, selectedMarkets); 
  };

  const handleClearFilterClick = () => {
    var newPeriod = TimeFilterEnum.YTD;
    var newMonths = [];
    setSelectedMarkets(marketIds);
    setPeriodFilter(newPeriod);
    setSelectedMonths(newMonths);
    props.getForecastChangeRequestChartData(newPeriod, newMonths, marketIds); 
  };

  const handleExportSummary = () => {
    props.exportRawData(ExportChart.ForecastChangeRequest, periodFilter, selectedMonths, selectedMarkets);
  };

  return <><div>
    <div className="group-details accordion download-container summary forecast-export">
      <div className="accordion-header">
        <h3>Download Summary</h3>
      </div>
      <div className="accordion-body horizontal-flex order-fields">
        <div>
          <button
            className="action export"
            type="button"
            title="Export"
            disabled={false}
            onClick={() => handleExportSummary()}
          >
            <div
              className="icon icon-download-light"
              alt="Export"
            />
          </button>
        </div>
      </div>
    </div>
    <div className='fcr-order-filter-container'>
      <MarketMultiSelectFilter 
        mbuList={props.mbuData}
        selectedMarkets={selectedMarkets}
        onMarketFilterChange={handleMarketFilterChange}/>
      <PeriodMultiSelectFilter        
        periodFilter={periodFilter}
        selectedMonths={selectedMonths}
        onPeriodFilterChange={handlePeriodFilterChange}
        onSelectedMonths={handleMonthsChange}
        orderMetrics={false} />
    </div>
    <div className="kpi-filter-action">
      <button
        type="button"
        className="action"
        onClick={() => handleSearchButtonClick()}
      >
          Search
      </button>
      {<button
        type="clear"
        className="action default"
        onClick={() => handleClearFilterClick()}
      >
          Clear Filter
      </button>}
    </div>

  </div>
  <div id='fcr-orders'
    className='fcr-dashboard'>

    <div id='market-fcr-orders'  
      className='fcr-order-chart-container large-width-container'>
      <CustomBarChart data={mbuFcrList} 
        chartTitle={'Number of Forecast Change Request Summaries order for all markets'}
        bars={bars} 
        dataKey={'market'}
        width={'100%'}/>
    </div>

    <div id='monthly-fcr-orders' 
      className='fcr-order-chart-container small-width-container'>
      <CustomBarChart data={monthlyFcrList} 
        chartTitle={'Number of Forecast Change Request Summaries  order for selected market monthly'}
        bars={bars} 
        dataKey={'month'}
        width={'100%'} />
    </div>
  </div>

  <div className='export-button-style'>
    <button type="button"
      className="action export"
      onClick={() => doDataExport('EO_', 'fcr-orders', 'fcr-dashboard', filters)}>Export Chart</button>
  </div>
  <div className='fcr-table-container'>
    <Table headers={tableHeaders} 
      transposed={true}
      data={transposeData(mbuFcrList)} />
  </div>

  </>;
};

const mapStateToProps = (state) => {
  return {
    forecastChangeRequestsData: state.forecastPerformanceDashboard.forecastChangeRequestsData,
  };
};
 

const mapDispatchToProps = (dispatch) => {
  return {
    getForecastChangeRequestChartData: (timeFilter, selectedMonths, selectedMarkets)=> 
      dispatch(forecastPerformanceDashboardActions.getForecastChangeRequestChartData(timeFilter, selectedMonths, selectedMarkets)),
    exportRawData: (exportChart, timeFilter, selectedMonths, selectedMarkets) => 
      dispatch(forecastPerformanceDashboardActions.exportRawData(exportChart, timeFilter, selectedMonths, selectedMarkets)),

  };
};
 

export default connect(mapStateToProps, mapDispatchToProps)(ForecastChangeRequest);