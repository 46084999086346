import {
  LSPOS_ITEMNUMBERADMIN_REQUEST, 
  LSPOS_ITEMNUMBERADMIN_SUCCESS, 
  LSPOS_ITEMNUMBERADMIN_FAILURE, 
  LSPOS_ITEMNUMBERADMIN_DETAIL_REQUEST,
  LSPOS_ITEMNUMBERADMIN_DETAIL_SUCCESS,
  LSPOS_ITEMNUMBERADMIN_DETAIL_FAILURE,
  LSPOS_ITEMNUMBERADMIN_SAVE_REQUEST, 
  LSPOS_ITEMNUMBERADMIN_SAVE_SUCCESS,
  LSPOS_ITEMNUMBERADMIN_SAVE_FAILURE, 
} from '../actions/actionTypes';
import initialState from './initialState';

export const PosItemNumberRequestAdminReducer = (
  state = initialState.posItemNumbeRequestAdminState,
  action = {},
) => {
  switch (action.type) {
  case  LSPOS_ITEMNUMBERADMIN_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
      storeGroups: [],
      adminItemList: [],
      errorMessage: '',
    };
  }
  case LSPOS_ITEMNUMBERADMIN_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      adminItemList: action.payload.data.data,
      pageNumber: action.payload.data.pageNumber,
      pageSize: action.payload.data.pageSize,
      totalRecords: action.payload.data.totalRecords,
      storeGroups: action.payload.storeGroups,
      errorMessage: '',
    };
  }
  case  LSPOS_ITEMNUMBERADMIN_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
      adminItemList: [],
      storeGroups: [],
      errorMessage: action.payload.message,
    };
  }
  case LSPOS_ITEMNUMBERADMIN_DETAIL_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      adminItemDetail: {},
      errorMessage: '',
    };
  }
  case LSPOS_ITEMNUMBERADMIN_DETAIL_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      adminItemDetail: action.payload,
      errorMessage: '',
    };
  }
  case LSPOS_ITEMNUMBERADMIN_DETAIL_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      adminItemDetail: {},
      errorMessage: action.payload.message,
    };
  }
  case LSPOS_ITEMNUMBERADMIN_SAVE_REQUEST: {
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      errorMessage: '',
    };
  }
  case LSPOS_ITEMNUMBERADMIN_SAVE_SUCCESS: {
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      errorMessage: '',
    };
  }
  case LSPOS_ITEMNUMBERADMIN_SAVE_FAILURE: {
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};

export default PosItemNumberRequestAdminReducer;
