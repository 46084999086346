import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import GroupRecord from '../shared/groupRecord';
import { userManagementActions } from '../../../actions/userManagementActions';
import DeleteModal from '../../shared/DeleteModal';

class CustomerGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showDeleteModal: false };

    this.handleCreateGroup = this.handleCreateGroup.bind(this);
    this.handleEditGroup = this.handleEditGroup.bind(this);
    this.handleDeleteGroup = this.handleDeleteGroup.bind(this);

    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);
  }

  componentDidMount() {
    this.props.getGroups();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.groupDeleted && !prevProps.groupDeleted) {
      this.props.getGroups();
    }
  }

  handleCreateGroup() {
    const { pathname } = this.props.location;
    this.props.push(`${pathname}/0`);
  }

  handleEditGroup(groupId) {
    const { pathname } = this.props.location;
    this.props.push(`${pathname}/${groupId}`);
  }

  handleDeleteModalShow(groupId) {
    this.setState({ showDeleteModal: true, groupId });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false, groupId: undefined });
  }

  handleDeleteGroup() {
    this.props.deleteGroup(this.state.groupId);
    this.handleDeleteModalHide();
  }

  render() {
    const { groups, tenantKey } = this.props;
    const { showDeleteModal } = this.state;
    const {
      handleEditGroup,
      handleDeleteGroup,
      handleDeleteModalShow,
      handleDeleteModalHide,
    } = this;

    const isEMEA = tenantKey === 'EMEA';
    return (
      <div className="groups-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-stores"></div>
              <div>
                <h2>Customer Access Configuration</h2>
                {isEMEA ? (
                  <p>
                  Manage the stores that can be linked to a customer user
                  account record.
                  </p>
                ): 
                  <p>
                  Manage the customers that can be linked to a customer user
                  account record.
                  </p>}
              </div>
            </div>
            <div className="header-buttons">
              <button
                className="action"
                type="button"
                onClick={() => this.handleCreateGroup()}>
                + Create New
              </button>
            </div>
          </div>
          <div className="group-container">
            {groups.map(group => (
              <GroupRecord
                key={group.storeGroupId}
                groupName={group.storeGroupName}
                iconName="icon-store"
                groupId={group.storeGroupId}
                editAction={() => handleEditGroup(group.storeGroupId)}
                deleteAction={() => handleDeleteModalShow(group.storeGroupId)}
              />
            ))}
          </div>
        </div>
        {showDeleteModal && (
          <DeleteModal
            cancel={handleDeleteModalHide}
            continue={handleDeleteGroup}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.userManagement.storeGroups.isFetching,
  groups: state.userManagement.storeGroups.groupList,
  groupDeleted: state.userManagement.storeGroups.isDeleted,
  tenantKey: state.auth.tenant,
});

const mapDispatchToProps = dispatch => {
  return {
    getGroups: () => dispatch(userManagementActions.requestStoreGroups()),
    deleteGroup: userId =>
      dispatch(userManagementActions.deleteStoreGroup(userId)),
    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerGroups);
