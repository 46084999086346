import React from 'react';
import ApplicationHelpers from '../../helpers/applicationHelpers';
import statuses from '../../helpers/applicationStatusEnum';
import projectTypes from '../../helpers/applicationProjectTypeEnum';

export class ApplicationInformationForm extends React.Component {

  render() {
    const {
      handleTextInputUpdate,
      locked,
      isReadOnly,
      fields,
      companiesDetail,
      formatsDetail,
      primaryBusinessGeneratorsDetail,
      planningGrantTypesDetail,
      projectTypesDetail,
      tenureTypesDetail,
      companyUsersDetail,
      applicationDetail,
      siteTypesDetail,
      countiesDetail,
      leaseDetail,
      handleCheckInputUpdate,
      nearApplications,
      existsOnSentrk  } = this.props;
    const {
      companyId,
      siteName,
      projectTypeId,
      formatId,
      tenureTypeId,
      netSquareFeet,
      grossSquareFeet,
      primaryBusinessGeneratorId,
      estimatedOpeningDate,
      plannedClosingDate,
      primaryContactId,
      secondaryContactid,
      statusId,
      street1,
      street2,
      city,
      countyId,
      postCode,
      latitude,
      longitude,
      showValidation,
      storeNo,
      developer,
      finalDeveloper,
      siteReferenceNo,
      projectType,
      headsOfTermsAgreed,
      leaseSignedOrSitePurchasedId,
      siteTypeId,
      planningRequired,
      planningGrantedId,
      planningGranted,
      planningSubmitted,
      planningComments,
      plannedSchematic,
      latestEstimatedOpeningDate,
    } = fields;

    const {
      company,
      tenureType,
      format,
      primaryBusinessGenerator,
      projectStatus,
      siteType,
    } = applicationDetail;

    const primaryContact = companyUsersDetail.find(
      (c) => c.userId === primaryContactId,
    );
    const secondaryContact = companyUsersDetail.find(
      (c) => c.userId === secondaryContactid,
    );
    const county = countiesDetail.find(
      (c) => c.key === countyId,
    );

    return (
      <div>
        <h3>Application Information</h3>
        <p>
          <span className="required"> *</span>
          {' '}
          These fields are mandatory to
          progress this application.
        </p>
        {!ApplicationHelpers.validateProximity(nearApplications, statusId, projectTypeId) ?
          <span  className="required">
           This application is too close to an existing application.
          </span> : null}
        <div className="form-section">
          {siteReferenceNo &&
            <label>
              S.I.T.T. Reference
              <span className="required"> *</span>
              <input
                readOnly
                value={siteReferenceNo}
              />
            </label>
          }

          <label>
            Store Name
            <span className="required"> *</span>
            <input
              type="text"
              maxLength={35}
              minLength={3}
              readOnly={locked || existsOnSentrk}
              value={siteName}
              onChange={(e) => handleTextInputUpdate(e, 'siteName', 'text')}
            />
          </label>

          <label>
            Project Type
            <span className="required"> *</span>
            {statusId <= statuses.draft && !isReadOnly ? (
              <>
                <select
                  value={projectTypeId}
                  className={showValidation && !projectTypeId ? 'invalid' : ''}
                  onChange={(e) => handleTextInputUpdate(e, 'projectTypeId')}
                >
                  <option value="">Please Select</option>
                  {projectTypesDetail
                    && projectTypesDetail.map((c) => (
                      <option key={c.key}
                        value={c.key}>
                        {c.value}
                      </option>
                    ))}
                </select>
              </>
            ) : (
              <input type="text"
                readOnly
                value={projectType} />
            )}
          </label>

          {((projectTypeId &&
              (projectTypeId.toString() === projectTypes.conversion.toString() ||
               projectTypeId.toString() === projectTypes.relocation.toString()))
            || storeNo) &&
          <label>
            Starbucks Store
            <span className="required"> *</span>
            <input
              type="text"
              placeholder="XXXXX"
              value={storeNo}
              maxLength="5"
              readOnly={locked || existsOnSentrk}
              onChange={(e) => handleTextInputUpdate(e, 'storeNo', 'text')}
            />
          </label>
          }

          {projectTypeId && projectTypeId.toString() === projectTypes.relocation.toString() &&
          <label>
            Planned Closure
            <span className="required"> *</span>
            <input
              type="date"
              value={
                plannedClosingDate
                  && plannedClosingDate.substr(0, 10)
              }
              readOnly={locked || existsOnSentrk}
              onChange={(e) => handleTextInputUpdate(e, 'plannedClosingDate', 'date')}
            />
          </label>
          }

        </div>
        <div className="form-section">
          <label>
            Business Partner
            <span className="required"> *</span>
            {statusId <= statuses.draft && !isReadOnly ? (
              <>
                <select
                  value={companyId}
                  className={showValidation && !companyId ? 'invalid' : ''}
                  onChange={(e) => handleTextInputUpdate(e, 'companyId')}
                >
                  <option value="">Please Select</option>
                  {companiesDetail
                    && companiesDetail.map((c) => (
                      <option key={c.key}
                        value={c.key}>
                        {c.value}
                      </option>
                    ))}
                </select>
              </>
            ) : (
              <input type="text"
                readOnly
                value={company} />
            )}
          </label>
          <label>
            Primary Contact
            <span className="required"> *</span>
            {!locked ? (
              <>
                <select
                  value={primaryContactId}
                  onChange={(e) => handleTextInputUpdate(e, 'primaryContactId')}
                >
                  <option value="">Please Select</option>
                  {companyUsersDetail
                    && companyUsersDetail.map((c) => (
                      <option key={c.userId}
                        value={c.userId}>
                        {c.userName}
                      </option>
                    ))}
                </select>
              </>
            ) : (
              <input
                type="text"
                readOnly
                value={primaryContact && primaryContact.userName}
              />
            )}
          </label>
          <label>
            Secondary Contact
            {!locked ? (
              <select
                value={secondaryContactid}
                onChange={(e) => handleTextInputUpdate(e, 'secondaryContactid')}
              >
                <option value="">Please Select</option>
                {companyUsersDetail
                  && companyUsersDetail.map((c) => (
                    <option key={c.userId}
                      value={c.userId}>
                      {c.userName}
                    </option>
                  ))}
              </select>
            ) : (
              <input
                type="text"
                readOnly
                value={secondaryContact && secondaryContact.userName}
              />
            )}
          </label>
        </div>

        <div className="form-section">
          <label>
            Store Address
            <span className="required"> *</span>
            <input
              type="text"
              value={street1}
              maxLength="128"
              readOnly={(locked || existsOnSentrk) && applicationDetail && applicationDetail.street1}
              onChange={(e) => handleTextInputUpdate(e, 'street1', 'text')}
            />
          </label>
          <label>
            Street 2
            <input type="text"
              value={street2}
              maxLength="128"
              readOnly={locked || existsOnSentrk}
              onChange={(e) => handleTextInputUpdate(e, 'street2', 'text')} />
          </label>
          <label>
            City
            <span className="required"> *</span>
            <input type="text"
              value={city}
              maxLength="128"
              readOnly={locked || existsOnSentrk}
              onChange={(e) => handleTextInputUpdate(e, 'city', 'text')}/>
          </label>
          <label>
              County
            <span className="required"> *</span>
            {!(locked || existsOnSentrk)  && !isReadOnly ? (
              <>
                <select
                  value={countyId}
                  onChange={(e) => {
                    handleTextInputUpdate(e, 'countyId', 'text');
                  }}
                >
                  <option value="" />
                  {countiesDetail
                    && countiesDetail.map((c) => (
                      <option key={c.key}
                        value={c.key}>
                        {c.value}
                      </option>
                    ))}
                </select>
              </>)
              :(
                <input type="text"
                  readOnly
                  value={county && county.value} />)}
          </label>
          <label>
            State
            <span className="required"> *</span>
            <input type="text"
              value={'United Kingdom'}
              readOnly />
          </label>
          <label>
            Post Code
            <span className="required"> *</span>
            <input type="text"
              value={postCode}
              maxLength={8}
              readOnly={locked || existsOnSentrk}
              onChange={(e) => handleTextInputUpdate(e, 'postCode', 'int')} />
          </label>
          <label>
           Latitude
            <span className="required"> *</span>
            <input type="text"
              value={latitude}
              readOnly />
          </label>
          <label>
            Longitude
            <span className="required"> *</span>
            <input type="text"
              value={longitude}
              readOnly />
          </label>
        </div>

        <div className="form-section">
          <label>
                Segment
            <span className="required"> *</span>
            {(!(locked || existsOnSentrk) || !primaryBusinessGenerator)  && !isReadOnly ? (
              <>
                <select
                  value={primaryBusinessGeneratorId}
                  onChange={(e) => handleTextInputUpdate(e, 'primaryBusinessGeneratorId')}
                >
                  <option value="">Please Select</option>
                  {primaryBusinessGeneratorsDetail
                        && primaryBusinessGeneratorsDetail.map((c) => (
                          <option key={c.key}
                            value={c.key}>
                            {c.value}
                          </option>
                        ))}
                </select>
              </>
            ) : (
              <input
                type="text"
                readOnly
                value={primaryBusinessGenerator}
              />
            )}
          </label>

          <label>
            Store Type
            <span className="required"> *</span>
            {(!(locked || existsOnSentrk) || !format)  && !isReadOnly ? (
              <>
                <select
                  value={formatId}
                  onChange={(e) => handleTextInputUpdate(e, 'formatId')}
                >
                  <option value="">Please Select</option>
                  {formatsDetail
                      && formatsDetail.map((c) => (
                        <option key={c.key}
                          value={c.key}>
                          {c.value}
                        </option>
                      ))}
                </select>
              </>
            ) : (
              <input type="text"
                readOnly
                value={format} />
            )}
          </label>

          <label>
            Tenure
            <span className="required"> *</span>
            {(!(locked || existsOnSentrk) || !tenureType)  && !isReadOnly ? (
              <>
                <select
                  value={tenureTypeId}
                  onChange={(e) => handleTextInputUpdate(e, 'tenureTypeId')}
                >
                  <option value="">Please Select</option>
                  {tenureTypesDetail
                    && tenureTypesDetail.map((c) => (
                      <option key={c.key}
                        value={c.key}>
                        {c.value}
                      </option>
                    ))}
                </select>
              </>
            ) : (
              <input type="text"
                readOnly
                value={tenureType} />
            )}
          </label>
          <label>
            Site Type
            {(statusId <= statuses.registered || !siteType)  && !isReadOnly ? (
              <>
                <span className="required"> *</span>
                <select
                  value={siteTypeId}
                  className={showValidation && !siteTypeId ? 'invalid' : ''}
                  onChange={(e) => handleTextInputUpdate(e, 'siteTypeId')}
                >
                  <option value="">Please Select</option>
                  {siteTypesDetail
                    && siteTypesDetail.map((c) => (
                      <option key={c.key}
                        value={c.key}>
                        {c.value}
                      </option>
                    ))}
                </select>
              </>
            ) : (
              <input type="text"
                readOnly
                value={siteType} />
            )}
          </label>
          <label>
               Forecast Opening
            <span className="required"> *</span>
            <input
              type="date"
              value={
                estimatedOpeningDate ? estimatedOpeningDate.substr(0, 10) : ''              }
              readOnly={((locked || existsOnSentrk) && estimatedOpeningDate) || isReadOnly}
              onChange={(e) => handleTextInputUpdate(e, 'estimatedOpeningDate', 'date')}
            />
          </label>
          <label>
            Internal Area (ft);
            <span className="required"> *</span>
            <input
              type="number"
              max="999999999"
              min="11"
              placeholder="0"
              className={(grossSquareFeet && parseInt(grossSquareFeet) < 11) ? 'invalid' : ''}
              value={grossSquareFeet}
              readOnly={locked || existsOnSentrk}
              onChange={(e) => handleTextInputUpdate(e, 'grossSquareFeet', 'int')}
            />
          </label>
          <label>
            External Area (ft);
            <input
              type="number"
              max="999999999"
              placeholder="0"
              value={netSquareFeet}
              readOnly={locked || existsOnSentrk}
              onChange={(e) => handleTextInputUpdate(e, 'netSquareFeet', 'int')}
            />
          </label>
          {
            (tenureTypeId === '2'
              || tenureTypeId === 2
              || tenureTypeId === '3'
              || tenureTypeId === 3)
            && (
              <label>
              Developer
                <input
                  type="text"
                  value={developer}
                  maxLength="1000"
                  readOnly={
                    locked 
                  || (statusId >= statuses.identified && applicationDetail.developer)
                  }
                  onChange={(e) => handleTextInputUpdate(e, 'developer', 'text')}
                />
              </label>
            )
          }
          {statusId >= statuses.identified && (
            <>
              {(tenureTypeId === '2'
                || tenureTypeId === 2
                || tenureTypeId === '3'
                || tenureTypeId === 3) && (
                <label>
                    Final Developer
                  <input
                    type="text"
                    maxLength="1000"
                    value={finalDeveloper}
                    readOnly={
                      locked 
                        || (statusId >= statuses.registered
                          && applicationDetail.finalDeveloper)
                    }
                    onChange={(e) => handleTextInputUpdate(e, 'finalDeveloper', 'text')}
                  />
                </label>
              )
              }
            </>
          )}
        </div>
        {statusId >= statuses.toBeIdentified &&
          <div className="form-section">
            <label>
              Heads of Terms Agreed
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  readOnly={locked}
                  onChange={(e) => handleCheckInputUpdate(e, 'headsOfTermsAgreed')}
                  checked={headsOfTermsAgreed}
                />
                <div className="toggle-icon" />
              </div>
            </label>
            <label>
              Lease Signed/Site Purchased
              {!locked ? (
                <>
                  <select
                    value={leaseSignedOrSitePurchasedId}
                    className={showValidation && !projectTypeId ? 'invalid' : ''}
                    onChange={(e) => handleTextInputUpdate(e, 'leaseSignedOrSitePurchasedId')}
                  >
                    <option value="">Please Select</option>
                    {leaseDetail
                      && leaseDetail.map((c) => (
                        <option key={c.key}
                          value={c.key}>
                          {c.value}
                        </option>
                      ))}
                  </select>
                </>
              ) : (
                <input type="text"
                  readOnly
                  value={leaseSignedOrSitePurchasedId} />
              )}
            </label>
            <label>
              Planning Required
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  readOnly={locked}
                  onChange={(e) => handleCheckInputUpdate(e, 'planningRequired')}
                  checked={planningRequired}
                />
                <div className="toggle-icon" />
              </div>
            </label>
            {planningRequired && (
              <>
                <label>
                  Planning Submitted
                  <input
                    type="date"
                    value={
                      planningSubmitted
                      && planningSubmitted.substr(0, 10)
                    }
                    readOnly={locked}
                    onChange={(e) => handleTextInputUpdate(e, 'planningSubmitted', 'date')}
                  />
                </label>
                <label>
                  Planning Granted
                  {!locked ? (
                    <select
                      value={planningGrantedId}
                      onChange={(e) => handleTextInputUpdate(e, 'planningGrantedId')}
                    >
                      <option value="">Please Select</option>

                      {planningGrantTypesDetail
                        && planningGrantTypesDetail.map((c) => (
                          <option key={c.key}
                            value={c.key}>
                            {c.value}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <input type="text"
                      readOnly
                      value={planningGranted} />
                  )}
                </label>
                <label>
                  Planning Comments
                  <textarea
                    type="text"
                    maxLength="2000"
                    value={planningComments}
                    readOnly={locked}
                    onChange={(e) => handleTextInputUpdate(e, 'planningComments', 'text')}
                  />
                </label>
              </>
            )}
            <label>
              Planned Opening Date
              <input
                type="date"
                value={
                  latestEstimatedOpeningDate
                  && latestEstimatedOpeningDate.substr(0, 10)
                }
                readOnly={locked || (projectStatus != 'REC Approved' && projectStatus != 'On Hold')}
                onChange={(e) => handleTextInputUpdate(e, 'latestEstimatedOpeningDate', 'date')}
              />
            </label>
            {statusId >= statuses.approved ?
              <label>
                Planned Schematic Request
                <input
                  type="date"
                  value={
                    plannedSchematic
                     && plannedSchematic.substr(0, 10)
                  }
                  readOnly={locked || (projectStatus != 'REC Approved' && projectStatus != 'On Hold')}
                  onChange={(e) => handleTextInputUpdate(e, 'plannedSchematic', 'date')}
                />
              </label> : null}
            
          </div>
        }
      </div>
    );

  }
}

export default ApplicationInformationForm;