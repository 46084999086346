import React from 'react';
import { connect } from 'react-redux';
import { instantMessagingArchiveActions } from '../../actions/messageArchiveActions';
import { navigationActions } from '../../actions/navigationActions';

class InstantMessagingArchiveDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { conversationId } = this.props.match.params;

    this.props.getConversation(conversationId);
  }

  render() {
    const { messageDetail } = this.props;
    return (
      <div className="instant-messaging-archive-detail-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className={'icon icon-messages'}></div>
              <h2>Instant Messaging Archive Detail</h2>
            </div>
          </div>
        </div>
        <div className="floating-body">
          <div className="conversation">
            <div className="conversation-text">
              {messageDetail &&
                messageDetail.messages &&
                messageDetail.messages.map((c, i) => (
                  <div
                    key={i}
                    className={`message ${
                      c.sentByAgent ? 'sender' : 'receiver'
                    }`}>
                    <div className="avatar ">
                      <div
                        className={`icon ${
                          c.sentByAgent ? 'icon-admin' : 'icon-messages'
                        }`}></div>
                    </div>
                    <div className="message-text-container">
                      <div className="message-text">{c.message}</div>
                      <div className="message-timestamp">
                        {new Date(c.sentOn + 'Z').toLocaleTimeString()}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.messageArchive.messageDetail.isLoading,
  isLoaded: state.messageArchive.messageDetail.isLoaded,
  messageDetail: state.messageArchive.messageDetail.data,
  errorMessage: state.messageArchive.messageDetail.errorMessage,
});

const mapDispatchToProps = dispatch => ({
  getConversation: conversationId =>
    dispatch(
      instantMessagingArchiveActions.requestMessageArchiveDetail(conversationId),
    ),

  push: path => dispatch(navigationActions.pushNavigation(path)),
  replace: path => dispatch(navigationActions.replaceNavigation(path)),
  reset: () => dispatch(navigationActions.resetNavigation()),
  goBack: () => dispatch(navigationActions.backNavigation()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InstantMessagingArchiveDetail);
