import React, { } from 'react';
import { Tooltip } from '@material-ui/core';
import AssignedMatrix from '../AssignedMatrix/AssignedMatrix';

const ReminderPanel = props => {
  return (
    <div className="accordion">
      <div
        className="accordion-header"
        onClick={() => props.handleToggleExpander('remindersExpanded')}>
        <h3>Reminders</h3>
        <div
          className={`icon ${props.remindersExpanded ? 'icon-expand' : 'icon-collapse'
          }`}></div>
      </div>
      <div className={`accordion-body ${props.remindersExpanded ? '' : 'collapsed'}`}>
        <div className="horizontal-layout">
          <div className="vertical-layout">
            <label>
                Primary Reminder <Tooltip
                title={'Set how many days prior to the due date the recipients who have not yet completed the task will be notified. If the date falls on a weekend, the closest weekday before will be chosen.'}>
                <span className="info">&#x1F6C8;</span></Tooltip>
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  onChange={(e) => props.handleRemindersCheckedInput(e, 'primaryReminder')}
                  checked={props.primaryReminder}
                />
                <div className="toggle-icon"></div>
              </div>
            </label>
            {props.primaryReminder &&
            <>
              <label>
                Days Prior to Due Date <Tooltip
                  title={'The days prior to the due date that the reminder email will be sent.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <input
                  type='number'
                  placeholder="Days prior..."
                  onChange={(e) => props.handleRemindersInput(e, 'primaryReminderDaysPrior')}
                  value={props.primaryReminderDaysPrior}
                />
              </label>
              <label>
                Subject <Tooltip
                  title={'The subject displayed for the primary reminder email.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <input
                  placeholder="Subject..."
                  onChange={(e) => props.handleRemindersInput(e, 'primaryReminderSubject')}
                  value={props.primaryReminderSubject}
                />
              </label>
              <label>
                Body <Tooltip
                  title={'The text displayed for the primary reminder email.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <textarea
                  placeholder="Body..."
                  onChange={(e) => props.handleRemindersInput(e, 'primaryReminderBody')}
                  value={props.primaryReminderBody}
                />
              </label>
              {props.primaryScheduledDate &&
              <label>
                 Currently scheduled for
                <input
                  disabled
                  value={props.primaryScheduledDate}
                />
              </label>
              }
            </>
            }
          </div>

          {props.primaryReminder &&
            <>
              <div className="vertical-layout">
                <label>
                Secondary Reminder <Tooltip
                    title={'Set how many days prior to the due date the recipients who have not yet completed the task will be notified. This value should be closer to the due date than the primary reminder. If the date falls on a weekend, the closest weekday before will be chosen.'}>
                    <span className="info">&#x1F6C8;</span></Tooltip>
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      onChange={(e) => props.handleRemindersCheckedInput(e, 'secondaryReminder')}
                      checked={props.secondaryReminder}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                {props.secondaryReminder &&
            <>
              <label>
                Days Prior to Due Date <Tooltip
                  title={'The days prior to the due date that the reminder email will be sent.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <input
                  type='number'
                  placeholder="Days prior..."
                  onChange={(e) => props.handleRemindersInput(e, 'secondaryReminderDaysPrior')}
                  value={props.secondaryReminderDaysPrior}
                />
              </label>
              <label>
                Subject <Tooltip
                  title={'The subject displayed for the secondary reminder email.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <input
                  placeholder="Subject..."
                  onChange={(e) => props.handleRemindersInput(e, 'secondaryReminderSubject')}
                  value={props.secondaryReminderSubject}
                />
              </label>
              <label>
                Body <Tooltip
                  title={'The text displayed for the secondary reminder email.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <textarea
                  placeholder="Body..."
                  onChange={(e) => props.handleRemindersInput(e, 'secondaryReminderBody')}
                  value={props.secondaryReminderBody}
                />
              </label>
              {props.secondaryScheduledDate &&
              <label>
              Currently scheduled for
                <input
                  disabled
                  value={props.secondaryScheduledDate}
                />
              </label>
              }
            </>
                }
              </div>
            </>
          }
          <div className="vertical-layout">
            <label>
                Overdue Reminder <Tooltip
                title={'Set whether the user should be sent a reminder email if they fail to complete the task by the due date.'}>
                <span className="info">&#x1F6C8;</span></Tooltip>
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  onChange={(e) => props.handleRemindersCheckedInput(e, 'overdueReminder')}
                  checked={props.overdueReminder}
                />
                <div className="toggle-icon"></div>
              </div>
            </label>
            {props.overdueReminder &&
            <>
              <label>
                Subject <Tooltip
                  title={'The subject displayed for the overdue reminder email.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <input
                  placeholder="Subject..."
                  onChange={(e) => props.handleRemindersInput(e, 'overdueReminderSubject')}
                  value={props.overdueReminderSubject}
                />
              </label>
              <label>
                Body <Tooltip
                  title={'The text displayed for the overdue reminder email.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <textarea
                  placeholder="Body..."
                  onChange={(e) => props.handleRemindersInput(e, 'overdueReminderBody')}
                  value={props.overdueReminderBody}
                />
              </label>
              {props.overdueScheduledDate &&
              <label>
                Currently scheduled for
                <input
                  disabled
                  value={props.overdueScheduledDate}
                />
              </label>
              }
            </>
            }
          </div>
        </div>
        {!props.isCreate &&
        <AssignedMatrix taskDetailId={props.taskDetailId} />
        }
      </div>
      
    </div>
  );
};

export default ReminderPanel;
