import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import kpiDashboardActions from '../../../actions/kpiDashboardActions';
import KpiConfigurationItem from './KpiConfigurationItem';

const KpiConfigurations = (props) => {
  const {
    loading,
    configurations,
    getConfig,
    updateConfig,
  } = props;
  const [kpiConfigs, setKpiConfigs] = useState({});

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  useEffect(() => {
    if (configurations && configurations.length > 0) {
      let configs = {};
      for (let item of configurations) {
        configs[item.name] = item;
      }
      setKpiConfigs(configs);
    }
  }, [configurations]);

  const onInputChanged = (name, value) => {
    setKpiConfigs({
      ...kpiConfigs,
      [name]: value,
    });
  };

  const onSave = () => {
    const data = Object.keys(kpiConfigs).map((key) => kpiConfigs[key]);
    if (data) {
      updateConfig(data);
    }
  };

  return (
    <div className="kpi-configurations">
      {loading &&
                <div className="app-spinner">
                  <span className="spinner" />
                </div>
      }
      <div className="floating-panel">
        <div className="floating-header">
          <div className="header-title">
            <span className="icon icon-table" />
            <h2>KPI Dashboard Administration</h2>
          </div>
        </div>
        {configurations && configurations.length > 0 && Object.keys(kpiConfigs).length > 0 && 
                    <div className="group-details accordion">
                      <div className="accordion-header">
                        <h3>In Full</h3>
                      </div>
                      <div className="accordion-body vertical-controls">
                        <div className="config-item">
                          <label>Traffic Lights (Percentages)</label>
                          <KpiConfigurationItem
                            item={kpiConfigs.Green}
                            onItemInputChanged={onInputChanged}
                          />
                          <KpiConfigurationItem
                            item={kpiConfigs.Orange}
                            onItemInputChanged={onInputChanged}
                          />
                          <KpiConfigurationItem
                            item={kpiConfigs.Red}
                            onItemInputChanged={onInputChanged}
                          />
                        </div>
                        <div className="config-item">
                          <label>Item Failure Table</label>
                          <KpiConfigurationItem
                            item={kpiConfigs.RowCount}
                            onItemInputChanged={onInputChanged}
                          />
                        </div>
                      </div>
                    </div>
        }
        {configurations && configurations.length > 0 && Object.keys(kpiConfigs).length > 0 &&
                    <div className="button-container">
                      <button
                        type="button"
                        className="action"
                        onClick={() => onSave()}>
                            Save
                      </button>
                    </div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.kpiDashboard.loading,
    configurations: state.kpiDashboard.configurations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConfig: () => dispatch(kpiDashboardActions.getKpiConfigurations()),
    updateConfig: (params) => dispatch(kpiDashboardActions.updateKpiConfigurations(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KpiConfigurations);
