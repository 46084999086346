/* eslint-disable no-unused-vars */
import React from 'react';
import './MarketMultiSelectFilter.scss';
import MultiSelect from '../../../siteID/siteIDApplications/list/MultiSelect';

function MarketMultiSelectFilter({ mbuList,  selectedMarkets,  onMarketFilterChange }) {
  const toggleMarketSelection = (markets) => {
    onMarketFilterChange(markets);
  };

  // Map your mbuList to the format expected
  const marketOptions = mbuList.map(market => ({
    key: market.marketId,
    value: market.name,
  }));

  return (
    <div className="market-filter-container"
      style={{ marginRight: '50px' }}>
      <MultiSelect
        title="Markets"
        selectedValues={selectedMarkets}
        valueChanged={toggleMarketSelection}
        values={marketOptions}
        valueName="selectedMarkets"
      />
    </div>
  );
}

export default MarketMultiSelectFilter;
