import React from 'react';
import { connect } from 'react-redux';
import File from '../../documents/shared/File';
import DeleteModal from '../../shared/DeleteModal';
import { apacOrderDocumentActions } from '../../../actions/apacOrderDocumentActions';
import { specialActions } from '../../../actions/specialActions';
import '../OrderDocuments/OrderDocuments.scss';

class OrderDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      multiFilesData: [],
      documentTypeId: '',
      marketId: '',
      trackingNumber: '',
      mbuName: '',
      documentIds: [],
      fileInputValue: '',
    };

    this.handleDocumentDownload = this.handleDocumentDownload.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);
    this.handleDeleteMyDocument = this.handleDeleteMyDocument.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleAllDocumentsDownload = this.handleAllDocumentsDownload.bind(this);
    this.handleFilesInputUpdate = this.handleFilesInputUpdate.bind(this);
  }

  handleTextInputUpdate(e, fieldName) {
    this.setState({ 
      [fieldName]: e.target.value },
    );
  }

  handleSave(e) {
    let documents = {
      multiFilesData: this.state.multiFilesData,
      documentTypeId: this.state.documentTypeId,
      marketId: this.state.marketId,
      trackingNumber: this.state.trackingNumber,
      mbuName: this.state.mbuName,
    };
    this.props.uploadOrderDocument(documents);

    e.preventDefault();
  }

  handleFilesInputUpdate(e) {
    if (!e.target.files || e.target.files.length === 0) {
      this.setState({ multiFilesData: [], documentTypeId: '', fileInputValue: '' });
      return;
    }

    const files = Array.from(e.target.files);

    const multiFilesData = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const mediaData = reader.result ? reader.result.replace(/^data:(.*,)?/, '') : '';
          resolve({
            mediaName: file.name,
            mediaType: file.type,
            mediaData: mediaData,
          });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });

    Promise.all(multiFilesData).then((data) => {
      this.setState({
        multiFilesData: [...this.state.multiFilesData, ...data],
        fileInputValue: '',
      });
    });
  }

  componentDidMount(){
    const { match } = this.props;
    let tN = match.params.trackingNumber;
    this.props.getOrderDocuments(tN);
    this.setState({ 
      trackingNumber: tN,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.isSaved && !prevProps.isSaved) ||
      (this.props.isDeleted && !prevProps.isDeleted)
    ) {
      this.props.getOrderDocuments(this.state.trackingNumber);
      this.setState({ multiFilesData: [], documentTypeId: '' });
    }
    if(this.props.isLoaded && !prevProps.isLoaded){
      this.setState({
        mbuName: this.props.mbu ? this.props.mbu?.storeGroupName : '',
        marketId: this.props.mbu ? this.props.mbu?.marketId : 0,
      });
    }
  }

  handleDocumentDownload(link) {
    this.props.downloadDocument(link);
  }
  handleAllDocumentsDownload() {
    this.handleDocumentDownload(
      `/api/omorderdocument/v1/downloaddocuments?${this.props.documents.map(document => `documentIds=${document.documentId}`).join('&')}`,
    );
  }

  handleDeleteMyDocument(doc) {
    this.setState({ showDeleteModal: true, selectedDoc: doc });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false, selectedDoc: '' });
  }

  handleDelete() {
    let selectedDoc = this.state.selectedDoc;
    this.props.delete(selectedDoc);
    this.handleDeleteModalHide();
  }

  handleCheckboxChange(event, documentId) {
    const checked = event.target.checked;

    let newIds = [];

    if (Array.isArray(this.state.documentIds)) {
      newIds = [...this.state.documentIds];
    }
    
    const index = newIds.findIndex(x => x == documentId);

    if (checked && index < 0) {
      newIds.push(documentId);
    }
    else if (!checked && index > -1) {
      newIds.splice(index, 1);
    }

    this.setState({
      documentIds: newIds,
    });
  }

  render() {
    const { documents, isAdmin,  isSaved, isSaving, errorMessage, documentTypes, requiredDocumentTypes, thirdPartyCanWrite, userId } = this.props;
    const { documentTypeId, trackingNumber, showDeleteModal } = this.state;
    return (
      <div className="my-documents-page page order-documents">
        
        <div className="flex">
          {(isAdmin || thirdPartyCanWrite) &&
          <div className="form-container floating-panel m-r-sm upload-panel">
            <form
              noValidate
              onSubmit={e => this.handleSave(e)}>
              
              <><div className="form-header">
                <div className="header-title">
                  <div className="icon icon-reports"></div>
                  <h2>Order Document Upload</h2>
                </div>
              </div><div className="form-explanation">
                <p>Here you can upload files to support this order.</p>
              </div><div className="form-body">
                <label className="file-upload-wrapper max-width"
                  style={{ maxWidth: '425px' }}>
                  <div className="icon icon-image-upload"></div>
                  <p>
                    {this.state.multiFilesData.length == 0 &&
                        'Tap to upload supporting files.'}
                    {this.state.multiFilesData.map(x => x.mediaName).join(', ')}
                  </p>
                  <input
                    autoFocus
                    name="file"
                    id="file"
                    value={this.state.fileInputValue}
                    className="inputfile"
                    type="file"
                    multiple
                    onChange={e => this.handleFilesInputUpdate(e)} />
                </label>
              </div><div className="form-body">
                <label className="max-width">
                  <select onChange={e => this.handleTextInputUpdate(e, 'documentTypeId')}
                    value={this.state.documentTypeId}>
                    <option value=''>Select Document Type</option>
                    {documentTypes.map(dt => <option key={dt.documentTypeId}
                      value={dt.documentTypeId}>
                      {dt.name}
                    </option>,
                    )}
                  </select>
                </label>
              </div><div className="form-buttons"
                style={{ flexDirection: 'row', justifyContent: 'start' }}>
                {errorMessage && <p>{errorMessage}</p>}
                {isSaved && <p>File Successfully Uploaded</p>}
                <button type="submit"
                  style={{ marginRight: '10px' }}
                  className="action"
                  disabled={isSaving || documentTypeId === '' || this.state.multiFilesData.length == 0}>
                    Upload
                </button>
                <button type="button"
                  className='action negative'
                  onClick={() => this.setState({ multiFilesData: [], documentTypeId: '', fileInputValue: '' })}
                  disabled={isSaving || this.state.documentTypeId == '' && this.state.multiFilesData.length == 0}>
                    Clear
                </button>
              </div></>
            </form>
          </div>}
          <div className="form-container floating-panel m-l-sm required-doc-panel">
            <div className="floating-header">
              <div className="header-title">
                <div className="icon icon-document-libary"></div>
                <h2>Required Documents</h2>
              </div>
            </div>
            <p>
            These are the required documents that have been uploaded for this order.
            </p>
            <div className="req-doc-toggles">
              {requiredDocumentTypes && requiredDocumentTypes.length > 0 && requiredDocumentTypes.map(r =>
                <label key={r}>
                  {documentTypes.find(d => d.documentTypeId == r)?.name}
                  <div  
                    className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      checked={documents.find(d => d.documentTypeId == r) != null}
                      disabled
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>,
              )}
            </div>
          </div>
        </div>
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-document-libary"></div>
              <h2>Documents for Order ({trackingNumber})</h2>
            </div>
          </div>
          <p>
            Starbucks takes no responsibility for any consequences of error or
            for any loss or damage suffered by users of any of the information
            published in or use of this library. Starbucks may audit and/or
            delete documents in this library at any time which Starbucks
            determines should not be hosted on the portal.
          </p>
          <div className='zip-download-container'>
            <button
              className="action"
              type="button"
              disabled={this.state.documentIds.length == 0}
              onClick={() => this.handleDocumentDownload(
                `/api/omorderdocument/v1/downloaddocuments?${this.state.documentIds.map(id => `documentIds=${id}`).join('&')}`,
              )}>
              Download Selected
            </button>
            &nbsp;
            <button
              className="action"
              type="button"
              disabled={documents.length == 0}
              onClick={() =>this.handleAllDocumentsDownload()}>
              Download All Documents
            </button>
          </div>
          <div className="my-documents-body folder-container">
            {documentTypes && documentTypes.length ? documentTypes.map(t => {
            
              if (!documents || !documents.length || !documents.filter(d => d.documentTypeId === t.documentTypeId).length) {
                return null;
              }
            
              return (
                <div className="folder"
                  key={t.documentTypeId}>
                  <h4>{t.name}</h4>

                  <div className="my-documents-holder">
                    {documents && documents.length ? documents.filter(d => d.documentTypeId === t.documentTypeId).map((d) => (
                      <div key={d.documentId}
                        className='file-container'>
                        <input type='checkbox'
                          onChange={(event) => this.handleCheckboxChange(event, d.documentId)}>

                        </input>
                        <File
                          data={d}
                          onView={() =>
                            this.handleDocumentDownload(
                              `/api/omorderdocument/v1/downloaddocument?documentId=${d.documentId}`,
                            )
                          }
                          onDeleteFile={(isAdmin || (thirdPartyCanWrite  && d.userId == userId )) ? () => this.handleDeleteMyDocument(d.documentId) : undefined}
                        />
                      </div>
                    )) : <div style={{ textAlign: 'center', width: '100%' }} >You do not currently have access to any documents.</div>}
                  </div>
                </div>);
              
            }) : null}
          </div>
        </div>
        
        {showDeleteModal && (
          <DeleteModal
            cancel={this.handleDeleteModalHide}
            continue={this.handleDelete}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.apacOrderDocument.isLoading,
  isLoaded: state.apacOrderDocument.isLoaded,
  isSaving: state.apacOrderDocument.isSaving,
  isSaved: state.apacOrderDocument.isSaved,
  isDeleting: state.apacOrderDocument.isDeleting,
  isDeleted: state.apacOrderDocument.isDeleted,
  documents: state.apacOrderDocument.documents,
  documentTypes: state.apacOrderDocument.documentTypes,
  isAdmin: state.apacOrderDocument.isAdmin,
  errorMessage: state.apacOrderDocument.errorMessage,
  mbu: state.apacOrderDocument.mbu,
  requiredDocumentTypes: state.apacOrderDocument.requiredDocumentTypes,
  thirdPartyCanWrite: state.apacOrderDocument.thirdPartyCanWrite,
  userId: state.apacOrderDocument.userId,
});

const mapDispatchToProps = dispatch => {
  return {
    downloadDocument: url => dispatch(specialActions.downloadFile(url)),
    getOrderDocuments: trackingNumber => dispatch(apacOrderDocumentActions.getOrderDocuments(trackingNumber)),
    uploadOrderDocument: document => dispatch(apacOrderDocumentActions.uploadOrderDocument(document)),
    delete: id => dispatch(apacOrderDocumentActions.deleteDocument(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderDocuments);
