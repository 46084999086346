import {
  BOOKINGFORMS_REQUEST,
  BOOKINGFORMS_SUCCESS,
  BOOKINGFORMS_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const bookingFormReducer = (
  state = initialState.bookingFormState,
  action,
) => {
  switch (action.type) {
  case BOOKINGFORMS_REQUEST: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      bookingForms: [],
      errorMessage: '',
    };
  }
  case BOOKINGFORMS_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      bookingForms: action.payload,
      errorMessage: '',
    };
  }
  case BOOKINGFORMS_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      errorMessage: action.payload.message,
      bookingForms: [],
    };
  }
  default:
    return state;
  }
};
export default bookingFormReducer;
