/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CustomBarChart from '../../CustomCharts/CustomBarChart';
import Table from '../../CustomTables/CustomTable';
import '../demandAndSupply.scss';
import { TimeFilterEnum } from '../../Common/Enums/TimeFilterEnum';
import { doDataExport } from './CommonCarouselFunctions';
import MarketMultiSelectFilter from '../../CustomFilters/MultiSelect/MarketMultiSelectFilter';
import PeriodMultiSelectFilter from '../../CustomFilters/MultiSelect/PeriodMultiSelectFilter';
import { ExportChart } from '../../Common/Enums/ExportChart';


const MapeByTopTenItemsCarouselChart = (props) => {

  const bars = [
    { dataKey: 'Forecast Variance (%)', strokeColor: '#8884d8' },
  ];
  const tableHeaders = ['Position', 'Item Number', 'Item Description', 'Forecast Variance (%)'];

  const marketIds = props.mbus.map(market => market.marketId);
  const [selectedMarkets, setSelectedMarkets] = useState(marketIds);
  const [periodFilter, setPeriodFilter] = useState(TimeFilterEnum.YTD);
  const [selectedMonths, setSelectedMonths] = useState([]);

  const handleMarketFilterChange = (selectedMarkets) => {
    setSelectedMarkets(selectedMarkets);
  };

  const handlePeriodFilterChange = (newFilter) => {
    setPeriodFilter(newFilter);
  };

  const handleMonthsChange = (selectedMonths) => {
    setSelectedMonths(selectedMonths);
  };

  const months = [
    { key: 10, value: 'October' },
    { key: 11, value: 'November' },
    { key: 12, value: 'December' },
    { key: 1, value: 'January' },
    { key: 2, value: 'February' },
    { key: 3, value: 'March' },
    { key: 4, value: 'April' },
    { key: 5, value: 'May' },
    { key: 6, value: 'June' },
    { key: 7, value: 'July' },
    { key: 8, value: 'August' },
    { key: 9, value: 'September' },
  ];  

  const filters = [
    { key: 'Period', value: periodFilter },
    { key: 'Selected Months', value: selectedMonths.map(id => months.find(month => month.key === id)?.value).join(', ') },
    { key: 'Selected Markets', value: selectedMarkets.map(id => props.mbus?.find(m => m.marketId === id)?.name).join(', ') },
  ];

  let isValid = props.chartData !== undefined &&  props.chartData.length > 0; 
  
  let result = isValid && props.chartData.map((item, index) => {
    return {
      Position: index + 1,
      'Item Number': item.itemNumber,
      'Item Description': item.itemDescription,
      'Forecast Variance (%)': item.forecastVariance ?? 0,
    };
  });

  const handleSearchButtonClick = () => {
    props.getMapeByTopTenItemsData(periodFilter, selectedMonths, selectedMarkets);
  };

  const handleClearFilterClick = () => {
    var newPeriod = TimeFilterEnum.YTD;
    var newMonths = [];
    setSelectedMarkets(marketIds);
    setPeriodFilter(newPeriod);
    setSelectedMonths(newMonths);
    props.getMapeByTopTenItemsData(newPeriod, newMonths, marketIds); 
  };

  const handleExportSummary = () => {
    props.handleExportSummary(ExportChart.ForecastVarianceByTopTenItems,periodFilter,selectedMonths,selectedMarkets, null);
  };

  return (
    <div>
      <div className="group-details accordion download-container summary forecast-export">
        <div className="accordion-header">
          <h3>Download Summary</h3>
        </div>
        <div className="accordion-body horizontal-flex order-fields">
          <div>
            <button
              className="action export"
              type="button"
              title="Export"
              disabled={false}
              onClick={() => handleExportSummary()}
            >
              <div
                className="icon icon-download-light"
                alt="Export"
              />
            </button>
          </div>
        </div>
      </div>
      <div className='topTen-filter-container'>
        <MarketMultiSelectFilter 
          mbuList={props.mbus}
          selectedMarkets={selectedMarkets}
          onMarketFilterChange={handleMarketFilterChange}/>
        <PeriodMultiSelectFilter        
          periodFilter={periodFilter}
          selectedMonths={selectedMonths}
          onPeriodFilterChange={handlePeriodFilterChange}
          onSelectedMonths={handleMonthsChange}
          orderMetrics={false} />
      </div>
      <div className="kpi-filter-action">
        <button
          type="button"
          className="action"
          onClick={() => handleSearchButtonClick()}
        >
          Search
        </button>
        {<button
          type="clear"
          className="action default"
          onClick={() => handleClearFilterClick()}
        >
          Clear Filter
        </button>}
      </div>
      {isValid && (
        <><div id='topTenItems-carousel-chart-container'
          className='carousel-chart-container'>
          <CustomBarChart data={result}
            chartTitle={'Forecast Variance by Top 10 Items Spend'}
            bars={bars}
            dataKey={'Item Number'}
            width={'100%'} 
            showsPercentage={true} />
        </div>
        
        <div className='export-button-style'
          style={{ textAlign: 'left' }}>
          <button type="button"
            className="action export"
            onClick={() => doDataExport('Forecast Variance by Top Ten Items', 'topTenItems-carousel-chart-container', 'carousel-chart-container', filters)}>Export Chart</button>
        </div>
        
        <div className='mape-table-container'>
          <Table headers={tableHeaders}
            transposed={false}
            data={result} />
        </div></>
      )}

      {!isValid && <div>
        <p className='text-style'>There is no data available</p>
      </div>}
    </div>
  );
};
 
export default MapeByTopTenItemsCarouselChart;