import React from 'react';
import { connect } from 'react-redux';
import apacOrderTrackerActions from '../../../actions/apacOrderTrackerActions';
import { navigationActions } from '../../../actions/navigationActions';
import './InProgressSummary.scss';
import Pagination from '../../shared/Pagination';
import qs from 'query-string';

class InProgressSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      filterOrders: {
        shipmentTypeId: '',
        marketId: '',
        orderTypeId: '',
        orderStatusId: '',
        carrier: '',
        shipMethod: '',
        fulfilmentCentre: '',
        missingCommercial: '',
        splitShipment: '',
        missingPacking: '',
        missingCustom: '',
        fiscalWeek: '',
      },
      page: 1,
      pageSize: 25,
      displayMode: 1,
    };

    this.setDisplayMode = this.setDisplayMode.bind(this);
    this.handleFilterInputUpdate = this.handleFilterInputUpdate.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleFilterClear = this.handleFilterClear.bind(this);

    this.handleExportSummary = this.handleExportSummary.bind(this);
    this.handleExportResults = this.handleExportResults.bind(this);
    this.doPaging = this.doPaging.bind(this);
    this.initialiseState = this.initialiseState.bind(this);
    this.doFilter = this.doFilter.bind(this);
  }

  componentDidMount() {
    const newState = this.initialiseState();

    const { filterOrders, page, pageSize } = newState;

    this.props.getInProgressOrders({ ...filterOrders, page, pageSize });

    this.setState(newState);
  }

  initialiseState() {
    const {
      location,
    } = this.props;

    const {
      filter,
    } = this.state;

    const { search } = location;

    const filterOrders = {
      shipmentTypeId: '',
      marketId: '',
      orderTypeId: '',
      orderStatusId: '',
      carrier: '',
      shipMethod: '',
      fulfilmentCentre: '',
      missingCommercial: '',
      splitShipment: '',
      missingPacking: '',
      missingCustom: '',
      fiscalWeek: '',
    };
    
    let newState = {
      filterOrders : filterOrders,
      page: 1,
      pageSize: 25,
      displayMode: 1,
    };

    if (filter != '') {
      const parsed = qs.parse(search.toLowerCase());
 
      const newFilterOrders = {
        shipmentTypeId: parsed.shipmenttype ? parsed.shipmenttype : '',
        marketId:  parsed.market ? parsed.market : '',
        orderTypeId:  parsed.ordertype ? parsed.ordertype : '',
        orderStatusId:  parsed.orderstatus ? parsed.orderstatus : '',
        carrier:  parsed.carrier ? parsed.carrier : '',
        shipMethod:  parsed.shipmethod ? parsed.shipmethod : '',
        fulfilmentCentre:  parsed.fulfilmentcentre ? parsed.fulfilmentcentre : '',
        missingCommercial:  parsed.missingcommercial ? parsed.missingcommercial : '',
        splitShipment:  parsed.splitshipment ? parsed.splitshipment : '',
        missingPacking:  parsed.missingpacking ? parsed.missingpacking : '',
        missingCustom:  parsed.missingcustom ? parsed.missingcustom : '',
        fiscalWeek:  parsed.fiscalweek ? parsed.fiscalweek : '',
      };

      newState = {
        ...newState,
        filterOrders: newFilterOrders,
        page: parsed.page ? parseInt(parsed.page, 10) : 1,
        pageSize:25,
      };
    }

    return newState;
  }

  setDisplayMode(mode){
    this.setState({ displayMode: mode });
  }

  handleFilterInputUpdate(e, fieldName) {
    this.setState({ filterOrders: { ...this.state.filterOrders, [fieldName]: e.target.value } });
  }

  doFilter(){
    const oldState = this.state;

    this.handleFilter(oldState);
  }

  handleFilter(newState){
    const { pathname } = this.props.location;
    const {
      filterOrders,
      pageSize,
      page,
    } = newState;
    
    const { replace } = this.props;

    const filterString = `shipmentType=${filterOrders.shipmentTypeId}&market=${filterOrders.marketId}`
      + `&customerType=${filterOrders.orderTypeId}&orderStatus=${filterOrders.orderStatusId}&carrier=${filterOrders.carrier}`
      + `&shipMethod=${filterOrders.shipMethod}&fulfilmentCentre=${filterOrders.fulfilmentCentre}&missingCommercial=${filterOrders.missingCommercial}`
      + `&splitShipment=${filterOrders.splitShipment}&missingPacking=${filterOrders.missingPacking}&missingCustom=${filterOrders.missingCustom}&fiscalWeek=${filterOrders.fiscalWeek}`;
      
    replace(`${pathname}?page=${page}&${filterString}`);

    this.props.getInProgressOrders( { ...filterOrders, page, pageSize } );

    this.setState({ ...newState });
  }

  handleFilterClear(){
    const { pageSize } = this.state;

    const filterOrders = {
      shipmentTypeId: '',
      marketId: '',
      orderTypeId: '',
      orderStatusId: '',
      carrier: '',
      shipMethod: '',
      fulfilmentCentre: '',
      missingCommercial: '',
      splitShipment: '',
      missingPacking: '',
      missingCustom: '',
      fiscalWeek: '',
    };

    const newState = {
      filterOrders,
      page: 1,
      pageSize,
    };

    this.handleFilter(newState);  
  }

  doPaging(page) {
    const oldState = this.state;

    const newState = {
      ...oldState,
      page: page,
    };

    this.handleFilter(newState);
  }

  handleExportSummary(){
    const { isAdmin, isClosed, filterOrders } = this.state;

    this.props.exportInProgressOrders( { ...filterOrders, isAdmin, isClosed, summary:true } );
  }

  handleExportResults(){
    const { isAdmin, isClosed, filterOrders } = this.state;

    this.props.exportInProgressOrders( { ...filterOrders, isAdmin, isClosed, summary:false } );
  }

  render (){

    const { orders, isLoading, navigate, orderStatuses, orderTypes, mbus, shipmentTypes,carriers, fulfilments, shipMethods, fiscals, rowCount } = this.props;
    const { displayMode, filterOrders, page, pageSize } = this.state;
    const { setDisplayMode } = this;

    return(
      <div className='page'>
        <div className="floating-panel form-container">
          <div className="form-header apac">
            <div className="header-title">
              <div className="icon icon-table"></div>
              <h2>In Progress Summary</h2>
            </div>

            <div className="search-order-container">
              <div className="group-details accordion download-container"
                style={{ marginRight: '18px' } }>
                <div className="accordion-header">
                  <h3>Download Results</h3>
                </div>
                <div className="accordion-body horizontal-flex order-fields">
                  <div>
                    <button
                      className="action export"
                      onClick={() => this.handleExportResults()}
                      type="button"
                      title="Export"
                      disabled={isLoading}
                    >
                      <div
                        className="icon icon-download-light"
                        alt="Export"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="group-details accordion download-container summary"
                style={{ marginRight: '18px' } }
              >
                <div className="accordion-header">
                  <h3>Download Summary</h3>
                </div>
                <div className="accordion-body horizontal-flex order-fields">
                  <div>
                    <button
                      className="action export"
                      type="button"
                      title="Export"
                      disabled={isLoading}
                      onClick={() => this.handleExportSummary()}
                    >
                      <div
                        className="icon icon-download-light"
                        alt="Export"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='floating-panel form-container'>
          <div> 
            <div className='form-body'>
              <label>
                    Shipment Type
                <select
                  value={filterOrders.shipmentTypeId}
                  onChange={e => this.handleFilterInputUpdate(e, 'shipmentTypeId')}
                >
                  <option value=''>All</option>
                  {shipmentTypes && shipmentTypes.length && shipmentTypes.map(m => 
                    <option key={m.key}
                      value={m.key}>{m.value}</option>,
                  )
                  }
                </select>
              </label>
              <label>
                    MBU
                <select
                  value={filterOrders.marketId}
                  onChange={e => this.handleFilterInputUpdate(e, 'marketId')}
                >
                  <option value=''>All</option>
                  {mbus && mbus.length && mbus.map(m => 
                    <option key={m.key}
                      value={m.key}>{m.value}</option>,
                  )
                  }
                </select>
              </label>
              <label>
                  Order Type
                <select
                  value={filterOrders.orderTypeId}
                  onChange={e => this.handleFilterInputUpdate(e, 'orderTypeId')}
                >
                  <option value=''>All</option>
                  {orderTypes && orderTypes.length && orderTypes.map(o => 
                    <option key={o.key}
                      value={o.key}>{o.value}</option>,
                  )
                  }
                </select>
              </label>
              <label>
                  Order Type
                <select
                  value={filterOrders.orderStatusId}
                  onChange={e => this.handleFilterInputUpdate(e, 'orderStatusId')}
                >
                  <option value=''>All</option>
                  {orderStatuses && orderStatuses.length && orderStatuses.map(o => 
                    <option key={o.key}
                      value={o.key}>{o.value}</option>,
                  )
                  }
                </select>
              </label>
              <label>
                  Carrier
                <select
                  value={filterOrders.carrier}
                  onChange={e => this.handleFilterInputUpdate(e, 'carrier')}
                >
                  <option value=''>All</option>
                  {carriers && carriers.length && carriers.map(o => 
                    <option key={o.key}
                      value={o.key}>{o.value}</option>,
                  )
                  }
                </select>
              </label>
              <label>
                  Fulfilment Center
                <select
                  value={filterOrders.fulfilmentCentre}
                  onChange={e => this.handleFilterInputUpdate(e, 'fulfilmentCentre')}
                >
                  <option value=''>All</option>
                  {fulfilments && fulfilments.length && fulfilments.map(o => 
                    <option key={o.key}
                      value={o.key}>{o.value}</option>,
                  )
                  }
                </select>
              </label>
              <label>
                  Ship Method
                <select
                  value={filterOrders.shipMethod}
                  onChange={e => this.handleFilterInputUpdate(e, 'shipMethod')}
                >
                  <option value=''>All</option>
                  {shipMethods && shipMethods.length && shipMethods.map(o => 
                    <option key={o.key}
                      value={o.key}>{o.value}</option>,
                  )
                  }
                </select>
              </label>
              <label>
                  Fiscal Week
                <select
                  value={filterOrders.fiscalWeek}
                  onChange={e => this.handleFilterInputUpdate(e, 'fiscalWeek')}
                >
                  <option value=''>All</option>
                  {fiscals && fiscals.length && fiscals.map(o => 
                    <option key={o.key}
                      value={o.key}>{o.value}</option>,
                  )
                  }
                </select>
              </label>

            </div>
          </div>

          <div
            style={{ paddingBottom:'18px', display:'flex', justifyContent:'flex-end' }}
          >
            <button onClick={() => this.doFilter()}
              type='button'
              style={{ marginRight:'18px' }}
              className='action'>
                    Filter
            </button>

                
            <button onClick={() => this.handleFilterClear()}
              type='button'
              className='action negative'>
                    Clear Filter
            </button>
          </div>
        </div>
        
        <div className={'customer-ordertracker-container in-progress'}>
          <div className="table-container">
            <div className="table-header">
              <div className="display-options">
            Table Size
                <button
                  type="button"
                  className="icon-display-mode-1"
                  disabled={displayMode === 1}
                  onClick={() => setDisplayMode(1)}
                />
                <button
                  type="button"
                  className="icon-display-mode-2"
                  disabled={displayMode === 2}
                  onClick={() => setDisplayMode(2)}
                />
                <button
                  type="button"
                  className="icon-display-mode-3"
                  disabled={displayMode === 3}
                  onClick={() => setDisplayMode(3)}
                />
              </div>
              <h2>
            Search Results
              </h2>
              <div className="pagination-controls">
                <Pagination
                  rowCount={rowCount}
                  page={page}
                  pageSize={pageSize}
                  doPaging={this.doPaging}
                />
              </div>

            </div>
            <div className="table-body">
              <table className={`table-display-mode-${displayMode}`}>
                <thead>
                  <tr>
                    <th>MBU</th>
                    <th>Customer Number</th>
                    <th>Tracking Number</th>
                    <th>Starbucks Order Number</th>
                    <th>Order Type</th>
                    <th>Order Status</th>
                    <th>Days Delayed</th>
                    <th>Order Date</th>
                    <th>View Details</th>
                  </tr>
                </thead>
                <tbody>
                  {orders && orders.length === 0 &&
                <tr>
                  <td
                    colSpan={9}
                    className="no-records"
                    style={{ textAlign: 'center' } }
                  >
                    {isLoading ? 'Loading...' : 'No orders found.'}
                  </td>
                </tr>
                  }
                  {orders && orders.length > 0 && orders.map(o => 
                    <tr key={o.id}>
                      <td>{o.mbu}</td>
                      <td>{o.customerNumber}</td>
                      <td>{o.trackingNumber}</td>
                      <td>{o.sbOrderNumber}</td>
                      <td>{orderTypes.find(m => m.key == o.orderTypeId)?.value ?? o.orderTypeId}</td>
                      <td>{orderStatuses.find(m => m.key == o.orderStatusId)?.value ?? o.orderStatusId}</td>
                      <td>{o.daysDelayed ?? 0}</td>
                      <td>{o.orderDate && new Date(o.orderDate).toLocaleDateString()}</td>
                      <td>
                        <button
                          className="link"
                          type="button"
                          onClick={() => navigate(`/module/220/AOT-ShippingDetails/${o.trackingNumber}`)}>
                        View Details
                        </button>
                      </td>
                    </tr>,
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) =>  {
  return {
    isLoading: state.apacOrderTracker.isLoading,
    isLoaded: state.apacOrderTracker.isLoaded,
    error: state.apacOrderTracker.error,
    orders: state.apacOrderTracker.orders,
    pageNumber: state.apacOrderTracker.pageNumber,
    pageCount: Math.ceil(state.apacOrderTracker.totalRecords / state.apacOrderTracker.pageSize),
    rowCount: state.apacOrderTracker.totalRecords,
    shipmentTypes: state.apacOrderTracker.shipmentTypes,
    orderTypes: state.apacOrderTracker.orderTypes,
    orderStatuses: state.apacOrderTracker.orderStatuses,
    mbus: state.apacOrderTracker.mbus,
    carriers: state.apacOrderTracker.carriers,
    fulfilments: state.apacOrderTracker.fulfilments,
    shipMethods: state.apacOrderTracker.shipMethods,
    fiscals: state.apacOrderTracker.fiscals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    exportInProgressOrders: (filter) => dispatch(apacOrderTrackerActions.exportInProgressSummary(filter)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    navigate: (path) => dispatch(navigationActions.pushNavigation(path)),
    getInProgressOrders: (filter) => dispatch(apacOrderTrackerActions.getInProgressSummary(filter)), 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InProgressSummary);
