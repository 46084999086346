import React, { useState } from 'react';

function BulkStatusChangeModal(props) {
  const [comments, setComments] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [status, setStatus] = useState('');

  const bulkfinalise = () => {
    async function loadFormData(){
      const response = await fetch('/api/fcr/v1/bulkfinalise', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ 
          selectedIds: props.selectedIds,
          comments: comments,
          status: status,
        }),
      });

      if (response.status == 200){
        props.complete();
      }
      else {
        setResponseMessage('Something went wrong. If this issue persists, contact an administrator.');
      }
    }
    loadFormData();
  };

  return (
    <div className="modal-blocker">
      <div className="modal">
        <form>
          <div className="modal-menu">
            <button
              type="button"
              className="action"
              onClick={() => props.cancel()}
            >
              X
            </button>
          </div>
          <div className="modal-title">
            <h2>Bulk Edit</h2>
            <div><b>{props.selectedIds.length}</b> requests have been selected.</div>
          </div>
          <div className="modal-body">
            <label>
                   Status
              <select
                value={status}
                onChange={e => setStatus(e.target.value)}
              >
                <option value=''>Select a Status</option>
                <option key={'approve'}
                  value={'approve'}>Approve</option>
                <option key={'reject'}
                  value={'reject'}>Reject</option>
              </select>
            </label>
            <label>
              Starbucks Comments:{' '}
              <textarea
                maxLength={500}
                type="text"
                onChange={(e) => setComments(e.target.value)}
                value={comments}
              />
            </label>
            <p>Are you sure you want to continue?</p>
            <p>This decision will be final.</p>

            {responseMessage}
          </div>
          <div className="modal-buttons">
            <button
              type="button"
              className="action"
              disabled={comments == '' || status == ''}
              onClick={() => bulkfinalise()}
            >
              Continue
            </button>
            <button
              type="button"
              className="action negative"
              onClick={() => props.cancel()}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BulkStatusChangeModal;
