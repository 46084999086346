import {
  PUSHNAVIGATION_REQUEST,
  REPLACENAVIGATION_REQUEST,
  BACKNAVIGATION_REQUEST,
  RESETNAVIGATION_REQUEST,
} from './actionTypes';

export const navigationActions = {
  pushNavigation: url => (dispatch, getState) => {
    dispatch({
      type: PUSHNAVIGATION_REQUEST,
      payload: {
        url,
      },
    });
  },
  replaceNavigation: url => (dispatch, getState) => {
    dispatch({
      type: REPLACENAVIGATION_REQUEST,
      payload: {
        url,
      },
    });
  },
  backNavigation: url => (dispatch, getState) => {
    dispatch({
      type: BACKNAVIGATION_REQUEST,
      payload: {},
    });
  },
  resetNavigation: url => (dispatch, getState) => {
    dispatch({
      type: RESETNAVIGATION_REQUEST,
      payload: {},
    });
  },
};
