import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { notificationActions } from '../../../actions/notificationActions';
import MessageRowAdmin from '../MessageRowAdmin/MessageRowAdmin';
import DeleteModal from '../../shared/DeleteModal';

class MessageAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      notificationId: undefined,
    };

    this.handleCreate = this.handleCreate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);
  }

  componentDidMount() {
    this.props.requestAlerts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isDeleted && !prevProps.isDeleted) {
      this.props.requestAlerts();
    }
  }

  handleDeleteModalShow(notificationId) {
    this.setState({ showDeleteModal: true, notificationId });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false, notificationId: undefined });
  }

  handleCreate() {
    const { pathname } = this.props.location;

    this.props.push(`${pathname}/0`);
  }

  handleEdit(notificationId) {
    const { pathname } = this.props.location;

    this.props.push(`${pathname}/${notificationId}`);
  }

  handleDelete() {
    this.props.deleteAlert(this.state.notificationId);
    this.handleDeleteModalHide();
  }

  render() {
    let { showDeleteModal } = this.state;
    return (
      <div className="message-admin-page page">
        <div className="floating-panel">
          <div className="floating-header-2">
            <div className="header-title">
              <div className="icon icon-alert"></div>
              <h2>Notifications</h2>
            </div>
            <div className="header-buttons">
              <button
                className="action"
                type="button"
                onClick={() => this.handleCreate()}>
                Create New
              </button>
            </div>
          </div>
          <div className="message-admin-list">
            {this.props.adminAlerts.map(p => (
              <MessageRowAdmin
                key={p.notificationId}
                data={p}
                onEdit={() => this.handleEdit(p.notificationId)}
                onDelete={() => this.handleDeleteModalShow(p.notificationId)}
              />
            ))}
          </div>
          <div>
            {showDeleteModal && (
              <DeleteModal
                cancel={this.handleDeleteModalHide}
                continue={this.handleDelete}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  adminAlerts: state.notification.adminAlerts,
  isDeleted: state.notification.isDeleted,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    requestAlerts: () => dispatch(notificationActions.requestAlerts(1, 25)),
    deleteAlert: notificationId =>
      dispatch(notificationActions.deleteAlert(notificationId)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessageAdmin);
