import {
  CHAT_TOKEN_REQUEST,
  CHAT_TOKEN_SUCCESS,
  CHAT_TOKEN_FAILURE,
} from '../actions/actionTypes';

export const ChatbotReducer = (
  state = {},
  action,
) => {
  switch (action.type) {
  case CHAT_TOKEN_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      error: undefined,
      token: undefined,
    };

  case CHAT_TOKEN_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: undefined,
      token: action.payload.token,
    };

  case CHAT_TOKEN_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: action.payload.message,
      token: undefined,
    };

  default:
    return state;
  }
};
export default ChatbotReducer;
