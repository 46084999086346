import {
  MODULEDATA_REQUEST,
  MODULEDATA_SUCCESS,
  MODULEDATA_FAILURE,
  USERDATA_REQUEST,
  USERDATA_SUCCESS,
  USERDATA_FAILURE,
  ACTIONDATA_REQUEST,
  ACTIONDATA_SUCCESS,
  ACTIONDATA_FAILURE,
} from './actionTypes';

export const platformUsageActions = {
  requestModuleData: (from, to) => (dispatch, getState) => {
    dispatch({
      type: MODULEDATA_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/platformusage/v1/GetModuleUsage?from=${from.toISOString()}&to=${to.toISOString()}`,
        successAction: MODULEDATA_SUCCESS,
        failureAction: MODULEDATA_FAILURE,
      },
    });
  },
  requestUserData: (from, to) => (dispatch, getState) => {
    dispatch({
      type: USERDATA_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/platformusage/v1/GetUserSessions?from=${from.toISOString()}&to=${to.toISOString()}`,
        successAction: USERDATA_SUCCESS,
        failureAction: USERDATA_FAILURE,
      },
    });
  },
  requestActionData: (from, to) => (dispatch, getState) => {
    dispatch({
      type: ACTIONDATA_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/platformusage/v1/GetActionsTaken?from=${from.toISOString()}&to=${to.toISOString()}`,
        successAction: ACTIONDATA_SUCCESS,
        failureAction: ACTIONDATA_FAILURE,
      },
    });
  },
};
