import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { navigationActions } from '../../../actions/navigationActions';
import { connectContentActions } from '../../../actions/connectContentActions';
import { connectReportingActions } from '../../../actions/connectReportingActions';
import ConnectMenu from './connectMenu/connectMenu';
import ConnectContent from './connectContent/connectContent';
import ConnectHeader from './connectHeader/connectHeader';
import Template1 from '../templates/template1/template1';
import Template2 from '../templates/template2/template2';
import Template3 from '../templates/template3/template3';
import Template4 from '../templates/template4/template4';
import Template5 from '../templates/template5/template5';
import Template6 from '../templates/template6/template6';
import Template7 from '../templates/template7/template7';
import Template8 from '../templates/template8/template8';
import Template9 from '../templates/template9/template9';
import Template10 from '../templates/template10/template10';
import Template11 from '../templates/template11/template11';
import ConnectSideBar from './connectSideBar/connectSideBar';
import ConnectFooter from '../../platform/footer/connectFooter';

class Connect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      reads: [],
      views: [],
    };

    this.handleContinueToPortal = this.handleContinueToPortal.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    this.props.getContent();

    this.props.logUserAction({
      metricTypeId: 4, //Connect Used.
      actionName: 'Loaded',
    });
  }

  handleContinueToPortal(event) {
    this.props.hideConnect();

    this.props.push('/');

    event.preventDefault();
  }

  handleSearch(term) {
    this.setState({ searchTerm: term.toLowerCase() });
  }

  onRead(contentId, contentTitle) {
    const { logUserAction } = this.props;
    const { reads } = this.state;

    if (reads.indexOf(contentId) === -1) {

      this.setState({ reads: [...reads, contentId] });

      logUserAction({
        metricTypeId: 1, //Content Read.
        actionId: contentId,
        actionName: contentTitle,
      });
    }

  }

  onWatch(contentId, contentTitle) {

    const { logUserAction } = this.props;
    const { views } = this.state;

    if (views.indexOf(contentId) === -1) {

      this.setState({ views: [...views, contentId] });

      logUserAction({
        metricTypeId: 2, //Video watched.
        actionId: contentId,
        actionName: contentTitle,
      });
    }
  }

  getContentTemplates() {
    const { content, storeData, storeGroupData, isAdmin } = this.props;
    const { searchTerm } = this.state;

    let contentList = content
      .filter(c => !searchTerm ||
        (c.title && c.title.toLowerCase().indexOf(searchTerm) > -1) ||
        (c.message && c.message.toLowerCase().indexOf(searchTerm) > -1) ||
        (c.label && c.label.text && c.label.text.toLowerCase().indexOf(searchTerm) > -1))
      .map(c => {
        switch (c.templateId) {
        case 1:
          return <Template1
            id={c.contentId}
            key={c.contentId}
            label={c.label && c.label.text}
            subHeading={c.title}
            preview={c.preview}
            content={c.message}
            background={c.backgroundImage}
            assetData={c.imageContent1}
            attachment={c.attachment}
            fontColor={c.fontColor}
            targetType={isAdmin && c.targetType}
            targetTypeIds={isAdmin && c.targetTypeIds}
            targetTypeData={isAdmin && c.targetType && c.targetType.toLowerCase().indexOf('group') > -1 ? storeGroupData: storeData }
            onRead={() => this.onRead(c.contentId, c.title)}
            onWatch={() => this.onWatch(c.contentId, c.title)}
          />;
        case 2:
          return <Template2
            id={c.contentId}
            key={c.contentId}
            label={c.label && c.label.text}
            subHeading={c.title}
            preview={c.preview}
            content={c.message}
            background={c.backgroundImage}
            assetData={c.imageContent1}
            attachment={c.attachment}
            fontColor={c.fontColor}
            targetType={isAdmin && c.targetType}
            targetTypeIds={isAdmin && c.targetTypeIds}
            targetTypeData={isAdmin && c.targetType && c.targetType.toLowerCase().indexOf('group') > -1 ? storeGroupData: storeData }
            onRead={() => this.onRead(c.contentId, c.title)}
            onWatch={() => this.onWatch(c.contentId, c.title)}
          />;
        case 3:
          return <Template3
            id={c.contentId}
            key={c.contentId}
            label={c.label && c.label.text}
            subHeading={c.title}
            preview={c.preview}
            content={c.message}
            background={c.backgroundImage}
            assetData={c.imageContent1}
            attachment={c.attachment}
            fontColor={c.fontColor}
            targetType={isAdmin && c.targetType}
            targetTypeIds={isAdmin && c.targetTypeIds}
            targetTypeData={isAdmin && c.targetType && c.targetType.toLowerCase().indexOf('group') > -1 ? storeGroupData: storeData }
            onRead={() => this.onRead(c.contentId, c.title)}
            onWatch={() => this.onWatch(c.contentId, c.title)}
          />;
        case 4:
          return <Template4
            id={c.contentId}
            key={c.contentId}
            label={c.label && c.label.text}
            subHeading={c.title}
            preview={c.preview}
            content={c.message}
            background={c.backgroundImage}
            assetData={c.imageContent1}
            attachment={c.attachment}
            fontColor={c.fontColor}
            targetType={isAdmin && c.targetType}
            targetTypeIds={isAdmin && c.targetTypeIds}
            targetTypeData={isAdmin && c.targetType && c.targetType.toLowerCase().indexOf('group') > -1 ? storeGroupData: storeData }
            onRead={() => this.onRead(c.contentId, c.title)}
            onWatch={() => this.onWatch(c.contentId, c.title)}
          />;
        case 5:
          return <Template5
            id={c.contentId}
            key={c.contentId}
            label={c.label && c.label.text}
            subHeading={c.title}
            preview={c.preview}
            content={c.message}
            background={c.backgroundImage}
            assetData={c.imageContent1}
            attachment={c.attachment}
            fontColor={c.fontColor}
            targetType={isAdmin && c.targetType}
            targetTypeIds={isAdmin && c.targetTypeIds}
            targetTypeData={isAdmin && c.targetType && c.targetType.toLowerCase().indexOf('group') > -1 ? storeGroupData: storeData }
            onRead={() => this.onRead(c.contentId, c.title)}
            onWatch={() => this.onWatch(c.contentId, c.title)}
          />;
        case 6:
          return <Template6
            id={c.contentId}
            key={c.contentId}
            label={c.label && c.label.text}
            subHeading={c.title}
            preview={c.preview}
            content={c.message}
            background={c.backgroundImage}
            assetData={c.imageContent1}
            attachment={c.attachment}
            fontColor={c.fontColor}
            targetType={isAdmin && c.targetType}
            targetTypeIds={isAdmin && c.targetTypeIds}
            targetTypeData={isAdmin && c.targetType && c.targetType.toLowerCase().indexOf('group') > -1 ? storeGroupData: storeData }
            onRead={() => this.onRead(c.contentId, c.title)}
            onWatch={() => this.onWatch(c.contentId, c.title)}
          />;
        case 7:
          return <Template7
            id={c.contentId}
            key={c.contentId}
            label={c.label && c.label.text}
            subHeading={c.title}
            preview={c.preview}
            content={c.message}
            background={c.backgroundImage}
            assetData={c.imageContent1}
            attachment={c.attachment}
            fontColor={c.fontColor}
            targetType={isAdmin && c.targetType}
            targetTypeIds={isAdmin && c.targetTypeIds}
            targetTypeData={isAdmin && c.targetType && c.targetType.toLowerCase().indexOf('group') > -1 ? storeGroupData: storeData }
            onRead={() => this.onRead(c.contentId, c.title)}
            onWatch={() => this.onWatch(c.contentId, c.title)}
          />;
        case 8:
          return <Template8
            id={c.contentId}
            key={c.contentId}
            label={c.label && c.label.text}
            subHeading={c.title}
            preview={c.preview}
            content={c.message}
            background={c.backgroundImage}
            assetData={c.imageContent1}
            attachment={c.attachment}
            fontColor={c.fontColor}
            targetType={isAdmin && c.targetType}
            targetTypeIds={isAdmin && c.targetTypeIds}
            targetTypeData={isAdmin && c.targetType && c.targetType.toLowerCase().indexOf('group') > -1 ? storeGroupData: storeData }
            onRead={() => this.onRead(c.contentId, c.title)}
            onWatch={() => this.onWatch(c.contentId, c.title)}
          />;
        case 9:
          return <Template9
            id={c.contentId}
            key={c.contentId}
            label={c.label && c.label.text}
            subHeading={c.title}
            preview={c.preview}
            content={c.message}
            background={c.backgroundImage}
            assetData={c.imageContent1}
            attachment={c.attachment}
            fontColor={c.fontColor}
            targetType={isAdmin && c.targetType}
            targetTypeIds={isAdmin && c.targetTypeIds}
            targetTypeData={isAdmin && c.targetType && c.targetType.toLowerCase().indexOf('group') > -1 ? storeGroupData: storeData }
            onRead={() => this.onRead(c.contentId, c.title)}
            onWatch={() => this.onWatch(c.contentId, c.title)}
          />;
        case 10:
          return <Template10
            id={c.contentId}
            key={c.contentId}
            label={c.label && c.label.text}
            subHeading={c.title}
            preview={c.preview}
            content={c.message}
            background={c.backgroundImage}
            assetData={c.imageContent1}
            attachment={c.attachment}
            fontColor={c.fontColor}
            targetType={isAdmin && c.targetType}
            targetTypeIds={isAdmin && c.targetTypeIds}
            targetTypeData={isAdmin && c.targetType && c.targetType.toLowerCase().indexOf('group') > -1 ? storeGroupData: storeData }
            onRead={() => this.onRead(c.contentId, c.title)}
            onWatch={() => this.onWatch(c.contentId, c.title)}
          />;
        case 11:
          return <Template11
            id={c.contentId}
            key={c.contentId}
            label={c.label && c.label.text}
            subHeading={c.title}
            preview={c.preview}
            content={c.message}
            background={c.backgroundImage}
            assetData={c.imageContent1}
            attachment={c.attachment}
            fontColor={c.fontColor}
            targetType={isAdmin && c.targetType}
            targetTypeIds={isAdmin && c.targetTypeIds}
            targetTypeData={isAdmin && c.targetType && c.targetType.toLowerCase().indexOf('group') > -1 ? storeGroupData: storeData }
            onRead={() => this.onRead(c.contentId, c.title)}
            onWatch={() => this.onWatch(c.contentId, c.title)}
          />;
        default:
          return null;
        }

      });

    contentList = contentList.filter(c => c !== null);

    if (contentList.length === 0) {

      if (searchTerm) {
        return <div className="no-items" > No content matched your search criteria. Please check the spelling of the item your looking for.</div>;
      }
      return <div className="no-items">There is currently no content to be displayed.</div>;
    }

    return contentList;
  }

  render() {
    const { isAdmin, config } = this.props;
    return (
      <div className="connect-page page">
        <ConnectHeader
          search={this.handleSearch}
          isAdmin={isAdmin}
        />

        <div className="horizontal-connect">
          <ConnectMenu />

          <ConnectSideBar 
            config = {config}
          />
          <div className="container">
            <ConnectContent>
              {this.getContentTemplates()}
            </ConnectContent>
          
            <ConnectFooter />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  content: state.connectContent.displayContent,
  isAdmin: state.connectContent.isDisplayAdmin,
  storeData:state.connectContent.displayStores,
  storeGroupData:state.connectContent.displayGroups,
  config: state.connectContent.config,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    logout: () => dispatch(authActions.requestLogout()),
    hideConnect: () => dispatch(authActions.hideConnect()),

    //connect action
    getContent: () => dispatch(connectContentActions.getContentDisplay()),

    logUserAction: (data) => dispatch(connectReportingActions.logUserAction(data)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Connect);
