import React from 'react';
import qs from 'query-string';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { connectContentActions } from '../../../actions/connectContentActions';
import Pagination from '../../shared/Pagination';

class ContentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isArchive:false,
      filter: '',
      labelId: undefined,
      publishedDate: '',
      page: 1,
      pageSize: 25,
      displayMode: 1,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.setDisplayMode = this.setDisplayMode.bind(this);

    this.doPaging = this.doPaging.bind(this);
    this.doSearch = this.doSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);

    this.navigateItemDetails = this.navigateItemDetails.bind(this);
  }

  componentDidMount() {
    const { pathname, search } = this.props.location;

    const archiveScreen = pathname.toLowerCase().indexOf('archive') > -1;

    let newState = {
      isArchive: archiveScreen,
      pageSize: 25,
      filter: '',
      labelId: undefined,
      publishedDate: '',
      page: 1,
      displayMode: 1,
    };

    if (search) {
      const parsed = qs.parse(search.toLowerCase());

      newState = {
        ...newState,
        filter: parsed.filter ? parsed.filter : '',
        labelId: parsed.labelid ? parseInt(parsed.labelid) : undefined,
        publishedDate: parsed.publisheddate ? parsed.publisheddate : '',
        page: parsed.page ? parseInt(parsed.page) : 1,
        displayMode: parsed.displaymode ? parseInt(parsed.displaymode) : 1,
      };
    }

    this.setState(newState);

    if (newState.isArchive) {
      this.props.getContentArchive(
        newState.filter,
        newState.labelId,
        newState.publishedDate,
        newState.page,
        newState.pageSize,
      );
    }else{
      this.props.getContentList(
        newState.filter,
        newState.labelId,
        newState.publishedDate,
        newState.page,
        newState.pageSize,
      );
    }
  }

  handleSearch(e, field) {
    this.setState({ [field]: e.target.value });
    e.preventDefault();
  }

  navigateItemDetails(contentId) {
    const { pathname } = this.props.location;
    this.props.push(`${pathname}/ContentBuilder/${contentId}`);
  }

  clearSearch(){
    this.setState({ filter:'', labelId: undefined, publishedDate: '', page: 1 });
    
    this.handleFilterChange({ ...this.state, filter:'', labelId: undefined, publishedDate: '', page: 1 });
  }

  doSearch() {
    this.setState({ page: 1 });

    this.handleFilterChange({ ...this.state, page: 1  });
  }

  doPaging(page) {
    this.setState({ page });

    this.handleFilterChange({ ...this.state, page });
  }

  setDisplayMode(mode) {
    this.setState({ displayMode: mode });
  }

  handleFilterChange(currentState) {
    const { pathname } = this.props.location;
    const {
      filter,
      labelId,
      publishedDate,
      displayMode,
      pageSize,
      page,
      isArchive,
    } = currentState;

    this.props.replace(
      `${pathname}?filter=${filter}&page=${page}&displayMode=${displayMode}`,
    );

  
    if (isArchive) {
      this.props.getContentArchive(
        filter,
        labelId,
        publishedDate,
        page,
        pageSize,
      );
    }else{
      this.props.getContentList(
        filter,
        labelId,
        publishedDate,
        page,
        pageSize,
      );
    }
  }

  render() {
    const { rowCount, contentList, labelList } = this.props;
    const {
      page,
      pageSize,
      filter,
      labelId,
      publishedDate,
      displayMode,
      isArchive,
    } = this.state;
    const {
      setDisplayMode,
      handleSearch,
      doSearch,
      clearSearch,
      doPaging,
      navigateItemDetails,
    } = this;
    return (
      <div className="content-list-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div
                className={'icon icon-table'}></div>
              <h2>
                {isArchive ? 'Connect Content Archive'
                  : 'Connect Content'}
              </h2>
            </div>
            {isArchive?null:
              <button
                type="button"
                className="action"
                onClick={() =>
                  navigateItemDetails(0)
                }
              >
              New Content
              </button>}
          </div>
        </div>
        <div className="content-list-header page-header">
          <div></div>
          <div className="header-buttons">
            <div className="search">
              <input
                type="text"
                placeholder="Search..."
                value={filter}
                onChange={e => handleSearch(e, 'filter')}
              />
              <select
                type="text"
                placeholder="Label..."
                value={labelId}
                onChange={e => handleSearch(e, 'labelId')}
              >
                <option value="">Label...</option>
                {labelList && labelList.map(l => 
                  <option key={l.labelId}
                    value={l.labelId} >{l.text}</option>)}
              </select>
              <input
                type="date"
                placeholder="Published Date... (dd/mm/yyyy"
                value={publishedDate}
                onChange={e => handleSearch(e, 'publishedDate')}
              />
            </div><div className="search">
              <button type="button"
                className="action"
                onClick={() => doSearch()}>
                Search
              </button>
              
              <button type="button"
                className="action"
                onClick={() => clearSearch()}>
                Clear
              </button>
            </div>
          </div>
        </div>
        <div className="content-list-container table-container">
          <div className="table-header">
            <div className="display-options">
              Table Size
              <button
                type="button"
                className="icon-display-mode-1"
                disabled={displayMode === 1}
                onClick={() => setDisplayMode(1)}></button>
              <button
                type="button"
                className="icon-display-mode-2"
                disabled={displayMode === 2}
                onClick={() => setDisplayMode(2)}></button>
              <button
                type="button"
                className="icon-display-mode-3"
                disabled={displayMode === 3}
                onClick={() => setDisplayMode(3)}></button>
            </div>
            <h2>
              {isArchive ? 'Connect Content Archive'
                : 'Connect Content'}
            </h2>
            <div className="pagination-controls">
              <Pagination
                rowCount={rowCount}
                page={page}
                pageSize={pageSize}
                doPaging={doPaging}
              />
            </div>
          </div>
          <div className="table-body">
            <table className={`table-display-mode-${displayMode}`}>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Label</th>
                  {this.props.match.params.moduleId !== '975' ? <th>Template</th> : null }
                  <th>Priority</th>
                  <th>Start</th>
                  <th>End</th>
                  {this.props.match.params.moduleId !== '975' ? <th>Created By</th> : null }
                  <th>Last Modified</th>
                  {isArchive && <th>Archived</th>}
                  <th />
                </tr>
              </thead>
              <tbody>
                {contentList.map(item => (
                  <tr
                    key={item.contentId}>
                    <td>{item.title}</td>
                    <td>{item.label}</td>
                    {this.props.match.params.moduleId !== '975' ? <td>{item.template}</td> : null }
                    
                    <td>{item.priority}</td>
                    <td>{item.startDate && item.startDate.replace('T', ' ')}</td>
                    <td>{item.endDate && item.endDate.replace('T', ' ')}</td>
                    {this.props.match.params.moduleId !== '975' ? <td>{item.createdBy}</td> : null }
                    <td>{item.modified && item.modified.replace('T', ' ')}</td>
                    {isArchive && <td>{item.archived && item.archived.replace('T', ' ')}</td>}
                    <td className="right-cell">
                      <button
                        type="button"
                        className="action"
                        onClick={() =>
                          navigateItemDetails(item.contentId)
                        }
                      >
                        {isArchive ? 'View' : 'Edit'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="table-header">
            <div></div>
            <div className="pagination-controls">
              <Pagination
                rowCount={rowCount}
                page={page}
                pageSize={pageSize}
                doPaging={doPaging}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let { connectContent } = state;
  return {
    isLoading: connectContent.isLoading,
    isLoaded: connectContent.isLoaded,
    contentList: connectContent.contentList,
    labelList: connectContent.labelList,
    pageNumber: connectContent.pageNumber,
    pageCount: Math.ceil(connectContent.totalRecords / connectContent.pageSize),
    rowCount: connectContent.totalRecords,
    errorMessage: connectContent.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getContentList: (filter,labelId, publishedDate, page, pageSize) => dispatch(connectContentActions.getContentList(filter,labelId, publishedDate, page, pageSize)),
    getContentArchive: (filter,labelId, publishedDate, page, pageSize) => dispatch(connectContentActions.getContentArchive(filter,labelId, publishedDate, page, pageSize)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentList);
