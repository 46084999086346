import {
  VASDETAIL_REQUEST,
  VASDETAIL_SUCCESS,
  VASDETAIL_FAILURE,
} from './actionTypes';

export const vasActions = {
  requestVasDetail: () => (dispatch, getState) => {
    dispatch({
      type: VASDETAIL_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/vasForm/v1/Get',
        successAction: VASDETAIL_SUCCESS,
        failureAction: VASDETAIL_FAILURE,
      },
    });
  },
};
