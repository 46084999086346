
export const calculateAge = (createdAt) => {
  if (!createdAt) {
    return '';
  }

  const age = getDaysBetweenDates(new Date(), createdAt);

  return age < 0 ? '' : age;
};

export const calculateDaysDelayed = (createdAt, progress5, bespokeDelay, progress6, status) => {
  if (!createdAt) {
    return '';
  }

  if (!progress5 && !bespokeDelay) {
    return '';
  }

  if (bespokeDelay) {
    const age = calculateAge(createdAt);
    if (age === '') {
      return '';
    }

    const daysDelayed = age - 30;

    return daysDelayed < 0 ? '' : daysDelayed;
  }

  const pickupPlannedDate = new Date(progress5);
  if (isNaN(pickupPlannedDate.getTime())){
    return '';
  }

  let daysDelayed;
  const shippedDate = new Date(progress6);

  if (progress6.length && !isNaN(pickupPlannedDate.getTime())) {
    daysDelayed = getDaysBetweenDates(shippedDate, pickupPlannedDate); 
  } else if (status !== 1) {
    daysDelayed = getDaysBetweenDates(new Date(), pickupPlannedDate); 
  } else {
    return '';
  }

  return daysDelayed < 0 ? '' : daysDelayed;
};

const getDaysBetweenDates = (d1, d2) => {
  const dayInMs = 24 * 60 * 60 * 1000;

  const date1 = new Date(d1);
  date1.setHours(0, 0, 0, 0);

  const date2 = new Date(d2);
  date2.setHours(0, 0, 0, 0);

  return Math.round(Math.ceil((date1 - date2) / dayInMs));
};

export const sanitiseNumberInput = (event) => {
  if (event.key.length === 1 && /\D/.test(event.key)) {
    event.preventDefault();
  }
};
