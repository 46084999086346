/* eslint-disable react/jsx-key */
import React from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  BarChart,
  Legend,
  Brush,
  ReferenceLine,
} from 'recharts';
import './charts.scss';

function CustomBarChart({ data, bars, width, xAxisFontSize, chartTitle, dataKey, description, showsPercentage }) {

  const DataFormater = (number) => {
    if(showsPercentage){
      return  `${number}%`;
    }
    if(number > 1000000000){
      return (number/1000000000).toString() + 'B';
    }else if(number > 1000000){
      return (number/1000000).toString() + 'M';
    }else if(number > 1000){
      return (number/1000).toString() + 'K';
    }else{
      return number.toString();
    }

  };
  
  return (
    <>
      <div><p className="text-center">{chartTitle}</p></div>
      <ResponsiveContainer width={width}
        height={450}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>

          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={dataKey ?? 'name'}
            interval={0} 
            tick={{ fontSize: xAxisFontSize ?? 10 }} />
          <YAxis tickFormatter={DataFormater}/>
          <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
          <Legend />
          <ReferenceLine y={0} 
            stroke='#000'/>
          <Brush dataKey={dataKey ?? 'name'}
            height={20} 
            stroke="green"/>
          {bars && bars.map((bar) => (
            <Bar
              dataKey={bar.dataKey}
              fill={bar.strokeColor} />
          ))}
        </BarChart>
      </ResponsiveContainer>

      <br/>
      <h5 className="explanation-style">
        {' '}
        {description}
      </h5>
    </>
  );
}
export default CustomBarChart;