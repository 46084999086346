import React from "react";

class FileSaveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaName: undefined,
      mediaType: undefined,
      mediaData: undefined,
      description: ""
    };
  }

  componentDidMount() {
    const { description } = this.props;

    if (description) {
      this.setState({ description })
    }
  }

  handleDescriptionChange = e => {
    this.setState({ description: e.target.value });
  };

  handleFileInputUpdate = e => {
    if (!e.target.files || e.target.files.length === 0) return;
    let currentComponent = this;
    let doc = {
      mediaName: e.target.files[0].name,
      description: e.target.files[0].name,
      mediaType: e.target.files[0].type
    };

    var reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }).then(data => {
      doc.mediaData = data ? data.replace(/^data:(.*,)?/, "") : "";

      currentComponent.setState(doc);
    });
  };
  render() {
    return (
      <div className="modal-blocker">
        <div className="modal">
          <form>
            <div className="modal-menu">
              <button
                type="button"
                className="action"
                onClick={() => this.props.cancel()}
              >
                X
              </button>
            </div>
            <div className="modal-title">
              <h2>Upload</h2>
            </div>
            <div className="modal-body">
              <label className="file-upload-wrapper">
                <div className="icon icon-image-upload"></div>
                <p>
                  {!this.state.mediaName &&
                    "Tap to select a file to upload to your customer documents folder."}
                  {this.state.mediaName}
                </p>
                <input
                  autoFocus
                  required
                  name="file"
                  id="file"
                  className="inputfile"
                  type="file"
                  onChange={e => this.handleFileInputUpdate(e)}
                />
              </label>
              <label>
                Title:
                <input
                  value={this.state.description}
                  maxLength={260}
                  onChange={e => this.handleDescriptionChange(e)}
                />
              </label>
            </div>
            <div className="modal-buttons">
              <button
                type="button"
                className="action"
                onClick={() => this.props.continue(this.state)}
              >
                Upload
              </button>
              <button
                type="button"
                className="action negative"
                onClick={() => this.props.cancel()}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default FileSaveModal;
