import {
  SITEID_APPLICATIONS_INACTIVE_REQUEST,
  SITEID_APPLICATIONS_INACTIVE_SUCCESS,
  SITEID_APPLICATIONS_INACTIVE_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const siteIDInactiveApplicationsReducer = (
  state = initialState.siteIDInactiveApplicationsState,
  action,
) => {
  switch (action.type) {
  case SITEID_APPLICATIONS_INACTIVE_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      errorMessage: '',
    };
  }
  case SITEID_APPLICATIONS_INACTIVE_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      data: action.payload.applications,
      companies: action.payload.companies,
      counties: action.payload.counties,
      statuses: action.payload.statuses,
      errorMessage: '',
    };
  }
  case SITEID_APPLICATIONS_INACTIVE_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      data: [],
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default siteIDInactiveApplicationsReducer;
