import {
  CONNECT_ASSET_REQUEST,
  CONNECT_ASSET_SUCCESS,
  CONNECT_ASSET_FAILURE,
} from './actionTypes';

export const assetActions = {
  requestAsset: (assetCategoryId, assetReference) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_ASSET_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Connect/Asset/v1/Get?assetCategoryId=${assetCategoryId}&assetReference=${assetReference}`,
        successAction: CONNECT_ASSET_SUCCESS,
        failureAction: CONNECT_ASSET_FAILURE,
      },
    });
  },
};
