import {
  MODULE_REQUEST,
  MODULE_SUCCESS,
  MODULE_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const moduleReducer = (state = initialState.moduleState, action) => {
  switch (action.type) {
  case MODULE_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      //Do not clear down current module, let success and failure overwrite.
      //currentModule: null,
      //modulePath: [...currentPath, action.payload.moduleId]
    };
  }
  case MODULE_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      currentModule: action.payload.parentModule,
      moduleContents: action.payload.moduleContents,
    };
  }
  case MODULE_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      currentModule: null,
      moduleContents: [],
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default moduleReducer;
