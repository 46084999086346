import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';

const TaskAdminBulkEditModal = props => {
  const [primaryReminderDays, setPrimaryReminderDays] = useState(null);
  const [secondaryReminderDays, setSecondaryReminderDays] = useState(null);
  const [primaryReminderBody, setPrimaryReminderBody] = useState('');
  const [secondaryReminderBody, setSecondaryReminderBody] = useState('');
  const [primaryReminderSubject, setPrimaryReminderSubject] = useState('');
  const [secondaryReminderSubject, setSecondaryReminderSubject] = useState('');
  const [overdueReminderSubject, setOverdueReminderSubject] = useState('');
  const [overdueReminderBody, setOverdueReminderBody] = useState('');

  const [showPrimaryReminder, setShowPrimaryReminder] = useState(false);
  const [showSecondaryReminder, setShowSecondaryReminder] = useState(false);
  const [showOverdueReminder, setShowOverdueReminder] = useState(false);

  const [isSaved, setIsSaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const saveBulkEditReminders = async() => {   
    setIsSaving(true);
    const response = await fetch('/api/connect/taskdetail/v1/SaveBulkReminders', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({ 
        taskIds: props.selectedTasks,
        primaryReminderDays,
        secondaryReminderDays,
        primaryReminderSubject,
        secondaryReminderSubject,
        primaryReminderBody,
        secondaryReminderBody,
        overdueReminderSubject,
        overdueReminderBody,
      }),
    });

    if (response.status == 200){
      setIsSaving(false);
      setIsSaved(true);
      setResponseMessage('Reminders saved.');
      props.cancel();
    }
    else{
      setIsSaving(false);
      setResponseMessage('Something went wrong.');
    }
  };

  const validated =
  (showPrimaryReminder && showOverdueReminder) ? 
    (primaryReminderDays > 0 && primaryReminderSubject.length > 0 && primaryReminderBody.length > 0 &&
     overdueReminderSubject.length > 0 && overdueReminderBody.length > 0) :
    (showSecondaryReminder ? 
      (showPrimaryReminder ?
        (primaryReminderDays > 0 && primaryReminderSubject.length > 0 && primaryReminderBody.length > 0) :
        false) &&
    (secondaryReminderDays > 0 && secondaryReminderSubject.length > 0 && secondaryReminderBody.length > 0) :
      (showPrimaryReminder ? 
        (primaryReminderDays > 0 && primaryReminderSubject.length > 0 && primaryReminderBody.length > 0) :
        (showOverdueReminder ? 
          (overdueReminderSubject.length > 0 && overdueReminderBody.length > 0) :
          false)));

  return (
    <div className="modal-blocker">
      <div className="modal">
        <form>
          <div className="modal-menu">
            <button
              type="button"
              className="action"
              onClick={() => props.cancel()}
            >
              X
            </button>
          </div>
          <div className="modal-title">
            <h2>Schedule Reminders</h2>
          </div>
          <div className="modal-body">
            <p>Schedule reminder emails to be sent to recipients.</p>
            <div className='bulk-edit-body'>
              <div className='reminder-wrapper'>
                <p className='reminder-title'><b>Primary Reminder</b>                  
                  <Tooltip
                    title={'Set how many days prior to the due date the recipients who have not yet completed the task will be notified. If the date falls on a weekend, the closest weekday before will be chosen.'}>
                    <span className="info">&#x1F6C8;</span></Tooltip></p>
                <label className='reminder-label'>
                  <div className="toggle-wrapper">
                    <input
                      checked={showPrimaryReminder}
                      onChange={(e) => setShowPrimaryReminder(e.target.checked)}
                      type="checkbox"
                      className="toggle"
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
              </div>
              {showPrimaryReminder && 
            <>
              <label>
              Days prior to due date
                <input type='number'
                  className='small-input'
                  value={primaryReminderDays}
                  onChange={(e) => setPrimaryReminderDays(e.target.value)}
                />
              </label>
              <label>
             Subject
                <input type='text'
                  value={primaryReminderSubject}
                  onChange={(e) => setPrimaryReminderSubject(e.target.value)}
                />
              </label>
              <label>
             Body
                <input type='text'
                  value={primaryReminderBody}
                  onChange={(e) => setPrimaryReminderBody(e.target.value)}
                />
              </label>
            </>
              }

              {showPrimaryReminder &&
            <>
              <div className='reminder-wrapper'>
                <p className='reminder-title'><b>Secondary Reminder</b><Tooltip
                  title={'Set how many days prior to the due date the recipients who have not yet completed the task will be notified. This value should be closer to the due date than the primary reminder. If the date falls on a weekend, the closest weekday before will be chosen.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip></p>
                <label className='reminder-label'>
                  <div className="toggle-wrapper">
                    <input
                      checked={showSecondaryReminder}
                      onChange={(e) => setShowSecondaryReminder(e.target.checked)}
                      type="checkbox"
                      className="toggle"
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
              </div>
              {showSecondaryReminder && 
            <>
              <label>
              Days prior to due date
                <input type='number'
                  className='small-input'
                  value={secondaryReminderDays}
                  onChange={(e) => setSecondaryReminderDays(Math.max(0, Math.min(e.target.value, primaryReminderDays - 1)))}
                />
              </label>
              <label>
             Subject
                <input type='text'
                  value={secondaryReminderSubject}
                  onChange={(e) => setSecondaryReminderSubject(e.target.value)}
                />
              </label>
              <label>
             Body
                <input type='text'
                  value={secondaryReminderBody}
                  onChange={(e) => setSecondaryReminderBody(e.target.value)}
                />
              </label>
            </>
              }
            </>
              }   

              <div className='reminder-wrapper'>
                <p className='reminder-title'><b>Overdue Reminder</b><Tooltip
                  title={'Set whether the user should be sent a reminder email if they fail to complete the task by the due date.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip></p>
                <label className='reminder-label'>
                  <div className="toggle-wrapper">
                    <input
                      checked={showOverdueReminder}
                      onChange={(e) => setShowOverdueReminder(e.target.checked)}
                      type="checkbox"
                      className="toggle"
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
              </div>
              {showOverdueReminder && 
            <>
              <label>
             Subject
                <input type='text'
                  value={overdueReminderSubject}
                  onChange={(e) => setOverdueReminderSubject(e.target.value)}
                />
              </label>
              <label>
             Body
                <input type='text'
                  value={overdueReminderBody}
                  onChange={(e) => setOverdueReminderBody(e.target.value)}
                />
              </label>
            </>
              }
            </div>
          </div>
          <p>{responseMessage}</p>
          <div className="modal-buttons reminders">
            <button
              style={{ marginRight: '5px' }}
              type="button"
              className="action negative"
              disabled={isSaving}
              onClick={() => props.cancel()}
            >
              Cancel
            </button>
            <button
              style={{ marginLeft: '5px' }}
              type="button"
              className="action"
              disabled={!validated}
              onClick={() => saveBulkEditReminders()}
            >
              {isSaved ? 'Saved' : isSaving ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TaskAdminBulkEditModal;
