import * as SignalR from '@aspnet/signalr';
import {
  CONNECTMESSAGING,
  DISCONNECTMESSAGING,
  FETCHCHATROOMS,
  HOSTCHATROOM,
  JOINCHATROOM,
  STARTCONVERSATION,
  SENDMESSAGE,
  TERMINATECONVERSATION,
} from '../actions/actionTypes';
import { instantMessagingActions } from '../actions/messageActions';

const connection = new SignalR.HubConnectionBuilder()
  .withUrl('/CustomerSupport', {
    accessTokenFactory: () => localStorage.getItem('token'),
  })
  .configureLogging(SignalR.LogLevel.Information)
  .build();

export const signalRInvokeMiddleware = store => next => action => {
  switch (action.type) {
  case CONNECTMESSAGING:
    if (!connection.state) {
      connection
        .start()
        .then(() => console.log('Connection started'))
        .then(() => next({ type: action.type, payload: { connected: true } }))
        .catch(err =>
          console.error(`Error while starting connection: ${err}`),
        );
    }
    break;
  case DISCONNECTMESSAGING:
    if (connection.state) {
      connection
        .stop()
        .then(() => console.log('Connection stopped'))
        .then(() =>
          next({ type: action.type, payload: { connected: false } }),
        )
        .catch(err =>
          console.error(`Error while stopping connection: ${err}`),
        );
    }
    break;
  case FETCHCHATROOMS:
    if (connection.state) {
      connection.invoke('GetChatRooms');
    }
    break;
  case HOSTCHATROOM:
    if (connection.state) {
      connection.invoke('HostChatRoom', action.payload.chatRoomId);
    }
    break;
  case JOINCHATROOM:
    if (connection.state) {
      connection.invoke('JoinChatRoom', action.payload.chatRoomId);
    }
    break;
  case STARTCONVERSATION:
    if (connection.state) {
      connection.invoke(
        'StartConversation',
        action.payload.chatRoomId,
        action.payload.userQueueId,
      );
    }
    break;
  case SENDMESSAGE:
    if (connection.state) {
      connection.invoke(
        'SendMessage',
        action.payload.conversationId,
        action.payload.message,
      );
    }
    break;
  case TERMINATECONVERSATION:
    if (connection.state) {
      connection.invoke(
        'SendMessage',
        action.payload.chatRoomId,
        action.payload.conversationId,
      );
    }
    break;
  default:
    // No signalR intervention required.
    break;
  }

  return next(action);
};

export const signalRRegisterCommands = store => {
  /*connection.on("SendMessage", data => {
    store.dispatch(messageRecievedAction.messageReceived(data));
    console.log("Message has been recieved");
  });*/

  connection.on('ChatRooms', data => {
    store.dispatch(instantMessagingActions.receiveChatRooms(data));
    console.log('ChatRooms Loaded');
  });

  connection.on('HostingChatRoom', data => {
    store.dispatch(instantMessagingActions.hostingChatRoom(data));
    console.log('Hosting ChatRoom');
  });

  connection.on('UserJoinedQueue', data => {
    store.dispatch(instantMessagingActions.userJoinedQueue(data));
    console.log('User began queueing');
  });

  connection.on('QueueUpdated', data => {
    store.dispatch(instantMessagingActions.queueUpdated(data));
    console.log('Queue Updated');
  });

  connection.on('ConversationStarted', data => {
    store.dispatch(instantMessagingActions.conversationStarted(data));
    console.log('Conversation Started');
  });

  connection.on('ReceiveMessage', data => {
    store.dispatch(instantMessagingActions.receiveMessage(data));
    console.log('Received Message');
  });

  connection.on('ConversationTerminated', data => {
    store.dispatch(instantMessagingActions.conversationTerminated(data));
    console.log('Conversation Terminated');
  });

  connection.logging = true;
};
