import React from 'react';
import { connect } from 'react-redux';
import { notificationActions } from '../../../actions/notificationActions';
import Notification2 from '../Notification2/Notification2';
import { navigationActions } from '../../../actions/navigationActions';
import Pagination from '../../shared/Pagination';

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      //mode
      mode: 'platform',
      
      //siteID defaults
      sIdCompany: '',
      sIdnotificationType: '',
      sIdread: '',
      page: 1,

    };

    this.doDropdownChange = this.doDropdownChange.bind(this);
    this.doPaging = this.doPaging.bind(this);
  }
  componentDidMount() {

    if (this.props.isSiteIdUser) {
      this.setState({ mode: 'siteId' });
      this.handleSearch();
    } else {
      this.setState({ mode: 'platform' });
      this.props.fetchNotifications();      
    }
  }

  handleAcknowledge(platformNotificationType, type, popupId) {
    this.props.acknowledgeNotifications(
      platformNotificationType,
      type,
      popupId,
    );
  }

  handleAllAcknowledge(mode) {

    if (mode === 'platform') {
      this.props.acknowledgeAllNotifications();
    } else {
      const { sIdCompany, sIdnotificationType } = this.state;
      this.props.acknowledgeAllSiteIdNotifications(sIdCompany, sIdnotificationType);
    }
  }

  doPaging(page) {
    this.handleSearch(page);
  }

  handleSearch(page = 1) {
    const { sIdCompany, sIdnotificationType, sIdread } = this.state;
    this.props.fetchSiteIdNotifications(sIdCompany, sIdnotificationType, sIdread, page, null);
  }

  handleToggleMode() {
    var modeSet;
    if(this.state.mode == 'platform'){
      modeSet = 'siteId';
      this.handleSearch();
    }
    else{
      modeSet = 'platform';
    }

    this.setState({ mode: modeSet });
  }

  doDropdownChange(e, fieldName) {
    this.setState({
      [fieldName]: e.target.value,
    });
  }

  handleNavigate(actionLink) {
    if (!actionLink) return;

    if (actionLink.toLowerCase().startsWith('http')) {
      window.open(actionLink);
    } else {
      this.props.push(actionLink);
    }
  }

  getFuzzyDate(dateString) {
    const now = new Date();
    const then = new Date(dateString);

    if (now.getFullYear() > then.getFullYear()) {
      if (now.getFullYear() - then.getFullYear() === 1) {
        return 'Last year';
      } else {
        return parseInt(now.getFullYear() - then.getFullYear()) + ' years ago';
      }
    } else if (now.getMonth() > then.getMonth()) {
      if (now.getMonth() - then.getMonth() === 1) {
        return 'Last month';
      } else {
        return parseInt(now.getMonth() - then.getMonth()) + ' months ago';
      }
    } else if (now.getDate() > then.getDate()) {
      if (now.getDate() - then.getDate() === 1) {
        return 'Yesterday';
      } else if (parseInt((now.getDate() - then.getDate()) / 7) === 1) {
        return 'Last week';
      } else if (parseInt((now.getDate() - then.getDate()) / 7) > 1) {
        return parseInt((now.getDate() - then.getDate()) / 7) + ' weeks ago';
      } else {
        return parseInt(now.getDate() - then.getDate()) + ' days ago';
      }
    } else {
      return '';
    }
  }

  getVagueDate(dateString) {
    const date = new Date();
    const now = date.getTime() + date.getTimezoneOffset() * 60 * 1000;
    const then = new Date(dateString).getTime();
    const difference = now - then;
    const minute = 1000 * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    const month = day * 30;
    if (difference < minute * 30) {
      //Last 30 minutes
      return 'Just now';
    } else if (difference < minute * 60) {
      return 'Last Hour';
    } else if (difference / hour < 24) {
      return parseInt(difference / hour) + ' hours ago';
    } else if (difference / day < 7) {
      return parseInt(difference / day) + ' days ago';
    } else if (difference / week < 5) {
      return parseInt(difference / week) + ' weeks ago';
    } else {
      return parseInt(difference / month) + ' months ago';
    }
  }

  handleGenerateMessageList(allPopups) {
    const popups = [];

    let previousFuzzy = '';
    for (const popup of allPopups) {
      let fuzzyDate = this.getFuzzyDate(popup.sentOn);
      let vagueDate = this.getVagueDate(popup.sentOn);

      if (previousFuzzy !== fuzzyDate) {
        popups.push(<div key={ fuzzyDate}
          className="date-pointer">{fuzzyDate}</div>);
      }

      previousFuzzy = fuzzyDate;

      popups.push(
        <Notification2
          key={popup.recipientId + '-' + popup.notificationId + '-' + popup.notificationType}
          data={popup}
          vagueDate={vagueDate}
          navigate={() => this.handleNavigate(popup.actionLink)}
          acknowledge={() =>
            this.handleAcknowledge(
              popup.platformNotificationType,
              popup.notificationType,
              popup.recipientId,
            )
          }
          markAll = {() => this.markAll()}
        />,
      );
    }

    return popups;
  }

  render() {
    const { platformNotifications, siteIdNotifications, currentPage, totalRows, pageSize, companies, isLicensee } = this.props;
    const { mode } = this.state;
    const { doDropdownChange, doPaging } = this;
    

    return (
      <div
        className={`messages-page page ${this.state.loaded ? 'loaded' : ''}`}
      >
        <div className="messages-header">
          <div className="logo-stack">
            <div className="icon icon-messages"></div>
            <h2>Message Archive</h2>
          
          </div>

          <div className="mark-all-container">
            
            <button
              className="edit"
              type="button"
              onClick = {() => this.handleAllAcknowledge(mode)}
            >
            Mark all as read
            </button>

          </div>
        </div>

        {mode === 'siteId' && <>
          <div className="notification-filter-container">
            <div className="notification-filter-dropdowns">
              <label>
                <select
                  value={this.sIdCompany}
                  onChange={(e) => doDropdownChange(e, 'sIdCompany' )}
                >
                  <option value=''>All Companies</option>
                  {companies && companies.map(c => {
                    return (
                      <option key={c.key} 
                        value={c.key}> 
                        {c.value} </option>
                    );
                  })}
                </select>
              </label>
              <label>
                <select
                  value={this.sIdnotificationType}
                  onChange={(e) => doDropdownChange(e, 'sIdnotificationType' )}
                >
                  <option value=''>All Notification Types</option>
                  {!isLicensee && <option value={7}>Project Request</option>}
                  <option value={8}>Project Number</option>
                  <option value={2}>Progression</option>
                  {!isLicensee && <option value={6}>EMEA Response</option>}
                  <option value={3}>Documents Upload</option>
                  <option value={4}>Comments</option>
                  <option value={1}>Extensions</option>
                  <option value={5}>Expiring Soon</option>
                  {!isLicensee && <option value={0}>To Reconsider</option>}
                </select>
              </label>
              {this.sIdCompany}
              <label>
                <select
                  value={this.sIdCompany}
                  onChange={(e) => doDropdownChange(e, 'sIdread' )}
                >
                  <option value=''>All Read/Unread</option>
                  <option value={true}>Read</option>
                  <option value={false}>Unread</option>
                </select>
              </label>

              <button className="action filter-search-buttons" 
                onClick={() => this.handleSearch(1)}>
                  Search
              </button>
            
            </div>
          
          </div>
        </>}

        <div className="messages-list">
          {(mode == 'platform' && (!this.props.platformNotifications || this.props.platformNotifications.length === 0))
          || (mode == 'siteId' && (!this.props.siteIdNotifications || this.props.siteIdNotifications.length === 0))
            ? (
              <p> You do not have any notifications at this time.</p>
            ): null}
          {this.handleGenerateMessageList(this.state.mode == 'platform' ? platformNotifications : siteIdNotifications)}
        </div>

        {mode == 'siteId' ?
          <div className="messages-pagination">
            <Pagination
              rowCount={totalRows}
              page={currentPage}
              pageSize={pageSize}
              doPaging={doPaging}
            />
          </div> : null}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  
  var platformNotifications = state.notification.allPopups && state.notification.allPopups.length && state.notification.allPopups.filter(p => p.platformNotificationType !== 2);
  var siteIdNotifications = state.notification.siteIdAllPopups;
  
  return {
    platformNotifications: platformNotifications || [],
    siteIdNotifications: siteIdNotifications || [],
    pageSize: state.notification.pageSize,
    totalRows: state.notification.totalRows,
    currentPage: state.notification.currentPage,
    companies: state.notification.companies,
    isPortalUser: state.auth.isPortalUser,
    isSiteIdUser: state.auth.isSiteIdUser,
    isLicensee: state.notification.isLicensee,
  };
}; 

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    fetchNotifications: () =>
      dispatch(notificationActions.requestNotifications(100, null)),
    acknowledgeNotifications: (platformNotificationType, type, popupId) =>
      dispatch(
        notificationActions.acknowledgeNotifications(
          platformNotificationType,
          type,
          popupId,
        ),
      ),
    acknowledgeAllNotifications: () =>
      dispatch(notificationActions.acknowledgeAllNotifications()),

    fetchSiteIdNotifications: (companyId, notificationTypeId, read, page) => 
      dispatch(
        notificationActions.requestSiteIdNotifications(
          companyId,
          notificationTypeId,
          read,
          page,
          null,
        ),
      ),
    
    acknowledgeAllSiteIdNotifications: (companyId, notificationType) =>
      dispatch(notificationActions.acknowledgeAllSiteIdNotifications(companyId, notificationType)),
    
    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
