import {
  BULKAPPLICATIONUPLOAD_REQUEST,
  BULKAPPLICATIONUPLOAD_SUCCESS,
  BULKAPPLICATIONUPLOAD_FAILURE,
} from './actionTypes';

export const bulkApplicationUploadActions = {
  bulkApplicationUpload: bulkApplicationFile => (dispatch, getState) => {
    dispatch({
      type: BULKAPPLICATIONUPLOAD_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/siteid/BulkApplication/v1/BulkApplicationUpload',
        successAction: BULKAPPLICATIONUPLOAD_SUCCESS,
        failureAction: BULKAPPLICATIONUPLOAD_FAILURE,
        body: {
          ...bulkApplicationFile,
        },
      },
    });
  },
};
