import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import orderTrackerActions from '../../../actions/orderTrackerActions';
import ProgressState from '../components/ProgressState/ProgressState';
import './EditOrder.scss';
import StatusDropdown from '../components/StatusDropdown/StatusDropdown';
import { calculateAge, calculateDaysDelayed, sanitiseNumberInput } from '../utils';

const EditOrder = (props) => {
  // general state
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Form Fields
  const [storeNumber, setStoreNumber] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [poNumber, setPoNumber] = useState('');
  const [trip, setTrip] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [createdDate, setCreatedDate] = useState('');
  const [comments, setComments] = useState('');
  const [orderProgress, setOrderProgress] = useState([
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ]);

  // Field refs
  const formRefContainer = useRef(null);

  const getProgressStage = () => {
    
    let progressStage = 0;

    for (let i = 6; i >= 0; i--) {
      if (orderProgress[i].length > 0) {
        progressStage = i + 1;
        break;
      }
    }

    return progressStage;
  };
  
  const getOrderId = () => {
    const { search } = props.location;
    const { id } = qs.parse(search);
    return id;
  };

  useEffect(
    () => props.getOrder(getOrderId()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {

      if (!props.order || !props.isLoaded) {
        return;
      }

      setStoreNumber(props.order.storeNumber);
      setOrderNumber(props.order.externalOrderNumber);
      setPoNumber(props.order.externalPONumber);
      setTrip(props.order.trip ? props.order.trip : '');
      setOrderStatus(props.order.statusId);
      setCreatedDate(props.order.createdAt.split('T')[0]);
      setComments(props.order.comments);
      setOrderProgress(props.order.progressStages);
    },
    [props.order, props.isLoaded],
  );

  const readOnly = props.readOnly || (props.order && (props.order.statusId === 5 || props.order.readOnly));

  const handleSubmit = (e) => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    props.saveOrder({
      comments,
      trip: trip.length ? trip : undefined,
      storeNumber,
      orderId: getOrderId(),
      externalOrderNumber: orderNumber,
      externalPONumber: poNumber,
      createdAt: createdDate,
      progressId: getProgressStage() - 1,
      statusId: orderStatus,
      progressStages: orderProgress,
    });
  };

  const handleProgressUpdate = (stage, value) => {
    const progress = orderProgress.slice(0);
    progress[stage] = value.trim();
    setOrderProgress(progress);
  };

  const progressStage = getProgressStage();

  const handleOrderStatusChange = (e) => {
    const { value } = e.target;
    setOrderStatus(value);

    if (value === '5' && orderProgress[6] === '') {
      handleProgressUpdate(6, new Date().toISOString().split('T')[0]);
    }
  };

  return (
    <div className="page edit-order-page">
      <div className="floating-panel">
        <form
          onSubmit={e => handleSubmit(e)}
          ref={formRefContainer}
          className={formSubmitted ? 'submitted' : ''}
        >
          <div className="floating-header">
            <div className="header-title">
              <h2>
                {readOnly
                  ? 'View Order'
                  : 'Edit Order'}
              </h2>
            </div>
          </div>
          <div className="edit-order-container">
            <div className="group-details accordion">
              <div className="accordion-header">
                <h3>Order Details</h3>
              </div>
              <div className="accordion-body horizontal-flex order-fields">
                <div>
                  <label>
                    Customer Details
                    <input
                      placeholder="Customer number"
                      type="text"
                      pattern="^\d{0,15}$"
                      required
                      onChange={e => setStoreNumber(e.target.value)}
                      onKeyPress={sanitiseNumberInput}
                      value={storeNumber}
                      autoComplete="off"
                      disabled={true}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Customer Address
                    <input
                      placeholder="Customer address"
                      type="text"
                      required
                      value={props.order ? props.order.storeName : ''}
                      autoComplete="off"
                      disabled={true}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Starbucks Order Number
                    <input
                      placeholder="Starbucks Order Number"
                      type="text"
                      pattern="^\d{0,15}$"
                      required
                      onChange={e => setOrderNumber(e.target.value)}
                      onKeyPress={sanitiseNumberInput}
                      value={orderNumber}
                      autoComplete="off"
                      disabled={true}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Customer PO Number
                    <input
                      placeholder="Customer PO Number"
                      type="text"
                      required
                      maxLength="60"
                      onChange={e => setPoNumber(e.target.value)}
                      value={poNumber}
                      autoComplete="off"
                      disabled={true}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Trip
                    <input
                      placeholder="Trip"
                      type="text"
                      pattern="^\d{0,15}$"
                      onChange={e => setTrip(e.target.value)}
                      onKeyPress={sanitiseNumberInput}
                      value={trip}
                      autoComplete="off"
                      disabled={readOnly}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="group-details accordion">
              <div className="accordion-header">
                <h3>Order Status</h3>
              </div>
              <div className="accordion-body horizontal-flex order-fields">
                <div>
                  <StatusDropdown
                    value={orderStatus}
                    onChange={handleOrderStatusChange}
                    required
                    disabled={readOnly}
                  />
                </div>
                <div>
                  <label>
                    Created Date
                    <input
                      placeholder="Created Date"
                      type="date"
                      required
                      onChange={e => setCreatedDate(e.target.value)}
                      value={createdDate}
                      autoComplete="off"
                      disabled={readOnly}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Age
                    <input
                      placeholder="Age"
                      type="text"
                      value={calculateAge(createdDate)}
                      readOnly
                      disabled
                      autoComplete="off"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Days Delayed
                    <input
                      placeholder="Days Delayed"
                      type="text"
                      value={calculateDaysDelayed(createdDate, orderProgress[5], props.order && props.order.bespokeDelay, orderProgress[6], parseInt(orderStatus, 10))}
                      readOnly
                      disabled
                      autoComplete="off"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="group-details accordion progress-state-container">
              <div className="accordion-header">
                <h3>Progress Update</h3>
              </div>
              <div className="accordion-body horizontal-flex order-fields">
                <ProgressState progressStage={progressStage}
                  numberOfBoxes={7} />
              </div>
              <div /> {/* This is just here for flex centering */}
            </div>
            <div className="group-details accordion">
              <div className="accordion-header">
                <h3>Comments</h3>
              </div>
              <div className="accordion-body horizontal-flex order-fields">
                <div className="comments-container">
                  <label className="comments">
                    Comments
                    <textarea
                      onChange={e => setComments(e.target.value)}
                      autoComplete="off"
                      value={comments}
                      disabled={readOnly}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="group-details accordion">
            <div className="accordion-header">
              <h3>Order Progress</h3>
            </div>
            <div className="accordion-body horizontal-flex order-fields progress-stages">
              <div className="progress-stage">
                <label>
                  1. Picking Process
                  <input
                    type="text"
                    maxLength={20}
                    onChange={e => handleProgressUpdate(0, e.target.value)}
                    value={orderProgress[0]}
                    required
                    autoComplete="off"
                    disabled={readOnly}
                  />
                </label>
              </div>
              <div className="progress-stage">
                <label>
                  2. CI Sent
                  <input
                    type="date"
                    onChange={e => handleProgressUpdate(1, e.target.value)}
                    value={orderProgress[1]}
                    autoComplete="off"
                    disabled={readOnly}
                  />
                </label>
              </div>
              <div className="progress-stage">
                <label>
                  3. HC Requested 4SA
                  <input
                    type="date"
                    onChange={e => handleProgressUpdate(2, e.target.value)}
                    value={orderProgress[2]}
                    autoComplete="off"
                    disabled={readOnly}
                  />
                </label>
              </div>
              <div className="progress-stage">
                <label>
                  4. Legalization
                  <input
                    type="date"
                    onChange={e => handleProgressUpdate(3, e.target.value)}
                    value={orderProgress[3]}
                    autoComplete="off"
                    disabled={readOnly}
                  />  
                </label>
              </div>
              <div className="progress-stage">
                <label>
                  5. Pickup Request Sent 6FF
                  <input
                    type="date"
                    onChange={e => handleProgressUpdate(4, e.target.value)}
                    value={orderProgress[4]}
                    autoComplete="off"
                    disabled={readOnly}
                  />
                </label>
              </div>
              <div className="progress-stage">
                <label>
                  6. Pickup Planned
                  <input
                    type="date"
                    onChange={e => handleProgressUpdate(5, e.target.value)}
                    value={orderProgress[5]}
                    autoComplete="off"
                    disabled={readOnly}
                  />
                </label>
              </div>
              <div className="progress-stage">
                <label>
                  7. Dispatched
                  <input
                    type="date"
                    onChange={e => handleProgressUpdate(6, e.target.value)}
                    value={orderProgress[6]}
                    autoComplete="off"
                    disabled={readOnly}
                  />
                </label>
              </div>
            </div>
          </div>
          {!readOnly && 
            <div className="button-container">
              <button
                className="action"
                type="submit"
                disabled={props.isLoading}
                onClick={() => setFormSubmitted(true)}
              >
                {props.isLoading
                  ? 'Saving...'
                  : 'Save'
                }
              </button>
              {props.isLoaded && formSubmitted && 
                <p className="submit-result">
                  Order saved successfully!    
                </p>
              }
              {props.error && 
                <p className="submit-result">
                  {props.error}
                </p>
              }
            </div>
          }
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.orderTracker.isLoading,
    isLoaded: state.orderTracker.isLoaded,
    error: state.orderTracker.error,
    order: state.orderTracker.order,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrder: (params) => dispatch(orderTrackerActions.getOrder(params)),
    saveOrder: (params) => dispatch(orderTrackerActions.saveOrder(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrder);
