import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  TOKEN_REFRESH_REQUEST,
  TOKEN_REFRESH_SUCCESS,
  TOKEN_REFRESH_FAILURE,
  ACCEPT_TERMS_REQUEST,
  ACCEPT_TERMS_SUCCESS,
  ACCEPT_TERMS_FAILURE,
  TERMS_ACCEPTED,
  CONNECT_DISPLAY,
  CONNECT_HIDE,
  REQUESTPASSWORDRESET_REQUEST,
  REQUESTPASSWORDRESET_SUCCESS,
  REQUESTPASSWORDRESET_FAILURE,
  PASSWORDRESET_REQUEST,
  PASSWORDRESET_SUCCESS,
  PASSWORDRESET_FAILURE,
  ACTIVATEACCOUNT_REQUEST,
  ACTIVATEACCOUNT_SUCCESS,
  ACTIVATEACCOUNT_FAILURE,
  COMPLETEMFA_REQUEST,
  COMPLETEMFA_SUCCESS,
  COMPLETEMFA_FAILURE,
} from './actionTypes';

export const authActions = {
  requestLogin: creds => (dispatch, getState) => {
    dispatch({
      type: LOGIN_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/identity/v1/preauthenticate',
        successAction: LOGIN_SUCCESS,
        failureAction: LOGIN_FAILURE,
        body: {
          Username: creds.username,
          Password: creds.password,
        },
      },
    });
  },
  completeMfa: tfaCode => (dispatch, getState) => {
    dispatch({
      type: COMPLETEMFA_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/identity/v1/authentication',
        successAction: COMPLETEMFA_SUCCESS,
        failureAction: COMPLETEMFA_FAILURE,
        body: {
          twoFactorCode: tfaCode,
        },
      },
    });
  },
  requestTokenRefresh: () => (dispatch, getState) => {
    dispatch({
      type: TOKEN_REFRESH_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/identity/v1/refreshToken',
        successAction: TOKEN_REFRESH_SUCCESS,
        failureAction: TOKEN_REFRESH_FAILURE,
      },
    });
  },
  requestLogout: () => (dispatch, getState) => {
    dispatch({
      type: LOGOUT_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/identity/v1/logout',
        successAction: LOGOUT_SUCCESS,
        failureAction: LOGOUT_FAILURE,
      },
    });
  },
  acceptTerms: () => (dispatch, getState) => {
    dispatch({
      type: ACCEPT_TERMS_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/terms/v1/acceptTerms',
        successAction: ACCEPT_TERMS_SUCCESS,
        failureAction: ACCEPT_TERMS_FAILURE,
      },
    });
  },
  displayConnect: () => (dispatch, getState) => {
    dispatch({
      type: CONNECT_DISPLAY,
    });
  },
  acceptedTerms: () => (dispatch, getState) => {
    dispatch({
      type: TERMS_ACCEPTED,
    });
  },
  hideConnect: () => (dispatch, getState) => {
    dispatch({
      type: CONNECT_HIDE,
    });
  },
  requestPasswordReset: data => (dispatch, getState) => {
    dispatch({
      type: REQUESTPASSWORDRESET_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/identity/v1/RequestPasswordReset',
        successAction: REQUESTPASSWORDRESET_SUCCESS,
        failureAction: REQUESTPASSWORDRESET_FAILURE,
        body: {
          ...data,
        },
      },
    });
  },
  resetPassword: data => (dispatch, getState) => {
    dispatch({
      type: PASSWORDRESET_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/identity/v1/resetPassword',
        successAction: PASSWORDRESET_SUCCESS,
        failureAction: PASSWORDRESET_FAILURE,
        body: {
          ...data,
        },
      },
    });
  },
  activateAccount: data => (dispatch, getState) => {
    dispatch({
      type: ACTIVATEACCOUNT_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/identity/v1/activateAccount',
        successAction: ACTIVATEACCOUNT_SUCCESS,
        failureAction: ACTIVATEACCOUNT_FAILURE,
        body: {
          ...data,
        },
      },
    });
  },
};
