import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { itemCatalogueActions } from '../../../actions/itemCatalogueActions';
import qs from 'query-string';

class ItemDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storeId: '',
      itemId: 0,
    };
  }

  componentDidMount() {
    const { storeId } = this.props.match.params;
    const { search } = this.props.location;

    const parsed = qs.parse(search.toLowerCase());

    let itemId = parsed.itemid ? parsed.itemid : '';

    this.props.getItemDetail(storeId, itemId);
  }

  generateUOMConversions() {
    const { itemDetail } = this.props;

    const uoms = [];
    const conversions = {};
    let uomCodeFrom, uomCodeTo, convRate, conversion;
    let i,
      j = 0;

    //Get all UOMs
    for (i = 0; i < itemDetail.uomConversions.length; i++) {
      uomCodeFrom = itemDetail.uomConversions[i].uomCodeFrom;
      uomCodeTo = itemDetail.uomConversions[i].uomCodeTo;

      if (uoms.indexOf(uomCodeFrom) === -1) {
        uoms.push(uomCodeFrom);
      }

      if (uoms.indexOf(uomCodeTo) === -1) {
        uoms.push(uomCodeTo);
      }
    }

    //Map every uom to every uom
    for (i = 0; i < uoms.length; i++) {
      let to = {};
      for (j = 0; j < uoms.length; j++) {
        to[uoms[j]] = uoms[i] === uoms[j] ? 1 : '-';
      }
      conversions[uoms[i]] = to;
    }

    //Set existing conversions
    for (i = 0; i < itemDetail.uomConversions.length; i++) {
      uomCodeFrom = itemDetail.uomConversions[i].uomCodeFrom;
      uomCodeTo = itemDetail.uomConversions[i].uomCodeTo;
      convRate = itemDetail.uomConversions[i].convRate;

      conversions[uomCodeFrom][uomCodeTo] = Number(convRate);
      conversions[uomCodeTo][uomCodeFrom] = 1 / Number(convRate);
    }

    for ([uomCodeFrom, conversion] of Object.entries(conversions)) {
      for ([uomCodeTo] of Object.entries(conversion)) {
        if (typeof conversion[uomCodeTo] !== 'number') {
          //find other measures of from
          let fromMeasures = conversion;

          let toMeasures = conversions[uomCodeTo];

          let matchingMeasures = [];

          for (let measure of Object.keys(toMeasures)) {
            if (
              typeof toMeasures[measure] === 'number' &&
              typeof fromMeasures[measure] === 'number'
            ) {
              matchingMeasures.push(measure);
            }
          }

          for (let i = 0; i < matchingMeasures.length; i++) {
            let baseMeasures = conversions[matchingMeasures[i]];

            let frombaseMeasure = baseMeasures[uomCodeFrom];
            let tobaseMeasure = baseMeasures[uomCodeTo];

            conversion[uomCodeTo] =
              Math.round((tobaseMeasure / frombaseMeasure) * 100) / 100;
          }
        }
      }
    }

    //Map every uom to every uom
    let firstUom = uoms[0];
    let newUoms = [];

    for (j = 0; j < uoms.length; j++) {
      newUoms.push({
        uom: uoms[j],
        qty: conversions[firstUom][uoms[j]],
      });
    }

    newUoms.sort((a, b) => parseFloat(a.qty) - parseFloat(b.qty));

    for (i = 0; i < uoms.length; i++) {
      uoms[i] = newUoms[i].uom;
    }

    return (
      <table>
        <thead>
          <tr>
            {uoms.map(c => (
              <th>
                <span>1 {c}</span>
              </th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {uoms.map(c => (
            <tr>
              {uoms.map(b => (
                <td>{conversions[c][b] < 1 ? '< 1' : conversions[c][b]}</td>
              ))}
              <td>{c}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  render() {
    const { itemDetail } = this.props;
    return (
      <div className="item-detail-page page">
        <div className="form-container">
          <div className="form-header">
            <div className="header-title">
              <div className="icon icon-item-catalogue"></div>
              <h2>Item Details</h2>
            </div>
            <div className="header-image">
              <h2>Item Image</h2>
              {itemDetail.imageList && itemDetail.imageList.length > 0 ? (
                <img
                  src={
                    itemDetail.imageList[0].indexOf('?') !== -1
                      ? itemDetail.imageList[0].substring(
                        0,
                        itemDetail.imageList[0].indexOf('?'),
                      )
                      : itemDetail.imageList[0]
                  }
                  alt="product"
                />
              ) : (
                <div className="image-placeholder">
                  <div className="icon icon-customer-service"></div>
                </div>
              )}
            </div>
          </div>
          <div className="form-body">
            <label>
              Item Number:{' '}
              <input
                type="text"
                disabled="disabled"
                value={itemDetail.itemNumber}
              />
            </label>
            <label>
              Item Description:{' '}
              <input
                type="text"
                disabled="disabled"
                value={itemDetail.itemDescription}
              />
            </label>

            {/*<label>
              Lifecycle Status:{" "}
              <input
                type="text"
                disabled="disabled"
                value={itemDetail.lifecycleStatus}
              />
            </label>*/}
            <label>
              Primary UOM:{' '}
              <input
                type="text"
                disabled="disabled"
                value={itemDetail.primaryUom}
              />
            </label>

            <label>
              UN Code:{' '}
              <input
                type="text"
                disabled="disabled"
                value={itemDetail.unCode}
              />
            </label>

            <label>
              Shelf Life:{' '}
              <input
                type="text"
                disabled="disabled"
                value={itemDetail.shelfLife}
              />
            </label>

            <label>
              Eur Harmonized Tariff Code:{' '}
              <input
                type="text"
                disabled="disabled"
                value={itemDetail.eurHarmonizedTariffCode}
              />
            </label>
          </div>

          <div className="form-body form-body-top">
            {itemDetail &&
              itemDetail.uomConversions &&
              itemDetail.uomConversions.length > 0 && (
              <label>
                  UOM Conversions
                {this.generateUOMConversions()}
              </label>
            )}
            {/*
              <ul>
                {itemDetail && itemDetail.uomConversions ? (
                  itemDetail.uomConversions.map(conv => (
                    <li
                      key={
                        conv.uomCodeFrom + conv.uomCodeTo
                      }>{`${conv.convRate} ${conv.uomCodeFrom} = 1 ${conv.uomCodeTo}`}</li>
                  ))
                ) : (
                  <li>No UOM Conversions Found</li>
                )}
              </ul>
            </label>*/}

            <label>
              Gtin List
              <ul>
                {itemDetail && itemDetail.gtinList ? (
                  itemDetail.gtinList.map(gtin => (
                    <li key={gtin.code}>
                      {gtin.uom} - {gtin.code}
                    </li>
                  ))
                ) : (
                  <li>No Gtins Found</li>
                )}
              </ul>
            </label>
            <label>
              Weights
              <ul>
                {itemDetail && itemDetail.weights ? (
                  itemDetail.weights.map(weight => (
                    <li
                      key={
                        weight.gross
                      }>{`${weight.gross}${weight.unit} gross/ ${weight.net}${weight.unit} net`}</li>
                  ))
                ) : (
                  <li>No Weights Found</li>
                )}
              </ul>
            </label>

            <label>
              Dimensions
              <ul>
                {itemDetail && itemDetail.dimensions ? (
                  itemDetail.dimensions.map(dim => (
                    <li
                      key={
                        dim.width + dim.height + dim.width + dim.unit
                      }>{`${parseFloat(dim.width)}W x ${parseFloat(
                        dim.height,
                      )}H x ${parseFloat(dim.length)}L ${dim.unit}`}</li>
                  ))
                ) : (
                  <li>No Dimensions Found</li>
                )}
              </ul>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.itemCatalogue.isFetching,
  loaded: state.itemCatalogue.isLoaded,
  stores: state.auth.stores,
  itemDetail: state.itemCatalogue.itemDetail,
});

const mapDispatchToProps = dispatch => {
  return {
    getItemDetail: (storeId, itemId) =>
      dispatch(itemCatalogueActions.requestItemDetail(storeId, itemId)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemDetail);
