import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';

class ConnectFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

  
  }

  getYear() {
    return new Date().getFullYear();
  }

  getStarbucksBusiness(tenant){
    if(tenant === "LAC"){
      return "Starbucks Latin America & Caribbean.";
    }

    if(tenant === "APAC"){
      return "Starbucks Asia Pacific.";
    }

    if(tenant === "EMEA"){
      return 'Starbucks Coffee Company UK.';
    }

    return 'Starbucks Coffee Company.'
  }

  render() {
    const { tenant } = this.props.auth;

    return (
      <footer className="connect-footer">
        <div className="connect-footer-container">
          <div className="connect-footer-logo">
            <hr />
            <img
              className="starbucks-main-logo"
              src="/assets/clear-logo.png"
              alt="Starbucks text logo"
            />
            <hr />
          </div>
          <div className="links">
    
            <button
              type="button"
              className="link"
              onClick={() => this.props.push('/termsandconditions')}
            >
                Terms &amp; Conditions
            </button>
            <a className = "link"
              href={tenant === "APAC" ? "mailto:apcsr.shipment@starbucks.com" : "mailto:ukstore@starbucks.com"}>
                Contact Us
            </a>
          </div>
          <div
            className="sbux-copyright"
          >
            ©
            {this.getYear()}
            {' '}
            {this.getStarbucksBusiness(tenant)}
             Confidential. For use only by authorised representatives of Starbuck Coffee Company and its licensees.
          </div>
          <div
            className="copyright"
            onClick={() => window.location = ('https://orderly.io')}
          >
            ©
            {this.getYear()}
            {' '}
            Powered by
            <img
              className="orderly-logo"
              src="/assets/logo-orderly.png"
              alt="orderly logo"
            />
          </div>
        </div>
       
      </footer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
 
  push: (path) => dispatch(navigationActions.pushNavigation(path)),
  replace: (path) => dispatch(navigationActions.replaceNavigation(path)),
  reset: () => dispatch(navigationActions.resetNavigation()),
  goBack: () => dispatch(navigationActions.backNavigation()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectFooter);
