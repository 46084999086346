import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { connectContentManagementActions } from '../../../actions/connectContentManagementActions';
import Template1 from '../templates/template1/template1';
import Template2 from '../templates/template2/template2';
import Template3 from '../templates/template3/template3';
import Template4 from '../templates/template4/template4';
import Template5 from '../templates/template5/template5';
import Template6 from '../templates/template6/template6';
import Template7 from '../templates/template7/template7';
import Template8 from '../templates/template8/template8';
import Template9 from '../templates/template9/template9';
import Template10 from '../templates/template10/template10';
import Template11 from '../templates/template11/template11';
import { DateTimePicker } from '@material-ui/pickers';
import DeleteModal from '../../shared/DeleteModal';
import ArchiveModal from '../../shared/ArchiveModal';
import { Tooltip } from '@material-ui/core';
import { Prompt } from 'react-router';

class ContentBuilder extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      contentId: 0,
      templateId: 0,
      labelId: 0,
      title: '',
      preview: '',
      message: '',

      priority: 1,

      audienceMode: 'all',
      storeGroupId: undefined,
      storeNumber: undefined,
      targetGroups: [],
      targetStores: [],
      targetMBUs: [],
      backgroundImage: {},
      imageContent1: {},
      attachment: {},
      key: undefined,
      fontColor: '#000',

      startDate: undefined,
      endDate: undefined,

      deleteOnEnd: false,

      currentTab: 'content',
      showValidation: false,

      showArchiveModal: false,
      showDeleteModal: false,

      archived: false,

      isDirty: false,
    };

    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleDateUpdate = this.handleDateUpdate.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleAssetUpdate = this.handleAssetUpdate.bind(this);
    this.handleBackgroundUpdate = this.handleBackgroundUpdate.bind(this);
    this.handleAttachmentUpdate = this.handleAttachmentUpdate.bind(this);
    this.handleAudienceAddition = this.handleAudienceAddition.bind(this);
    this.handleAudienceRemoval = this.handleAudienceRemoval.bind(this);

    this.handleSave = this.handleSave.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.handleArchive = this.handleArchive.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.handleModal = this.handleModal.bind(this);
  }

  componentDidMount() {
    const { getContentBuilder } = this.props;
    let contentId = this.props.match.params.contentId;

    this.setState({ contentId });

    getContentBuilder(contentId);
  }

  componentDidUpdate(prevProps) {
    const { isLoaded, isSaved, isDeleted, isArchived, content } = this.props;

    if (isLoaded && !prevProps.isLoaded) {

      if (content) {

        let audienceMode;
        let targetGroups = [];
        let targetStores = [];
        //let targetMBUs = [];

        if (content.allCustomers) {
          audienceMode = 'all';
        } else if (content.licenseeOnly) {
          audienceMode = 'licensee';
        } else if (content.retailOnly) {
          audienceMode = 'retail';
        } else if (content.targetGroups && content.targetGroups.length > 0) {
          audienceMode = 'groups';
          targetGroups = content.targetGroups;
        } else if (content.targetStores && content.targetStores.length > 0) {
          audienceMode = 'stores';
          targetStores = content.targetStores;
          /* } else if (content.targetMBUs && content.targetMBUs.length > 0) {
          audienceMode = 'mbuUsers';
          targetMBUs = content.targetMBUs;*/
        } else {
          audienceMode = 'none';
        }

        this.setState({
          contentId: content.contentId,
          templateId: content.templateId,
          labelId: content.labelId,
          title: content.title,
          message: content.message,
          preview: content.preview,

          priority: content.priority,

          audienceMode,
          targetGroups,
          targetStores,
          //targetMBUs,
          backgroundImage: content.backgroundImage,
          imageContent1: content.imageContent1,
          attachment: content.attachment,

          fontColor: content.fontColor,

          startDate: content.startDate,
          endDate: content.archivedDate ? content.archivedDate : content.deletedDate,

          deleteOnEnd: content.deletedDate ? true : false,

          archived: content.archivedDate && new Date(content.archivedDate) < new Date(),

          isDirty: false,
        });

        if (content.backgroundImage && content.backgroundImage.assetCategoryId) {
          this.props.getBackgrounds(content.backgroundImage.assetCategoryId);
        }

        if (content.imageContent1 && content.imageContent1.assetCategoryId) {
          this.props.getAssets(content.imageContent1.assetCategoryId);
        }

        if (content.attachment && content.attachment.assetCategoryId) {
          this.props.getAttachments(content.attachment.assetCategoryId);
        }
      }
    } else if ((isSaved && !prevProps.isSaved) ||
      (isArchived && !prevProps.isArchived) ||
      (isDeleted && !prevProps.isDeleted)
    ) {
      this.props.goBack();
    }
  }

  valid() {
    const { templateId, priority, endDate, startDate } = this.state;

    return templateId
      && ((priority > 0 && priority < 1000) || (!priority && templateId.toString() === '1'))
      && (startDate)
      && ((new Date(endDate) > new Date()) || !endDate);
  }

  handleSave(e) {
    const { saveContent } = this.props;
    const {
      contentId,
      labelId,
      templateId,
      title,
      message,
      preview,

      priority,

      imageContent1,
      backgroundImage,
      attachment,

      fontColor,

      audienceMode,

      startDate,
      endDate,
      deleteOnEnd,

      targetGroups,
      targetStores,
      //targetMBUs,
    } = this.state;


    if (!this.valid()) {
      this.setState({ showValidation: true });
    } else {
      saveContent({
        contentId,
        labelId,
        templateId,
        title,
        message,
        preview,
        imageContent1Id: imageContent1 && imageContent1.assetId ? imageContent1.assetId : null,
        backgroundImageId: backgroundImage && backgroundImage.assetId ? backgroundImage.assetId : null,
        attachmentId: attachment && attachment.assetId ? attachment.assetId : null,
        fontColor,
        priority: templateId && templateId.toString() === '1' ? 0 : priority,
        allCustomers: audienceMode === 'all',
        retailOnly: audienceMode === 'retail',
        licenseeOnly: audienceMode === 'licensee',
        startDate,
        archivedDate: deleteOnEnd ? null : endDate,
        deletedDate: deleteOnEnd ? endDate : null,
        targetGroups: audienceMode === 'groups' ? targetGroups : [],
        targetStores: audienceMode === 'stores' ? targetStores : [],
        //targetMBUs : audienceMode === 'mbuUsers' ? targetMBUs : [],
      });

      this.setState({ isDirty: false });
    }

    e.preventDefault();
  }

  handleDuplicate() {
    const { pathname } = this.props.location;
    this.setState({ contentId: 0, archived: false, isDirty:true });

    let newPath = pathname.toLowerCase();

    let index = newPath.indexOf('contentbuilder');

    newPath = newPath.substring(0, index);

    this.props.replace(`${newPath}contentbuilder/0`);
  }

  handleArchive() {
    const { contentId } = this.state;
    const { archiveContent } = this.props;

    archiveContent(contentId);
  }

  handleDelete() {
    const { contentId } = this.state;
    const { deleteContent } = this.props;

    deleteContent(contentId);
  }

  handleTextInputUpdate(e, fieldName, objectName) {
    if (objectName) {
      let { [objectName]: obj } = this.state;
      obj[fieldName] = e.target.value;
      this.setState({ [objectName]: { ...obj }, isDirty: true });
    } else {
      this.setState({ [fieldName]: e.target.value, isDirty: true });
    }
  }

  handleDateUpdate(e, fieldName) {

    this.setState({ [fieldName]: e, isDirty: true });

  }

  handleLabelChange(e) {
    const { labels } = this.props;

    const currentLabel = labels.filter(l => l.labelId.toString() === e.target.value);

    this.setState({ label: { ...currentLabel, isDirty: true } });
  }

  handleAssetUpdate(e, assetTypeName) {
    const { assets } = this.props;

    let asset = assets.find(a => a.assetId.toString() === e.target.value);

    this.setState({ [assetTypeName]: { ...asset, isDirty: true } });
  }

  handleAttachmentUpdate(e, assetTypeName) {
    const { attachments } = this.props;

    let asset = attachments.find(a => a.assetId.toString() === e.target.value);

    this.setState({ [assetTypeName]: { ...asset, isDirty: true } });
  }

  handleBackgroundUpdate(e, assetTypeName) {
    const { backgrounds } = this.props;

    let asset = backgrounds.find(a => a.assetId.toString() === e.target.value);

    this.setState({ [assetTypeName]: { ...asset, isDirty: true } });
  }

  handleChangeTab(tab) {
    this.setState({ currentTab: tab });
  }

  handleAudienceAddition(rawVal, audienceType) {
    const { [audienceType]: audienceList } = this.state;

    const value = parseInt(rawVal);

    const index = audienceList.indexOf(value);

    if (index === -1 && !isNaN(value)) {
      audienceList.push(value);

      this.setState({ [audienceType]: [...audienceList], isDirty: true });
    }
  }

  handleAudienceRemoval(rawVal, audienceType) {
    const { [audienceType]: audienceList } = this.state;

    const value = parseInt(rawVal);

    const index = audienceList.indexOf(value);

    if (index > -1 && !isNaN(value)) {
      audienceList.splice(index, 1);

      this.setState({ [audienceType]: [...audienceList], isDirty: true });
    }
  }

  handleModal(modalName, show) {
    this.setState({ [modalName]: show });
  }

  getCombinedAssetLabel(title, description) {

    const maxLength = 20;

    if (title.length > maxLength) {
      title = title.substr(0, maxLength) + '...';
    }

    if (description.length > maxLength) {
      description = description.substr(0, maxLength) + '...';
    }

    return `${title} - ${description}`;
  }

  getTitle(isLoaded, contentId, archived) {

    if (!isLoaded) {
      return 'Content';
    } else if (archived) {
      return 'Content Viewer';
    } else if (contentId) {
      return 'Content Builder (Edit)';
    } else {
      return 'Content Builder (Create)';
    }

  }

  render() {
    const {
      labelId,
      title,
      message,
      preview,
      imageContent1,
      backgroundImage,
      attachment,
      fontColor,
      priority,
      showValidation,
      templateId,
      currentTab,
      startDate,
      endDate,
      deleteOnEnd,
      audienceMode,
      //key,
      storeGroupId,
      storeNumber,
      targetGroups,
      targetStores,
      //targetMBUs,
      contentId,

      showArchiveModal,
      showDeleteModal,

      archived,

      isDirty,

    } = this.state;
    const {
      isSaving,
      isSaved,
      isArchiving,
      isArchived,
      isDeleted,
      isDeleting,
      isLoaded,
      errorMessage,
      templates,
      labels,
      categories,
      assets,
      backgrounds,
      attachments,
      audiences,
      //mbuUsers,
      canEdit,
      tenantKey,
    } = this.props;

    const isEMEA = tenantKey === 'EMEA';

    let label = labels.find(l => l.labelId && labelId && l.labelId.toString() === labelId.toString());


    return (
      <div className="content-builder page">
        <form noValidate
          onSubmit={(e) => this.handleSave(e)}>
          <div className="floating-panel">

            <div className="floating-header">
              <div className="header-title">
                <div className="icon icon-form"></div>
                <h2>{this.getTitle(isLoaded, contentId, archived)}</h2>
              </div>
              <div>

                <p>{errorMessage}</p>
                <div className="button-container">
                  {canEdit && !archived ?
                    <button
                      type="submit"
                      className="action"
                      disabled={isSaving}>
                      {isSaving
                        ? 'Saving...'
                        : isSaved
                          ? 'Saved'
                          : 'Save'}
                    </button> : null}
                  {canEdit && contentId > 0 ?
                    <>
                      <button
                        type="button"
                        className="action"
                        onClick={(e) => this.handleDuplicate()}>
                        Duplicate
                      </button>
                      {!archived &&
                        <button
                          type="button"
                          className="action negative"
                          disabled={isArchiving}
                          onClick={() => this.handleModal('showArchiveModal', true)}>
                          {isArchiving
                            ? 'Archiving...'
                            : isArchived
                              ? 'Archived'
                              : 'Archive'}
                        </button>
                      }
                      <button
                        type="button"
                        className="action negative"
                        onClick={() => this.handleModal('showDeleteModal', true)}
                        disabled={isDeleting}>
                        {isDeleting
                          ? 'Deleting...'
                          : isDeleted
                            ? 'Deleted'
                            : 'Delete'}
                      </button>
                    </>
                    : null}
                </div>
              </div>
            </div>
          </div>
          {canEdit ?
            <div className="template-selector">

              <select
                onChange={e => this.setState({
                  templateId: e.target.value,
                  currentTab: 'content',
                  priority: templateId && templateId.toString() === '1' ? 1 : priority,
                })}
                required={true}
                disabled={archived}
                className={showValidation && !templateId ? 'invalid' : ''}
                value={templateId}>

                <option>Template</option>
                {templates && templates.map(t =>
                  <option key={t.templateId}
                    value={t.templateId}>{t.name}</option>,
                )};
              </select>
            </div> : null}


          <div className="preview">
            {templateId && templateId.toString() === '1' ?
              <Template1
                id={1}
                label={label && label.text}
                subHeading={title}
                preview={preview}
                content={message}
                assetData={imageContent1}
                attachment={attachment}
                fontColor={fontColor}
                onRead={() => { }}
                onWatch={() => { }}
              /> : null}
            {templateId && templateId.toString() === '2' ?
              <Template2
                id={1}
                label={label && label.text}
                subHeading={title}
                preview={preview}
                content={message}
                background={backgroundImage}
                assetData={imageContent1}
                attachment={attachment}
                fontColor={fontColor}
                onRead={() => { }}
                onWatch={() => { }}
              /> : null}
            {templateId && templateId.toString() === '3' ?
              <Template3
                id={1}
                label={label && label.text}
                subHeading={title}
                preview={preview}
                content={message}
                background={backgroundImage}
                assetData={imageContent1}
                attachment={attachment}
                fontColor={fontColor}
                onRead={() => { }}
                onWatch={() => { }}
              /> : null}
            {templateId && templateId.toString() === '4' ?
              <Template4
                id={1}
                label={label && label.text}
                subHeading={title}
                preview={preview}
                content={message}
                background={backgroundImage}
                assetData={imageContent1}
                attachment={attachment}
                fontColor={fontColor}
                onRead={() => { }}
                onWatch={() => { }}
              /> : null}
            {templateId && templateId.toString() === '5' ?
              <Template5
                id={1}
                label={label && label.text}
                subHeading={title}
                preview={preview}
                content={message}
                background={backgroundImage}
                assetData={imageContent1}
                attachment={attachment}
                fontColor={fontColor}
                onRead={() => { }}
                onWatch={() => { }}
              /> : null}
            {templateId && templateId.toString() === '6' ?
              <Template6
                id={1}
                label={label && label.text}
                subHeading={title}
                preview={preview}
                content={message}
                background={backgroundImage}
                assetData={imageContent1}
                attachment={attachment}
                fontColor={fontColor}
                onRead={() => { }}
                onWatch={() => { }}
              /> : null}
            {templateId && templateId.toString() === '7' ?
              <Template7
                id={1}
                label={label && label.text}
                subHeading={title}
                preview={preview}
                content={message}
                background={backgroundImage}
                assetData={imageContent1}
                attachment={attachment}
                fontColor={fontColor}
                onRead={() => { }}
                onWatch={() => { }}
              /> : null}
            {templateId && templateId.toString() === '8' ?
              <Template8
                id={1}
                label={label && label.text}
                subHeading={title}
                preview={preview}
                content={message}
                background={backgroundImage}
                assetData={imageContent1}
                attachment={attachment}
                fontColor={fontColor}
                onRead={() => { }}
                onWatch={() => { }}
              /> : null}
            {templateId && templateId.toString() === '9' ?
              <Template9
                id={1}
                label={label && label.text}
                subHeading={title}
                preview={preview}
                content={message}
                background={backgroundImage}
                assetData={imageContent1}
                attachment={attachment}
                fontColor={fontColor}
                onRead={() => { }}
                onWatch={() => { }}
              /> : null}
            {templateId && templateId.toString() === '10' ?
              <Template10
                id={1}
                label={label && label.text}
                subHeading={title}
                preview={preview}
                content={message}
                background={backgroundImage}
                assetData={imageContent1}
                attachment={attachment}
                fontColor={fontColor}
                onRead={() => { }}
                onWatch={() => { }}
              /> : null}
            {templateId && templateId.toString() === '11' ?
              <Template11
                id={1}
                label={label && label.text}
                subHeading={title}
                preview={preview}
                content={message}
                background={backgroundImage}
                assetData={imageContent1}
                attachment={attachment}
                fontColor={fontColor}
                onRead={() => { }}
                onWatch={() => { }}
              /> : null}

          </div>
          {canEdit && templateId && templateId.toString() !== '0' ?
            <>
              <div className="tab-container">
                <button
                  type="button"
                  className={`tab-button ${currentTab === 'content' ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab('content')}
                >Content</button>
                <button
                  type="button"
                  className={`tab-button ${currentTab === 'asset' ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab('asset')}
                >Asset</button>
                {templateId && templateId > 1 ? <button
                  type="button"
                  className={`tab-button ${currentTab === 'background' ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab('background')}
                >Background</button> : null}
                {templateId && templateId > 1 ? <button
                  type="button"
                  className={`tab-button ${currentTab === 'priority' ? 'selected' : ''} ${showValidation && (!priority || priority < 1 || priority > 999) ? 'invalid' : ''}`}
                  onClick={() => this.handleChangeTab('priority')}
                >Priority</button> : null}
                <button
                  type="button"
                  className={`tab-button ${currentTab === 'attachment' ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab('attachment')}
                >Attachment</button>
                <button
                  type="button"
                  className={`tab-button ${currentTab === 'audience' ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab('audience')}
                >Audience</button>
                <button
                  type="button"
                  className={`tab-button ${currentTab === 'schedule' ? 'selected' : ''} ${showValidation && ((endDate && new Date(endDate) < new Date()) || !startDate ) ? 'invalid' : ''}`}
                  onClick={() => this.handleChangeTab('schedule')}
                >Schedule</button>
              </div>

              {currentTab === 'content' &&
                <div className="floating-panel content-panel">
                  <div className="horizontal-stack">
                    <div className="content-headers">
                      <label>
                        Heading
                        <input
                          placeholder="..."
                          required
                          className={showValidation && !title ? 'invalid' : ''}
                          onChange={e => this.handleTextInputUpdate(e, 'title')}
                          value={title}
                          disabled={archived}
                        />
                      </label>
                      <label>
                        Label
                        <select
                          placeholder="..."
                          required
                          className={showValidation && !labelId ? 'invalid' : ''}
                          value={labelId}
                          onChange={(e) => this.setState({ labelId: e.target.value })}
                          disabled={archived}
                        >
                          <option value="">Select one...</option>
                          {labels && labels.map(l =>
                            <option key={l.labelId}
                              value={l.labelId}>{l.text}</option>)
                          }
                        </select>
                      </label>
                      {templateId && templateId > 1 ?
                        <label>
                          Font Colour
                          <input
                            type="color"
                            value={fontColor}
                            onChange={(e) => this.setState({ fontColor: e.target.value })}
                          />
                        </label> : null}
                    </div>
                    <label className="big-content">
                      Preview <Tooltip
                        title={'The text entered below will make up the preview. \
                      This can be extended through the use of the markdown language. \
                      This permits the addition of bullet points, headings and links.'}>
                        <span className="info">&#x1F6C8;</span></Tooltip>
                      <textarea
                        placeholder="..."
                        disabled={archived}
                        required
                        className={showValidation && !preview ? 'invalid' : ''}
                        onChange={e => this.handleTextInputUpdate(e, 'preview')}
                        value={preview}
                      />
                    </label>
                    <label className="big-content">
                      Content <Tooltip
                        title={'The text entered below will make up the bulk of the content. \
                      This can be extended through the use of the markdown language. \
                      This permits the addition of bullet points, headings and links.'}>
                        <span className="info">&#x1F6C8;</span></Tooltip>
                      <textarea
                        placeholder="..."
                        disabled={archived}
                        required
                        className={showValidation && !message ? 'invalid' : ''}
                        onChange={e => this.handleTextInputUpdate(e, 'message')}
                        value={message}
                      />
                    </label>
                  </div>
                </div>
              }

              {currentTab === 'asset' &&
                <div className="floating-panel asset-panel">
                  <label>
                    Asset Category
                    <select
                      placeholder="..."
                      required
                      disabled={archived}
                      value={imageContent1 && imageContent1.assetCategoryId}
                      onChange={(e) => {
                        if (e.target.value)
                          this.props.getAssets(e.target.value);

                        this.setState({ imageContent1: { assetCategoryId: e.target.value } });
                      }}
                    >
                      <option value="">Select one...</option>
                      {categories && categories.map(l =>
                        <option key={l.assetCategoryId}
                          value={l.assetCategoryId}>{l.name}</option>)
                      }
                    </select>
                  </label>

                  <label>
                    Asset <Tooltip
                      title={'The selected asset will fill the asset slot in the template. \
                        This can be audio, video or an image. \
                        Some templates will better suit certain types of content.'}>
                      <span className="info">&#x1F6C8;</span></Tooltip>
                    <select
                      placeholder="..."
                      required
                      disabled={archived}
                      value={imageContent1 && imageContent1.assetId}
                      onChange={(e) => this.handleAssetUpdate(e, 'imageContent1')}
                    >
                      <option value="">Select one...</option>
                      {assets && assets.filter(b => b.type.indexOf('image') > -1 || b.type.indexOf('video') > -1 || b.type.indexOf('audio') > -1).map(l =>
                        <option key={l.assetId}
                          value={l.assetId}>{this.getCombinedAssetLabel(l.title, l.description)}</option>)
                      }
                    </select>
                  </label>
                </div>}
              {currentTab === 'background' &&
                <div className="floating-panel background-panel">
                  <label>
                    Background Category
                    <select
                      placeholder="..."
                      required
                      disabled={archived}
                      value={backgroundImage && backgroundImage.assetCategoryId}
                      onChange={(e) => {
                        if (e.target.value)
                          this.props.getBackgrounds(e.target.value);

                        this.setState({ backgroundImage: { assetCategoryId: e.target.value } });
                      }}
                    >
                      <option value="">Select one...</option>
                      {categories && categories.map(l =>
                        <option key={l.assetCategoryId}
                          value={l.assetCategoryId}>{l.name}</option>)
                      }
                    </select>
                  </label>

                  <label>
                    Background Asset <Tooltip
                      title={'The selected background will cover the background of the template. \
                        Depending on the template please check that text is legible.'}>
                      <span className="info">&#x1F6C8;</span></Tooltip>
                    <select
                      placeholder="..."
                      required
                      disabled={archived}
                      value={backgroundImage && backgroundImage.assetId}
                      onChange={(e) => this.handleBackgroundUpdate(e, 'backgroundImage')}
                    >
                      <option value="">Select one...</option>
                      {backgrounds && backgrounds.filter(b => b.type.indexOf('image') > -1).map(l =>
                        <option key={l.assetId}
                          value={l.assetId}>{this.getCombinedAssetLabel(l.title, l.description)}</option>)
                      }
                    </select>
                  </label>
                </div>}
              {currentTab === 'attachment' &&
                <div className="floating-panel background-panel">
                  <label>
                    Attachment Category
                    <select
                      placeholder="..."
                      required
                      disabled={archived}
                      value={attachment && attachment.assetCategoryId}
                      onChange={(e) => {
                        if (e.target.value)
                          this.props.getAttachments(e.target.value);

                        this.setState({ attachment: { assetCategoryId: e.target.value } });
                      }}
                    >
                      <option value="">Select one...</option>
                      {categories && categories.map(l =>
                        <option key={l.assetCategoryId}
                          value={l.assetCategoryId}>{l.name}</option>)
                      }
                    </select>
                  </label>

                  <label>
                    Attachment File <Tooltip
                      title={'The selected asset will be made available to users to download. \
                        This will appear as a download button under the content.'}>
                      <span className="info">&#x1F6C8;</span></Tooltip>
                    <select
                      placeholder="..."
                      required
                      disabled={archived}
                      value={attachment && attachment.assetId}
                      onChange={(e) => this.handleAttachmentUpdate(e, 'attachment')}
                    >
                      <option value="">Select one...</option>
                      {attachments && attachments.map(l =>
                        <option key={l.assetId}
                          value={l.assetId}>{this.getCombinedAssetLabel(l.title, l.description)}</option>)
                      }
                    </select>
                  </label>
                </div>}
              {currentTab === 'priority' &&
                <div className="floating-panel priority-panel">
                  <p>The priority will influence the order that content is displayed when shown in Connect alongside other pieces of content.
                  Where two pieces of content have the same priority the most recently published will appear first. Priority 0 is reserved for alerts.</p>
                  <label>
                    Priority
                    <input
                      placeholder="..."
                      required
                      disabled={archived}
                      type="number"
                      min={1}
                      max={999}
                      maxLength={3}
                      className={showValidation && (!priority || priority < 1 || priority > 999) ? 'invalid' : ''}
                      onChange={e => this.handleTextInputUpdate(e, 'priority')}
                      value={priority}
                    />
                  </label>
                </div>}
              {currentTab === 'audience' &&
                <div className="floating-panel audience-panel">
                  <div className="horizontal-stack">
                    <label>
                      Target Audience...
                      <label><input type="radio"
                        disabled={archived}
                        name="audienceMode"
                        value="all"
                        checked={audienceMode === 'all'}
                        onChange={(e) => this.setState({ audienceMode: e.target.value })} /> All Connect Users <Tooltip
                        title={'Display this content to all connect users.'}>
                        <span className="info">&#x1F6C8;</span></Tooltip></label>
                      <label><input type="radio"
                        disabled={archived}
                        name="audienceMode"
                        value="retail"
                        checked={audienceMode === 'retail'}
                        onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Retail Users <Tooltip
                        title={'Display this content to only retail users.'}>
                        <span className="info">&#x1F6C8;</span></Tooltip></label>
                      <label><input type="radio"
                        disabled={archived}
                        name="audienceMode"
                        value="licensee"
                        checked={audienceMode === 'licensee'}
                        onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Licensee Users <Tooltip
                        title={'Display this content to only licensee users.'}>
                        <span className="info">&#x1F6C8;</span></Tooltip></label>
                      {isEMEA ? (
                        <label><input type="radio"
                          disabled={archived}
                          name="audienceMode"
                          value="groups"
                          checked={audienceMode === 'groups'}
                          onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Store Groups <Tooltip
                          title={'Display this content to users in any of the selected store groups.'}>
                          <span className="info">&#x1F6C8;</span></Tooltip></label>
                      ) : 
                        <label><input type="radio"
                          disabled={archived}
                          name="audienceMode"
                          value="groups"
                          checked={audienceMode === 'groups'}
                          onChange={(e) => this.setState({ audienceMode: e.target.value })} /> MBU Groups <Tooltip
                          title={'Display this content to users in any of the selected MBU groups.'}>
                          <span className="info">&#x1F6C8;</span></Tooltip></label>
                      } 
                      {isEMEA ? (
                        <label><input type="radio"
                          disabled={archived}
                          name="audienceMode"
                          value="stores"
                          checked={audienceMode === 'stores'}
                          onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Individual Stores <Tooltip
                          title={'Display this content to users with access to any of the selected stores.'}>
                          <span className="info">&#x1F6C8;</span></Tooltip></label>
                      ) :
                        <label><input type="radio"
                          disabled={archived}
                          name="audienceMode"
                          value="stores"
                          checked={audienceMode === 'stores'}
                          onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Individual Customers <Tooltip
                          title={'Display this content to users with access to any of the selected customers.'}>
                          <span className="info">&#x1F6C8;</span></Tooltip></label>
                      }
                      {/*{!isEMEA &&
                        <label><input type="radio"
                          disabled={archived}
                          name="audienceMode"
                          value="mbuUsers"
                          checked={audienceMode === 'mbuUsers'}
                          onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Individual MBU Users <Tooltip
                          title={'Display this content to users with access to any of the selected MBU Users.'}>
                          <span className="info">&#x1F6C8;</span></Tooltip></label>
                      }*/ }
                    </label>
                    {audienceMode === 'groups' && isEMEA ? <div>
                      <label>
                        Store Groups
                        <div className="floating-header">
                          <select
                            placeholder="..."
                            required
                            disabled={archived}
                            value={storeGroupId}
                            onChange={e => this.setState({ storeGroupId: e.target.value })}
                          >
                            <option value="">Select one...</option>
                            {audiences && audiences.map(l =>
                              <option key={l.storeGroupId}
                                value={l.storeGroupId}>{l.storeGroupName}</option>)
                            }
                          </select>
                          <button
                            type="button"
                            disabled={archived}
                            className="action"
                            onClick={e => this.handleAudienceAddition(storeGroupId, 'targetGroups')}
                          >
                            Add
                          </button>
                        </div>
                      </label>
                      <div className="label-list">

                        {targetGroups && targetGroups.length > 0 && targetGroups.map(g => {

                          let group = audiences.find(a => a.storeGroupId.toString() === g.toString());

                          if (group) {
                            return (<label
                              key={group.storeGroupId}
                            >
                              {group.storeGroupName}
                              <button
                                type="button"
                                disabled={archived}
                                className="action negative"
                                onClick={e => this.handleAudienceRemoval(group.storeGroupId, 'targetGroups')}
                              >Remove</button>
                            </label>
                            );
                          }

                          return null;
                        },
                        )}
                      </div>
                    </div> : null}  
                    {audienceMode === 'groups' && !isEMEA ? <div>
                      <label>
                        MBU Groups
                        <div className="floating-header">
                          <select
                            placeholder="..."
                            required
                            disabled={archived}
                            value={storeGroupId}
                            onChange={e => this.setState({ storeGroupId: e.target.value })}
                          >
                            <option value="">Select one...</option>
                            {audiences && audiences.map(l =>
                              <option key={l.storeGroupId}
                                value={l.storeGroupId}>{l.storeGroupName}</option>)
                            }
                          </select>
                          <button
                            type="button"
                            disabled={archived}
                            className="action"
                            onClick={e => this.handleAudienceAddition(storeGroupId, 'targetGroups')}
                          >
                            Add
                          </button>
                        </div>
                      </label>
                      <div className="label-list">

                        {targetGroups && targetGroups.length > 0 && targetGroups.map(g => {

                          let group = audiences.find(a => a.storeGroupId.toString() === g.toString());

                          if (group) {
                            return (<label
                              key={group.storeGroupId}
                            >
                              {group.storeGroupName}
                              <button
                                type="button"
                                disabled={archived}
                                className="action negative"
                                onClick={e => this.handleAudienceRemoval(group.storeGroupId, 'targetGroups')}
                              >Remove</button>
                            </label>
                            );
                          }

                          return null;
                        },
                        )}
                      </div>
                    </div> : null}           
                    {audienceMode === 'stores' && isEMEA ? <div>
                      <label>
                        Stores
                        <div className="floating-header">
                          <input
                            placeholder="..."
                            required
                            disabled={archived}
                            type="number"
                            onChange={e => this.setState({ storeNumber: e.target.value })}
                            value={storeNumber}
                          />
                          <button
                            type="button"
                            disabled={archived}
                            className="action"
                            onClick={e => this.handleAudienceAddition(storeNumber, 'targetStores')}
                          >
                            Add
                          </button>
                        </div>
                      </label>

                      <div className="label-list">

                        {targetStores && targetStores.length > 0 && targetStores.map(g => {
                          return (<label
                            key={g}
                          >
                            {g}
                            <button
                              type="button"
                              disabled={archived}
                              className="action negative"
                              onClick={e => this.handleAudienceRemoval(g, 'targetStores')}
                            >Remove</button>
                          </label>
                          );
                        },
                        )}
                      </div>

                    </div> : null}
                    {audienceMode === 'stores' && !isEMEA ? <div>
                      <label>
                        Customers
                        <div className="floating-header">
                          <input
                            placeholder="..."
                            required
                            disabled={archived}
                            type="number"
                            onChange={e => this.setState({ storeNumber: e.target.value })}
                            value={storeNumber}
                          />
                          <button
                            type="button"
                            disabled={archived}
                            className="action"
                            onClick={e => this.handleAudienceAddition(storeNumber, 'targetStores')}
                          >
                            Add
                          </button>
                        </div>
                      </label>

                      <div className="label-list">

                        {targetStores && targetStores.length > 0 && targetStores.map(g => {
                          return (<label
                            key={g}
                          >
                            {g}
                            <button
                              type="button"
                              disabled={archived}
                              className="action negative"
                              onClick={e => this.handleAudienceRemoval(g, 'targetStores')}
                            >Remove</button>
                          </label>
                          );
                        },
                        )}
                      </div>

                    </div> : null}
                    {/*{audienceMode === 'mbuUsers' ? <div>
                      <label>
                        MBU Users
                        <div className="floating-header">
                          <select
                            placeholder="..."
                            required
                            disabled={archived}
                            value={key}
                            onChange={e => this.setState({ key: e.target.value })}
                          >
                            <option value="">Select one...</option>
                            {mbuUsers && mbuUsers.map(l =>
                              <option key={l.key}
                                value={l.key}>{l.value}</option>)
                            }
                          </select>
                          <button
                            type="button"
                            disabled={archived}
                            className="action"
                            onClick={e => this.handleAudienceAddition(key, 'targetMBUs')}
                          >
                            Add
                          </button>
                        </div>
                      </label>
                      <div className="label-list">

                        {targetMBUs && targetMBUs.length > 0 && targetMBUs.map(g => {

                          let group = mbuUsers.find(a => a.key.toString() === g.toString());

                          if (group) {
                            return (<label
                              key={group.key}
                            >
                              {group.value}
                              <button
                                type="button"
                                disabled={archived}
                                className="action negative"
                                onClick={e => this.handleAudienceRemoval(group.key, 'targetMBUs')}
                              >Remove</button>
                            </label>
                            );
                          }

                          return null;
                        },
                        )}
                      </div>
                      </div> : null} */}
                  </div>

                </div>}
              {currentTab === 'schedule' &&
                <div className="floating-panel schedule-panel">
                  <div className="horizontal-stack">
                    <div className="schedule-headers">
                      <label>
                        Publish Date <Tooltip
                          title={'The date the content will become visible to the target audience.'}>
                          <span className="info">&#x1F6C8;</span></Tooltip>
                        <DateTimePicker
                          value={startDate}
                          disabled={archived}
                          ampm={false}
                          className={showValidation && !startDate ? 'invalid' : ''}
                          onChange={(mom) => this.handleDateUpdate(mom.toISOString(), 'startDate')} />
                      </label>
                      <label>
                        End Date <Tooltip
                          title={'The date the content will stop being visible to the target audience.'}>
                          <span className="info">&#x1F6C8;</span></Tooltip>
                        <DateTimePicker
                          value={endDate}
                          disabled={archived}
                          ampm={false}
                          minDate={startDate}
                          className={showValidation && endDate && new Date(endDate) < new Date() ? 'invalid' : ''}
                          onChange={(mom) => this.handleDateUpdate(mom.toISOString(), 'endDate')} />
                      </label>
                    </div>
                    <div>
                      <label>
                        After End Date...
                        <label><input type="radio"
                          name="ondelete"
                          value="archive"
                          disabled={archived}
                          checked={!deleteOnEnd}
                          onChange={() => this.setState({ deleteOnEnd: false })} /> Archive <Tooltip
                          title={'After the content is delisted, move the content to the content archive.'}>
                          <span className="info">&#x1F6C8;</span></Tooltip></label>
                        <label><input type="radio"
                          name="ondelete"
                          value="delete"
                          disabled={archived}
                          checked={deleteOnEnd}
                          onChange={() => this.setState({ deleteOnEnd: true })} /> Delete <Tooltip
                          title={'After the content is delisted, remove the content from the system.'}>
                          <span className="info">&#x1F6C8;</span></Tooltip></label>
                      </label>
                    </div>
                  </div>
                </div>}

            </>
            : null}
        </form>

        {showDeleteModal && (
          <DeleteModal
            cancel={() => this.handleModal('showDeleteModal', false)}
            continue={this.handleDelete}
          />
        )}

        {showArchiveModal && (
          <ArchiveModal
            cancel={() => this.handleModal('showArchiveModal', false)}
            continue={this.handleArchive}
          />
        )}

        <Prompt
          when={isDirty}
          message='You have unsaved changes, are you sure you want to leave?'
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { connectContentManagement } = state;
  return {
    isLoading: connectContentManagement.isLoading,
    isLoaded: connectContentManagement.isLoaded,
    isSaving: connectContentManagement.isSaving,
    isSaved: connectContentManagement.isSaved,
    isDeleting: connectContentManagement.isDeleting,
    isDeleted: connectContentManagement.isDeleted,
    isArchiving: connectContentManagement.isArchiving,
    isArchived: connectContentManagement.isArchived,
    tenantKey: state.auth.tenant,
    canEdit: connectContentManagement.canEdit,

    labels: connectContentManagement.labels,
    templates: connectContentManagement.templates,
    audiences: connectContentManagement.audiences,
    //mbuUsers: connectContentManagement.mbuUsers,
    categories: connectContentManagement.categories,
    content: connectContentManagement.content,

    assets: connectContentManagement.assets,
    backgrounds: connectContentManagement.backgrounds,
    attachments: connectContentManagement.attachments,

    errorMessage: connectContentManagement.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getContentBuilder: (contentId) => dispatch(connectContentManagementActions.getContentBuilder(contentId)),
    getAssets: (assetCategoryId) => dispatch(connectContentManagementActions.requestAssets(assetCategoryId)),
    getBackgrounds: (assetCategoryId) => dispatch(connectContentManagementActions.requestBackgrounds(assetCategoryId)),
    getAttachments: (assetCategoryId) => dispatch(connectContentManagementActions.requestAttachments(assetCategoryId)),

    saveContent: (saveData) => dispatch(connectContentManagementActions.saveContent(saveData)),
    archiveContent: (contentId) => dispatch(connectContentManagementActions.archiveContent(contentId)),
    deleteContent: (contentId) => dispatch(connectContentManagementActions.deleteContent(contentId)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentBuilder);
