import React from 'react';

class CustomerTabOds extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { customerRequirementsDetail, handleTextInputUpdate } = this.props;
    return (
      <>
        <label>
      Customer #
          <input
            type="text"
            disabled
            value={customerRequirementsDetail.customerNumber}
          />
        </label>
        <label>
      Customer Name
          <input
            type="text"
            disabled
            value={customerRequirementsDetail.customerName}
          />
        </label>
        <label>
      Market Name
          <input
            type="text"
            disabled
            value={customerRequirementsDetail.marketName}
          />
        </label>
        <label>
      Primary Market Contact Name
          <input
            maxLength={250}
            type="text"
            value={customerRequirementsDetail && customerRequirementsDetail.primaryMarketContactName}
            onChange={e => handleTextInputUpdate(e, 'primaryMarketContactName')}
          />
        </label>
        <label>
      Primary Market Contact Email
          <input
            maxLength={250}
            type="text"
            value={customerRequirementsDetail && customerRequirementsDetail.primaryMarketContactEmail}
            onChange={e => handleTextInputUpdate(e, 'primaryMarketContactEmail')}
          />
        </label>
        <label>
      Primary Market Contact Telephone
          <input
            maxLength={50}
            type="text"
            value={customerRequirementsDetail && customerRequirementsDetail.primaryMarketContactTelephone}
            onChange={e => handleTextInputUpdate(e, 'primaryMarketContactTelephone')}
          />
        </label>
      </>
    );
  }
}

export default CustomerTabOds;
