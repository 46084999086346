import {
  PUSHNAVIGATION_REQUEST,
  REPLACENAVIGATION_REQUEST,
  BACKNAVIGATION_REQUEST,
  RESETNAVIGATION_REQUEST,
} from '../actions/actionTypes';
import initialState from './initialState';

export const navigationReducer = (
  state = initialState.navigationState,
  action,
) => {
  switch (action.type) {
  case PUSHNAVIGATION_REQUEST: {
    return {
      history: [...state.history, action.payload.url],
      navigationCount: state.navigationCount + 1,
    };
  }
  case REPLACENAVIGATION_REQUEST: {
    if (state.history.length > 0) state.history.pop();

    return {
      history: [...state.history, action.payload.url],
      navigationCount: state.navigationCount + 1,
    };
  }
  case BACKNAVIGATION_REQUEST: {
    if (state.history.length > 0) state.history.pop();

    return {
      history: [...state.history],
      navigationCount: state.navigationCount + 1,
    };
  }
  case RESETNAVIGATION_REQUEST: {
    return {
      ...state,
      history: [],
      navigationCount: state.navigationCount + 1,
    };
  }
  default:
    return state;
  }
};
export default navigationReducer;
