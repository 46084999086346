import {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAILURE,
  USERID_REQUEST,
  USERID_SUCCESS,
  USERID_FAILURE,
  UPDATEMYUSER_REQUEST,
  UPDATEMYUSER_SUCCESS,
  UPDATEMYUSER_FAILURE,
  SAVEUSER_REQUEST,
  SAVEUSER_SUCCESS,
  SAVEUSER_FAILURE,
  DELETEUSER_REQUEST,
  DELETEUSER_SUCCESS,
  DELETEUSER_FAILURE,
  MODULEGROUPS_REQUEST,
  MODULEGROUPS_SUCCESS,
  MODULEGROUPS_FAILURE,
  MODULEGROUPID_REQUEST,
  MODULEGROUPID_SUCCESS,
  MODULEGROUPID_FAILURE,
  SAVEMODULEGROUP_REQUEST,
  SAVEMODULEGROUP_SUCCESS,
  SAVEMODULEGROUP_FAILURE,
  DELETEMODULEGROUP_REQUEST,
  DELETEMODULEGROUP_SUCCESS,
  DELETEMODULEGROUP_FAILURE,
  STOREGROUPS_REQUEST,
  STOREGROUPS_SUCCESS,
  STOREGROUPS_FAILURE,
  STOREGROUPID_REQUEST,
  STOREGROUPID_SUCCESS,
  STOREGROUPID_FAILURE,
  SAVESTOREGROUP_REQUEST,
  SAVESTOREGROUP_SUCCESS,
  SAVESTOREGROUP_FAILURE,
  DELETESTOREGROUP_REQUEST,
  DELETESTOREGROUP_SUCCESS,
  DELETESTOREGROUP_FAILURE,
} from './actionTypes';

export const userManagementActions = {
  requestUsers: (filter, page, pageSize) => (dispatch, getState) => {
    dispatch({
      type: USERS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/user/v1/GetUsers?filter=${filter}&page=${page}&pageSize=${pageSize}`,
        successAction: USERS_SUCCESS,
        failureAction: USERS_FAILURE,
      },
    });
  },
  requestUser: userId => (dispatch, getState) => {
    dispatch({
      type: USERID_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/user/v1/GetUser/${userId}`,
        successAction: USERID_SUCCESS,
        failureAction: USERID_FAILURE,
      },
    });
  },
  saveUser: userData => (dispatch, getState) => {
    dispatch({
      type: SAVEUSER_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/user/v1/Save',
        successAction: SAVEUSER_SUCCESS,
        failureAction: SAVEUSER_FAILURE,
        body: userData,
      },
    });
  },
  updateMyUser: userData => (dispatch, getState) => {
    dispatch({
      type: UPDATEMYUSER_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/user/v1/Update',
        successAction: UPDATEMYUSER_SUCCESS,
        failureAction: UPDATEMYUSER_FAILURE,
        body: userData,
      },
    });
  },
  deleteUser: userId => (dispatch, getState) => {
    dispatch({
      type: DELETEUSER_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: `/api/user/v1/Delete/${userId}`,
        successAction: DELETEUSER_SUCCESS,
        failureAction: DELETEUSER_FAILURE,
      },
    });
  },
  requestModuleGroups: () => (dispatch, getState) => {
    dispatch({
      type: MODULEGROUPS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/ModuleGroup/v1/GetModuleGroups',
        successAction: MODULEGROUPS_SUCCESS,
        failureAction: MODULEGROUPS_FAILURE,
      },
    });
  },
  requestModuleGroup: groupId => (dispatch, getState) => {
    dispatch({
      type: MODULEGROUPID_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/ModuleGroup/v1/GetModuleGroup/${groupId}`,
        successAction: MODULEGROUPID_SUCCESS,
        failureAction: MODULEGROUPID_FAILURE,
      },
    });
  },
  saveModuleGroup: groupData => (dispatch, getState) => {
    dispatch({
      type: SAVEMODULEGROUP_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/ModuleGroup/v1/Save',
        successAction: SAVEMODULEGROUP_SUCCESS,
        failureAction: SAVEMODULEGROUP_FAILURE,
        body: groupData,
      },
    });
  },
  deleteModuleGroup: groupId => (dispatch, getState) => {
    dispatch({
      type: DELETEMODULEGROUP_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: `/api/ModuleGroup/v1/Delete/${groupId}`,
        successAction: DELETEMODULEGROUP_SUCCESS,
        failureAction: DELETEMODULEGROUP_FAILURE,
      },
    });
  },
  requestStoreGroups: () => (dispatch, getState) => {
    dispatch({
      type: STOREGROUPS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/StoreGroup/v1/GetStoreGroups',
        successAction: STOREGROUPS_SUCCESS,
        failureAction: STOREGROUPS_FAILURE,
      },
    });
  },
  requestStoreGroup: groupId => (dispatch, getState) => {
    dispatch({
      type: STOREGROUPID_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/StoreGroup/v1/GetStoreGroup/${groupId}`,
        successAction: STOREGROUPID_SUCCESS,
        failureAction: STOREGROUPID_FAILURE,
      },
    });
  },
  saveStoreGroup: groupData => (dispatch, getState) => {
    dispatch({
      type: SAVESTOREGROUP_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/StoreGroup/v1/Save',
        successAction: SAVESTOREGROUP_SUCCESS,
        failureAction: SAVESTOREGROUP_FAILURE,
        body: groupData,
      },
    });
  },
  deleteStoreGroup: groupId => (dispatch, getState) => {
    dispatch({
      type: DELETESTOREGROUP_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: `/api/StoreGroup/v1/Delete/${groupId}`,
        successAction: DELETESTOREGROUP_SUCCESS,
        failureAction: DELETESTOREGROUP_FAILURE,
      },
    });
  },
};
