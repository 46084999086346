import {
  //Active messages
  CONNECTMESSAGING,
  DISCONNECTMESSAGING,
  FETCHCHATROOMS,
  HOSTCHATROOM,
  JOINCHATROOM,
  STARTCONVERSATION,
  SENDMESSAGE,
  TERMINATECONVERSATION,
  //Passive Messages
  RECEIVECHATROOMS,
  HOSTINGCHATROOM,
  USERJOINEDQUEUE,
  QUEUEUPDATED,
  CONVERSATIONSTARTED,
  RECEIVEMESSAGE,
  CONVERSATIONTERMINATED,
} from './actionTypes';

export const instantMessagingActions = {
  connect: () => (dispatch, getState) => {
    dispatch({
      type: CONNECTMESSAGING,
      payload: {},
    });
  },
  disconnect: () => (dispatch, getState) => {
    dispatch({
      type: DISCONNECTMESSAGING,
      payload: {},
    });
  },
  fetchChatRooms: () => (dispatch, getState) => {
    dispatch({
      type: FETCHCHATROOMS,
      payload: {},
    });
  },
  receiveChatRooms: data => (dispatch, getState) => {
    dispatch({
      type: RECEIVECHATROOMS,
      payload: data,
    });
  },
  hostChatRoom: chatRoomId => (dispatch, getState) => {
    dispatch({
      type: HOSTCHATROOM,
      payload: { chatRoomId },
    });
  },
  joinChatRoom: chatRoomId => (dispatch, getState) => {
    dispatch({
      type: JOINCHATROOM,
      payload: { chatRoomId },
    });
  },
  hostingChatRoom: data => (dispatch, getState) => {
    dispatch({
      type: HOSTINGCHATROOM,
      payload: data,
    });
  },
  userJoinedQueue: data => (dispatch, getState) => {
    dispatch({
      type: USERJOINEDQUEUE,
      payload: data,
    });
  },
  queueUpdated: data => (dispatch, getState) => {
    dispatch({
      type: QUEUEUPDATED,
      payload: data,
    });
  },
  startConversation: (chatRoomId, userQueueId) => (dispatch, getState) => {
    dispatch({
      type: STARTCONVERSATION,
      payload: { chatRoomId, userQueueId },
    });
  },
  conversationStarted: data => (dispatch, getState) => {
    dispatch({
      type: CONVERSATIONSTARTED,
      payload: data,
    });
  },
  sendMessage: (conversationId, message) => (dispatch, getState) => {
    dispatch({
      type: SENDMESSAGE,
      payload: { conversationId, message },
    });
  },
  receiveMessage: data => (dispatch, getState) => {
    dispatch({
      type: RECEIVEMESSAGE,
      payload: data,
    });
  },
  terminateConversation: (chatRoomId, conversationId) => (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: TERMINATECONVERSATION,
      payload: { chatRoomId, conversationId },
    });
  },
  conversationTerminated: conversationId => (dispatch, getState) => {
    dispatch({
      type: CONVERSATIONTERMINATED,
      payload: { conversationId },
    });
  },
};
