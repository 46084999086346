import {
  MYDOCUMENTS_REQUEST,
  MYDOCUMENTS_SUCCESS,
  MYDOCUMENTS_FAILURE,
  SAVEMYDOC_REQUEST,
  SAVEMYDOC_SUCCESS,
  SAVEMYDOC_FAILURE,
  DELETEMYDOC_REQUEST,
  DELETEMYDOC_SUCCESS,
  DELETEMYDOC_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const myDocumentsReducer = (
  state = initialState.myDocumentsState,
  action,
) => {
  switch (action.type) {
  case MYDOCUMENTS_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      errorMessage: '',
      myDocumentList: [],
    };
  }
  case MYDOCUMENTS_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      errorMessage: '',
      myDocumentList: action.payload,
    };
  }
  case MYDOCUMENTS_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      errorMessage: action.payload.message,
      myDocumentList: [],
    };
  }
  case SAVEMYDOC_REQUEST: {
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      errorMessage: '',
    };
  }
  case SAVEMYDOC_SUCCESS: {
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      errorMessage: '',
    };
  }
  case SAVEMYDOC_FAILURE: {
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      errorMessage: action.payload.message,
    };
  }
  case DELETEMYDOC_REQUEST: {
    return {
      ...state,
      isDeleting: true,
      isDeleted: false,
      errorMessage: '',
    };
  }
  case DELETEMYDOC_SUCCESS: {
    return {
      ...state,
      isDeleting: false,
      isDeleted: true,
      errorMessage: '',
    };
  }
  case DELETEMYDOC_FAILURE: {
    return {
      ...state,
      isDeleting: false,
      isDeleted: false,
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default myDocumentsReducer;
