import { TOGGLESIDEBAR_REQUEST } from '../actions/actionTypes';

export const sideBarReducer = (state = { open: false }, action) => {
  switch (action.type) {
  case TOGGLESIDEBAR_REQUEST: {
    return {
      sideBarOpen: action.payload.open,
    };
  }
  default:
    return state;
  }
};
export default sideBarReducer;
