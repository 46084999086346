import React, { useState } from 'react';

function StatusChangeModal(props) {
  const [comments, setComments] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const finalise = (status, comments) => {
    async function loadFormData(){
      const response = await fetch('/api/fcr/v1/finalise', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ 
          id: props.id,
          comments: comments,
          status: status,
        }),
      });

      if (response.status == 200){
        props.complete();
      }
      else {
        setResponseMessage('Something went wrong. If this issue persists, contact an administrator.');
      }
    }
    loadFormData();
  };

  return (
    <div className="modal-blocker">
      <div className="modal">
        <form>
          <div className="modal-menu">
            <button
              type="button"
              className="action"
              onClick={() => props.cancel()}
            >
              X
            </button>
          </div>
          <div className="modal-title">
            <h2>{`${props.status} this request?`}</h2>
          </div>
          <div className="modal-body">
            <label>
              Starbucks Comments:{' '}
              <textarea
                maxLength={500}
                type="text"
                onChange={(e) => setComments(e.target.value)}
                value={comments}
              />
            </label>
            <p>Are you sure you want to continue?</p>
            <p>This decision will be final.</p>

            {responseMessage}
          </div>
          <div className="modal-buttons">
            <button
              type="button"
              className="action"
              disabled={comments == ''}
              onClick={() => finalise(props.status, comments)}
            >
              {props.status}
            </button>
            <button
              type="button"
              className="action negative"
              onClick={() => props.cancel()}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default StatusChangeModal;
