import React from "react";

class SaveDocumentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentId: 0,
      categoryId: "",
      description: "",
      fileName: undefined,
      fileType: undefined,
      fileData: undefined,
      licenseeVisible: false,

      showValidation: false
    };

    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
  }

  componentDidMount() {}

  handleFileInputUpdate = e => {
    if (!e.target.files || e.target.files.length === 0) return;
    let currentComponent = this;
    let doc = {
      fileName: e.target.files[0].name,
      fileType: e.target.files[0].type
    };

    var reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }).then(data => {
      doc.fileData = data ? data.replace(/^data:(.*,)?/, "") : "";

      currentComponent.setState(doc);
    });
  };

  handleTextInputUpdate(e, fieldName) {
    this.setState({ [fieldName]: e.target.value });
  }

  handleCheckInputUpdate(e, fieldName) {
    this.setState({ [fieldName]: true && e.target.checked });
  }

  handleContinue = e => {
    const {
      documentId,
      categoryId,
      description,
      fileName,
      fileType,
      fileData,
      licenseeVisible
    } = this.state;
    const { isLicensee } = this.props;

    if (!categoryId || !description || !fileName) {
      this.setState({ showValidation: true });
    } else {
      this.props.continue({
        documentId,
        categoryId,
        description,
        fileName,
        fileType,
        fileData,
        licenseeVisible: licenseeVisible || isLicensee
      });
    }
  };

  render() {
    const {
      description,
      licenseeVisible,
      fileName,
      categoryId,
      showValidation
    } = this.state;
    const { isLicensee, cancel, documentCategories, inProgress } = this.props;
    const {
      handleTextInputUpdate,
      handleCheckInputUpdate,
      handleFileInputUpdate,
      handleContinue
    } = this;
    return (
      <div className="modal-blocker">
        <div className="modal">
          <form>
            <div className="modal-menu">
              <button type="button" className="action" onClick={() => cancel()}>
                X
              </button>
            </div>
            <div className="modal-title">
              <h2>Document</h2>
            </div>
            <div className="modal-body">
              <label>
                Document Category
                <select
                  autoFocus
                  type="text"
                  required
                  className={showValidation && !categoryId ? "invalid" : ""}
                  value={categoryId}
                  onChange={e => handleTextInputUpdate(e, "categoryId")}
                >
                  <option value="">Please Select</option>
                  {documentCategories.map(d => (
                    <option key={d.key} value={d.key}>
                      {d.value}
                    </option>
                  ))}
                </select>
              </label>

              <label className="file-upload-wrapper">
                <div className="icon icon-image-upload"></div>
                <p>
                  {!fileName
                    ? "Tap to select a file to upload to this application."
                    : fileName}
                </p>
                <input
                  autoFocus
                  required
                  name="file"
                  id="file"
                  className="inputfile"
                  type="file"
                  onChange={e => handleFileInputUpdate(e)}
                />
              </label>
              <label>
                Description
                <textarea
                  autoFocus
                  type="text"
                  required
                  className={showValidation && !description ? "invalid" : ""}
                  value={description}
                  onChange={e => handleTextInputUpdate(e, "description")}
                />
              </label>
            </div>
            {!isLicensee && (
              <div className="modal-body">
                <label>
                  Licensee Visible
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      onChange={e =>
                        handleCheckInputUpdate(e, "licenseeVisible")
                      }
                      checked={licenseeVisible}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
              </div>
            )}
            <div className="modal-buttons">
              <button
                type="button"
                className="action"
                disabled={inProgress}
                onClick={() => handleContinue()}
              >
                Save
              </button>
              <button
                type="button"
                className="action negative"
                disabled={inProgress}
                onClick={() => cancel()}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SaveDocumentModal;
