/* eslint-disable react/jsx-key */
import React from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  BarChart,
  Legend,
  Brush,
  ReferenceLine,
} from 'recharts';

function CustomStackedBarChart({ data, bars, width, chartTitle, description, maxYValue }) {
  const formatTooltip = (value, name) => {
    return [`${name}: ${value}%`];
  };

  const formatYAxis = (tickItem) => {
    return `${tickItem}%`;
  };

  return (
    <><p className="text-center">{chartTitle}</p>
      <ResponsiveContainer width={width}
        height={450}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" 
            interval={0} 
            tick={{ fontSize: 10 }} />
          {maxYValue == undefined && <YAxis tickFormatter={formatYAxis} />}
          {maxYValue != undefined && <YAxis tickFormatter={formatYAxis}
            domain={[0, maxYValue]}
            allowDataOverflow />}
          <Tooltip formatter={formatTooltip} />
          <Legend />
          <ReferenceLine y={0} 
            stroke='#000'/>
          <Brush dataKey="name" 
            height={20} 
            stroke="green" />  
          {bars && bars.map((bar) => (
            <Bar
              dataKey={bar.dataKey}
              stackId="a" 
              fill={bar.strokeColor}
            />
          ))}
        </BarChart>
      </ResponsiveContainer> 

      <br/>
      <h5 className="explanation-style">
        {' '}
        {description}
      </h5></>
  );
}
export default CustomStackedBarChart;