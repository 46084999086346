import React from 'react';

class PriorityKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: true,
    };
  }

  render() {
    const {
      isExpanded,
    } = this.state;

    const { canViewPriorityAreas } = this.props;

    if (!canViewPriorityAreas) {
      return null;
    }

    return (

      <div className="equity-wrapper-priority">
        {/*<div className="key-header-priority">
          <div>
            <button
              type="button"
              className={isExpanded ? 'close-button' : 'open-button'}
              onClick={() => this.setState(
                { isExpanded: !isExpanded },
              )}
            >
              +
            </button>
          </div>
              </div>*/}
        <div className={`key ${isExpanded ? '' : 'hideKey'}`}>
          <h2>
           Priority Area Key
          </h2>
          <div className="content-wrapper">
            <div className="key-content">
              <div className="key-entry">
                <div className="color-block twenty-three">
                </div>
                <div className="key-text">23.5</div>
              </div>
              <div className="key-entry">
                <div className="color-block oco">
                </div>
                <div className="key-text">OCO</div>
              </div>
              <div className="key-entry">
                <div className="color-block cobra">
                </div>
                <div className="key-text">Cobra</div>
              </div>
              <div className="key-entry">
                <div className="color-block mb">
                </div>
                <div className="key-text">MB</div>
              </div>
            </div>

            <div className="key-content middle">
              <div className="key-entry">
                <div className="color-block sch">
                </div>
                <div className="key-text">SCH</div>
              </div>
              <div className="key-entry">
                <div className="color-block kbev">
                </div>
                <div className="key-text">K Bev</div>
              </div>
              <div className="key-entry">
                <div className="color-block qw">
                </div>
                <div className="key-text">QW</div>
              </div>
              <div className="key-entry">
                <div className="color-block cf">
                </div>
                <div className="key-text">CF</div>
              </div>
              <div className="key-entry">
                <div className="color-block sbux">
                </div>
                <div className="key-text">SBUX UK</div>
              </div>
            </div>

            {/*<div className="key-content last">
              <div className="key-entry">
                <div className="color-block eq">
                </div>
                <div className="key-text">EQ</div>
              </div>
              <div className="key-entry">
                <div className="color-block eg">
                </div>
                <div className="key-text">EG</div>
              </div>
              <div className="key-entry">
                <div className="color-block treats">
                </div>
                <div className="key-text">Treats</div>
              </div>
              <div className="key-entry">
                <div className="color-block p2">
                </div>
                <div className="key-text">P2</div>
              </div>
                            <div className="key-entry">
                <div className="color-block bs">
                </div>
                <div className="key-text">B&amp;S</div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    
    );
  }
}

export default PriorityKey;
