import {
  GETCUSTOMERREQUIREMENTSODS_REQUEST,
  GETCUSTOMERREQUIREMENTSODS_SUCCESS,
  GETCUSTOMERREQUIREMENTSODS_FAILURE,
  SAVECUSTOMERREQUIREMENTSODS_REQUEST,
  SAVECUSTOMERREQUIREMENTSODS_SUCCESS,
  SAVECUSTOMERREQUIREMENTSODS_FAILURE,
  DOWNLOADDOCUMENTODS_REQUEST,
  DOWNLOADDOCUMENTODS_SUCCESS,
  DOWNLOADDOCUMENTODS_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const customerRequirementsOdsReducer = (
  state = initialState.customerRequirementsOdsState,
  action,
) => {
  switch (action.type) {
  case GETCUSTOMERREQUIREMENTSODS_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      customerRequirementsOds: {},
      errorMessage: '',
    };
  }
  case GETCUSTOMERREQUIREMENTSODS_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      customerRequirementsOds: action.payload,
      errorMessage: '',
    };
  }
  case GETCUSTOMERREQUIREMENTSODS_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      customerRequirementsOds: {},
      errorMessage: action.payload.message,
    };
  }
  case SAVECUSTOMERREQUIREMENTSODS_REQUEST:
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      errorMessage: '',
    };
  case SAVECUSTOMERREQUIREMENTSODS_SUCCESS:
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      errorMessage: '',
    };
  case SAVECUSTOMERREQUIREMENTSODS_FAILURE:
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      errorMessage: action.payload.message,
    };
  case DOWNLOADDOCUMENTODS_REQUEST: {
    return {
      ...state,
      isFetching: true,
      documentLoaded: false,
      errorMessage: '',
    };
  }
  case DOWNLOADDOCUMENTODS_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      documentLoaded: true,
      errorMessage: '',
    };
  }
  case DOWNLOADDOCUMENTODS_FAILURE: {
    return {
      ...state,
      isFetching: false,
      documentLoaded: false,
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default customerRequirementsOdsReducer;
