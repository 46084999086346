import {
  LSPOS_ITEMNUMBER_REQUEST,
  LSPOS_ITEMNUMBER_SUCCESS,
  LSPOS_ITEMNUMBER_FAILURE,
  LSPOS_ITEMNUMBER_DETAIL_REQUEST,
  LSPOS_ITEMNUMBER_DETAIL_SUCCESS,
  LSPOS_ITEMNUMBER_DETAIL_FAILURE,
  LSPOS_ITEMNUMBER_SAVE_REQUEST,
  LSPOS_ITEMNUMBER_SAVE_SUCCESS,
  LSPOS_ITEMNUMBER_SAVE_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const PosItemNumberRequestReducer = (
  state = initialState.posItemNumbeRequestState,
  action = {},
) => {
  switch (action.type) {
  case  LSPOS_ITEMNUMBER_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
      itemList: [],
      errorMessage: '',
    };
  }
  case LSPOS_ITEMNUMBER_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      itemList: action.payload.data,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      totalRecords: action.payload.totalRecords,
      errorMessage: '',
    };
  }
  case  LSPOS_ITEMNUMBER_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
      itemList: [],
      errorMessage: action.payload.message,
    };
  }
  case LSPOS_ITEMNUMBER_DETAIL_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      itemDetail: null,
      itemType: [],
      productPlatform:[],
      productSubCategory: [],
      temperature: [],
      uom: [],
      statuses: [],
      storeGroups: [],
      errorMessage: '',
    };
  }
  case LSPOS_ITEMNUMBER_DETAIL_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      itemDetail: action.payload.itemDetail,
      itemType: action.payload.itemType,
      productPlatform: action.payload.productPlatform,
      productSubCategory: action.payload.productSubCategory,
      temperature: action.payload.temperature,
      statuses: action.payload.statuses,
      uom: action.payload.uom,
      storeGroups: action.payload.storeGroups,
      errorMessage: '',
    };
  }
  case LSPOS_ITEMNUMBER_DETAIL_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      itemDetail: null,
      itemType: [],
      productPlatform:[],
      productSubCategory: [],
      temperature: [],
      uom: [],
      statuses: [],
      storeGroups: [],
      errorMessage: action.payload.message,
    };
  }
  case LSPOS_ITEMNUMBER_SAVE_REQUEST: {
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      errorMessage: '',
    };
  }
  case LSPOS_ITEMNUMBER_SAVE_SUCCESS: {
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      errorMessage: '',
    };
  }
  case LSPOS_ITEMNUMBER_SAVE_FAILURE: {
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};

export default PosItemNumberRequestReducer;
