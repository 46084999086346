import {
  SITEID_OPEN_REQUEST,
  SITEID_OPEN_SUCCESS,
  SITEID_OPEN_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const SiteIDOpenStoreReducer = (
  state = initialState.siteIDOpenStoreState,
  action,
) => {
  switch (action.type) {
  case SITEID_OPEN_REQUEST: {
    return {
      ...state,
      isLoaded: false,
      openStores: [],
      errorMessage: '',
    };
  }
  case SITEID_OPEN_SUCCESS: {
    return {
      ...state,
      isLoaded: true,
      data: action.payload.applications,
      companies: action.payload.companies,
      counties: action.payload.counties,
      statuses: action.payload.statuses,
      errorMessage: '',
    };
  }
  case SITEID_OPEN_FAILURE: {
    return {
      ...state,
      isLoaded: false,
      openStores: [],
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default SiteIDOpenStoreReducer;
