import React, { useState } from 'react';
import Pagination from '../../shared/Pagination';

function CsrResultsTable(props){
  const [displayMode, setDisplayMode] = useState(2);
  const startIndex = (props.page - 1) * props.pageSize;
  const endIndex = startIndex + props.pageSize;
  const [sortedColumn, setSortedColumn] = useState({ column: 'orderDate', order: 'desc' });


  const handleSort = (column) => {
    if (sortedColumn.column === column && sortedColumn.order === 'asc') {
      setSortedColumn({ column: column, order: 'desc' });
    } else {
      setSortedColumn({ column: column, order: 'asc' });
    }
  };

  return <div className="csr-container table-container">
    <div className="table-header">
      <div className='display-search-wrapper'>
        <div className="display-options">
              Table Size
          <button
            type="button"
            className="icon-display-mode-1"
            disabled={displayMode === 1}
            onClick={() => setDisplayMode(1)}></button>
          <button
            type="button"
            className="icon-display-mode-2"
            disabled={displayMode === 2}
            onClick={() => setDisplayMode(2)}></button>
          <button
            type="button"
            className="icon-display-mode-3"
            disabled={displayMode === 3}
            onClick={() => setDisplayMode(3)}></button>
        </div>
      </div>
      <h2>
        Results
      </h2>
      <div className="pagination-controls">
        <Pagination
          rowCount={props.orders && props.orders.length}
          page={props.orders && props.page}
          pageSize={props.orders && props.pageSize}
          doPaging={props.orders && props.doPaging}
        />
      </div>
    </div>
    <div className='table-body'>   
      <table className={`table-display-mode-${displayMode}`}>
        <thead>
          <tr>
            <th onClick={() => handleSort('orderDate')}>
              <div className='flexed-header'>
              Order Date 
                {sortedColumn.column == 'orderDate' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}
              </div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('org')}>
              <div className='flexed-header'>
              Sources  
                {sortedColumn.column == 'org' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('shipmentNo')}>
              <div className='flexed-header'>
              Shipment #
                {sortedColumn.column == 'shipmentNo' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}
              </div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('soNo')}>
              <div className='flexed-header'>
              SO #  
                {sortedColumn.column == 'soNo' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('poNo')}>
              <div className='flexed-header'>
            PO # / DID #  
                {sortedColumn.column == 'poNo' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('supplier')}>
              <div className='flexed-header'>
              Supplier  
                {sortedColumn.column == 'supplier' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('portOfLoading')}>
              <div className='flexed-header'>
              Port of Loading 
                {sortedColumn.column == 'portOfLoading' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('region')}>
              <div className='flexed-header'>
              Region
                {sortedColumn.column == 'region' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('portOfDischarge')}> 
              <div className='flexed-header'>
              Port of Discharge 
                {sortedColumn.column == 'portOfDischarge' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('placeOfDelivery')}>
              <div className='flexed-header'>
              Place of delivery  
                {sortedColumn.column == 'placeOfDelivery' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('shipMode')}>
              <div className='flexed-header'>
              Ship Mode
                {sortedColumn.column == 'shipMode' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('rts')}>
              <div className='flexed-header'>
              RTS
                {sortedColumn.column == 'rts' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('etd')}>
              <div className='flexed-header'>
              ETD  
                {sortedColumn.column == 'etd' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('cob')}>
              <div className='flexed-header'>
              COB  
                {sortedColumn.column == 'cob' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('eta')}>
              <div className='flexed-header'>
              ETA  
                {sortedColumn.column == 'eta' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('promiseDate')}>
              <div className='flexed-header'>
              Promise Date  
                {sortedColumn.column == 'promiseDate' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('category')}>
              <div className='flexed-header'>
              Category  
                {sortedColumn.column == 'category' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('promotion')}>
              <div className='flexed-header'>
              Promotion 
                {sortedColumn.column == 'promotion' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('remarks')}>
              <div className='flexed-header'>
              Remarks  
                {sortedColumn.column == 'remarks' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('formRecieveDate')}>
              <div className='flexed-header'>
              Form D / E Receive Date 
                {sortedColumn.column == 'formRecieveDate' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('eventCode')}>Event Code  
              <div className='flexed-header'>
                {sortedColumn.column == 'eventCode' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('ombContainerBooked')}>
              <div className='flexed-header'>
              OMB Container Booked
                {sortedColumn.column == 'ombContainerBooked' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>
            <th onClick={() => handleSort('temperatureControlCondition')}>
              <div className='flexed-header'>
              Temperature Control 
                {sortedColumn.column == 'temperatureControlCondition' &&
              <div className={`expand ${sortedColumn.order == 'asc' ? 'asc' : 'desc'}`}>{'>'}</div>
                }
              </div>
            </th>            
          </tr>
        </thead>
        <tbody>
          {props.orders && props.orders && props.orders.length > 0 ?
            props.orders.sort((a, b) => {
              let valueA = a[sortedColumn.column];
              let valueB = b[sortedColumn.column];

              if (['orderDate', 'rts', 'etd', 'cob', 'eta', 'promiseDate', 'formRecieveDate'].includes(sortedColumn.column)) {
                valueA = new Date(valueA);
                valueB = new Date(valueB);
              }

              if (sortedColumn.order === 'asc') {
                return (valueA || '') > (valueB || '') ? 1 : -1;
              } else if (sortedColumn.order === 'desc') {
                return (valueA || '') < (valueB || '') ? 1 : -1;
              } else {
                return 0;
              }
            }).slice(startIndex, endIndex).map(o => 
              <tr key={o.orderId}>
                <td>{o.orderDate ? new Date(o.orderDate).toLocaleDateString() : null}</td>                            
                <td>{o.sources}</td>
                <td>{o.shipmentNo}</td>
                <td>{o.soNo}</td>
                <td>{o.poNo}</td>
                <td>{o.supplier}</td>
                <td>{o.portOfLoading}</td>
                <td>{o.region}</td>
                <td>{o.portOfDischarge}</td>
                <td>{o.placeOfDelivery}</td>
                <td>{o.shipMode}</td>
                <td>{o.rts ? new Date(o.rts).toLocaleDateString() : null}</td>
                <td>{o.etd ? new Date(o.etd).toLocaleDateString(): null}</td>
                <td>{o.cob ? new Date(o.cob).toLocaleDateString(): null}</td>
                <td>{o.eta ? new Date(o.eta).toLocaleDateString(): null}</td>
                <td>{o.promiseDate ? new Date(o.promiseDate).toLocaleDateString() : null}</td>
                <td>{o.category}</td>
                <td>{o.promotion}</td>
                <td>{o.remarks}</td>
                <td>{o.formRecieveDate ? new Date(o.formRecieveDate).toLocaleDateString() : null}</td>
                <td>{o.eventCode}</td>
                <td>{o.ombContainerBooked}</td>
                <td>{o.temperatureControlCondition}</td>
              </tr>,
            ) :      
            <tr><td colSpan={25}
              style={{ textAlign:'center' }}>There are currently no records found.</td></tr> }                
        </tbody>
      </table>
    </div>
  </div>;
}

export default CsrResultsTable;