import React from 'react';

const MessageModal = props => {
  return (
    <div className="modal-blocker">
      <div className="modal">
        <form>
          <div className="modal-menu">
            <button
              type="button"
              className="action"
              onClick={() => props.cancel()}>
              X
            </button>
          </div>
          <div className="modal-title">
            <h2>{props.title}</h2>
          </div>
          <div className="modal-body">
            <p>{props.message}</p>
          </div>
          <div className="modal-buttons">
            <button
              type="button"
              className="action"
              onClick={() => props.cancel()}>
              Ok
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MessageModal;
