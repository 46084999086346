import React from 'react';
import { connect } from 'react-redux';
import { customerRequirementsActions } from '../../../actions/customerRequirementsActions';
import StoreSelector from '../../shared/storeSelector';
import BookingTabOds from './formTabsOds/BookingTabOds';
import CustomerTabOds from './formTabsOds/CustomerTabOds';
import DocumentTabOds from './formTabsOds/DocumentTabOds';
import LabelTabOds from './formTabsOds/LabelTabOds';
import LoadingTabOds from './formTabsOds/LoadingTabOds';
import OdsTabOds from './formTabsOds/OdsTabOds';
import PalletTabOds from './formTabsOds/PalletTabOds';
import ShelfTabOds from './formTabsOds/ShelfTabOds';

class CustomerRequirementsOds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storeNumberInternal: '',
      customerName: '',
      marketName: '',
      currentTab: 'customer',
      newMode: '',
      newPalletType: '',
      primaryMarketContactName: '',
      primaryMarketContactEmail: '',
      primaryMarketContactTelephone: '',
      mode: 0,
      maxPallet: '',
      transportCarrierHeight: '',
      maxWeightPerTransportCarrier: '',
      standardFtlOrFclCarrierType: 0,
      tempControlled: false,
      foilType: 0,
      securityTape: false,
      oneItemPerPallet: false,
      oneItemPerLayer: false,
      palletType: 0,
      heatTreated: false,
      bookingRequirements: '',
      starbucksPackingList: false,
      starbucksCommercialInvoice: false,
      bol: false,
      loadPlan: false,
      warehousePackingList: false,
      marketSpecificRequirementsDocument: false,
      marketSpecificRequirementsLabel: false,
      marketSpecificRequirementsShelf: false,
      caseLabelling: false,
      innerPackLabelling: false,
      productLabelling: false,
      doubleStackingCoffeeItems: false,
      fullTruckVehicleSealing: false,
      approvedExceptionDoubleStacking: false,
      approverName: '',
      noShelfExtensionAllowed: false,
      strictDelivery: false,
      frequency: 0,
      orderSubmitDay: 0,
      orderBookToReadyToShipLeadTime: 0,
      deliveryTransitLeadTime: 0,
      otherRequirementsDocument: '',
      otherRequirementsLabel: '',
      otherRequirementsOds: '',
      otherRequirementsShelf: '',
      standardFtlOrFclCarrierTypes: [],
      foilTypes: [],
      palletTypes: [],
      frequencies: [],
      orderSubmitDays: [],
      modes: [],
      existingDocument: false,
      existingImage: false,
      documentDescription: '',
      imageDescription: '',
      documentUpdated: false,
      imageUpdated: false,
      documentData:'',
      imageData: '',
      documentFileName: '',
      imageFileName: '',
      documentFileType: '',
      imageFileType: '',
    };

    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);
    this.handleStoreChange = this.handleStoreChange.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.updateState = this.updateState.bind(this);
    this.handleDocumentFileInputUpdate = this.handleDocumentFileInputUpdate.bind(this);
  }

  componentDidMount() {
    const { stores } = this.props;
    let { storeNumberInternal } = this.state;
    if (!storeNumberInternal) {
      if (stores && stores.length > 0) {
        storeNumberInternal = stores[0].storeNumber;
      }
    }

    this.setState({ storeNumberInternal });

    if (storeNumberInternal) {
      this.props.requestCustomerRequirementsOds(storeNumberInternal);
    }
  }

  updateState(){
    const { customerRequirementsOds } = this.props;
    this.setState({ 
      customerNumber: customerRequirementsOds?.customerNumber,
      customerName: customerRequirementsOds?.customerName,
      marketName: customerRequirementsOds?.marketName,
      primaryMarketContactName: customerRequirementsOds?.primaryMarketContactName,
      primaryMarketContactEmail: customerRequirementsOds?.primaryMarketContactEmail,
      primaryMarketContactTelephone: customerRequirementsOds?.primaryMarketContactTelephone,
      mode: customerRequirementsOds?.modeId,
      maxPallet: customerRequirementsOds?.maxPallet,
      transportCarrierHeight: customerRequirementsOds?.transportCarrierHeight,
      maxWeightPerTransportCarrier: customerRequirementsOds?.maxWeightPerTransportCarrier,
      standardFtlOrFclCarrierType: customerRequirementsOds?.standardFtlOrFclCarrierTypeId,
      tempControlled: customerRequirementsOds?.tempControlled,
      foilType: customerRequirementsOds?.foilTypeId,
      securityTape: customerRequirementsOds?.securityTape,
      oneItemPerPallet: customerRequirementsOds?.oneItemPerPallet,
      oneItemPerLayer: customerRequirementsOds?.oneItemPerLayer,
      palletType: customerRequirementsOds?.palletTypeId,
      newPalletType: customerRequirementsOds?.newPalletType,
      newMode: customerRequirementsOds?.newMode,
      heatTreated: customerRequirementsOds?.heatTreated,
      bookingRequirements: customerRequirementsOds?.bookingRequirements,
      starbucksPackingList: customerRequirementsOds?.starbucksPackingList,
      starbucksCommercialInvoice: customerRequirementsOds?.starbucksCommercialInvoice,
      bol: customerRequirementsOds?.bol,
      loadPlan: customerRequirementsOds?.loadPlan,
      warehousePackingList: customerRequirementsOds?.warehousePackingList,
      marketSpecificRequirementsDocument: customerRequirementsOds?.marketSpecificRequirementsDocument,
      marketSpecificRequirementsLabel: customerRequirementsOds?.marketSpecificRequirementsLabel,
      marketSpecificRequirementsShelf: customerRequirementsOds?.marketSpecificRequirementsShelf,
      caseLabelling: customerRequirementsOds?.caseLabelling,
      innerPackLabelling: customerRequirementsOds?.innerPackLabelling,
      productLabelling: customerRequirementsOds?.productLabelling,
      doubleStackingCoffeeItems: customerRequirementsOds?.doubleStackingCoffeeItems,
      fullTruckVehicleSealing: customerRequirementsOds?.fullTruckVehicleSealing,
      approvedExceptionDoubleStacking: customerRequirementsOds?.approvedExceptionDoubleStacking,
      approverName: customerRequirementsOds?.approverName,
      noShelfExtensionAllowed: customerRequirementsOds?.noShelfExtensionAllowed,
      strictDelivery: customerRequirementsOds?.strictDelivery,
      frequency: customerRequirementsOds?.frequencyId,
      orderSubmitDay: customerRequirementsOds?.orderSubmitDayId,
      orderBookToReadyToShipLeadTime: customerRequirementsOds?.orderBookToReadyToShipLeadTime,
      deliveryTransitLeadTime: customerRequirementsOds?.deliveryTransitLeadTime,
      otherRequirementsDocument: customerRequirementsOds?.otherRequirementsDocument,
      otherRequirementsLabel: customerRequirementsOds?.otherRequirementsLabel,
      otherRequirementsOds: customerRequirementsOds?.otherRequirementsOds,
      otherRequirementsShelf: customerRequirementsOds?.otherRequirementsShelf,
      standardFtlOrFclCarrierTypes: customerRequirementsOds?.standardFtlOrFclCarrierTypes,
      foilTypes: customerRequirementsOds?.foilTypes,
      palletTypes: customerRequirementsOds?.palletTypes,
      frequencies: customerRequirementsOds?.frequencies,
      orderSubmitDays: customerRequirementsOds?.orderSubmitDays,
      modes: customerRequirementsOds?.modeTypes,
      documentDescription: customerRequirementsOds?.documentDescription,
      imageDescription: customerRequirementsOds?.imageDescription,
      existingDocument : customerRequirementsOds?.existingDocument,
      existingImage : customerRequirementsOds?.existingImage,
      imageFileName: customerRequirementsOds?.imageFileName,
      documentFileName: customerRequirementsOds?.documentFileName,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLoaded && !prevProps.isLoaded) {
      this.updateState();
    }
  }

  saveCustomerRequirementsForm() {
    const { 
      storeNumberInternal,
      customerName,
      marketName,
      primaryMarketContactName,
      primaryMarketContactEmail,
      primaryMarketContactTelephone,
      mode,
      maxPallet,
      transportCarrierHeight,
      maxWeightPerTransportCarrier,
      standardFtlOrFclCarrierType,
      tempControlled,
      foilType,
      securityTape,
      oneItemPerPallet,
      oneItemPerLayer,
      palletType,
      newPalletType,
      newMode,
      heatTreated,
      bookingRequirements,
      starbucksPackingList,
      starbucksCommercialInvoice,
      bol,
      loadPlan,
      warehousePackingList,
      marketSpecificRequirementsDocument,
      marketSpecificRequirementsLabel,
      marketSpecificRequirementsShelf,
      caseLabelling,
      innerPackLabelling,
      productLabelling,
      doubleStackingCoffeeItems,
      fullTruckVehicleSealing,
      approvedExceptionDoubleStacking,
      approverName,
      noShelfExtensionAllowed,
      strictDelivery,
      frequency,
      orderSubmitDay,
      orderBookToReadyToShipLeadTime,
      deliveryTransitLeadTime,
      otherRequirementsDocument,
      otherRequirementsLabel,
      otherRequirementsOds,
      otherRequirementsShelf,
      documentDescription,
      imageDescription,
      documentUpdated,
      imageUpdated,
      documentData,
      imageData,
      documentFileName,
      imageFileName,
      documentFileType,
      imageFileType,
    } = this.state;

    this.props.saveCustomerRequirementsOdsForm({ 
      storeNumberInternal,
      customerName,
      marketName,
      primaryMarketContactName,
      primaryMarketContactEmail,
      primaryMarketContactTelephone,
      mode,
      maxPallet,
      transportCarrierHeight,
      maxWeightPerTransportCarrier,
      standardFtlOrFclCarrierType,
      tempControlled,
      foilType,
      securityTape,
      oneItemPerPallet,
      oneItemPerLayer,
      palletType,
      newPalletType,
      newMode,
      heatTreated,
      bookingRequirements,
      starbucksPackingList,
      starbucksCommercialInvoice,
      bol,
      loadPlan,
      warehousePackingList,
      marketSpecificRequirementsDocument,
      marketSpecificRequirementsLabel,
      marketSpecificRequirementsShelf,
      caseLabelling,
      innerPackLabelling,
      productLabelling,
      doubleStackingCoffeeItems,
      fullTruckVehicleSealing,
      approvedExceptionDoubleStacking,
      approverName,
      noShelfExtensionAllowed,
      strictDelivery,
      frequency,
      orderSubmitDay,
      orderBookToReadyToShipLeadTime,
      deliveryTransitLeadTime,
      otherRequirementsDocument,
      otherRequirementsLabel,
      otherRequirementsOds,
      otherRequirementsShelf,
      documentDescription,
      imageDescription,
      documentUpdated,
      imageUpdated,
      documentData,
      imageData,
      documentFileName,
      imageFileName,
      documentFileType,
      imageFileType,
    });
  }

  handleTextInputUpdate(e, fieldName) {
    this.setState({ 
      [fieldName]: e.target.value, 
    });
  }

  handleCheckInputUpdate(e, fieldName) {
    this.setState({ 
      [fieldName]: e.target.checked, 
    });
  }

  handleChangeTab(tab) {
    this.setState({ currentTab: tab });
  }

  handleStoreChange(storeNumber) {
    this.setState({ storeNumberInternal: storeNumber });

    this.props.requestCustomerRequirementsOds(storeNumber); 
    
    this.updateState();
  }

  handleDocumentFileInputUpdate(e, type) {
    if (!e.target.files || e.target.files.length === 0) return;
    let doc = {
      fileName: e.target.files[0].name,
      fileType: e.target.files[0].type,
    };

    var reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }).then(data => {
      doc.fileData = data ? data.replace(/^data:(.*,)?/, '') : '';

      if (type === 'doc'){
        this.setState({ 
          documentData: doc.fileData, 
          documentFileName: doc.fileName,
          documentFileType: doc.fileType,
          documentUpdated: true,
        });
      }
      else{
        this.setState({ 
          imageData: doc.fileData,
          imageFileName: doc.fileName,
          imageFileType: doc.fileType,
          imageUpdated: true,
        });
      }

    });
  }

  render() {
    const { stores, isLoaded, customerRequirementsOds, isSaving, isSaved } = this.props;
    const { storeNumberInternal, currentTab } = this.state;

    const isCustomerTab = currentTab === 'customer';
    const isPalletTab = currentTab === 'pallet';
    const isBookingTab = currentTab === 'booking';
    const isDocumentTab = currentTab === 'document';
    const isLabelTab = currentTab === 'label';
    const isLoadingTab = currentTab === 'loading';
    const isShelfTab = currentTab === 'shelf';
    const isOdsTab = currentTab === 'ods';

    const { handleStoreChange } = this;
    return (
      <div className="edit-feedback-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-form"></div>
              <h2>Customer Requirements + ODS</h2>
            </div>
            <StoreSelector
              onStoreSelected={handleStoreChange}
              stores={stores}
              storeNumber={
                storeNumberInternal ? storeNumberInternal.toString() : ''
              }
            />
          </div>
        </div>
        {isLoaded && (
          <div className="tab-container">
            <div
              className={`tab-button ${
                isCustomerTab ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('customer')}>
              Customer
              <br />
              Information
            </div>
            <div
              className={`tab-button ${
                isPalletTab ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('pallet')}>
              Pallet <br />Requirements
            </div>
            <div
              className={`tab-button ${
                isBookingTab ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('booking')}>
              Booking
              <br />
              Requirements
            </div>
            <div
              className={`tab-button ${
                isDocumentTab ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('document')}>
              Document
              <br />
              Requirements
            </div>
            <div
              className={`tab-button ${
                isLabelTab ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('label')}>
              Label
              <br />
              Requirements
            </div>
            <div
              className={`tab-button ${
                isLoadingTab ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('loading')}>
              Loading
              <br />
              Requirements
            </div>  
            <div
              className={`tab-button ${
                isShelfTab ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('shelf')}>
              Shelf Life
              <br />
              Requirements
            </div>  
            <div
              className={`tab-button ${
                isOdsTab ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('ods')}>
              ODS
            </div>  
          </div>        
        )}
        {isLoaded && customerRequirementsOds && (
          <form className="form-container"
            noValidate
            onSubmit={e => false}>
            {isCustomerTab && (
              <div className="form-header">
                <h3>Customer Information</h3>
              </div>
            )}
            {isPalletTab && (
              <div className="form-header">
                <h3>Pallet Requirements</h3>
              </div>
            )}
            {isBookingTab && (
              <div className="form-header">
                <h3>Booking Requirements</h3>
              </div>
            )}
            {isDocumentTab && (
              <div className="form-header">
                <h3>Document Requirements</h3>
              </div>
            )}
            {isLabelTab && (
              <div className="form-header">
                <h3>Label Requirements</h3>
              </div>
            )}
            {isLoadingTab && (
              <div className="form-header">
                <h3>Loading Requirements</h3>
              </div>
            )}
            {isShelfTab && (
              <div className="form-header">
                <h3>Shelf Life Requirements</h3>
              </div>
            )}
            {isOdsTab && (
              <div className="form-header">
                <h3>ODS</h3>
              </div>
            )} 
            <div className="form-body">      
              {isCustomerTab && (
                <CustomerTabOds
                  customerRequirementsDetail={this.state}
                  handleTextInputUpdate={this.handleTextInputUpdate}
                />           
              )}
              {isPalletTab && (
                <PalletTabOds
                  customerRequirementsDetail={this.state}
                  handleTextInputUpdate={this.handleTextInputUpdate}
                  handleCheckInputUpdate={this.handleCheckInputUpdate}
                />  
              )}  
              {isBookingTab && (
                <BookingTabOds
                  customerRequirementsDetail={this.state}
                  handleTextInputUpdate={this.handleTextInputUpdate}
                />
              )}
              {isDocumentTab && (
                <DocumentTabOds
                  customerRequirementsDetail={this.state}
                  handleTextInputUpdate={this.handleTextInputUpdate}
                  handleCheckInputUpdate={this.handleCheckInputUpdate}
                /> 
              )}
              {isLabelTab && (
                <LabelTabOds
                  customerRequirementsDetail={this.state}
                  handleTextInputUpdate={this.handleTextInputUpdate}
                  handleCheckInputUpdate={this.handleCheckInputUpdate}
                  handleDocumentFileInputUpdate={this.handleDocumentFileInputUpdate}
                /> 
              )}
              {isLoadingTab && (
                <LoadingTabOds
                  customerRequirementsDetail={this.state}
                  handleTextInputUpdate={this.handleTextInputUpdate}
                  handleCheckInputUpdate={this.handleCheckInputUpdate}
                /> 
              )}
              {isShelfTab && (
                <ShelfTabOds
                  customerRequirementsDetail={this.state}
                  handleTextInputUpdate={this.handleTextInputUpdate}
                  handleCheckInputUpdate={this.handleCheckInputUpdate}
                /> 
              )}
              {isOdsTab && (
                <OdsTabOds
                  customerRequirementsDetail={this.state}
                  handleTextInputUpdate={this.handleTextInputUpdate}
                />   
              )}
            </div>
            <div className="form-buttons">
              <button
                type="button"
                className="action"
                onClick={() => this.saveCustomerRequirementsForm()}>
                {isSaving? 'Saving...' : isSaved? 'Saved' : 'Save'}
              </button>
              {isSaved &&
            <div className='success-message'>Form successfully saved.</div>
              }
              {
                this.props.errorMessage ? <div className='warning-message'>{this.props.errorMessage}</div> : null
              }
            </div>
          </form>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    stores: state.auth.stores,
    isFetching: state.customerRequirementsOds.isFetching,
    isLoaded: state.customerRequirementsOds.isLoaded,
    isSaving: state.customerRequirementsOds.isSaving,
    isSaved: state.customerRequirementsOds.isSaved,
    customerRequirementsOds: state.customerRequirementsOds.customerRequirementsOds
      ? state.customerRequirementsOds.customerRequirementsOds
      : {},
    errorMessage: state.customerRequirementsOds.errorMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestCustomerRequirementsOds: customerNumber =>
      dispatch(
        customerRequirementsActions.requestCustomerRequirementsOds(customerNumber),
      ),
    saveCustomerRequirementsOdsForm: data => dispatch(customerRequirementsActions.saveCustomerRequirementsOdsForm(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerRequirementsOds);
