import {
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_FAILURE,
  SITEID_NOTIFICATIONS_REQUEST,
  SITEID_NOTIFICATIONS_SUCCESS,
  SITEID_NOTIFICATIONS_FAILURE,
  ALLACKSITEIDNOTIFICATION_REQUEST,
  ALLACKSITEIDNOTIFICATION_SUCCESS,
  ALLACKSITEIDNOTIFICATION_FAILURE,
  ACKNOTIFICATION_REQUEST,
  ACKNOTIFICATION_SUCCESS,
  ACKNOTIFICATION_FAILURE,
  ALLACKNOTIFICATION_REQUEST,
  ALLACKNOTIFICATION_SUCCESS,
  ALLACKNOTIFICATION_FAILURE,
  ALERTS_REQUEST,
  ALERTS_SUCCESS,
  ALERTS_FAILURE,
  ALERT_REQUEST,
  ALERT_SUCCESS,
  ALERT_FAILURE,
  SAVEALERT_REQUEST,
  SAVEALERT_SUCCESS,
  SAVEALERT_FAILURE,
  DELETEALERT_REQUEST,
  DELETEALERT_SUCCESS,
  DELETEALERT_FAILURE,
} from './actionTypes';

export const notificationActions = {
  requestNotifications: (maxRecords, since) => (dispatch, getState) => {
    dispatch({
      type: NOTIFICATIONS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Notification/v1/GetUserNotifications?maxRecords=${
          maxRecords ? maxRecords : ''
        }&since=${since ? since.toISOString() : ''}`,
        successAction: NOTIFICATIONS_SUCCESS,
        failureAction: NOTIFICATIONS_FAILURE,
      },
      payload: {
        baseline: !since,
      },
    });
  },
  requestSiteIdNotifications: (companyId, notificationType, read, page, since) => (dispatch, getState) => {
    dispatch({
      type: SITEID_NOTIFICATIONS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Notification/v1/GetSiteIdUserNotifications?companyId=${
          companyId ? companyId : ''
        }&notificationTypeId=${notificationType ? notificationType: ''}
        &read=${read ? read : ''}
        &page=${page ? page : ''}
        &since=${since ? since.toISOString() : ''}`,
        successAction: SITEID_NOTIFICATIONS_SUCCESS,
        failureAction: SITEID_NOTIFICATIONS_FAILURE,
      },
      payload: {
        baseline: !since,
      },
    });
  },
  acknowledgeNotifications: (platformNotificationType, type, popupId) => (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: ACKNOTIFICATION_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Notification/v1/AcknowledgePopup',
        successAction: ACKNOTIFICATION_SUCCESS,
        failureAction: ACKNOTIFICATION_FAILURE,
        body: {
          platformNotificationType,
          popupId,
          type,
        },
      },
    });
  },
  acknowledgeAllNotifications: () => (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: ALLACKNOTIFICATION_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Notification/v1/AcknowledgeAllPopups',
        successAction: ALLACKNOTIFICATION_SUCCESS,
        failureAction: ALLACKNOTIFICATION_FAILURE,
        body: {
        },
      },
    });
  },
  acknowledgeAllSiteIdNotifications: (companyId, notificationType) => (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: ALLACKSITEIDNOTIFICATION_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Notification/v1/AcknowledgeAllSiteId',
        successAction: ALLACKSITEIDNOTIFICATION_SUCCESS,
        failureAction: ALLACKSITEIDNOTIFICATION_FAILURE,
        body: {
          companyId, notificationType,
        },
      },
    });
  },
  requestAlerts: (type, maxRecords) => (dispatch, getState) => {
    dispatch({
      type: ALERTS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Notification/v1/getAllNotifications?type=${type}&maxRecords=${maxRecords}`,
        successAction: ALERTS_SUCCESS,
        failureAction: ALERTS_FAILURE,
      },
    });
  },
  requestAlert: notificationId => (dispatch, getState) => {
    dispatch({
      type: ALERT_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Notification/v1/getnotification/${notificationId}`,
        successAction: ALERT_SUCCESS,
        failureAction: ALERT_FAILURE,
      },
    });
  },
  saveAlert: notification => (dispatch, getState) => {
    dispatch({
      type: SAVEALERT_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Notification/v1/save',
        successAction: SAVEALERT_SUCCESS,
        failureAction: SAVEALERT_FAILURE,
        body: {
          ...notification,
        },
      },
    });
  },
  deleteAlert: notificationId => (dispatch, getState) => {
    dispatch({
      type: DELETEALERT_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: `/api/Notification/v1/delete/${notificationId}`,
        successAction: DELETEALERT_SUCCESS,
        failureAction: DELETEALERT_FAILURE,
      },
    });
  },
};
