import React from 'react';
import { connect } from 'react-redux';

class SmallStat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, value, totalValue, area } = this.props;

    return (
      <div className="small-stat">
        <div className="small-stat__values">
          <span className="small-stat__value">{value}</span>
        </div>
        <div className="small-stat__name">{title}</div>
        <div className="small-stat__area">{area}</div>
        <div
          className="small-stat small-stat--overlay"
          style={{ width: (value / totalValue) * 100 + '%' }}>
          <div className="small-stat__values">
            <span className="small-stat__value">{value}</span>
          </div>
          <div className="small-stat__name">{title}</div>
          <div className="small-stat__area">{area}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SmallStat);
