import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { navigationActions } from '../../../actions/navigationActions';
import zxcvbn from 'zxcvbn';

class ActivateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordInvalid: false,
      passwordConfirmation: '',
      passwordConfirmationInvalid: false,
      forgottenPasswordToken: '',
      preferredContactMethodId: 1,
      contactMethods: [],
      allowEmail: false,
      allowPhone: false,
      validationMessage: '',
    };

    this.passwordOnChange = this.passwordOnChange.bind(this);
    this.passwordConfirmationOnChange = this.passwordConfirmationOnChange.bind(
      this,
    );
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.submit = this.submit.bind(this);
    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);
  }

  componentDidMount() {
    const { token } = this.props.match.params;
    if (token) {
      this.setState({ forgottenPasswordToken: token });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.loaded && !prevProps.loaded) {
      this.setState({ validationMessage: 'User Created Successfully.' });
    }
  }

  passwordOnChange(event) {
    let { value } = event.target;
    let valid = value !== undefined && value.length > 0 && value.length < 50;

    this.setState({ password: value, passwordInvalid: !valid });
  }

  passwordConfirmationOnChange(event) {
    let { value } = event.target;
    let valid = value !== undefined && value.length > 0 && value.length < 50;

    this.setState({
      passwordConfirmation: value,
      passwordConfirmationInvalid: !valid,
    });
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  handleCheckInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = true && e.target.checked;

    this.setState(update);
  }
  submit(event) {
    let {
      passwordConfirmation,
      password,
      passwordConfirmationInvalid,
      passwordInvalid,
      forgottenPasswordToken,
      preferredContactMethodId,
      allowEmail,
      allowPhone,
    } = this.state;
    let { activateAccount } = this.props;
    const passwordEvaluation = zxcvbn(password);

    if (password === '' || passwordInvalid || password.length < 8) {
      this.setState({ passwordInvalid: true, validationMessage: 'Password must be greater than 8 characters.' });
      event.preventDefault();
      return;
    }
    if (passwordEvaluation.score < 2){
      this.setState({ validationMessage: 'Increase the strength of your password by adding upper case and special characters (e.g. ABC@!)', 
        passwordInvalid: true });
      event.preventDefault();
      return;
    }

    if (
      passwordConfirmation === '' ||
      passwordConfirmationInvalid ||
      password !== passwordConfirmation
    ) {
      this.setState({ passwordConfirmationInvalid: true,  validationMessage: 'Passwords must match.' });
      event.preventDefault();
      return;
    }

    activateAccount({
      password,
      forgottenPasswordToken,
      preferredContactMethodId,
      allowEmail,
      allowPhone,
    });

    event.preventDefault();
  }

  render() {
    const {
      password,
      allowEmail,
      allowPhone,
      preferredContactMethodId,
      validationMessage,
    } = this.state;

    return (
      <form onSubmit={this.submit}
        noValidate>
        <p>Please enter a new password.</p>
        <input
          placeholder="Password"
          type="password"
          minLength="8"
          onChange={e => this.passwordOnChange(e)}
          required
          value={password}
          className={`float ${this.state.passwordInvalid ? 'invalid' : ''}`}
          autoComplete="new-password"
        />
        <input
          placeholder="Password Confirmation"
          type="password"
          minLength="8"
          onChange={e => this.passwordConfirmationOnChange(e)}
          required
          value={this.state.passwordConfirmation}
          className={`float ${
            this.state.passwordConfirmationInvalid ? 'invalid' : ''
          }`}
          autoComplete="new-password"
        />
        <label>
          Preferred Contact Method
          <select
            name="Preferred Contact Method"
            placeholder="..."
            onChange={e =>
              this.handleTextInputUpdate(e, 'preferredContactMethodId')
            }
            className="float"
            value={preferredContactMethodId}
          >
            <option value="1">Any</option>
            <option value="2">Email</option>
            <option value="3">Phone</option>
          </select>
        </label>
        <label></label>
        <label>
          Allow Email
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              onChange={e => this.handleCheckInputUpdate(e, 'allowEmail')}
              checked={allowEmail}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
          Allow Phone
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              onChange={e => this.handleCheckInputUpdate(e, 'allowPhone')}
              checked={allowPhone}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <div className="submission">
          <div className='password-validation-message'>
            <p>{validationMessage}</p>
          </div>
          <p>
            {this.props.message}
            {this.props.loaded
              ? 'Your account has been activated. Click the home button below and log in to continue.'
              : ''}
          </p>
          <button
            type="submit"
            className="float"
            disabled={this.props.isFetching}
          >
            {this.props.fetching ? 'Working...' : 'Activate'}
          </button>

          <button
            type="button"
            className="float negative"
            disabled={this.props.isFetching}
            onClick={() => this.props.push('/')}
          >
            Home
          </button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  fetching: state.auth.isFetching_ActivateAccount,
  loaded: state.auth.isLoaded_ActivateAccount,
  message: state.auth.errorMessage,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    activateAccount: creds => dispatch(authActions.activateAccount(creds)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount);
