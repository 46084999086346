import React from 'react';
import { connect } from 'react-redux';
import { customerRequirementsActions } from '../../../actions/customerRequirementsActions';

class CustomerRequirementsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storeNumber: undefined,
      change: undefined,
      explanation: undefined,

      showValidation: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
  }

  componentDidMount() {
    this.setState({ storeNumber: this.props.storeNumber });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.feedbackSaved && !prevProps.feedbackSaved) {
      this.props.escape();
    }
  }

  handleSave(e) {
    let { submitFeedback } = this.props;
    let { storeNumber, change, explanation } = this.state;
    if (!change || !explanation) {
      this.setState({ showValidation: true });
    } else {
      submitFeedback({ storeNumber, change, explanation });
    }
    e.preventDefault();
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  render() {
    let { change, explanation, showValidation } = this.state;
    const { feedbackSaving, feedbackError, feedbackSaved } = this.props;
    return (
      <div className="modal-blocker">
        <div className="modal">
          <form noValidate
            onSubmit={e => this.handleSave(e)}>
            <div className="modal-menu">
              <button
                type="button"
                className="action"
                onClick={() => this.props.escape()}>
                X
              </button>
            </div>
            <div className="modal-title">
              <h2>Customer Requirements Change Request</h2>
              <p>Tell us what change you'd like to make?</p>
            </div>
            <div className="modal-body">
              <div>
                <label>
                  What would you like to change?
                  <input
                    type="text"
                    value={change}
                    required
                    className={showValidation && !change ? 'invalid' : ''}
                    onChange={e => this.handleTextInputUpdate(e, 'change')}
                  />
                </label>
                <label>
                  How would you like to change this?
                  <textarea
                    value={explanation}
                    required
                    className={showValidation && !explanation ? 'invalid' : ''}
                    onChange={e => this.handleTextInputUpdate(e, 'explanation')}
                  />
                </label>
              </div>
            </div>
            <div className="modal-buttons">
              {feedbackError && <p>{feedbackError}</p>}
              {feedbackSaved && <p>Feedback Successfully Submitted</p>}
              <button
                type="submit"
                className="action"
                disabled={feedbackSaving}>
                Submit
              </button>
              <button
                type="button"
                className="action negative"
                onClick={() => this.props.escape()}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    feedbackSaving: state.customerRequirements.isSubmitting,
    feedbackSaved: state.customerRequirements.isSubmitted,
    feedbackError: state.customerRequirements.errorMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submitFeedback: feedbackData =>
      dispatch(
        customerRequirementsActions.amendCustomerRequirements(feedbackData),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerRequirementsModal);
