import {
  FORECASTCHANGEREQUEST_REQUEST,
  FORECASTCHANGEREQUEST_SUCCESS,
  FORECASTCHANGEREQUEST_FAILURE,
  CHECKITEM_REQUEST,
  CHECKITEM_SUCCESS,
  CHECKITEM_FAILURE,
} from './actionTypes';

export const forecastChangeRequestActions = {
  submitForecastChangeRequest: request => (dispatch, getState) => {
    dispatch({
      type: FORECASTCHANGEREQUEST_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Forecast/v1/SubmitForecastChangeRequest',
        successAction: FORECASTCHANGEREQUEST_SUCCESS,
        failureAction: FORECASTCHANGEREQUEST_FAILURE,
        body: {
          ...request,
        },
      },
    });
  },
  checkItem: filter => (dispatch, getState) => {
    dispatch({
      type: CHECKITEM_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/ItemCatalogue/v1/CheckItem?itemNumber=${filter}`,
        successAction: CHECKITEM_SUCCESS,
        failureAction: CHECKITEM_FAILURE,
      },
      payload: {
        requestedItem: filter,
      },
    });
  },
};
