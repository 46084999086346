import {
  BULKAPPLICATIONUPLOAD_REQUEST,
  BULKAPPLICATIONUPLOAD_SUCCESS,
  BULKAPPLICATIONUPLOAD_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const bulkApplicationReducer = (
  state = initialState.bulkApllicationUploadState,
  action,
) => {
  switch (action.type) {
  case BULKAPPLICATIONUPLOAD_REQUEST: {
    return {
      ...state,
      isUploading: true,
      isLoaded: false,
      errorMessage: '',
    };
  }
  case BULKAPPLICATIONUPLOAD_SUCCESS: {
    return {
      ...state,
      isUploading: false,
      isLoaded: true,
      errorMessage: '',
    };
  }
  case BULKAPPLICATIONUPLOAD_FAILURE: {
    return {
      ...state,
      isUploading: false,
      isLoaded: false,
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default bulkApplicationReducer;
