import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { navigationActions } from '../../../actions/navigationActions';
import ApacTerms from '../../shared/termsAndConditions/apacTerms';
import EmeaTerms from '../../shared/termsAndConditions/emeaTerms';
import LacTerms from '../../shared/termsAndConditions/lacTerms';

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };

    this.handleDeclineTerms = this.handleDeclineTerms.bind(this);
    this.handleAcceptTerms = this.handleAcceptTerms.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.hasAcceptedTerms) {
      this.props.acceptedTerms();

      if (this.props.auth.hasConnectAccess) {
        this.props.push('/connect');
      }
    }
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 300);

  }

  handleDeclineTerms(event) {
    this.props.logout();
    this.props.reset();
    event.preventDefault();
  }

  handleAcceptTerms(event) {
    this.props.acceptTerms();

    if (this.props.auth.hasConnectAccess) {
      this.props.push('/connect');
    }

    if (event) {
      event.preventDefault();
    }
  }

  render() {
    const { loaded } = this.state;
    const { givenName, tenant } = this.props.auth;

    return (
      <div className="terms-page page">
        <div className={`banner-top ${loaded ? 'loaded' : ''}`}> </div>
        <div className={`banner-text ${loaded ? 'loaded' : ''}`}>
          <h5>
            {givenName}, please read and agree to the following Terms &amp;
                        Conditions to continue to the portal.
          </h5>
        </div>
        <div className="form">
          <div className={`terms-content ${loaded ? 'loaded' : ''}`}>

            {tenant === 'EMEA' && <EmeaTerms />}
            {tenant === 'APAC' && <ApacTerms />}
            {tenant === 'LAC' && <LacTerms />}

            <div className="submission">
              <button
                type="button"
                onClick={this.handleDeclineTerms}
                className="float bad">
                                Decline
              </button>
              <button
                type="button"
                onClick={this.handleAcceptTerms}
                className="float">
                                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    logout: () => dispatch(authActions.requestLogout()),
    acceptTerms: () => dispatch(authActions.acceptTerms()),
    acceptedTerms: () => dispatch(authActions.acceptedTerms()),
    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TermsAndConditions);
