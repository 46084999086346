import React from 'react';
 
const EmeaContactDetails=()=>
{
  return (<>
    <div className="form-body form-body-top">
      <div>
        <h4>EMEA Customers</h4>
      </div>
    </div>
    <div className="form-body form-body-top">
      <div>
        <h5>1st Line Customer Service</h5>

        <label>
          <span>Email</span>
          <a href="mailto:EMEA-CSD@starbucks.com">
                  EMEA-CSD@starbucks.com
          </a>
        </label>
      </div>
    </div>
    <div>
      <h4>Overdue Invoices</h4>

      <p>
              If you require a copy of an invoice, a statement for your account
              or have any payment queries please email:
      </p>
      <p>
        <a href="mailto:EMEA_APCUSTOMERCARE@STARBUCKS.COM">
                EMEA_APCUSTOMERCARE@STARBUCKS.COM
        </a>{' '}
              for EMEA queries.
      </p>
      <p>
              Please ensure that you have the Starbucks order number to hand.
              This is the number that will be with 50 or 90.
      </p>

      <h4>Pricing</h4>
      <p>
              If you have any pricing related queries, please contact{' '}
        <a href="mailto:UKBApricing@starbucks.com">
                UKBApricing@starbucks.com
        </a>
      </p>

      <h4>Store Development</h4>
      <p>
              For enquiries related to store development items: tiles,
              equipment, furniture, bar counters, signage, banners etc. please
              contact{' '}
        <a href="mailto:emea-sdevcs@starbucks.com">
                 emea-sdevcs@starbucks.com
        </a>
      </p>
      <p>
              For enquiries related to customized furniture, bar counters,
              signage, banners, sinks, merchandise stands and other store
              material that is not fulfilled through the Penske distribution
              center, please contact the vendor directly. If you have any
              questions regarding new item options please reach out to the
              Starbucks design team directly. For contact details of approved
              suppliers and the design team please refer to the Library within
              the Orderly application.
      </p>
    </div>
  </>);
};
 
export default EmeaContactDetails;