import {
  SITEID_COMPANIES_REQUEST,
  SITEID_COMPANIES_SUCCESS,
  SITEID_COMPANIES_FAILURE,
  SITEID_COMPANY_REQUEST,
  SITEID_COMPANY_SUCCESS,
  SITEID_COMPANY_FAILURE,
  SITEID_SAVECOMPANY_REQUEST,
  SITEID_SAVECOMPANY_SUCCESS,
  SITEID_SAVECOMPANY_FAILURE,
  SITEID_DELETECOMPANY_REQUEST,
  SITEID_DELETECOMPANY_SUCCESS,
  SITEID_DELETECOMPANY_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const siteIDCompanyReducer = (
  state = initialState.siteIDCompanyState,
  action,
) => {
  switch (action.type) {
  case SITEID_COMPANIES_REQUEST:
    return {
      ...state,
      companies: {
        isFetching: true,
        isLoaded: false,
        isDeleting: false,
        isDeleted: false,
        companyList: [],
        errorMessage: '',
      },
    };
  case SITEID_COMPANIES_SUCCESS:
    return {
      ...state,
      companies: {
        isFetching: false,
        isLoaded: true,
        companyList: action.payload.data,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalRecords: action.payload.totalRecords,
        errorMessage: '',
      },
    };
  case SITEID_COMPANIES_FAILURE:
    return {
      ...state,
      companies: {
        isFetching: false,
        isLoaded: false,
        companyList: [],
        errorMessage: action.payload.message,
      },
    };
  case SITEID_COMPANY_REQUEST:
    return {
      ...state,
      currentCompany: {
        ...state.currentCompany,
        isFetching: true,
        isLoaded: false,
        isSaving: false,
        isSaved: false,
        isDeleting: false,
        isDeleted: false,
        companyDetails: {},
        errorMessage: '',
      },
    };
  case SITEID_COMPANY_SUCCESS:
    return {
      ...state,
      currentCompany: {
        ...state.currentCompany,
        isFetching: false,
        isLoaded: true,
        isSaving: false,
        isSaved: false,
        companyDetails: action.payload,
        errorMessage: '',
      },
    };
  case SITEID_COMPANY_FAILURE:
    return {
      ...state,
      currentCompany: {
        ...state.currentCompany,
        isFetching: false,
        isLoaded: false,
        isSaving: false,
        isSaved: false,
        companyDetails: {},
        errorMessage: action.payload.message,
      },
    };
  case SITEID_SAVECOMPANY_REQUEST:
    return {
      ...state,
      currentCompany: {
        ...state.currentCompany,
        isSaving: true,
        isSaved: false,
        errorMessage: '',
      },
    };
  case SITEID_SAVECOMPANY_SUCCESS:
    return {
      ...state,
      currentCompany: {
        ...state.currentCompany,
        isSaving: false,
        isSaved: true,
        companyDetails: {
          ...state.currentCompany.companyDetails,
          company: {
            ...state.currentCompany.companyDetails.user,
            companyId: action.payload,
          },
        },
        errorMessage: '',
      },
    };
  case SITEID_SAVECOMPANY_FAILURE:
    return {
      ...state,
      currentCompany: {
        ...state.currentCompany,
        isSaving: false,
        isSaved: false,
        errorMessage: action.payload.message,
      },
    };
  case SITEID_DELETECOMPANY_REQUEST:
    return {
      ...state,
      companies: {
        ...state.companies,
        isDeleting: true,
        isDeleted: false,
        errorMessage: '',
      },
      currentCompany: {
        ...state.currentCompany,
        isDeleting: true,
        isDeleted: false,
        companyDetails: {},
        errorMessage: '',
      },
    };
  case SITEID_DELETECOMPANY_SUCCESS:
    return {
      ...state,
      companies: {
        ...state.companies,
        isDeleting: false,
        isDeleted: true,
        errorMessage: '',
      },
      currentCompany: {
        ...state.currentCompany,
        isDeleting: false,
        isDeleted: true,
        companyDetails: {},
        errorMessage: '',
      },
    };
  case SITEID_DELETECOMPANY_FAILURE:
    return {
      ...state,
      companies: {
        ...state.companies,
        isDeleting: false,
        isDeleted: false,
        errorMessage: action.payload.message,
      },
      currentCompany: {
        ...state.currentCompany,
        isDeleting: false,
        isDeleted: false,
        companyDetails: {},
        errorMessage: action.payload.message,
      },
    };
  default:
    return state;
  }
};
export default siteIDCompanyReducer;
