import {
  SITEID_COMPANIES_REQUEST,
  SITEID_COMPANIES_SUCCESS,
  SITEID_COMPANIES_FAILURE,
  SITEID_COMPANY_REQUEST,
  SITEID_COMPANY_SUCCESS,
  SITEID_COMPANY_FAILURE,
  SITEID_SAVECOMPANY_REQUEST,
  SITEID_SAVECOMPANY_SUCCESS,
  SITEID_SAVECOMPANY_FAILURE,
  SITEID_DELETECOMPANY_REQUEST,
  SITEID_DELETECOMPANY_SUCCESS,
  SITEID_DELETECOMPANY_FAILURE,
} from './actionTypes';

export const siteIDCompanyActions = {
  requestCompanies: (filter, page, pageSize) => (dispatch, getState) => {
    dispatch({
      type: SITEID_COMPANIES_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/siteid/company/v1/GetCompanies?filter=${filter}&page=${page}&pageSize=${pageSize}`,
        successAction: SITEID_COMPANIES_SUCCESS,
        failureAction: SITEID_COMPANIES_FAILURE,
      },
    });
  },
  requestCompany: companyId => (dispatch, getState) => {
    dispatch({
      type: SITEID_COMPANY_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/siteid/company/v1/GetCompany/${companyId}`,
        successAction: SITEID_COMPANY_SUCCESS,
        failureAction: SITEID_COMPANY_FAILURE,
      },
    });
  },
  saveCompany: company => (dispatch, getState) => {
    dispatch({
      type: SITEID_SAVECOMPANY_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/siteid/company/v1/Save',
        successAction: SITEID_SAVECOMPANY_SUCCESS,
        failureAction: SITEID_SAVECOMPANY_FAILURE,
        body: company,
      },
    });
  },
  deleteCompany: companyId => (dispatch, getState) => {
    dispatch({
      type: SITEID_DELETECOMPANY_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: `/api/siteid/company/v1/Delete/${companyId}`,
        successAction: SITEID_DELETECOMPANY_SUCCESS,
        failureAction: SITEID_DELETECOMPANY_FAILURE,
      },
    });
  },
};
