import React from 'react';
import { connect } from 'react-redux';
import { emailDocumentManagementActions } from '../../../../actions/emailDocumentManagementActions';
import { navigationActions } from '../../../../actions/navigationActions';
import DeleteModal from '../../../shared/DeleteModal';

class EditEmailDocumentManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      fileName: '',
      subject: '',
      sourceEmailAddress: '',
      documentFolderId: '',
      validate: false,
      showDeleteModal: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.getEmailNotificationManagementPanel = this.getEmailNotificationManagementPanel.bind(this);
    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);
  }

  componentDidMount() {
    let id = this.props.match.params.id;

    this.props.getEntry(id);

  }

  componentDidUpdate(prevProps) {
    const { pathname } = this.props.location;
    if (this.props.loaded && !prevProps.loaded && this.props.entry) {
      let entry = this.props.entry;

      this.setState({
        id: entry.id,
        fileName: entry.fileName,
        subject: entry.subject,
        sourceEmailAddress: entry.sourceEmailAddress,
        documentFolderId: entry.documentFolderId,
      });
    } else if (this.props.saved && !prevProps.saved) {
      this.props.goBack();
    } else if (this.props.deleted && !prevProps.deleted) {
      this.props.goBack();
    }
  }

  handleSave(e) {
    let { saveEntry } = this.props;
    let {
      id,
      fileName,
      subject,
      sourceEmailAddress,
      documentFolderId,
    } = this.state;

    if ((fileName ||
         subject ||
         sourceEmailAddress) &&
        documentFolderId) {
      saveEntry({
        id,
        fileName,
        subject,
        sourceEmailAddress,
        documentFolderId,
      });
    }

    this.setState({ validate: true });

    e.preventDefault();
  }

  handleDeleteModalShow() {
    this.setState({ showDeleteModal: true });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false });
  }

  handleDelete() {
    this.props.deleteEntry(this.state.id);
    this.handleDeleteModalHide();
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  getEmailNotificationManagementPanel() {
    let {
      fileName,
      subject,
      sourceEmailAddress,
      documentFolderId,
      validate,
    } = this.state;

    return (
      <div className="accordion-body">
        <div className="horizontal-layout">
          <label>
                Email Subject
            <input
              placeholder="Email Subject"
              onChange={e => this.handleTextInputUpdate(e, 'subject')}
              className={validate && !subject && !sourceEmailAddress && !fileName ? 'invalid' : '' }
              value={subject}
              maxLength={250}
            />
          </label>
          <label>
                Sender Email Address
            <input
              placeholder="Sender Email Address"
              onChange={e => this.handleTextInputUpdate(e, 'sourceEmailAddress')}
              className={validate && !subject && !sourceEmailAddress && !fileName ? 'invalid' : '' }
              value={sourceEmailAddress}
              maxLength={250}
            />
          </label>
          <label>
                Attachment File Name
            <input
              placeholder="File Name"
              onChange={e => this.handleTextInputUpdate(e, 'fileName')}
              className={validate && !subject && !sourceEmailAddress && !fileName ? 'invalid' : '' }
              value={fileName}
              maxLength={250}
            />
          </label>
          <label>
                Destination Document Folder
            <select
              onChange={e => this.handleTextInputUpdate(e, 'documentFolderId')}
              value={documentFolderId}
              className={validate && !documentFolderId ? 'invalid' : '' }
            >
              <option value=''>Upload to Document Folder...</option>
              {this.props.folders.map(f => 
                <option key={f.key}
                  value={f.key}>{f.value}</option>,
              )}
            </select>
          </label>
        </div>
      </div>
    );
  }

  render() {
    let { showDeleteModal } = this.state;
    return (
      <div className="edit-message-page page">
        <div className="floating-panel">
          <form noValidate
            onSubmit={e => this.handleSave(e)}>
            <div className="floating-header">
              <div className="header-title">
                <div className="icon icon-alert"></div>
                {this.state.id ? (
                  <h2>Edit Email Document Configuration</h2>
                ) : (
                  <h2>Create Email Document Configuration</h2>
                )}
              </div>
            </div>

            <p>
              Please enter at least one criteria to match upon as well as the destination folder.</p>
            <p>
              Where multiple criteria are entered, all must match for the rule to apply.
              Each of the criteria leverages a &lsquo;contains&rsquo; style partial matching and is case-insensitive.  
            </p>

            {this.getEmailNotificationManagementPanel()}

            <div className="button-container">
              <button className="action">Save</button>
              {this.state.id && (
                <button
                  type="button"
                  className="action negative"
                  onClick={() => this.handleDeleteModalShow()}>
                  Delete
                </button>
              )}
            </div>
          </form>
          {showDeleteModal && (
            <DeleteModal
              cancel={this.handleDeleteModalHide}
              continue={this.handleDelete}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    folders: state.emailDocumentManagement.folders,
    entry: state.emailDocumentManagement.entry,
    loading: state.emailDocumentManagement.isFetching,
    loaded: state.emailDocumentManagement.isLoaded,
    saving: state.emailDocumentManagement.isSaving,
    saved: state.emailDocumentManagement.isSaved,
    deleting: state.emailDocumentManagement.isDeleting,
    deleted: state.emailDocumentManagement.isDeleted,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEntry: id =>
      dispatch(emailDocumentManagementActions.requestEntry(id)),
    saveEntry: entry =>
      dispatch(emailDocumentManagementActions.saveEntry(entry)),
    deleteEntry: id =>
      dispatch(emailDocumentManagementActions.deleteEntry(id)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditEmailDocumentManagement);
