import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { connectConfigurationActions } from '../../../actions/connectConfigurationActions';
import { Tooltip } from '@material-ui/core';
import { PieChart, Pie, Cell } from 'recharts';


class ConnectConfiguration extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      configurationItems: [],
      greenLowerLimit: 100,
      redUpperLimit: 0,
      percentageCompleted: 50,
      tempGreenLowerLimit: null,
    };

    this.handleSaveConfiguration = this.handleSaveConfiguration.bind(this);
    this.handleGreenLimitBlur = this.handleGreenLimitBlur.bind(this);
    this.handleGreenLimitChange = this.handleGreenLimitChange.bind(this);
    this.handleCheckInputListUpdate = this.handleCheckInputListUpdate.bind(this);
  }

  componentDidMount() {
    const { getConfiguration } = this.props;
    
    getConfiguration();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isLoaded, isSaved, configurationItems, greenLowerLimit, redUpperLimit } = this.props;

    if (isLoaded && !prevProps.isLoaded) {
      this.setState({
        configurationItems,
        greenLowerLimit,
        redUpperLimit,
      });
    } else if (isSaved && !prevProps.isSaved){
      this.props.goBack();
    }
  }

  handleSaveConfiguration(e) {
    let { saveConfiguration } = this.props;
    let {
      configurationItems,
      greenLowerLimit,
      redUpperLimit,
    } = this.state;

    
    saveConfiguration({
      configurationItems: configurationItems,
      greenLowerLimit,
      redUpperLimit,
    });

    e.preventDefault();
  }

  handleCheckInputListUpdate(e, id) {
    const { configurationItems } = this.state;

    let selected = configurationItems.map(x => {
      if(x.configurationId === id){
        return { ...x, userEnabled: e.target.checked };
      }else{
        return x;
      }
    });
  
    this.setState({
      configurationItems: selected,
    });
  }

  handleGreenLimitChange (e) {
    this.setState({ tempGreenLowerLimit: e.target.value });
  }

  handleGreenLimitBlur() {
    const { redUpperLimit } = this.state;
    let tempGreenLimit = parseInt(this.state.tempGreenLowerLimit, 10) || 0;
    tempGreenLimit = Math.max(redUpperLimit + 1, Math.min(100, tempGreenLimit));
    this.setState({ greenLowerLimit: tempGreenLimit, tempGreenLowerLimit: null });
  }

  render() {
    const { configurationItems, percentageCompleted, greenLowerLimit, redUpperLimit } = this.state;
    const {
      userSaving,
      userSaved,
      errorMessage,
    } = this.props;

    const data = [
      { name: 'Completed', value: percentageCompleted * 10 },
      { name: 'Not Completed', value: 1000 - (percentageCompleted * 10) },
    ];
    var isGreen = percentageCompleted * 10 >= greenLowerLimit * 10;
    var isAmber = percentageCompleted * 10 < greenLowerLimit * 10 && percentageCompleted * 10 >= redUpperLimit * 10;
    var isRed = percentageCompleted * 10 < redUpperLimit * 10;
    const COLORS = [isGreen ? '#02a862' : isAmber ? '#faad00' : isRed ? '#ed4646' : '', '#D3D3D3'];
    return (
      <div className="edit-user-page page connect-config">
        <div className="floating-panel">
          <form noValidate
            onSubmit={e => this.handleSaveConfiguration(e)}>
            <div className="floating-header">
              <div className="header-title">
                <div className="icon-form"></div>
                <h2>Connect Configuration</h2>
              </div>
            </div>
            <div className="horizontal-layout">
              <div className="vertical-layout"
                style={{ marginBottom: '40px', width: '250px' }}>
                <h5>Feeds</h5>
                {configurationItems && configurationItems.map(x =>
                  <label key={x.configurationId}
                    className="wrapping">
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={x.userEnabled}
                        onChange={e =>
                          this.handleCheckInputListUpdate(
                            e,
                            x.configurationId,
                          )
                        }
                      />
                      <div className="toggle-icon"></div>
                    </div>
                    <span>{x.name}</span>
                  </label>,
                )}
              </div>

              <div className="vertical-layout">
                <h5>Default Task Completion Targets <Tooltip
                  title={'These values help to visualise the completion percentage of each task (shown in a progress bar). These are the default values that will be added to new tasks. They can then be overwritten via the task edit page for each specific task.'}>
                  <span className="info"
                    style={{ fontWeight: 200 }}>&#x1F6C8;</span></Tooltip></h5>

                <div className='pie-and-values-wrapper'>
                  <div className='user-values'>
                    <div className='user-value-item'>
                      <label>
                        Green (Target Line)
                      </label>
                      <div className='percentage-wrapper'>
                        <span> x &ge; </span>
                        <input type='number'
                          value={this.state.tempGreenLowerLimit !== null ? this.state.tempGreenLowerLimit : this.state.greenLowerLimit}
                          max={100}
                          min={this.state.redUpperLimit + 1}
                          onBlur={this.handleGreenLimitBlur}
                          onChange={this.handleGreenLimitChange}
                        />
                        <div className='percentage'>%</div>
                      </div>
                    </div>
                    
                    <div className='user-value-item'>
                      <label>
                        Amber
                      </label>
                      <div className='percentage-wrapper'>
                        <input type='number'
                          disabled
                          value={this.state.redUpperLimit}
                        />
                        <span> &le; x &lt;</span>
                        <input type='text'
                          disabled
                          value={this.state.greenLowerLimit}
                        />
                        <div className='percentage'>%</div>
                      </div>
                    </div>
                   
                    <div className='user-value-item'>
                      <label>
                        Red
                      </label>
                      <div className='percentage-wrapper'>
                        <span> x &lt;</span>
                        <input type='number' 
                          max={this.state.greenLowerLimit - 1}
                          min={0}
                          value={this.state.redUpperLimit}
                          onChange={(e) => this.setState({ redUpperLimit: Math.max(0, Math.min(e.target.value, this.state.greenLowerLimit - 1)) })}
                        />
                        <div className='percentage'>%</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='vertical-layout'>
                <h5>Example</h5>
                <Tooltip
                  title={`Percentage Complete : ${percentageCompleted?.toString().substring(0,5)}%`}>
                  <div>
                    <PieChart width={250}
                      height={140}>
                      <Pie
                        data={data}
                        cx={100}
                        cy={100}
                        startAngle={180}
                        endAngle={0}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {data.map((entry, index) => (
                          <Cell key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </div>
                </Tooltip>
                <label>Test Completion %
                  <input type='number'
                    style={{ width:'200px' }}
                    value={this.state.percentageCompleted}
                    min={0}
                    max={100}
                    onChange={(e) => this.setState({ percentageCompleted: e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value })}
                    placeholder='e.g 80'/>
                </label>
              </div>
            </div>

            <p>{errorMessage}</p>
            <div className="button-container">
              <button type="submit"
                className="action"
                disabled={userSaving}>
                {userSaving
                  ? 'Saving...'
                  : userSaved && !this.state.userId
                    ? 'Saved'
                    : 'Save'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { connectConfiguration } = state;
  return {
    isLoading: connectConfiguration.isLoading,
    isLoaded: connectConfiguration.isLoaded,
    isSaved: connectConfiguration.isSaved,
    configurationItems: connectConfiguration.configurationItems,
    redUpperLimit: connectConfiguration.redUpperLimit,
    greenLowerLimit: connectConfiguration.greenLowerLimit,
    errorMessage: connectConfiguration.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getConfiguration: () => dispatch(connectConfigurationActions.getConfiguration()),
    saveConfiguration: configuration => dispatch(connectConfigurationActions.saveConfiguration(configuration)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectConfiguration);
