import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../../actions/navigationActions';
import { connectTaskActions } from '../../../../actions/connectTaskActions';
import { specialActions } from '../../../../actions/specialActions';
import DeleteModal from '../../../shared/DeleteModal';
import TaskRowAdmin from '../TaskRowAdmin/TaskRowAdmin';
import TaskAdminBulkEditModal from '../TaskAdminBulkEditModal/TaskAdminBulkEditModal';

class TaskAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      taskDetailId: undefined,
      selectedTasks: [],
      isBulkEditModalOpen: false,
    };

    this.handleCreate = this.handleCreate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);
    this.handleBulkEditChecked = this.handleBulkEditChecked.bind(this);
  }

  componentDidMount() {
    this.props.requestAlerts();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isDeleted && !prevProps.isDeleted) {
      this.props.requestAlerts();
    }
  }

  handleDeleteModalShow(taskDetailId) {
    this.setState({ showDeleteModal: true, taskDetailId });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false, taskDetailId: undefined });
  }

  handleCreate() {
    const { pathname } = this.props.location;

    this.props.push(`${pathname}/0`);
  }

  handleEdit(taskDetailId) {
    const { pathname } = this.props.location;

    this.props.push(`${pathname}/${taskDetailId}`);
  }

  handleReport(taskDetailId) {
    this.props.downloadFile(`/api/connect/TaskManagement/v1/GetTaskReport?taskDetailId=${taskDetailId}`);
  }

  handleDelete() {
    this.props.deleteAlert(this.state.taskDetailId);
    this.handleDeleteModalHide();
  }

  handleBulkEditChecked(e, id) {
    console.log('hit');
    const { selectedTasks } = this.state;
    if (e.target.checked) {
      this.setState({ selectedTasks: [...selectedTasks, id] });
    } else {
      this.setState({ selectedTasks: selectedTasks.filter(val => val !== id) });
    }
  }

  render() {
    let { showDeleteModal, selectedTasks, isBulkEditModalOpen } = this.state;
    return (
      <div className="task-admin-page page">
        <div className="floating-panel">
          <div className="floating-header-2">
            <div className="header-title">
              <div className="icon icon-alert"></div>
              <h2>Tasks</h2>
            </div>
            <div className="header-buttons">
              <button
                style={{ marginRight: '10px' }}
                className="action"
                type="button"
                disabled={selectedTasks.length <= 1}
                onClick={() => this.setState({ isBulkEditModalOpen: true })}>
                Bulk Edit
              </button>
              <button
                className="action"
                type="button"
                onClick={() => this.handleCreate()}>
                Create New
              </button>
            </div>
          </div>
          <div className="task-admin-list">
            {this.props.adminAlerts.map(p => (
              <TaskRowAdmin
                key={p.taskDetailId}
                taskId={p.taskDetailId}
                data={p}
                onEdit={() => this.handleEdit(p.taskDetailId)}
                onDelete={() => this.handleDeleteModalShow(p.taskDetailId)}
                onReport={() => this.handleReport(p.taskDetailId)}
                handleBulkEditChecked={(e, id) => this.handleBulkEditChecked(e, id)}
                selectedTasks={this.state.selectedTasks}
              />
            ))}
          </div>
          <div>
            {showDeleteModal && (
              <DeleteModal
                cancel={this.handleDeleteModalHide}
                continue={this.handleDelete}
              />
            )}
            {isBulkEditModalOpen && (
              <TaskAdminBulkEditModal
                cancel={() => this.setState({ isBulkEditModalOpen: false })}
                selectedTasks={this.state.selectedTasks}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  adminAlerts: state.connectTask.adminAlerts,
  isDeleted: state.connectTask.isDeleted,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    requestAlerts: () => dispatch(connectTaskActions.requestTaskList(25)),
    deleteAlert: taskDetailId =>
      dispatch(connectTaskActions.deleteTask(taskDetailId)),

    downloadFile: (url) => dispatch(specialActions.downloadFile(url)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskAdmin);
