import React from 'react';

class FilterPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      companies,
      companyId,
      counties,
      countyId,
      statuses,
      statusId,
      formats,
      formatId,
      doFilter,
    } = this.props;

    return (
      <div className="filter-panel floating-panel">
        {companies && Object.keys(companies).length > 1 && (
          <label>
            Company
            <select value={companyId}
              onChange={e => doFilter(e, 'companyId')}>
              <option value="">All Companies</option>
              {Object.entries(companies).map(([key, value]) => (
                <option key={key}
                  value={key}>
                  {value}
                </option>
              ))}
            </select>
          </label>
        )}
        {counties && Object.keys(counties).length > 1 && (
          <label>
            Territory
            <select value={countyId}
              onChange={e => doFilter(e, 'countyId')}>
              <option value="">All Territories</option>
              {Object.entries(counties).map(([key, value]) => (
                <option key={key}
                  value={key}>
                  {value}
                </option>
              ))}
            </select>
          </label>
        )}
        {statuses && Object.keys(statuses).length > 1 && (
          <label>
            Current Status
            <select value={statusId}
              onChange={e => doFilter(e, 'statusId')}>
              <option value="">All Statuses</option>
              {Object.entries(statuses).map(([key, value]) => (
                <option key={key}
                  value={key}>
                  {value}
                </option>
              ))}
            </select>
          </label>
        )}
        {formats && Object.keys(formats).length > 1 && (
          <label>
            Format
            <select value={formatId}
              onChange={e => doFilter(e, 'formatId')}>
              <option value="">All Formats</option>
              {Object.entries(formats).map(([key, value]) => (
                <option key={key}
                  value={key}>
                  {value}
                </option>
              ))}
            </select>
          </label>
        )}
      </div>
    );
  }
}

export default FilterPanel;
