import {
  ORDERLYREQUEST_REQUEST,
  ORDERLYREQUEST_SUCCESS,
  ORDERLYREQUEST_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const marketingReducer = (
  state = initialState.feedbackState,
  action,
) => {
  switch (action.type) {
  case ORDERLYREQUEST_REQUEST: {
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      errorMessage: '',
    };
  }
  case ORDERLYREQUEST_SUCCESS: {
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      errorMessage: '',
    };
  }
  case ORDERLYREQUEST_FAILURE: {
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      errorMessage: action.payload.message
        ? action.payload.message
        : action.payload.errorMessage
          ? action.payload.errorMessage
          : 'An error has occurred',
    };
  }
  default:
    return state;
  }
};
export default marketingReducer;
