import {
  IN_FULL_KPI_REQUEST,
  IN_FULL_KPI_FAILURE,
  GET_STORE_GROUPS_SUCCESS,
  GET_STORES_SUCCESS,
  GET_LATEST_IMPORT_SUCCESS,
  GET_KPI_CONFIGURATIONS_SUCCESS,
  UPDATE_KPI_CONFIGURATIONS_SUCCESS,
  IN_FULL_KPI_DATA_REQUEST,
  IN_FULL_KPI_DATA_SUCCESS,
  FAILEDITEMS_REQUEST,
  FAILEDITEMS_SUCCESS,
} from '../actions/actionTypes';
import initialState from './initialState';

export const kpiDashboardReducer = (
  state = initialState.kpiDashboardState,
  action,
) => {
  switch (action.type) {
  case IN_FULL_KPI_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case IN_FULL_KPI_DATA_REQUEST: {
    return {
      ...state,
      loading: true,
      inFullDashboard: {},
    };
  }
  case IN_FULL_KPI_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      inFullDashboard: action.payload,
    };
  }
  case FAILEDITEMS_REQUEST:{
    return {
      ...state,
      loadingFailedItems: true,
      failedItems: [],
    };
  }
  case FAILEDITEMS_SUCCESS: {
    return {
      ...state,
      loadingFailedItems: false,
      failedItems: action.payload,
    };
  }
  case GET_STORE_GROUPS_SUCCESS: {
    return {
      ...state,
      storeGroups: !action.payload ? [] : action.payload.map((item) => {
        return {
          label: item.storeGroupName,
          value: item.storeGroupId,
          data: item,
        };
      }),
    };
  }
  case GET_STORES_SUCCESS: {
    return {
      ...state,
      stores: !action.payload ? [] : action.payload.map((item) => {
        return {
          label: item.storeName,
          value: item.storeId,
          data: item,
        };
      }),
    };
  }
  case GET_LATEST_IMPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
    };
  }
  case GET_KPI_CONFIGURATIONS_SUCCESS: {
    return {
      ...state,
      configurations: action.payload,
      loading: false,
    };
  }
  case UPDATE_KPI_CONFIGURATIONS_SUCCESS: {
    return {
      ...state,
      configurations: action.payload,
      loading: false,
    };
  }
  case IN_FULL_KPI_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }
  default:
    return state;
  }
};
export default kpiDashboardReducer;
