import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { posItemNumberRequestActions } from '../../../actions/posItemNumberRequestActions';

class RequestItemNumberForm extends React.Component {

  constructor(props) {
    super(props);

    this.itemTypes = {
      Beverage: '1',
      Food: '2',
      Merchandise: '3',
    };

    this.initialState = {
      isAdmin: false,

      //Header
      id:0,
      customerStoreGroupId: undefined,
      market: '',
      itemTypeId: '',
      customerItemNumber: '',  
      customerItemDescription: '',
      primaryUomId: '',

      //Footer
      cacao: false, 
      gtin: '',
      comments: '',
      supportingDocumentMediaData: undefined,
      supportingDocumentMediaName: undefined,
      supportingDocumentMediaType: undefined,

      //Food
      productPlatformId : '',
      flavour : '',
      productSubCategoryId: '',

      //Beverage
      itemSize: '',
      temperatureId: '',
      rtd: false,
      rtdMediaName: undefined,
      rtdMediaType: undefined,
      rtdMediaData: undefined,
      newComponents: false,
      newComponentsDetails: '',
      additionalModifiers: false,
      modifiersDetails: '',

      //Merchandise
      imageMediaData: undefined,
      imageMediaName: undefined,
      imageMediaType: undefined,

      //Other
      statusId: '',
      moreInformation: '',
      rejectionReason: '',
      sbuxItemNumber: '',
      sbuxItemDescription: '',
      showValidation: '',
    };

    this.state = {
      ...this.initialState,
    };

    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleFileInputUpdate = this.handleFileInputUpdate.bind(this);
  }
  
  componentDidMount(){
    const {
      getItemDetail,
      match, 
    } = this.props;
    const { itemRequestId, moduleId } = match.params;

    this.setState({ isAdmin: moduleId === '663' });

    getItemDetail(itemRequestId);
  }

  componentDidUpdate(prevProps){
    if(this.props.isLoaded && !prevProps.isLoaded){
      this.setState({
        ...this.props.itemDetail,
      });
    }
    
    if(this.props.isSaved && !prevProps.isSaved){
      this.props.goBack();
    }
  }

  handleInput(e, fieldName){
    this.setState({ [fieldName]: e.target.value });
  } 

  handleCheckbox(e, fieldName){
    this.setState({ [fieldName]: e.target.checked });
  } 
  
  handleReset(){
    this.setState ( {
      ...this.initialState,
    });
  }

  validationClass(value){
    const { showValidation } = this.state;
    if (showValidation && !value){
      return 'invalid'; 
    }else 
      return '';
  }

  handleFileInputUpdate(e, dataField, fileNameField, fileExtField) {
    if (!e.target.files || e.target.files.length === 0) return;
    let currentComponent = this;
    let update = {};

    update[fileNameField] = e.target.files[0].name;

    update[fileExtField] = e.target.files[0].type;

    var reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }).then(data => {
      update[dataField] = data;
      currentComponent.setState(update);
    });
  }

  handleSave () {
    const { 
      id,
      //Header
      customerStoreGroupId,
      market,
      itemTypeId,
      customerItemNumber,  
      customerItemDescription,
      primaryUomId,

      //Footer
      cacao, 
      gtin,
      comments,
      supportingDocumentMediaData,
      supportingDocumentMediaName,
      supportingDocumentMediaType,

      //Food
      productPlatformId,
      otherPlatform,
      flavour,
      productSubCategoryId,

      //Beverage
      itemSize,
      temperatureId,
      rtd,
      rtdMediaName,
      rtdMediaType,
      rtdMediaData,
      newComponents,
      newComponentsDetails,
      additionalModifiers,
      modifiersDetails,

      //Merchandise
      imageMediaData,
      imageMediaName,
      imageMediaType, 

      //admin
      isAdmin,
      statusId,
      moreInformation,
      rejectionReason,
    } = this.state;

    const { saveForm } = this.props;

    if(isAdmin){
      if(this.isAdminFormInvalid()){
        this.setState({ showValidation: true });
        return;
      }

      saveForm({  
        id,
        isAdmin,
        statusId,
        moreInformation,
        rejectionReason,
      });

      return;
    }
    
    //Required
    if(this.isCoreFormInvalid()){
      this.setState({ showValidation: true });
      return;
    }

    let itemSpecificFields = {};

    switch (itemTypeId.toString()) {
    case this.itemTypes.Beverage:
      if(this.isBeverageFormInvalid()){
        this.setState({ showValidation: true });
        return;
      }

      itemSpecificFields = {
        itemSize,
        temperatureId,
        rtd,
        rtdMediaName,
        rtdMediaType,
        rtdMediaData,
        newComponents,
        newComponentsDetails,
        additionalModifiers,
        modifiersDetails,
      };
      break;
    case this.itemTypes.Food:
      if(this. isFoodFormInvalid()){
        this.setState({ showValidation: true });
        return;
      }

      itemSpecificFields = {
        productPlatformId,
        otherPlatform,
        flavour,
        productSubCategoryId,
      };
      break;
    case this.itemTypes.Merchandise:
      if(this.isMerchandiseFormInvalid()){
        this.setState({ showValidation: true });
        return;
      }

      itemSpecificFields = {
        imageMediaData,
        imageMediaName,
        imageMediaType,
      };
      break;
    }

    saveForm({ 
      id, 
      customerStoreGroupId,
      market,
      itemTypeId,
      customerItemNumber,  
      customerItemDescription,
      primaryUomId,
      cacao, 
      gtin,
      comments,
      supportingDocumentMediaData,
      supportingDocumentMediaName,
      supportingDocumentMediaType,
      ...itemSpecificFields,
    });
  }


  // Save Validation methods
  isCoreFormInvalid(){
    const {
      customerStoreGroupId,
      market,
      itemTypeId,
      customerItemNumber,  
      customerItemDescription,
      primaryUomId,
    } = this.state;
    return !market ||
      !customerStoreGroupId ||
      !itemTypeId ||
      !customerItemNumber ||
      !customerItemDescription ||
      !primaryUomId;
  }

  isAdminFormInvalid(){
    const {
      statusId,
      moreInformation,
      rejectionReason,
    }= this.state;
    return !statusId ||
         (statusId.toString() === '2' && !rejectionReason)||
         (statusId.toString() === '3' && !moreInformation);
  }

  isBeverageFormInvalid(){
    const {
      itemSize,
      temperatureId,
      rtd,
      rtdMediaName,
      rtdMediaType,
      rtdMediaData,
      newComponents,
      newComponentsDetails,
      additionalModifiers,
      modifiersDetails,
      comments,
    } = this.state;
    return !itemSize ||
      !comments ||
      !temperatureId ||
      (!rtd && (
        !rtdMediaName ||
      !rtdMediaType ||
      !rtdMediaData)) ||
      (newComponents &&
      !newComponentsDetails) ||
      (additionalModifiers &&
      !modifiersDetails
      );
  }
  
  isFoodFormInvalid(){
    const {
      productPlatformId,
      otherPlatform,
      flavour,
      productSubCategoryId,
    } = this.state;
   
    return !productPlatformId ||
         (productPlatformId.toString() === '50' && !otherPlatform) ||
         !flavour||
         !productSubCategoryId;
  }

  isMerchandiseFormInvalid(){
    const {
      imageMediaData,
      imageMediaName,
      imageMediaType, 
      comments,
    } = this.state;
    
    return !comments ||
         !imageMediaData ||
         !imageMediaName||
         !imageMediaType;
  }
  

  referenceFileDownload (file) {
    if (file && file.fileReference){
      return <a
        className="expand-button"
        href={`/api/LsPos/ItemNumber/v1/Download?fileReference=${file.fileReference}`}>
        <span className="icon download-icon"></span>Download</a>;
    } else {
      return null;
    }
  }

  renderForm(){

    const { itemTypeId } = this.state;

    return (<>
      
      {this.renderFormHeader()}

      {itemTypeId.toString() === this.itemTypes.Beverage ? 
        this.renderFormBeverage()
        : null}

      {itemTypeId.toString() === this.itemTypes.Food ? 
        this.renderFormFood()
        : null}

      {itemTypeId.toString() === this.itemTypes.Merchandise ? 
        this.renderFormMerchandise()
        : null}

      {this.renderFormFooter()}
     
    </>);

  }

  renderFormHeader(){

    const { itemType, uom, storeGroups } = this.props;

    return(<>
      <div className="form-body">
        <label>Customer Group
          <select onChange={(e) => this.handleInput(e, 'customerStoreGroupId') }
            className={this.validationClass(this.state.customerStoreGroupId)}
            value={this.state.customerStoreGroupId}
          >
            <option value=''>Select your customer group...</option>
            {storeGroups.map(item => (
              <option
                key={item.storeGroupId}
                value={item.storeGroupId}
              >{item.storeGroupName}</option>         
            ))}           
          </select>
        </label>
        <label>Market
          <input type="text"
            placeholder="UK, NL, ES etc..."
            className={this.validationClass(this.state.market)}
            value={this.state.market}
            onChange={(e) => this.handleInput(e, 'market')}
          />
        </label>
      </div>

      <div className="form-body">
        <label>Item Type
          <select onChange={(e) => this.handleInput(e, 'itemTypeId') }
            className={this.validationClass(this.state.itemTypeId)}
            value={this.state.itemTypeId}
          >
            <option value=''>Select an item type...</option>
            {itemType.map(item => (
              <option
                key={item.key}
                value={item.key}
              >{item.value}</option>         
            ))}           
          </select>
        </label>
       
      
        <label>Customer (Supplier) Item Number
          <input type="text"
            placeholder="0110222033..."
            className={this.validationClass(this.state.customerItemNumber)}
            onChange={(e) => this.handleInput(e, 'customerItemNumber')}
            value={this.state.customerItemNumber}
          />
        </label>
        <label>Customer Item POS Description
          <input type="text"
            placeholder="Banana Bread..."
            className={this.validationClass(this.state.customerItemDescription)}
            onChange={(e) => this.handleInput(e, 'customerItemDescription')}
            value={this.state.customerItemDescription}
          />
        </label>
        <label>Primary UOM
          <select onChange={(e) => this.handleInput(e, 'primaryUomId')}
            className={this.validationClass(this.state.primaryUomId)}
            value={this.state.primaryUomId}
          >
            <option value=''>Select a primary UOM...</option>
            {uom.map(item => (
              <option key={item.key}
                value={item.key}>{item.value}</option>         
            ))}           
          </select>
        </label>
      </div></>);
  }

  renderFormFooter(){
    return(<><div className="form-body">
      {this.state.itemTypeId != 3 ?
        <label>Cocoa Included?
          <div className="toggle-container">
            <div>
              <label className="switch">
                <input
                  onChange={(e) => this.handleCheckbox(e, 'cacao')}
                  type="checkbox"
                  checked={this.state.cacao}
                />
                <span className="slider round" />
              </label>
            </div>
          </div>
        </label>
        : null}
      <label>GTIN (If Known)
        <input type="text"
          placeholder="5751114256..."
          onChange={(e) => this.handleInput(e, 'gtin')}
          value={this.state.gtin}
        />
      </label>
    </div>
    <div className="form-body">
      <label> Attach Supporting Document{this.referenceFileDownload(this.state.supportingDocumentFile)}
        <label className="file-upload-wrapper">
          <div className="icon icon-image-upload"></div>
          <p>
            {!this.state.supportingDocumentMediaName &&
                  'Tap to upload supporting documents'}
            {this.state.supportingDocumentMediaName}
          </p>  
          <input
            name="file"
            id="file"
            className="inputfile"
            type="file"
            onChange={e =>
              this.handleFileInputUpdate(
                e,
                'supportingDocumentMediaData',
                'supportingDocumentMediaName',
                'supportingDocumentMediaType',
              )
            }
          />
        </label>
      </label>
      
      <label> {this.state.itemTypeId != 2 ? 'Comments' : 'Comments (optional)' }
        <textarea
          placeholder="Please provide further details on this item (if necessary) to support Starbucks approval decision to support its sale."
          onChange={(e) => this.handleInput(e, 'comments')}
          className={(this.state.itemTypeId != 2 ? this.validationClass(this.state.comments) : null)}
          value= {this.state.comments}
        >
        </textarea>
      </label></div></>);
  }

  renderFormBeverage(){

    const { temperature } = this.props;

    return (<>
      <div className="form-body">
        <label>Item Size
          <input type="text"
            placeholder="Tall, Grande, etc…"
            className={this.validationClass(this.state.itemSize)}
            onChange={(e) => this.handleInput(e, 'itemSize')}
            value={this.state.itemSize}
          />
        </label>
        <label>Temperature
          <select 
            onChange={(e) => this.handleInput(e, 'temperatureId')}
            className={this.validationClass(this.state.temperatureId)}
            value={this.state.temperatureId}
          >
            <option value=''>Select a temperature...</option>
            {temperature.map(item => (
              <option key={item.key}
                value={item.key}>{item.value}</option>         
            ))}           
          </select>
        </label>
      </div>
      <div className="form-body">
        <label>RTD
          <div className="toggle-container">
            <div>
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={(e) => this.handleCheckbox(e, 'rtd')}
                  checked={this.state.rtd}
                />
                <span className="slider round" />
              </label>
            </div>
          </div>
        </label>
        {!this.state.rtd ? 
          <label className="new-comments">Upload Recipe Card{this.referenceFileDownload(this.state.rtdFile)}
            <label className="file-upload-wrapper">
              <div className="icon icon-image-upload"></div>
              <p>
                {!this.state.rtdMediaName &&
                  'Tap to upload a recipe card'}
                {this.state.rtdMediaName}
              </p>
              <input
                name="file"
                id="file"
                className="inputfile"
                type="file"
                onChange={e =>
                  this.handleFileInputUpdate(
                    e,
                    'rtdMediaData',
                    'rtdMediaName',
                    'rtdMediaType',
                  )
                }
              />
            </label>
          </label> : null}</div>
      {this.renderFormBeverageAdditional()}
    </>);
  }

  renderFormBeverageAdditional(){ 
    return (<>
      <div className="form-body">
        <label>Contains New Components?
          <div className="toggle-container">
            <div>
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={(e) => this.handleCheckbox(e, 'newComponents')}
                  checked={this.state.newComponents}
                />        
                <span className="slider round" />
              </label>
            </div>
          </div>
          {this.state.newComponents ? 
            <textarea 
              className={this.validationClass(this.state.newComponentsDetails)}
              onChange={(e) => this.handleInput(e, 'newComponentsDetails')}
              value={this.state.newComponentsDetails}
              placeholder="Please enter the details for the new components..."></textarea> : null}
        </label>
        <label>Additional Modifiers Needed?
          <div className="toggle-container">
            <div>
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={(e) => this.handleCheckbox(e, 'additionalModifiers')}
                  checked={this.state.additionalModifiers}
                />
                <span className="slider round" />
              </label>
            </div>
          </div>   
          {this.state.additionalModifiers ? 
            <textarea 
              className={this.validationClass(this.state.modifiersDetails)}
              onChange={(e) => this.handleInput(e, 'modifiersDetails')}
              value={this.state.modifiersDetails}
              placeholder="Please enter the details for the new modifiers..."></textarea> : null }
        </label>
      </div>
    </>);
  }

  renderFormFood(){

    const { productPlatform, productSubCategory } = this.props;

    return (<div className="form-body">
      <label>Product Platform
        <select 
          onChange={(e) => this.handleInput(e, 'productPlatformId')}
          value={this.state.productPlatformId}
          className={this.validationClass(this.state.productPlatformId)}
        >
          <option value=''>Select a product platform...</option>
          {productPlatform.map(item => (
            <option key={item.key}
              value={item.key}>{item.value}</option>         
          ))}  
        </select>
      </label>
      {this.state.productPlatformId && this.state.productPlatformId.toString() === '50' ?
        <label>Product Platform (Other)
          <input type="text"
            placeholder=""
            className={this.validationClass(this.state.otherPlatform)}
            onChange={(e) => this.handleInput(e, 'otherPlatform')}
            value={this.state.otherPlatform}
          />
        </label>
      
        : null}
      <label>Product Sub Category
        <select 
          onChange={(e) => this.handleInput(e, 'productSubCategoryId')}
          value={this.state.productSubCategoryId}
          className={this.validationClass(this.state.productSubCategoryId)}
        >
          <option value="first">Select a product sub category...</option>
          {productSubCategory.map(item => (
            <option key={item.key}
              value={item.key}>{item.value}</option>         
          ))}           
        </select>
      </label>
      <label>Flavour
        <input type="text"
          placeholder="Banana, Coffee, etc…"
          className={this.validationClass(this.state.flavour)}
          onChange={(e) => this.handleInput(e, 'flavour')}
          value={this.state.flavour}
        />
      </label>
    </div>);
  }

  renderFormMerchandise(){
    return (<div className="form-body">
      <label className="merchandise-image">Upload Item Image
        {this.referenceFileDownload(this.state.imageFile)}
        <label className="file-upload-wrapper">
          <div className="icon icon-image-upload"></div>
          <p>
            {!this.state.imageMediaName ?
              'Tap to upload an item image' : this.state.imageMediaName}
          </p>  
          <input
            name="file"
            id="file"
            accept="image/*"
            className="inputfile"
            type="file"
            onChange={e =>
              this.handleFileInputUpdate(
                e,
                'imageMediaData',
                'imageMediaName',
                'imageMediaType',
              )
            }
          />
        </label>
      </label>
    </div>);
  }

  renderAdminForm(){

    const { isAdmin } = this.state;

    const { statuses } = this.props;

    if(!isAdmin) return null;

    return(<>
      <div className="form-body">
        <p>Following review within Starbucks, the following request confirmation details are as follows:</p>
      </div>
      <div className="form-body">
        
        <label>Starbucks Status
          <select 
            onChange={(e) => this.handleInput(e, 'statusId')}
            value={this.state.statusId}
            className={this.validationClass(this.state.statusId)}
          >
            <option value=''>Select a status...</option>
            {statuses.map(item => (
              <option key={item.key}
                value={item.key}>{item.value}</option>         
            ))}  
          </select>
        </label>
        {this.state.statusId && this.state.statusId.toString() === '2' ? 
          <label>Rejection Reason
            <textarea type="text"
              className={this.validationClass(this.state.rejectionReason)}
              onChange={(e) => this.handleInput(e, 'rejectionReason')}
              value={this.state.rejectionReason}
            ></textarea>
          </label> : null}
        {this.state.statusId && this.state.statusId.toString() === '3' ? 
          <label>More Information
            <textarea type="text"
              className={this.validationClass(this.state.moreInformation)}
              onChange={(e) => this.handleInput(e, 'moreInformation')}
              value={this.state.moreInformation}
            ></textarea>
          </label>:  null}
      </div></>
    ); 
              
  }

  renderUserSummary(){

    const { statuses } = this.props;

    const { isAdmin, id } = this.state;

    if(isAdmin || id === 0) return;

    let currentStatus = statuses && statuses.filter(s => s.key === this.state.statusId);
    
    return(<>
      <div className="form-body">
        <p>Following review within Starbucks, the following request confirmation details are as follows:</p>
      </div>
      <div className="form-body">
        
        <label>Starbucks Status
          <input disabled={true}
            value={currentStatus && currentStatus.length && currentStatus.length > 0 && currentStatus[0].value} />
        </label>
        {this.state.statusId && this.state.statusId.toString() === '2' ? 
          <label>Rejection Reason
            <textarea type="text"
              disabled={true}
              value={this.state.rejectionReason}
            ></textarea>
          </label> : null}
        {this.state.statusId && this.state.statusId.toString() === '3' ? 
          <label>More Information
            <textarea type="text"
              disabled={true}
              value={this.state.moreInformation}
            ></textarea>
          </label>:  null}
        <label>Starbucks POS Item Number
          <input disabled={true}
            value={this.state.sbuxItemNumber} />
        </label>
        <label>Starbucks POS Item Description
          <input disabled={true}
            value={this.state.sbuxItemDescription} />
        </label>
      </div></>
    ); 
              
  }

  render() {
    const { isSaving } = this.props;

    const {
      handleSave,
      handleReset,
    } = this;

    return ( 
      <div className="request-item-number">
        <div className="form-container">
          <div className="form-header">
            <div className="header-title">
              <div className="icon icon-form"></div>
              <h2>Request Starbucks Item Number</h2>
            </div>
          </div>
          <div className="form-explanation">
            <p>Use this form to request a Starbucks SKU Item Number.</p>
            <p>This number is required for any item that you will sell through the POS that you source from your own
                supplier and does not have an existing Starbucks approved item number.
            </p>
          </div>
          

          {this.renderForm()}
            
          {this.renderAdminForm()}

          {this.renderUserSummary()}

          <div className="form-body">
            <div className="form-buttons">
              <button className="action"
                onClick={() => handleSave() }
                type="button"
              >
                {isSaving
                  ? 'Sending request...'
                  : 'Submit Request'}</button>
            </div>
            <div className="form-buttons">
              <button className="action clear"
                onClick= {() => handleReset() }
                type="button"
              >Clear Form</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.lsPosItemNumber.isFetching,
  isLoaded: state.lsPosItemNumber.isLoaded,
  error: state.lsPosItemNumber.errorMessage,
  itemDetail: state.lsPosItemNumber.itemDetail,
  itemType: state.lsPosItemNumber.itemType,
  productPlatform:state.lsPosItemNumber.productPlatform,
  productSubCategory: state.lsPosItemNumber.productSubCategory,
  temperature: state.lsPosItemNumber.temperature,
  uom: state.lsPosItemNumber.uom,
  storeGroups: state.lsPosItemNumber.storeGroups,
  statuses: state.lsPosItemNumber.statuses,
  isSaved: state.lsPosItemNumber.isSaved,
  //moreInformation: state.lsPosItemNumber.moreInformation,
  //statusDescription: state.lsPosItemNumber.statusDescription,
  //sbuxItemNumber: state.lsPosItemNumber.sbuxItemNumber,
  //sbuxItemDescription: state.lsPosItemNumber.sbuxItemDescription,
});

const mapDispatchToProps = dispatch => {
  return {
    getItemDetail: (id) =>
      dispatch(posItemNumberRequestActions.requestItemDetail(id)),
    saveForm: form => dispatch(posItemNumberRequestActions.saveForm(form)),
   
    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestItemNumberForm,
);
