/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CustomLineChart from '../../CustomCharts/CustomLineChart';
import Table from '../../CustomTables/CustomTable';
import '../demandAndSupply.scss';
import { transposeData } from './CommonCarouselFunctions';
import { doDataExport } from './CommonCarouselFunctions';
import MarketMultiSelectFilter from '../../CustomFilters/MultiSelect/MarketMultiSelectFilter';
import { ExportChart } from '../../Common/Enums/ExportChart';
import { TimeFilterEnum } from '../../Common/Enums/TimeFilterEnum';


const MapeYearlyCarouselChart = (props) => {
  const lines = [
    { dataKey: 'CurrentYear', strokeColor: '#8884d8' },
    { dataKey: 'PreviousYear', strokeColor: '#82ca9d' },

  ];
  const marketIds = props.mbus.map(market => market.marketId);
  const [selectedMarkets, setSelectedMarkets] = useState(marketIds);

  const handleMarketFilterChange = (selectedMarkets) => {
    setSelectedMarkets(selectedMarkets);
  };

  const filters = [
    { key: 'Selected Markets', value: selectedMarkets.map(id => props.mbus?.find(m => m.marketId === id)?.name).join(', ') },
  ];
  
  const tableHeaders = ['Month', 'Current Year (%)', 'Previous Year (%)'];

  let isValid = props.chartData !== undefined &&  props.chartData.length > 0; 

  let monthlyChartData = Array.isArray(props.months) && props.months.map(month => {
    let data = isValid && 
               props.chartData.find(sd => sd.month === month);
    return {
      month: month,
      CurrentYear: data ? data.currentYear : 0,
      PreviousYear: data ? data.previousYear : 0,
    };
  });

  const handleSearchButtonClick = () => {
    props.getYearlyMapeData(selectedMarkets);
  };

  const handleClearFilterClick = () => {
    setSelectedMarkets(marketIds);
    props.getYearlyMapeData(marketIds); 
  };

  const handleExportSummary = () => {
    props.handleExportSummary(ExportChart.ForecastVariance, TimeFilterEnum.YTD, [], selectedMarkets, null);
  };

  return (
    <div>
      <div className="group-details accordion download-container summary forecast-export">
        <div className="accordion-header">
          <h3>Download Summary</h3>
        </div>
        <div className="accordion-body horizontal-flex order-fields">
          <div>
            <button
              className="action export"
              type="button"
              title="Export"
              disabled={false}
              onClick={() => handleExportSummary()}
            >
              <div
                className="icon icon-download-light"
                alt="Export"
              />
            </button>
          </div>
        </div>
      </div>
      <div className='mape-filter-container'>
        <MarketMultiSelectFilter 
          mbuList={props.mbus}
          selectedMarkets={selectedMarkets}
          onMarketFilterChange={handleMarketFilterChange}/>
      </div>
      <div className="kpi-filter-action">
        <button
          type="button"
          className="action"
          onClick={() => handleSearchButtonClick()}
        >
          Search
        </button>
        {<button
          type="clear"
          className="action default"
          onClick={() => handleClearFilterClick()}
        >
          Clear Filter
        </button>}
      </div>
      <div id='yearly-carousel-chart-container'
        className='carousel-chart-container'>
        <CustomLineChart data={monthlyChartData}
          chartTitle={'Forecast Variance YTD'}
          lines={lines}
          xAxisFontSize={16}
          dataKey={'month'}
          width={'100%'}
          showsPercentage={true}  />
      </div><div className='export-button-style'
        style={{ textAlign: 'left' }}>
        <button type="button"
          className="action export"
          onClick={() => doDataExport('Forecast Variance', 'yearly-carousel-chart-container', 'carousel-chart-container', filters)}>Export Chart</button>
      </div><div className='mape-table-container'>
        <Table headers={tableHeaders}
          transposed={true}
          data={transposeData(monthlyChartData)} />

      </div>
    </div>
  );
};
 
export default MapeYearlyCarouselChart;