import React from 'react';
import ReactDOM from 'react-dom';
import { Clusterer } from './clustering/Clusterer.js';
import MarkerKey from '../list/MarkerKey';
import PriorityKey from '../list/PriorityKey';

class MapPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapLoaded: false,

      markers: [],
      equityMarkers: [],
      companyRegionLayer: undefined,
      companyRegions: {},
      priorityRegionAreas: undefined,
      driveThroughMarkers: [],
      clusterer: undefined,
      currentMarker: undefined,
      circle: undefined,

      search: '',
    };
    this.googleMapRef = React.createRef();

    this.handleMarkerDrag = this.handleMarkerDrag.bind(this);

    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);

    this.setMapToPostalCode = this.setMapToPostalCode.bind(this);
    this.setMapToCurrentLocation = this.setMapToCurrentLocation.bind(this);
  }

  componentDidMount() {
    let googleMapScript = document.getElementById('googleMapScript');

    if (!googleMapScript) {
      googleMapScript = document.createElement('script');
      googleMapScript.id = 'googleMapScript';
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${this.props.apiKey}&libraries=visualization`;
      window.document.body.appendChild(googleMapScript);

      googleMapScript.addEventListener('load', () => {
        this.initialiseMap(54.472611, -3.480127, 5);
      });
    } else {
      this.initialiseMap(54.472611, -3.480127, 5);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      mapLoaded,
      currentMarker,
      markers,
      equityMarkers,
      clusterer,
      circle,
      companyRegions,
      companyRegionLayer,
      priorityAreas,
      priorityAreasLayer,
      driveThroughMarkers,
    } = this.state;

    const {
      center: prevCenter,
      radius: prevRadius,
      editable: prevEditable,
      companyTerritory: prevCompanyTerritory,
      filter: prevFilter,
      applicationId: prevApplicationId,
    } = prevProps;

    const {
      mode,
      center,
      radius,
      editable,
      priorityDetail,
      companyTerritory,
      driveThroughDetail,
      equityStores,
      allData,
      filteredData,
      filter,
      loading,
      toggleApplication,
      navigateItemDetails,
      applicationId,
    } = this.props;

    const {
      performanceLogging,
      renderCircle,
      renderCurrentMarker,
      createMarker,
      handleMarkerDrag,
      renderPriorityAreas,
      renderCompanyTerritory,
      renderDriveThroughPlan,
      renderEquityStores,
      renderApplications,
      initialiseMarker,
    } = this;

    if (this.props === prevProps
        && mapLoaded === prevState.mapLoaded
        && mode === prevProps.mode) return;

    let start;

    // Property is backwards - use constant
    const deferDraw = true;

    if (!mapLoaded) return;

    let newState = {};
    let stateChanges = {};
    let isChanged = false;

    console.log(`State : Start ${mode}`);

    // Mode swap - clear down
    const centerMatches = (center && !prevCenter)
                          || (center && prevCenter
                              && center.longitude === prevCenter.longitude
                              && center.latitude === prevCenter.latitude);

    if ((mode !== prevProps.mode
        || (mode === 'detail' && !centerMatches)
        || applicationId !== prevApplicationId)
        && clusterer) {
      start = performanceLogging('Mode Swapped', 'Started');

      if (clusterer) {
        clusterer.removeMarkers(markers, deferDraw);
        clusterer.repaint();
      }

      if (equityMarkers && equityMarkers.length > 0) {
        equityMarkers.forEach((e) => e.setMap(null));
      }

      if (companyRegionLayer) {
        companyRegionLayer.setMap(null);
      }

      if (priorityAreasLayer) {
        priorityAreasLayer.setMap(null);
      }

      if (driveThroughMarkers) {
        driveThroughMarkers.forEach((e) => e.setMap(null));
      }

      if (circle) {
        circle.setMap(null);
      }

      if (currentMarker) {
        currentMarker.setMap(null);
      }

      // CENTER - Zoom
      if (mode === 'list') {
        this.googleMap.setCenter({
          lat: 54.472611, 
          lng: -3.480127,
        });
        this.googleMap.setZoom(5);
      }

      this.setState({
        markers: [],
        equityMarkers: [],
        priorityAreas: [],
        priorityAreasLayer: undefined,
        companyRegionLayer: undefined,
        companyRegions: {},
        driveThroughMarkers: [],
        currentMarker: undefined,
        circle: undefined,
      });

      performanceLogging('Mode Swapped', 'Ended', start);

      // Escape - let the state update trigger the next update
      return;
    }

    // List Mode
    if (mode === 'list' && !loading) {
      start = performanceLogging('List Mode', 'Started');
      // Markers
      stateChanges = renderApplications(
        markers,
        allData,
        filteredData,
        filter,
        prevFilter,
        clusterer,
        this.googleMap,
        createMarker,
        initialiseMarker,
        performanceLogging,
        toggleApplication,
        navigateItemDetails,
        mode,
      );

      if (stateChanges) {
        isChanged = isChanged || stateChanges.updateState;
        newState = { ...newState, markers: stateChanges.data };
      }

      // Priority Areas
      stateChanges = renderPriorityAreas(
        priorityDetail,
        priorityAreasLayer,
        priorityAreas,
        false,
        false,
        this.googleMap,
        performanceLogging,
      );

      if (stateChanges) {
        isChanged = isChanged || stateChanges.updateState;
        newState = { ...newState, priorityAreasLayer: stateChanges.data };
      }

      // Equity Stores
      stateChanges = renderEquityStores(
        equityMarkers,
        equityStores,
        this.googleMap,
        createMarker,
        performanceLogging,
      );

      if (stateChanges) {
        isChanged = isChanged || stateChanges.updateState;
        newState = { ...newState, equityMarkers: stateChanges.data };
      }

      // Drive-Thru Market Plan
      stateChanges = renderDriveThroughPlan(
        driveThroughMarkers,
        driveThroughDetail,
        false,
        this.googleMap,
        performanceLogging,
      );

      // CENTER - Zoom
      if (center && (!centerMatches || !prevCenter)) {
        this.googleMap.setCenter({
          lat: center.latitude, 
          lng: center.longitude,
        });
        this.googleMap.setZoom(17);
      }

      if (stateChanges && stateChanges.updateState) {
        isChanged = true;
        newState = {
          ...newState,
          driveThroughMarkers: stateChanges.data.driveThroughMarkers,
        };
      }

      performanceLogging('List Mode', 'Ended', start);
    }

    // Detail Mode
    if (mode === 'detail' && !loading) {
      start = performanceLogging('Detail Mode', 'Started');
      // Markers
      stateChanges = renderApplications(
        markers,
        allData,
        filteredData,
        filter,
        prevFilter,
        clusterer,
        this.googleMap,
        createMarker,
        initialiseMarker,
        performanceLogging,
        toggleApplication,
        navigateItemDetails,
        mode,
      );

      if (stateChanges) {
        isChanged = isChanged || stateChanges.updateState;
        newState = { ...newState, markers: stateChanges.data };
      }

      // Circle
      stateChanges = renderCircle(
        circle,
        center,
        radius,
        prevCenter,
        prevRadius,
        this.googleMap,
        performanceLogging,
      );

      if (stateChanges) {
        isChanged = isChanged || stateChanges.updateState;
        newState = { ...newState, circle: stateChanges.data };
      }

      // Local application
      stateChanges = renderCurrentMarker(
        currentMarker,
        center,
        editable,
        prevCenter,
        prevEditable,
        this.googleMap,
        createMarker,
        handleMarkerDrag,
        performanceLogging,
      );

      if (stateChanges) {
        isChanged = isChanged || stateChanges.updateState;
        newState = { ...newState, currentMarker: stateChanges.data };
      }

      // Company Territory
      stateChanges = renderCompanyTerritory(
        companyRegions,
        companyRegionLayer,
        companyTerritory,
        prevCompanyTerritory,
        editable,
        prevEditable,
        this.googleMap,
        performanceLogging,
      );

      if (stateChanges) {
        isChanged = isChanged || stateChanges.updateState;
        newState = {
          ...newState,
          companyRegionLayer: stateChanges.data.companyRegionLayer,
          companyRegions: stateChanges.data.companyRegions,
        };
      }

      // Equity Areas
      stateChanges = renderPriorityAreas(
        priorityDetail,
        priorityAreasLayer,
        priorityAreas,
        editable,
        prevEditable,
        this.googleMap,
        performanceLogging,
      );

      if (stateChanges) {
        isChanged = isChanged || stateChanges.updateState;
        newState = { ...newState, priorityAreasLayer: stateChanges.data };
      }

      // Equity Stores
      stateChanges = renderEquityStores(
        equityMarkers,
        equityStores,
        this.googleMap,
        createMarker,
        performanceLogging,
      );

      if (stateChanges) {
        isChanged = isChanged || stateChanges.updateState;
        newState = { ...newState, equityMarkers: stateChanges.data };
      }

      // Drive-Thru Market Plan
      stateChanges = renderDriveThroughPlan(
        driveThroughMarkers,
        driveThroughDetail,
        true,
        this.googleMap,
        performanceLogging,
      );

      if (stateChanges && stateChanges.updateState) {
        isChanged = true;
        newState = {
          ...newState,
          driveThroughMarkers: stateChanges.data.driveThroughMarkers,
        };
      }

      // CENTER - Zoom
      if ((center && !prevCenter) || (center && radius !== prevRadius)) {
        this.googleMap.setCenter({
          lat: center.latitude,
          lng: center.longitude,
        });
        if (radius === 1) {
          this.googleMap.setZoom(13);
        } else if (radius === 2) {
          this.googleMap.setZoom(12);
        } else if (radius >= 3 && radius < 6) {
          this.googleMap.setZoom(11);
        } else if (radius >= 6) {
          this.googleMap.setZoom(10);
        }
      }

      performanceLogging('Detail Mode', 'Ended', start);
    }

    if (isChanged) {
      this.setState(newState);
      console.log('State : Updated');
    }
  }

  createClusterer(markers) {
    return new Clusterer(this.googleMap, markers, {
      imagePath: '/assets/map-icons/m',
    });
  }

  createGoogleMap(latitude, longitude, zoom) {
    return new window.google.maps.Map(this.googleMapRef.current, {
      zoom,
      center: {
        lat: latitude,
        lng: longitude,
      },
      gestureHandling: 'greedy',
      disableDefaultUI: true,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
      },
    });
  }

  createMarker(
    latitude,
    longitude,
    enabled,
    statusId,
    type,
    id,
    title,
    draggable,
  ) {
    return new window.google.maps.Marker({
      position: { lat: latitude, lng: longitude },
      // map: this.googleMap,
      icon: `/assets/map-icons/marker${statusId}.png`,
      type,
      id,
      statusId,
      exclude: !enabled,
      title,
      draggable,
      visible: enabled,
    });
  }

  initialiseMarker(
    d,
    filteredData,
    createMarker,
    map,
    toggleApplication,
    navigateItemDetails,
    mode,
  ) {
    const contentElement = document.createElement('div');

    ReactDOM.render(
      <div>
        <div style={{ display: 'flex' }}>
          <div>
            <img
              src="/assets/map-icons/m1.png"
              style={{ marginRight: '15px' }}
              alt="coffee cup"
            />
          </div>
          <div>
            <div>{d.siteName || ' '}</div>
            <div>{d.format || ' '}</div>
            <div>{d.company || ' '}</div>
            <div>{d.status || ' '}</div>
            <div>{d.postCode || ' '}</div>
          </div>
        </div>
        {d.isAccessible
          && (
            <div className="address-wrapper">
              <button
                id="first-button"
                style={{ marginTop: '5px' }}
                className="action"
                value="navigate"
                type="button"
                onClick={() => navigateItemDetails(d.applicationId)}
              >
              View
              </button>
              <button
                id="second-button"
                style={{ marginTop: '5px', width: '30px' }}
                className="action"
                value="navigate"
                type="button"
                onClick={() => navigateItemDetails(d.applicationId, true)}
              />
            </div>
          )}
      </div>,
      contentElement,
    );

    const infowindow = new window.google.maps.InfoWindow({
      content: contentElement,
    });

    const marker = createMarker(
      d.latitude,
      d.longitude,
      filteredData.some((x) => x.applicationId === d.applicationId),
      d.statusId,
      'application',
      d.applicationId,
      `${d.siteName || ''}\n${d.format || ''}\n${d.company || ''}\n${d.status
        || ''}\n${d.postCode || ''}`,
    );

    marker.addListener('click', () => {
      infowindow.open(map, marker);

      if (mode === 'list') {
        infowindow.addListener('closeclick', () => {
          toggleApplication(d.applicationId, false);
        });

        toggleApplication(d.applicationId, true);
      }
    });

    return marker;
  }

  centerMapOnMarkers(markers) {
    let south;
    let west;
    let north;
    let east = 0;

    markers.forEach((x, i) => {
      if (i === 0) {
        south = x.latitude;
        north = x.latitude;
        east = x.longitude;
        west = x.longitude;
      }

      south = south > x.latitude ? x.latitude : south;
      north = north < x.latitude ? x.latitude : north;

      west = west > x.longitude ? x.longitude : west;
      east = east < x.longitude ? x.longitude : east;
    });

    if (
      markers.length > 0
        && !isNaN(south)
        && !isNaN(north)
        && !isNaN(west)
        && !isNaN(east)
    ) {
      this.googleMap.fitBounds(
        new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(south, west),
          new window.google.maps.LatLng(north, east),
        ),
      );

      if (north === south || east === west) {
        this.googleMap.setZoom(15);
      }
    }
  }

  handleMapClick(e) {
    if (this.props.editable) {
      this.googleMap.setCenter({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });

      this.updateLocation(e.latLng.lat(), e.latLng.lng());
    }
  }

  handleMarkerDrag(currentApplication) {
    if (currentApplication === undefined) {
      currentApplication = this.state;
    }
    this.updateLocation(
      currentApplication.position.lat(),
      currentApplication.position.lng(),
    );
  }

  updateLocation(lat, lng) {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      {
        location: {
          lat,
          lng,
        },
      },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.props.locationSet(lat, lng, results[0]);
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert(`Geocoder failed due to: ${status}`);
        }
      },
    );
  }

  setMapToCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.googleMap.setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        this.googleMap.setZoom(17);
      });
    } else {
      window.alert('Geolocation is not supported by this browser.');
    }
  }

  setMapToPostalCode(postalCode) {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      {
        address: postalCode,
        region: 'uk',
      },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.googleMap.fitBounds(results[0].geometry.bounds);
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('No results found');
        }
      },
    );
  }

  handleTextInputUpdate(e, fieldName) {
    this.setState({ [fieldName]: e.target.value });
  }

  initialiseMap(latitude, longitude, zoom) {
    this.googleMap = this.createGoogleMap(latitude, longitude, zoom);

    const clusterer = this.createClusterer([]);

    this.googleMap.addListener('click', (e) => this.handleMapClick(e));

    this.setState({ mapLoaded: true, clusterer });
  }

  performanceLogging(category, message, previous) {
    const now = new Date();
    let diff = 0;

    if (previous) diff = (now.getTime() - previous.getTime()) / 1000;

    console.log(
      `${now} : ${category} - ${message}${diff ? `${diff}s` : ''}`,
    );
    return now;
  }

  renderCompanyTerritory(
    companyRegions,
    companyRegionLayer,
    companyTerritory,
    prevCompanyTerritory,
    editable,
    prevEditable,
    map,
    performanceLogging,
  ) {
    const start = performanceLogging('Company Regions', 'Started');

    // Remove - Or Hold until load
    if (!companyTerritory || companyTerritory.length === 0) {
      if (companyRegionLayer) {
        companyRegionLayer.setMap(null);

        performanceLogging('Company Regions', 'Ended - Remove', start);
        return {
          updateState: true,
          data: { companyRegionLayer: undefined, companyRegions: undefined },
        };
      }
      return;
    }

    // Add
    if (!companyRegionLayer) {
      companyRegionLayer = new window.google.maps.Data({ map });

      companyRegionLayer.setStyle({
        fillColor: '#329da8',
        strokeWeight: 1,
        fillOpacity: 0.2,
        cursor: 'grab',
      });

      if (editable) {
        companyRegionLayer.addListener('click', (e) => {
          window.google.maps.event.trigger(map, 'click', e);
        });
      }

      companyRegions = {};

      companyTerritory.forEach((r) => {
        companyRegions[r.mapRegionId] = companyRegionLayer.addGeoJson(
          JSON.parse(r.shape),
        );
      });

      performanceLogging('Company Regions', 'Ended - Added', start);
      return {
        updateState: true,
        data: { companyRegionLayer, companyRegions },
      };
    }

    // Update
    if (editable !== prevEditable) {
      if (editable) {
        companyRegionLayer.addListener('click', (e) => {
          window.google.maps.event.trigger(map, 'click', e);
        });
      } else {
        map.event.clearListeners(companyRegionLayer, 'click');
      }
    }

    // TODO in the future company regions may change horizontally.
    // This will need investigating as removing individual features
    // currently causes an exception in google maps requiring the
    // entire layer to be dropped and recreated to change areas.

    performanceLogging('Company Regions', 'Ended', start);
  }

  renderPriorityAreas(
    priorityDetail,
    priorityAreasLayer,
    priorityAreas,
    editable,
    prevEditable,
    map,
    performanceLogging,
  ) {
    const start = performanceLogging('Priority Areas', 'Started');

    // Remove - Or Hold until load
    if (!priorityDetail || priorityDetail.length === 0) {
      if (priorityAreasLayer) {
        priorityAreasLayer.setMap(null);

        performanceLogging('Priority Areas', 'Ended - Remove', start);
        return {
          updateState: true,
          data: undefined,
        };
      }
      return;
    }

    // Add
    if (!priorityAreasLayer) {
      priorityAreasLayer = new window.google.maps.Data({ map });

      //switch based on priority
      priorityAreasLayer.setStyle(function(feature) {
        const priority = feature.getProperty('Preferred_');
        let color = '';

        switch (priority) {
        case '23.5':
          color = '#106b44';
          break;
        case 'Cobra':
          color = '#00ff00';
          break;
        case 'EQ':
          color = '#00ffe1';
          break;
        case 'QW':
          color = '#f7a1ed';
          break;
        case 'CF':
          color = '#7bb3dd';
          break;
        case 'SBUX':
          color = '#00ffe1';
          break;
        case 'P2':
          color = '#a7d0eb';
          break;
        case 'SCH':
          color = '#18167b';
          break;
        case 'K Bev':
          color = '#caa40b';
          break;
        case 'OCO':
          color = '#fffb00';
          break;
        case 'B&S':
          color = '#c52121';
          break;
        case 'MB':
          color = '#ff00cc';
          break;
        case 'EG':
          color = '#244db6';
          break;
        case 'Treats':
          color = '#77c57a';
          break;
        }

        if (color) {
          return {
            fillColor: color,
            fillOpacity: 0.7,
            strokeWeight: 0.5,
          };  
        }

        return {
          fillColor: 'white',
          strokeWeight: 1,
        };
      });

      priorityDetail.forEach((r) => {
        priorityAreasLayer.addGeoJson(
          JSON.parse(r.shape),
        );
      });

      performanceLogging('Priority Areas', 'Ended - Added', start);
      return {
        updateState: true,
        data: priorityAreasLayer,
      };
    }

    performanceLogging('Priority Areas', 'Ended', start);
  }

  renderDriveThroughPlan(
    driveThroughMarkers,
    driveThroughPlans,
    allowClick,
    map,
    performanceLogging,
  ) {
    const start = performanceLogging('Drive-Thru Market Plan', 'Started');

    // Remove - Or Hold until load
    if (!driveThroughPlans || driveThroughPlans.length === 0) {
      if (driveThroughMarkers && driveThroughMarkers.length > 0) {
        driveThroughMarkers.forEach((d) => {
          d.setMap(null);
          window.google.maps.event.clearListeners(d, 'click');
        });

        performanceLogging('Drive-Thru Market Plan', 'Ended - Remove', start);
        return {
          updateState: true,
          data: { driveThroughMarkers: [] },
        };
      }

      return {
        updateState: false,
      };
    }

    // Add
    if (driveThroughPlans && driveThroughPlans.length > 0
        && driveThroughMarkers.length === 0) {
      const newDriveThroughMarkers = driveThroughPlans.map((r) => {
        let fillColor = '';

        switch (r.banding.toLowerCase()) {
        case 'bronze':
          fillColor = '#65391d';
          break;
        case 'gold':
          fillColor = '#af9029';
          break;
        case 'platinum':
          fillColor = '#e5d766';
          break;
        case 'silver':
          fillColor = '#9ba6aa';
          break;
        default:
          fillColor = '#ffffff';
        }

        const newMarker = new window.google.maps.Circle({
          strokeOpacity: 0,
          strokeWeight: 0,
          fillColor,
          fillOpacity: 0.8,
          clickable: allowClick,
          map,
          center: {
            lat: r.latitude,
            lng: r.longitude,
          },
          radius: r.radiusMiles * 1609.344,
        });

        if (allowClick) {
          newMarker.addListener('click', (e) => {
            window.google.maps.event.trigger(map, 'click', e);
          });
        }

        return newMarker;
      });

      performanceLogging('Drive-Thru Market Plan', 'Ended - Added', start);
      return {
        updateState: true,
        data: { driveThroughMarkers: newDriveThroughMarkers },
      };
    }

    // TODO in the future company regions may change horizontally.
    // This will need investigating as removing individual features
    // currently causes an exception in google maps requiring the
    // entire layer to be dropped and recreated to change areas.

    performanceLogging('Drive-Thru Market Plan', 'Ended', start);

    return {
      updateState: false,
    };
  }

  renderEquityStores(
    equityMarkers,
    newEquityStores,
    map,
    createMarker,
    performanceLogging,
  ) {
    const start = performanceLogging('Equity Stores', 'Started');

    // Remove - Or Hold until load
    if (!newEquityStores || newEquityStores.length === 0) {
      if (equityMarkers && equityMarkers.length > 0) {
        equityMarkers.forEach((e) => e.setMap(null));

        performanceLogging('Equity Stores', 'Ended - Remove', start);
        return {
          updateState: true,
          data: [],
        };
      }
      return {
        updateState: false,
      };
    }

    // Update
    if(equityMarkers && newEquityStores && equityMarkers.length !== newEquityStores.length){
      equityMarkers.forEach((e) => e.setMap(null));

      equityMarkers = [];
    }

    // Add
    if (!equityMarkers || equityMarkers.length === 0) {
      equityMarkers = newEquityStores.map((e) => {
        const mark = createMarker(
          e.latitude,
          e.longitude,
          true,
          -1,
          'equity',
          e.equityStoreId,
          e.storeName,
          false,
        );

        // mark.setMap(this.googleMap);

        const infowindow = new window.google.maps.InfoWindow({
          content: `<div style="display:flex;"><div><img src="/assets/map-icons/marker-brand${e.lifecycleStatus}.png" style="margin-right:15px;"/></div><div  style="margin-right:15px;"><div>${e.lifecycleStatus
            || ' '}</div><div>${e.estimatedOpenDate
            ? new Date(e.estimatedOpenDate).toLocaleDateString()
            : ' '
          }</div><div>${e.storeType || ' '}</div><div>${e.storeNumber
            || ' '}</div><div>${e.storeName
            || ' '}</div></div><div><div>${e.addressEnglish
            || ' '}</div><div>${e.cityEnglish || ' '}</div><div>${e.marketName
            || ' '}</div><div>${e.postalCode || ' '}</div></div></div>`,
        });

        mark.addListener('click', () => {
          infowindow.open(map, mark);
        });

        mark.setIcon(`/assets/map-icons/marker-brand${e.lifecycleStatus}.png`);
        return mark;
      });

      equityMarkers.forEach((e) => e.setMap(map));

      performanceLogging('Equity Stores', 'Ended - Added', start);
      return {
        updateState: true,
        data: equityMarkers,
      };
    }
  }

  renderCircle(
    circle,
    center,
    radius,
    prevCenter,
    prevRadius,
    map,
    performanceLogging,
  ) {
    let localCircle = circle;

    const start = performanceLogging('Circle', 'Started');

    // Remove - Or Hold until load
    if (!center) {
      if (localCircle) {
        localCircle.setMap(null);

        performanceLogging('Circle', 'Ended - Remove', start);
        return {
          updateState: true,
          data: undefined,
        };
      }
      return;
    }

    // Add
    if (!localCircle) {
      localCircle = new window.google.maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: '#FF0000',
        fillOpacity: 0,
        map,
        center: {
          lat: center.latitude,
          lng: center.longitude,
        },
        radius: radius * 1609.344,
      });

      localCircle.addListener('click', (e) => {
        window.google.maps.event.trigger(map, 'click', e);
      });

      performanceLogging('Circle', 'Ended - Added', start);
      return {
        updateState: true,
        data: localCircle,
      };
    }

    // Update location
    if (
      (center && !prevCenter)
        || center.latitude !== prevCenter.latitude
        || center.longitude !== prevCenter.longitude
    ) {
      localCircle.setCenter({
        lat: center.latitude,
        lng: center.longitude,
      });
    }

    // Update radius
    if (radius !== prevRadius) {
      localCircle.setRadius(radius * 1609.344);
    }

    performanceLogging('Circle', 'Ended', start);
    return {
      isChanged: false,
      data: localCircle,
    };
  }

  renderCurrentMarker(
    marker,
    center,
    editable,
    prevCenter,
    prevEditable,
    map,
    createMarker,
    handleMarkerDrag,
    performanceLogging,
  ) {
    let localMarker = marker;

    const start = performanceLogging('Current Marker', 'Started');

    // Remove - Or Hold until load
    if (!center) {
      if (localMarker) {
        localMarker.setMap(null);

        performanceLogging('Current Marker', 'Ended - Remove', start);
        return {
          updateState: true,
          data: undefined,
        };
      }

      return;
    }

    // Add
    if (!localMarker) {
      localMarker = createMarker(
        center.latitude,
        center.longitude,
        true,
        '',
        'current',
        '',
        'current',
        editable,
      );

      localMarker.setMap(map);

      if (editable) {
        localMarker.addListener('dragend', () => handleMarkerDrag(localMarker));
      }

      performanceLogging('Current Marker', 'Ended - Added', start);
      return {
        updateState: true,
        data: localMarker,
      };
    }

    // Update location
    if (
      (center && !prevCenter)
        || center.latitude !== prevCenter.latitude
        || center.longitude !== prevCenter.longitude
    ) {
      localMarker.setPosition({
        lat: center.latitude,
        lng: center.longitude,
      });
    }

    if (editable !== prevEditable) {
      if (editable) {
        if (localMarker) {
          localMarker.setDraggable(true);
          localMarker.addListener('dragend', () => handleMarkerDrag(localMarker));
        }
      } else if (localMarker && map && map.event) {
        localMarker.setDraggable(false);
        map.event.clearListeners(localMarker, 'dragend');
      }
    }

    performanceLogging('Current Marker', 'Ended', start);
    return {
      isChanged: false,
      data: localMarker,
    };
  }

  renderApplications(
    markers,
    newAllData,
    filteredData,
    filter,
    prevFilter,
    clusterer,
    map,
    createMarker,
    initialiseMarker,
    performanceLogging,
    toggleApplication,
    navigateItemDetails,
    mode,
  ) {
    const start = performanceLogging('Application Markers', 'Started');
   
    // Remove - Or Hold until load
    if (!newAllData || newAllData.length === 0) {
      if (clusterer && markers && markers.length > 0) {
        clusterer.removeMarkers(markers);

        performanceLogging('Application Markers', 'Ended - Remove', start);
        return {
          updateState: true,
          data: [],
        };
      }
      return;
    }
    if (newAllData.length < markers.length) {
      if (clusterer && markers && markers.length > 0) {
        clusterer.removeMarkers(markers);
        markers = [];
      }
    }

    // Add
    if (!markers || markers.length === 0) {
      markers = newAllData.map((d) => initialiseMarker(
        d,
        filteredData,
        createMarker,
        map,
        toggleApplication,
        navigateItemDetails,
        mode,
      ));

      clusterer.addMarkers(markers);

      performanceLogging('Application Markers', 'Ended - Added', start);
      return {
        updateState: true,
        data: markers,
      };
    }

    // Update - late arrivals
    if (markers.length < newAllData.length) {
      const newMarkers = newAllData
        .filter((d) => !markers.some((m) => m.id === d.applicationId))
        .map((d) => initialiseMarker(
          d,
          filteredData,
          createMarker,
          map,
          toggleApplication,
          navigateItemDetails,
          mode,
        ));

      clusterer.addMarkers(newMarkers);

      markers = markers.concat(newMarkers);

      performanceLogging('Application Markers', 'Ended - Added', start);

      return {
        updateState: true,
        data: markers,
      };
    }

    // Filtering
    if (filter !== prevFilter) {
      // Speedy Set everything to exclude
      if (filteredData.length === 0) {
        markers.forEach((x) => {
          x.set('exclude', true);
          x.setVisible(false);
        });

        // Speedy Set everything to include
      } else if (filteredData.length === newAllData.length) {
        markers.forEach((x) => {
          x.set('exclude', false);
          x.setIcon(`/assets/map-icons/marker${x.get('statusId')}.png`);
          x.setVisible(true);
        });
      } else { // Slow check every marker for include / exclude
        markers.forEach((x) => {
          if (x.get('type') === 'application') {
            const applicationExists = filteredData.some(
              (y) => y.applicationId === x.get('id'),
            );

            x.set('exclude', !applicationExists);
            x.setIcon(`/assets/map-icons/marker${x.get('statusId')}.png`);
            x.setVisible(applicationExists);
          }
        });
      }

      clusterer.repaint();
    }
  }

  render() {
    const {
      mode,
      statuses,
      statusIds,
      canViewPriorityAreas,
      displayOpen,
      displayEquity,
      displayPriorityAreas,
      displayCompanyTerritory,
      driveThrough,
      driveThroughEnabled,
      doToggle,
      doToggleMulti,
      fullScreenMode,
      doFullScreenMode,
      allData,
    } = this.props;
    const {
      search,
    } = this.state;
    const {
      setMapToCurrentLocation,
      handleTextInputUpdate,
      setMapToPostalCode,
    } = this;
    const markerStatusIds = allData.map((k) => k.statusId);

    return (
      <div className={`floating-panel google-panel ${fullScreenMode ? 'full-screen' : ''}`}>
        <MarkerKey
          mode={mode}
          canViewPriorityAreas={ canViewPriorityAreas}
          statuses={statuses.filter((k) => markerStatusIds.indexOf(k.key) > -1)}
          statusIds={statusIds}
          displayOpen={displayOpen}
          displayEquity={displayEquity}
          doToggle={doToggle}
          doToggleMulti={doToggleMulti}
          displayPriorityAreas={displayPriorityAreas}
          displayCompanyTerritory={displayCompanyTerritory}
          driveThrough={driveThrough}
          driveThroughEnabled={driveThroughEnabled}
        />
  
        {displayPriorityAreas ? (
          <PriorityKey
            canViewPriorityAreas={ canViewPriorityAreas}
          />
        ) : null
        }
        <div id="siteidmapcontrols">
          <label>
            <input
              maxLength={100}
              placeholder="Search Google Maps"
              onChange={(e) => handleTextInputUpdate(e, 'search')}
              value={search}
              onKeyDown={(e) => { if (e.key === 'Enter') setMapToPostalCode(search); }}
            />
          </label>
          <button
            onClick={() => setMapToPostalCode(search)}
            className="button action square"
            type="button"
            title="search"
          >
            <div className="icon icon-siteid-search" />
          </button>
          <button
            onClick={() => setMapToCurrentLocation()}
            className="button action square"
            type="button"
            title="Location"
          >
            <div className="icon icon-pin" />
          </button>
        </div>
        <div className="bottom-right-buttons">
          <button
            onClick={() => doFullScreenMode(!fullScreenMode)}
            className="button square"
            type="button"
            title="Fullscreen"
          >
            <div className="icon icon-full-screen" />
          </button>
        </div>
        <div
          id="google-map"
          ref={this.googleMapRef}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    );
  }
}

export default MapPanel;
