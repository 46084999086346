import {
  CONNECT_CONFIGURATION_REQUEST,
  CONNECT_CONFIGURATION_SUCCESS,
  CONNECT_CONFIGURATION_FAILURE,
  CONNECT_CONFIGURATIONSAVE_REQUEST,
  CONNECT_CONFIGURATIONSAVE_SUCCESS,
  CONNECT_CONFIGURATIONSAVE_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const ConnectConfigurationRouter = (
  state = initialState.connectConfigurationState,
  action,
) => {
  switch (action.type) {
  case CONNECT_CONFIGURATION_REQUEST:
  case CONNECT_CONFIGURATIONSAVE_REQUEST: 
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      isSaved: false,
      error: undefined,
      configurationItems: [],
      greenLowerLimit: 0,
      redUpperLimit: 0,
    };
    
  case CONNECT_CONFIGURATION_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      isSaved: false,
      error: undefined,
      configurationItems: action.payload.configurationItems,
      greenLowerLimit: action.payload.greenLowerLimit,
      redUpperLimit: action.payload.redUpperLimit,
    };

    
  case CONNECT_CONFIGURATIONSAVE_SUCCESS: 
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      isSaved: true,
      error: undefined,
      configurationItems: [],
      greenLowerLimit: 0,
      redUpperLimit: 0,
    };
    
  case CONNECT_CONFIGURATION_FAILURE:
  case CONNECT_CONFIGURATIONSAVE_FAILURE: 
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: action.payload.message,
      configurationItems: [],
      greenLowerLimit: 0,
      redUpperLimit: 0,
    };
    
  default:
    return state;
  }
};
export default ConnectConfigurationRouter;
