import React from 'react';

const Notification2 = props => {
  const notification = props.data;
  return (
    <div
      className={`notification-card2 ${
        notification.acknowledged || notification.notificationType === 3  ? '' : 'not-acknowledged'
      }`}>
      <div className="left-holder">
        <h5 className="title">{notification.title}</h5>
      </div>
      <div
        className={`icon-holder  ${notification.sentFrom
          .toLowerCase()
          .replace(' ', '-')}`}>
        <div
          className={`icon ${
            notification.sentFrom === 'Order Management'
              ? 'icon-notification-box'
              : notification.sentFrom === 'Admin'
                ? 'icon-notification-alert'
                : notification.sentFrom === 'Customer Service'
                  ? 'icon-notification-messages'
                  : notification.sentFrom === 'Item Catalogue'
                    ? 'icon-notification-item'
                    : notification.sentFrom === 'Document Library'
                      ? 'icon-notification-document'
                      : notification.sentFrom === 'Quality Awareness'
                        ? 'icon-notification-qa'
                        : notification.sentFrom === 'Pricing Catalogue'
                          ? 'icon-notification-pricing'
                          : notification.sentFrom === 'Forecasting'
                            ? 'icon-notification-forecasting'
                            : notification.sentFrom === 'Orderly Inventory'
                              ? 'icon-notification-orderly'
                              : notification.sentFrom === 'Reports'
                                ? 'icon-notification-report'
                                : notification.sentFrom === 'Policies'
                                  ? 'icon-notification-policy'
                                  : 'icon-notification-messages'
          }`}
        />
      </div>
      <div className="right-holder">
        <div className="message-holder">
          <div className="message">{notification.body}</div>
          <div className="sent-on">{props.vagueDate}</div>{' '}
        </div>
        {(!notification.acknowledged && notification.notificationType !== 3) && (
          <button
            className="edit"
            type="button"
            //ref={ref => this.markAll = ref}
            onClick={() => props.acknowledge()}>
            Mark as read
          </button>
        )}
        {(notification.acknowledged || notification.notificationType === 3) && notification.actionLink && (
          <button
            className="edit action"
            type="button"
            onClick={() => props.navigate()}>
            Take action
          </button>
        )}
      </div>
    </div>
  );
};

export default Notification2;
