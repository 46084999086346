import {
  VASDETAIL_REQUEST,
  VASDETAIL_SUCCESS,
  VASDETAIL_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const vasReducer = (state = initialState.vasListState, action) => {
  switch (action.type) {
  case VASDETAIL_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      vasDetails: [],
      errorMessage: '',
    };
  }
  case VASDETAIL_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      vasDetails: action.payload,
      errorMessage: '',
    };
  }
  case VASDETAIL_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      vasDetails: [],
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default vasReducer;
