import React, { Component } from "react";

class StoreSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: ""
    };
  }

  componentDidMount() {
    const { storeNumber, stores } = this.props;

    const filteredSuggestions = stores.filter(
      store => store.storeNumber.indexOf(storeNumber) > -1
    );

    if (filteredSuggestions.length === 1) {
      this.setState({
        userInput: filteredSuggestions[0].storeName
      });

      this.props.onStoreSelected(
        filteredSuggestions[0].storeNumber
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.storeNumber !== this.props.storeNumber) {
      const { storeNumber, stores } = this.props;

      const filteredSuggestions = stores.filter(
        store => store.storeNumber.indexOf(storeNumber) > -1
      );

      if (filteredSuggestions.length === 1) {
        this.setState({
          userInput: filteredSuggestions[0].storeName
        });
        this.props.onStoreSelected(
          filteredSuggestions[0].storeNumber
        );
      }
    }
  }

  // Event fired when the input value is changed
  onChange = e => {
    const { stores } = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = stores.filter(
      store =>
        store.storeName.toLowerCase().indexOf(userInput.toLowerCase()) > -1 ||
        store.storeNumber.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };

  // Event fired when the user clicks on a suggestion
  onClick = e => {
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });

    this.props.onStoreSelected(e.target.value);
  };

  // Event fired when the user presses a key down
  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion].storeName
      });

      this.props.onStoreSelected(
        filteredSuggestions[activeSuggestion].storeNumber
      );
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    const { onStoreSelected, stores, storeNumber } = this.props;

    if (stores && stores.length <= 10) {
      return (
        <select
          autoFocus
          value={storeNumber}
          onChange={e => {
            onStoreSelected(e.target.value);
            e.preventDefault();
          }}>
          {stores.map(s => (
            <option key={s.storeNumber} value={s.storeNumber}>
              {s.storeName}
            </option>
          ))}
        </select>
      );
    } else {
      let suggestionsListComponent;

      if (showSuggestions && userInput) {
        if (filteredSuggestions.length) {
          suggestionsListComponent = (
            <ul className="suggestions">
              {filteredSuggestions.slice(0, 10).map((store, index) => {
                let className;

                // Flag the active suggestion with a class
                if (index === activeSuggestion) {
                  className = "suggestion-active";
                }

                return (
                  <li
                    className={className}
                    key={store.storeName}
                    onClick={onClick}
                    value={store.storeNumber}>
                    {store.storeName}
                  </li>
                );
              })}
              {filteredSuggestions.length > 10 && <li>...</li>}
            </ul>
          );
        } else {
          suggestionsListComponent = (
            <ul className="suggestions">
              <li>No suggestions, you're on your own!</li>
            </ul>
          );
        }
      }

      return (
        <label className="store-selector">
          <input
            type="text"
            placeholder="Search Customer..."
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={userInput}
          />
          {suggestionsListComponent}
        </label>
      );
    }
  }
}

export default StoreSelector;
