/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import DeleteModal from '../../shared/DeleteModal';

function TabDocuments(props) {
  const [doc, setDoc] = useState({
    mediaName: '',
    mediaType: '',
    mediaData: '',
    formId: 0,
  });
  const [isLoading] = useState(false);
  const [existingForms, setExistingForms] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    fetchDocuments();
  }, []);

  const handleSave = async() => {
    const response = await fetch('/api/OmBookingRequestDocument/v1/uploadorderdocument', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body:JSON.stringify({
        ...doc,
      }),
    });

    if (response.status == 200){
      fetchDocuments();
      setDoc({
        mediaName: doc.mediaName,
        mediaType: doc.mediaType,
        mediaData: doc.mediaData,
        formId: props.formId,
      });
    }
  };

  const handleFileInputUpdate = async(e) => {
    if (!e.target.files || e.target.files.length === 0) return;

    let doc = {
      mediaName: e.target.files[0].name,
      mediaType: e.target.files[0].type,
    };

    var reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }).then(data => {
      doc.mediaData = data ? data.replace(/^data:(.*,)?/, '') : '';

      setDoc({
        mediaName: doc.mediaName,
        mediaType: doc.mediaType,
        mediaData: doc.mediaData,
        formId: props.formId,
      });
    });
  };

  const fetchDocuments = () => {
    async function loadDocuments(){
      const response = await fetch(`/api/OmBookingRequestDocument/v1/GetOrderDocuments?formId=${props.formId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (response.status == 200){
        const data = await response.json();
        setExistingForms(data);
      }
    }
    loadDocuments();
  };

  const handleContinue = (id) => {
    setShowDeleteModal(false);
    handleDelete(id);
  };

  const handleDelete = (docId) => {
    async function loadDocuments(){
      const response = await fetch(`/api/OmBookingRequestDocument/v1/DeleteOrderDocument?id=${docId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (response.status == 200){
        fetchDocuments();
      }

      setDoc({
        mediaName: '',
        mediaType: '',
        mediaData: '',
        formId: 0,
      });
    }
    loadDocuments();
  };

  const handleDownload = (docId) => {
    async function loadDocuments(){
      const response = await fetch(`/api/OmBookingRequestDocument/v1/DownloadDocument?documentId=${docId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
      if (response.status == 200){
        const data = await response.blob();

        let filename = response.headers.get('content-disposition');
        let nameIndex = filename.indexOf('filename="');

        if (nameIndex > -1) {
          filename = filename.slice(nameIndex + 10);
          nameIndex = filename.indexOf('"');

          if (nameIndex > -1) {
            filename = filename.slice(0, nameIndex);
          }
        } else {
          nameIndex = filename.indexOf('filename=');
          if (nameIndex > -1) {
            filename = filename.slice(nameIndex + 9);

            nameIndex = filename.indexOf(';');

            if (nameIndex > -1) {
              filename = filename.slice(0, nameIndex);
            }
          }
        }

        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0);
      }
    }
    loadDocuments();
  };

  const handleShowModal = (id) => {
    setSelectedDocument(id);
    setShowDeleteModal(true);
  };

  return (
    <>
      <div className="form-body document">
        <label className="file-upload-wrapper">
          <div className="icon icon-image-upload"></div>
          <p>
            {!doc.mediaName &&
                  'Tap to upload your document.'}
            {doc.mediaName}
          </p>
          <input
            autoFocus
            name="file"
            id="file"
            className="inputfile"
            type="file"
            onChange={e => handleFileInputUpdate(e)}
          />
        </label>
      </div>
      <div className="form-buttons">
        <button type="button"
          className="action"
          disabled={isLoading}
          onClick={e => handleSave(e)}>
              Upload
        </button>
      </div>
      {existingForms && existingForms.length > 0 &&
      <div className="form-body document">
        <h4 style={{ marginLeft: 0 }}>Existing</h4>
        <div className='documents-wrapper'>
          {existingForms && existingForms.length > 0 && existingForms.map(f => (
            <>
              <div className='document-details'>
                <div className='form-buttons'>
                  <button type="button"
                    className="action negative delete"
                    disabled={isLoading}
                    onClick={() => handleShowModal(f.documentId)}>
                    x
                  </button>
                </div>
                <div className='document-tile-header'>File Name</div>
                <div className='file-title'>{f.title}</div>
                <div className="form-buttons">
                  <button type="button"
                    className="action download"
                    disabled={isLoading}
                    onClick={() => handleDownload(f.documentId)}>
                    <div
                      className="icon icon-download-light"
                      alt="Export"
                    />
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
        {showDeleteModal && (
          <DeleteModal
            cancel={() => setShowDeleteModal(false)}
            data={selectedDocument}
            continue={(id) => handleContinue(id)}
          />
        )}
      </div>}
    </>
  );
}

export default TabDocuments;
