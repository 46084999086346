import {
  ITEMCATALOGUE_REQUEST,
  ITEMCATALOGUE_SUCCESS,
  ITEMCATALOGUE_FAILURE,
  ITEMDETAIL_REQUEST,
  ITEMDETAIL_SUCCESS,
  ITEMDETAIL_FAILURE,
  ITEMCATALOGUEEXPORT_REQUEST,
  ITEMCATALOGUEEXPORT_SUCCESS,
  ITEMCATALOGUEEXPORT_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const itemCatalogueReducer = (
  state = initialState.itemCatalogueState,
  action = {},
) => {
  switch (action.type) {
  case ITEMCATALOGUE_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      maxPages: undefined,
      rowCount: undefined,
      itemList: [],
      errorMessage: '',
    };
  }
  case ITEMCATALOGUE_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      maxPages: action.payload.pageCount,
      itemList: action.payload.data,
      rowCount: action.payload.rowCount,
      errorMessage: '',
    };
  }
  case ITEMCATALOGUE_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      maxPages: undefined,
      rowCount: undefined,
      itemList: [],
      errorMessage: action.payload.message,
    };
  }
  case ITEMDETAIL_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      itemDetail: {},
      errorMessage: '',
    };
  }
  case ITEMDETAIL_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      itemDetail: action.payload,
      errorMessage: '',
    };
  }
  case ITEMDETAIL_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      itemDetail: {},
      errorMessage: action.payload.message,
    };
  }
  case ITEMCATALOGUEEXPORT_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      errorMessage: '',
    };
  }
  case ITEMCATALOGUEEXPORT_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      errorMessage: '',
    };
  }
  case ITEMCATALOGUEEXPORT_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};

export default itemCatalogueReducer;
