import React from 'react';
import { connect } from 'react-redux';
import { customerRequirementsActions } from '../../../../actions/customerRequirementsActions';

class LabelTabOds extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { customerRequirementsDetail, handleTextInputUpdate, handleCheckInputUpdate, handleDocumentFileInputUpdate } = this.props;
    return (
      <>
        <label>
        Case (CS) Labelling
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.caseLabelling}
              onChange={e => handleCheckInputUpdate(e, 'caseLabelling')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
        Inner Pack (IP) Labelling
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.innerPackLabelling}
              onChange={e => handleCheckInputUpdate(e, 'innerPackLabelling')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
        Product/Item (EA) Labelling
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.productLabelling}
              onChange={e => handleCheckInputUpdate(e, 'productLabelling')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
        Market Specific Requirements
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.marketSpecificRequirementsLabel}
              onChange={e => handleCheckInputUpdate(e, 'marketSpecificRequirementsLabel')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
        Details
          <textarea
            maxLength={500}
            type="text"
            value={customerRequirementsDetail && customerRequirementsDetail.otherRequirementsLabel}
            onChange={e => handleTextInputUpdate(e, 'otherRequirementsLabel')}
          />
        </label>
        <div className='form-body'
          style={{ 'marginRight':'0','marginLeft':'0', 'width':'100%' }}>
          <label className='form-label-button-wrap'>
          Document Upload
            <label className="file-upload-wrapper ods-form">
              <div className="icon icon-image-upload"></div>
              <p>
                {!customerRequirementsDetail.documentFileName
                  ? 'Tap to select a file to upload.'
                  : customerRequirementsDetail.documentFileName}
              </p>
              <input
                autoFocus
                required
                name="file"
                id="file"
                className="inputfile"
                type="file"
                onChange={e => handleDocumentFileInputUpdate(e, 'doc')}
              />
            </label>
          </label>             
          <label>
        Document Description
            <textarea
              maxLength={500}
              type="text"
              value={customerRequirementsDetail && customerRequirementsDetail.documentDescription}
              onChange={e => (handleTextInputUpdate(e, 'documentDescription'), this.setState({ documentUpdated: true }))}
            />
          </label>
        
          {customerRequirementsDetail.existingDocument &&
        <label>
          <button className='action'
            type="button"
            style={{ marginRight:'5px' }}
            onClick={() => this.props.downloadDocument(customerRequirementsDetail.storeNumberInternal)}
          >Download</button>
        </label>
          }
        </div>
        <div className='form-body'
          style={{ 'marginRight':'0','marginLeft':'0', 'width':'100%' }}>
          <label className='form-label-button-wrap'>
          Image Upload
            <label className="file-upload-wrapper ods-form">
              <div className="icon icon-image-upload"></div>
              <p>
                {!customerRequirementsDetail.imageFileName
                  ? 'Tap to select a file to upload.'
                  : customerRequirementsDetail.imageFileName}
              </p>
              <input
                autoFocus
                required
                name="file"
                id="file"
                className="inputfile"
                type="file"
                onChange={e => handleDocumentFileInputUpdate(e, 'img')}
              />
            </label>
          </label>
          <label>
        Image Description
            <textarea
              maxLength={500}
              type="text"
              value={customerRequirementsDetail && customerRequirementsDetail.imageDescription}
              onChange={e => (handleTextInputUpdate(e, 'imageDescription'), this.setState({ imageUpdated: true }))}
            />
          </label>
          {customerRequirementsDetail.existingImage &&
        <div>
          <img className="preview"
            src={`/api/CustomerRequirements/v1/GetImage?customerNo=${customerRequirementsDetail.storeNumberInternal}`}
            alt/>
        </div>
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {
    downloadDocument: (storeId) => dispatch(customerRequirementsActions.downloadCustomerRequirementsOdsDocument(storeId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LabelTabOds);
