import React from 'react';

const Notification = props => {
  const notification = props.data;
  return (
    <div
      className={`notification-card ${
        notification.acknowledged ? '' : 'not-acknowledged'
      }`}>
      <div
        className={`icon-holder  ${notification.sentFrom
          .toLowerCase()
          .replace(' ', '-')}`}>
        <div
          className={`icon ${
            notification.sentFrom === 'Order Management'
              ? 'icon-notification-box'
              : notification.sentFrom === 'Admin'
                ? 'icon-notification-alert'
                : notification.sentFrom === 'Customer Service'
                  ? 'icon-notification-messages'
                  : notification.sentFrom === 'Item Catalogue'
                    ? 'icon-notification-item'
                    : notification.sentFrom === 'Document Library'
                      ? 'icon-notification-document'
                      : notification.sentFrom === 'Quality Awareness'
                        ? 'icon-notification-qa'
                        : notification.sentFrom === 'Pricing Catalogue'
                          ? 'icon-notification-pricing'
                          : notification.sentFrom === 'Forecasting'
                            ? 'icon-notification-forecasting'
                            : notification.sentFrom === 'Orderly Inventory'
                              ? 'icon-notification-orderly'
                              : notification.sentFrom === 'Reports'
                                ? 'icon-notification-report'
                                : notification.sentFrom === 'Policies'
                                  ? 'icon-notification-policy'
                                  : 'icon-notification-messages'
          }`}
        />
      </div>
      <div className="notification">
        <div className={notification.notificationType == 0 ? 'sidebar-notif' : ''}
          onClick={notification.notificationType == 0 ? () => {props.acknowledge(); props.navigate();} : null}>
          <h5 className="title">{notification.title}</h5>
          <div className="message">{notification.body}</div>
          <div className="date">{props.vagueDate}</div>
        </div>
     
       
        {notification.actionLink && !notification.acknowledged && window.location.toString().toLowerCase().indexOf(notification.actionLink.toLowerCase()) === -1 && notification.notificationType != 0 && (
          <div className="button-holder">
            <button
              className="edit action"
              type="button"
              onClick={() => {props.navigate(); props.acknowledge();}}>
              Take Action
            </button>
          </div>
        )}

        {!notification.actionLink && !notification.acknowledged && notification.notificationType != 0 && (
          <div className="button-holder">
            <button
              className="edit"
              type="button"
              onClick={() => props.acknowledge()}>
            Mark as read
            </button>
          </div>
        )}

      </div>
      {/*<div className="notification-body">
        {notification.body}
        {!notification.acknowledged && (
          <button
            className="action"
            type="button"
            onClick={() => props.acknowledge()}>
            Acknowledge
          </button>
        )}
        </div>*/}
    </div>
  );
};

export default Notification;
