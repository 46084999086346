import React from "react";

class AssetCategorySaveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: undefined,
      name: "",

      showValidation: false
    };
  }

  componentDidMount() {
    const { id, name } = this.props;

    if (id) {
      this.setState({ id, name })
    }
  }

  handleCategoryNameInputUpdate = e => {
    this.setState({ name: e.target.value });
  };

  handleContinue = e => {
    const { id, name } = this.state;

    if (!name) {
      this.setState({ showValidation: true });
    } else {
      this.props.continue({
        id, name
      });
    }
  };

  render() {
    return (
      <div className="modal-blocker">
        <div className="modal">
          <form>
            <div className="modal-menu">
              <button
                type="button"
                className="action"
                onClick={() => this.props.cancel()}>
                X
              </button>
            </div>
            <div className="modal-title">
              <h2>Category</h2>
            </div>
            <div className="modal-body">
              <label>
                Category Name
                <input
                  autoFocus
                  type="text"
                  required
                  maxLength={200}
                  className={
                    this.state.showValidation && !this.state.name
                      ? "invalid"
                      : ""
                  }
                  value={this.state.name}
                  onChange={e => this.handleCategoryNameInputUpdate(e)}
                />
              </label>
            </div>
            <div className="modal-buttons">
              <button
                type="button"
                className="action"
                onClick={() => this.handleContinue()}>
                Save
              </button>
              <button
                type="button"
                className="action negative"
                onClick={() => this.props.cancel()}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default AssetCategorySaveModal;
