import React from 'react';

class MessageRowAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
    this.handleToggleExpander = this.handleToggleExpander.bind(this);
  }

  handleToggleExpander(expanderName) {
    let state = this.state;
    this.setState({ [expanderName]: !state[expanderName] });
  }

  render() {
    const notification = this.props.data;
    const { isExpanded } = this.state;
    const { onEdit, onDelete } = this.props;
    return (
      <div className="accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('isExpanded')}>
          <h3>{notification.title}</h3>
          <div className="horizontal-flex">
            <button className="edit"
              type="button"
              onClick={() => onEdit()}>
              Edit
            </button>
            <button
              className="edit negative"
              type="button"
              onClick={() => onDelete()}>
              Delete
            </button>

            <div className="date-stamp">{`${new Date(
              notification.sentOn + 'Z',
            ).toLocaleDateString()} ${new Date(
              notification.sentOn + 'Z',
            ).toLocaleTimeString()}`}</div>
            <div
              className={`icon ${
                isExpanded ? 'icon-expand' : 'icon-collapse'
              }`}></div>
          </div>
        </div>
        <div className={`accordion-body ${isExpanded ? '' : 'collapsed'}`}>
          {notification.body}
        </div>
      </div>
    );
  }
}

export default MessageRowAdmin;
