import React from "react";

class FolderSaveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      folderName: "",

      showValidation: false
    };
  }

  componentDidMount() {
    const { folderName } = this.props;

    if (folderName) {
      this.setState({ folderName })
    }
  }

  handleFolderNameInputUpdate = e => {
    this.setState({ folderName: e.target.value });
  };

  handleContinue = e => {
    const { folderName } = this.state;

    if (!folderName) {
      this.setState({ showValidation: true });
    } else {
      this.props.continue({
        folderName
      });
    }
  };

  render() {
    return (
      <div className="modal-blocker">
        <div className="modal">
          <form>
            <div className="modal-menu">
              <button
                type="button"
                className="action"
                onClick={() => this.props.cancel()}>
                X
              </button>
            </div>
            <div className="modal-title">
              <h2>Folder</h2>
            </div>
            <div className="modal-body">
              <label>
                Folder Name
                <input
                  autoFocus
                  type="text"
                  required
                  maxLength={200}
                  className={
                    this.state.showValidation && !this.state.folderName
                      ? "invalid"
                      : ""
                  }
                  value={this.state.folderName}
                  onChange={e => this.handleFolderNameInputUpdate(e)}
                />
              </label>
            </div>
            <div className="modal-buttons">
              <button
                type="button"
                className="action"
                onClick={() => this.handleContinue()}>
                Save
              </button>
              <button
                type="button"
                className="action negative"
                onClick={() => this.props.cancel()}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default FolderSaveModal;
