import {
  EMAILDOCUMENTENTRIES_REQUEST,
  EMAILDOCUMENTENTRIES_SUCCESS,
  EMAILDOCUMENTENTRIES_FAILURE,
  EMAILDOCUMENTENTRY_REQUEST,
  EMAILDOCUMENTENTRY_SUCCESS,
  EMAILDOCUMENTENTRY_FAILURE,
  SAVEEMAILDOCUMENTENTRY_REQUEST,
  SAVEEMAILDOCUMENTENTRY_SUCCESS,
  SAVEEMAILDOCUMENTENTRY_FAILURE,
  DELETEEMAILDOCUMENTENTRY_REQUEST,
  DELETEEMAILDOCUMENTENTRY_SUCCESS,
  DELETEEMAILDOCUMENTENTRY_FAILURE,
} from './actionTypes';

export const emailDocumentManagementActions = {
  requestEntries: (maxRecords) => (dispatch, getState) => {
    dispatch({
      type: EMAILDOCUMENTENTRIES_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/EmailDocumentManagement/v1/GetAllEmailEntries?maxRecords=${maxRecords}`,
        successAction: EMAILDOCUMENTENTRIES_SUCCESS,
        failureAction: EMAILDOCUMENTENTRIES_FAILURE,
      },
    });
  },
  requestEntry: id => (dispatch, getState) => {
    dispatch({
      type: EMAILDOCUMENTENTRY_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/EmailDocumentManagement/v1/GetEmailEntry/${id}`,
        successAction: EMAILDOCUMENTENTRY_SUCCESS,
        failureAction: EMAILDOCUMENTENTRY_FAILURE,
      },
    });
  },
  saveEntry: entry => (dispatch, getState) => {
    dispatch({
      type: SAVEEMAILDOCUMENTENTRY_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/EmailDocumentManagement/v1/save',
        successAction: SAVEEMAILDOCUMENTENTRY_SUCCESS,
        failureAction: SAVEEMAILDOCUMENTENTRY_FAILURE,
        body: {
          ...entry,
        },
      },
    });
  },
  deleteEntry: id => (dispatch, getState) => {
    dispatch({
      type: DELETEEMAILDOCUMENTENTRY_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: `/api/EmailDocumentManagement/v1/delete/${id}`,
        successAction: DELETEEMAILDOCUMENTENTRY_SUCCESS,
        failureAction: DELETEEMAILDOCUMENTENTRY_FAILURE,
      },
    });
  },
};
