/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

function OtherHandlingTab(props) {
  const [totalHandling, setTotalHandling] = useState(0.00);
  const formDetail = props.formDetail;


  useEffect(() => {
    calculateTotalHandling();
  }, [formDetail]);

  const calculateTotalHandling = () => {
    let calculation = 0;

    calculation = (Number(formDetail.dthc) + Number(formDetail.othc) + Number(formDetail.other)
     + Number(formDetail.documentFee)
    );

    props.handleSetTotalHandling(calculation > 0 ? calculation.toFixed(2) : 0);
  };
  return (
    <>
      <div className="form-body">
        <label>
            Total Handling Cost (USD)
          <input
            disabled
            type='number'
            value={props.totalHandling}
          />
        </label>
      </div>
      <div className='form-body'>
        <label>
              DTHC (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'dthc')}
            type='number'
            value={formDetail.dthc}
          />
        </label>
        <label>
            OTHC (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othc')}
            type='number'
            value={formDetail.othc}
          />
        </label>
        <label>
             Other (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'other')}
            type='number'
            value={formDetail.other}
          />
        </label>
        <label>
             Document Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'documentFee')}
            type='number'
            value={formDetail.documentFee}
          />
        </label>
      </div>
    </>
  );
}

export default OtherHandlingTab;
