import React from 'react';
import './ProgressState.scss';

export default ({ progressStage, numberOfBoxes }) => {
  return (
    <div className="progress-state">
      { new Array(numberOfBoxes).fill(0).map((a,b) => 
        <div key={b}
          className={`progress-update ${progressStage > b ? 'progressed' : ''}`}>{b + 1}</div>,
      )
      }
    </div>
  );
};
