import React from "react";
import { connect } from "react-redux";
import { navigationActions } from "../../actions/navigationActions";
import { instantMessagingActions } from "../../actions/messageActions";
import ChatRoomTile from "./shared/chatRoomTile";

class InstantMessaging extends React.Component {
  constructor(props) {
    super(props);
    this.state = { message: "" };

    this.handleJoinChat = this.handleJoinChat.bind(this);
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
  }

  componentDidMount() {
    this.props.connect();
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.connected !== this.props.connected) {
      if (this.props.connected) {
        this.props.fetchChatRooms();
      } else if (this.props.connected === false) {
        this.props.connect();
      }
    }
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({ block: "end" });
      this.chatEnd.scrollIntoView({ block: "end" });
    }
  };

  componentWillUnmount() {
    this.props.disconnect();
  }

  handleJoinChat(chatRoomId) {
    this.props.joinChatRoom(chatRoomId);
  }

  handleSendMessage(conversationId, message) {
    if (message) {
      this.props.sendMessage(conversationId, message);
      this.setState({ message: "" });
    }
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  render() {
    const { chatRooms, currentChatRoom } = this.props;
    const { message } = this.state;
    const { handleJoinChat, handleSendMessage, handleTextInputUpdate } = this;

    let conversation =
      currentChatRoom.conversations.length > 0
        ? currentChatRoom.conversations[0]
        : undefined;

    return (
      <div className="instant-messaging-admin-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-orderly"></div>
              <div>
                <h2>Instant Messaging</h2>
                <p>
                  IM is available from 08:00 – 15:00 CET Mon – Fri{" "}
                  <a target="blank" href="https://www.gov.uk/bank-holidays">
                    (excluding UK public holidays)
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
        {!currentChatRoom.active ? (
          <div className="chat-room-container">
            {chatRooms.map(c => (
              <ChatRoomTile
                key={c.chatRoomId}
                title={c.name}
                iconClass="icon-messages"
                description={c.email}
                active={c.active}
                activeAction={() => handleJoinChat(c.chatRoomId)}
                inactiveAction={() => { }}
              />
            ))}
          </div>
        ) : (
            <div className="floating-panel">
              <div className="floating-header">
                <h2>{currentChatRoom.name}</h2>
                <button
                  type="button"
                  className="float bad"
                  onClick={() => { this.props.disconnect(); }}>
                  Leave
              </button>
              </div>
              <div className="floating-body">
                {conversation ? (
                  <div className="conversation">
                    <div className="conversation-text">
                      {conversation &&
                        conversation.messages.map((c, i) => (
                          <div
                            key={i}
                            className={`message ${c.sender ? "sender" : "receiver"
                              }`}>
                            <div className="avatar ">
                              <div
                                className={`icon ${c.sender ? "icon-admin" : "icon-messages"
                                  }`}></div>
                            </div>
                            <div className="message-text-container">
                              <div className="message-text">{c.text}</div>
                              <div className="message-timestamp">
                                {new Date(c.sent).toLocaleTimeString()}
                              </div>
                            </div>
                          </div>
                        ))}
                      <div
                        ref={el => {
                          this.messagesEnd = el;
                        }}></div>
                    </div>
                    <div className="conversation-controls">
                      <input
                        value={message}
                        onChange={e => handleTextInputUpdate(e, "message")}
                        onKeyDown={e =>
                          e.key === "Enter"
                            ? handleSendMessage(
                              conversation.conversationId,
                              message
                            )
                            : null
                        }
                      />
                      <button
                        type="button"
                        className="float"
                        onClick={() =>
                          handleSendMessage(conversation.conversationId, message)
                        }>
                        Send
                    </button>
                    </div>
                    <div
                      ref={el => {
                        this.chatEnd = el;
                      }}></div>
                  </div>
                ) : (
                    <div className="conversation">
                      <div className="status">
                        Please wait while we find an agent to assist you.
                  </div>
                    </div>
                  )}
              </div>
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    connected: state.message.connected,
    chatRooms: state.message.chatRooms,
    currentChatRoom: state.message.currentChatRoom
  };
};

const mapDispatchToProps = dispatch => ({
  connect: () => dispatch(instantMessagingActions.connect()),
  disconnect: () => dispatch(instantMessagingActions.disconnect()),

  fetchChatRooms: () => dispatch(instantMessagingActions.fetchChatRooms()),
  joinChatRoom: chatRoomId =>
    dispatch(instantMessagingActions.joinChatRoom(chatRoomId)),
  sendMessage: (conversationId, message) =>
    dispatch(instantMessagingActions.sendMessage(conversationId, message)),

  push: path => dispatch(navigationActions.pushNavigation(path)),
  replace: path => dispatch(navigationActions.replaceNavigation(path)),
  reset: () => dispatch(navigationActions.resetNavigation()),
  goBack: () => dispatch(navigationActions.backNavigation())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstantMessaging);
