import React from 'react';

const KpiConfigurationItem = (props) => {
  const {
    item,
    onItemInputChanged,
  } = props;

  switch (item.name.toString().toLowerCase()) {
  case 'green': {
    return (
      <div className="item">
        <label className="name">{item.name} (Target Line)</label>
        <div className="helper">x &ge;</div>
        <div className="value">
          <div className="form-control">
            <input
              type="number"
              className="form-control"
              value={item.lowerLimit}
              onChange={(e) => 
                onItemInputChanged(item.name, {
                  ...item,
                  lowerLimit: Number(e.target.value),
                })}
            />
          </div>
        </div>
      </div>
    );
  }
  case 'orange': {
    return (
      <div className="item">
        <label className="name">{item.name}</label>
        <div className="value">
          <div className="form-control">
            <input
              type="number"
              className="form-control"
              value={item.lowerLimit}
              onChange={(e) => 
                onItemInputChanged(item.name, {
                  ...item,
                  lowerLimit: Number(e.target.value),
                })}
            />
          </div>
          <div className="helper">&le; x &lt;</div>
          <div className="form-control">
            <input
              type="number"
              className="form-control"
              value={item.upperLimit}
              onChange={(e) => 
                onItemInputChanged(item.name, {
                  ...item,
                  upperLimit: Number(e.target.value),
                })}
            />
          </div>
        </div>
      </div>
    );
  }
  case'red': {
    return (
      <div className="item">
        <label className="name">{item.name}</label>
        <div className="helper">x &lt;</div>
        <div className="value">
          <div className="form-control">
            <input
              type="number"
              className="form-control"
              value={item.upperLimit}
              onChange={(e) => 
                onItemInputChanged(item.name, {
                  ...item,
                  upperLimit: Number(e.target.value),
                })}
            />
          </div>
        </div>
      </div>
    );
  }
  case'rowcount': {
    return (
      <div className="item">
        <label className="name">Rows per page</label>
        <div className="value">
          <div className="form-control">
            <input
              type="number"
              className="form-control"
              value={item.upperLimit}
              onChange={(e) => 
                onItemInputChanged(item.name, {
                  ...item,
                  upperLimit: Number(e.target.value),
                })}
            />
          </div>
        </div>
      </div>
    );
  }
  default:{
    return null;
  }
  }
};

export default KpiConfigurationItem;
