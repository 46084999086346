import React from 'react';

class PalletTabOds extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { customerRequirementsDetail, handleTextInputUpdate, handleCheckInputUpdate } = this.props;
    return (
      <>
        <label>
      Mode
          <select value={customerRequirementsDetail && customerRequirementsDetail.mode}
            onChange={e => handleTextInputUpdate(e, 'mode')}
          >
            <option value={0}>Select a Mode</option>
            {customerRequirementsDetail.modes && customerRequirementsDetail.modes.map(f => 
              <option key={f.key}
                value={f.key}>{f.value}</option>,
            )}
          </select>
        </label>
        <label>
      Other Mode
          <input
            maxLength={50}
            type="text"
            value={customerRequirementsDetail && customerRequirementsDetail.newMode}
            onChange={e => handleTextInputUpdate(e, 'newMode')}
          />
        </label>
        <label>
      Max pallet (carrier height)
          <input
            maxLength={50}
            type="text"
            value={customerRequirementsDetail && customerRequirementsDetail.maxPallet}
            onChange={e => handleTextInputUpdate(e, 'maxPallet')}
          />
        </label>
        <label>
      Transport Carrier Height
          <input
            maxLength={50}
            type="text"
            value={customerRequirementsDetail && customerRequirementsDetail.transportCarrierHeight}
            onChange={e => handleTextInputUpdate(e, 'transportCarrierHeight')}
          />
        </label>
        <label>
      Max Weight per Transport Carrier
          <input
            maxLength={50}
            type="text"
            value={customerRequirementsDetail && customerRequirementsDetail.maxWeightPerTransportCarrier}
            onChange={e => handleTextInputUpdate(e, 'maxWeightPerTransportCarrier')}
          />
        </label>
        <label>
      Standard FTL or FCL Carrier Type
          <select value={customerRequirementsDetail && customerRequirementsDetail.standardFtlOrFclCarrierType}
            onChange={e => handleTextInputUpdate(e, 'standardFtlOrFclCarrierType')}
          >
            <option value={0}>Select a Standard FTL or FCL Carrier Type</option>
            {customerRequirementsDetail.standardFtlOrFclCarrierTypes && customerRequirementsDetail.standardFtlOrFclCarrierTypes.map(f => 
              <option key={f.key}
                value={f.key}>{f.value}</option>,
            )}
          </select>
        </label>
        <label>
      Temp Controlled
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.tempControlled}
              onChange={e => handleCheckInputUpdate(e, 'tempControlled')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
      Foil Type
          <select value={customerRequirementsDetail && customerRequirementsDetail.foilType}
            onChange={e => handleTextInputUpdate(e, 'foilType')}
          >
            <option value={0}>Select a Foil Type</option>
            {customerRequirementsDetail.foilTypes && customerRequirementsDetail.foilTypes.map(f => 
              <option key={f.key}
                value={f.key}>{f.value}</option>,
            )}
          </select>
        </label>
        <label>
      Security Tape
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.securityTape}
              onChange={e => handleCheckInputUpdate(e, 'securityTape')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
      1 Item per Pallet/In Between Pallet
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.oneItemPerPallet}
              onChange={e => handleCheckInputUpdate(e, 'oneItemPerPallet')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
      1 Item per Layer with no Exceptions
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.oneItemPerLayer}
              onChange={e => handleCheckInputUpdate(e, 'oneItemPerLayer')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
      Pallet Type
          <select value={customerRequirementsDetail && customerRequirementsDetail.palletType}
            onChange={e => handleTextInputUpdate(e, 'palletType')}
          >
            <option value={0}>Select a Pallet Type</option>
            {customerRequirementsDetail.palletTypes && customerRequirementsDetail.palletTypes.map(f => 
              <option key={f.key}
                value={f.key}>{f.value}</option>,
            )}
          </select>
        </label>
        <label>
      Other Pallet Type
          <input
            maxLength={50}
            type="text"
            value={customerRequirementsDetail && customerRequirementsDetail.newPalletType}
            onChange={e => handleTextInputUpdate(e, 'newPalletType')}
          />
        </label>
        <label>
      Heat Treated
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.heatTreated}
              onChange={e => handleCheckInputUpdate(e, 'heatTreated')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
      </>
    );
  }
}

export default PalletTabOds;
