import {
  CONNECT_DASHBOARD_REQUEST,
  CONNECT_DASHBOARD_SUCCESS,
  CONNECT_DASHBOARD_FAILURE,
  CONNECT_USERACTION_REQUEST,
  CONNECT_USERACTION_SUCCESS,
  CONNECT_USERACTION_FAILURE,
  CONNECT_DASHBOARDNOTIFICATIONS_REQUEST,
  CONNECT_DASHBOARDNOTIFICATIONS_SUCCESS,
  CONNECT_DASHBOARDNOTIFICATIONS_FAILURE,
  CONNECT_DASHBOARDTASKS_REQUEST,
  CONNECT_DASHBOARDTASKS_SUCCESS,
  CONNECT_DASHBOARDTASKS_FAILURE,
} from './actionTypes';

export const connectReportingActions = {
  getDashboard: (from, to) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_DASHBOARD_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Connect/Reporting/v1/GetData?fromDate=${new Date(from).toISOString()}${to ? `&toDate=${new Date(to).toISOString()}` : ''}`,
        successAction: CONNECT_DASHBOARD_SUCCESS,
        failureAction: CONNECT_DASHBOARD_FAILURE,
      },
    });
  },
  getDashboardTasks: (from, to) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_DASHBOARDTASKS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Connect/TaskDetail/v1/GetData?fromDate=${new Date(from).toISOString()}${to ? `&toDate=${new Date(to).toISOString()}` : ''}`,
        successAction: CONNECT_DASHBOARDTASKS_SUCCESS,
        failureAction: CONNECT_DASHBOARDTASKS_FAILURE,
      },
    });
  },
  getDashboardNotifications: (from, to) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_DASHBOARDNOTIFICATIONS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Notification/v1/GetData?fromDate=${new Date(from).toISOString()}${to ? `&toDate=${new Date(to).toISOString()}` : ''}`,
        successAction: CONNECT_DASHBOARDNOTIFICATIONS_SUCCESS,
        failureAction: CONNECT_DASHBOARDNOTIFICATIONS_FAILURE,
      },
    });
  },
  logUserAction: (data) => (dispatch, getState) => {
    dispatch({
      type: CONNECT_USERACTION_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/Connect/Reporting/v1/LogAction',
        successAction: CONNECT_USERACTION_SUCCESS,
        failureAction: CONNECT_USERACTION_FAILURE,
        body: {
          ...data,
        },
      },
    });
  },
};
