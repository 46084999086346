import {
  CONNECT_WEATHER_REQUEST,
  CONNECT_WEATHER_SUCCESS,
  CONNECT_WEATHER_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const ConnectWeatherRouter = (
  state = initialState.connectWeatherState,
  action,
) => {
  switch (action.type) {
  case CONNECT_WEATHER_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      error: undefined,
      weather: {},
    };

  case CONNECT_WEATHER_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: undefined,
      weather: action.payload,
    };

  case CONNECT_WEATHER_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: action.payload.message,
      weather: {},
    };

  default:
    return state;
  }
};
export default ConnectWeatherRouter;
