/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef } from 'react';
import CustomBarChart from '../CustomCharts/CustomBarChart';
import forecastPerformanceDashboardActions from '../../../actions/forecastPerformanceDashboardActions';
import { connect } from 'react-redux';
import { TimeFilterEnum } from '../Common/Enums/TimeFilterEnum';
import { doDataExport } from '../DemandAndSupply/CarouselCharts/CommonCarouselFunctions';
import './forecastVsOrder.scss';
import MarketMultiSelectFilter from '../CustomFilters/MultiSelect/MarketMultiSelectFilter';
import PeriodMultiSelectFilter from '../CustomFilters/MultiSelect/PeriodMultiSelectFilter';
import Table from '../CustomTables/CustomTable';
import { ExportChart } from '../Common/Enums/ExportChart';

const ForecastVsOrder = (props) =>{
  const tableHeaders = ['Month', 'Forecast', 'Order'];

  const bars = [
    { dataKey: 'Forecast', strokeColor: '#8884d8' },
    { dataKey: 'Order', strokeColor: '#82ca9d' },
  ]; 
  const marketIds = props.mbuData.map(market => market.marketId);
  const [selectedMarkets, setSelectedMarkets] = useState(marketIds);
  const [periodFilter, setPeriodFilter] = useState(TimeFilterEnum.YTD);
  const [selectedMonths, setSelectedMonths] = useState([]);


  useEffect(() => {
    props.getForecastVsOrderChartData(periodFilter, selectedMonths, selectedMarkets); 
  }, []);

  const months = [
    { key: 10, value: 'October' },
    { key: 11, value: 'November' },
    { key: 12, value: 'December' },
    { key: 1, value: 'January' },
    { key: 2, value: 'February' },
    { key: 3, value: 'March' },
    { key: 4, value: 'April' },
    { key: 5, value: 'May' },
    { key: 6, value: 'June' },
    { key: 7, value: 'July' },
    { key: 8, value: 'August' },
    { key: 9, value: 'September' },
  ];  

  const filters = [
    { key: 'Period', value: periodFilter },
    { key: 'Selected Months', value: selectedMonths.map(id => months.find(month => month.key === id)?.value).join(', ') },
    { key: 'Selected Markets', value: selectedMarkets.map(id => props.mbuData?.find(m => m.marketId === id)?.name).join(', ') },
  ];

  const handlePeriodFilterChange = (newFilter) => {
    setPeriodFilter(newFilter);
  };

  const handleMonthsChange = (selectedMonths) => {
    setSelectedMonths(selectedMonths);
  };

  const handleMarketFilterChange = (selectedMarkets) => {
    setSelectedMarkets(selectedMarkets);
  };

  const monthsArr = ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept'];
  
  let monthlyChartData = Array.isArray(monthsArr) && monthsArr.map(month => {
    let data = Array.isArray(props.forecastVsOrderData) && 
               props.forecastVsOrderData.find(sd => sd.month === month);
    return {
      month: month,
      Forecast: data ? data.forecast : 0,
      Order: data ? data.order : 0,
    };
  });

  function formatNumber(number) {
    if (number >= 1000000) {
      return (number / 1000000).toLocaleString('en', { maximumFractionDigits: 2 }) + 'M';
    } else if (number >= 1000) {
      return (number / 1000).toLocaleString('en', { maximumFractionDigits: 2 }) + 'k';
    }
    return number.toLocaleString('en');
  }

  
  const handleSearchButtonClick = () => {
    props.getForecastVsOrderChartData(periodFilter, selectedMonths, selectedMarkets); 
  };

  const handleClearFilterClick = () => {
    var newPeriod = TimeFilterEnum.YTD;
    var newMonths = [];
    setSelectedMarkets(marketIds);
    setPeriodFilter(newPeriod);
    setSelectedMonths(newMonths);
    props.getForecastVsOrderChartData(newPeriod, newMonths, marketIds); 
  };

  function transposeData(data) {
    if (!Array.isArray(data)) {
      throw new TypeError('Data must be an array');
    }
    if (data.length === 0) {
      return [];
    }
    const keys = Object.keys(data[0]);
    if (!keys.every(key => data.every(item => key in item))) {
      throw new TypeError('All objects in data must have the same keys');
    }
    // Apply formatNumber to each numerical value in the data
    const formattedData = data.map(item => {
      const formattedItem = {};
      keys.forEach(key => {
        if (typeof item[key] === 'number') {
          formattedItem[key] = formatNumber(item[key]);
        } else {
          formattedItem[key] = item[key];
        }
      });
      return formattedItem;
    });
  
    // Transpose the formatted data
    return keys.map(key => formattedData.map(item => item[key]));
  }

  const handleExportSummary = () => {
    props.exportRawData(ExportChart.ForecastVsOrder,periodFilter,selectedMonths,selectedMarkets);
  };

  return <>
  
    <div className="group-details accordion download-container summary forecast-export">
      <div className="accordion-header">
        <h3>Download Summary</h3>
      </div>
      <div className="accordion-body horizontal-flex order-fields">
        <div>
          <button
            className="action export"
            type="button"
            title="Export"
            disabled={false}
            onClick={() => handleExportSummary()}
          >
            <div
              className="icon icon-download-light"
              alt="Export"
            />
          </button>
        </div>
      </div>
    </div>
    <div className='filter-container'>
      <MarketMultiSelectFilter 
        mbuList={props.mbuData}
        selectedMarkets={selectedMarkets}
        onMarketFilterChange={handleMarketFilterChange}/>
      <PeriodMultiSelectFilter        
        periodFilter={periodFilter}
        selectedMonths={selectedMonths}
        onPeriodFilterChange={handlePeriodFilterChange}
        onSelectedMonths={handleMonthsChange}
        orderMetrics={false} />
    </div>

    <div className="kpi-filter-action">
      <button
        type="button"
        className="action"
        onClick={() => handleSearchButtonClick()}
      >
          Search
      </button>
      {<button
        type="clear"
        className="action default"
        onClick={() => handleClearFilterClick()}
      >
          Clear Filter
      </button>}
    </div>
  
    <div id='forecastVsOrder'
      className='forecast-chart-container'>
      <CustomBarChart data={monthlyChartData} 
        chartTitle={'Total Forecast vs Order cost'}
        bars={bars} 
        xAxisFontSize={16}
        dataKey={'month'}
        width={'100%'} 
        showsPercentage={false}/>
    </div>

    <div className='export-button-style'>
      <button type="button"
        className="action export"
        onClick={() => doDataExport('Forecast Vs Order', 'forecastVsOrder', 'forecast-chart-container', filters)}>Export Chart</button>
    </div>

    <div className='order-table-container'>
      <Table headers={tableHeaders} 
        transposed={true}
        data={transposeData(monthlyChartData)} />
    </div>
  </>;
};

const mapStateToProps = (state) => {
  return {
    forecastVsOrderData: state.forecastPerformanceDashboard.forecastVsOrderData,
  };
};
 

const mapDispatchToProps = (dispatch) => {
  return {
    getForecastVsOrderChartData: (timeFilter, selectedMonths, selectedMarkets) => 
      dispatch(forecastPerformanceDashboardActions.getForecastVsOrderChartData(timeFilter, selectedMonths, selectedMarkets)),
    exportRawData: (exportChart, timeFilter, selectedMonths, selectedMarkets) => 
      dispatch(forecastPerformanceDashboardActions.exportRawData(exportChart, timeFilter, selectedMonths, selectedMarkets)),
  };
};
 

export default connect(mapStateToProps, mapDispatchToProps)(ForecastVsOrder);