import React from 'react';

const HeaderPanel = (props) => {
  const {
    pageTitle, pageSubtitle, pageSubtitle2, iconName, children, pagingControl,
  } = props;

  return (
    <div className="header-panel floating-panel">
      <div className="floating-header">
        <div>
          <div className="icon-panel">
            <div className={`icon ${iconName}`} />
            <div>
              <h2>{pageTitle}</h2>
              {pageSubtitle && <h2>{pageSubtitle}</h2>}
              {pageSubtitle2 && <h2>{pageSubtitle2}</h2>}
            </div>

          </div>
          {pagingControl || null}
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default HeaderPanel;
