import React from 'react';
const ReactMarkdown = require('react-markdown');

function BrfAudit(props) {
  return (
    <div className='form-container audit-history'>
      <h4 className="header-order">Audit History</h4>
      <div className="form-body">
        {props.data.map((d) => (<div key={d}>
          <h5 className="section-header"><span>{d.triggeredBy}</span> <span>{new Date(d.auditDate + 'Z').toLocaleString()}</span></h5>
          <ReactMarkdown source={d.description}
            skipHtml={true}
            className="content-preview"
            linkTarget={'_blank'} />
        </div>))}
      </div>
    </div>
  );
}



export default BrfAudit;
