import React from 'react';

class EmailRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onEdit } = this.props;
    const entry = this.props.data;
    return (
      <tr >
        <td >{entry.subject}</td>
        <td >{entry.sourceEmailAddress}</td>
        <td >{entry.fileName}</td>
        <td >{entry.documentFolder}</td>
        <td className="right-cell">
          <button className="button action"
            type="button"
            onClick={() => onEdit()}>
              Edit
          </button>
        </td>
      </tr>
    );
  }
}

export default EmailRow;
