/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { emailDistributionListActions } from '../../../actions/emailDistributionListActions';
import EmailDistributionGroup from './emailDistributionGroup/EmailDistributionGroup';

class EmailDistributionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showValidation: false,
      portalNotifGroupOne: false,
      portalNotifGroupTwo: false,
      emailInputGroupOne: '',
      emailInputGroupTwo: '',
      newTableRows:[],
      existingEmails:[],
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleGroupUpdate = this.handleGroupUpdate.bind(this);
  }

  componentDidMount(){
    const { getDetail, getEmailDistributionMarkets } = this.props;
    getDetail();
    getEmailDistributionMarkets();
  }

  componentDidUpdate(prevProps, prevState){
    if (this.props.isLoaded && !prevProps.isLoaded)
    {
      this.setState({
        existingEmails: this.props.existingEmails.map( e =>
        {
          return { 
            ...e, emailDistributionLists: e.emailDistributionLists && e.emailDistributionLists.map( d =>
            {
              return { ...d };
            },
          
            ) };
        },
        ),
      });  
    }
  }

  handleGroupUpdate(newGroupData){
    const { existingEmails } = this.state;

    const filteredList = existingEmails.filter(e => e.emailDistributionGroupId !== newGroupData.emailDistributionGroupId);

    this.setState({ existingEmails: [...filteredList, newGroupData] });
  }

  handleSave(){
    const { existingEmails } = this.state;

    let groups = existingEmails;
    let object = { groups };
    this.props.save(object);
    this.props.goBack();
  }

  render() {

    const {
      existingEmails,
    } = this.state;

    const {
      freights,
      markets,
      isSaving,
    } = this.props;

    const {
      handleGroupUpdate,
      handleSave,
    } = this;

   
    return (
      
      <div className="edit-email-page page">
        <div className="floating-panel">
         
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-form"></div>
              <div>
                <h2>Email Notifications</h2>
                <p>Use the following fields to control the notification path for each email type. You can also configure the recipients for each type of email.</p>
              </div>
            </div>
            <div className="button-container">
              <button 
                className="action"
                type="button"
                disabled={isSaving}
                onClick={() => handleSave() }
              >
                {isSaving
                  ? 'Saving...'
                  : 'Save'}
                
              </button>
              
            </div> 
          </div>  
        </div>     
      
        {existingEmails &&
          existingEmails.sort((a, b) => a.emailDistributionGroupId - b.emailDistributionGroupId).map(e => 
            <div key={e.emailDistributionGroupId}>
              <EmailDistributionGroup
                group={e}
                markets={markets}
                freights={freights}
                handleGroupUpdate={handleGroupUpdate}
              />
            </div>,         
          )
        }      
      </div>   
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.emailDistributionList.isLoading,
    isLoaded: state.emailDistributionList.isLoaded,
    isSaving: state.emailDistributionList.isSaving,
    isSaved: state.emailDistributionList.isSaved,
    existingEmails: state.emailDistributionList.emails,
    markets: state.emailDistributionList.markets,
    freights: state.emailDistributionList.detail,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    save: (groups) => dispatch(emailDistributionListActions.save(groups)),
    getDetail: () => dispatch(emailDistributionListActions.getDetail()),
    getEmailDistributionMarkets: () => dispatch(emailDistributionListActions.getEmailDistributionMarkets()),
    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailDistributionList);
