import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../../actions/navigationActions';
import { connectSideBarActions } from '../../../../actions/connectSideBarActions';
import NotificationList from '../../../notifications/NotificationList/NotificationList';
import TaskList from '../../tasks/TaskList/TaskList';
import ConnectWeather from '../connectWeather/connectWeather';
import ConnectEvents from '../connectEvents/connectEvents';
import InstantMessaging from '../../../instantMessaging/InstantMessaging';


class ConnectSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }

      
  /*getNumbers() {
    if (this.props.summary.numbers) {
        return Object.keys(this.props.summary.numbers).map( (key) => {
            return <p>{key} => {this.props.summary.numbers[key]}</p>;
        });
    } else {
        return <p>data is not available</p>;
    }
}*/

  render() {
    const { sideBarOpen, sideBarMode, toggleSideBar, config } = this.props;

    const configItems = config.configurationItems;

    const liveChatConfig = configItems.find(c => c.configurationId == 3);
    const weatherConfig = configItems.find(c => c.configurationId == 2);
    const eventConfig = configItems.find(c => c.configurationId== 1);

    const isLiveChat = liveChatConfig && liveChatConfig.userEnabled;
    const isWeather = weatherConfig && weatherConfig.userEnabled;
    const isEvent = eventConfig && eventConfig.userEnabled;

    const darkTheme = sideBarMode === 'weather' ||
      sideBarMode === 'events' ||
      sideBarMode === 'live-chat';

    return (
      <div className={`connect-sidebar ${sideBarOpen ? 'show' : ''} ${darkTheme ? 'dark' : ''}`}>
        <div className="connect-sidebar-header">
          <button
            type="button"
            className="close-button"
            onClick={() => toggleSideBar(false)}>
            X
          </button>
        </div>
        <div className="connect-sidebar-content">
          <div className={`notifications ${sideBarMode === 'notifications' ? 'show' : 'hide'}`}>
            <h2>Notifications</h2>
            <NotificationList />
          </div>
          <div className={`tasks ${sideBarMode === 'tasks' ? 'show' : 'hide'}`}>
            <h2>Tasks</h2>
            <TaskList />
          </div>
          {isWeather &&
            <div className={`weather ${sideBarMode === 'weather' ? 'show' : 'hide'}`}>
              <ConnectWeather />
            </div>
          }
          {isEvent &&
            <div className={`events ${sideBarMode === 'events' ? 'show' : 'hide'}`}>
              <ConnectEvents />
            </div>
          }
          {isLiveChat &&
            <div className={`live-chat ${sideBarMode === 'live-chat' ? 'show' : 'hide'}`}>
              <InstantMessaging />
            </div>
          }
          
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  sideBarOpen: state.connectSideBar.open,
  sideBarMode: state.connectSideBar.mode,
});

const mapDispatchToProps = dispatch => {
  return {

    toggleSideBar: (show, mode) => dispatch(connectSideBarActions.toggleSideBar(show, mode)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectSideBar);
