import {
  DOCUMENTS_REQUEST,
  DOCUMENTS_SUCCESS,
  DOCUMENTS_FAILURE,
  SAVEFOLDER_REQUEST,
  SAVEFOLDER_SUCCESS,
  SAVEFOLDER_FAILURE,
  UPDATEFOLDER_REQUEST,
  UPDATEFOLDER_SUCCESS,
  UPDATEFOLDER_FAILURE,
  DELETEFOLDER_REQUEST,
  DELETEFOLDER_SUCCESS,
  DELETEFOLDER_FAILURE,
  CREATEDOC_REQUEST,
  CREATEDOC_SUCCESS,
  CREATEDOC_FAILURE,
  UPDATEDOC_REQUEST,
  UPDATEDOC_SUCCESS,
  UPDATEDOC_FAILURE,
  MOVEDOC_REQUEST,
  MOVEDOC_SUCCESS,
  MOVEDOC_FAILURE,
  DELETEDOC_REQUEST,
  DELETEDOC_SUCCESS,
  DELETEDOC_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const documentManagementReducer = (
  state = initialState.documentManagementState,
  action,
) => {
  switch (action.type) {
  case DOCUMENTS_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      errorMessage: '',
      documentList: [],
    };
  }
  case DOCUMENTS_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      errorMessage: '',
      documentList: action.payload,
    };
  }
  case DOCUMENTS_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      errorMessage: action.payload.message,
      documentList: [],
    };
  }
  case SAVEFOLDER_REQUEST:
  case UPDATEFOLDER_REQUEST: {
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      errorMessage: '',
    };
  }
  case SAVEFOLDER_SUCCESS:
  case UPDATEFOLDER_SUCCESS: {
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      errorMessage: '',
    };
  }
  case SAVEFOLDER_FAILURE:
  case UPDATEFOLDER_FAILURE: {
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      errorMessage: action.payload.message,
    };
  }
  case CREATEDOC_REQUEST:
  case UPDATEDOC_REQUEST:
  case MOVEDOC_REQUEST: {
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      errorMessage: '',
    };
  }
  case CREATEDOC_SUCCESS:
  case UPDATEDOC_SUCCESS:
  case MOVEDOC_SUCCESS: {
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      errorMessage: '',
    };
  }
  case CREATEDOC_FAILURE:
  case UPDATEDOC_FAILURE:
  case MOVEDOC_FAILURE:
  {
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      errorMessage: action.payload.message,
    };
  }
  case DELETEFOLDER_REQUEST: {
    return {
      ...state,
      isDeleting: true,
      isDeleted: false,
      errorMessage: '',
    };
  }
  case DELETEFOLDER_SUCCESS: {
    return {
      ...state,
      isDeleting: false,
      isDeleted: true,
      errorMessage: '',
    };
  }
  case DELETEFOLDER_FAILURE: {
    return {
      ...state,
      isDeleting: false,
      isDeleted: false,
      errorMessage: action.payload.message,
    };
  }
  case DELETEDOC_REQUEST: {
    return {
      ...state,
      isDeleting: true,
      isDeleted: false,
      errorMessage: '',
    };
  }
  case DELETEDOC_SUCCESS: {
    return {
      ...state,
      isDeleting: false,
      isDeleted: true,
      errorMessage: '',
    };
  }
  case DELETEDOC_FAILURE: {
    return {
      ...state,
      isDeleting: false,
      isDeleted: false,
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default documentManagementReducer;
