import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { emailDocumentManagementActions } from '../../../actions/emailDocumentManagementActions';
import EmailRow from './emailRow/emailRow';
import DeleteModal from '../../shared/DeleteModal';

class EmailDocumentManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      id: undefined,
    };

    this.handleCreate = this.handleCreate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);
  }

  componentDidMount() {
    this.props.requestEntires();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isDeleted && !prevProps.isDeleted) {
      this.props.requestEntires();
    }
  }

  handleDeleteModalShow(id) {
    this.setState({ showDeleteModal: true, id });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false, id: undefined });
  }

  handleCreate() {
    const { pathname } = this.props.location;

    this.props.push(`${pathname}/0`);
  }

  handleEdit(id) {
    const { pathname } = this.props.location;

    this.props.push(`${pathname}/${id}`);
  }

  handleDelete() {
    this.props.deleteEntry(this.state.id);
    this.handleDeleteModalHide();
  }

  render() {
    let { showDeleteModal } = this.state;
    return (
      <div className="email-document-management page">
        <div className="floating-panel">
          <div className="floating-header-2">
            <div className="header-title">
              <div className="icon icon-alert"></div>
              <h2>Email Document Management</h2>
            </div>
            <div className="header-buttons">
              <button
                className="action"
                type="button"
                onClick={() => this.handleCreate()}>
                Create New
              </button>
            </div>
          </div>
        </div>

        <div className="item-catalogue-container table-container">
          <div className="table-header">
            <div></div>
            <h2>Mapping Rules</h2>
            <div></div>
          </div>
          <div className="table-body">
            <table className={'table-display-mode-2'}>
              <thead className="email-document-management-headers">
                <tr>
                  <th>Subject</th>
                  <th>Sender Email Address</th>
                  <th>Attachment File Name</th>
                  <th>Upload to Document Folder</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.props.entries.map(p => (
                  <EmailRow
                    key={p.id}
                    data={p}
                    onEdit={() => this.handleEdit(p.id)}
                    onDelete={() => this.handleDeleteModalShow()}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <div className="table-header"></div>
        </div>
        <div>
          {showDeleteModal && (
            <DeleteModal
              cancel={this.handleDeleteModalHide}
              continue={this.handleDelete}
            />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  entries: state.emailDocumentManagement.entries,
  isDeleted: state.emailDocumentManagement.isDeleted,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    requestEntires: () => dispatch(emailDocumentManagementActions.requestEntries(500)),
    deleteEntry: id =>
      dispatch(emailDocumentManagementActions.deleteEntry(id)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailDocumentManagement);
