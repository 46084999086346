import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { posItemEmailConfigActions } from '../../../actions/posItemEmailConfigurationActions';

class EmailConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemTypes: [],
      distributionLists: [],
      showValidation: false,
    };

    this.renderItemTypes = this.renderItemTypes.bind(this);
    this.renderDistributionLists = this.renderDistributionLists.bind(this);
    this.handleItemNotificationToggle = this.handleItemNotificationToggle.bind(this);
    this.handleNewEmailAddress = this.handleNewEmailAddress.bind(this);
    this.handleUpdateEmailAddress = this.handleUpdateEmailAddress.bind(this);
    this.handleRemoveEmailAddress = this.handleRemoveEmailAddress.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount(){
    const { getConfiguration } = this.props; 
    getConfiguration();
  }

  componentDidUpdate(prevProps){
    if(this.props.isLoaded && !prevProps.isLoaded){
      this.setState({
        itemTypes: this.props.itemTypes,
        distributionLists: this.props.distributionLists,
      });
    }

    if(this.props.isSaved && !prevProps.isSaved){
      this.props.goBack();
    }
  }

  handleItemNotificationToggle(itemTypeId, notification){
    const { itemTypes } = this.state;

    let currentIndex = itemTypes.findIndex(i => i.id === itemTypeId);

    let currentItem = itemTypes.splice(currentIndex, 1)[0];

    const currentValue = currentItem[notification];

    currentItem = { ...currentItem, [notification]: !currentValue };

    itemTypes.splice(currentIndex, 0, currentItem);
    
    this.setState({ itemTypes: [ ...itemTypes ] });
  }

  handleNewEmailAddress(distributionListId){
    const { distributionLists } = this.state;

    let currentItem = distributionLists.find(d => d.id === distributionListId);

    currentItem.emails = [...currentItem.emails, ''];

    this.setState({ distributionLists: [ ...distributionLists ] });
  }

  handleUpdateEmailAddress(distributionListId, index, value){
    const { distributionLists } = this.state;

    let currentItem = distributionLists.find(d => d.id === distributionListId);

    currentItem.emails[index] = value;

    this.setState({ distributionLists: [ ...distributionLists ] });
  }

  handleRemoveEmailAddress(distributionListId, index){
    const { distributionLists } = this.state;

    var currentItem = distributionLists.find(d => d.id === distributionListId);

    currentItem.emails.splice(index, 1);

    this.setState({ distributionLists: [ ...distributionLists ] });
  }

  handleSave(){
    const { itemTypes, distributionLists } = this.state;
    const { saveConfiguration } = this.props;
    let valid = true;

    for(const list of distributionLists){
      if(list.emails.filter(d => !/\S+@\S+\.\S+/.test(d)).length > 0){
        valid = false;
        break;
      }
    }

    if(!valid){
      this.setState({ showValidation: true });
    }else{
      saveConfiguration({ itemTypes, distributionLists });
    }
  }

  renderDistributionLists(distributionLists){
    const { handleUpdateEmailAddress, handleRemoveEmailAddress, handleNewEmailAddress } = this;
    const { showValidation } = this.state;

    return distributionLists.map(distributionList => 
      <div key={distributionList.id}
        className=  "email">
        <div className= "email-header"> 
          <h5>{`${distributionList.name} Email Distribution List`}</h5>
        </div>
        <div className="itemName">
          <div className= "item-list">
            {distributionList.emails.map((email, idx) =>
              <label key={idx}
                className="wrapper-top">
                <input 
                  className={`label ${showValidation && !/\S+@\S+\.\S+/.test(email) ? 'invalid' : ''}`}
                  type="email"
                  value={email}
                  onChange={(e) => handleUpdateEmailAddress(distributionList.id, idx, e.target.value)}
                />
                <button 
                  className="action negative"
                  onClick={() => handleRemoveEmailAddress(distributionList.id, idx)}
                >X</button>
              </label>,
            )}
          </div>
        </div>
        <button
          type="button"
          className="add"
          onClick={() => handleNewEmailAddress(distributionList.id)}
        >

          {`+ New ${distributionList.name} Email`}
        </button>
      </div>);
  }

  renderItemTypes(itemTypes){
    const { handleItemNotificationToggle } = this;

    return itemTypes.map(itemType => 
      <div key={itemType.id}
        className="item-type">
        <h5>{`${itemType.name} Items`}</h5>
        <div className="itemName">
          <div className= "item-list">
            <label className="wrapper">
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  checked={itemType.categoryNotified}
                  onChange={() => handleItemNotificationToggle(itemType.id, 'categoryNotified')}
                />
                <div className="toggle-icon" />
              </div>
              <div className="label">(1) Category Notified</div>
            </label>
            <label className="wrapper">
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  checked={itemType.categoryApprovalRequired}
                  onChange={() => handleItemNotificationToggle(itemType.id, 'categoryApprovalRequired')}
                />
                <div className="toggle-icon" />
              </div>
              <div className="label">
                    (2) Category Approval Required
              </div>
            </label>
            <label className="wrapper">
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  checked={itemType.mdioNotified}
                  onChange={() => handleItemNotificationToggle(itemType.id, 'mdioNotified')}
                />
                <div className="toggle-icon" />
              </div>
              <div className="label">
                    (3) MDIO Notified
              </div>
            </label>
            <label className="wrapper">
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  checked={itemType.customerNotified}
                  onChange={() => handleItemNotificationToggle(itemType.id, 'customerNotified')}
                />
                <div className="toggle-icon" />
              </div>
              <div className="label">
                    (4) Customer Notified
              </div>
            </label>
            <label className="wrapper">
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  checked={itemType.oracleNotified}
                  onChange={() => handleItemNotificationToggle(itemType.id, 'oracleNotified')}
                />
                <div className="toggle-icon" />
              </div>
              <div className="label">
                    (5) Oracle Notified
              </div>
            </label>
          </div>
        </div>
      </div>,
    );
  }

  render() {
    const {
      isSaving,
    } = this.props;

    const {
      itemTypes,
      distributionLists,
    } = this.state;

    const {
      renderItemTypes,
      renderDistributionLists,
      handleSave,
    } = this;

    return (
      <div className="edit-email-page page">
        <div className="floating-panel">
         
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-form"></div>
              <div>
                <h2>Email Notifications</h2>
                <p>Use the following tick-boxes to control the approval and notification path for each item type. You can also configure the recipients for each type of notification.</p>
              </div>
            </div>
            <div className="button-container">
              <button 
                className="action"
                type="button"
                disabled={isSaving}
                onClick={() => handleSave() }
              >
                {isSaving
                  ? 'Saving...'
                  : 'Save'}
                
              </button>
              
            </div> 
          </div>       
        </div>       
        <div className= "email-settings">
          {renderDistributionLists(distributionLists)}

        </div>
                
        <div className= "settings">
          
          {renderItemTypes(itemTypes)}
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  let { PosItemEmailConfig } = state;
  return {
    isLoading: PosItemEmailConfig.isLoading,
    isLoaded: PosItemEmailConfig.isLoaded,
    isSaving: PosItemEmailConfig.isSaving,
    isSaved: PosItemEmailConfig.isSaved,
    distributionLists: PosItemEmailConfig.distributionLists,
    itemTypes: PosItemEmailConfig.itemTypes,
    errorMessage: PosItemEmailConfig.errorMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getConfiguration: () => dispatch(posItemEmailConfigActions.getConfiguration()),
    saveConfiguration: configuration => dispatch(posItemEmailConfigActions.saveConfiguration(configuration)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfiguration);
