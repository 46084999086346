import React, { useEffect, useState, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import DebouncedInput from 'react-debounce-input';
import orderTrackerActions from '../../../actions/orderTrackerActions';
import { navigationActions } from '../../../actions/navigationActions';
import './BulkUpdate.scss';
import '../../shared/modal.scss';
import StatusDropdown from '../components/StatusDropdown/StatusDropdown';
import { sanitiseNumberInput } from '../utils';

const BulkUpdate = ({ isLoading, isLoaded, error, orders, getOrders, saveOrders, backNavigation, clearState }) => {

  const [selectedOrders, setSelectedOrders] = useState([]);
  const [orderNumberSearch, setOrderNumberSearch] = useState('');
  const [poNumberSearch, setPONumberSearch] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [submitMethod, setSubmitMethod] = useState('submit');
  const formRef = useRef(null);

  const [orderStatus, setOrderStatus] = useState(undefined);
  const [orderProgress, setOrderProgress] = useState([
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ]);
  const [orderProgressChecked, setOrderProgressChecked] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleProgressUpdate = (stage, value) => {
    const progress = orderProgress.slice(0);
    progress[stage] = value.trim();
    setOrderProgress(progress);
  };

  useEffect(() => { clearState(); }, [clearState]);
  useEffect(() => { getOrders(); }, [getOrders]);

  const selectOrder = (orderId) => {
    if (!orderId) {
      return;
    }

    const orders = [
      ...selectedOrders,
      orderId,
    ];
    
    setSelectedOrders(orders);
  };

  const unselectOrder = (orderId) => {
    if (!orderId) {
      return;
    }

    const orders = [
      ...selectedOrders,
    ];

    const itemIndex = orders.indexOf(orderId);
    if (itemIndex === -1) {
      return;
    }
    
    orders.splice(itemIndex, 1);

    setSelectedOrders(orders);
  };

  const selectedOrderDetails = useMemo(
    () => orders ? orders.filter(x => selectedOrders.includes(x.orderId)) : [],
    [selectedOrders, orders],
  );

  const unselectedOrderDetails = useMemo(
    () => orders
      ? orders
        .filter(x => !selectedOrders.includes(x.orderId))
        .filter(x => (orderNumberSearch.length === 0 && poNumberSearch.length === 0)
          || (x.externalOrderNumber.toString().includes(orderNumberSearch) && x.externalPONumber.includes(poNumberSearch)))
      : [],
    [selectedOrders, orders, orderNumberSearch, poNumberSearch],
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedOrders.length === 0) {
      return;
    }

    if (submitMethod === 'preview') {
      handlePreview();
      return;
    }

    saveBulkUpdate();
  };

  const handlePreview = () => {    
    setShowPreview(true);
  };

  const saveBulkUpdate = () => {
    const progressStages = orderProgress.slice(0);
    for (let i = 0; i <= 6; i++) {
      if (!orderProgressChecked[i]) {
        progressStages[i] = undefined;
      }
    }

    saveOrders({
      progressStages,
      orderIds: selectedOrders,
      statusId: orderStatus,
    });

    clearForm();
  };

  useEffect(
    () => {
      if (isLoaded) {
        getOrders();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoaded],
  );

  const handleClear = (e) => {
    e.preventDefault();
    clearForm();
  };

  const clearForm = () => {
    setSelectedOrders([]);
    setOrderProgress([
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ]);
    setOrderProgressChecked([
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]);
    setOrderStatus(undefined);
    setSubmitted(false);
    setOrderNumberSearch('');
    setPONumberSearch('');
    setShowPreview(false);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    backNavigation();
  };

  const handleOrderStatusChange = (e) => {
    const { value } = e.target;
    const parsedValue = parseInt(value);
    if (isNaN(parsedValue)){
      setOrderStatus(undefined);
    } else {
      setOrderStatus(parsedValue);
    }

    if (value === 5 && orderProgress[6] === '') {
      handleProgressUpdate(6, new Date().toISOString().split('T')[0]);
    }
  };

  const handleOrderStatusCheck = (stage, checked) => {
    const progress = orderProgressChecked.slice(0);
    progress[stage] = checked;
    setOrderProgressChecked(progress);
  };

  const getOrderStatusText = (statusId) => {
    switch (statusId) {
    case 1: return 'Cancelled';
    case 2: return 'Request to Cancel';
    case 3: return 'Behind Schedule';
    case 4: return 'On Track';
    case 5: return 'Shipped';
    default: return '';
    }
  };

  return (
    <div className="bulkupdate-container">
      <form
        className={`bulkupdate-form ${submitted ? 'submitted' : ''}`}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <div className="action-container">
          <button
            className="action"
            type="submit"
            disabled={isLoading}
            onClick={() => { setSubmitted(true); setSubmitMethod('submit'); }}
          >
            Submit
          </button>
          <button
            className="action"
            type="submit"
            disabled={isLoading}
            onClick={() => { setSubmitted(true); setSubmitMethod('preview'); }}
          >
            Preview
          </button>
          <button
            className="action negative"
            type="button"
            disabled={isLoading}
            onClick={handleClear}
          >
            Clear
          </button>
          <button
            className="action negative"
            type="button"
            disabled={isLoading}
            onClick={handleCancel}
          >
            Cancel
          </button>
          {isLoaded && !error &&
            <span className="save-result">Orders updated!</span>
          }
          {isLoaded && error &&
            <span className="save-result">{error}</span>
          }
        </div>
        <div className="layout-container">
          <div className="search-container">
            <div className="search-orders group-details accordion">
              <div className="accordion-header">
                <h3>Search and Select</h3>
              </div>
              <div className="accordion-body">
                <div className="search-fields">
                  <label>
                    Starbucks Order Number
                    <DebouncedInput
                      type="text"
                      pattern="^\d*$"
                      onKeyPress={sanitiseNumberInput}
                      minLength={0}
                      debounceTimeout={100}
                      onChange={(e) => setOrderNumberSearch(e.target.value)}
                      value={orderNumberSearch}
                      placeholder="Starbucks Order Number"
                    />
                  </label>
                  <label>
                    Customer PO Number
                    <DebouncedInput
                      type="text"
                      minLength={0}
                      debounceTimeout={100}
                      onChange={(e) => setPONumberSearch(e.target.value)}
                      value={poNumberSearch}
                      placeholder="Customer Number"
                    />
                  </label>
                </div>
                <div className="table-body search-results">
                  <table className="table-display-mode-2">
                    <thead>
                      <tr>
                        <th>Starbucks Order Number</th>
                        <th>Customer PO Number</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {unselectedOrderDetails.map(x => 
                        <tr key={x.orderId}>
                          <td>{x.externalOrderNumber}</td>
                          <td>{x.externalPONumber}</td>
                          <td>
                            <button
                              className="action"
                              type="button"
                              onClick={() => selectOrder(x.orderId)}
                            >
                              Select
                            </button>
                          </td>
                        </tr>,
                      )}
                      {unselectedOrderDetails.length === 0 &&
                        <tr>
                          <td colSpan="999">No orders found.</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="selected-orders group-details accordion">
              <div className="accordion-header">
                <h3>Selection Details</h3>
              </div>
              <div className="accordion-body">
                <div className="table-body">
                  <table className="table-display-mode-2">
                    <thead>
                      <tr>
                        <th>Starbucks Order Number</th>
                        <th>Customer PO Number</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {selectedOrderDetails.map(x => 
                        <tr key={x.orderId}>
                          <td>{x.externalOrderNumber}</td>
                          <td>{x.externalPONumber}</td>
                          <td>
                            <button
                              className="action negative"
                              type="button"
                              onClick={() => unselectOrder(x.orderId)}
                            >
                              Remove
                            </button>
                          </td>
                        </tr>,
                      )}
                      {selectedOrderDetails.length === 0 &&
                        <tr>
                          <td colSpan="999">No orders selected.</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="edit-container">
            <div className="order-status group-details accordion">
              <div className="accordion-header">
                <h3>Change Status</h3>
              </div>
              <div className="accordion-body horizontal-flex">
                <div>
                  <StatusDropdown
                    value={orderStatus}
                    onChange={handleOrderStatusChange}
                  />
                </div>
              </div>
            </div>
            <div className="order-progress group-details accordion">
              <div className="accordion-header">
                <h3>Change Order Progress</h3>
              </div>
              <div className="accordion-body horizontal-flex progress-stages">
                <div className="progress-stage">
                  <label>
                    1. Picking Process
                    <input
                      type="text"
                      maxLength={20}
                      onChange={e => handleProgressUpdate(0, e.target.value)}
                      value={orderProgress[0]}
                      autoComplete="off"
                    />
                  </label>
                  <label>
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={orderProgressChecked[0]}
                        onChange={e => handleOrderStatusCheck(0, e.target.checked)}
                      />
                      <div className="toggle-icon" />
                    </div>
                  </label>
                </div>
                <div className="progress-stage">
                  <label>
                    2. CI Sent
                    <input
                      type="date"
                      onChange={e => handleProgressUpdate(1, e.target.value)}
                      value={orderProgress[1]}
                      autoComplete="off"
                    />
                  </label>
                  <label>
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={orderProgressChecked[1]}
                        onChange={e => handleOrderStatusCheck(1, e.target.checked)}
                      />
                      <div className="toggle-icon" />
                    </div>
                  </label>
                </div>
                <div className="progress-stage">
                  <label>
                    3. HC Requested 4SA
                    <input
                      type="date"
                      onChange={e => handleProgressUpdate(2, e.target.value)}
                      value={orderProgress[2]}
                      autoComplete="off"
                    />
                  </label>
                  <label>
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={orderProgressChecked[2]}
                        onChange={e => handleOrderStatusCheck(2, e.target.checked)}
                      />
                      <div className="toggle-icon" />
                    </div>
                  </label>
                </div>
                <div className="progress-stage">
                  <label>
                    4. Legalization
                    <input
                      type="date"
                      onChange={e => handleProgressUpdate(3, e.target.value)}
                      value={orderProgress[3]}
                      autoComplete="off"
                    />
                  </label>
                  <label>
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={orderProgressChecked[3]}
                        onChange={e => handleOrderStatusCheck(3, e.target.checked)}
                      />
                      <div className="toggle-icon" />
                    </div>
                  </label>
                </div>
                <div className="progress-stage">
                  <label>
                    5. Pickup Request Sent 6FF
                    <input
                      type="date"
                      onChange={e => handleProgressUpdate(4, e.target.value)}
                      value={orderProgress[4]}
                      autoComplete="off"
                    />
                  </label>
                  <label>
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={orderProgressChecked[4]}
                        onChange={e => handleOrderStatusCheck(4, e.target.checked)}
                      />
                      <div className="toggle-icon" />
                    </div>
                  </label>
                </div>
                <div className="progress-stage">
                  <label>
                    6. Pickup Planned
                    <input
                      type="date"
                      onChange={e => handleProgressUpdate(5, e.target.value)}
                      value={orderProgress[5]}
                      autoComplete="off"
                    />
                  </label>
                  <label>
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={orderProgressChecked[5]}
                        onChange={e => handleOrderStatusCheck(5, e.target.checked)}
                      />
                      <div className="toggle-icon" />
                    </div>
                  </label>
                </div>
                <div className="progress-stage">
                  <label>
                    7. Dispatched
                    <input
                      type="date"
                      onChange={e => handleProgressUpdate(6, e.target.value)}
                      value={orderProgress[6]}
                      autoComplete="off"
                    />
                  </label>
                  <label>
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={orderProgressChecked[6]}
                        onChange={e => handleOrderStatusCheck(6, e.target.checked)}
                      />
                      <div className="toggle-icon" />
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showPreview && 
          <div className="modal-blocker">
            <div className="modal">
              <div className="modal-menu">
                <button
                  type="button"
                  className="action"
                  onClick={() => setShowPreview(false)}
                >
                    X
                </button>
              </div>
              <div className="modal-content">
                <div className="modal-title">
                  <h2>Preview</h2>
                </div>
                <div className="modal-body">
                  <div className="table-body">
                    <table className="table-display-mode-2">
                      <thead>
                        <tr>
                          <th colSpan="2">Customer</th>
                          <th className="spacer" />
                          <th>Customer PO Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedOrderDetails.map(x => 
                          <tr key={x.orderId}>
                            <td>{x.storeNumber}</td>
                            <td>{x.storeName}</td>
                            <td className="spacer" />
                            <td>{x.externalPONumber}</td>
                          </tr>,
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="preview-changes">
                    {orderStatus &&
                      <div>
                        <label>
                          Status
                          <input
                            type="text"
                            disabled
                            readOnly
                            value={getOrderStatusText(orderStatus)}
                          />
                        </label>
                      </div>
                    }
                    {orderProgressChecked[0] && 
                      <div className="progress-stage">
                        <label>
                          1. Picking Process
                          <input
                            type="text"
                            disabled
                            readOnly
                            value={orderProgress[0]}
                          />
                        </label>
                      </div>
                    }
                    {orderProgressChecked[1] && 
                      <div className="progress-stage">
                        <label>
                        2. CI Sent
                          <input
                            type="text"
                            disabled
                            readOnly
                            value={orderProgress[1]}
                          />
                        </label>
                      </div>
                    }
                    {orderProgressChecked[2] && 
                      <div className="progress-stage">
                        <label>
                          3. HC Requested 4SA
                          <input
                            type="text"
                            disabled
                            readOnly
                            value={orderProgress[2]}
                          />
                        </label>
                      </div>
                    }
                    {orderProgressChecked[3] && 
                      <div className="progress-stage">
                        <label>
                          4. Legalization
                          <input
                            type="text"
                            disabled
                            readOnly
                            value={orderProgress[3]}
                          />  
                        </label>
                      </div>
                    }
                    {orderProgressChecked[4] && 
                      <div className="progress-stage">
                        <label>
                          5. Pickup Request Sent 6FF
                          <input
                            type="text"
                            disabled
                            readOnly
                            value={orderProgress[4]}
                          />
                        </label>
                      </div>
                    }
                    {orderProgressChecked[5] && 
                      <div className="progress-stage">
                        <label>
                          6. Pickup Planned
                          <input
                            type="text"
                            disabled
                            readOnly
                            value={orderProgress[5]}
                          />
                        </label>
                      </div>
                    }
                    {orderProgressChecked[6] && 
                      <div className="progress-stage">
                        <label>
                          7. Dispatched
                          <input
                            type="text"
                            disabled
                            readOnly
                            value={orderProgress[6]}
                          />
                        </label>
                      </div>
                    }
                  </div>
                </div>
                <div className="modal-buttons">
                  <button
                    type="button"
                    className="action"
                    disabled={isLoading}
                    onClick={() => saveBulkUpdate()}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="action negative"
                    disabled={isLoading}
                    onClick={() => setShowPreview(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.orderTracker.isLoading,
    isLoaded: state.orderTracker.isLoaded,
    error: state.orderTracker.error,
    orders: state.orderTracker.bulkOrderList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrders: () => dispatch(orderTrackerActions.loadBulkOrders()),
    saveOrders: (params) => dispatch(orderTrackerActions.saveBulkOrders(params)),
    backNavigation: () => dispatch(navigationActions.backNavigation()),
    clearState: () => dispatch(orderTrackerActions.clearState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkUpdate);
