import React from 'react';

const UserRecord = props => {
  let { familyName, givenName, tenantId, userId, userName } = props.data;
  let { editAction, deleteAction } = props;

  return (
    <div
      className="user-record"
      data-user-id={userId}
      data-tenant-id={tenantId}>
      <div className="user-top-details">
        <div className="initials">
          {givenName[0]}
          {familyName[0]}
        </div>
        <div>
          <div className="username">{userName}</div>
        </div>
      </div>
      <div className="user-top-buttons">
        <button className="edit"
          type="button"
          onClick={() => editAction()}>
          EDIT
        </button>
        <button
          className="edit negative"
          type="button"
          onClick={() => deleteAction()}>
          DELETE
        </button>
      </div>
    </div>
  );
};

export default UserRecord;
