import React from 'react';

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      searchFilter: '',

    };
    this.handleTextInputSearch = this.handleTextInputSearch.bind(this);
    this.handleValueToggle = this.handleValueToggle.bind(this);
    this.deselectAll = this.deselectAll.bind(this);
    this.selectAll = this.selectAll.bind(
      this,
    );
  }

  handleTextInputSearch(e, values) {
    const update = {};

    update[values] = e.target.value;

    this.setState(update);
  }

  handleValueToggle(e, selectedId) {
    const {
      valueChanged,
      valueName,
      selectedValues,
    } = this.props;
    let newSelectedValues = [...selectedValues];
    if (e.target.checked) {
      newSelectedValues.push(selectedId);
    } else {
      const position = newSelectedValues.indexOf(selectedId);
      if (position > -1) {
        newSelectedValues.splice(position, 1);
      }
    }
    valueChanged(newSelectedValues, valueName);
  }

  selectAll() {
    const {
      valueChanged,
      valueName,
      values,
    } = this.props;
    const {
      searchFilter,
    } = this.state;
    const filteredSearches = values.filter(
      (s) => s.value.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1,
    );
    valueChanged(filteredSearches.map((v) => v.key), valueName);
  }

  deselectAll() {
    const {
      valueChanged,
      valueName,
    } = this.props;
    valueChanged([], valueName);
  }

  render() {
    const {
      title,
      values,
      selectedValues,
    } = this.props;

    const {
      isExpanded,
      searchFilter,
    } = this.state;

    const {
      deselectAll,
      selectAll,
    } = this;

    const filteredSearches = values.filter(
      (s) => s.value.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1,
    );

    return (

      <div className="multi-select-siteid">
        <div className="container-multi">
          <button
            type="button"
            className={isExpanded ? 'closed' : 'expanded'}
            onClick={() => this.setState(
              { isExpanded: !isExpanded },
            )}
          >
            +
          </button>
          <h3 className="multi-title">
            {title}
          </h3>

          <div className={`icon ${selectedValues.length !== values.length ? 'icon-siteid-filter' : ''}`} />
        </div>
        <div className={`selector ${isExpanded ? '' : 'hideSelector'}`}>
          <div className="search-container">

            <input
              className="search"
              type="text"
              placeholder="Search..."
              value={searchFilter}
              onChange={(e) => this.handleTextInputSearch(e, 'searchFilter')}
            />

          </div>
          <div className="select-buttons">
            <button
              type="button"
              className="button-two"
              onClick={(e) => selectAll(e)}
            >
              Select
              All
            </button>
            <button
              type="button"
              className="button-three"
              onClick={(e) => deselectAll(e)}
            >
              Deselect
              All
            </button>
          </div>
          <div className="scroll-container">
            {filteredSearches.map((v) => (
              <div key={v.key}>
                <div className="toggle-container">
                  <div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={selectedValues.indexOf(v.key) > -1}
                        onChange={(e) => this.handleValueToggle(e, v.key)}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                  <div className="option-text">{v.value}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

    );
  }
}

export default MultiSelect;
