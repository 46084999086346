import {
  UPLOADMASTERFILE_REQUEST,
  UPLOADMASTERFILE_SUCCESS,
  UPLOADMASTERFILE_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const masterFileReducer = (
  state = initialState.masterFileState,
  action,
) => {
  switch (action.type) {
  case UPLOADMASTERFILE_REQUEST: {
    return {
      ...state,
      isUploading: true,
      isLoaded: false,
      errorMessage: '',
    };
  }
  case UPLOADMASTERFILE_SUCCESS: {
    return {
      ...state,
      isUploading: false,
      isLoaded: true,
      errorMessage: '',
    };
  }
  case UPLOADMASTERFILE_FAILURE: {
    return {
      ...state,
      isUploading: false,
      isLoaded: false,
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default masterFileReducer;
