import {
  ORDERLYREQUEST_REQUEST,
  ORDERLYREQUEST_SUCCESS,
  ORDERLYREQUEST_FAILURE,
} from './actionTypes';

export const marketingActions = {
  submitOrderlyRequest: contactInformation => (dispatch, getState) => {
    dispatch({
      type: ORDERLYREQUEST_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/marketing/v1/RequestOrderly',
        successAction: ORDERLYREQUEST_SUCCESS,
        failureAction: ORDERLYREQUEST_FAILURE,
        body: {
          ...contactInformation,
        },
      },
    });
  },
};
