import React from 'react';
import { connect } from 'react-redux';
import CustomerRequirementsModal from '../customerRequirementsModal/CustomerRequirementsModal';
import { customerRequirementsActions } from '../../../actions/customerRequirementsActions';
import StoreSelector from '../../shared/storeSelector';

class CustomerRequirementsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storeNumberInternal: '',
      displayModal: false,
      currentTab: 'customer',
    };

    this.handleStoreChange = this.handleStoreChange.bind(this);
    this.handleDisplayModal = this.handleDisplayModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  componentDidMount() {
    const { stores } = this.props;
    let { storeNumberInternal } = this.state;
    if (!storeNumberInternal) {
      if (stores && stores.length > 0) {
        storeNumberInternal = stores[0].storeNumber;
      }
    }

    this.setState({ storeNumberInternal });

    if (storeNumberInternal) {
      this.props.requestCustomerRequirements(storeNumberInternal);
    }
  }

  handleDisplayModal(e) {
    this.setState({ displayModal: true });
  }

  handleHideModal(e) {
    this.setState({ displayModal: false });
  }

  handleChangeTab(tab) {
    this.setState({ currentTab: tab });
  }

  handleStoreChange(storeNumber) {
    this.setState({ storeNumberInternal: storeNumber });

    this.props.requestCustomerRequirements(storeNumber);
  }

  render() {
    const { stores, customerRequirements, isLoaded } = this.props;
    const { storeNumberInternal, displayModal, currentTab } = this.state;

    const { handleStoreChange } = this;
    return (
      <div className="edit-feedback-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-form"></div>
              <h2>Customer Requirements + ODS</h2>
            </div>
            <StoreSelector
              onStoreSelected={handleStoreChange}
              stores={stores}
              storeNumber={
                storeNumberInternal ? storeNumberInternal.toString() : ''
              }
            />
          </div>
        </div>
        {isLoaded && (
          <div className="tab-container">
            <div
              className={`tab-button ${
                currentTab === 'customer' ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('customer')}>
              Customer
              <br />
              Information
            </div>
            <div
              className={`tab-button ${
                currentTab === 'billing' ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('billing')}>
              K+N
              <br />
              Billing Info
            </div>
            <div
              className={`tab-button ${
                currentTab === 'pallet' ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('pallet')}>
              Pallet
              <br />
              Requirements
            </div>
            <div
              className={`tab-button ${
                currentTab === 'booking' ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('booking')}>
              Booking
              <br />
              Requirements
            </div>
            <div
              className={`tab-button ${
                currentTab === 'documentation' ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('documentation')}>
              Documentation
              <br />
              Requirements
            </div>
            <div
              className={`tab-button ${
                currentTab === 'export' ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('export')}>
              Export Doc
              <br />
              Requirements
            </div>
            <div
              className={`tab-button ${
                currentTab === 'label' ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('label')}>
              Label
              <br />
              Requirements
            </div>
            <div
              className={`tab-button ${
                currentTab === 'loading' ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('loading')}>
              Loading
              <br />
              Requirements
            </div>
            <div
              className={`tab-button ${
                currentTab === 'shelf' ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('shelf')}>
              Shelf
              <br />
              Life
            </div>
            <div
              className={`tab-button ${
                currentTab === 'remarks' ? 'selected' : ''
              }`}
              onClick={() => this.handleChangeTab('remarks')}>
              Remarks
            </div>
            <div
              className={`tab-button ${currentTab === 'ods' ? 'selected' : ''}`}
              onClick={() => this.handleChangeTab('ods')}>
              ODS
            </div>
          </div>
        )}
        {isLoaded && customerRequirements && (
          <form className="form-container"
            noValidate
            onSubmit={e => false}>
            {currentTab === 'customer' && (
              <div className="form-header">
                <h3>Customer Information</h3>
              </div>
            )}
            {currentTab === 'billing' && (
              <div className="form-header">
                <h3>K+N billing Info</h3>
              </div>
            )}
            {currentTab === 'pallet' && (
              <div className="form-header">
                <h3>Pallet Requirements</h3>
              </div>
            )}
            {currentTab === 'booking' && (
              <div className="form-header">
                <h3>Booking Requirements</h3>
              </div>
            )}
            {currentTab === 'documentation' && (
              <div className="form-header">
                <h3>Documentation Requirements</h3>
              </div>
            )}
            {currentTab === 'export' && (
              <div className="form-header">
                <h3>Export Documentation Requirements</h3>
              </div>
            )}
            {currentTab === 'label' && (
              <div className="form-header">
                <h3>Label Requirements</h3>
              </div>
            )}
            {currentTab === 'loading' && (
              <div className="form-header">
                <h3>Loading Requirements</h3>
              </div>
            )}
            {currentTab === 'shelf' && (
              <div className="form-header">
                <h3>Shelf Lifes</h3>
              </div>
            )}
            {currentTab === 'remarks' && (
              <div className="form-header">
                <h3>Remarks</h3>
              </div>
            )}
            {currentTab === 'ods' && (
              <div className="form-header">
                <h3>ODS</h3>
              </div>
            )}
            {currentTab === 'customer' && (
              <div className="form-body">
                <label>
                  Customer #
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.customerNumber}
                  />
                </label>
                <label>
                  Store #
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.storeNumber}
                  />
                </label>
                <label>
                  Country{' '}
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.customerCountry}
                  />
                </label>
                <label>
                  Customer Name{' '}
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.customerName}
                  />
                </label>
                <label>
                  Customer Group?{' '}
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.customerGroup}
                  />
                </label>
                <label>
                  EU or NON-EU?
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.customerIsEu}
                  />
                </label>
                <label>
                  Store or Bulk?{' '}
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.storeOrBulk}
                  />
                </label>
              </div>
            )}
            {currentTab === 'billing' && (
              <div className="form-body">
                <label>
                  Billing Group{' '}
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.billingGroup}
                  />
                </label>
                <label>
                  Equity/Non-Equity{' '}
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.equity}
                  />
                </label>
                <label>
                  K+N Caf Received{' '}
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.kncafReceived}
                  />
                </label>
                <label>
                  Transport Combination{' '}
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.transportCombination}
                  />
                </label>
              </div>
            )}
            {currentTab === 'pallet' && (
              <div className="form-body">
                <label>
                  Max pallet (carrier height)
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.maxPallet}
                  />
                </label>
                <label>
                  Transport carrier height (max 250cm)
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.transportCarrierHeight}
                  />
                </label>
                <label>
                  Max Weight (1000KG) per transport carrier
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.maxWeightPerTransportCarrier}
                  />
                </label>
                <label>
                  Carrier Type{' '}
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.carrierType}
                  />
                </label>
                <label>
                  Clear foil / Black Foil (default is clear foil)
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.foilColour}
                  />
                </label>
                <label>
                  Top Foil
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.topFoil}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  K+N Security Tape
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.knsecurityTape}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Packlist per Order/Delivery/Pallet (added physically to
                  shipment by whs)
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.packlistPer}
                  />
                </label>
                <label>
                  1 article per pallet/in between pallet (exception is less than
                  a layer ordered)
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.articlePerPallet}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  1 article per layer (no exceptions)
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.articlePerLayer}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Consolidate orders on customer level or Del. ID
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.consolidateOrdersLevel}
                  />
                </label>
              </div>
            )}
            {currentTab === 'booking' && (
              <div className="form-body">
                <label>
                  Booking Form &amp; CI+PL 24 hours before dispatch (or book in
                  portal)
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={
                        customerRequirements.twentFourHoursBeforeDispatch
                      }
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>

                <label>
                  Booking Form &amp; CI+PL 48 hours before dispatch
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={
                        customerRequirements.fourtyEightHoursBeforeDispatch
                      }
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Booking form &amp; CI+PL 7 days before dispatch
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.sevenDaysBeforeDispatch}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Book in DSV portal
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.dsvPortal}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Book in GIST Portal
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.gistPortal}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Book in UPS portal
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.upsPortal}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Send Stores List (at least 1 day in advance)
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.sendStoresList}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
              </div>
            )}
            {currentTab === 'documentation' && (
              <div className="form-body">
                <label>
                  Commercial invoice per order or truck (default is order)
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.commercialInvoicePer}
                  />
                </label>
                <label>
                  Send Commercial Invoice &amp; Packing list in Excel (default
                  is PDF)
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.sendPaperworkInExcel}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Send hardcopy documents to customer (default is not sending
                  docs)
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={
                        customerRequirements.sendHardcopyDocumentsToCustomer
                      }
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
              </div>
            )}
            {currentTab === 'export' && (
              <div className="form-body">
                <label>
                  Export declaration per order
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.exportDeclarationPerOrder}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Export declaration per truck
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.exportDeclarationPerTruck}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  ATR
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.atr}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Euro 1
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.euro}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Health Certificate
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.healthCertificate}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Chamber of Commerce Attested Invoice
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={
                        customerRequirements.chamberOfCommerceAttestedInvoice
                      }
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  COO/HC/Commercial invoice legalized by consignee Country's
                  embassy
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.invoiceLegalizedByEmbassy}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Certificate of Origin
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.certificateOfOrigin}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  T2LF
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.t2lf}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
              </div>
            )}
            {currentTab === 'label' && (
              <div className="form-body">
                <label>
                  Case (CS) labeling
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.caseLabeling}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Inner pack (IP) labeling
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.innerPackLabeling}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  product(EA) labeling
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.productLabeling}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
              </div>
            )}
            {currentTab === 'loading' && (
              <div className="form-body">
                <label>
                  Take pictures of the pallets and send to CS prior to sending
                  the BF
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.takePicturesOfPallets}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Double stacking of coffee items allowed
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.allowCoffeeDoubleStacking}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Full truck/container vehicle sealing
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.vehicleSealing}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
              </div>
            )}
            {currentTab === 'shelf' && (
              <div className="form-body">
                <label>
                  Sample case food items (1 EA per food item).
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.sampleCaseFoodItems}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  No shelf life extensions allowed!
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={
                        customerRequirements.noShelfLifeExtensionsAllowed
                      }
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  Strict delivery (no older shelf life can be delivered to a
                  customer)
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled="disabled"
                      checked={customerRequirements.strictDelivery}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
              </div>
            )}
            {currentTab === 'remarks' && (
              <div className="form-body">
                <label>
                  Remarks{' '}
                  <textarea
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.remarks}
                  />
                </label>
              </div>
            )}
            {currentTab === 'ods' && (
              <div className="form-body">
                <label>
                  Frequency{' '}
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.frequency}
                  />
                </label>
                <label>
                  Delivery Time{' '}
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.deliveryTime}
                  />
                </label>
                <label>
                  EQ-FRN Priorities{' '}
                  <input
                    type="text"
                    disabled="disabled"
                    value={customerRequirements.eqFrnPriorities}
                  />
                </label>
              </div>
            )}
            {currentTab === 'ods' && (
              <div>
                <h4>ODS Week 1</h4>
                <div className="form-body">
                  <label>
                    Monday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekOneMonday}
                    />
                  </label>
                  <label>
                    Tuesday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekOneTuesday}
                    />
                  </label>
                  <label>
                    Wednesday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekOneWednesday}
                    />
                  </label>
                  <label>
                    Thursday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekOneThursday}
                    />
                  </label>
                  <label>
                    Friday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekOneFriday}
                    />
                  </label>
                  <label>
                    Saturday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekOneSaturday}
                    />
                  </label>
                  <label>
                    Sunday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekOneSunday}
                    />
                  </label>
                </div>
              </div>
            )}
            {currentTab === 'ods' && (
              <div>
                <h4>ODS Week 2</h4>
                <div className="form-body">
                  <label>
                    Monday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekTwoMonday}
                    />
                  </label>
                  <label>
                    Tuesday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekTwoTuesday}
                    />
                  </label>
                  <label>
                    Wednesday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekTwoWednesday}
                    />
                  </label>
                  <label>
                    Thursday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekTwoThursday}
                    />
                  </label>
                  <label>
                    Friday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekTwoFriday}
                    />
                  </label>
                  <label>
                    Saturday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekTwoSaturday}
                    />
                  </label>
                  <label>
                    Sunday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekTwoSunday}
                    />
                  </label>
                </div>
              </div>
            )}
            {currentTab === 'ods' && (
              <div>
                <h4>ODS Week 3</h4>
                <div className="form-body">
                  <label>
                    Monday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekThreeMonday}
                    />
                  </label>
                  <label>
                    Tuesday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekThreeTuesday}
                    />
                  </label>
                  <label>
                    Wednesday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekThreeWednesday}
                    />
                  </label>
                  <label>
                    Thursday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekThreeThursday}
                    />
                  </label>
                  <label>
                    Friday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekThreeFriday}
                    />
                  </label>
                  <label>
                    Saturday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekThreeSaturday}
                    />
                  </label>
                  <label>
                    Sunday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekThreeSunday}
                    />
                  </label>
                </div>
              </div>
            )}
            {currentTab === 'ods' && (
              <div>
                <h4>ODS Week 4</h4>
                <div className="form-body">
                  <label>
                    Monday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekFourMonday}
                    />
                  </label>
                  <label>
                    Tuesday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekFourTuesday}
                    />
                  </label>
                  <label>
                    Wednesday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekFourWednesday}
                    />
                  </label>
                  <label>
                    Thursday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekFourThursday}
                    />
                  </label>
                  <label>
                    Friday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekFourFriday}
                    />
                  </label>
                  <label>
                    Saturday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekFourSaturday}
                    />
                  </label>
                  <label>
                    Sunday{' '}
                    <input
                      type="text"
                      disabled="disabled"
                      value={customerRequirements.odsWeekFourSunday}
                    />
                  </label>
                </div>
              </div>
            )}
            <div className="form-buttons">
              <button
                type="button"
                className="action"
                onClick={() => this.handleDisplayModal()}>
                Amend
              </button>
            </div>
          </form>
        )}
        {displayModal && (
          <CustomerRequirementsModal
            storeNumber={storeNumberInternal}
            escape={this.handleHideModal}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    stores: state.auth.stores,
    isFetching: state.customerRequirements.isFetching,
    isLoaded: state.customerRequirements.isLoaded,
    customerRequirements: state.customerRequirements.customerRequirements
      ? state.customerRequirements.customerRequirements
      : {},
    errorMessage: state.customerRequirements.errorMessage,
    isSubmitting: state.customerRequirements.isSubmitting,
    isSubmitted: state.customerRequirements.isSubmitted,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestCustomerRequirements: customerNumber =>
      dispatch(
        customerRequirementsActions.requestCustomerRequirements(customerNumber),
      ),
    amendCustomerRequirements: amendedDetails =>
      dispatch(
        customerRequirementsActions.amendCustomerRequirements(amendedDetails),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerRequirementsForm);
