/* eslint-disable react/jsx-key */
import React from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  LineChart,
  Legend,
  Brush, 
  ReferenceLine,
} from 'recharts';

function CustomLineChart({ data, lines, width, xAxisFontSize, chartTitle, description, showsPercentage, dataKey }) {
  
  const DataFormater = (number) => {
    if(showsPercentage){
      return  `${number}%`;
    }
    if(number > 1000000000){
      return (number/1000000000).toString() + 'B';
    }else if(number > 1000000){
      return (number/1000000).toString() + 'M';
    }else if(number > 1000){
      return (number/1000).toString() + 'K';
    }else{
      return number.toString();
    }
  };

  const formatTooltip = (value, name, props) => {
    if (showsPercentage) {
      return [`${name}: ${value}%`, props.payload['name']];
    } else {
      return new Intl.NumberFormat('en').format(value);
    }
  };
  
  return (
    <><p className="text-center">{chartTitle}</p>
      <ResponsiveContainer width={width}
        height={450}>
        <LineChart width={200}
          height={250}
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={dataKey ?? 'name'}
            interval={0} 
            tick={{ fontSize: xAxisFontSize ?? 10 }}/>
          <YAxis tickFormatter={DataFormater}/>
          <Tooltip formatter={formatTooltip} />
          <Legend />
          <ReferenceLine y={0} 
            stroke='#000'/>
          <Brush dataKey="name"
            height={20} 
            stroke="green" />  
          {lines && lines.map((line) => (
            <Line
              name={line.name}
              type="monotone"
              dataKey={line.dataKey}
              stroke={line.strokeColor} />
          ))}
        </LineChart>
      </ResponsiveContainer>
      
      <br/>
      <h5 className="explanation-style-styleginLeft">
        {' '}
        {description}
      </h5></>
  );
}

export default CustomLineChart;