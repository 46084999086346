import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import orderTrackerActions from '../../../actions/orderTrackerActions';
import { navigationActions } from '../../../actions/navigationActions';
import ProgressState from '../components/ProgressState/ProgressState';
import './CustomerInProgress.scss';
import PeriodFilter, { getDefaultTimePeriod } from '../components/PeriodFilter/PeriodFilter';

const CustomerInProgresss = (
  { completed, submitContactForm, clearState, getSummary,
    orders, exportSummary, navigate, isLoading, isSubmitted,
    getCustomerStores, customerStores }) => {
  const [displayMode, setDisplayMode] = useState(1);
  const [customerNumber, setCustomerNumber] = useState('');

  let defaultTimePeriod = '';
  if (completed) {
    defaultTimePeriod = getDefaultTimePeriod();
  }

  const [timePeriod, setTimePeriod] = useState(defaultTimePeriod);

  // Contact us
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactTelephone, setContactTelephone] = useState('');
  const [contactMessage, setContactMessage] = useState('');

  const handleSubmitContactForm = (e) => {
    e.preventDefault();

    submitContactForm({
      fullname: contactName,
      email: contactEmail,
      telephone: contactTelephone,
      message: contactMessage,
    });
  };

  useEffect(
    () => {
      getCustomerStores();
    },
    [getCustomerStores]);

  useEffect(
    () => {
      if (customerStores.length === 1) {
        setCustomerNumber(customerStores[0].number);
      }
    },
    [customerStores],
  );

  useEffect(
    () => {
      let month = '';
      let year = '';
      
      if (timePeriod) {
        month = timePeriod.split('-')[0];
        year = timePeriod.split('-')[1];
      }

      clearState();
      getSummary({
        month,
        year,
        completed: completed,
        store: customerNumber,
      });
    },
    [timePeriod, clearState, getSummary, completed, customerNumber],
  );

  const orderCountByProgress = (progressId, filter) => {
    if (!orders || orders.length === 0) {
      return 0;
    }

    return orders.filter(x => x.progressId === progressId && (filter === undefined || filter(x))).length;
  };

  const handleExportSummary = () => {
    let month = '';
    let year = '';
    
    if (timePeriod) {
      month = timePeriod.split('-')[0];
      year = timePeriod.split('-')[1];
    }

    exportSummary({
      month,
      year,
      completed: completed,
    });
  };

  return (
    <div className={`customer-ordertracker-container ${completed ? 'completed' : 'in-progress'}`}>
      <div className="summary-container">
        <div className="left-container">
          <div className="floating-panel">
            <div className="group-details accordion">
              <div className="accordion-header">
                <h3>In Progress Overview</h3>
              </div>
              <div className="accordion-body horizontal-flex order-progress-fields">
                <div>
                  <label>
                    Orders in Picking / Picked
                    <input
                      readOnly
                      disabled
                      value={orderCountByProgress(0)}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Orders Picked
                    <input
                      readOnly
                      disabled
                      value={orders ? orders.filter(x => x.progressStages[0] === '100').length : 0}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    CI Sent
                    <input
                      readOnly
                      disabled
                      value={orderCountByProgress(1)}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    HC Requested
                    <input
                      readOnly
                      disabled
                      value={orderCountByProgress(2)}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Legalization
                    <input
                      readOnly
                      disabled
                      value={orderCountByProgress(3)}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Pickup Request Sent
                    <input
                      readOnly
                      disabled
                      value={orderCountByProgress(4)}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Pickup Planned
                    <input
                      readOnly
                      disabled
                      value={orderCountByProgress(5)}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Shipped
                    <input
                      readOnly
                      disabled
                      value={orderCountByProgress(6)}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Delayed Orders
                    <input
                      readOnly
                      disabled
                      value={orders ? orders.filter(x => x.progressId < 6 && x.daysDelayed && x.daysDelayed > 0).length : 0}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Total Orders in Progress
                    <input
                      readOnly
                      disabled
                      value={(orders && orders.length) || 0}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="floating-panel archive-panel">
            <button
              className="action"
              type="button"
              onClick={() => navigate('/module/220/ot-viewcompleted')}
            >
              View Archive
            </button>
          </div>
        </div>
        <div className="right-container">
          <div className="floating-panel">
            <div className="group-details accordion">
              <div className="accordion-header">
                <h3>Customer Details</h3>
              </div>
              <div className="accordion-body horizontal-flex order-fields">
                <div>
                  <label>
                    Customer Number
                    <select
                      value={customerNumber}
                      onChange={e => setCustomerNumber(e.target.value)}
                    >
                      <option value="">Select a customer</option>
                      {customerStores.map(x =>
                        <option value={x.number}
                          key={x.number}>{x.displayName}</option>,
                      )}
                    </select>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="floating-panel inprogress-hidden">
            <div className="group-details accordion">
              <div className="accordion-header">
                <h3>Filter by Period</h3>
              </div>
              <div className="accordion-body horizontal-flex order-fields">
                <div>
                  <PeriodFilter
                    value={timePeriod}
                    onChange={(e) => setTimePeriod(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="floating-panel completed-hidden">
            <div className="group-details accordion">
              <div className="accordion-header">
                <h3>Order Status Details</h3>
              </div>
              <div className="accordion-body horizontal-flex order-status-fields">
                <div>
                  <label>
                    Cancelled
                    <input
                      readOnly
                      disabled
                      value="Order has been cancelled"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Request
                    <input
                      readOnly
                      disabled
                      value="Customer has requested order to be cancelled"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Behind
                    <input
                      readOnly
                      disabled
                      value="Order is still in process but behind plan"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    On Track
                    <input
                      readOnly
                      disabled
                      value="Order is on track to meet the expected shipping date"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Shipped
                    <input
                      readOnly
                      disabled
                      value="Order collected and ship confirmed in the system"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="floating-panel completed-hidden">
            <div className="group-details accordion">
              <div className="accordion-header">
                <h3>Contact</h3>
              </div>
              <div className="accordion-body horizontal-flex order-fields">
                <form onSubmit={(e) => handleSubmitContactForm(e)}>
                  <div>
                    <label>
                      Name
                      <input
                        value={contactName}
                        placeholder="Name"
                        type="text"
                        required
                        autoComplete="off"
                        onChange={(e) => setContactName(e.target.value)}
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      Email
                      <input
                        value={contactEmail}
                        placeholder="Email"
                        type="email"
                        required
                        autoComplete="email"
                        pattern="^.+@.+\..+$"
                        onChange={(e) => setContactEmail(e.target.value)}
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      Telephone
                      <input
                        value={contactTelephone}
                        placeholder="Telephone"
                        type="text"
                        required
                        pattern="^[\d\+\(\)\-\s]+$"
                        onChange={(e) => setContactTelephone(e.target.value)}
                      />
                    </label>
                  </div>
                  <div className="submit-container">
                    <label>
                      Subject
                      <textarea
                        value={contactMessage}
                        required
                        onChange={(e) => setContactMessage(e.target.value)}
                      />
                    </label>
                    <button
                      className="action flex-bottom"
                      type="submit"
                      disabled={isLoading}
                    >
                      Submit
                    </button>
                    {isSubmitted &&
                      <span className="submit-success flex-bottom">Contact form submitted!</span>
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-container">
        <div className="table-header">
          <div className="display-options">
            Table Size
            <button
              type="button"
              className="icon-display-mode-1"
              disabled={displayMode === 1}
              onClick={() => setDisplayMode(1)}
            />
            <button
              type="button"
              className="icon-display-mode-2"
              disabled={displayMode === 2}
              onClick={() => setDisplayMode(2)}
            />
            <button
              type="button"
              className="icon-display-mode-3"
              disabled={displayMode === 3}
              onClick={() => setDisplayMode(3)}
            />
          </div>
          <h2>
            Search Results
          </h2>
          <button
            className="action export"
            onClick={handleExportSummary}
            type="button"
            title="Export"
            disabled={isLoading}
          >
            <div
              className="icon icon-download-light"
              alt="Export"
            />
          </button>
        </div>
        <div className="table-body">
          <table className={`table-display-mode-${displayMode}`}>
            <thead>
              <tr>
                <th>Status</th>
                <th>Customer Number</th>
                <th>Customer PO Number</th>
                <th>Starbucks Order Number</th>
                <th>Created Date</th>
                <th>Age</th>
                <th>Progress Update</th>
                <th>View Details</th>
              </tr>
            </thead>
            <tbody>
              {orders.length === 0 &&
                <tr>
                  <td
                    colSpan={100}
                    className="no-records"
                  >
                    {isLoading ? 'Loading...' : 'No orders found.'}
                  </td>
                </tr>
              }
              {orders.length > 0 && orders.map(x =>
                <tr key={x.orderId}>
                  <td>
                    <div className={`order-status order-status-${x.statusId}`}>
                      {x.status}
                    </div>
                  </td>
                  <td>{x.storeNumber}</td>
                  <td>{x.externalPONumber}</td>
                  <td>{x.externalOrderNumber}</td>
                  <td>{x.createdAt.split('T')[0]}</td>
                  <td>{x.age}</td>
                  <td>
                    <ProgressState progressStage={x.progressId + 1}
                      numberOfBoxes={7} />
                  </td>
                  <td>
                    <button
                      className="link"
                      type="button"
                      onClick={() => navigate(`/module/220/ot-viewcustomerorder?id=${x.orderId}`)}>
                      View Details
                    </button>
                  </td>
                </tr>,
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );  
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.orderTracker.isLoading,
    isLoaded: state.orderTracker.isLoaded,
    isSubmitted: state.orderTracker.isContactSubmitted,
    orders: state.orderTracker.searchResults,
    error: state.orderTracker.error,
    customerStores: state.orderTracker.customerStores,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearState: () => dispatch(orderTrackerActions.clearState()),
  getSummary: (params) => dispatch(orderTrackerActions.getCustomerSummary(params)),
  navigate: (path) => dispatch(navigationActions.pushNavigation(path)),
  submitContactForm: (payload) => dispatch(orderTrackerActions.submitContactForm(payload)),
  exportSummary: (payload) => dispatch(orderTrackerActions.exportCustomerSummary(payload)),
  getCustomerStores: () => dispatch(orderTrackerActions.getCustomerStores()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInProgresss);
