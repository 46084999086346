import {
  SITEID_UPLOADDTMPFILE_REQUEST,
  SITEID_UPLOADDTMPFILE_SUCCESS,
  SITEID_UPLOADDTMPFILE_FAILURE,
} from './actionTypes';

const dtmpFileActions = {
  uploadDtmpFile: (dtmpFile) => (dispatch) => {
    dispatch({
      type: SITEID_UPLOADDTMPFILE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/siteid/DriveThrough/v1/UploadDtmpFile',
        successAction: SITEID_UPLOADDTMPFILE_SUCCESS,
        failureAction: SITEID_UPLOADDTMPFILE_FAILURE,
        body: {
          ...dtmpFile,
        },
      },
    });
  },
};

export default dtmpFileActions;
