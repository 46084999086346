import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import qs from 'query-string';
import DeleteModal from '../../shared/DeleteModal';
import CompanyRecord from './subComponents/companyRecord';
import { siteIDCompanyActions } from '../../../actions/siteIDCompanyActions';

class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      filter: '',
      page: 1,
      pageSize: 25,
      companyId: undefined,
    };

    this.handleCreateCompany = this.handleCreateCompany.bind(this);
    this.handleEditCompany = this.handleEditCompany.bind(this);
    this.handleDeleteCompany = this.handleDeleteCompany.bind(this);

    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.doSearch = this.doSearch.bind(this);

    this.doPaging = this.doPaging.bind(this);
  }

  componentDidMount() {
    const { search } = this.props.location;

    const parsed = qs.parse(search.toLowerCase());

    const newState = {
      filter: parsed.filter ? parsed.filter : '',
      page: parsed.page ? parseInt(parsed.page) : 1,
      pageSize: 25,
    };

    this.setState(newState);

    this.props.getCompanies(newState.filter, newState.page, newState.pageSize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.companyDeleted && !prevProps.companyDeleted) {
      const { filter, page, pageSize } = this.state;

      this.props.getCompanies(filter, page, pageSize);
    }
  }

  handleSearch(e) {
    this.setState({ filter: e.target.value, page: 1 });
  }

  doSearch() {
    const { pathname } = this.props.location;
    const { filter, page, pageSize } = this.state;

    this.props.replace(`${pathname}?filter=${filter}&page=${page}`);

    this.props.getCompanies(filter, page, pageSize);
  }

  handleCreateCompany() {
    const { pathname } = this.props.location;
    this.props.push(`${pathname}/0`);
  }

  handleEditCompany(companyId) {
    const { pathname } = this.props.location;
    this.props.push(`${pathname}/${companyId}`);
  }

  handleDeleteModalShow(companyId) {
    this.setState({ showDeleteModal: true, companyId });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false, companyId: undefined });
  }

  handleDeleteCompany() {
    this.props.deleteCompany(this.state.companyId);
    this.handleDeleteModalHide();
  }

  doPaging(page) {
    const { pathname } = this.props.location;
    const { filter, pageSize } = this.state;

    this.setState({ page });

    this.props.replace(`${pathname}?filter=${filter}&page=${page}`);

    this.props.getCompanies(filter, page, pageSize);
  }

  render() {
    const { companies, rowCount } = this.props;
    const { showDeleteModal, filter, page, pageSize } = this.state;
    const {
      handleEditCompany,
      handleDeleteModalShow,
      handleDeleteCompany,
      handleDeleteModalHide,
      handleSearch,
      doSearch,
      doPaging,
    } = this;
    return (
      <div className="company-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-stores"></div>
              <div>
                <h2>Company Configuration</h2>
                <p>Manage company records and regions that they operate in.</p>
              </div>
            </div>
            <div className="header-buttons">
              <div>
                {' '}
                <button
                  className="action"
                  type="button"
                  onClick={() => this.handleCreateCompany()}
                >
                  + Create New
                </button>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="..."
                  value={filter}
                  onChange={e => handleSearch(e)}
                />
                <button
                  type="button"
                  className="action"
                  onClick={() => doSearch()}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="company-container">
            {companies &&
              companies.map(company => (
                <CompanyRecord
                  key={company.companyId}
                  data={company}
                  editAction={() => handleEditCompany(company.companyId)}
                  deleteAction={() => handleDeleteModalShow(company.companyId)}
                />
              ))}
          </div>
          <div className="pagination-controls">
            {rowCount === 0 && '0-0 of 0'}
            {rowCount > 0 &&
              `${page * pageSize - pageSize + 1}-${
                page * pageSize < rowCount ? page * pageSize : rowCount
              } of ${rowCount}`}
            <button
              type="button"
              disabled={page === 1}
              onClick={() => doPaging(page - 1)}
            >
              {'<'}
            </button>
            <button
              type="button"
              disabled={page >= rowCount / pageSize}
              onClick={() => doPaging(page + 1)}
            >
              {'>'}
            </button>
          </div>
          {showDeleteModal && (
            <DeleteModal
              cancel={handleDeleteModalHide}
              continue={handleDeleteCompany}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.siteIDCompanies.companies.isFetching,
  companies: state.siteIDCompanies.companies.companyList,
  companyDeleted: state.siteIDCompanies.companies.isDeleted,
  page: state.siteIDCompanies.companies.pageNumber,
  pageSize: state.siteIDCompanies.companies.pageSize,
  rowCount: state.siteIDCompanies.companies.totalRecords,
});

const mapDispatchToProps = dispatch => {
  return {
    getCompanies: (filter, page, pageSize) =>
      dispatch(siteIDCompanyActions.requestCompanies(filter, page, pageSize)),
    deleteCompany: companyId =>
      dispatch(siteIDCompanyActions.deleteCompany(companyId)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
