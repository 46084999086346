import {
  CONNECT_GETTASKS_REQUEST,
  CONNECT_GETTASKS_SUCCESS,
  CONNECT_GETTASKS_FAILURE,
  CONNECT_COMPLETETASK_REQUEST,
  CONNECT_COMPLETETASK_SUCCESS,
  CONNECT_COMPLETETASK_FAILURE,
  CONNECT_GETTASKLIST_REQUEST,
  CONNECT_GETTASKLIST_SUCCESS, 
  CONNECT_GETTASKLIST_FAILURE,
  CONNECT_GETEDITTASK_REQUEST, 
  CONNECT_GETEDITTASK_SUCCESS, 
  CONNECT_GETEDITTASK_FAILURE, 
  CONNECT_SAVETASK_REQUEST,
  CONNECT_SAVETASK_SUCCESS,
  CONNECT_SAVETASK_FAILURE,
  CONNECT_DELETETASK_REQUEST,
  CONNECT_DELETETASK_SUCCESS,
  CONNECT_DELETETASK_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const connectTaskReducer = (
  state = initialState.connectTaskState,
  action,
) => {
  switch (action.type) {
  case CONNECT_GETTASKS_REQUEST: {
    if (action.payload && action.payload.baseline) {
      return {
        ...state,
        isFetching: true,
        allPopups: [],
        alertPopups: [],
      };
    }

    return {
      ...state,
      isFetching: true,
    };
  }
  case CONNECT_GETTASKS_SUCCESS: {
    const alerts = action.payload.notifications.filter(
      alert => !alert.acknowledged && alert.notificationType === 1,
    );

    if (action.payload && action.payload.baseline) {
      return {
        ...state,
        isFetching: false,
        allPopups: [...action.payload.notifications],
        alertPopups: [...alerts],
      };
    } else {
      return {
        ...state,
        isFetching: false,
        allPopups: [...action.payload.notifications, ...state.allPopups],
        alertPopups: [...alerts, ...state.alertPopups],
      };
    }
  }
  case CONNECT_GETTASKS_FAILURE: {
    return {
      ...state,
      isFetching: false,
      errorMessage: action.payload.message,
    };
  }
  case CONNECT_COMPLETETASK_REQUEST: {
    return {
      ...state,
      isFetching: true,
    };
  }
  case CONNECT_COMPLETETASK_SUCCESS: {
    const allPopups = [...state.allPopups].map(p => {
      if (
        p.platformNotificationType ===
            action.payload.platformNotificationType &&
          p.recipientId === action.payload.popupId
      ) {
        p.completed = true;
        p.completedOn = new Date();
      }
      return p;
    });
    const alertPopups = [...state.alertPopups]
      .map(p => {
        if (
          p.platformNotificationType ===
              action.payload.platformNotificationType &&
            p.recipientId === action.payload.popupId &&
            p.notificationType === action.payload.type
        ) {
          return null;
        }
        return p;
      })
      .filter(p => p !== null);

    return {
      ...state,
      isFetching: false,
      allPopups,
      alertPopups,
    };
  }
  case CONNECT_COMPLETETASK_FAILURE: {
    return {
      ...state,
      isFetching: false,
      errorMessage: action.payload.message,
    };
  }
  case CONNECT_GETTASKLIST_REQUEST:
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      errorMessage: '',
      adminAlerts: [],
      currentAdminAlert: {},
    };
  case CONNECT_GETTASKLIST_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      errorMessage: '',
      adminAlerts: action.payload,
    };
  case CONNECT_GETTASKLIST_FAILURE:
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      errorMessage: action.payload.message,
      adminAlerts: [],
      currentAdminAlert: {},
    };
  case CONNECT_GETEDITTASK_REQUEST:
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      errorMessage: '',
      currentAdminAlert: {},
    };
  case CONNECT_GETEDITTASK_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      errorMessage: '',
      currentAdminAlert: action.payload,
      //mbuUsers: action.payload,
    };
  case CONNECT_GETEDITTASK_FAILURE:
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      errorMessage: action.payload.message,
      currentAdminAlert: {},
    };
  case CONNECT_SAVETASK_REQUEST:
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      errorMessage: '',
    };
  case CONNECT_SAVETASK_SUCCESS:
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      currentAdminAlert: {
        ...state.currentAdminAlert,
        notification: {
          ...state.currentAdminAlert.notification,
          notificationId: action.payload,
        },
      },
      errorMessage: '',
    };
  case CONNECT_SAVETASK_FAILURE:
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      errorMessage: action.payload.message,
    };
  case CONNECT_DELETETASK_REQUEST:
    return {
      ...state,
      isDeleting: true,
      isDeleted: false,
      errorMessage: '',
    };
  case CONNECT_DELETETASK_SUCCESS:
    return {
      ...state,
      isDeleting: false,
      isDeleted: true,
      errorMessage: '',
    };
  case CONNECT_DELETETASK_FAILURE:
    return {
      ...state,
      isDeleting: false,
      isDeleted: false,
      errorMessage: action.payload.message,
    };

  default:
    return state;
  }
};
export default connectTaskReducer;
