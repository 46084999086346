import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../actions/navigationActions';
import Tile from '../shared/tile';

class WhatIsMyStarbucks extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
      <div className="whatismystarbucks-page page">
        <div className="mid-content">
          <div className="top-content">
            <div className="introduction">
              <img
                className="starbucks-logo"
                src="/assets/clear-logo.png"
                alt="starbucks logo"
              />
              <h3> Welcome to the My Starbucks portal</h3>
              <p>
                The mystarbucks.com secure portal will enable you to quickly and
                easily access an organized set of applications and key business
                information with which you can manage all aspects of your
                Starbucks supply chain related business operations.
              </p>
              {/*
              <p>
                The video presentation on this page will give you a general
                introduction to the portal. We welcome your feedback on the
                portal and any features you would like to see introduced in the
                future. You can contact us here: EMEA-CSD@starbucks.com
              </p>
             */}
              <button
                type="button"
                onClick={() => {
                  const redirectWindow = window.open(
                    'https://fast.wistia.net/embed/iframe/3ufgmq3n9g',
                    '_blank',
                  );
                  redirectWindow.location = 'https://fast.wistia.net/embed/iframe/3ufgmq3n9g';
                }}
                className="float"
              >
                Watch intro
              </button>
            </div>
            <div className="image-holder">
              <img
                className="laptop-image"
                src="/assets/Laptop@2x.png"
                alt="A laptop showing the My Starbucks portal"
              />
              <iframe
                allowtransparency="true"
                title="Wistia video player"
                allowFullScreen
                frameBorder="0"
                scrolling="no"
                className="wistia_embed"
                name="wistia_embed"
                src="https://fast.wistia.net/embed/iframe/3ufgmq3n9g"
                width="400"
                height="225"
              />
            </div>
          </div>
          <div className="key-features">
            <div className="key-features-content">
              <h3> Key Features</h3>
              <div className="icons-and-descriptions">
                <div className="feature">
                  <div
                    className="icon icon-inventory-light"
                    alt="orderly logo"
                  />
                  <div className="feature__text">
                    <h4> Fast Access </h4>
                    <p>
                      Quick and easy access to all your Starbucks applications
                      and up to date information from inside one portal
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <div
                    className="icon icon-customer-service-light"
                    alt="A starbucks cup"
                  />
                  <div className="feature__text">
                    <h4> Talk to CSD </h4>
                    <p>
                      Coming soon - Use online instant messaging tools to talk
                      to the Starbucks customer service department
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <div className="icon icon-announcements"
                    alt="a megaphone" />
                  <div className="feature__text">
                    <h4> Read Announcements </h4>
                    <p>
                      Keep up to date with important announcements and
                      notifications through the portal
                    </p>
                  </div>
                </div>
                <div className="feature">
                  <div className="icon icon-download-light"
                    alt="download" />
                  <div className="feature__text">
                    <h4> Download Documents and Reports </h4>
                    <p>
                      Download key documents – QA, Recipes and Manuals – and
                      reports from the portal, without the need to login into
                      different applications
                    </p>
                  </div>
                </div>

                <div className="feature feature-long">
                  <div className="icon icon-mobile"
                    alt="a mobile phone" />
                  <div className="feature__text">
                    <h4> Access Controls </h4>
                    <p>
                      Working with Starbucks you can control the levels of
                      access you want to provide your organization: this can be
                      all stores, a selection of stores or a single store, all
                      applications or a business specific selection. The
                      information you wish to access and provide to your
                      business can be easily customized with the dedicated
                      Starbucks support team.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="access-on-the-go">
            <img
              className="phone-image"
              src="/assets/iPhoneX@2x.png"
              alt="A smartphone showing the My Starbucks portal"
            />
            <div className="access-text">
              <h3>Access on the go</h3>
              <p>
                Fully secure and mobile friendly, so you can keep up to date
                with operations wherever you are.
              </p>
            </div>
          </div>
          <div className="simple-to-use">
            <h3>Simple to use and accessible to all</h3>
            <div className="simple-content">
              <div className="simple-green-box">
                <div
                  className="icon icon-customer-service-light"
                  alt="A starbucks cup"
                />
                <p>
                  Easy to access information on your item catalogue (including
                  hazardous materials), pricing details, invoices and a clear
                  and simple document library.
                </p>
              </div>
              <img
                className="simple-image"
                src="/assets/ManWithLaptop@2x.png"
                alt="A man with a laptop"
              />
            </div>
          </div>
          <div className="store-showcase">
            <div className="store-green-box">
              <div className="icon icon-qa-light"
                alt="A star" />
              <p>
                The portal has been designed to provide you with access to
                applications and information around Order Management, Item
                Management, Pricing, Forecasting, QA and to keep you in touch
                with Starbucks around all developments relating to your business
                operations.
              </p>
            </div>
          </div>
          <div className="includes">
            <h3>Includes the following modules and more...</h3>
            <div className="tiles">
              <Tile
                data={{
                  iconType: 1,
                  iconData: 'icon-orderly',
                  title: 'Order Management',
                  description:
                    'Access applications, information and tools to assist with the management of your orders',
                  parentMenuId: null,
                }}
              />
              <Tile
                data={{
                  iconType: 1,
                  iconData: 'Icon-Customer-Service',
                  title: 'Customer Service',
                  description:
                    'Contact us, read alerts and notifications or provide feedback',
                  parentMenuId: null,
                }}
              />
              <Tile
                data={{
                  iconType: 1,
                  iconData: 'Icon-item-catalogue',
                  title: 'Item Catalogue',
                  description:
                    'Review detailed item information for the products in your catalogues',
                  parentMenuId: null,
                }}
              />
            </div>
          </div>
          <div className="access-today">
            <div className="access-content">
              <div className="access-text">
                <h3>Access Today</h3>
                <p>To gain access all you need to do is...</p>
                <button
                  type="button"
                  onClick={() => {
                    this.props.push('/');
                  }}
                  className="float"
                >
                  Sign in now
                </button>
              </div>
              <img
                className="i-pad-image"
                src="/assets/iPad-Pro-Mockup@2x.png"
                alt="An ipad showing the My Starbucks portal"
              />
            </div>
          </div>
          <footer
            className="orderly-footer"
            onClick={() => window.location = ('https://orderly.io')}
          >
            ©
            {' '}
            {this.getYear()}
            {' '}
            Powered by
            <img
              className="orderly-logo"
              src="/assets/logo-orderly.png"
              alt="orderly logo"
            />
          </footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(navigationActions.pushNavigation(path)),
  replace: (path) => dispatch(navigationActions.replaceNavigation(path)),
  reset: () => dispatch(navigationActions.resetNavigation()),
  goBack: () => dispatch(navigationActions.backNavigation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WhatIsMyStarbucks);
