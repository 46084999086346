import {
  MYDOCUMENTS_REQUEST,
  MYDOCUMENTS_SUCCESS,
  MYDOCUMENTS_FAILURE,
  SAVEMYDOC_REQUEST,
  SAVEMYDOC_SUCCESS,
  SAVEMYDOC_FAILURE,
  DELETEMYDOC_REQUEST,
  DELETEMYDOC_SUCCESS,
  DELETEMYDOC_FAILURE,
} from './actionTypes';

export const myDocumentActions = {
  requestMyDocuments: storeId => (dispatch, getState) => {
    dispatch({
      type: MYDOCUMENTS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/mydocuments/v1/GetMyDocuments?storeNumber=${storeId}`,
        successAction: MYDOCUMENTS_SUCCESS,
        failureAction: MYDOCUMENTS_FAILURE,
      },
    });
  },
  saveMyDocument: request => (dispatch, getState) => {
    dispatch({
      type: SAVEMYDOC_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/mydocuments/v1/SaveMyDocument',
        successAction: SAVEMYDOC_SUCCESS,
        failureAction: SAVEMYDOC_FAILURE,
        body: {
          ...request,
        },
      },
    });
  },
  deleteMyDocument: request => (dispatch, getState) => {
    dispatch({
      type: DELETEMYDOC_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: '/api/mydocuments/v1/DeleteMyDocument',
        successAction: DELETEMYDOC_SUCCESS,
        failureAction: DELETEMYDOC_FAILURE,
        body: {
          ...request,
        },
      },
    });
  },
};
