import {
  SITEID_DEFAULTRESPONSE_REQUEST,
  SITEID_DEFAULTRESPONSE_SUCCESS,
  SITEID_DEFAULTRESPONSE_FAILURE,
  SITEID_DEFAULTRESPONSES_REQUEST,
  SITEID_DEFAULTRESPONSES_SUCCESS,
  SITEID_DEFAULTRESPONSES_FAILURE,
  SITEID_SAVERESPONSE_REQUEST,
  SITEID_SAVERESPONSE_SUCCESS,
  SITEID_SAVERESPONSE_FAILURE,
  SITEID_DELETERESPONSE_REQUEST,
  SITEID_DELETERESPONSE_SUCCESS,
  SITEID_DELETERESPONSE_FAILURE,
} from './actionTypes';

export const siteIDDefaultResponseActions = {
  getDefaultResponses: () => (dispatch) => {
    dispatch({
      type: SITEID_DEFAULTRESPONSES_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/siteid/defaultresponse/v1/list',
        successAction: SITEID_DEFAULTRESPONSES_SUCCESS,
        failureAction: SITEID_DEFAULTRESPONSES_FAILURE,
      },
    });
  },
  getDefaultResponse: (defaultresponseId) => (dispatch) => {
    dispatch({
      type: SITEID_DEFAULTRESPONSE_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/siteid/defaultresponse/v1/get?defaultresponseId=${defaultresponseId || ''}`,
        successAction: SITEID_DEFAULTRESPONSE_SUCCESS,
        failureAction: SITEID_DEFAULTRESPONSE_FAILURE,
      },
    });
  },
  saveDefaultResponse: (defaultresponse) => (dispatch) => {
    dispatch({
      type: SITEID_SAVERESPONSE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/siteid/defaultresponse/v1/save',
        successAction: SITEID_SAVERESPONSE_SUCCESS,
        failureAction: SITEID_SAVERESPONSE_FAILURE,
        body: {
          ...defaultresponse,
        },
      },
    });
  },
  deleteDefaultResponse: (defaultresponseId) => (dispatch) => {
    dispatch({
      type: SITEID_DELETERESPONSE_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: `/api/siteid/defaultresponse/v1/delete?defaultresponseId=${defaultresponseId || ''}`,
        successAction: SITEID_DELETERESPONSE_SUCCESS,
        failureAction: SITEID_DELETERESPONSE_FAILURE,
      },
    });
  },
};

export default siteIDDefaultResponseActions;
