
import {  GETORDERMETRICS_SUCCESS, GETORDERMETRICS_FAILURE,
  GETSHIPMENTPERFORMANCE_SUCCESS, GETSHIPMENTPERFORMANCE_FAILURE,
  GETPRODUCTCATEGORIES_SUCCESS, GETPRODUCTCATEGORIES_FAILURE,
  GETEMERGENCYORDER_SUCCESS,
  GETEMERGENCYORDER_FAILURE,
  GETFORECASTCHANGEREQUEST_SUCCESS,
  GETFORECASTCHANGEREQUEST_FAILURE,
  GETKPI_SUCCESS,
  GETKPI_FAILURE,
  GETMAPE_SUCCESS,
  GETMAPE_FAILURE,
  GETMAPE_REQUEST,
  GETMAPEBYCATEGORY_REQUEST,
  GETMAPEBYCATEGORY_SUCCESS,
  GETMAPEBYCATEGORY_FAILURE,
  GETMAPEBYTOPITEM_REQUEST,
  GETMAPEBYTOPITEM_SUCCESS,
  GETMAPEBYTOPITEM_FAILURE,
  GETFORECASTDASHBOARD_REQUEST,
  EXPORTDATA_REQUEST,
  EXPORTDATA_SUCCESS,
  EXPORTDATA_FAILURE,
  GETKPIREFERENCEDATA_SUCCESS,
  GETKPIREFERENCEDATA_FAILURE,
  GETFORECASTVSORDER_SUCCESS,
  GETFORECASTVSORDER_FAILURE,
  GETMBUS_SUCCESS,
  GETMBUS_FAILURE } from '../actions/actionTypes';
import initialState from './initialState';
  
export const forecastPerformanceDashboardReducer = (

  state = initialState.forecastPerformanceDashboardState,
  action,
) => {
  switch (action.type) {

  case GETFORECASTDASHBOARD_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case GETORDERMETRICS_SUCCESS: {
    return {
      ...state,
      loading: false,
      orderMetricsData: action.payload,
    };
  }
  case GETORDERMETRICS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }

  case GETSHIPMENTPERFORMANCE_SUCCESS: {
    return {
      ...state,
      loading: false,
      shipmentPerfomanceData: action.payload,
    };
  }
  case GETSHIPMENTPERFORMANCE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }

  case GETEMERGENCYORDER_SUCCESS: {
    return {
      ...state,
      loading: false,
      emergencyOrdersData: action.payload,
    };
  }
  case GETEMERGENCYORDER_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }


  case GETFORECASTCHANGEREQUEST_SUCCESS: {
    return {
      ...state,
      loading: false,
      forecastChangeRequestsData: action.payload,
    };
  }
  case GETFORECASTCHANGEREQUEST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }
  

  case GETKPI_SUCCESS: {
    return {
      ...state,
      loading: true,
      kpiData: action.payload,
    };
  }
  case GETKPI_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }
  

  case GETMAPE_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case GETMAPE_SUCCESS: {
    return {
      ...state,
      loading: false,
      mapeYearlyChartData: action.payload,
    };
  }
  case GETMAPE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }

  case GETMAPEBYCATEGORY_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case GETMAPEBYCATEGORY_SUCCESS: {
    return {
      ...state,
      loading: false,
      mapeByCategoryChartData: action.payload,
    };
  }
  case GETMAPEBYCATEGORY_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    }; 
  }

  case GETMAPEBYTOPITEM_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case GETMAPEBYTOPITEM_SUCCESS: {
    return {
      ...state,
      loading: false,
      mapeByTopTenItemsChartData: action.payload,
    };
  }
  case GETMAPEBYTOPITEM_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }

  case GETFORECASTVSORDER_SUCCESS: {
    return {
      ...state,
      loading: false,
      forecastVsOrderData: action.payload,
    };
  }

  case GETFORECASTVSORDER_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }

  case GETMBUS_SUCCESS: {
    return {
      ...state,
      loading: false,
      mbuData: action.payload,
    };
  }

  case GETMBUS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }


  case GETPRODUCTCATEGORIES_SUCCESS: {
    return {
      ...state,
      loading: false,
      productCategoriesData: action.payload,
    };
  }

  case  GETPRODUCTCATEGORIES_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }
  case GETKPIREFERENCEDATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      kpiReferenceData : action.payload,
    };
  }

  case  GETKPIREFERENCEDATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }
  case EXPORTDATA_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case EXPORTDATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      file: action.payload,
    };
  }

  case  EXPORTDATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }

  default:
    return state;
  }
};
export default forecastPerformanceDashboardReducer;
  