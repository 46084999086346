import React from 'react';
import { connect } from 'react-redux';
import UserRecord from './subComponents/userRecord';
import { navigationActions } from '../../../actions/navigationActions';
import { userManagementActions } from '../../../actions/userManagementActions';
import qs from 'query-string';
import DeleteModal from '../../shared/DeleteModal';

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      filter: '',
      page: 1,
      pageSize: 25,
      userId: undefined,
    };

    this.handleCreateUser = this.handleCreateUser.bind(this);
    this.handleEditUser = this.handleEditUser.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);

    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.doSearch = this.doSearch.bind(this);

    this.doPaging = this.doPaging.bind(this);
  }

  componentDidMount() {
    const { search } = this.props.location;

    const parsed = qs.parse(search.toLowerCase());

    const newState = {
      filter: parsed.filter ? parsed.filter : '',
      page: parsed.page ? parseInt(parsed.page) : 1,
      pageSize: 25,
    };

    this.setState(newState);

    this.props.getUsers(newState.filter, newState.page, newState.pageSize);
  }

  componentDidUpdate(prevProps) {
    if (this.props.userDeleted && !prevProps.userDeleted) {
      const { filter, page, pageSize } = this.state;

      this.props.getUsers(filter, page, pageSize);
    }
  }

  handleSearch(e) {
    this.setState({ filter: e.target.value, page: 1 });
  }

  doSearch() {
    const { pathname } = this.props.location;
    const { filter, page, pageSize } = this.state;

    this.props.replace(`${pathname}?filter=${filter}&page=${page}`);

    this.props.getUsers(filter, page, pageSize);
  }

  handleCreateUser() {
    const { pathname } = this.props.location;
    this.props.push(`${pathname}/0`);
  }

  handleEditUser(userId) {
    const { pathname } = this.props.location;
    this.props.push(`${pathname}/${userId}`);
  }

  handleDeleteModalShow(userId) {
    this.setState({ showDeleteModal: true, userId });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false, userId: undefined });
  }

  handleDeleteUser() {
    this.props.deleteUser(this.state.userId);
    this.handleDeleteModalHide();
  }

  doPaging(page) {
    const { pathname } = this.props.location;
    const { filter, pageSize } = this.state;

    this.setState({ page });

    this.props.replace(`${pathname}?filter=${filter}&page=${page}`);

    this.props.getUsers(filter, page, pageSize);
  }

  render() {
    const { users, rowCount, tenantKey } = this.props;
    const { showDeleteModal, filter, page, pageSize } = this.state;
    const {
      handleEditUser,
      handleDeleteModalShow,
      handleDeleteUser,
      handleDeleteModalHide,
      handleSearch,
      doSearch,
      doPaging,
    } = this;

    const isEMEA = tenantKey == tenantKey === 'EMEA';

    return (
      <div className="users-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-admin"></div>
              <div>
                <h2>Customer User Configuration</h2>
                {isEMEA ? (
                  <p>
                  Manage individual user account records and the module content
                  and stores they can access.
                  </p>
                ): 
                  <p>
                  Manage individual user account records and the module content
                  and customers they can access.
                  </p>
                }
              </div>
            </div>
            <div className="header-buttons">
              <div>
                {' '}
                <button
                  className="action"
                  type="button"
                  onClick={() => this.handleCreateUser()}
                >
                  + Create New
                </button>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="..."
                  value={filter}
                  onChange={e => handleSearch(e)}
                />
                <button
                  type="button"
                  className="action"
                  onClick={() => doSearch()}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="user-container">
            {users &&
              users.map(user => (
                <UserRecord
                  key={user.userId}
                  data={user}
                  editAction={() => handleEditUser(user.userId)}
                  deleteAction={() => handleDeleteModalShow(user.userId)}
                />
              ))}
          </div>
          <div className="pagination-controls">
            {rowCount === 0 && '0-0 of 0'}
            {rowCount > 0 &&
              `${page * pageSize - pageSize + 1}-${
                page * pageSize < rowCount ? page * pageSize : rowCount
              } of ${rowCount}`}
            <button
              type="button"
              disabled={page === 1}
              onClick={() => doPaging(page - 1)}
            >
              {'<'}
            </button>
            <button
              type="button"
              disabled={page >= rowCount / pageSize}
              onClick={() => doPaging(page + 1)}
            >
              {'>'}
            </button>
          </div>
          {showDeleteModal && (
            <DeleteModal
              cancel={handleDeleteModalHide}
              continue={handleDeleteUser}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.userManagement.users.isFetching,
  users: state.userManagement.users.userList,
  userDeleted: state.userManagement.users.isDeleted,
  page: state.userManagement.users.pageNumber,
  pageSize: state.userManagement.users.pageSize,
  rowCount: state.userManagement.users.totalRecords,
  tenantKey: state.auth.tenant,
});

const mapDispatchToProps = dispatch => {
  return {
    getUsers: (filter, page, pageSize) =>
      dispatch(userManagementActions.requestUsers(filter, page, pageSize)),
    deleteUser: userId => dispatch(userManagementActions.deleteUser(userId)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
