import {
  CONNECT_DASHBOARD_REQUEST,
  CONNECT_DASHBOARD_SUCCESS,
  CONNECT_DASHBOARD_FAILURE,

  CONNECT_DASHBOARDNOTIFICATIONS_SUCCESS,
  CONNECT_DASHBOARDNOTIFICATIONS_FAILURE,

  CONNECT_DASHBOARDTASKS_SUCCESS,
  CONNECT_DASHBOARDTASKS_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const ConnectReportingRouter = (
  state = initialState.connectReportingState,
  action,
) => {
  switch (action.type) {
  case CONNECT_DASHBOARD_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      error: undefined,
      data: {},
    };

  case CONNECT_DASHBOARD_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: undefined,
      data: action.payload,
    };

  case CONNECT_DASHBOARDNOTIFICATIONS_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: undefined,
      notificationData: action.payload,
    };

  case CONNECT_DASHBOARDTASKS_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: undefined,
      taskData: action.payload,
    };

  case CONNECT_DASHBOARD_FAILURE:
  case CONNECT_DASHBOARDNOTIFICATIONS_FAILURE:
  case CONNECT_DASHBOARDTASKS_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: action.payload.message,
    };

  default:
    return state;
  }
};
export default ConnectReportingRouter;
