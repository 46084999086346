import {
  SITEIDREPORT_REFERENCEDATA_REQUEST,
  SITEIDREPORT_REFERENCEDATA_SUCCESS,
  SITEIDREPORT_REFERENCEDATA_FAILURE,
  SITEIDREPORT_REPORTDATA_REQUEST,
  SITEIDREPORT_REPORTDATA_SUCCESS,
  SITEIDREPORT_REPORTDATA_FAILURE,
} from './actionTypes';

export const siteIDReportingActions = {
  getReferenceData: () => (dispatch, getState) => {
    dispatch({
      type: SITEIDREPORT_REFERENCEDATA_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/siteid/reporting/v1/GetReferenceData',
        successAction: SITEIDREPORT_REFERENCEDATA_SUCCESS,
        failureAction: SITEIDREPORT_REFERENCEDATA_FAILURE,
      },
    });
  },
  getData: (fromDate, toDate, companyId, formatId, countyId, statusId) => (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: SITEIDREPORT_REPORTDATA_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/siteid/reporting/v1/GetData?${
          !fromDate ? '' : `fromDate=${new Date(fromDate).toISOString()}&`
        }${!toDate ? '' : `toDate=${new Date(toDate).toISOString()}&`}${
          !companyId ? '' : `companyId=${companyId}&`
        }${!formatId ? '' : `formatId=${formatId}&`}${
          !countyId ? '' : `countyId=${countyId}&`
        }${!statusId ? '' : `statusId=${statusId}&`}`,
        successAction: SITEIDREPORT_REPORTDATA_SUCCESS,
        failureAction: SITEIDREPORT_REPORTDATA_FAILURE,
      },
    });
  },
};
