import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { forecastChangeRequestActions } from '../../../actions/forecastChangeRequestActions';
import MessageModal from '../../shared/MessageModal';

class ForecastChangeRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      errorMessage: '',

      showMessage: false,
      message: '',
      title: '',
    };

    this.handleSave = this.handleSave.bind(this);
    this.addNewItem = this.addNewItem.bind(this);
    this.handleCheckItem = this.handleCheckItem.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);

    this.handleMessageShow = this.handleMessageShow.bind(this);
    this.handleMessageHide = this.handleMessageHide.bind(this);
  }

  componentDidMount() {
    this.addNewItem();
  }

  componentDidUpdate(prevProps, prevState) {
    //Check item validity
    if (this.props.checkedItem && !prevProps.checkedItem) {
      const { items } = this.state;

      items.map(item => {
        if (this.props.requestedItem === item.itemNumber) {
          if (this.props.item.itemNumber === item.itemNumber) {
            item.itemDescription = this.props.item.itemDescription;
            item.valid = true;
          } else {
            item.itemDescription = '';
            item.valid = false;
          }
        }
        return item;
      });

      this.setState({ items });
    }

    if (this.props.feedbackError && !prevProps.feedbackError) {
      this.handleMessageShow(this.props.feedbackError, 'An error occurred');
    }

    if (this.props.feedbackSaved && !prevProps.feedbackSaved) {
      this.handleMessageShow(
        'Thank you for submitting your forecast change request.',
        'Request Submitted',
      );
    }
  }

  addNewItem() {
    let { items } = this.state;

    items.push({
      itemNumber: '',
      itemDescription: '',
      weekDate: new Date(),
      originalQty: 0,
      qty: 0,
      market: '',
      reason: '',
    });

    this.setState({ items });
  }

  removeItem(index) {
    let { items } = this.state;

    items.splice(index, 1);

    this.setState({ items });
  }
  handleSave(e) {
    let { submitFeedback } = this.props;
    let { items } = this.state;

    if (items.filter(i => !i.valid).length > 0) {
      this.handleMessageShow('Some items are invalid.', 'An error occurred');
      e.preventDefault();
      return;
    }

    submitFeedback({
      items,
    });

    e.preventDefault();
  }

  handleCheckItem(e) {
    this.props.checkItem(e.target.value);

    e.preventDefault();
  }

  handleTextInputUpdate(e, record, fieldName) {
    let { items } = this.state;

    items[record][fieldName] = e.target.value;

    this.setState({ items });
  }

  handleMessageShow(message, title) {
    this.setState({ showMessage: true, message, title });
  }

  handleMessageHide() {
    this.setState({ showMessage: false, message: '', title: '' });
  }

  render() {
    let { items, errorMessage } = this.state;
    const { feedbackSaving, feedbackSaved } = this.props;
    return (
      <div className="forecast-change-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-form"></div>
              <h2>Forecast Change Request</h2>
            </div>
          </div>
          <p>
            All Forecast Change Requests should be sent no later than 17.00 CET
            every Tuesday. SBUX Demand Planner will submit these changes to the
            appropriate Supply Planner and will advise you of the outcome the
            following Thursday between 12:00 – 13:00 CET.
          </p>
          <p>
            If your demand increase request cannot be met, you will be notified
            of the reason why. If possible, you will be advised of the next best
            order placement date. If this date is agreeable, please confirm this
            with the SBUX Demand Planner by 16:30 CET in order that your
            template can be updated.
          </p>
          <p>
            Any Forecast Change Request received after 16:30 CET on a Wednesday
            will only be taken into consideration the following week. For
            example, a FCR received after the cut-off on Wednesday 30-Jan will
            be added to the consolidated file on 07-Feb and the SBUX Demand
            Planner will inform the market by 08-Feb if the request has been
            approved. Exceptions can be made and a business case needs to be
            provided by the market for these.
          </p>
          <p>
            Forecast Change Requests should be for A and B class items only. C
            class items are excluded so change cannot be made unless the item
            was out of stock on the previous order or if the market can detail
            to the Demand Planner an explanation of the critical business need.
          </p>
          <p>
            Forecast Change Requests within the <u>6-week locked period </u>
            are only meant to be submitted
            <b> in case of extreme exceptions.</b>
          </p>
        </div>
        <form noValidate
          onSubmit={e => this.handleSave(e)}>
          <div>
            {items.map((item, i) => (
              <div className="floating-panel"
                key={i}>
                <div className="floating-mini-header">
                  <h3>
                    {item.itemNumber ? item.itemNumber : 'Item Number'} -{' '}
                    {item.itemDescription
                      ? item.itemDescription
                      : 'Item Description'}
                  </h3>
                  <div
                    className="remove-button"
                    onClick={() => this.removeItem(i)}>
                    Remove Entry
                    <div className="icon icon-image-remove"></div>
                  </div>
                </div>
                <div className="forecast-item-change-body">
                  <div>
                    <label title="Please use the correct SKU in case of replacements">
                      Item Number &#x1F6C8;
                      <input
                        type="text"
                        autoFocus
                        value={item.itemNumber}
                        onChange={e =>
                          this.handleTextInputUpdate(e, i, 'itemNumber')
                        }
                        onBlur={e => this.handleCheckItem(e, i)}
                        placeholder="Item Number..."
                      />
                    </label>
                    <label>
                      Item Description
                      <input
                        type="text"
                        disabled
                        value={item.itemDescription}
                        onChange={e =>
                          this.handleTextInputUpdate(e, i, 'itemDescription')
                        }
                        placeholder="Item Description..."
                      />
                    </label>
                  </div>
                  <div>
                    <label title="Only Mondays will be recognized.  The week should be the dispatch week and not the order week">
                      Week Date &#x1F6C8;
                      <input
                        type="date"
                        value={item.weekDate}
                        onChange={e =>
                          this.handleTextInputUpdate(e, i, 'weekDate')
                        }
                        placeholder="Week Date..."
                      />
                    </label>
                    <label>
                      Market
                      <input
                        type="text"
                        value={item.market}
                        onChange={e =>
                          this.handleTextInputUpdate(e, i, 'market')
                        }
                        placeholder="Market..."
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      Original Qty
                      <input
                        type="number"
                        min="0"
                        value={item.originalQty}
                        onChange={e =>
                          this.handleTextInputUpdate(e, i, 'originalQty')
                        }
                        placeholder="Qty..."
                      />
                    </label>

                    <label title="Forecast Unit of Measure">
                      New Qty
                      <input
                        type="number"
                        min="0"
                        value={item.qty}
                        onChange={e => this.handleTextInputUpdate(e, i, 'qty')}
                        placeholder="Qty..."
                      />
                    </label>
                  </div>
                </div>
                <div className="forecast-item-change-reason">
                  <label>
                    Reason for the forecast change request.
                    <textarea
                      type="text"
                      value={item.reason}
                      onChange={e => this.handleTextInputUpdate(e, i, 'reason')}
                      placeholder="Reason..."
                    />
                  </label>
                </div>
                {item.valid === false && (
                  <p>
                    This item is not linked to your current catalogue and no
                    amendment can be made. Please contact customer services to
                    review if necessary
                  </p>
                )}
              </div>
            ))}
          </div>

          {errorMessage && <p>{errorMessage}</p>}
          <div className="form-buttons">
            <button
              type="button"
              className="action"
              onClick={() => this.addNewItem()}>
              Add new Item
            </button>
            <button className="action"
              disabled={feedbackSaving}>
              {feedbackSaving
                ? 'Submitting...'
                : feedbackSaved
                  ? 'Submitted'
                  : 'Submit'}
            </button>
          </div>
        </form>
        {this.state.showMessage && (
          <MessageModal
            cancel={this.handleMessageHide}
            title={this.state.title}
            message={this.state.message}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    feedbackSaving: state.forecastChangeRequest.feedbackSaving,
    feedbackSaved: state.forecastChangeRequest.feedbackSaved,
    feedbackError: state.forecastChangeRequest.errorMessage,
    checkingItem: state.forecastChangeRequest.checkingItem,
    checkedItem: state.forecastChangeRequest.checkedItem,
    requestedItem: state.forecastChangeRequest.requestedItem,
    item: state.forecastChangeRequest.item,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submitFeedback: feedbackData =>
      dispatch(
        forecastChangeRequestActions.submitForecastChangeRequest(feedbackData),
      ),
    checkItem: itemNumber =>
      dispatch(forecastChangeRequestActions.checkItem(itemNumber)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForecastChangeRequest);
