import React from 'react';

const CompanyRecord = props => {
  let { companyId, name } = props.data;
  let { editAction, deleteAction } = props;

  return (
    <div className="company-record"
      data-company-id={companyId}>
      <div className="company-top-details">
        <div className="icon icon-store"></div>
        <div>
          <div className="companyname">{name}</div>
        </div>
      </div>
      <div className="company-top-buttons">
        <button className="edit"
          type="button"
          onClick={() => editAction()}>
          EDIT
        </button>
        <button
          className="edit negative"
          type="button"
          onClick={() => deleteAction()}
        >
          DELETE
        </button>
      </div>
    </div>
  );
};

export default CompanyRecord;
