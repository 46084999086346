import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { navigationActions } from '../../../actions/navigationActions';

class ForgottenPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      usernameInvalid: false,
      message: '',
    };

    this.usernameOnChange = this.usernameOnChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const username = localStorage.getItem('rememberMe');
    if (username) {
      this.setState({ username });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.fetching && !prevProps.fetching) {
      this.setState({ message: this.props.message });
    }
  }
  
  usernameOnChange(event) {
    let { value } = event.target;
    let valid = value !== undefined && value.length > 0 && value.length < 50;

    this.setState({ username: value, usernameInvalid: !valid });
  }

  submit(event) {
    let { username, usernameInvalid } = this.state;
    let { requestPasswordReset } = this.props;

    if (username === '' || usernameInvalid) {
      this.setState({ usernameInvalid: true });
      event.preventDefault();
      return;
    }

    requestPasswordReset({
      username,
    });

    event.preventDefault();
  }

  render() {
    return (
      <form onSubmit={this.submit}
        noValidate>
        <p>Please enter your username to request a password reset email.</p>
        <input
          placeholder="Username"
          type="text"
          onChange={e => this.usernameOnChange(e)}
          required
          autoFocus
          value={this.state.username}
          className={`float ${this.state.usernameInvalid ? 'invalid' : ''}`}
        />
        <div className="submission">
          <p>
            {this.state.message}
            {this.props.loaded ? 'Please check your email inbox.' : ''}
          </p>

          <button
            type="submit"
            className="float"
            disabled={this.props.fetching}>
            {this.props.fetching ? 'Working...' : 'Reset'}
          </button>
          <button
            type="button"
            className="float negative"
            disabled={this.props.isFetching}
            onClick={() => this.props.push('/')}>
            Home
          </button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  fetching: state.auth.isFetching_RequestPasswordReset,
  loaded: state.auth.isLoaded_RequestPasswordReset,
  message: state.auth.errorMessage,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    requestPasswordReset: creds =>
      dispatch(authActions.requestPasswordReset(creds)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgottenPassword);
