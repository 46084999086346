import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import qs from 'query-string';
import { platformUsageActions } from '../../../actions/platformUsageActions';
import SmallStat from './shared/SmallStat';
import * as V from 'victory';
import TagCloud from 'react-tag-cloud';
import StarRating from './shared/StarRating';

class PlatformUsage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timePeriod: 1,
      questionTopic: '',
    };

    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleQuestionTopic = this.handleQuestionTopic.bind(this);

    this.getModuleUsage = this.getModuleUsage.bind(this);
    this.getUserSessions = this.getUserSessions.bind(this);
    this.getActionData = this.getActionData.bind(this);
  }

  handleQuestionTopic(e) {
    const questionTopic = e.target.value;
    this.setState({ questionTopic });

    this.updateCurrentUrl({ ...this.state, questionTopic });
  }

  handleChangeTab(timePeriod) {
    this.setState({ timePeriod });
    this.updateCurrentUrl({ ...this.state, timePeriod });
    this.fetchData(timePeriod);
  }

  updateCurrentUrl(data) {
    const { pathname } = this.props.location;
    this.props.replace(
      `${pathname}?dashboard=${data.timePeriod}&questiontopic=${data.questionTopic}`,
    );
  }

  componentDidMount() {
    const { search } = this.props.location;

    const parsed = qs.parse(search.toLowerCase());

    let timePeriod = parsed.dashboard ? parseInt(parsed.dashboard) : 1;
    let questionTopic = parsed.questiontopic ? parsed.questiontopic : '';

    const newState = {
      timePeriod,
      questionTopic,
    };

    this.setState(newState);

    this.fetchData(timePeriod);
  }

  fetchData(daysBack) {
    const days = 86400000;

    //23:59:59 today
    let newDate = new Date();
    newDate.setHours(0, 0, 0, 0);
    newDate = new Date(newDate.getTime() + days);

    let oldDate = new Date(newDate.getTime() - days * daysBack);

    this.props.getActionData(oldDate, newDate);
    this.props.getModuleData(oldDate, newDate);
    this.props.getUserData(oldDate, newDate);
  }

  getModuleUsage() {
    const { moduleData } = this.props;
    const { questionTopic } = this.state;

    let components = [];
    /*
    if (moduleData.distinctModuleHits && moduleData.distinctModuleHits.length) {
      components.push(
        <div className="stat-group">
          <div className="stat-header">
            <h3>Unique Visits</h3>
          </div>
          <div className="stat-body">
            {moduleData.distinctModuleHits.map(d => (
              <SmallStat key={d.key} title={d.key} value={d.value} />
            ))}
          </div>
        </div>
      );
    }
*/
    if (
      moduleData.totalModuleHits &&
      moduleData.totalModuleHits.length &&
      (questionTopic === '' || questionTopic === 'modules')
    ) {
      let total = moduleData.totalModuleHits.reduce(
        (acc, curr) => curr.value + acc,
        0,
      );

      components.push(
        <div className="stat-group">
          <div className="stat-header">
            <h3>Module Access</h3>
          </div>
          <div className="stat-body  stat-body--x2 fixed-height">
            {moduleData.totalModuleHits.map(d => (
              <SmallStat
                key={d.key}
                title={d.key}
                value={d.value}
                totalValue={total}
              />
            ))}
          </div>
        </div>,
      );
    }

    if (
      moduleData.modulesUsedPerUser &&
      moduleData.modulesUsedPerUser.length &&
      (questionTopic === '' || questionTopic === 'modules')
    ) {
      components.push(
        <div className="stat-group">
          <div className="stat-header">
            <h3>Modules Accessed per User</h3>
          </div>
          <div className="stat-body">
            <V.VictoryPie
              barRatio={0.8}
              colorScale={[
                '#0F8296',
                '#00633C',
                '#51B7C9',
                '#c43a31',
                '#960F4B',
              ]}
              theme={V.VictoryTheme.material}
              data={moduleData.modulesUsedPerUser
                .map(d => {
                  if (d.value) return { x: d.key, y: d.value };
                  return null;
                })
                .filter(x => x != null)}
            />
          </div>
        </div>,
      );
    }
    return components;
  }

  getUserSessions() {
    const { userData } = this.props;
    const { questionTopic } = this.state;

    let components = [];

    if (
      userData.totalVisitors &&
      userData.totalVisitors.length &&
      (questionTopic === '' || questionTopic === 'users')
    ) {
      components.push(
        <div className="stat-group">
          <div className="stat-header">
            <h3>Total User Logins</h3>
          </div>
          <div className="stat-body  stat-body--x2">
            <V.VictoryChart
              theme={V.VictoryTheme.material}
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 },
              }}
              height={220}
              scale={{ x: 'time' }}
              padding={{ top: 10, bottom: 30, left: 30, right: 20 }}
              domainPadding={{ x: 15 }}>
              <V.VictoryBar
                barRatio={0.8}
                cornerRadius={{ topLeft: 10 }}
                style={{
                  data: { fill: '#c43a31' },
                }}
                data={userData.totalVisitors.map(d => {
                  return { x: new Date(d.key), y: d.value };
                })}
              />
            </V.VictoryChart>
          </div>
        </div>,
      );
    }

    if (
      userData.sessionLengths &&
      userData.sessionLengths.length &&
      (questionTopic === '' || questionTopic === 'users')
    ) {
      components.push(
        <div className="stat-group">
          <div className="stat-header">
            <h3>User Session Durations (minutes)</h3>
          </div>
          <div className="stat-body  stat-body--x2">
            <V.VictoryChart
              theme={V.VictoryTheme.material}
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 },
              }}
              height={220}
              padding={{ top: 10, bottom: 30, left: 30, right: 10 }}>
              <V.VictoryArea
                style={{
                  data: { stroke: '#c43a31', fill: '#c43a31' },
                  parent: { border: '1px solid #ccc' },
                }}
                interpolation="natural"
                data={userData.sessionLengths.map(d => {
                  return { x: d.key, y: d.value };
                })}
              />
            </V.VictoryChart>
          </div>
        </div>,
      );
    }

    if (
      userData.getLastActive &&
      userData.getLastActive.length &&
      (questionTopic === '' || questionTopic === 'users')
    ) {
      components.push(
        <div className="stat-group">
          <div className="stat-header">
            <h3>Users Last Active</h3>
          </div>
          <div className="stat-body">
            <V.VictoryPie
              colorScale={[
                '#0F8296',
                '#00633C',
                '#51B7C9',
                '#c43a31',
                '#960F4B',
              ]}
              barRatio={0.8}
              scale={{ x: 'time' }}
              data={userData.getLastActive
                .map(d => {
                  if (d.value)
                    return {
                      x:
                        new Date(d.key).toLocaleDateString() +
                        '\r\n' +
                        new Date(d.key).toLocaleTimeString(),
                      y: d.value,
                    };
                  return null;
                })
                .filter(x => x != null)}
            />
          </div>
        </div>,
      );
    }

    return components;
  }

  getActionData() {
    const { actionData } = this.props;
    const { questionTopic } = this.state;

    let components = [];

    if (actionData.data) {
      if (
        actionData.data.documentActions &&
        actionData.data.documentActions.length &&
        (questionTopic === '' || questionTopic === 'documents')
      ) {
        let total = actionData.data.documentActions.reduce(
          (acc, curr) => curr.value + acc,
          0,
        );
        components.push(
          <div className="stat-group">
            <div className="stat-header">
              <h3>Document Actions</h3>
            </div>
            <div className="stat-body">
              {actionData.data.documentActions.map(d => (
                <SmallStat
                  key={d.key}
                  title={d.key}
                  value={d.value}
                  totalValue={total}
                />
              ))}
            </div>
          </div>,
        );
      }

      if (
        actionData.data.feedBackActions &&
        actionData.data.feedBackActions.length &&
        (questionTopic === '' || questionTopic === 'feedback')
      ) {
        let total = actionData.data.feedBackActions.reduce(
          (acc, curr) => curr.value + acc,
          0,
        );

        components.push(
          <div className="stat-group">
            <div className="stat-header">
              <h3>Feedback Actions</h3>
            </div>
            <div className="stat-body">
              {actionData.data.feedBackActions.map(d => (
                <SmallStat
                  key={d.key}
                  title={d.key}
                  value={d.value}
                  totalValue={total}
                />
              ))}
            </div>
          </div>,
        );
      }

      if (
        actionData.data.myDocumentActions &&
        actionData.data.myDocumentActions.length &&
        (questionTopic === '' || questionTopic === 'documents')
      ) {
        let total = actionData.data.myDocumentActions.reduce(
          (acc, curr) => curr.value + acc,
          0,
        );

        components.push(
          <div className="stat-group">
            <div className="stat-header">
              <h3>My Document Actions</h3>
            </div>
            <div className="stat-body">
              {actionData.data.myDocumentActions.map(d => (
                <SmallStat
                  key={d.key}
                  title={d.key}
                  value={d.value}
                  totalValue={total}
                />
              ))}
            </div>
          </div>,
        );
      }
    }

    if (
      actionData.portalFeedbackRating &&
      (questionTopic === '' || questionTopic === 'feedback')
    ) {
      components.push(
        <div className="stat-group">
          <div className="stat-header">
            <h3>Average MyStarbucks Feedback Rating</h3>
          </div>
          <div className="stat-body">
            <StarRating rating={actionData.portalFeedbackRating}></StarRating>
          </div>
        </div>,
      );
    }

    if (
      actionData.supplierFeedbackRating &&
      (questionTopic === '' || questionTopic === 'feedback')
    ) {
      components.push(
        <div className="stat-group">
          <div className="stat-header">
            <h3>Average Supplier Feedback Rating</h3>
          </div>
          <div className="stat-body">
            <StarRating rating={actionData.supplierFeedbackRating}></StarRating>
          </div>
        </div>,
      );
    }

    if (
      actionData.productFeedbackRating &&
      (questionTopic === '' || questionTopic === 'feedback')
    ) {
      components.push(
        <div className="stat-group">
          <div className="stat-header">
            <h3>Average Product Feedback Rating</h3>
          </div>
          <div className="stat-body">
            <StarRating rating={actionData.productFeedbackRating}></StarRating>
          </div>
        </div>,
      );
    }

    if (
      actionData.supplierFeedbackWords &&
      actionData.supplierFeedbackWords.length &&
      (questionTopic === '' || questionTopic === 'feedback')
    ) {
      let max = actionData.supplierFeedbackWords.reduce(
        (acc, curr) => (curr.value > acc ? curr.value : acc),
        0,
      );
      let topTier = max - max * 0.9;
      let middleTier = max - max * 0.5;

      components.push(
        <div className="stat-group">
          <div className="stat-header">
            <h3>Supplier Feedback Most Used Words</h3>
          </div>
          <div className="stat-body word-cloud  stat-body--x2">
            <TagCloud
              style={{
                fontFamily: 'sans-serif',
                fontSize: 30,
                fontWeight: 'bold',
                fontStyle: 'italic',
                padding: 5,
                width: '100%',
                height: '100%',
              }}>
              {actionData.supplierFeedbackWords.map(s => (
                <div
                  style={{
                    fontSize:
                      s.value > topTier ? 24 : s.value > middleTier ? 16 : 12,
                  }}>
                  {s.key}
                </div>
              ))}
            </TagCloud>
          </div>
        </div>,
      );
    }

    if (
      actionData.productFeedbackWords &&
      actionData.productFeedbackWords.length &&
      (questionTopic === '' || questionTopic === 'feedback')
    ) {
      let max = actionData.productFeedbackWords.reduce(
        (acc, curr) => (curr.value > acc ? curr.value : acc),
        0,
      );
      let topTier = max - max * 0.9;
      let middleTier = max - max * 0.5;

      components.push(
        <div className="stat-group">
          <div className="stat-header">
            <h3>Product Feedback Most Used Words</h3>
          </div>
          <div className="stat-body word-cloud  stat-body--x2">
            <TagCloud
              style={{
                fontFamily: 'sans-serif',
                fontSize: 30,
                fontWeight: 'bold',
                fontStyle: 'italic',
                padding: 5,
                width: '100%',
                height: '100%',
              }}>
              {actionData.productFeedbackWords.map(s => (
                <div
                  style={{
                    fontSize:
                      s.value > topTier ? 24 : s.value > middleTier ? 16 : 12,
                  }}>
                  {s.key}
                </div>
              ))}
            </TagCloud>
          </div>
        </div>,
      );
    }

    if (
      actionData.portalFeedbackWords &&
      actionData.portalFeedbackWords.length &&
      (questionTopic === '' || questionTopic === 'feedback')
    ) {
      let max = actionData.portalFeedbackWords.reduce(
        (acc, curr) => (curr.value > acc ? curr.value : acc),
        0,
      );
      let topTier = max - max * 0.9;
      let middleTier = max - max * 0.5;

      components.push(
        <div className="stat-group">
          <div className="stat-header">
            <h3>MyStarbucks Feedback - Most Used Words</h3>
          </div>
          <div className="stat-body word-cloud  stat-body--x2">
            <TagCloud
              style={{
                fontFamily: 'sans-serif',
                fontSize: 30,
                fontWeight: 'bold',
                fontStyle: 'italic',
                padding: 5,
                width: '100%',
                height: '100%',
              }}>
              {actionData.portalFeedbackWords.map(s => (
                <div
                  style={{
                    fontSize:
                      s.value > topTier ? 24 : s.value > middleTier ? 16 : 12,
                  }}>
                  {s.key}
                </div>
              ))}
            </TagCloud>
          </div>
        </div>,
      );
    }

    return components;
  }

  render() {
    const { timePeriod, questionTopic } = this.state;
    const {
      moduleDataIsLoaded,
      userDataIsLoaded,
      actionDataIsLoaded,
    } = this.props;
    const {
      handleChangeTab,
      getModuleUsage,
      getUserSessions,
      getActionData,
      handleQuestionTopic,
    } = this;
    return (
      <div className="platform-usage-page page dashboard">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className={'icon icon-speedo'}></div>
              <div>
                <h2>Platform Usage</h2>
                <p>
                  Review and analyse data about how the My Starbucks portal is
                  being utilised by customers and which functionality they are
                  using.
                </p>
              </div>
            </div>
            <div className="group-details accordion">
              <div className="accordion-body vertical-controls">
                <div className="horizontal-controls">
                  <div className="tab-container">
                    <div
                      className={`tab-button ${
                        timePeriod === 30 ? 'selected' : ''
                      }`}
                      onClick={() => handleChangeTab(30)}>
                      Last 30 days
                    </div>
                    <div
                      className={`tab-button ${
                        timePeriod === 7 ? 'selected' : ''
                      }`}
                      onClick={() => handleChangeTab(7)}>
                      Last 7 days
                    </div>
                    <div
                      className={`tab-button ${
                        timePeriod === 1 ? 'selected' : ''
                      }`}
                      onClick={() => handleChangeTab(1)}>
                      Today
                    </div>
                  </div>
                </div>
                <select
                  onChange={e => handleQuestionTopic(e)}
                  value={questionTopic}>
                  <option value="">Ask a question...</option>
                  <option value="modules">
                    What functionality are users accessing?
                  </option>
                  <option value="users">
                    How frequently do users access the portal?
                  </option>
                  <option value="feedback">
                    What feedback are users submitting?
                  </option>
                  <option value="documents">
                    How are users interacting with documents?
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="stat-container">
          {moduleDataIsLoaded && getModuleUsage()}
          {userDataIsLoaded && getUserSessions()}
          {actionDataIsLoaded && getActionData()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  moduleDataIsLoaded: state.platformUsage.moduleData.isLoaded,
  userDataIsLoaded: state.platformUsage.userData.isLoaded,
  actionDataIsLoaded: state.platformUsage.actionData.isLoaded,

  moduleData: state.platformUsage.moduleData.data,
  userData: state.platformUsage.userData.data,
  actionData: state.platformUsage.actionData.data,
});

const mapDispatchToProps = dispatch => {
  return {
    getModuleData: (from, to) =>
      dispatch(platformUsageActions.requestModuleData(from, to)),
    getUserData: (from, to) =>
      dispatch(platformUsageActions.requestUserData(from, to)),
    getActionData: (from, to) =>
      dispatch(platformUsageActions.requestActionData(from, to)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlatformUsage);
