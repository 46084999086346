import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';

class LoginPage extends React.Component {
  getYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
      <div className="login-page page">
        <div className="login-text">
          <div className="login-form">
            <div className="logo"
              onClick={() => this.props.push('/')} />
            <h2>Welcome to the MyStarbucks Portal</h2>
            {this.props.children}
          </div>
          <footer>
            <div className="orderly-logo-footer"
              onClick={() => window.location = ('https://orderly.io')}>
              ©
              {this.getYear()}
              {' '}
              Powered by
              <img
                className="orderly-logo"
                src="/assets/logo-orderly.png"
                alt="orderly logo"
              />
            </div>
            <div className="footer-links">
              <button
                type="button"
                className="link"
                onClick={() => this.props.push('/forgottenpassword')}
              >
                Trouble logging in?
              </button>
              {/*<button
                type="button"
                className="link"
                onClick={() => this.props.push('/WhatIsMyStarbucks')}
              >
                What is the MyStarbucks Portal?
              </button>*/}
            </div>
          </footer>
        </div>
        <div className="login-picture" />
        <footer
          className="orderly-footer"
          onClick={() => window.location = ('https://orderly.io')}
        >
          ©
          {this.getYear()}
          {' '}
          Powered by
          <img
            className="orderly-logo"
            src="/assets/logo-orderly.png"
            alt="orderly logo"
          />
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(navigationActions.pushNavigation(path)),
  replace: (path) => dispatch(navigationActions.replaceNavigation(path)),
  reset: () => dispatch(navigationActions.resetNavigation()),
  goBack: () => dispatch(navigationActions.backNavigation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
