import { LOGOUT_SUCCESS } from '../actions/actionTypes';

const httpMiddleware = (store) => (next) => (action) => {
  if (action.http) {
    const actionInfo = action.http;
    const defaultHeaders = { 'Content-Type': 'application/json' };

    const token = localStorage.getItem('token');

    if (token) {
      defaultHeaders.Authorization = `Bearer ${token}`;
    }

    const fetchOptions = {
      method: actionInfo.verb,
      headers: { ...defaultHeaders, ...actionInfo.headers },
    };

    if (actionInfo.body) {
      fetchOptions.body = JSON.stringify(actionInfo.body);
    }

    next({
      type: action.type,
      payload: action.payload,
    });

    let filename = '';

    fetch(actionInfo.endpoint, fetchOptions)
      .then((response) => {
        const contentType = response.headers.get('content-type') || '';

        if (!response.ok) {
          if (response.status === 401 && action.type !== 'LOGIN_REQUEST') {
            return next({
              type: LOGOUT_SUCCESS,
              payload: {
                message: 'Your session has expired.',
              },
            });
          }
          if (response.status === 403) {
            throw new Error(
              'You do not currently have access to this area. Please contact your administrator.',
            );
          }

          if (response.status === 400) {
            if (contentType.indexOf('text/plain') > -1) {
              return response.text();
            }

            throw new Error(
              'An error occurred',
            );
          }

          if (contentType.indexOf('text/plain') > -1) {
            return response.text();
          }

          return response.json();
        }

        if (contentType === '0' || contentType === '') {
          return {};
        }

        if (
          response.headers.get('content-disposition') !== undefined
          && response.headers.get('content-disposition') !== null
        ) {
          filename = response.headers.get('content-disposition');
          let nameIndex = filename.indexOf('filename="');

          if (nameIndex > -1) {
            filename = filename.slice(nameIndex + 10);
            nameIndex = filename.indexOf('"');

            if (nameIndex > -1) {
              filename = filename.slice(0, nameIndex);
            }
          } else {
            nameIndex = filename.indexOf('filename=');
            if (nameIndex > -1) {
              filename = filename.slice(nameIndex + 9);

              nameIndex = filename.indexOf(';');

              if (nameIndex > -1) {
                filename = filename.slice(0, nameIndex);
              }
            }
          }

          return response.blob();
        }

        if (
          contentType.indexOf('text/plain') > -1
        ) {
          return response.text();
        }

        return response.json();
      })
      .then((data) => {
        if (data && !data.payload) {
          if (typeof data === 'string') {
            throw new Error(data);
          } else if (data.message) {
            throw new Error(data.message);
          } else if (data.errorMessage) {
            throw new Error(data.errorMessage);
          } else if (data.constructor.name === 'Blob') {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(data, filename);
            } else {
              const a = document.createElement('a');
              document.body.appendChild(a);
              const url = window.URL.createObjectURL(data);
              a.href = url;
              a.download = filename;
              a.click();
              setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
              }, 0);
            }

            next({ type: actionInfo.successAction });
          } else {
            next({
              type: actionInfo.successAction,
              payload: data,
            });
          }
        }
      })
      .catch((error) => next({
        type: actionInfo.failureAction,
        payload: error,
      }));
  } else {
    return next(action);
  }
};

export default httpMiddleware;
