
import {  GETORDERMETRICS_SUCCESS, GETORDERMETRICS_FAILURE,
  GETSHIPMENTPERFORMANCE_SUCCESS, GETSHIPMENTPERFORMANCE_FAILURE,
  GETPRODUCTCATEGORIES_SUCCESS, GETPRODUCTCATEGORIES_FAILURE,
  GETMBUS_SUCCESS,
  GETMBUS_FAILURE,
  GETEMERGENCYORDER_SUCCESS,
  GETEMERGENCYORDER_FAILURE,
  GETFORECASTCHANGEREQUEST_SUCCESS,
  GETFORECASTCHANGEREQUEST_FAILURE,
  GETKPI_SUCCESS,
  GETKPI_FAILURE,
  GETMAPE_REQUEST,
  GETMAPE_SUCCESS,
  GETMAPE_FAILURE,
  GETMAPEBYCATEGORY_REQUEST,
  GETMAPEBYCATEGORY_SUCCESS,
  GETMAPEBYCATEGORY_FAILURE,
  GETMAPEBYTOPITEM_REQUEST,
  GETMAPEBYTOPITEM_SUCCESS,
  GETMAPEBYTOPITEM_FAILURE,
  GETFORECASTDASHBOARD_REQUEST,
  GETFORECASTVSORDER_SUCCESS,
  EXPORTDATA_REQUEST,
  EXPORTDATA_SUCCESS,
  EXPORTDATA_FAILURE,
  GETKPIREFERENCEDATA_SUCCESS,
  GETKPIREFERENCEDATA_FAILURE,
  GETFORECASTVSORDER_FAILURE } from './actionTypes';

import { FORECAST_DASHBOARD_PERFORMANCE_BASE_URL } from './actionEndpointBaseUrl';
  
const forecastPerformanceDashboardActions = {

  getOrderMetricsChartData: (timefilter, selectedMonths, selectedMarkets) => (dispatch) => {

    const selectedMonthsQueryString = selectedMonths.map(month => `selectedMonths=${month}`).join('&');
    const selectedMarketsQueryString = selectedMarkets.map(market => `selectedMarkets=${market}`).join('&');

    const endpoint = `${FORECAST_DASHBOARD_PERFORMANCE_BASE_URL}/GetOrderMetrics?timefilter=${timefilter}&${selectedMonthsQueryString}&${selectedMarketsQueryString}`;
 
    dispatch({
      type: GETFORECASTDASHBOARD_REQUEST ,
      http: {
        verb: 'GET',
        endpoint: endpoint,
        successAction: GETORDERMETRICS_SUCCESS,
        failureAction: GETORDERMETRICS_FAILURE,
      },
    });
  },

  getShipmentPerformanceChartData: (timefilter, selectedMonths) => (dispatch) => {
    const selectedMonthsQueryString = selectedMonths.map(month => `selectedMonths=${month}`).join('&');

    dispatch({
      type: GETFORECASTDASHBOARD_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_DASHBOARD_PERFORMANCE_BASE_URL}/GetShipmentPerformance?timefilter=${timefilter}&${selectedMonthsQueryString}`,
        successAction: GETSHIPMENTPERFORMANCE_SUCCESS,
        failureAction: GETSHIPMENTPERFORMANCE_FAILURE,
      },
    });
  },

  getForecastVsOrderChartData: (timefilter, selectedMonths, selectedMarkets) => (dispatch) => {

    const selectedMonthsQueryString = selectedMonths.map(month => `selectedMonths=${month}`).join('&');
    const selectedMarketsQueryString = selectedMarkets.map(market => `selectedMarkets=${market}`).join('&');

    const endpoint = `${FORECAST_DASHBOARD_PERFORMANCE_BASE_URL}/GetForecastVsOrder?timefilter=${timefilter}&${selectedMonthsQueryString}&${selectedMarketsQueryString}`;
 
    dispatch({
      type: GETFORECASTDASHBOARD_REQUEST,
      http: {
        verb: 'GET',
        endpoint: endpoint,
        successAction: GETFORECASTVSORDER_SUCCESS,
        failureAction: GETFORECASTVSORDER_FAILURE,
      },
    });
  },


  getKpiChartData: (timefilter, selectedMonths, selectedMarkets, supplierNames, orgs, reasonCodes, itemName) => (dispatch) => {
    const selectedMonthsQueryString = selectedMonths.map(month => `selectedMonths=${month}`).join('&');
    const selectedMarketsQueryString = selectedMarkets.map(m => `selectedMarkets=${m}`).join('&');
    const supplierNamesQueryString = supplierNames.map(m => `supplierNames=${m}`).join('&');
    const orgsQueryString = orgs.map(m => `orgs=${m}`).join('&');
    const reasonCodesQueryString = reasonCodes.map(m => `reasonCodes=${m}`).join('&');

    dispatch({
      type: GETFORECASTDASHBOARD_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_DASHBOARD_PERFORMANCE_BASE_URL}/GetKpiDashboard?timefilter=${timefilter}&${selectedMonthsQueryString}` +
         `&${selectedMarketsQueryString}` +
         `&${supplierNamesQueryString}`+
         `&${orgsQueryString}`+
         `&${reasonCodesQueryString}`+
         `${itemName ? `&itemName=${encodeURIComponent(itemName)}` : ''}`,
        successAction: GETKPI_SUCCESS,
        failureAction: GETKPI_FAILURE,
      },
    });
  },

  exportKpiMetrics: (timefilter, selectedMonths, selectedMarkets, supplierNames, orgs, reasonCodes, itemName) => (dispatch) => {
    const selectedMonthsQueryString = selectedMonths.map(month => `selectedMonths=${month}`).join('&');
    const selectedMarketsQueryString = selectedMarkets.map(m => `selectedMarkets=${m}`).join('&');
    const supplierNamesQueryString = supplierNames.map(m => `supplierNames=${m}`).join('&');
    const orgsQueryString = orgs.map(m => `orgs=${m}`).join('&');
    const reasonCodesQueryString = reasonCodes.map(m => `reasonCodes=${m}`).join('&');
    
    dispatch({
      type: EXPORTDATA_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_DASHBOARD_PERFORMANCE_BASE_URL}/ExportKpiData?timefilter=${timefilter}&${selectedMonthsQueryString}` +
         `&${selectedMarketsQueryString}` +
         `&${supplierNamesQueryString}`+
         `&${orgsQueryString}`+
         `&${reasonCodesQueryString}`+
         `${itemName ? `&itemName=${encodeURIComponent(itemName)}` : ''}`,
        successAction: EXPORTDATA_SUCCESS,
        failureAction: EXPORTDATA_FAILURE,
      },
    });
  },
   
  getEmergencyOrderChartData: (timefilter, selectedMonths, selectedMarkets) => (dispatch) => {
    const selectedMonthsQueryString = selectedMonths.map(month => `selectedMonths=${month}`).join('&');
    const selectedMarketsQueryString = selectedMarkets.map(market => `selectedMarkets=${market}`).join('&');

    const endpoint = `${FORECAST_DASHBOARD_PERFORMANCE_BASE_URL}/GetEmergencyOrders?timefilter=${timefilter}&${selectedMonthsQueryString}&${selectedMarketsQueryString}`;
 
    dispatch({
      type: GETFORECASTDASHBOARD_REQUEST,
      http: {
        verb: 'GET',
        endpoint: endpoint,
        successAction: GETEMERGENCYORDER_SUCCESS,
        failureAction: GETEMERGENCYORDER_FAILURE,
      },
    });
  },

  
  getForecastChangeRequestChartData: (timefilter, selectedMonths, selectedMarkets) => (dispatch) => {
    const selectedMonthsQueryString = selectedMonths.map(month => `selectedMonths=${month}`).join('&');
    const selectedMarketsQueryString = selectedMarkets.map(market => `selectedMarkets=${market}`).join('&');

    const endpoint = `${FORECAST_DASHBOARD_PERFORMANCE_BASE_URL}/GetForecastChangeRequests?timefilter=${timefilter}&${selectedMonthsQueryString}&${selectedMarketsQueryString}`;
 

    dispatch({
      type: GETFORECASTDASHBOARD_REQUEST,
      http: {
        verb: 'GET',
        endpoint: endpoint,
        successAction: GETFORECASTCHANGEREQUEST_SUCCESS,
        failureAction: GETFORECASTCHANGEREQUEST_FAILURE,
      },
    });
  },

  getForecastVarianceChart: (chartType, selectedMarketsIds, categoryTimeFilter, mbuForCategoryIds, categoryId, topTenItemsTimeFilter, mbuForTop10ItemsIds) => (dispatch) => {
    const queryParams = [
      `chartType=${chartType}`,
      ...selectedMarketsIds.map(id => `selectedMarketsIds=${id}`),
      `categoryTimeFilter=${categoryTimeFilter}`,
      ...mbuForCategoryIds.map(id => `mbuForCategoryIds=${id}`),
      `categoryId=${categoryId}`, 
      `topTenItemsTimeFilter=${topTenItemsTimeFilter}`,
      ...mbuForTop10ItemsIds.map(id => `mbuForTop10ItemsIds=${id}`),
    ].join('&');

    const endpoint = `${FORECAST_DASHBOARD_PERFORMANCE_BASE_URL}/GetForecastVarianceChart?${queryParams}`;

    dispatch({
      type: GETMAPE_REQUEST,
      http: {
        verb: 'GET',
        endpoint: endpoint,
        successAction: GETMAPE_SUCCESS,
        failureAction: GETMAPE_FAILURE,
      },
    });
  },


  getMapeChartData: (selectedMarkets) => (dispatch) => {
    const selectedMarketsQueryString = selectedMarkets.map(market => `selectedMarkets=${market}`).join('&');
    const endpoint = `${FORECAST_DASHBOARD_PERFORMANCE_BASE_URL}/GetMapeYearlyChart?${selectedMarketsQueryString}`;
 
    dispatch({
      type: GETMAPE_REQUEST,
      http: {
        verb: 'GET',
        endpoint: endpoint,
        successAction: GETMAPE_SUCCESS,
        failureAction: GETMAPE_FAILURE,
      },
    });
  },
   

  getMapeByCategoryChartData: (categoryTimeFilter, selectedMonths, selectedMarkets, categoryId) => (dispatch) => {
    const selectedMonthsQueryString = selectedMonths.map(month => `selectedMonths=${month}`).join('&');
    const selectedMarketsQueryString = selectedMarkets.map(market => `selectedMarkets=${market}`).join('&');

    const endpoint = `${FORECAST_DASHBOARD_PERFORMANCE_BASE_URL}/GetMapeByCategoryChart?timefilter=${categoryTimeFilter}
    &${selectedMonthsQueryString}&${selectedMarketsQueryString}${categoryId ? `&categoryId=${categoryId}` : ''}`;
    
    dispatch({
      type: GETMAPEBYCATEGORY_REQUEST,
      http: {
        verb: 'GET',
        endpoint: endpoint,
        successAction: GETMAPEBYCATEGORY_SUCCESS,
        failureAction: GETMAPEBYCATEGORY_FAILURE,
      },
    });
  },


  getMapeByTopTenItemsChartData: (timefilter, selectedMonths, selectedMarkets) => (dispatch) => {
    const selectedMonthsQueryString = selectedMonths.map(month => `selectedMonths=${month}`).join('&');
    const selectedMarketsQueryString = selectedMarkets.map(market => `selectedMarkets=${market}`).join('&');

    const endpoint = `${FORECAST_DASHBOARD_PERFORMANCE_BASE_URL}/GetMapeTopTenItemChart?timefilter=${timefilter}&${selectedMonthsQueryString}&${selectedMarketsQueryString}`;
 

    dispatch({
      type: GETMAPEBYTOPITEM_REQUEST,
      http: {
        verb: 'GET',
        endpoint: endpoint,
        successAction: GETMAPEBYTOPITEM_SUCCESS,
        failureAction: GETMAPEBYTOPITEM_FAILURE,
      },
    });
  },

  exportRawData: (exportChart, timefilter, selectedMonths, selectedMarkets, categoryId) => (dispatch) => {
    const selectedMonthsQueryString = selectedMonths.map(month => `selectedMonths=${month}`).join('&');
    const selectedMarketsQueryString = selectedMarkets.map(market => `selectedMarkets=${market}`).join('&');

    const endpoint = `${FORECAST_DASHBOARD_PERFORMANCE_BASE_URL}/ExportRawData?exportChart=${exportChart}&timefilter=${timefilter}
    &${selectedMonthsQueryString}&${selectedMarketsQueryString}${categoryId ? `&categoryId=${categoryId}` : ''}`;
    dispatch({
      type: EXPORTDATA_REQUEST,
      http: {
        verb: 'GET',
        endpoint: endpoint,
        successAction:  EXPORTDATA_SUCCESS,
        failureAction: EXPORTDATA_FAILURE,
      },
    });
  },

  getMbus: () => (dispatch) => {
    dispatch({
      type: '',
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_DASHBOARD_PERFORMANCE_BASE_URL}/GetMbus`,
        successAction: GETMBUS_SUCCESS,
        failureAction: GETMBUS_FAILURE,
      },
    });
  },

  getProductCategories: () => (dispatch) => {
    dispatch({
      type: '',
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_DASHBOARD_PERFORMANCE_BASE_URL}/GetProductCategories`,
        successAction: GETPRODUCTCATEGORIES_SUCCESS,
        failureAction: GETPRODUCTCATEGORIES_FAILURE,
      },
    });
  },

  getKpiReferenceData: () => (dispatch) => {
    dispatch({
      type: '',
      http: {
        verb: 'GET',
        endpoint: `${FORECAST_DASHBOARD_PERFORMANCE_BASE_URL}/GetKpiReferenceData`,
        successAction: GETKPIREFERENCEDATA_SUCCESS,
        failureAction: GETKPIREFERENCEDATA_FAILURE,
      },
    });
  },
};
  
export default forecastPerformanceDashboardActions;
  