import {
  INSTANTMESSAGEARCHIVE_REQUEST,
  INSTANTMESSAGEARCHIVE_SUCCESS,
  INSTANTMESSAGEARCHIVE_FAILURE,
  INSTANTMESSAGEARCHIVE_DETAIL_REQUEST,
  INSTANTMESSAGEARCHIVE_DETAIL_SUCCESS,
  INSTANTMESSAGEARCHIVE_DETAIL_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const messageArchiveReducer = (
  state = initialState.messageArchiveState,
  action,
) => {
  switch (action.type) {
  case INSTANTMESSAGEARCHIVE_REQUEST:
    return {
      ...state,
      messageHistory: {
        isLoading: true,
        isLoaded: false,
        data: [],
        errorMessage: '',
      },
    };

  case INSTANTMESSAGEARCHIVE_SUCCESS:
    return {
      ...state,
      messageHistory: {
        isLoading: true,
        isLoaded: false,
        data: action.payload,
        errorMessage: '',
      },
    };

  case INSTANTMESSAGEARCHIVE_FAILURE:
    return {
      ...state,
      messageHistory: {
        isLoading: false,
        isLoaded: false,
        data: [],
        errorMessage: action.payload.message
          ? action.payload.message
          : action.payload.errorMessage
            ? action.payload.errorMessage
            : 'An error has occurred',
      },
    };
  case INSTANTMESSAGEARCHIVE_DETAIL_REQUEST:
    return {
      ...state,
      messageDetail: {
        isLoading: true,
        isLoaded: false,
        data: [],
        errorMessage: '',
      },
    };

  case INSTANTMESSAGEARCHIVE_DETAIL_SUCCESS:
    return {
      ...state,
      messageDetail: {
        isLoading: true,
        isLoaded: false,
        data: action.payload,
        errorMessage: '',
      },
    };

  case INSTANTMESSAGEARCHIVE_DETAIL_FAILURE:
    return {
      ...state,
      messageDetail: {
        isLoading: false,
        isLoaded: false,
        data: [],
        errorMessage: action.payload.message
          ? action.payload.message
          : action.payload.errorMessage
            ? action.payload.errorMessage
            : 'An error has occurred',
      },
    };
  default:
    return state;
  }
};

export default messageArchiveReducer;
