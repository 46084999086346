import React from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import { instantMessagingArchiveActions } from '../../actions/messageArchiveActions';
import { navigationActions } from '../../actions/navigationActions';

class InstantMessagingArchive extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filter: '', page: 1, pageSize: 25, displayMode: 1 };

    this.handleSearch = this.handleSearch.bind(this);
    this.setDisplayMode = this.setDisplayMode.bind(this);

    this.doPaging = this.doPaging.bind(this);
    this.doSearch = this.doSearch.bind(this);

    this.navigateItemDetails = this.navigateItemDetails.bind(this);
  }

  componentDidMount() {
    const { search } = this.props.location;

    let newState = {
      pageSize: 25,
      filter: '',
      page: 1,
      displayMode: 1,
    };

    if (search) {
      const parsed = qs.parse(search.toLowerCase());

      newState = {
        ...newState,
        filter: parsed.filter ? parsed.filter : '',
        page: parsed.page ? parseInt(parsed.page) : 1,
        displayMode: parsed.displaymode ? parseInt(parsed.displaymode) : 1,
      };
    }

    this.setState(newState);

    this.props.getConversations(
      newState.filter,
      newState.page,
      newState.pageSize,
    );
  }

  handleSearch(e) {
    this.setState({ filter: e.target.value, page: 1 });
    e.preventDefault();
  }

  navigateItemDetails(conversationId) {
    const { pathname } = this.props.location;
    this.props.push(`${pathname}/${conversationId}`);
  }

  doSearch() {
    this.handleFilterChange({ ...this.state });
  }

  doPaging(page) {
    this.setState({ page });

    this.handleFilterChange({ ...this.state, page });
  }

  setDisplayMode(mode) {
    this.setState({ displayMode: mode });
  }

  handleFilterChange(currentState) {
    const { pathname } = this.props.location;
    const { filter, displayMode, pageSize, page } = currentState;

    this.props.replace(
      `${pathname}?filter=${filter}&page=${page}&displayMode=${displayMode}`,
    );

    this.props.getConversations(filter, page, pageSize);
  }

  render() {
    const {
      handleSearch,
      doSearch,
      setDisplayMode,
      doPaging,
      navigateItemDetails,
    } = this;

    const { filter, displayMode, page, pageSize } = this.state;

    const { messageHistory } = this.props;
    let rowCount = 0,
      pageCount = 0;

    if (messageHistory && messageHistory.data) {
      rowCount = messageHistory.totalRecords;
      pageCount =
        parseInt(rowCount / pageSize) + (rowCount % pageSize > 0 ? 1 : 0);
    }

    return (
      <div className="instant-messaging-archive-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className={'icon icon-messages'}></div>
              <h2>Instant Messaging Archive</h2>
            </div>
            <div className="header-buttons">
              <input
                type="text"
                placeholder="..."
                value={filter}
                onChange={e => handleSearch(e)}
              />
              <button
                type="button"
                className="action"
                onClick={() => doSearch()}>
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="item-catalogue-header page-header"></div>
        <div className="item-catalogue-container table-container">
          <div className="table-header">
            <div className="display-options">
              Table Size
              <button
                type="button"
                className="icon-display-mode-1"
                disabled={displayMode === 1}
                onClick={() => setDisplayMode(1)}></button>
              <button
                type="button"
                className="icon-display-mode-2"
                disabled={displayMode === 2}
                onClick={() => setDisplayMode(2)}></button>
              <button
                type="button"
                className="icon-display-mode-3"
                disabled={displayMode === 3}
                onClick={() => setDisplayMode(3)}></button>
            </div>
            <h2>Conversations</h2>
            <div className="pagination-controls">
              {rowCount === 0 && '0-0 of 0'}
              {rowCount > 0 &&
                `${page * pageSize - pageSize + 1}-${
                  page * pageSize < rowCount ? page * pageSize : rowCount
                } of ${rowCount}`}
              <button
                type="button"
                disabled={page === 1}
                onClick={() => doPaging(page - 1)}>
                {'<'}
              </button>
              <button
                type="button"
                disabled={page >= pageCount}
                onClick={() => doPaging(page + 1)}>
                {'>'}
              </button>
            </div>
          </div>
          <div className="table-body">
            <table className={`table-display-mode-${displayMode}`}>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Agent</th>
                  <th>Started Queuing</th>
                  <th>Started Talking</th>
                  <th>Terminated By</th>
                </tr>
              </thead>
              <tbody>
                {messageHistory &&
                  messageHistory.data &&
                  messageHistory.data.map(conversation => (
                    <tr
                      key={conversation.conversationId}
                      onClick={() =>
                        navigateItemDetails(conversation.conversationId)
                      }>
                      <td>{conversation.username}</td>
                      <td>{conversation.agent}</td>
                      <td>
                        {new Date(
                          conversation.queueStartTime + 'Z',
                        ).toLocaleDateString()}{' '}
                        {new Date(
                          conversation.queueStartTime + 'Z',
                        ).toLocaleTimeString()}
                      </td>
                      <td>
                        {new Date(
                          conversation.started + 'Z',
                        ).toLocaleDateString()}{' '}
                        {new Date(
                          conversation.started + 'Z',
                        ).toLocaleTimeString()}
                      </td>
                      <td>{conversation.terminatedBy}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="table-header">
            <div></div>
            <div className="pagination-controls">
              {rowCount === 0 && '0-0 of 0'}
              {rowCount > 0 &&
                `${page * pageSize - pageSize + 1}-${
                  page * pageSize < rowCount ? page * pageSize : rowCount
                } of ${rowCount}`}
              <button
                type="button"
                disabled={page === 1}
                onClick={() => doPaging(page - 1)}>
                {'<'}
              </button>
              <button
                type="button"
                disabled={page >= pageCount}
                onClick={() => doPaging(page + 1)}>
                {'>'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.messageArchive.messageHistory.isLoading,
  isLoaded: state.messageArchive.messageHistory.isLoaded,
  messageHistory: state.messageArchive.messageHistory.data,
  errorMessage: state.messageArchive.messageHistory.errorMessage,
});

const mapDispatchToProps = dispatch => ({
  getConversations: (filter, page, pageSize) =>
    dispatch(
      instantMessagingArchiveActions.requestMessageArchive(
        filter,
        page,
        pageSize,
      ),
    ),

  push: path => dispatch(navigationActions.pushNavigation(path)),
  replace: path => dispatch(navigationActions.replaceNavigation(path)),
  reset: () => dispatch(navigationActions.resetNavigation()),
  goBack: () => dispatch(navigationActions.backNavigation()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InstantMessagingArchive);
