import {
  RSLPERFORMANCE_REQUEST,
  RSLPERFORMANCE_SUCCESS,
  RSLPERFORMANCE_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const rslPerformanceReducer = (
  state = initialState.rslPerformanceState,
  action = {},
) => {
  switch (action.type) {
  case RSLPERFORMANCE_REQUEST: {
    return {
      isFetching: true,
      isLoaded: false,
      rslPerformanceList: [],
      errorMessage: '',
    };
  }
  case RSLPERFORMANCE_SUCCESS: {
    return {
      isFetching: false,
      isLoaded: true,
      rslPerformanceList: action.payload.data,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      totalRecords: action.payload.totalRecords,
      errorMessage: '',
    };
  }
  case RSLPERFORMANCE_FAILURE: {
    return {
      ...state,
      isFetching: false,
      rslPerformanceList: [],
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default rslPerformanceReducer;
