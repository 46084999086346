import {
  ORDERTRACKER_CLEARSTATE,
  ORDERTRACKER_GETORDER_REQUEST,
  ORDERTRACKER_GETORDER_SUCCESS,
  ORDERTRACKER_GETORDER_FAILURE,
  ORDERTRACKER_SAVEORRDER_REQUEST,
  ORDERTRACKER_SAVEORRDER_SUCCESS,
  ORDERTRACKER_SAVEORRDER_FAILURE,
  ORDERTRACKER_SEARCH_REQUEST,
  ORDERTRACKER_SEARCH_SUCCESS,
  ORDERTRACKER_SEARCH_FAILURE,
  ORDERTRACKER_EXPORT_REQUEST,
  ORDERTRACKER_EXPORT_SUCCESS,
  ORDERTRACKER_EXPORT_FAILURE,
  ORDERTRACKER_CUSTOMERSUMMARY_REQUEST,
  ORDERTRACKER_CUSTOMERSUMMARY_SUCCESS,
  ORDERTRACKER_CUSTOMERSUMMARY_FAILURE,
  ORDERTRACKER_CONTACT_REQUEST,
  ORDERTRACKER_CONTACT_SUCCESS,
  ORDERTRACKER_CONTACT_FAILURE,
  ORDERTRACKER_LOADBULK_REQUEST,
  ORDERTRACKER_LOADBULK_SUCCESS,
  ORDERTRACKER_LOADBULK_FAILURE,
  ORDERTRACKER_SAVEBULK_REQUEST,
  ORDERTRACKER_SAVEBULK_SUCCESS,
  ORDERTRACKER_SAVEBULK_FAILURE,
  ORDERTRACKER_DASHBOARD_REQUEST,
  ORDERTRACKER_DASHBOARD_SUCCESS,
  ORDERTRACKER_DASHBOARD_FAILURE,
  ORDERTRACKER_DASHBOARDCUSTOMER_SUCCESS,
  ORDERTRACKER_CUSTOMERSEARCH_SUCCESS,
  ORDERTRACKER_CUSTOMERSTORES_REQUEST,
  ORDERTRACKER_CUSTOMERSTORES_SUCCESS,
} from '../actions/actionTypes';
import initialState from './initialState';

const orderTrackerReducer = (state = initialState.orderTrackerState, action) => {
  switch (action.type) {
  case ORDERTRACKER_CLEARSTATE:
    return {
      ...initialState.orderTrackerState,
      customerStores: [...state.customerStores],
    };
  case ORDERTRACKER_SAVEORRDER_REQUEST:
  case ORDERTRACKER_SEARCH_REQUEST:
  case ORDERTRACKER_EXPORT_REQUEST:
  case ORDERTRACKER_GETORDER_REQUEST:
  case ORDERTRACKER_CUSTOMERSUMMARY_REQUEST:
  case ORDERTRACKER_CONTACT_REQUEST:
  case ORDERTRACKER_DASHBOARD_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      isContactSubmitted: false,
      error: undefined,
      order: undefined,
    };
  case ORDERTRACKER_LOADBULK_REQUEST:
    return {
      ...state,
      isLoading: true,
      isContactSubmitted: false,
      error: undefined,
      order: undefined,
    };
  case ORDERTRACKER_SAVEORRDER_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: undefined,
    };
  case ORDERTRACKER_SAVEORRDER_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: action.payload.message,
    };
  case ORDERTRACKER_SEARCH_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      searchResults: action.payload,
    };
  case ORDERTRACKER_SEARCH_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      searchResults: [],
      error: action.payload.message,
    };
  case ORDERTRACKER_EXPORT_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
    };
  case ORDERTRACKER_EXPORT_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: action.payload.message,
    };
  case ORDERTRACKER_GETORDER_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      order: action.payload,
    };
  case ORDERTRACKER_GETORDER_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: action.payload.message,
    };
  case ORDERTRACKER_CUSTOMERSUMMARY_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      searchResults: action.payload,
    };
  case ORDERTRACKER_CUSTOMERSUMMARY_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      searchResults: [],
      error: action.payload.message,
    };
  case ORDERTRACKER_CONTACT_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      isContactSubmitted: true,
    };
  case ORDERTRACKER_CONTACT_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: action.payload.message,
    };
  case ORDERTRACKER_LOADBULK_SUCCESS:
    return {
      ...state,
      isLoading: false,
      bulkOrderList: action.payload,
    };
  case ORDERTRACKER_LOADBULK_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: action.payload.message,
    };
  case ORDERTRACKER_SAVEBULK_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      error: '',
    };
  case ORDERTRACKER_SAVEBULK_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
    };
  case ORDERTRACKER_SAVEBULK_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: action.payload.message,
    };
  case ORDERTRACKER_DASHBOARD_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      dashboard: action.payload,
    };
  case ORDERTRACKER_DASHBOARD_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: action.payload.message,
      dashboard: undefined,
    };
  case ORDERTRACKER_DASHBOARDCUSTOMER_SUCCESS:
    return {
      ...state,
      dashboardCustomers: action.payload,
    };
  case ORDERTRACKER_CUSTOMERSEARCH_SUCCESS:
    return {
      ...state,
      customers: action.payload,
    };
  case ORDERTRACKER_CUSTOMERSTORES_REQUEST:
    return {
      ...state,
      customerStores: [],
    };
  case ORDERTRACKER_CUSTOMERSTORES_SUCCESS:
    return {
      ...state,
      customerStores: action.payload,
    };
  default:
    return state;
  }
};

export default orderTrackerReducer;
