import {
  SUPPLIERFEEDBACK_REQUEST,
  SUPPLIERFEEDBACK_SUCCESS,
  SUPPLIERFEEDBACK_FAILURE,
  PRODUCTFEEDBACK_REQUEST,
  PRODUCTFEEDBACK_SUCCESS,
  PRODUCTFEEDBACK_FAILURE,
  PORTALFEEDBACK_REQUEST,
  PORTALFEEDBACK_SUCCESS,
  PORTALFEEDBACK_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const feedbackReducer = (state = initialState.feedbackState, action) => {
  switch (action.type) {
  case SUPPLIERFEEDBACK_REQUEST:
  case PRODUCTFEEDBACK_REQUEST:
  case PORTALFEEDBACK_REQUEST: {
    return {
      ...state,
      feedbackSaving: true,
      feedbackSaved: false,
      errorMessage: '',
    };
  }
  case SUPPLIERFEEDBACK_SUCCESS:
  case PRODUCTFEEDBACK_SUCCESS:
  case PORTALFEEDBACK_SUCCESS: {
    return {
      ...state,
      feedbackSaving: false,
      feedbackSaved: true,
      errorMessage: '',
    };
  }
  case SUPPLIERFEEDBACK_FAILURE:
  case PRODUCTFEEDBACK_FAILURE:
  case PORTALFEEDBACK_FAILURE: {
    return {
      ...state,
      feedbackSaving: false,
      feedbackSaved: false,
      errorMessage: action.payload.message
        ? action.payload.message
        : action.payload.errorMessage
          ? action.payload.errorMessage
          : 'An error has occurred',
    };
  }
  default:
    return state;
  }
};
export default feedbackReducer;
