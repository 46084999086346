import {
  SITEID_DTMP_REQUEST,
  SITEID_DTMP_SUCCESS,
  SITEID_DTMP_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const SiteIDDriveThroughReducer = (
  state = initialState.siteIDDriveThroughState,
  action = {},
) => {
  switch (action.type) {
  case SITEID_DTMP_REQUEST: {
    return {
      ...initialState.siteIDDriveThroughState,
    };
  }
  case SITEID_DTMP_SUCCESS: {
    return {
      ...initialState.siteIDDriveThroughState,
      isLoaded: true,
      marketPlan: action.payload.dtmps,
    };
  }
  case SITEID_DTMP_FAILURE: {
    return {
      ...initialState.siteIDDriveThroughState,
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default SiteIDDriveThroughReducer;
