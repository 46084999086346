import React from 'react';


const TableViewControls = props => {
  const { displayMode, setDisplayMode } = props;
 
  
  return (
    <div className="display-options">Table Size
      <button type="button"
        className="icon-display-mode-1"
        disabled={displayMode === 1}
        onClick={() => setDisplayMode(1)}
      >
      </button>
      <button type="button"
        className="icon-display-mode-2"
        disabled={displayMode === 2}
        onClick={() => setDisplayMode(2)}
      ></button>
      <button type="button"
        className="icon-display-mode-3"
        disabled={displayMode === 3}
        onClick={() => setDisplayMode(3)}
      ></button>
    </div>
  );
};



export default TableViewControls;
