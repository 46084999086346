import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { userManagementActions } from '../../../actions/userManagementActions';
import zxcvbn from 'zxcvbn';

class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      givenName: '',
      familyName: '',
      password: '',
      passwordConfirmation: '',
      validationMessage: '',

      jobTitle: '',
      businessPhone: '',
      mobilePhone: '',
      street1: '',
      street2: '',
      street3: '',
      city: '',
      stateOrProvince: '',
      zipOrPostalCode: '',
      countryOrRegion: '',
      preferredContactMethodId: 1,
      contactMethods: [],
      allowEmail: true,
      allowPhone: true,
      verifyPassword: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);
  }

  componentDidMount() {
    const {
      username,
      givenName,
      familyName,
      jobTitle,
      businessPhone,
      mobilePhone,
      street1,
      street2,
      street3,
      city,
      stateOrProvince,
      zipOrPostalCode,
      countryOrRegion,
      preferredContactMethodId,
      contactMethods,
      allowEmail,
      allowPhone,
    } = this.props;

    this.setState({
      username,
      givenName,
      familyName,
      password: '',
      passwordConfirmation: '',
      validationMessage: '',
      jobTitle,
      businessPhone,
      mobilePhone,
      street1,
      street2,
      street3,
      city,
      stateOrProvince,
      zipOrPostalCode,
      countryOrRegion,
      preferredContactMethodId,
      contactMethods,
      allowEmail,
      allowPhone,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      username,
      givenName,
      familyName,
      jobTitle,
      businessPhone,
      mobilePhone,
      street1,
      street2,
      street3,
      city,
      stateOrProvince,
      zipOrPostalCode,
      countryOrRegion,
      preferredContactMethodId,
      contactMethods,
      allowEmail,
      allowPhone,
    } = this.props;

    if (
      prevProps.username !== username
      || prevProps.givenName !== givenName
      || prevProps.familyName !== familyName
      || prevProps.jobTitle !== jobTitle
      || prevProps.businessPhone !== businessPhone
      || prevProps.mobilePhone !== mobilePhone
      || prevProps.street1 !== street1
      || prevProps.street2 !== street2
      || prevProps.street3 !== street3
      || prevProps.city !== city
      || prevProps.stateOrProvince !== stateOrProvince
      || prevProps.zipOrPostalCode !== zipOrPostalCode
      || prevProps.countryOrRegion !== countryOrRegion
      || prevProps.preferredContactMethodId !== preferredContactMethodId
      || prevProps.contactMethods !== contactMethods
      || prevProps.allowEmail !== allowEmail
      || prevProps.allowPhone !== allowPhone
    ) {
      this.setState({
        username,
        givenName,
        familyName,
        password: '',
        passwordConfirmation: '',
        validationMessage: '',

        jobTitle,
        businessPhone,
        mobilePhone,
        street1,
        street2,
        street3,
        city,
        stateOrProvince,
        zipOrPostalCode,
        countryOrRegion,
        preferredContactMethodId,
        contactMethods,
        allowEmail,
        allowPhone,
      });
    }

    if (prevProps.updatingUser && this.props.updatedUser && !this.props.errorMessage){
      this.setState({ password: '', passwordConfirmation: '', verifyPassword: '', validationMessage: 'Account Updated' });
    }
  }
  

  handleTextInputUpdate(e, fieldName) {
    const update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  handleCheckInputUpdate(e, fieldName) {
    const update = {};

    update[fieldName] = true && e.target.checked;

    this.setState(update);
  }

  handleSubmit(e) {
    const { password, passwordConfirmation } = this.state;
    let error = '';
    const passwordEvaluation = zxcvbn(password);
    
    if (password.length < 8) {
      error = 'Password must be greater than 8 characters.';
    }   
    else if (passwordEvaluation.score < 2){
      error = 'Increase the strength of your password by adding upper case and special characters (e.g. ABC@!)';
    }
    else if (password !== passwordConfirmation) {
      error = 'Passwords do not match.';
    }
    
    if (!error) {

      this.props.updateUser(this.state);
      e.preventDefault();
    } else {
      this.setState({ validationMessage: error });
      e.preventDefault();
    }
  }

  render() {
    const {
      username,
      password,
      givenName,
      familyName,
      validationMessage,
      passwordConfirmation,
      jobTitle,
      businessPhone,
      mobilePhone,
      street1,
      street2,
      street3,
      city,
      stateOrProvince,
      zipOrPostalCode,
      countryOrRegion,
      preferredContactMethodId,
      contactMethods,
      allowEmail,
      allowPhone,
      verifyPassword,
    } = this.state;

    const { updatingUser, errorMessage } = this.props;
    return (
      <div className="account-detail-page page">
        <form
          className="form-container"
          noValidate
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <div className="form-header">
            <div className="header-title">
              <div className="initials">
                {givenName[0]}
                {familyName[0]}
              </div>
              <h2>Your Account</h2>
            </div>
          </div>
          <div className="form-body">
            <label>
              Username:
              {' '}
              <input
                type="text"
                disabled="disabled"
                value={username}
                onChange={(e) => this.handleTextInputUpdate(e, 'username')}
              />
            </label>
          </div>
          <div className="form-body">
            <label>
              First Name:
              {' '}
              <input
                type="text"
                autoFocus
                value={givenName}
                onChange={(e) => this.handleTextInputUpdate(e, 'givenName')}
              />
            </label>
            <label>
              Last Name:
              {' '}
              <input
                type="text"
                value={familyName}
                onChange={(e) => this.handleTextInputUpdate(e, 'familyName')}
              />
            </label>
          </div>
          <div className="form-body">
            <label>
              Password:
              {' '}
              <input
                type="password"
                value={password}
                minLength="8"
                onChange={(e) => this.handleTextInputUpdate(e, 'password')}
                autoComplete="new-password"
              />
            </label>
            <label>
              Password Confirmation:
              {' '}
              <input
                type="password"
                value={passwordConfirmation}
                minLength="8"
                onChange={(e) => this.handleTextInputUpdate(e, 'passwordConfirmation')}
                autoComplete="new-password"
              />
            </label>
          </div>
          <div className="form-body">
            <label>
              Job Title
              <input
                placeholder="..."
                onChange={(e) => this.handleTextInputUpdate(e, 'jobTitle')}
                value={jobTitle}
              />
            </label>
            <label>
              Business Phone
              <input
                type="tel"
                placeholder="..."
                onChange={(e) => this.handleTextInputUpdate(e, 'businessPhone')}
                value={businessPhone}
              />
            </label>
            <label>
              Mobile Phone
              <input
                type="tel"
                placeholder="..."
                onChange={(e) => this.handleTextInputUpdate(e, 'mobilePhone')}
                value={mobilePhone}
              />
            </label>
          </div>
          <div className="form-body">
            <label>
              Preferred Contact Method
              <select
                name="Preferred Contact Method"
                placeholder="..."
                onChange={(e) => this.handleTextInputUpdate(e, 'preferredContactMethodId')}
                value={preferredContactMethodId}
              >
                {contactMethods.map((c) => (
                  <option
                    key={c.preferredContactMethodId}
                    value={c.preferredContactMethodId}
                  >
                    {c.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Allow Email
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  onChange={(e) => this.handleCheckInputUpdate(e, 'allowEmail')}
                  checked={allowEmail}
                />
                <div className="toggle-icon" />
              </div>
            </label>
            <label>
              Allow Phone
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  onChange={(e) => this.handleCheckInputUpdate(e, 'allowPhone')}
                  checked={allowPhone}
                />
                <div className="toggle-icon" />
              </div>
            </label>
          </div>
          <div className="form-body">
            <label>
              Street 1
              <input
                placeholder="..."
                onChange={(e) => this.handleTextInputUpdate(e, 'street1')}
                value={street1}
              />
            </label>
            <label>
              Street 2
              <input
                placeholder="..."
                onChange={(e) => this.handleTextInputUpdate(e, 'street2')}
                value={street2}
              />
            </label>
            <label>
              Street 3
              <input
                placeholder="..."
                onChange={(e) => this.handleTextInputUpdate(e, 'street3')}
                value={street3}
              />
            </label>
            <label>
              City
              <input
                placeholder="..."
                onChange={(e) => this.handleTextInputUpdate(e, 'city')}
                value={city}
              />
            </label>
            <label>
              County
              <input
                placeholder="..."
                onChange={(e) => this.handleTextInputUpdate(e, 'stateOrProvince')}
                value={stateOrProvince}
              />
            </label>
            <label>
              Postal Code
              <input
                placeholder="..."
                onChange={(e) => this.handleTextInputUpdate(e, 'zipOrPostalCode')}
                value={zipOrPostalCode}
              />
            </label>
            <label>
              Country
              <input
                placeholder="..."
                onChange={(e) => this.handleTextInputUpdate(e, 'countryOrRegion')}
                value={countryOrRegion}
              />
            </label>
          </div>
          <div className="form-buttons">

            {password.length > 0 && passwordConfirmation.length > 0 &&
            <label>
              Please confirm your current password:
              {' '}
              <input
                type="password"
                value={verifyPassword}
                minLength="8"
                onChange={(e) => this.handleTextInputUpdate(e, 'verifyPassword')}
              />
            </label>
            }
            <div className='password-validation-message'>
                <p>{validationMessage}</p>
            </div>
            <button className="action"
              disabled={updatingUser || (password.length > 0 && verifyPassword.length <= 0)}>
              {updatingUser ? 'Saving...' : 'Save'}
            </button>
            <p>{errorMessage}</p>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  username: state.auth.username,
  givenName: state.auth.givenName,
  familyName: state.auth.familyName,
  updatingUser: state.auth.updateUser,
  updatedUser: state.auth.updatedUser,
  errorMessage: state.auth.errorMessage,
  jobTitle: state.auth.jobTitle,
  businessPhone: state.auth.businessPhone,
  mobilePhone: state.auth.mobilePhone,
  street1: state.auth.street1,
  street2: state.auth.street2,
  street3: state.auth.street3,
  city: state.auth.city,
  stateOrProvince: state.auth.stateOrProvince,
  zipOrPostalCode: state.auth.zipOrPostalCode,
  countryOrRegion: state.auth.countryOrRegion,
  preferredContactMethodId: state.auth.preferredContactMethodId,
  contactMethods: state.auth.contactMethods,
  allowEmail: state.auth.allowEmail,
  allowPhone: state.auth.allowPhone,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(userManagementActions.updateMyUser(user)),

  push: (path) => dispatch(navigationActions.pushNavigation(path)),
  replace: (path) => dispatch(navigationActions.replaceNavigation(path)),
  reset: () => dispatch(navigationActions.resetNavigation()),
  goBack: () => dispatch(navigationActions.backNavigation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
