/* globals google */
import { ClusterIcon } from "./ClusterIcon";

export class Cluster {
  markerClusterer;
  map;
  gridSize;
  minClusterSize;
  averageCenter;
  markers;
  center;
  bounds;
  clusterIcon;

  constructor(markerClusterer) {
    this.markerClusterer = markerClusterer;

    this.map = this.markerClusterer.getMap();

    this.gridSize = this.markerClusterer.getGridSize();

    this.minClusterSize = this.markerClusterer.getMinimumClusterSize();

    this.averageCenter = this.markerClusterer.getAverageCenter();

    this.markers = [];

    this.center = undefined;

    this.bounds = null;

    this.clusterIcon = new ClusterIcon(this, this.markerClusterer.getStyles());
  }

  getSize() {
    return this.markers.length;
  }

  getMarkers() {
    return this.markers;
  }

  getCenter() {
    return this.center;
  }

  getMap() {
    return this.map;
  }

  getClusterer() {
    return this.markerClusterer;
  }

  getBounds() {
    const bounds = new google.maps.LatLngBounds(this.center, this.center);

    const markers = this.getMarkers();

    for (let i = 0; i < markers.length; i++) {
      const position = markers[i].getPosition();

      if (position) {
        bounds.extend(position);
      }
    }

    return bounds;
  }

  remove() {
    this.clusterIcon.setMap(null);

    this.markers = [];

    delete this.markers;
  }

  addMarker(marker) {
    if (this.isMarkerAlreadyAdded(marker)) {
      return false;
    }

    if (!this.center) {
      const position = marker.getPosition();

      if (position) {
        this.center = position;

        this.calculateBounds();
      }
    } else {
      if (this.averageCenter) {
        const position = marker.getPosition();

        if (position) {
          const length = this.markers.length + 1;

          this.center = new google.maps.LatLng(
            (this.center.lat() * (length - 1) + position.lat()) / length,
            (this.center.lng() * (length - 1) + position.lng()) / length
          );

          this.calculateBounds();
        }
      }
    }

    marker.isAdded = true;

    this.markers.push(marker);

    const mCount = this.markers.length;

    const maxZoom = this.markerClusterer.getMaxZoom();

    if (maxZoom !== null && this.map.getZoom() > maxZoom) {
      // Zoomed in past max zoom, so show the marker.
      if (marker.getMap() !== this.map) {
        marker.setMap(this.map);
      }
    } else if (mCount < this.minClusterSize) {
      // Min cluster size not reached so show the marker.
      if (marker.getMap() !== this.map) {
        marker.setMap(this.map);
      }
    } else if (mCount === this.minClusterSize) {
      // Hide the markers that were showing.
      for (let i = 0; i < mCount; i++) {
        this.markers[i].setMap(null);
      }
    } else {
      marker.setMap(null);
    }

    this.updateIcon();

    return true;
  }

  isMarkerInClusterBounds(marker) {
    if (this.bounds !== null) {
      const position = marker.getPosition();

      if (position) {
        return this.bounds.contains(position);
      }
    }

    return false;
  }

  calculateBounds() {
    this.bounds = this.markerClusterer.getExtendedBounds(
      new google.maps.LatLngBounds(this.center, this.center)
    );
  }

  updateIcon() {
    const mCount = this.markers.length;

    const maxZoom = this.markerClusterer.getMaxZoom();

    if (maxZoom !== null && this.map.getZoom() > maxZoom) {
      this.clusterIcon.hide();

      return;
    }

    if (mCount < this.minClusterSize) {
      // Min cluster size not yet reached.
      this.clusterIcon.hide();

      return;
    }

    if (this.center) {
      this.clusterIcon.setCenter(this.center);
    }

    this.clusterIcon.useStyle(
      this.markerClusterer.getCalculator()(
        this.markers,
        this.markerClusterer.getStyles().length
      )
    );

    this.clusterIcon.show();
  }

  isMarkerAlreadyAdded(marker) {
    if (this.markers.indexOf) {
      return this.markers.includes(marker);
    } else {
      for (let i = 0; i < this.markers.length; i++) {
        if (marker === this.markers[i]) {
          return true;
        }
      }
    }

    return false;
  }
}
