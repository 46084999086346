import {
  SITEIDREPORT_REFERENCEDATA_REQUEST,
  SITEIDREPORT_REFERENCEDATA_SUCCESS,
  SITEIDREPORT_REFERENCEDATA_FAILURE,
  SITEIDREPORT_REPORTDATA_REQUEST,
  SITEIDREPORT_REPORTDATA_SUCCESS,
  SITEIDREPORT_REPORTDATA_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const SiteIDReportingReducer = (
  state = initialState.siteIDReportingState,
  action,
) => {
  switch (action.type) {
  case SITEIDREPORT_REFERENCEDATA_REQUEST: {
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      errorMessage: '',
    };
  }
  case SITEIDREPORT_REFERENCEDATA_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      referenceData: action.payload,
      errorMessage: '',
    };
  }
  case SITEIDREPORT_REFERENCEDATA_FAILURE: {
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      referenceData: {},
      errorMessage: action.payload.message,
    };
  }
  case SITEIDREPORT_REPORTDATA_REQUEST: {
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      errorMessage: '',
    };
  }
  case SITEIDREPORT_REPORTDATA_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      reportData: action.payload,
      errorMessage: '',
    };
  }
  case SITEIDREPORT_REPORTDATA_FAILURE: {
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      reportData: {},
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default SiteIDReportingReducer;
