import {
  SUPPLIERFEEDBACK_REQUEST,
  SUPPLIERFEEDBACK_SUCCESS,
  SUPPLIERFEEDBACK_FAILURE,
  PRODUCTFEEDBACK_REQUEST,
  PRODUCTFEEDBACK_SUCCESS,
  PRODUCTFEEDBACK_FAILURE,
  PORTALFEEDBACK_REQUEST,
  PORTALFEEDBACK_SUCCESS,
  PORTALFEEDBACK_FAILURE,
} from './actionTypes';

export const feedbackActions = {
  submitSupplierFeedback: supplierFeedback => (dispatch, getState) => {
    dispatch({
      type: SUPPLIERFEEDBACK_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/feedback/v1/SubmitSupplierFeedback',
        successAction: SUPPLIERFEEDBACK_SUCCESS,
        failureAction: SUPPLIERFEEDBACK_FAILURE,
        body: {
          ...supplierFeedback,
        },
      },
    });
  },
  submitProductFeedback: productFeedback => (dispatch, getState) => {
    dispatch({
      type: PRODUCTFEEDBACK_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/feedback/v1/SubmitProductFeedback',
        successAction: PRODUCTFEEDBACK_SUCCESS,
        failureAction: PRODUCTFEEDBACK_FAILURE,
        body: {
          ...productFeedback,
        },
      },
    });
  },
  submitPortalFeedback: portalFeedback => (dispatch, getState) => {
    dispatch({
      type: PORTALFEEDBACK_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/feedback/v1/SubmitPortalFeedback',
        successAction: PORTALFEEDBACK_SUCCESS,
        failureAction: PORTALFEEDBACK_FAILURE,
        body: {
          ...portalFeedback,
        },
      },
    });
  },
};
