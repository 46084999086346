import React from 'react';
 
const LacContactDetails=()=>
{
  return (<>
    <div className="form-body form-body-top">
      <div>
        <h4>LAC SCO Retail Supply</h4>
        <p>
          <a href="mailto:lacsco.rs.planning@starbucks.com">
                lacsco.rs.planning@starbucks.com
          </a>{' '}
        </p>

        <h4>LAC SCO Store Development Support</h4>
        <p>
          <a href="mailto:lacsco.sd.planning@starbucks.com">
                lacsco.sd.planning@starbucks.com
          </a>{' '}
        </p>
      </div>   
    </div>
  </>);
};
 
export default LacContactDetails;