import React from 'react';

const DeleteUserModal = props => {
  return (
    <div className="modal-blocker">
      <div className="modal">
        <form>
          <div className="modal-menu">
            <button
              type="button"
              className="action"
              onClick={() => props.cancel()}
            >
              X
            </button>
          </div>
          <div className="modal-title">
            <h2>Delete User(s)</h2>
          </div>
          <div className="modal-body">
            <p>The file you have uploaded is missing one or more users. This will result in deletion of these users. Are you sure you want to continue?</p>
            <p style={{ fontWeight: 'bold' }}>{props.usersToBeDeleted?.join(', ')}</p>
          </div>
          <div className="modal-buttons">
            <button
              type="button"
              className="action"
              disabled={props.inProgress}
              onClick={() => props.continue()}
            >
              Yes
            </button>
            <button
              type="button"
              className="action negative"
              disabled={props.inProgress}
              onClick={() => props.cancel()}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeleteUserModal;
