import {
  ORDERTRACKER_CLEARSTATE,
  ORDERTRACKER_GETORDER_REQUEST,
  ORDERTRACKER_GETORDER_SUCCESS,
  ORDERTRACKER_GETORDER_FAILURE,
  ORDERTRACKER_SAVEORRDER_REQUEST,
  ORDERTRACKER_SAVEORRDER_SUCCESS,
  ORDERTRACKER_SAVEORRDER_FAILURE,
  ORDERTRACKER_SEARCH_REQUEST,
  ORDERTRACKER_SEARCH_SUCCESS,
  ORDERTRACKER_SEARCH_FAILURE,
  ORDERTRACKER_EXPORT_REQUEST,
  ORDERTRACKER_EXPORT_SUCCESS,
  ORDERTRACKER_EXPORT_FAILURE,
  ORDERTRACKER_CUSTOMERSUMMARY_REQUEST,
  ORDERTRACKER_CUSTOMERSUMMARY_SUCCESS,
  ORDERTRACKER_CUSTOMERSUMMARY_FAILURE,
  ORDERTRACKER_CUSTOMERSUMMARYEXPORT_REQUEST,
  ORDERTRACKER_CUSTOMERSUMMARYEXPORT_SUCCESS,
  ORDERTRACKER_CUSTOMERSUMMARYEXPORT_FAILURE,
  ORDERTRACKER_CONTACT_REQUEST,
  ORDERTRACKER_CONTACT_SUCCESS,
  ORDERTRACKER_CONTACT_FAILURE,
  ORDERTRACKER_LOADBULK_REQUEST,
  ORDERTRACKER_LOADBULK_SUCCESS,
  ORDERTRACKER_LOADBULK_FAILURE,
  ORDERTRACKER_SAVEBULK_REQUEST,
  ORDERTRACKER_SAVEBULK_SUCCESS,
  ORDERTRACKER_SAVEBULK_FAILURE,
  ORDERTRACKER_DASHBOARD_REQUEST,
  ORDERTRACKER_DASHBOARD_SUCCESS,
  ORDERTRACKER_DASHBOARD_FAILURE,
  ORDERTRACKER_DASHBOARDCUSTOMER_REQUEST,
  ORDERTRACKER_DASHBOARDCUSTOMER_SUCCESS,
  ORDERTRACKER_DASHBOARDCUSTOMER_FAILURE,
  ORDERTRACKER_CUSTOMERSEARCH_REQUEST,
  ORDERTRACKER_CUSTOMERSEARCH_SUCCESS,
  ORDERTRACKER_CUSTOMERSEARCH_FAILURE,
  ORDERTRACKER_CUSTOMERSTORES_REQUEST,
  ORDERTRACKER_CUSTOMERSTORES_SUCCESS,
  ORDERTRACKER_CUSTOMERSTORES_FAILURE,
} from './actionTypes';

const orderTrackerActions = {
  clearState: () => {
    return {
      type: ORDERTRACKER_CLEARSTATE,
    };
  },
  getOrder: (payload) => (dispatch) => {
    dispatch({
      type: ORDERTRACKER_GETORDER_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/ordertracker/v1/get?orderId=${payload}`,
        successAction: ORDERTRACKER_GETORDER_SUCCESS,
        failureAction: ORDERTRACKER_GETORDER_FAILURE,
      },
    });
  },

  saveOrder: (payload) => (dispatch) => {
    dispatch({
      type: ORDERTRACKER_SAVEORRDER_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/ordertracker/v1/save',
        successAction: ORDERTRACKER_SAVEORRDER_SUCCESS,
        failureAction: ORDERTRACKER_SAVEORRDER_FAILURE,
        body: payload,
      },
    });
  },
  searchOrders: (payload) => (dispatch) => {
    dispatch({
      type: ORDERTRACKER_SEARCH_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/ordertracker/v1/search${convertToQuerystring(payload)}`,
        successAction: ORDERTRACKER_SEARCH_SUCCESS,
        failureAction: ORDERTRACKER_SEARCH_FAILURE,
      },
    });
  },
  exportOrders: (payload) => (dispatch) => {
    dispatch({
      type: ORDERTRACKER_EXPORT_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/ordertracker/v1/export${convertToQuerystring(payload)}`,
        successAction: ORDERTRACKER_EXPORT_SUCCESS,
        failureAction: ORDERTRACKER_EXPORT_FAILURE,
      },
    });
  },
  getCustomerSummary: (payload) => (dispatch) => {
    dispatch({
      type: ORDERTRACKER_CUSTOMERSUMMARY_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/ordertracker/v1/customersummary${convertToQuerystring(payload)}`,
        successAction: ORDERTRACKER_CUSTOMERSUMMARY_SUCCESS,
        failureAction: ORDERTRACKER_CUSTOMERSUMMARY_FAILURE,
      },
    });
  },
  exportCustomerSummary: (payload) => (dispatch) => {
    dispatch({
      type: ORDERTRACKER_CUSTOMERSUMMARYEXPORT_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/ordertracker/v1/exportcustomersummary${convertToQuerystring(payload)}`,
        successAction: ORDERTRACKER_CUSTOMERSUMMARYEXPORT_SUCCESS,
        failureAction: ORDERTRACKER_CUSTOMERSUMMARYEXPORT_FAILURE,
      },
    });
  },
  submitContactForm: (payload) => (dispatch) => {
    dispatch({
      type: ORDERTRACKER_CONTACT_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/ordertracker/v1/contact',
        successAction: ORDERTRACKER_CONTACT_SUCCESS,
        failureAction: ORDERTRACKER_CONTACT_FAILURE,
        body: payload,
      },
    });
  },
  loadBulkOrders: () => (dispatch) => {
    dispatch({
      type: ORDERTRACKER_LOADBULK_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/ordertracker/v1/inprogress',
        successAction: ORDERTRACKER_LOADBULK_SUCCESS,
        failureAction: ORDERTRACKER_LOADBULK_FAILURE,
      },
    });
  },
  saveBulkOrders: (payload) => (dispatch) => {
    dispatch({
      type: ORDERTRACKER_SAVEBULK_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/ordertracker/v1/bulkupdate',
        successAction: ORDERTRACKER_SAVEBULK_SUCCESS,
        failureAction: ORDERTRACKER_SAVEBULK_FAILURE,
        body: payload,
      },
    });
  },
  getDashboard: (payload) => (dispatch) => {
    dispatch({
      type: ORDERTRACKER_DASHBOARD_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/ordertracker/v1/reports${convertToQuerystring(payload)}`,
        successAction: ORDERTRACKER_DASHBOARD_SUCCESS,
        failureAction: ORDERTRACKER_DASHBOARD_FAILURE,
      },
    });
  },
  getDashboardCustomers: () => (dispatch) => {
    dispatch({
      type: ORDERTRACKER_DASHBOARDCUSTOMER_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/ordertracker/v1/reportcustomers',
        successAction: ORDERTRACKER_DASHBOARDCUSTOMER_SUCCESS,
        failureAction: ORDERTRACKER_DASHBOARDCUSTOMER_FAILURE,
      },
    });
  },
  searchCustomers: (payload) => (dispatch) => {
    dispatch({
      type: ORDERTRACKER_CUSTOMERSEARCH_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/ordertracker/v1/customersearch${convertToQuerystring(payload)}`,
        successAction: ORDERTRACKER_CUSTOMERSEARCH_SUCCESS,
        failureAction: ORDERTRACKER_CUSTOMERSEARCH_FAILURE,
      },
    });
  },
  getCustomerStores: () => (dispatch) => {
    dispatch({
      type: ORDERTRACKER_CUSTOMERSTORES_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/ordertracker/v1/customerstores',
        successAction: ORDERTRACKER_CUSTOMERSTORES_SUCCESS,
        failureAction: ORDERTRACKER_CUSTOMERSTORES_FAILURE,
      },
    });
  },
};

const convertToQuerystring = (obj) => {
  const qs = Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join('&');

  return qs.length === 0
    ? qs
    : `?${qs}`;
};

export default orderTrackerActions;
