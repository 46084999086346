import React, { useState, useEffect } from 'react';
import CustomStackedBarChart from '../CustomCharts/CustomStackedBarChart';
import { TimeFilterEnum } from '../Common/Enums/TimeFilterEnum';
import { ShipmentBarsEnum } from '../Common/Enums/ShipmentBarsEnum';
import forecastPerformanceDashboardActions from '../../../actions/forecastPerformanceDashboardActions';
import { connect } from 'react-redux';
import './shipmentPerformance.scss';
import { doDataExport } from '../DemandAndSupply/CarouselCharts/CommonCarouselFunctions';
import Table from '../CustomTables/CustomTable';
import { ExportChart } from '../Common/Enums/ExportChart';
import PeriodMultiSelectFilter from '../CustomFilters/MultiSelect/PeriodMultiSelectFilter';

const ShipmentPerformance = (props) => {
  const [loading, setLoading] = useState(true);
  const [periodFilter, setPeriodFilter] = useState(TimeFilterEnum.YTD);
  const [selectedMonths, setSelectedMonths] = useState([]);

  const tableHeaders = ['Market', 'On Time (%)', 'Delayed 4 to 14 days (%)','Delayed more than 14 days (%)'];

  useEffect(() => {
    props.getShipmentPerformanceChartData(periodFilter, selectedMonths); 
    setLoading(false);
  }, [periodFilter, selectedMonths]);

  const handlePeriodFilterChange = (newFilter) => {
    setPeriodFilter(newFilter);
  };

  const handleMonthsChange = (selectedMonths) => {
    setSelectedMonths(selectedMonths);
  };

  const months = [
    { key: 10, value: 'October' },
    { key: 11, value: 'November' },
    { key: 12, value: 'December' },
    { key: 1, value: 'January' },
    { key: 2, value: 'February' },
    { key: 3, value: 'March' },
    { key: 4, value: 'April' },
    { key: 5, value: 'May' },
    { key: 6, value: 'June' },
    { key: 7, value: 'July' },
    { key: 8, value: 'August' },
    { key: 9, value: 'September' },
  ];

  const filters = [
    { key: 'Period', value: periodFilter },
    { key: 'Selected Months', value: selectedMonths.map(id => months.find(month => month.key === id)?.value).join(', ') },
  ];


  let result = Array.isArray(props.mbuData) && props.mbuData.map(mbu => {
    let data = Array.isArray(props.shipmentPerfomanceData) && props.shipmentPerfomanceData.find(sd => sd.marketId === mbu.marketId);
    return {
      name: mbu.name.replace('Starbucks', ''),
      OnTime: data ? formatNumber(data.onTime) : 0,
      Delay4To14D:  data ? formatNumber(data.fourToFourteenDayDelay) : 0,
      DelayMoreThan14D:  data ? formatNumber(data.moreThanFourteenDayDelay) : 0,
    };
  });

  function formatNumber(number) {
    return number.toLocaleString('en', { maximumFractionDigits: 2 });
  }

  
  function transposeData(data) {
    if (!Array.isArray(data)) {
      throw new TypeError('Data must be an array');
    }
    if (data.length === 0) {
      return [];
    }
    const keys = Object.keys(data[0]);
    if (!keys.every(key => data.every(item => key in item))) {
      throw new TypeError('All objects in data must have the same keys');
    }
    return keys.map(key => data.map(item => item[key]));
  }

  const bars = [
    { dataKey: ShipmentBarsEnum.OnTime, strokeColor: '#006400' }, // Green color for on time
    { dataKey: ShipmentBarsEnum.Delay4To14D, strokeColor: '#FFA500' }, // Orange color for delays between 4 to 14 days
    { dataKey: ShipmentBarsEnum.DelayMoreThan14D, strokeColor: '#FF0000' }, // Red color for delays more than 14 days
  ];


  const handleExportSummary = () => {
    props.exportRawData(ExportChart.ShipmentPerformance,periodFilter,selectedMonths,[]);
  };

  if (loading) {
    return <div className="app-spinner">
      <span className="spinner" />
    </div>;
  }
 
  return <div>
    <div className="group-details accordion download-container summary forecast-export">
      <div className="accordion-header">
        <h3>Download Summary</h3>
      </div>
      <div className="accordion-body horizontal-flex order-fields">
        <div>
          <button
            className="action export"
            type="button"
            title="Export"
            disabled={false}
            onClick={() => handleExportSummary()}
          >
            <div
              className="icon icon-download-light"
              alt="Export"
            />
          </button>
        </div>
      </div>
    </div>
    <div className='shipment-filter-container'>
      <PeriodMultiSelectFilter        
        periodFilter={periodFilter}
        selectedMonths={selectedMonths}
        onPeriodFilterChange={handlePeriodFilterChange}
        onSelectedMonths={handleMonthsChange}
        orderMetrics={false} />
    </div>

    <div id ='shipment-performance'
      className='shipment-performance-chart' >
      <CustomStackedBarChart data={result} 
        chartTitle= {'Shipment Performance'}
        bars={bars} 
        maxYValue={100}
        width={'100%'} />
    </div>

    <div className='export-button-style'>
      <button type="button"
        className="action export"
        onClick={() => doDataExport('Shipment Performance', 'shipment-performance', 'shipment-performance-chart', filters)}>Export Chart</button>
    </div>

    <div className='shipment-table-container'>
      <Table headers={tableHeaders} 
        transposed={true}
        data={transposeData(result)} />
    </div>
  </div>;
};


const mapStateToProps = (state) => {
  return {
    shipmentPerfomanceData: state.forecastPerformanceDashboard.shipmentPerfomanceData,
  };
};
 

const mapDispatchToProps = (dispatch) => {
  return {
    getShipmentPerformanceChartData: (periodFilter, selectedMonths) => dispatch(forecastPerformanceDashboardActions.getShipmentPerformanceChartData(periodFilter, selectedMonths)),
    exportRawData: (exportChart, periodFilter, selectedMonths, selectedMarkets) => 
      dispatch(forecastPerformanceDashboardActions.exportRawData(exportChart, periodFilter, selectedMonths, selectedMarkets)),
  };
};
 

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentPerformance);
