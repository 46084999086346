import {
  DISTRIBUTION_DETAIL_REQUEST,
  DISTRIBUTION_DETAIL_SUCCESS,
  DISTRIBUTION_DETAIL_FAILURE,
  DISTRIBUTION_MARKETS_REQUEST,
  DISTRIBUTION_MARKETS_SUCCESS,
  DISTRIBUTION_MARKETS_FAILURE,
  DISTRIBUTION_SAVE_REQUEST,
  DISTRIBUTION_SAVE_SUCCESS,
  DISTRIBUTION_SAVE_FAILURE,
} from './actionTypes';

export const emailDistributionListActions = {
  getDetail: () => (dispatch, getState) => {
    dispatch({
      type: DISTRIBUTION_DETAIL_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/emaildistribution/v1/getdistributiondetail',
        successAction: DISTRIBUTION_DETAIL_SUCCESS,
        failureAction: DISTRIBUTION_DETAIL_FAILURE,
      },
    });
  },
  getEmailDistributionMarkets: () => (dispatch, getState) => {
    dispatch({
      type: DISTRIBUTION_MARKETS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/emaildistribution/v1/getEmailDistributionMarkets',
        successAction: DISTRIBUTION_MARKETS_SUCCESS,
        failureAction: DISTRIBUTION_MARKETS_FAILURE,
      },
    });
  },
  save: (groups) => (dispatch, getState) => {
    dispatch({
      type: DISTRIBUTION_SAVE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/emaildistribution/v1/savegroups',
        successAction: DISTRIBUTION_SAVE_SUCCESS,
        failureAction: DISTRIBUTION_SAVE_FAILURE,
        body: groups,
      },
    });
  },
};
