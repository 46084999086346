import {
  CONNECT_CONTENTLIST_REQUEST, 
  CONNECT_CONTENTLIST_SUCCESS, 
  CONNECT_CONTENTLIST_FAILURE, 
  CONNECT_CONTENTARCHIVE_REQUEST,
  CONNECT_CONTENTARCHIVE_SUCCESS,
  CONNECT_CONTENTARCHIVE_FAILURE,
  CONNECT_CONTENTDISPLAY_REQUEST,
  CONNECT_CONTENTDISPLAY_SUCCESS,
  CONNECT_CONTENTDISPLAY_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const ConnectContentRouter = (
  state = initialState.connectContentState,
  action,
) => {
  switch (action.type) {
  case CONNECT_CONTENTLIST_REQUEST:
  case CONNECT_CONTENTARCHIVE_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      error: undefined,
      contentList: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
      labelList: [],
    };
  case CONNECT_CONTENTLIST_SUCCESS:
  case CONNECT_CONTENTARCHIVE_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: undefined,
      contentList: action.payload.items.data,
      pageNumber: action.payload.items.pageNumber,
      pageSize: action.payload.items.pageSize,
      totalRecords: action.payload.items.totalRecords,
      labelList: action.payload.labels,
    };
  case CONNECT_CONTENTLIST_FAILURE:
  case CONNECT_CONTENTARCHIVE_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: action.payload.message,
      contentList: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
      labelList: [],
    };

  case CONNECT_CONTENTDISPLAY_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      error: undefined,
      displayContent: [],
      isDisplayAdmin: false,
    };
  case CONNECT_CONTENTDISPLAY_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: undefined,
      displayContent:action.payload.content,
      isDisplayAdmin: action.payload.isAdmin,
      displayStores: action.payload.storeData,
      displayGroups: action.payload.storeGroupData,
      config: action.payload.config,
    };
  case CONNECT_CONTENTDISPLAY_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: action.payload.message,
      displayContent: [],
      isDisplayAdmin: false,
    };

    
  default:
    return state;
  }
};
export default ConnectContentRouter;
