import React from 'react';

class LoadingTabOds extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { customerRequirementsDetail, handleTextInputUpdate, handleCheckInputUpdate } = this.props;
    return (
      <>
        <label>
        Double Stacking of Coffee Items Allowed
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.doubleStackingCoffeeItems}
              onChange={e => handleCheckInputUpdate(e, 'doubleStackingCoffeeItems')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
        Full Truck/Container Vehicle Sealing
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.fullTruckVehicleSealing}
              onChange={e => handleCheckInputUpdate(e, 'fullTruckVehicleSealing')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
        Approved Exception Double Stacking
          <div className="toggle-wrapper">
            <input
              type="checkbox"
              className="toggle"
              checked={customerRequirementsDetail && customerRequirementsDetail.approvedExceptionDoubleStacking}
              onChange={e => handleCheckInputUpdate(e, 'approvedExceptionDoubleStacking')}
            />
            <div className="toggle-icon"></div>
          </div>
        </label>
        <label>
        Approver Name
          <input
            maxLength={50}
            type="text"
            value={customerRequirementsDetail && customerRequirementsDetail.approverName}
            onChange={e => handleTextInputUpdate(e, 'approverName')}
          />
        </label>
      </>
    );
  }
}

export default LoadingTabOds;
