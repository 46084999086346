import React from 'react';

class OdsTabOds extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { customerRequirementsDetail, handleTextInputUpdate } = this.props;
    return (
      <>
        <label>
        Frequency
          <select value={customerRequirementsDetail && customerRequirementsDetail.frequency}
            onChange={e => handleTextInputUpdate(e, 'frequency')}
          >
            <option value={0}>Select a Frequency</option>
            {customerRequirementsDetail.frequencies && customerRequirementsDetail.frequencies.map(f => 
              <option key={f.key}
                value={f.key}>{f.value}</option>,
            )}
          </select>
        </label>
        <label>
         Order Submit Day
          <select value={customerRequirementsDetail && customerRequirementsDetail.orderSubmitDay}
            onChange={e => handleTextInputUpdate(e, 'orderSubmitDay')}
          >
            <option value={0}>Select an Order Submit Day</option>
            {customerRequirementsDetail.orderSubmitDays && customerRequirementsDetail.orderSubmitDays.map(f => 
              <option key={f.key}
                value={f.key}>{f.value}</option>,
            )}
          </select>
        </label>
        <label>
        Order Submit Cut-Off Time
          <input
            type="text"
            disabled
            value="09:00 PST"
          />
        </label>
        <label>
        Order Book to Ready to Ship Lead Time
          <input
            type="number"
            value={customerRequirementsDetail && customerRequirementsDetail.orderBookToReadyToShipLeadTime}
            onChange={e => handleTextInputUpdate(e, 'orderBookToReadyToShipLeadTime')}
          />
        </label>
        <label>
        Delivery Transit Lead Time RTS to Ship To
          <input
            type="number"
            value={customerRequirementsDetail && customerRequirementsDetail.deliveryTransitLeadTime}
            onChange={e => handleTextInputUpdate(e, 'deliveryTransitLeadTime')}
          />
        </label>
        <label>
        Details
          <textarea
            type="text"
            maxLength={500}
            value={customerRequirementsDetail && customerRequirementsDetail.otherRequirementsOds}
            onChange={e => handleTextInputUpdate(e, 'otherRequirementsOds')}
          />
        </label>
      </>
    );
  }
}

export default OdsTabOds;
